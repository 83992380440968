<template >
  <b-card no-body class="doughnut-chart">
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          {{chartName}}
        </b-card-title>
      </div>
    </b-card-header>
    <b-card-body>
    <div class="pie-text text-center">
      <h2 class="font-weight-bolder">
        {{total}}
      </h2>
    </div>
   <app-echart-doughnut :series="series"    :options="chartOptions"   ref="line" autoresize  theme="theme-color" auto-resize/>
  </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle, } from 'bootstrap-vue'
import AppEchartDoughnut from '@core/components/charts/echart/AppEchartDoughnut.vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    AppEchartDoughnut,
  },
  props:{
    chartName:String,
    chart:Array,
    total:String
  },
  data() {
    return {
      series: this.chart,
      chartOptions:{
      },
    }
  },
}
</script>

<style lang="scss">

</style>
