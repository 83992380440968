<template>
    <div>
        <b-card title="Sommelier Form">
            <b-row>
                <b-col md="4">
                    <b-form-group label="Brief">
                        <b-form-input
                            v-model="brief"
                            placeholder="Brief"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Full Name">
                        <b-form-input
                            v-model="fullName"
                            placeholder="Full Name"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Title">
                        <b-form-input
                            v-model="title"
                            placeholder="Title"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Location">
                        <b-form-input
                            v-model="location"
                            placeholder="Location"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Email">
                        <b-form-input
                            v-model="email"
                            placeholder="Email"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button @click="saveSommelierData()">
                        Add Sommelier
                    </b-button>
                </b-col>
            </b-row>
        </b-card>

        <b-card title="Restaurant Form">
            <b-row>
                <b-col md="4">
                    <b-form-group label="Restaurant Name">
                        <b-form-input
                            v-model="titleRestaurant"
                            placeholder="Restaurant Name"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Brief">
                        <b-form-input
                            v-model="briefRestaurant"
                            placeholder="Brief"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Full Name">
                        <b-form-input
                            v-model="fullNameRestaurant"
                            placeholder="Full Name"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Location">
                        <b-form-input
                            v-model="locationRestaurant"
                            placeholder="Location"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Email">
                        <b-form-input
                            v-model="emailRestaurant"
                            placeholder="Email"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4">
                    <b-form-group label="Online Presence Link">
                        <b-form-input
                            v-model="onlinePresenceLink"
                            placeholder="Online Presence Link"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button @click="saveRestaurantData()">
                        Add Restaurant
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BSpinner,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal,BFormSelect,BFormGroup,
} from 'bootstrap-vue'

import firebase from '@/utils/firebaseInit'
import firebaseTimeStamp from 'firebase'
import { dbCollections } from '@/utils/firebaseCollection'
const db = firebase.firestore()

export default {
    name:'fields',
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BModal,
        BFormSelect,
        BFormGroup,
        BSpinner,
    },
    data(){
        return{
            brief: '',
            email: '',
            fullName: '',
            location: '',
            title: '',

            briefRestaurant: '',
            emailRestaurant: '',
            fullNameRestaurant: '',
            locationRestaurant: '',
            titleRestaurant: '',
            onlinePresenceLink:'',
        }
    },
    methods:
    {
        resetData()
        {
            this.brief= ''
            this.email= ''
            this.fullName= ''
            this.location= ''
            this.title= '',

            this.briefRestaurant= ''
            this.emailRestaurant= ''
            this.fullNameRestaurant= ''
            this.locationRestaurant= ''
            this.titleRestaurant= ''
            this.onlinePresenceLink= ''
        },
        saveSommelierData()
        {
            db
                .collection(dbCollections.USERS)
                .add({
                    id: "",
                    createdAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                    updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                    brief: this.brief,
                    email: this.email,
                    fullName: this.fullName,
                    location: this.location,
                    title: this.title,

                    certificates: [{course: 'information technology', year: '2020', certification: 'bachelor degree'}],
                    education: [{course: 'information technology', year: 2016, college: 'G H Patel College of Engineering and Technology'}],
                    experience: [{location: 'Anand', year: 2020, companyName: 'alian software'}],
                    hourlyRate: 3,
                    isEmailVerified: true,
                    isProfileApproved: true,
                    languages: ["English"],
                    profilePicture: "URL",
                    sessions: [{
                        deviceBrand: "Device Brand",
                        deviceId: "Device ID",
                        deviceModel: "Model Name",
                        fcmToken: "[TOKEN]",
                        isEmulator: true,
                        isOnline: true,
                        isTablet: true,
                        lastOnlineTimestamp: new Date(),
                        timeZone: "Time Zone",
                    }],

                    status:'Pending',
                    
                    userRole: "sommelier",
                })
                .then((docRef)=>{
                    db
                        .collection(dbCollections.USERS)
                        .doc(docRef.id)
                        .update({
                            id:docRef.id
                        })
                        .then(()=>{
                            this.$root.$emit('showToastMessage','Data saved successfully.','success')
                            this.resetData()
                        })
                        .catch(error=>{
                            console.error(error)
                        })
                })
                .catch(error=>{
                    console.error(error)
                })
        },
        saveRestaurantData()
        {
            db
                .collection(dbCollections.USERS)
                .add({
                    id: "",
                    createdAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                    updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),
                    brief: this.briefRestaurant,
                    email: this.emailRestaurant,
                    fullName: this.fullNameRestaurant,
                    location: this.locationRestaurant,
                    restaurantName: this.titleRestaurant,
                    onlinePresenceLink: this.onlinePresenceLink,

                    cuisine: ["Indian"],
                    logoUrl: "",
                    menuImgURL: "",
                    menuImgNAME: "",
                    profilePicture: "",

                    sessions: [{
                        deviceBrand: "Device Brand",
                        deviceId: "Device ID",
                        deviceModel: "Model Name",
                        fcmToken: "[TOKEN]",
                        isEmulator: true,
                        isOnline: true,
                        isTablet: true,
                        lastOnlineTimestamp: new Date(),
                        timeZone: "Time Zone",
                    }],

                    updatedAt: firebaseTimeStamp.firestore.FieldValue.serverTimestamp(),

                    status:'Pending',
                    
                    userRole: "restaurant",
                })
                .then((docRef)=>{
                    db
                        .collection(dbCollections.USERS)
                        .doc(docRef.id)
                        .update({
                            id:docRef.id
                        })
                        .then(()=>{
                            this.$root.$emit('showToastMessage','Data saved successfully.','success')
                            this.resetData()
                        })
                        .catch(error=>{
                            console.error(error)
                        })
                })
                .catch(error=>{
                    console.error(error)
                })
        }
    }
}
</script>

<style>

</style>