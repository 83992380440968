<template>
    <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isProcessed"
        large
        class="pageLoadSpinners loaderDetail"
        style="position: fixed"
      />
    

    <div class="card user_filter all_session">
      <h2>Filters</h2>
     <b-form @submit.prevent>
        <b-row>
          <b-col xl="3" lg="3" md="3">         
            <label>Learner Name</label>
            <b-dropdown
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :text="learner_selected"
              variant="outline-secondary"
              class="w-100"
            >
              <b-dropdown-item
                v-for="item in learnerNameOptions"
                :key="item.value"
                @click="learner_selected = item.text"
                >{{ item.text }}</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <label>Tutor Name</label>
            <b-dropdown
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :text="tutorNameSelected"
              variant="outline-secondary"
              class="w-100"
            >
              <b-dropdown-item
                v-for="item in tutorNameOptions"
                :key="item.value"
                @click="tutorNameSelected = item.text"
                >{{ item.text }}</b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-form>
    </div>

    <div class="card user_table pendingsession">
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="reviewmanagementsearchTerm"
              placeholder="Search..."
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <vue-good-table
        :columns="pendingSessionColumns"
        :rows="filterRows"
        @on-cell-click="onCellClick"
        class="cursor-pointer"
        :rtl="true"
        :search-options="{
          enabled: true,
          externalQuery: reviewmanagementsearchTerm,
        }"
        :select-options="{
          enabled: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'bookingId'" class="text-nowrap">
            <span class="userid">
               {{ props.row.bookingId }}
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'sessionDate'"
            class="text-nowrap"
          >
          {{(props.row.startTime && props.row.endTime) ? sessionTimeCalculate(props.row.startTime,props.row.endTime)  : ""}}
          </span>
          <span
            v-else-if="props.column.field === 'sessionName'"
            class="tutor_type"
          >
            <span v-if='props.row.sessionName !== undefined'>
              {{props.row.sessionName}}
            </span>
           <span v-else>N/A</span>
          </span>
          <span
            v-else-if="props.column.field === 'tutorFullName'"
            class="childname text-nowrap tutor_type"
          >     
           <b-avatar
              :src="(props.row.tutorDetails &&  props.row.tutorDetails.profilePicture) ? props.row.tutorDetails.profilePicture :  ''"
              alt="profile image"
              class="user_img"
            />   
            <router-link v-if="props.row.tutor" :to="{ name: 'user-details', params: { id: props.row.tutor} }" class="text-nowrap">
              <a>{{props.row.tutorFullName}}</a>
            </router-link>
          </span>
          <span
            v-else-if="props.column.field === 'learnerFullName'"
            class="childname text-nowrap tutor_type"
          > 
          <b-avatar
              :src="(props.row.learnerDetails &&  props.row.learnerDetails.profileImage) ? props.row.learnerDetails.profileImage :  ''"
              alt="profile image"
              class="user_img"
          />   
          <router-link v-if="props.row.learnerId" :to="{ name: 'user-details', params: { id: props.row.learnerId} }" class="text-nowrap">
           <a>{{props.row.learnerFullName}}</a>
          </router-link>
          </span>
          <span
            v-else-if="props.column.field === 'totalStars'"
            v-bind:class="props.row.totalStars !== '' ? 'rating' : 'hide'"
          >
            <b-img
              v-if="props.row.totalStars !== '' && props.row.totalStars !== 0"
              src="@/assets/images/raty/star-on-2.png"
              class="star_img"
            />
            <b-img
              v-if="props.row.totalStars == '' && props.row.totalStars == 0"
              src="@/assets/images/raty/star-half-2.png"
              class="star_img"
            />
            {{ props.row.totalStars }}
          </span>
          <div
            v-else-if="props.column.field === 'usedCredits'"
            class="wrap-break-word"           
          >
          <span v-if="props.row.usedCredits!==0">
          {{props.row.usedCredits}} Credits
          </span>
          <span v-else style="color:red">
            Trial Booking
          </span>
          </div>
          <span
              v-else-if="props.column.field === 'action'"
              class="action_field action_field1513"
            >
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  v-if="props.row.userStatus !== 2"
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                   <b-dropdown-item>
                    <span @click="ConfirmBooking(props.row.id)">
                      <feather-icon icon="CheckSquareIcon" size="18" />

                      Confirm</span
                    >
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <span v-b-modal.modal-reject-booking @click="selctedData = props.row">
                      <feather-icon icon="XCircleIcon" size="18" />
                      Reject</span
                    >
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>       
        </template>
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> 
                  <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to 
                  <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                  <span v-else>{{props.total}} of {{ props.total }} entries</span>
                </span>

                    <div class="d-flex align-items-center mb-1 mt-0 showentry">
                      <span>Show</span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10', '20', '30', '40', '50']"
                        class="mx-1"
                        @input="
                          (value) => props.perPageChanged({ currentPerPage: value })
                        "
                     />
                      <span>entries</span>
                    </div>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :current-page="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => {
                      props.pageChanged({ currentPage: value });
                      currentPage = value
                    }
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
        </template>
      </vue-good-table>
    </div>    
  </div>
    <b-modal
        id="modal-reject-booking"
        ref="my-modal"
        title="Rejection Reason"
        ok-title="Reject"
        data-class="addcredit_popup"
        data-value-class="addcredit_popup"
        centered
        cancel-variant="outline-secondary"
        @show="cancelbookingresetModal"
        @hidden="cancelbookingresetModal"
        @ok="cancelbookinghandleOk"
      >
      <validation-observer ref="formCancel">
        <b-form ref="form" @submit.stop.prevent="cancelbookinghandleSubmit">
            <b-form-group class="radioselected" label-for="usertype">
              <b-form-radio
                v-model="RadioSelected"
                name="some-radios"
                value="Not interested in this Session"
              >
                Not interested in this Session
              </b-form-radio>
              <b-form-radio
                v-model="RadioSelected"
                name="some-radios"
                value="Not available"
              >
                Not available
              </b-form-radio>
              <b-form-radio
                v-model="RadioSelected"
                name="some-radios"
                value="Medical Emergency"
              >
                Medical Emergency
              </b-form-radio>
              <b-form-radio
                v-model="RadioSelected"
                name="some-radios"
                value="Family situation"
              >
                Family situation
              </b-form-radio>
              <b-form-radio
                v-model="RadioSelected"
                name="some-radios"
                value="Other"
              >
                Other
              </b-form-radio>
            </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="reason for rejection"
            rules="required|min:3|max:300"
            v-if="RadioSelected === 'Other'"
          >
            <b-form-group
              :state="nameState"
              label-for="name-input"
              invalid-feedback="Reason for rejection is required"
              v-if="RadioSelected === 'Other'"
            >
            <label style="font-weight:600;">Reason for Rejection</label>
              <b-form-textarea
                id="name-input"
                v-model="cancelReason"
                :state="nameState"
                placeholder="Enter Reason For Rejection"
                required
              />
              <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  BSpinner,
  BFormRadio,
  BCardText,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import {errorEmail} from "@/utils/apiRequests.js"
import { mapActions, mapGetters, mapMutations } from "vuex";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import * as insertFirebase from "@/utils/FirebaseQueries/insertQueries/insertQueries.js";
import { dbCollections } from '@/utils/firebaseCollection';
import { adminBookingNotification } from './service.js';
import { onBookingCancelUpdateSlot } from '@/utils/globalFunction/updateSlotWhenCancelBookingService.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import firebase from "@/utils/firebaseInit"
import moment from 'moment';
import axios from "axios";
const db = firebase.firestore();
const currentFileName = "@/views/SessionsManagement/pastSession(s)/pastSessionList.vue"
export default {
    name:'pendingSessionList',
    directives: {
        Ripple
  },
  components: {
    VueGoodTable,
    BCard,
    BImg,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BSpinner,
    BFormRadio,
    BCardText,
    ValidationProvider, ValidationObserver,
  },
  data() {
    return {
      isSpinner: false,
      isUpdating: false,
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      currentPage: 1,    
      pageLength: 10,
      reviewmanagementsearchTerm: "",
      pendingSessionColumns: [
        {
          label: "Booking ID",
          field: "bookingId",
          width: '170px'
        },
        {
          label: "Session Booking Date & Time",
          field: "sessionDate",
        },
        {
          label: "Session(s) Title",
          field: "sessionName",
        },
        {
          label: "Tutor Name",
          field: "tutorFullName",
        },
        {
          label: "Learner Name",
          field: "learnerFullName",
        },
        {
          label: "Booking Credits",
          field: "usedCredits",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      reviewmanagementsrows: [],
      rows:[],
      readMore: false,
      tutor_name_options: [{ value: null, text: "All" }],
      learner_name_options: [{ value: null, text: "All" }], 
      selectedUser: "",
      byTutorFiltered: "All",
      learner_selected: "All",
      finalPastBookingData:[],
      isProcessed:false,
      RadioSelected: "Not interested in this Session",
      cancelReason:"",
      selctedData:null,
      tutorNameSelected:"All"
    };
  },
  computed: {
    ...mapGetters({
      'getPendingBookingList' : 'booking/getPendingBooking'
    }),
    rowData(){
        try {
            var self = this;
            let rows = [];
            self.getPendingBookingList.forEach((booking) =>{
                let obj = booking; 
                obj.sessionDate = (booking.startTime && booking.endTime) ? self.sessionTimeCalculate(booking.startTime,booking.endTime)  : ""
                obj.sessionName = booking.sessionDetails && booking.sessionDetails.name ? booking.sessionDetails.name : '';
                obj.learnerFullName= booking.learnerDetails && booking.learnerDetails.firstName ? booking.learnerDetails.firstName + " " +booking.learnerDetails.lastName : "" ;
                obj.tutorFullName= booking.tutorDetails && booking.tutorDetails.firstName ? booking.tutorDetails.firstName + " " +booking.tutorDetails.lastName: "" ;

                rows.push(obj);
            });
            return rows;
        } catch (error) {
            console.error(error);
            errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "rowData",
                errorMessage : error.message
            },(mailRes) => {
                if(mailRes.error){
                    console.error(mailRes);
                }
            })
            return [];
        }
    },
    learnerNameOptions(){
        try {
            var self = this;
            let learnerOption = []
            learnerOption.push({text: "All" , value: null})
            self.getPendingBookingList.forEach(booking => {
                if(booking.learnerDetails && booking.learnerDetails.firstName) {
                    let index = learnerOption.findIndex((data) => {
                        return data.value == booking.learnerId
                    })
                    if(index < 0) {
                        learnerOption.push({
                            text: `${booking.learnerDetails.firstName} ${booking.learnerDetails.lastName}`,
                            value: booking.learnerId
                        });
                    }
                }
            });
            return learnerOption
        } catch (error) {
            console.error(error);
            errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "learnerNameOptions",
                errorMessage : error.message
            },(mailRes) => {
                if(mailRes.error){
                    console.error(mailRes);
                }
            })
            return [];
        }
    },
    tutorNameOptions() {
        try {
            var self = this;
            let tutorOption = []
            tutorOption.push({text: "All" , value: null})
            self.getPendingBookingList.forEach(booking => {
                if(booking.tutorDetails && booking.tutorDetails.firstName) {
                    let index = tutorOption.findIndex((data) => {
                        return data.value == booking.tutor
                    })
                    if(index < 0) {
                        tutorOption.push({
                            text: `${booking.tutorDetails.firstName} ${booking.tutorDetails.lastName}`,
                            value: booking.tutor
                        })
                    }
                }
            });
            return tutorOption
        } catch (error) {
            console.error(error);
            errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "tutorNameOptions",
                errorMessage : error.message
            },(mailRes) => {
                if(mailRes.error){
                    console.error(mailRes);
                }
            })
            return [];
        }
    },
    filterRows() {
        try {
            var self = this;
            var listA = self.rowData;
            if (self.learner_selected == "All" && self.tutorNameSelected == "All" ) {
                return listA;
            }
            if (self.learner_selected != "All") {
                listA = listA.filter((data) => {
                    return data.learnerFullName.toLowerCase().includes(self.learner_selected.toString().toLowerCase());
                });
            }
            if (self.tutorNameSelected != "All") {
                listA = listA.filter((dataTutor) => {
                    return dataTutor.tutorFullName.toLowerCase().includes(self.tutorNameSelected.toString().toLowerCase());
                });
            }
            return listA;
        } catch (error) {
            console.error(error)
            errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "filterRows",
                errorMessage : error.message
            },(mailRes) => {
                if(mailRes.error){
                    console.error(mailRes);
                }
            })
        }
    },
  },
  created() {
    var self = this;
    self.isSpinner = true;
    if(self.getPendingBookings.length === 0) {
      self.getPendingBookings().then((res) => {
          console.log("found!" , res);
          self.isSpinner = false;
      }).catch((error) => {
          console.error(error);
          self.isSpinner = false;
      });
    } else {
        self.isSpinner = false;
    }
  },
  methods: {
    onCellClick(data) {
      if (data.column.field==="learnerFullName") {
            this.$router.push({name: 'user-details', params: { id: data.row.learnerId}})
        } else if (data.column.field==="tutorFullName") {
            this.$router.push({  name: 'user-details', params: { id: data.row.tutor} ,})
        }  else if (data.column.field ==="action") {
          return;
        }
        else {
          this.$router.push({ name: 'booking-detail-pending', params: { id: data.row.id}})
        }
    },
    ...mapActions({
      'getPendingBookings' : 'booking/getPendingBookingData'
    }),
    ...mapMutations({
      'setRejectSessionData' : 'booking/setRejectedBookigData',
      'setupComingSessionArray' : 'upComingSession/setupComingSessionArray'
    }),
    sessionTimeCalculate(startTime , endTime) {
        if (moment(startTime.seconds*1000).format("DD-MM-YYYY") == moment(endTime.seconds*1000).format("DD-MM-YYYY")) {
          return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("hh:mm A")}`
        } else {
          return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")}`
        }
    },
    ConfirmBooking(id){
      try {
        var self = this;
        self.$bvModal
        .msgBoxConfirm('Are you sure you want to confirm this booking request?', {
          title: 'Confirm Booking Request',         
          class:'test',
          okVariant: 'primary',
          okTitle: 'Confirm',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
          footerClass: 'approve-footer',
          headerClass: 'approve-header',
          bodyClass: 'approve-body approverequest',
        })
        .then(value => {
          if(value){
            self.isSpinner = true;
            const updateObject = {
              status :1,
              isApprovedByTutor:true,
              isApprovedByLearner:true
            }
            updateFirebase.updateRootCollectionDataByDocId(dbCollections.BOOKING,id,updateObject,() => {
              self.$root.$emit('showToastMessage','Booking confirmed successfully.','success');
              self.isSpinner = false;
              
              var index = self.getPendingBookingList.findIndex(item => {
                return item.id == id
              })
              adminBookingNotification({
                bookingData : {...self.getPendingBookingList[index], status:1},
              }, () => {});
              if(index != -1){
                  self.getPendingBookingList.splice(index, 1);
                  self.setupComingSessionArray([]);
              }
            });
          }
        })
      } catch (error) {
        console.error(error);
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "dataDisplay",
          errorMessage : err.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        });
      }
    },
    jwtApiCall(){

    },
    cancelbookingresetModal() {
      this.name = "";
      this.nameState = null;
    },
    cancelbookinghandleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.cancelbookinghandleSubmit(bvModalEvt);   
    },
    cancelbookinghandleSubmit() {
      try {
        this.$refs.formCancel.validate().then(async(valid)=>{
        if(valid){
          this.$bvModal.hide("modal-reject-booking");
          this.isSpinner = true;
          try {
            await db.collection(dbCollections.CREDITTRANSACTIONS)
                    .where("userId","==",this.selctedData.learnerId)
                    .orderBy("createdAt", "desc")
                    .limit(1)
                    .get()
                    .then(async(docData) => {
                      if(docData.empty){
                      }else{
                          let sengleBookingData = docData.docs[0].data();                                         
                          const updateObj = {
                            currentCredit : Number(sengleBookingData.availableCredit) + Number(this.selctedData.usedCredits)
                          }
                          await updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,this.selctedData.learnerId,updateObj,async(res) => {
                            if(!res){
                              return;
                            }
                            const learnerDeatil = {
                              firstName : this.selctedData.learnerDetails.firstName,
                              lastName :this.selctedData.learnerDetails.lastName,
                              profileImage: this.selctedData.learnerDetails.profileImage
                            }
                            const transactionObject = {
                              availableCredit:Number(sengleBookingData.availableCredit) + Number(this.selctedData.usedCredits),
                              bookingId:this.selctedData.bookingId,
                              createdAt:new Date(),
                              credits:Number(this.selctedData.usedCredits),
                              id:'',
                              paymentId:'',// This feild is static
                              reference:1,
                              remarks:'Booking cancelled.',
                              transactionType:"cr",
                              userDetail:learnerDeatil,
                              userId: this.selctedData.learnerId,
                              isSendNotification: false
                            }
                            insertFirebase.addDataToRootCollection(dbCollections.CREDITTRANSACTIONS,transactionObject,async(credit) => {
                              if(credit){
                                const updateObjCredit = {
                                  id : credit.id
                                }
                                await updateFirebase.updateRootCollectionDataByDocId(dbCollections.CREDITTRANSACTIONS,credit.id,updateObjCredit,async(creditUpdate) => {
                                    const cancelreasondetail = {
                                    cancellationReason: this.RadioSelected === "Other"? this.cancelReason : this.RadioSelected,
                                    status: 2,
                                    cancellationDate: new Date(),
                                    updatedAt: new Date(),
                                    usedCredits: 0
                                  };
                                  updateFirebase.updateRootCollectionDataByDocId(
                                    dbCollections.BOOKING,
                                    this.selctedData.id,
                                    cancelreasondetail,
                                    async(res) => {
                                      if(!res){
                                        return;
                                      }
                                      let index = this.getPendingBookingList.findIndex((data) =>{
                                          return data.id == this.selctedData.id
                                      });
                                        onBookingCancelUpdateSlot({
                                          bookingId:  this.selctedData.id
                                        },() => {})
                                          adminBookingNotification({
                                            bookingData : {...this.getPendingBookingList[index], status:2, cancellationReason: this.RadioSelected === "Other"? this.cancelReason : this.RadioSelected,},
                                          }, () => {});
                                          if(index > -1){
                                              this.getPendingBookingList.splice(index , 1);
                                              this.setRejectSessionData([]);
                                          }
                                          this.$root.$emit(
                                            "showToastMessage",
                                            "Rejected session successfully.",
                                            "success"
                                          );
                                      this.isSpinner = false;
                                    });
                                })
                              }
                            })
                          })  
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    })
            } catch (error) {
              console.error("error", error);
                errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "cancelbookinghandleSubmit",
                errorMessage : error.message
              },(mailRes) => {
                  if(mailRes.error){
                      console.error(mailRes);
                  }
              })
              this.isUpdating = false
            }
          }
        })
      } catch (error) {
        console.error("error",error)
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "cancelbookinghandleSubmit",
          errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
      }
    },
  },
}
</script>

<style>

</style>