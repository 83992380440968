<template>
  <div class="dashboard">
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-if="!isSpinner">
    <div class="row match-height statistics">
      <div class="col-lg-2 col-6">
        <b-spinner
          variant="primary"
          v-if="totalRevenueData.length<6"
          large
          class="pageLoadSpinners stasticgraph"
        />
        <card-statistic-profit-chart
          v-if="totalRevenueData.length>=6"
          :chartName="'Total Revenue'"
          :total="`${currency} ${getRevanueData.totalRevenue!== undefined ? Math.floor(getRevanueData.totalRevenue) : ''}`"
          :data="totalRevenueData"
          :isChange="isChangeStasticRevanue"
          :changeDone="stasticRevanueDone()"
        />
      </div>
      <div class="col-lg-2 col-6">
        <b-spinner
          variant="primary"
          v-if="totalProfitData.length<6"
          large
          class="pageLoadSpinners stasticgraph"
        />
        <card-statistic-profit-chart
          v-if="totalProfitData.length>=6"
          ref="chartName"
          :chartName="'Total Profit'"
          :data="totalProfitData"
          :isChange="isChangeStasticProfit"
          :total="`${currency} ${getRevanueData.totalProfit!== undefined ?  Math.floor(getRevanueData.totalProfit) : ''}`"
          :changeDone="stasticRevanueDone()"
        />
      </div>
      <div class="col-lg-8 col-12">
        <div class="card card-statistics">
          <div class="text-right days_months">
            <b-dropdown :text="today_selected" variant="flat-secondary" right>
              <b-dropdown-item @click="today_selected = 'Today'"
                >Today</b-dropdown-item
              >
              <b-dropdown-item
                @click="(today_selected = 'This Week'), getWeekData()"
                >This Week</b-dropdown-item
              >
            </b-dropdown>
          </div>
          <div class="card-body statistics-body statistics_line">
            <div class="row" v-if="today_selected == 'Today'">
              <div class="mb-2 mb-md-0 col-sm-6 col-md-3 mb-2 mb-xl-0">
                <div class="media">
                  <div class="">
                    <div class="blue_bg round_bg tutorline_dashboard">
                      <feather-icon
                        style="color: #00cfe8"
                        icon="MessageCircleIcon"
                        size="20"
                      />
                    </div>
                  </div>
                  <div class="media-body my-auto text-center">
                    <h4 class="font-weight-bolder mb-0 pt-2">
                      {{ total_current_tutor_request }}
                    </h4>
                    <p class="card-text font-small-4 mb-0">
                      Current Online Session(s)
                    </p>
                  </div>
                </div>
              </div>
              <div class="mb-2 mb-md-0 col-sm-6 col-md-3 mb-2 mb-xl-0">
                <div class="media">
                  <div class="">
                    <div class="orange_bg round_bg tutorline_dashboard">
                      <feather-icon
                        style="color: #ff9f43"
                        icon="CalendarIcon"
                        size="20"
                      />
                    </div>
                  </div>
                  <div class="media-body my-auto text-center">
                    <h4 class="font-weight-bolder mb-0 pt-2">
                      {{ total_upcoming_tutor_request }}
                    </h4>
                    <p class="card-text font-small-4 mb-0">
                      Today Upcoming Session(s)
                    </p>
                  </div>
                </div>
              </div>
              <div class="mb-2 mb-md-0 col-sm-6 col-md-3 mb-2 mb-sm-0">
                <div class="media">
                  <div class="">
                    <div class="purple_bg round_bg tutorline_dashboard">
                      <feather-icon
                        style="color: #7367f0"
                        icon="UserPlusIcon"
                        size="20"
                      />
                    </div>
                  </div>
                  <div class="media-body my-auto text-center">
                    <h4 class="font-weight-bolder mb-0 pt-2">
                      {{ today_new_user }}
                    </h4>
                    <p class="card-text font-small-4 mb-0">
                      Today No. of New User
                    </p>
                  </div>
                </div>
              </div>
              <div class="mb-2 mb-md-0 col-sm-6 col-md-3">
                <div class="media">
                  <div class="">
                    <div class="green_bg round_bg tutorline_dashboard">
                      <feather-icon
                        style="color: #28c76f"
                        icon="DollarSignIcon"
                        size="20"
                      />
                    </div>
                  </div>
                  <div class="media-body my-auto text-center">
                    <h4 class="font-weight-bolder mb-0 pt-2">{{currency}}{{today_palns_purchase}}</h4>
                    <p class="card-text font-small-4 mb-0">
                      Today Plans Bought
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="today_selected == 'This Week'">
              <div class="mb-2 mb-md-0 col-sm-4 col-md-4 mb-2 mb-xl-0">
                <div class="media">
                  <div class="">
                    <div class="orange_bg round_bg tutorline_dashboard">
                      <feather-icon
                        style="color: #ff9f43"
                        icon="CalendarIcon"
                        size="20"
                      />
                    </div>
                  </div>
                  <div class="media-body my-auto text-center">
                    <h4 class="font-weight-bolder mb-0 pt-2">
                      {{ total_past_tutor_request }}
                    </h4>
                    <p class="card-text font-small-4 mb-0">
                      Past Upcoming Session(s)
                    </p>
                  </div>
                </div>
              </div>
              <div class="mb-2 mb-md-0 col-sm-4 col-md-4 mb-2 mb-xl-0">
                <div class="media">
                  <div class="">
                    <div class="purple_bg round_bg tutorline_dashboard">
                      <feather-icon
                        style="color: #7367f0"
                        icon="UserPlusIcon"
                        size="20"
                      />
                    </div>
                  </div>
                  <div class="media-body my-auto text-center">
                    <h4 class="font-weight-bolder mb-0 pt-2">
                      {{ past_new_user }}
                    </h4>
                    <p class="card-text font-small-4 mb-0">
                      Past No. of New User
                    </p>
                  </div>
                </div>
              </div>
              <div class="mb-2 mb-md-0 col-sm-4 col-md-4 mb-2 mb-xl-0">
                <div class="media">
                  <div class="">
                    <div class="green_bg round_bg tutorline_dashboard">
                      <feather-icon
                        style="color: #28c76f"
                        icon="DollarSignIcon"
                        size="20"
                      />
                    </div>
                  </div>
                  <div class="media-body my-auto text-center">
                    <h4 class="font-weight-bolder mb-0 pt-2">{{currency}}{{past_palns_Bought}}</h4>
                    <p class="card-text font-small-4 mb-0">Past Plans Bought</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-row class="match-height">
      <b-col md="4">
        <b-row class="match-height">
          <b-col md="6">
            <b-card class="doc_box_tabs_main extra_class">
              <b-row>
                <b-col md="12">
                  <div class="text-center tutorline_dashboard">
                    <div class="blue_bg round_bg">
                      <feather-icon
                        style="color: #00cfe8"
                        icon="AwardIcon"
                        size="20"
                      />
                    </div>
                    <div class="doc_text_info">
                      <span class="doc_no mb-0 pt-2">{{
                        DisplayTotalReviewRequest
                      }}</span>
                      <span class="doc_title mb-0 pt-1 d-block"
                        >Total No. of Review Request</span
                      >
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card class="doc_box_tabs_main extra_class">
              <b-row>
                <b-col md="12">
                  <div class="text-center tutorline_dashboard">
                    <div class="orange_bg round_bg">
                      <feather-icon
                        style="color: #ff9f43"
                        icon="DollarSignIcon"
                        size="20"
                      />
                    </div>
                    <div class="doc_text_info">
                      <span class="doc_no mb-0 pt-2"
                        >{{currency}} {{getRevanueData.totalExpense!== undefined?  Math.floor(getRevanueData.totalExpense) : ''}}</span
                      >
                      <span class="doc_title mb-0 pt-1 d-block">Total Expense</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col md="6">
            <b-card class="doc_box_tabs_main total_pending">
              <b-row>
                <b-col md="12">
                  <div class="text-center tutorline_dashboard">
                    <div class="purple_bg round_bg">
                      <feather-icon
                        style="color: #7367f0"
                        icon="UserPlusIcon"
                        size="20"
                      />
                    </div>
                    <div class="doc_text_info">
                      <span class="doc_no mb-0 pt-2">{{
                        DisplayTotalPendingTutorRequest
                      }}</span>
                      <span class="doc_title mb-0 pt-1 d-block"
                        >Total No. of Pending Tutor Request</span
                      >
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col md="6">
            <b-card class="doc_box_tabs_main total_pending">
              <b-row>
                <b-col md="12">
                  <div class="text-center tutorline_dashboard">
                    <div class="green_bg round_bg">
                      <feather-icon
                        style="color: #28c76f"
                        icon="XCircleIcon"
                        size="20"
                      />
                    </div>
                    <div class="doc_text_info">
                      <span class="doc_no mb-0 pt-2">{{
                        DisplayTotalBlockAccount
                      }}</span>
                      <span class="doc_title mb-0 pt-1 d-block"
                        >Total No. of Block Account</span
                      >
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="4">
         <b-spinner
          variant="primary"
          v-if="isTotalUser"
          large
          class="pageLoadSpinners"
        />
        <div v-if="!isTotalUser" class="totalUserDashboard">
        <ApexDonutChart :chartName="'Total Users'" :options="totalUsersChart1Options" :series="totalUsersChart1Series"/>
        </div>
      </b-col>
      <b-col md="4">
         <b-spinner
          variant="primary"
          v-if="isTotalTutor"
          large
          class="pageLoadSpinners"
        />
        <div v-if="!isTotalTutor">
        <ApexDonutChart :chartName="'Total Tutors'" :options="totalUsersChart2Options" :series="totalUsersChart2Series"/>
        </div>
      </b-col>
    </b-row>
  
    <b-row>
      <b-col md="6">
        <b-spinner
          variant="primary"
          v-if="isLoading"
          large
          class="pageLoadSpinners"
        />
        <b-card no-body v-if="!isLoading" class="custome_chart">
        
              <div class="graphHeader">
                <b-card-title class="mb-1">
                  Revenue
                </b-card-title>
                <div class="d-flex align-items-center" >
                    <feather-icon
                    icon="CalendarIcon"
                    size="16"
                    />
                    <date-range-picker
                        ref="picker"
                         opens="left"
                        v-model="dateRange"
                        :singleDatePicker="singleDatePicker"
                        :show-dropdowns="true"
                        @toggle="toggleDateRangePickerRevanue($event)"
                        @start-selection="daterangePickerRevanue($event)"
                        :dateFormat="dateFormatRevanue"
                        @update="selectRevanue($event)"
                        :always-show-calendars="false"
                        :ranges="revanueRange"
                        >
                    </date-range-picker>
                </div>
              </div>
    
        <chartjs-line-chart :chartName="'Revenue'" :chartData="lineChartdata" :isChange="isChangeChart" :changeDone="changeDoneChart" :chartOption="lineChartOption.options"/>
        </b-card>
      </b-col>
      <b-col md="6">
         <b-spinner
          variant="primary"
          v-if="isLoadingPlan"
          large
          class="pageLoadSpinners"
        />
        <b-card no-body v-if="!isLoadingPlan" class="custome_chart">
          <div class="graphHeader">
                <b-card-title class="mb-1 plancount">
                  Plans Bought
                  <div class="plansBought" style="color:#28C76F;">{{planPurchase}}</div>
                </b-card-title>
               
                <div class="d-flex align-items-center" >
                    <feather-icon
                    icon="CalendarIcon"
                    size="16"
                    />
                    <date-range-picker
                      ref="picker"
                      v-model="dateRangePlan"
                        opens="left"
                        :singleDatePicker="singleDatePicker"
                        :show-dropdowns="true"
                        @toggle="toggleDateRangePickerPlane($event)"
                        @start-selection="daterangePickerPlan($event)"
                        :dateFormat="dateFormatPlan"
                        @update="selectPlan($event)"
                        :always-show-calendars="false"
                        :ranges="planRange"
                    >
                    </date-range-picker>
                </div>
          </div>
         <chartjs-line-chart :chartName="'Plans Bought'" :chartData="lineChartPlansdata" :isChange="isPlanChangeChart" :chartOption="lineChartPlanOption.options" :changeDone="changePlanDoneChart"/>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-spinner
          variant="primary"
          v-if="isLoadingActiveUser"
          large
          class="pageLoadSpinners"
        />
         <b-card no-body v-if="!isLoadingActiveUser" class="custome_chart">
          <div class="graphHeader">
              <b-card-title class="mb-1">
                  Active User
                </b-card-title>
          <div class="d-flex align-items-center">
              <feather-icon
              icon="CalendarIcon"
              size="16"
              />
              <date-range-picker
                  ref="picker"
                   opens="left"
                  v-model="dateRangeActiveUser"
                  :singleDatePicker="singleDatePicker"
                  :show-dropdowns="true"
                  :ranges="activeUserRange"
                  @toggle="toggleDateRangePickerActiveUser($event)"
                  @start-selection="daterangePickerActiveUser($event)"
                  :dateFormat="dateFormatActiveUser"
                  @update="selectActiveUser($event)"
                  :always-show-calendars="false"
                  >
              </date-range-picker>
          </div>
          </div>
           <app-echart-bar
           v-if="renderComponent" 
            :option-data="option"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BTabs,
  BTab,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BCardText,
  BFormSelect,
  BFormDatepicker,
  BFormRadio,
  BFormFile,
  BBadge,
  BFormTextarea,
  BTable,
  BSpinner,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";

import flatPickr from "vue-flatpickr-component";
import ChartjsComponentBarChart from "./charts-components/ChartjsComponentBarChart.vue";
import CardStatisticProfitChart from "./charts-components/CardStatisticProfitChart.vue";
import ChartjsLineChart from "./charts-components/ChartjsLineChart.vue";
import EchartDoughnut from "./charts-components/EchartDoughnut.vue";
import ApexDonutChart from '@/views/Dashboard/charts-components/ApexDonutChart';
import AppEchartBar from '@/views/Dashboard/charts-components/AppEchart.vue'
import { mapGetters,mapActions } from "vuex";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import moment from "moment";
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import firebase from "@/utils/firebaseInit.js";
import { dbCollections } from "@/utils/firebaseCollection.js";
import index from "vue-prism-component";
const db = firebase.firestore();
const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  backgroundColor: '#ffffff',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}
import { $themeColors } from '@themeConfig'
export default {
  name: "dashboard",
  components: {
    BCard,
    BSpinner,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormSelect,
    BFormDatepicker,
    BFormRadio,
    BFormFile,
    BTabs,
    BTab,
    BBadge,
    BFormTextarea,
    BDropdown,
    BDropdownItem,
    BTable,
    BCardBody,
    BCardHeader,
    BCardTitle,
    flatPickr,
    ChartjsComponentBarChart,
    CardStatisticProfitChart,
    ChartjsLineChart,
    EchartDoughnut,
    DateRangePicker, 
    ApexDonutChart,
    AppEchartBar
  },
  data() {
    const today = new Date()
    let yesterday = new Date()
    yesterday.setDate(today.getDate() - 1)
    return {
      renderComponent: true,
      revanueDropDownSelect: "",
      revanueDropDownOption: ["Today","Yesterday","This Month","This Year","Range Select"],
      rangePicker: new Date(),
      dateRange: {
        startDate:new Date(today.getFullYear(), today.getMonth() , 1),
        endDate: new Date(today)
      },
      revanueRange:{
        'Today': [new Date(),new Date()],
        'Yesterday': [yesterday, yesterday],
        'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0),true],
        'This Month': [new Date(today.getFullYear(), today.getMonth() , 1), new Date(today)],
        'This year': [new Date(today.getFullYear(), 0, 1), new Date(today)],
      },
      planRange:{
        'Today': [new Date(),new Date()],
        'Yesterday': [yesterday, yesterday],
        'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0),true],
        'This Month': [new Date(today.getFullYear(), today.getMonth() , 1), new Date(today)],
        'This year': [new Date(today.getFullYear(), 0, 1), new Date(today)],
      },
      activeUserRange:{
        'Today': [new Date(),new Date()],
        'Yesterday': [yesterday, yesterday],
        'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0),true],
        'This Month': [new Date(today.getFullYear(), today.getMonth() , 1), new Date(today)],
        'This year': [new Date(today.getFullYear(), 0, 1), new Date(today)],
      },
      dateRangePlan: {
        startDate:new Date(today.getFullYear(), today.getMonth() , 1),
        endDate: new Date(today)
      },
      dateRangeActiveUser: {
        startDate:new Date(today.getFullYear(), today.getMonth() , 1),
        endDate: new Date(today),
      },
      singleDatePicker: "range",
      restaurants: 0,
      sommeliers: 0,
      tutorDetailType: [],
      total_expanse:'',
      isLoading: false,
      total_review_request: [],
      total_pending_tutor_request: [],
      total_block_account: [],
      total_current_tutor_request: 0,
      total_upcoming_tutor_request: 0,
      total_past_tutor_request: 0,
      total_new_user: 0,
      today_new_user: 0,
      today_palns_purchase: 0,
      past_palns_Bought: 0,
      isLoadingPlan: false,
      isLoadingActiveUser: false,
      totalMonthGet: [],
      past_new_user: 0,
      today_selected: "Today",
      revuneName: "Total Revune",
      recentWeek: [],
      totalRevenueData: [],
      totalProfitData: [],
      isChangeStasticRevanue:false,
      isChangeStasticProfit: false,
      isTotalUser: false,
      isTotalTutor: true,
      chartjsData: {
        data: [65, 59, 80, 81, 56, 55, 40],
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      },
      chart1: [
        {
          name: "Visit source",
          type: "pie",
          radius: ["50%", "80%"],
          avoidLabelOverlap: false,
          hasLegend: true,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: 1664, name: "Active Learner" },
            { value: 1200, name: "Idle Learner" },
            { value: 1300, name: "Active Tutor" },
            { value: 400, name: "Idle Tutor" },
            { value: 300, name: "Active Child" },
            { value: 150, name: "Idle Child" },
          ],
          color: ["#F080A4","#28DAC6","#FFA800","#7367F0","#28C76F","#FFED33",],
        },
      ],
       chart2: [
        {
          name: 'Tutor Type',
          type: 'pie',
          radius: ['50%', '70%'],
          avoidLabelOverlap: false,
           hasLegend: true,
            
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
          data: [
          ],
        options: {
                legend: {
                    display: true,
                }
        },
          color: ['#F080A4','#28DAC6','#FFA800','#7367F0','#28C76F','#FFED33','#13fc03','#030ffc','#fc03d7','#665f31',
            '#1dde64','#d11541','#f76605','#0f0f0f','#2b7328'
          ],
         },
         

      ],
      totalUsersChart1Options:{
        
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: ['#F080A4','#28DAC6','#FFA800','#7367F0','#28C76F','#FFED33','#13fc03','#030ffc','#fc03d7','#665f31',
            '#1dde64','#d11541','#f76605','#0f0f0f','#2b7328'
          ],
          dataLabels: {
            enabled: false,
            formatter(val) {
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1.5rem',
                    label: 'Total',
                  },
                },
              },
            },
          },
          
          labels: [],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                      
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
      },
      totalUsersChart1Series:[],
      totalUsersChart2Options:{
        
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          colors: ['#F080A4','#28DAC6','#FFA800','#7367F0','#28C76F','#FFED33','#13fc03','#030ffc','#fc03d7','#665f31',
            '#1dde64','#d11541','#f76605','#0f0f0f','#2b7328'
          ],
          dataLabels: {
            enabled: false,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: '2rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                    fontSize: '1.5rem',
                    label: 'Total',
                  },
                },
              },
            },
          },
          
          labels: [],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: '1.5rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                      
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
      },
      totalUsersChart2Series:[],
      totalNo: 0,
      count: 0,
      countNumber:0,
      generalType:[],
      dataOfTutor:[],
      userSnapshot: null,
      bookingSnapshot:null,
      reviewSnapshot:null,
      isSpinner: false,
      disabledDate: null,
      disabledDatePlans: null,
      disabledActiveUserDate: null,
      totalDate : [],
      totalMonth: [],
      totalYear: [],
      planPurchase: 0,
      lineChartOption:{
        options: {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: false,
          hover: {
            mode: 'label',
          },
          tooltips: {
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor:'#000',
            titleFontColor: '#fff',
            bodyFontColor: $themeColors.black,
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  stepSize: 50000,
                  min: 0,
                  max: 100000,
                  fontColor: chartColors.labelColor,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
              },
            ],
          },
          legend: {
            position: 'top',
            align: 'start',
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
        },
      },
      lineChartdata: {
        labels: [],
        datasets: [
          {
            data: [],
            label: 'Revenue',
            borderColor: chartColors.lineChartDanger,
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: chartColors.lineChartDanger,
            fill: false,
            pointRadius: 2,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: chartColors.lineChartDanger,
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
          {
            data: [],
            label: 'Expense',
            borderColor: chartColors.lineChartPrimary,
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: chartColors.lineChartPrimary,
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: chartColors.lineChartPrimary,
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
          {
            data: [],
            label: 'Profit',
            borderColor: chartColors.warningColorShade,
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: chartColors.warningColorShade,
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: chartColors.warningColorShade,
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
        ],
      },
      lineChartPlanOption:{
        options: {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: false,
          hover: {
            mode: 'label',
          },
          tooltips: {
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor:'#000',
            titleFontColor: '#fff',
            bodyFontColor: $themeColors.black,
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  stepSize: 50000,
                  min: 0,
                  max: 100000,
                  fontColor: chartColors.labelColor,
                },
                gridLines: {
                  display: true,
                  color: chartColors.grid_line_color,
                  zeroLineColor: chartColors.grid_line_color,
                },
              },
            ],
          },
          legend: {
            position: 'top',
            align: 'start',
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
        },
      },
      lineChartPlansdata: {
        labels: [],
        datasets: [
        ],
      },
      lineChartActiveUserdata: {
        labels: [],
        datasets: [
          {
            data: [800, 1500, 1800, 2070, 2100, 1600, 1600, 2002, 2605, 2100, 2700, 2505, 2900, 3600, 3705],
            label: 'Europe',
            borderColor: chartColors.lineChartDanger,
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: chartColors.lineChartDanger,
            fill: false,
            pointRadius: 2,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: chartColors.lineChartDanger,
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
          {
            data: [800, 1250, 1050, 1300, 2150, 1950, 1400, 1600, 2300, 3000, 2200, 1700, 2100, 2000, 2800],
            label: 'Asia',
            borderColor: chartColors.lineChartPrimary,
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: chartColors.lineChartPrimary,
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: chartColors.lineChartPrimary,
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
          {
            data: [800, 909, 802, 900, 1105, 1105, 704, 705, 1300, 1505, 1205, 900, 1400, 1300, 1080],
            label: 'Africa',
            borderColor: chartColors.warningColorShade,
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: chartColors.warningColorShade,
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBorderColor: $themeColors.white,
            pointHoverBackgroundColor: chartColors.warningColorShade,
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
        ],
      },
      option: {
        xAxis: [
          {
            type: 'category',
            data: [],
          },
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: { show: false },
          },
        ],
        grid: {
          left: '40px',
          right: '30px',
          bottom: '30px',
        },
        series: [
          {
            name: 'Learner',
            type: 'bar',
            stack: 'advertising',
            data: [],
            barMaxWidth: '20%',
            barMinWidth: '10px',
          },
          {
            name: 'Tutor',
            type: 'bar',
            stack: 'advertising',
            data: [],
            barMaxWidth: '20%',
            barMinWidth: '10px',
          },
          {
            name: 'Child',
            type: 'bar',
            stack: 'advertising',
            data: [],
            barMaxWidth: '20%',
            barMinWidth: '10px',
          },
        ],
      },
      isChangeChart: false,
      isPlanChangeChart: false,
      isActiveUserChange: false,
    };
  },
    computed: {
        ...mapGetters({
            getsettingList: "settings/getsettingArrayFeild",
            getUsersList: "userManagement/getUsers",
            getReviewsList: "reviews/getReviews",
            getRevanueData: 'RevanueCalculation/getRevanueCalc',
            getAmountTransection: 'RevanueCalculation/getAmountTransectionDate',
            getRevanueGraphData: 'RevanueCalculation/getRevanueStartGraph',
            getPlanGraphData: 'RevanueCalculation/getPlanStartGraph',
            getStasticGraphData: 'RevanueCalculation/getStasticGraph',
            getActiveUserGraphData: 'RevanueCalculation/getActiveUserStartGraph'
        }),
        currency(){
            if (Object.keys(this.getsettingList).length != 0) {

                return this.getsettingList.currency.slice(4)
            } else {
                return ""
            }
        },
        DisplayTotalReviewRequest() {
          return this.total_review_request.length
        },
        DisplayTotalPendingTutorRequest() {
          return this.total_pending_tutor_request.length
        },
        DisplayTotalBlockAccount() {
            return this.total_block_account.length
        },
    },
    created() {
        this.getTotalUsersChartData();
        this.revanueChaartAtStart();
        this.planChartAtStart();
        this.statsticChartAtStart();
        this.todayPlanPurchase();
        this.pastPlanBought();
        this.activeUserChartAtStart();
        this.isSpinner = true
        let settingMangFound = Object.keys(this.getsettingList).length != 0;
        if (!settingMangFound) {
        this.getsettingArray().then((res)=>{
                this.toatlno();
                this.totalType();
                this.totalFilter();
        }).catch(error=>{
            console.error("error",error)
        })
        }else{
            this.toatlno();
            this.totalType();
            this.totalFilter();
        }
        this.userSnapshot = db.collection(dbCollections.USERS).onSnapshot((snapshot) => {
            if (!snapshot.empty) {
                this.pendingTutorReq();
                this.blockAccount();
                this.todayNewUser();
                this.pastUser();
            }
        });
        

        this.bookingSnapshot = db.collection(dbCollections.BOOKING).onSnapshot((snapshot) => {
        if (!snapshot.empty) {
            this.currentOnlineSession();
            this.upcomingSession();
            this.pastUpcomingSession();
            }
        });

        this.reviewSnapshot = db.collection(dbCollections.REVIEWS).onSnapshot((snapshot) => {
        if (!snapshot.empty) {
            this.reviewRequest();
            }
        });
        this.isSpinner = false
    },
    beforeDestroy() {
        this.userSnapshot();
        this.reviewSnapshot();
        this.bookingSnapshot();
    },
    methods: {
        forceRerender() {
          this.renderComponent = false;

          this.$nextTick(() => {
          this.renderComponent = true;
        });
        },
         ...mapActions({
          'getRevanueIntialGraphData' : 'RevanueCalculation/getRevanueGraph',
          'getPlanInitialGraphData' : 'RevanueCalculation/getPlanGraph',
          'getActiveUserInitialGraphData' : 'RevanueCalculation/getActiveUserGraph',
          'getStasticInitialGraphData' : 'RevanueCalculation/getStatsticGraph'
         }),
         stasticRevanueDone(){
            this.isChangeStasticRevanue = false;
            this.isChangeStasticProfit = false;
         },
         todayPlanPurchase(){
          let date = moment(new Date()).format("YYYY-MM")
          db.collection(dbCollections.UPDATEGRAPHDATA).doc(date).get().then((revanue)=>{
            if(revanue.exists && Object.keys(revanue.data()).length){
              let ind = revanue.data().dateWiseData.findIndex((data)=>{
                return data.date == moment(new Date()).format("DD-MM")
              })
              if (ind > -1) {
                this.today_palns_purchase = (revanue.data() && revanue.data().dateWiseData[ind].revanue) ? revanue.data().dateWiseData[ind].revanue : 0
              }
            }
          })
         },
         pastPlanBought(){
           var pastplan1 = new Date(moment().startOf("week"));
          var pastplan2 = new Date(moment(Date.now()));
          this.totalDate = []
           let planCount = 0;
          this.getInbetweenDates(pastplan1,pastplan2)
          let count = 0;
          let countFun = (raw) => {
            if(count >= this.totalDate.length) {
              this.past_palns_Bought = planCount
              return;
            }
            else {
                let getDate = moment(raw).format("YYYY-MM")
                let getdate1 = moment(raw).format("DD-MM")
                db.collection(dbCollections.UPDATEGRAPHDATA).doc(getDate).collection("day").doc(getdate1).get().then((plans)=>{
                  if (plans.exists) {    
                    planCount += plans.data().planDetail.planCount
                    count++;
                    countFun(this.totalDate[count]);
                  } else {
                    count++;
                    countFun(this.totalDate[count]);
                  }
               }).catch(error=>{
                  console.error(error)
                  count++;
                  countFun(this.totalDate[count]);
               })
            }
          }
            countFun(this.totalDate[count]);
         },
        revanueChaartAtStart(){
          let graphDataFound = Object.keys(this.getRevanueGraphData).length != 0;
          if (!graphDataFound) {   
            this.isLoading = true;
            this.getRevanueIntialGraphData({startingDate:this.dateRange.startDate,endingDate:this.dateRange.endDate}).then((data)=>{
              let maximum = [Math.max(...this.getRevanueGraphData.revanue),Math.max(...this.getRevanueGraphData.expense),Math.max(...this.getRevanueGraphData.profit)]
              let maximumNumber = Math.max(...maximum)
              let RoundFigure = Math.ceil(maximumNumber / 1000) * 1000
              let step = Math.ceil(RoundFigure / 10)
              this.lineChartdata.labels = this.getRevanueGraphData.label
              this.lineChartdata.datasets[0].data = this.getRevanueGraphData.revanue
              this.lineChartdata.datasets[1].data = this.getRevanueGraphData.expense
              this.lineChartdata.datasets[2].data = this.getRevanueGraphData.profit
              this.lineChartOption.options.scales.yAxes[0].ticks.stepSize = step
               this.lineChartOption.options.scales.yAxes[0].ticks.max = RoundFigure
              this.isChangeChart = true;
              this.isLoading = false;
  
            }).catch(error=>{
              console.error(error)
            })
          } else {
              let maximum = [Math.max(...this.getRevanueGraphData.revanue),Math.max(...this.getRevanueGraphData.expense),Math.max(...this.getRevanueGraphData.profit)]
              let maximumNumber = Math.max(...maximum)
              let RoundFigure = Math.ceil(maximumNumber / 1000) * 1000
              let step = Math.ceil(RoundFigure / 10)
              this.lineChartdata.labels = this.getRevanueGraphData.label
              this.lineChartdata.datasets[0].data = this.getRevanueGraphData.revanue
              this.lineChartdata.datasets[1].data = this.getRevanueGraphData.expense
              this.lineChartdata.datasets[2].data = this.getRevanueGraphData.profit
              this.lineChartOption.options.scales.yAxes[0].ticks.stepSize = step
              this.lineChartOption.options.scales.yAxes[0].ticks.max = RoundFigure
              this.isChangeChart = true;
          }
        },
        statsticChartAtStart(){
          let graphDataFound = Object.keys(this.getStasticGraphData).length != 0;
          if (!graphDataFound) {
            this.getStasticInitialGraphData().then((resp)=>{
                  this.totalProfitData = resp.profit
                  this.totalRevenueData = resp.revanue
              this.isChangeStasticProfit = true;
              this.isChangeStasticRevanue = true;
            })
          } else {
                this.totalProfitData = this.getStasticGraphData.profit
                this.totalRevenueData = this.getStasticGraphData.revanue

              this.isChangeStasticProfit = true;
              this.isChangeStasticRevanue = true;
            }
        },
        planChartAtStart(){
          this.isLoadingPlan = true;
          let graphDataFound = Object.keys(this.getPlanGraphData).length != 0;
          if (!graphDataFound) {   
            this.getPlanInitialGraphData({startingDate:this.dateRangePlan.startDate,endingDate:this.dateRangePlan.endDate}).then((data)=>{
              
              this.lineChartPlanOption.options.scales.yAxes[0].ticks.stepSize = this.getPlanGraphData.step
              this.lineChartPlanOption.options.scales.yAxes[0].ticks.max = this.getPlanGraphData.RoundFigure
              this.lineChartPlansdata.labels = this.getPlanGraphData.labels
              this.lineChartPlansdata.datasets =  this.getPlanGraphData.data
              this.isPlanChangeChart = true;
              this.isLoadingPlan = false;
              this.planPurchase = this.getPlanGraphData.planCount
            }).catch(error=>{
              console.error(error)
            })
          } else {
              this.lineChartPlanOption.options.scales.yAxes[0].ticks.stepSize = this.getPlanGraphData.step
              this.lineChartPlanOption.options.scales.yAxes[0].ticks.max = this.getPlanGraphData.RoundFigure
              this.lineChartPlansdata.labels = this.getPlanGraphData.labels
              this.lineChartPlansdata.datasets =  this.getPlanGraphData.data
              this.isPlanChangeChart = true;
              this.isLoadingPlan = false;
          }
        },
        activeUserChartAtStart(){
          this.isLoadingActiveUser = true
          let graphDataFound = Object.keys(this.getActiveUserGraphData).length != 0;
          if (!graphDataFound) {   
            this.isLoading = true;
            this.getActiveUserInitialGraphData({startingDate:this.dateRangeActiveUser.startDate,endingDate:this.dateRangeActiveUser.endDate}).then((data)=>{
                this.option.series[0].data = this.getActiveUserGraphData.learner
                this.option.series[1].data = this.getActiveUserGraphData.tutor
                this.option.series[2].data = this.getActiveUserGraphData.child
                this.option.xAxis[0].data = this.getActiveUserGraphData.label
                this.forceRerender();
                this.isLoadingActiveUser = false;
            }).catch(error=>{
              console.error(error)
            })
          } else {
              this.option.series[0].data = this.getActiveUserGraphData.learner
              this.option.series[1].data = this.getActiveUserGraphData.tutor
              this.option.series[2].data = this.getActiveUserGraphData.child
              this.option.xAxis[0].data = this.getActiveUserGraphData.label
              this.forceRerender();
              this.isLoadingActiveUser = false;
          }
        },
        getInbetweenDates(startDate,endDate){
          this.totalDate = []
          startDate = new Date(startDate).setHours(0,0,0,0);
          endDate = new Date(endDate).setHours(0,0,0,0);
          if(startDate == endDate){
              let addDate = new Date(startDate)
              this.totalDate.push(addDate);
              this.totalDate.push(addDate);
          }else{
              while(startDate < endDate){
                  let addDate = new Date(startDate)
                  this.totalDate.push(addDate);
                  var newDate = moment(startDate).add(1,'days')
                  startDate = new Date(newDate).getTime();
              }
              let addDate = new Date(endDate)
              this.totalDate.push(addDate);
          }
        },
        getYearbetweemDate(startDate,endDate) {
          startDate = moment(startDate).format("YYYY")
          endDate = moment(endDate).format("YYYY")
          this.totalYear = []
           if(startDate == endDate){
              this.totalYear.push(startDate)
          }else{
              while(startDate < endDate){
                  this.totalYear.push(startDate)
                  var newDate = moment(startDate).add(1,'year')
                  let date =  newDate.toDate()
                  startDate = moment(date).format("YYYY")
              }
              this.totalYear.push(endDate)
          }
        },
        getMonthBetweenDate(from,to, getData, endData){
          let startMonth =new Date(getData).getTime();
          let Endmonth = new Date(endData).getTime();
          this.totalMonthGet = []
          if(startMonth == Endmonth){
              let datToAdd = moment(startMonth).format("YYYY-MM");
              this.totalMonthGet.push(datToAdd)
          }else{
              while(startMonth < Endmonth){
                  let datToAdd = moment(startMonth).format("YYYY-MM");
                  this.totalMonthGet.push(datToAdd)
                  var newDate = moment(startMonth).add(1,'month')
                  let date =  newDate.toDate();
                  startMonth = date.getTime();
              }
               let datToAddEnd = moment(Endmonth).format("YYYY-MM");
                this.totalMonthGet.push(datToAddEnd)
          }
          var monthNames = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
            this.totalMonth = [];
            var datFrom = new Date('1 ' + from);
            var datTo = new Date('1 ' + to);
            if(datFrom < datTo) {
              var month = datFrom.getMonth();
              var toMonth = datTo.getMonth() + 1 + ((datTo.getYear() - datFrom.getYear())*12); //toMonth adjusted for year
              for(; month < toMonth; month++) { //Slice around the corner...
                this.totalMonth.push(monthNames[month % 12]);
              }
            }
        },
        changeDoneChart() {
          this.isChangeChart = false;
        },
        selectRevanue(e){
          this.isLoading = true;
          let today = new Date()
          let startOfMonth = new Date(moment(today).startOf('month'));
          let endOfLastMonth = new Date(startOfMonth).setDate(new Date(startOfMonth).getDate() - 1);
          let startOfLastMonth = new Date(moment(endOfLastMonth).startOf('month'));
          let startDate = moment(e.startDate, "DD.MM.YYYY");
          let endDate = moment(e.endDate, "DD.MM.YYYY");
          let diffrence =  endDate.diff(startDate, 'days')
          if ((e.startDate).getTime() == startOfLastMonth.getTime() &&  (e.endDate).getTime() == endOfLastMonth) { // if previous month is selected
              let date = moment(e.startDate).format("YYYY-MM")
               db.collection(dbCollections.UPDATEGRAPHDATA).doc(date).get().then((revanue)=>{
                let resetArray = []
                revanue.data().dateWiseData.forEach((data)=>{
                  resetArray.push((data))
                })
                let array = []
                let data = []
                let expense = []
                let profit = []
                let count = 0;
                let countFun = (raw) => {
                    if (count === resetArray.length) {
                      let maximum = [Math.max(...data),Math.max(...expense),Math.max(...profit)]
                      let maximumNumber = Math.max(...maximum)
                      let RoundFigure = Math.ceil(maximumNumber / 1000) * 1000
                      let step = Math.ceil(RoundFigure / 10)
                      this.lineChartdata.labels = array
                      this.lineChartdata.datasets[0].data = data
                      this.lineChartdata.datasets[1].data = expense
                      this.lineChartdata.datasets[2].data = profit
                      this.lineChartOption.options.scales.yAxes[0].ticks.stepSize = step
                      this.lineChartOption.options.scales.yAxes[0].ticks.max = RoundFigure
                      this.isChangeChart = true
                      this.isLoading = false;
                      return;
                    }
                    array.push(raw.date)
                    data.push(raw.revanue)
                    expense.push(raw.expense)
                    profit.push(raw.profit)
                    count += 1;
                    countFun(resetArray[count]);
                  } 
                countFun(resetArray[count]);
               })
          }
          else if (diffrence < 31) {
            this.totalDate = []
            this.getInbetweenDates(new Date(e.startDate).getTime(), new Date(e.endDate).getTime())
            this.lineChartdata.labels = []
            let array = []
            let data = []
            let expense = []
            let profit = []
            let count = 0;
            let countFun = (raw) => {
                if (count === this.totalDate.length) {
                  let maximum = [Math.max(...data),Math.max(...expense),Math.max(...profit)]
                  let maximumNumber = Math.max(...maximum)
                  let RoundFigure = Math.ceil(maximumNumber / 1000) * 1000
                  let step = Math.ceil(RoundFigure / 10)
                  this.lineChartdata.labels = array
                  this.lineChartdata.datasets[0].data = data
                  this.lineChartdata.datasets[1].data = expense
                  this.lineChartdata.datasets[2].data = profit
                  this.lineChartOption.options.scales.yAxes[0].ticks.stepSize = step
                  this.lineChartOption.options.scales.yAxes[0].ticks.max = RoundFigure
                  this.isChangeChart = true
                  this.isLoading = false;
                  return;
                }
                 let date = moment(raw).format("DD-MM")
                 let month = moment(raw).format("YYYY-MM")
                 array.push(date)
                  db.collection(dbCollections.UPDATEGRAPHDATA).doc(month).collection("day").doc(date).get().then((revanue)=>{
                    data.push(revanue.data().revanue)
                    expense.push(revanue.data().expense)
                    profit.push(revanue.data().profit)
                    count+= 1;
                    countFun(this.totalDate[count]);
                  }).catch(error=>{
                      console.error(error);
                      count+= 1;
                      countFun(this.totalDate[count]);
                  })
            }
            countFun(this.totalDate[count]);
          } 
          else if(diffrence > 31 && diffrence < 365){
            let Startmonth = moment(e.startDate).format("MMMM YYYY")
            let Endmonth = moment(e.endDate).format("MMMM YYYY")
            this.totalMonth = []
            this.totalMonthGet = []
            this.getMonthBetweenDate(Startmonth, Endmonth, e.startDate, e.endDate)
            this.lineChartdata.labels = []
            let array = []
            let data = []
            let expense = []
            let profit = []
            this.totalMonth.forEach((data)=>{
              array.push(data)
            })
            this.lineChartdata.labels = array
            let count = 0;
            let countFun = (raw) => {
                if (count === this.totalMonthGet.length) {
                  let maximum = [Math.max(...data),Math.max(...expense),Math.max(...profit)]
                  let maximumNumber = Math.max(...maximum)
                  let RoundFigure = Math.ceil(maximumNumber / 1000) * 1000
                  let step = Math.ceil(RoundFigure / 10)
                  this.lineChartdata.datasets[0].data = data
                  this.lineChartdata.datasets[1].data = expense
                  this.lineChartdata.datasets[2].data = profit
                  this.lineChartOption.options.scales.yAxes[0].ticks.stepSize = step
                  this.lineChartOption.options.scales.yAxes[0].ticks.max = RoundFigure
                  this.isChangeChart = true
                  this.isLoading = false;
                  return;
                }
                  db.collection(dbCollections.UPDATEGRAPHDATA).doc(raw).get().then((revanue)=>{
                    data.push(revanue.data().revanue)
                    expense.push(revanue.data().expense)
                    profit.push(revanue.data().profit)
                    count+= 1;
                    countFun(this.totalMonthGet[count]);
                  }).catch(error=>{
                      console.error(error);
                      count+= 1;
                      countFun(this.totalMonthGet[count]);
                  })
            }
            countFun(this.totalMonthGet[count]);
          }
          else if (diffrence > 365) {
            this.totalYear = []
            this.getYearbetweemDate(e.startDate,e.endDate)
            this.lineChartdata.labels = []
            let array = []
            let data = []
            let expense = []
            let profit = []
            let count = 0;
            let countFun = (raw) => {
                if (count === this.totalYear.length) {
                  let maximum = [Math.max(...data),Math.max(...expense),Math.max(...profit)]
                  let maximumNumber = Math.max(...maximum)
                  let RoundFigure = Math.ceil(maximumNumber / 1000) * 1000
                  let step = Math.ceil(RoundFigure / 10)
                  this.lineChartdata.labels = array
                  this.lineChartdata.datasets[0].data = data
                  this.lineChartdata.datasets[1].data = expense
                  this.lineChartdata.datasets[2].data = profit
                  this.lineChartOption.options.scales.yAxes[0].ticks.stepSize = step
                  this.lineChartOption.options.scales.yAxes[0].ticks.max = RoundFigure
                  this.isChangeChart = true
                  this.isLoading = false;
                  return;
                }
                 array.push(raw)
                  db.collection(dbCollections.UPDATEGRAPHDATA).doc(raw).get().then((revanue)=>{
                    data.push(revanue.data().revanue)
                    expense.push(revanue.data().expense)
                    profit.push(revanue.data().profit)
                    count+= 1;
                    countFun(this.totalYear[count]);
                  }).catch(error=>{
                      console.error(error);
                      count+= 1;
                      countFun(this.totalYear[count]);
                  })
            }
            countFun(this.totalYear[count]);
          }
        },
        toggleDateRangePickerRevanue(e){
          if (e === false) {
            this.disabledDate = null
          }
        },
        daterangePickerRevanue(e){
          this.disabledDate = e       
        },
        dateFormatRevanue (classes, date) {
            if (!classes.disabled) {
              if (date.getTime() > new Date().setHours(12,0,0)) {
                classes.disabled = true;
              }
            }
          return classes  
        },
      
        //Plan Chart Date Filter
        changePlanDoneChart() {
          this.isPlanChangeChart = false;
        },
        selectPlan(e){
          this.isLoadingPlan = true;
          this.planPurchase = 0
          this.lineChartPlansdata.datasets = []
          let today = new Date()
          let startOfMonth = new Date(moment(today).startOf('month'));
          let endOfLastMonth = new Date(startOfMonth).setDate(new Date(startOfMonth).getDate() - 1);
          let startOfLastMonth = new Date(moment(endOfLastMonth).startOf('month'));
          let startDate = moment(e.startDate, "DD.MM.YYYY");
          let endDate = moment(e.endDate, "DD.MM.YYYY");
          let diffrence =  endDate.diff(startDate, 'days')
          if ((e.startDate).getTime() == startOfLastMonth.getTime() &&  (e.endDate).getTime() == endOfLastMonth) { // if previous month is selected
              let date = moment(e.startDate).format("YYYY-MM")
               db.collection(dbCollections.UPDATEGRAPHDATA).doc(date).get().then((revanue)=>{
                let resetArray = []
                revanue.data().dateWisePlanData.forEach((data)=>{
                  resetArray.push((data))
                })
                let count = 0;
                let array = [];
                let planCount = 0;
                let planName = {};
                let colors = ['#F080A4','#28DAC6','#FFA800','#7367F0','#28C76F','#FFED33','#13fc03','#030ffc','#fc03d7','#665f31',
                  '#1dde64','#d11541','#f76605','#0f0f0f','#2b7328'];
                let maxPlan = [];
                let countFun = (raw) => {
                  if(count === resetArray.length) {
                    this.planPurchase = planCount
                    for (let i = 0; i < Object.keys(planName).length; i++) {                 
                      let dataDisplay = [];
                      for (let j = 0; j < array.length; j++) {
                        let count = 0;
                        for (let k = 0; k < planName[Object.keys(planName)[i]].length; k++) {
                          if (array[j] === planName[Object.keys(planName)[i]][k].date) {
                            count = planName[Object.keys(planName)[i]][k].plan
                            break;
                          }
                        }
                        dataDisplay.push(count)
                      }
                        let obj = { 
                          data: dataDisplay,
                          borderColor: colors[i] ? colors[i] : (colors[i-15] ?  colors[i-15] :  chartColors.lineChartPrimary),
                          label: Object.keys(planName)[i],
                          lineTension: 0.5,
                          pointStyle: 'circle',
                          backgroundColor: colors[i] ? colors[i] : (colors[i-15] ?  colors[i-15] :  chartColors.lineChartPrimary),
                          fill: false,
                          pointRadius: 2,
                          pointHoverRadius: 5,
                          pointHoverBorderWidth: 5,
                          pointBorderColor: 'transparent',
                          pointHoverBorderColor: $themeColors.white,
                          pointHoverBackgroundColor: colors[i] ? colors[i] : (colors[i-15] ?  colors[i-15] :  chartColors.lineChartPrimary),
                          pointShadowOffsetX: 1,
                          pointShadowOffsetY: 1,
                          pointShadowBlur: 5,
                          pointShadowColor: chartColors.tooltipShadow,
                        }
                        this.lineChartPlansdata.datasets.push(obj);
                        let max = Math.max(...dataDisplay)
                        maxPlan.push(max);
                    }
                    let maximum = [Math.max(...maxPlan)]
                    let maximumNumber = Math.max(...maximum)
                    let RoundFigure = Math.ceil(maximumNumber / 10) * 10
                    let step = Math.ceil(RoundFigure / 10)
                    this.lineChartPlanOption.options.scales.yAxes[0].ticks.stepSize = step
                    this.lineChartPlanOption.options.scales.yAxes[0].ticks.max = RoundFigure
                    this.lineChartPlansdata.labels = array
                    this.isPlanChangeChart = true;
                    this.isLoadingPlan = false;
                    return;
                  }
                    planCount += raw.planCount
                    array.push(raw.id)
                    let number = 0
                    let fun = (plan) => {
                        if (number >= Object.keys(raw.planName).length) {
                            count += 1;
                            countFun(resetArray[count]);
                            return;
                        }
                        if(planName[plan] && planName[plan].length !== 0) {
                          planName[plan].push({date: raw.id,plan: raw.planName[plan]})
                        } else {
                            planName[plan] = [{date: raw.id ,plan: raw.planName[plan]}]
                        }
                        number+= 1
                        fun(Object.keys(raw.planName)[number]);
                    }
                    fun(Object.keys(raw.planName)[number]);
                }
                countFun(resetArray[count]);
               })
          }
          else if (diffrence < 31) {
            this.totalDate = []
            this.getInbetweenDates(new Date(e.startDate).getTime(), new Date(e.endDate).getTime())
            this.lineChartPlansdata.labels = []
            let array = []
            this.totalDate.forEach((date)=>{
              array.push(moment(date).format("DD-MM"))
            })
            let count = 0;
            let planCount = 0;
             let planName = {};
            let colors = ['#F080A4','#28DAC6','#FFA800','#7367F0','#28C76F','#FFED33','#13fc03','#030ffc','#fc03d7','#665f31',
              '#1dde64','#d11541','#f76605','#0f0f0f','#2b7328'];
            let maxPlan = [];
            let countFun = (raw) => {
              if(count === this.totalDate.length) {
                this.planPurchase = planCount
                for (let i = 0; i < Object.keys(planName).length; i++) {                 
                  let dataDisplay = [];
                  for (let j = 0; j < array.length; j++) {
                    let count = 0;
                    for (let k = 0; k < planName[Object.keys(planName)[i]].length; k++) {
                      if (array[j] === planName[Object.keys(planName)[i]][k].date) {
                        count = planName[Object.keys(planName)[i]][k].plan
                        break;
                      }
                    }
                    dataDisplay.push(count)
                  }
                     let obj = { 
                      data: dataDisplay,
                      borderColor: colors[i] ? colors[i] : (colors[i-15] ?  colors[i-15] :  chartColors.lineChartPrimary),
                      label: Object.keys(planName)[i],
                      lineTension: 0.5,
                      pointStyle: 'circle',
                      backgroundColor: colors[i] ? colors[i] : (colors[i-15] ?  colors[i-15] :  chartColors.lineChartPrimary),
                      fill: false,
                      pointRadius: 2,
                      pointHoverRadius: 5,
                      pointHoverBorderWidth: 5,
                      pointBorderColor: 'transparent',
                      pointHoverBorderColor: $themeColors.white,
                      pointHoverBackgroundColor: colors[i] ? colors[i] : (colors[i-15] ?  colors[i-15] :  chartColors.lineChartPrimary),
                      pointShadowOffsetX: 1,
                      pointShadowOffsetY: 1,
                      pointShadowBlur: 5,
                      pointShadowColor: chartColors.tooltipShadow,
                    }
                    this.lineChartPlansdata.datasets.push(obj);
                    let max = Math.max(...dataDisplay)
                    maxPlan.push(max);
                }
                let maximum = [Math.max(...maxPlan)]
                let maximumNumber = Math.max(...maximum)
                let RoundFigure = Math.ceil(maximumNumber / 10) * 10
                let step = Math.ceil(RoundFigure / 10)
                this.lineChartPlanOption.options.scales.yAxes[0].ticks.stepSize = step
                this.lineChartPlanOption.options.scales.yAxes[0].ticks.max = RoundFigure
                this.lineChartPlansdata.labels = array
                this.isPlanChangeChart = true;
                this.isLoadingPlan = false;
                return;
              }
              let date = moment(raw).format("DD-MM")
              let month = moment(raw).format("YYYY-MM")
               db.collection(dbCollections.UPDATEGRAPHDATA).doc(month).collection("day").doc(date).get().then((revanue)=>{
                if (revanue.exists) {   
                  planCount += revanue.data().planDetail.planCount
                  let number = 0
                  let fun = (plan) => {
                      if (number >= Object.keys(revanue.data().planDetail.planName).length) {
                          count += 1;
                          countFun(this.totalDate[count]);
                          return;
                      }
                      if(planName[plan] && planName[plan].length !== 0) {
                        planName[plan].push({date: date,plan: revanue.data().planDetail.planName[plan]})
                      } else {
                          planName[plan] = [{date: date ,plan: revanue.data().planDetail.planName[plan]}]
                      }
                      number+= 1
                      fun(Object.keys(revanue.data().planDetail.planName)[number]);
                  }
                  fun(Object.keys(revanue.data().planDetail.planName)[number]);
                } else {
                  count+= 1;
                  countFun(this.totalYear[count]);
                }
               }).catch(error=>{
                  console.error(error);
                  count+= 1;
                  countFun(this.totalDate[count]);
                  })
            }
            countFun(this.totalDate[count]);
          } else if(diffrence > 31 && diffrence < 365) {
            let Startmonth = moment(e.startDate).format("MMMM YYYY")
            let Endmonth = moment(e.endDate).format("MMMM YYYY")
            this.totalMonth = []
            this.totalMonthGet = []
            this.getMonthBetweenDate(Startmonth, Endmonth, e.startDate, e.endDate)
            this.lineChartPlansdata.labels = []
            let array = []
            this.totalMonth.forEach((data)=>{
              array.push(data)
            })
            let count = 0;
            let planCount = 0;
            let planName = {};
            let colors = ['#F080A4','#28DAC6','#FFA800','#7367F0','#28C76F','#FFED33','#13fc03','#030ffc','#fc03d7','#665f31',
              '#1dde64','#d11541','#f76605','#0f0f0f','#2b7328'];
            let maxPlan = [];
            let countFun = (raw) => {
              if(count === this.totalMonthGet.length) {
                this.planPurchase = planCount
                for (let i = 0; i < Object.keys(planName).length; i++) {                 
                  let dataDisplay = [];
                  for (let j = 0; j < this.totalMonthGet.length; j++) {
                    let count = 0;
                    for (let k = 0; k < planName[Object.keys(planName)[i]].length; k++) {
                      if (this.totalMonthGet[j] === planName[Object.keys(planName)[i]][k].date) {
                        count = planName[Object.keys(planName)[i]][k].plan
                        break;
                      }
                    }
                    dataDisplay.push(count)
                  }
                     let obj = { 
                      data: dataDisplay,
                      borderColor: colors[i] ? colors[i] : (colors[i-15] ?  colors[i-15] :  chartColors.lineChartPrimary),
                      label: Object.keys(planName)[i],
                      lineTension: 0.5,
                      pointStyle: 'circle',
                      backgroundColor: colors[i] ? colors[i] : (colors[i-15] ?  colors[i-15] :  chartColors.lineChartPrimary),
                      fill: false,
                      pointRadius: 2,
                      pointHoverRadius: 5,
                      pointHoverBorderWidth: 5,
                      pointBorderColor: 'transparent',
                      pointHoverBorderColor: $themeColors.white,
                      pointHoverBackgroundColor: colors[i] ? colors[i] : (colors[i-15] ?  colors[i-15] :  chartColors.lineChartPrimary),
                      pointShadowOffsetX: 1,
                      pointShadowOffsetY: 1,
                      pointShadowBlur: 5,
                      pointShadowColor: chartColors.tooltipShadow,
                    }
                    this.lineChartPlansdata.datasets.push(obj);
                    let max = Math.max(...dataDisplay)
                    maxPlan.push(max);
                }
                let maximum = [Math.max(...maxPlan)]
                let maximumNumber = Math.max(...maximum)
                let RoundFigure = Math.ceil(maximumNumber / 10) * 10
                let step = Math.ceil(RoundFigure / 10)
                this.lineChartPlanOption.options.scales.yAxes[0].ticks.stepSize = step
                this.lineChartPlanOption.options.scales.yAxes[0].ticks.max = RoundFigure
                this.lineChartPlansdata.labels = array
                this.isPlanChangeChart = true;
                this.isLoadingPlan = false;
                return;
              }
              let date = moment(raw).format("DD-MM")
              let month = moment(raw).format("YYYY-MM")
              db.collection(dbCollections.UPDATEGRAPHDATA).doc(raw).get().then((revanue)=>{
                if (revanue.exists) {
                  planCount += revanue.data().planDetail.planCount
                  let number = 0
                  let fun = (plan) => {
                      if (number >= Object.keys(revanue.data().planDetail.planName).length) {
                          count += 1;
                          countFun(this.totalMonthGet[count]);
                          return;
                      }
                      if(planName[plan] && planName[plan].length !== 0) {
                        planName[plan].push({date: raw,plan: revanue.data().planDetail.planName[plan]})
                      } else {
                          planName[plan] = [{date: raw ,plan: revanue.data().planDetail.planName[plan]}]
                      }
                      number+= 1
                      fun(Object.keys(revanue.data().planDetail.planName)[number]);
                  }
                  fun(Object.keys(revanue.data().planDetail.planName)[number]);
                } else {
                  count+= 1;
                  countFun(this.totalYear[count]);
                }
               }).catch(error=>{
                  console.error(error);
                  count+= 1;
                  countFun(this.totalMonthGet[count]);
                  })
            }
            countFun(this.totalMonthGet[count]);
          } else if (diffrence > 365) {
            this.totalYear = []
            this.getYearbetweemDate(e.startDate,e.endDate)
            this.lineChartdata.labels = []
            let array = []
            this.totalYear.forEach((data)=>{
              array.push(data)
            })
            let count = 0;
            let planCount = 0;
            let planName = {};
            let colors = ['#F080A4','#28DAC6','#FFA800','#7367F0','#28C76F','#FFED33','#13fc03','#030ffc','#fc03d7','#665f31',
              '#1dde64','#d11541','#f76605','#0f0f0f','#2b7328'];
            let maxPlan = [];
            let countFun = (raw) => {
              if(count === this.totalYear.length) {
                this.planPurchase = planCount
                for (let i = 0; i < Object.keys(planName).length; i++) {                 
                  let dataDisplay = [];
                  for (let j = 0; j < this.totalYear.length; j++) {
                    let count = 0;
                    for (let k = 0; k < planName[Object.keys(planName)[i]].length; k++) {
                      if (this.totalYear[j] === planName[Object.keys(planName)[i]][k].date) {
                        count = planName[Object.keys(planName)[i]][k].plan
                        break;
                      }
                    }
                    dataDisplay.push(count)
                  }
                     let obj = { 
                      data: dataDisplay,
                      borderColor: colors[i] ? colors[i] : (colors[i-15] ?  colors[i-15] :  chartColors.lineChartPrimary),
                      label: Object.keys(planName)[i],
                      lineTension: 0.5,
                      pointStyle: 'circle',
                      backgroundColor: colors[i] ? colors[i] : (colors[i-15] ?  colors[i-15] :  chartColors.lineChartPrimary),
                      fill: false,
                      pointRadius: 2,
                      pointHoverRadius: 5,
                      pointHoverBorderWidth: 5,
                      pointBorderColor: 'transparent',
                      pointHoverBorderColor: $themeColors.white,
                      pointHoverBackgroundColor: colors[i] ? colors[i] : (colors[i-15] ?  colors[i-15] :  chartColors.lineChartPrimary),
                      pointShadowOffsetX: 1,
                      pointShadowOffsetY: 1,
                      pointShadowBlur: 5,
                      pointShadowColor: chartColors.tooltipShadow,
                    }
                    this.lineChartPlansdata.datasets.push(obj);
                    let max = Math.max(...dataDisplay)
                    maxPlan.push(max);
                }
                let maximum = [Math.max(...maxPlan)]
                let maximumNumber = Math.max(...maximum)
                let RoundFigure = Math.ceil(maximumNumber / 10) * 10
                let step = Math.ceil(RoundFigure / 10)
                this.lineChartPlanOption.options.scales.yAxes[0].ticks.stepSize = step
                this.lineChartPlanOption.options.scales.yAxes[0].ticks.max = RoundFigure
                this.lineChartPlansdata.labels = array
                this.isPlanChangeChart = true;
                this.isLoadingPlan = false;
                return;
              }
              let date = moment(raw).format("DD-MM")
              let month = moment(raw).format("YYYY-MM")
              db.collection(dbCollections.UPDATEGRAPHDATA).doc(raw).get().then((revanue)=>{
                if(revanue.exists) {
                  planCount += revanue.data().planDetail.planCount
                  let number = 0
                  let fun = (plan) => {
                      if (number >= Object.keys(revanue.data().planDetail.planName).length) {
                          count += 1;
                          countFun(this.totalYear[count]);
                          return;
                      }
                      if(planName[plan] && planName[plan].length !== 0) {
                        planName[plan].push({date: raw,plan: revanue.data().planDetail.planName[plan]})
                      } else {
                          planName[plan] = [{date: raw ,plan: revanue.data().planDetail.planName[plan]}]
                      }
                      number+= 1
                      fun(Object.keys(revanue.data().planDetail.planName)[number]);
                  }
                  fun(Object.keys(revanue.data().planDetail.planName)[number]);
                } else {
                  count+= 1;
                  countFun(this.totalYear[count]);
                }
               }).catch(error=>{
                  console.error(error);
                  count+= 1;
                  countFun(this.totalYear[count]);
                  })
            }
            countFun(this.totalYear[count]);
             this.lineChartPlansdata.labels = array
             this.isPlanChangeChart = true;
          }
        },
        toggleDateRangePickerPlane(e){
          if (e === false) {
            this.disabledDatePlans = null
          }
        },
        daterangePickerPlan(e){
          this.disabledDatePlans = e       
        },
        dateFormatPlan (classes, date) {
            if (!classes.disabled) {
              if (date.getTime() > new Date().setHours(12,0,0)) {
                classes.disabled = true;
              }
            }
          return classes  
        },

        changeActiveUserDoneChart() {
          this.isActiveUserChange = false;
        },
        selectActiveUser(e){
          this.isLoadingActiveUser = true;
           let today = new Date()
          let startOfMonth = new Date(moment(today).startOf('month'));
          let endOfLastMonth = new Date(startOfMonth).setDate(new Date(startOfMonth).getDate() - 1);
          let startOfLastMonth = new Date(moment(endOfLastMonth).startOf('month'));
          let startDate = moment(e.startDate, "DD.MM.YYYY");
          let endDate = moment(e.endDate, "DD.MM.YYYY");
          let diffrence =  endDate.diff(startDate, 'days')
          if ((e.startDate).getTime() == startOfLastMonth.getTime() &&  (e.endDate).getTime() == endOfLastMonth) { // if previous month is selected
              let date = moment(e.startDate).format("YYYY-MM")
               db.collection(dbCollections.USERCHARTDATA).doc(date).get().then((activeUser)=>{
                let resetArray = []
                activeUser.data().dateWiseData.forEach((data)=>{
                  resetArray.push((data))
                })
                let learner = []
                let array = []
                let tutor = []
                let child = []
                let count = 0;
                let countFun = (raw) => {
                    if (count === resetArray.length) {
                      this.option.series[0].data = learner
                      this.option.series[1].data = tutor
                      this.option.series[2].data = child
                      this.option.xAxis[0].data = array
                      this.forceRerender();
                      this.isLoadingActiveUser = false;
                      return;
                    }
                    array.push(raw.id)
                    tutor.push(raw.activeTutor.length)
                    child.push(raw.activeChild.length)
                    learner.push(raw.activeLearner.length)
                    count += 1;
                    countFun(resetArray[count]);
                  } 
                countFun(resetArray[count]);
               })
          }
          else if (diffrence < 31) {
            this.totalDate = []
            this.getInbetweenDates(new Date(e.startDate).getTime(), new Date(e.endDate).getTime())
            let Array = []
            this.totalDate.forEach((date)=>{
              Array.push(moment(date).format("DD-MM"))
            })
            let learner = []
            let tutor = []
            let child = []
            let count = 0;
            let countFun = (raw) => {
                if (count === this.totalDate.length) {
                  this.option.series[0].data = learner
                  this.option.series[1].data = tutor
                  this.option.series[2].data = child
                  this.option.xAxis[0].data = Array
                  this.forceRerender();
                  this.isLoadingActiveUser = false;
                  return;
                }
                 let date = moment(raw).format("DD-MM")
                 let month = moment(raw).format("YYYY-MM")
                  db.collection(dbCollections.USERCHARTDATA).doc(month).collection("day").doc(date).get().then((revanue)=>{
                    if(revanue.exists) {
                      learner.push(revanue.data().activeLearner.length)
                      tutor.push(revanue.data().activeTutor.length)
                      child.push(revanue.data().activeChild.length)
                      count+= 1;
                      countFun(this.totalDate[count]);
                    }
                    else {
                      learner.push(0)
                      tutor.push(0)
                      child.push(0)
                      count+= 1;
                      countFun(this.totalDate[count]);
                    }
                  }).catch(error=>{
                      console.error(error);
                      count+= 1;
                      countFun(this.totalDate[count]);
                  })
            }
            countFun(this.totalDate[count]);
           
          } 
          else if(diffrence > 31 && diffrence < 365){
            let Startmonth = moment(e.startDate).format("MMMM YYYY")
            let Endmonth = moment(e.endDate).format("MMMM YYYY")
            this.totalMonth = []
            this.totalMonthGet = []
            this.getMonthBetweenDate(Startmonth, Endmonth, e.startDate, e.endDate)
            let learner = []
            let tutor = []
            let child = []
            let count = 0;
            let countFun = (raw) => {
                if (count === this.totalMonthGet.length) {
                  this.option.series[0].data = learner
                  this.option.series[1].data = tutor
                  this.option.series[2].data = child
                  this.option.xAxis[0].data = this.totalMonth
                  this.forceRerender();
                  this.isLoadingActiveUser = false;
                  return;
                }
                  db.collection(dbCollections.USERCHARTDATA).doc(raw).get().then((revanue)=>{
                    if (revanue.exists) {
                      learner.push(revanue.data().activeLearner.length)
                      tutor.push(revanue.data().activeTutor.length)
                      child.push(revanue.data().activeChild.length)
                      count+= 1;
                      countFun(this.totalMonthGet[count]);
                    } else {
                      learner.push(0)
                      tutor.push(0)
                      child.push(0)
                      count+= 1;
                      countFun(this.totalMonthGet[count]);
                    }
                  }).catch(error=>{
                      console.error(error);
                      count+= 1;
                      countFun(this.totalMonthGet[count]);
                  })
            }
            countFun(this.totalMonthGet[count]);
          }
          else if (diffrence > 365) {
            this.totalYear = []
            this.getYearbetweemDate(e.startDate,e.endDate)
            let learner = []
            let tutor = []
            let child = []
            let count = 0;
            let countFun = (raw) => {
                if (count === this.totalYear.length) {
                  this.option.series[0].data = learner
                  this.option.series[1].data = tutor
                  this.option.series[2].data = child
                  this.option.xAxis[0].data = this.totalYear
                  this.forceRerender();
                  this.isLoadingActiveUser = false;
                  return;
                }
                  db.collection(dbCollections.USERCHARTDATA).doc(raw).get().then((revanue)=>{
                     if (revanue.exists) {
                      learner.push(revanue.data().activeLearner.length)
                      tutor.push(revanue.data().activeTutor.length)
                      child.push(revanue.data().activeChild.length)
                      count+= 1;
                      countFun(this.totalYear[count]);
                    } else {
                      learner.push(0)
                      tutor.push(0)
                      child.push(0)
                      count+= 1;
                      countFun(this.totalYear[count]);
                    }
                  }).catch(error=>{
                      console.error(error);
                      count+= 1;
                      countFun(this.totalYear[count]);
                  })
            }
            countFun(this.totalYear[count]);
          }
        },
        toggleDateRangePickerActiveUser(e){
          if (e === false) {
            this.disabledActiveUserDate = null
          }
        },
        daterangePickerActiveUser(e){
          this.disabledActiveUserDate = e
        },
        dateFormatActiveUser (classes, date) {
            if (!classes.disabled) {
              if (date.getTime() > new Date()) {
                classes.disabled = true;
              }
            }
          return classes  
        },
        ...mapActions({
            'getsettingArray' : 'settings/getsettingArrayFeild',
        }),
        checkDate(time) {
        const finaldate = time.getTime();
        return finaldate;
        },
        //Total No. of Review Request
        reviewRequest() {
            getFirebase.simpleGetDataWithSpecificWhereCondition(dbCollections.REVIEWS, "status", "==", 0,(resreq) => {
                this.total_review_request = resreq;
            });
        },
        // Total No. of Pending Tutor Request
        pendingTutorReq() {
            getFirebase.simpleGetDataWithSpecificWhereCondition( dbCollections.USERS,"tutorStatus","==", 0,(respen) => {
                this.total_pending_tutor_request = respen;
            });
        },
        //Total No. of Block Account
        blockAccount() {
            getFirebase.simpleGetDataWithSpecificWhereCondition(dbCollections.USERS, "status", "==",3,(res) => {
                this.total_block_account = res;
            });
        },
        //Current Online Session
        currentOnlineSession() {
        db.collection(dbCollections.BOOKING)
            .where("startTime",">=",new Date(new Date().getTime() - this.getsettingList.maxBookingTime * 60 * 1000))
            .where("startTime", "<=", new Date())
            .where("status", "in", [1,5])
            .get()
            .then((rescurrent) => {
            let response = [];
            if (!rescurrent.empty) {
                rescurrent.forEach((doc) => {
                if (this.checkDate(new Date(doc.data().endTime.seconds * 1000)) >=this.checkDate(new Date())) {
                    response.push({ id: doc.id, ...doc.data() });
                    this.total_current_tutor_request = response.length;
                }
                });
                return;
            } else {
                return;
            }
            });
        },
        //Today Upcoming Session
        upcomingSession() {
        var up1 = moment(new Date()).set({hour: new Date().getHours(),minute: new Date().getMinutes(),second: new Date().getSeconds(),millisecond: 59,}).format();
        var up2 = moment(new Date()).set({ hour: 23, minute: 59, second: 59, millisecond: 59 }).format();
        db.collection(dbCollections.BOOKING)
            .where("startTime", ">", new Date(up1))
            .where("startTime", "<", new Date(up2))
            .where("status", "in", [0, 1, 2, 4])
            .get()
            .then((snapshot) => {
            let responseup = [];
            if (!snapshot.empty) {
                snapshot.forEach((doc) => {
                responseup.push({ id: doc.id, ...doc.data() });
                this.total_upcoming_tutor_request = responseup.length;
                });
                return;
            } else {
                return;
            }
            });
        },
        //Today No. of New User
        todayNewUser() {
        var date1 = moment(new Date())
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format();
        var date2 = moment(new Date())
            .set({
            hour: new Date().getHours(),
            minute: new Date().getMinutes(),
            second: new Date().getSeconds(),
            millisecond: 59,
            })
            .format();
        db.collection(dbCollections.USERS)
            .where("createdAt", ">", new Date(date1))
            .where("createdAt", "<", new Date(date2))
            .get()
            .then((snapshot) => {
            let responseuser = [];
            if (!snapshot.empty) {
                snapshot.forEach((doc) => {
                responseuser.push({ id: doc.id, ...doc.data() });
                this.today_new_user = responseuser.length;
                });
                return;
            } else {
                return;
            }
            });
        },
        //Past Upcoming Session
        pastUpcomingSession() {
        var pastsession1 = moment().startOf("week");
        var pastsession2 = moment(Date.now());
        db.collection(dbCollections.BOOKING)
            .where("endTime", ">", new Date(pastsession1))
            .where("endTime", "<", new Date(pastsession2))
            .get()
            .then((snapshot) => {
            let responseup = [];
            if (!snapshot.empty) {
                snapshot.forEach((doc) => {
                responseup.push({ id: doc.id, ...doc.data() });
                this.total_past_tutor_request = responseup.length;
                });
                return;
            } else {
                return;
            }
            });
        },
        //Past No. of New User
        pastUser() {
        var pastuser1 = moment().startOf("week");
        var pastuser2 = moment(Date.now());
        db.collection(dbCollections.USERS)
            .where("createdAt", ">", new Date(pastuser1))
            .where("createdAt", "<", new Date(pastuser2))
            .get()
            .then((snapshot) => {
            let responseuser = [];
            if (!snapshot.empty) {
                snapshot.forEach((doc) => {
                responseuser.push({ id: doc.id, ...doc.data() });
                this.past_new_user = responseuser.length;
                });
                return;
            } else {
                return;
            }
            });
        },
        //chart EchartDoughnut
        toatlno(){
            this.generalType = [];
            this.getsettingList.tutorType.forEach(element => {
                this.generalType.push(element.toLowerCase());
                if(this.generalType.length === this.getsettingList.tutorType.length){
                    return;
                }
            });
        },
        totalType(){
            this.tutorDetailType = [];
            this.getUsersList.forEach(elem =>{
                if(elem.tutorType === undefined){
                    return;
                }
                let datasss = elem.tutorType.toLowerCase();
                this.tutorDetailType.push(datasss);
            });
        },
        totalFilter(){
            this.isTotalTutor = true;
            this.dataOfTutor = [];
            this.totalNo = 0;
            this.countNumber = 0;
            if(this.generalType.length>0 && this.tutorDetailType.length>0){
                for(let t=0; t < this.generalType.length;t++){
                    this.count = 0;
                    for(let l=0; l < this.tutorDetailType.length;l++){
                        if(this.generalType[t].toLowerCase() === this.tutorDetailType[l].toLowerCase()){
                            this.count++
                            this.countNumber++
                        }
                    }
                    let details = {
                        element : this.generalType[t].toLowerCase(),
                        value12 : this.count
                    }
                    this.dataOfTutor.push(details)
                    
                }
                this.totalNo = this.totalNo + this.countNumber;
                this.dataOfTutor.forEach((data89)=>{
                      this.totalUsersChart2Options.labels.push(data89.element)
                      this.totalUsersChart2Series.push(data89.value12)
                })
                  if (this.dataOfTutor.length == this.generalType.length && this.dataOfTutor.length ==  this.totalUsersChart2Series.length) {
                      this.isTotalTutor = false;
                    }
            } else {
              this.isTotalTutor = false;
            }
        },
        getTotalUsersChartData(){
            this.isTotalUser = true;
            db.collection(dbCollections.USERCHARTDATA).doc("data").get().then((resUserChart) => {
                if(resUserChart.exists) {
                    let labels = [
                        "Active Learner",
                        "Idle Learner",
                        "Active Tutor",
                        "Idle Tutor",
                        "Active Child",
                        "Idle Child"
                    ];
                    let values = [
                        resUserChart.data().activeLearners.length,
                        resUserChart.data().idealLearners.length,
                        resUserChart.data().activeTutors.length,
                        resUserChart.data().idealTutors.length,
                        resUserChart.data().activeChilds.length,
                        resUserChart.data().idealChilds.length
                    ]
                    for (let index = 0; index < labels.length; index++) {
                        this.totalUsersChart1Options.labels.push(labels[index]);
                        this.totalUsersChart1Series.push(values[index]);
                    }
                    this.isTotalUser = false;  
                } else {
                    this.isTotalUser = false;  
                }
            }).catch((error) => {
                this.isTotalUser = false;  
                console.error(error);
            });
        },
        getWeekData() {
        var weekStart = moment().clone().startOf("week");
        var days = [];
        for (let i = 0; i <= 6; i++) {
            days.push(moment(weekStart).add(i, "days").format("DD-MM-YY"));
        }
        this.recentWeek = days;
        },
    },
};
</script>
<style>
.echarts {
  width: 100% !important;
}
</style>