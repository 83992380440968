<template>
<b-tab title="Tutor History" @click="getTabData" :active="tutorTabActive">
<div>
    <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners" />
    <div v-show="!isSpinner">
        <b-spinner variant="primary" v-if="isUpdating" large class="pageLoadSpinners" style="position: fixed" />

        
        <div class="card user_table cancel_session_table upcoming_session_table learner_histroy ">
            <div class="custom-search d-flex justify-content-end">
                <b-button variant="outline-primary" v-on:click="exportData">
                    Export Excel
                </b-button>
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <b-form-input
                                v-model="searchTerm"
                                placeholder="Search..."
                                type="text"
                                class="d-inline-block"
                                
                            />
                        </div>
                    </b-form-group>
            </div>

            <vue-good-table v-if="getTutorHistoryTransectionData.length" :columns="dynamicColumns" class="cursor_pointer" :rows="transactionHistroyList" :rtl="direction" :search-options="{
                    enabled: true,
                    externalQuery: searchTerm,
                }" :select-options="{
                    enabled: true,
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true, // disable the select info panel on top
                }" :pagination-options="{
                    enabled: true,
                    perPage: pageLength,
                }"
            >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'date'" class="text-nowrap">
                        <span>{{props.row.date ? props.row.date : ""}}</span>
                    </span>
                     <span v-if="props.column.field === 'transactionid'" class="text-nowrap">
                        <span>{{props.row.transactionid ? props.row.transactionid : ""}}</span>
                    </span>
                     <span v-if="props.column.field === 'userName'" class="text-nowrap">
                        <router-link :to="{name: 'user-details' , params: { id: props.row.userId}}">{{props.row.userName ? props.row.userName : ""}}</router-link>
                    </span>
                     <span v-if="props.column.field === 'bookingId'" class="text-nowrap">
                    <router-link :to="{name: 'booking-detail-transection-learner', params: { id: props.row.bookingDocId} , query: {tab: 'learner'}}">
                        {{props.row.bookingId ? props.row.bookingId : ""}}
                    </router-link>
                    </span>
                     <span v-if="props.column.field === 'amount'" class="text-nowrap">
                        <span>{{props.row.amount ? props.row.amount : ""}}</span>
                    </span>
                    <span v-if="props.column.field === 'availAmount'" class="text-nowrap">
                        <span>{{props.row.availAmount ? props.row.availAmount : ""}}</span>
                    </span>
                     <span v-if="props.column.field === 'remarks'" class="text-nowrap">
                        <span>{{props.row.remarks ? props.row.remarks : ""}}</span>
                    </span>
                </template>
                <template slot="pagination-bottom" slot-scope="props">
                        <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap">
                            <span v-if="props.total === 0"> Showing 0 </span>
                            <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                            to
                            <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                            <span v-else>{{props.total}} of {{ props.total }} entries</span>
                            </span>

                                <div class="d-flex align-items-center mb-1 mt-0 showentry subcategoryDetail">
                                <span>Show</span>
                                <b-form-select
                                    v-model="pageLength"
                                    :options="['10', '20', '30', '40', '50']"
                                    class="mx-1"
                                    @input="
                                    (value) => props.perPageChanged({ currentPerPage: value })
                                    "
                                />
                                <span>entries</span>
                                </div>
                        </div>
                        <div>
                            <b-pagination
                            :value="1"
                            :total-rows="props.total"
                            :current-page="currentPage"
                            :per-page="pageLength"
                            first-number
                            last-number
                            align="right"
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0"
                            @input="
                                (value) => {
                                props.pageChanged({ currentPage: value });
                                currentPage = value
                                }
                            "
                            >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                            </b-pagination>
                        </div>
                        </div>
                </template>
            </vue-good-table>
        </div>
    </div>    
</div>
</b-tab>
</template>

<script>
import {
    BButton,
    BSpinner,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BTabs,
    BTab,
    BCardText,
    BCard,
} from "bootstrap-vue";
import {
    VueGoodTable
} from "vue-good-table";
import store from "@/store/index";
import * as getQuery from "@/utils/FirebaseQueries/getQueries/getQueries.js";
import { dbCollections } from "@/utils/firebaseCollection.js";
import moment from "moment";
import Ripple from 'vue-ripple-directive'
import firebase from "@/utils/firebaseInit.js"
const db = firebase.firestore()
import {errorEmail} from "@/utils/apiRequests.js"
import { mapGetters,mapActions } from "vuex";

const currentFileName = "src/views/TransactionHistory/TransactionHistoryTabComponents/tutorHistory.vue";
export default {
    directives: {
        Ripple,
    },
    props: {
        getTutorHistory : Boolean
    },
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BSpinner,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BRow,
        BCol,
        BForm,
        BButton,
        BTabs,
        BTab,
        BCardText,
        BCard,
    },
    data() {
        return {
            direction:false,
            isSpinner:false,
            isUpdating:false, 
            perPage: 10,
            pageOptions: [10, 20, 30, 40, 50],
            tutorTransection:[],
            tutorTabActive: false,
            searchTerm: "",
            currentPage: 1,
            pageLength: 10,
            columns: [{
                    label: "Date",
                    field: "date",
                },
                {
                    label: "Transaction ID",
                    field: "transactionid",
                },
                {
                    label: "User Name",
                    field: "userName",
                },
                {
                    label: "Booking ID",
                    field: "bookingId",
                },
                {
                    label: "Amount",
                    field: "amount",
                },

                {
                    label: "Avail. Amount($)",
                    field: "availAmount",
                },
                {
                    label: "Remarks",
                    field: "remarks",
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            getsettingList : 'settings/getsettingArrayFeild',
            getTutorHistoryTransectionData: 'RevanueCalculation/getTutorHistory'
        }),
        currency(){
            if (Object.keys(this.getsettingList).length != 0) {

                return this.getsettingList.currency.slice(4)
            } else {
                return ""
            }
        },
        transactionHistroyList(){
            return this.getTutorHistoryTransectionData
        },
        dynamicColumns(){
             this.columns[4].label = `Amount(${this.currency})`
            this.columns[5].label = `Avail. Amount(${this.currency})`	
            return this.columns
        },
    },
    created() {
        if (this.$route.params && this.$route.params.tab === "tutor") {
            this.tutorTabActive = true;
        }
        this.getTransection()
    },
    methods: {
        ...mapActions({
            'getTutorTransection': 'RevanueCalculation/getTutorHistoryTransection'
         }),
        getTransection(){
            let transecionData = this.getTutorHistoryTransectionData.length != 0;
            if (!transecionData) {
                this.isSpinner = true;
                this.getTutorTransection().then((resp)=>{
                    this.isSpinner = false;
                }).catch((error)=>{
                    this.isSpinner = false;
                    console.error("error",error)
                })
            }
        },
        exportData(){
            try{
                var self = this;
                var CSV = '';
                var row = "";
                let keys = "date,transactionid,userName,bookingId,credit,availCredit,remarks".split(',')
                row+='Date,Transaction ID,User Name,Booking ID,Credits,Avail. Credits,Remarks\n'
                self.getTutorHistoryTransectionData.forEach((data2)=>{
                    row+=""
                    keys.forEach(key=>{
                    row+=data2[key] ? `"${data2[key]}"` : " "
                    row+=",";
                    })
                    row+="\n"
                })
                row.slice(0, row.length - 1);
                    CSV += row + "\n";
                    if (CSV == '') {        
                    alert("Invalid data");
                    return;
                }
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
                var link = document.createElement("a");
                link.href = uri;
                link.style = "visibility:hidden";
                link.download = `TutorTransactionHistory.csv`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            }catch (error) {
                errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "exportData",
                errorMessage : error.message
                },(res)=>{
                if(res.error){
                    console.error(res.error)
                }
                })
                console.error(error)
            }
            
        },
        getTabData(){
           try{ 
                var allTabList = document.getElementsByClassName('nav-link active')
                if (allTabList[0].innerText !== 'Tutor History'  || this.getTutorHistory == true) { 
                    this.getTransection()
                }
            }catch (error) {
                console.error(error)
                errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "getTabData",
                errorMessage : error.message
                },(res)=>{
                if(res.error){
                    console.error(res.error)
                }
                })

            }
        },
    },
};
</script>
