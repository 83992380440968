<template>
<div>
  <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
  <div class="auth-wrapper auth-v2" v-show="!isSpinner">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo" to="/login">
           <img src="@/assets/images/tutorimages/Logo.png" alt="">
     
      </b-link>
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-0 mobile_bg_main"
      >      
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img
            fluid
             src="@/assets/images/tutorimages/BG.png"
            alt="Login V2"
          />
        </div>
      </b-col>
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-2"
      >      
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >     
        <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Reset Password 
          </b-card-title>
          <validation-observer
            ref="simpleRules"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="setUserPassword"
            >
              <b-form-group>
                <label label-for="reset-password-new">New Password<span class="redcolorstar">*</span></label>
                <validation-provider #default="{ errors }" name="password " vid="Password" rules="required|password">
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                    <b-form-input id="reset-password-new" v-model="password" :type="password1FieldType" class="form-control-merge" name="reset-password-new" placeholder="Enter New Password" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="password1ToggleIcon" @click="togglePassword1Visibility"/>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group>
                <label label-for="reset-password-confirm">Confirm Password<span class="redcolorstar">*</span></label>
                <validation-provider #default="{ errors }"  name="confirm password" rules="required|confirmed:Password" >
                  <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null">
                    <b-form-input id="reset-password-confirm" v-model="cPassword" :type="password2FieldType" class="form-control-merge" name="reset-password-confirm" placeholder="Enter Confirm Password" />
                    <b-input-group-append is-text>
                      <feather-icon class="cursor-pointer" :icon="password2ToggleIcon" @click="togglePassword2Visibility" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                type="submit"
                variant="primary"
                block
              >
                <span v-if="!isLogging">
                  Set New Password
                </span>
                <span v-else>
                  <b-spinner small/>
                </span>
              </b-button>
            </b-form>
          </validation-observer>
          </b-col>
      </b-col>
    </b-row>
  </div>
</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, 
  BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'; //FOR NOTIFICATION
import firebase from "@/utils/firebaseInit";
export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  data() {
    return {      
      password1FieldType: 'password',
      password2FieldType: 'password',
      cPassword: '',
      password: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      required,
      isLogging:false,
      isSpinner: true,
    }
  },  
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },  
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created(){
    let oobCode = this.$route.query.oobCode.toString();  
      firebase.
      auth().
      verifyPasswordResetCode(oobCode).then((data)=>{
        this.isSpinner = false;
      })
      .catch((error) => {
        this.isSpinner = false;
        console.error("error",error)
        if(error.code !== undefined && error.code == 'auth/invalid-action-code'){
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Current link expired, or has already been used.`,
              variant: 'danger',
            }
          })
        this.$router.replace('/forgot-password');  
        }
      })
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    setUserPassword(){ 
        this.isLogging=true
        this.$refs.simpleRules.validate().then(success => {
            if (success) {           
              setTimeout(() => {
                let oobCode = this.$route.query.oobCode.toString();         
                firebase.
                  auth().
                  confirmPasswordReset(oobCode, this.cPassword).then(() => {
                    this.isLogging=false
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Password Update Successfully.',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text:'Now log in with new password.'
                      },
                    })
                    this.$router.replace('/login');
                  }).catch(error => { 
                    this.isLogging=false
                    let eMessage = error.message ? error.message : 'Authentication Error';
                    if(error.code !== undefined && error.code == 'auth/invalid-action-code'){
                      eMessage = "Current link expired, or has already been used.";
                    }
                    console.error(error,"?????")
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `${eMessage}`,
                        icon: 'CoffeeIcon',
                        variant: 'danger',
                        text:'Please try again.'
                      },
                    })
                    this.$router.replace('/login');
                  }); 
              }, 1000);       
            }
            else
            {
              this.isLogging=false
            }
        })
    }
  },  
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
