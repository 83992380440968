<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
    </div>
    <b-card>
      <b-form
        ref="form"
        :style="{ height: trHeight }"
        class="repeater-form qualification-repeater-form"
        @submit.prevent="repeateAgain"
      >
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >
          <b-col md="3">
            <b-form-group label="Document ID Number" label-for="documentnumber">
              <b-form-input
                id="documentnumber"
                type="text"
                v-model="item.documentnumber"
               placeholder="Enter Document ID Number"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Document Name" label-for="documentname">
              <b-form-input
                id="documentname"
                type="text"
                v-model="item.documentname"
                placeholder="Enter Document Name"
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group label="Document Front Side" label-for="documentfrontside">
              <b-form-file
                v-model="item.documentfrontside"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group label="Document Back Side" label-for="documentbackside">
              <b-form-file
                v-model="item.documentbackside"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              />
            </b-form-group>
          </b-col>
          <b-col  md="12" class="mb-50 text-right">
            <b-button
              variant="flat-danger"
              class="p-0 text-decoration-underline"
              @click="removeItem(index)"
            >
              <span>Remove</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
      </b-form>

      <b-button
        variant="flat-primary"
        class="p-0 text-decoration-underline"
        @click="repeateAgain"
      >
        <span>+ Add New</span>
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormSelect,
  BFormFile,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BCard,
    BFormInput,
    BFormSelect,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [
        {
          id: 1,
          prevHeight: 0,
          documentnumber: "K12-34-5678",
          documentname: "National ID Card",
          documentfrontside: "National-ID-card-front.pdf",
          documentbackside:"National-ID-card-back.pdf"
        },
        {
          id: 2,
          prevHeight: 0,
          documentnumber: "M635-173-72-586-0",
          documentname: "Driving License",
          documentfrontside: "Driving-License-front.pdf",
          documentbackside:"Driving-License-back.pdf"
         
        },
      ],
    };
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
 