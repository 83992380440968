<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
    </div>
        <b-card>
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-form-row>
                        <b-col md="3">
                            <b-form-group>
                                <label for="planname">Plan Name<span class="redcolorstar">*</span></label>
                                <validation-provider
                                    #default="{ errors }"
                                    name="plan name"
                                    rules="required|min:3"
                                    >
                                    <b-form-input
                                        id="planname"
                                        v-model="planname"
                                        placeholder="Enter Plan Name"
                                        maxlength="12"
                                        @blur="planname = planname.trim()"
                                        @keyup="CheckPlanNameEditExist()"
                                        autocomplete="off"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    <small
                                        class="text-danger"
                                        v-if="samePlanErrorEditMessage != ''"
                                        >{{ samePlanErrorEditMessage }}</small
                                    >
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                        <b-form-group>
                            <label for="nocredits">No. of Credits<span class="redcolorstar">*</span></label>
                            <validation-provider
                                #default="{ errors }"
                                name="no. of credits"
                                rules="required"
                                >
                                <b-form-input
                                    id="nocredits"
                                    v-model="nocredits"
                                    placeholder="Enter Credits"
                                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                    @keyup="trackChange"
                                    maxlength="5"
                                    autocomplete="off"
                                    @input="creditsnumber()"
                                    @copy.prevent
                                    @paste.prevent
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <label for="planamount">Plan Amount({{currency}})<span class="redcolorstar">*</span></label>
                                <b-form-input
                                id="planamount"
                                type="number"
                                :placeholder="planAmountUpdate.toString()"
                                disabled
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                        <b-form-group>
                            <label for="discount">Discount(%)<span class="redcolorstar"></span></label>
                            <validation-provider
                                :rules="{
                                    regex: /^(\d{1,5}|\d{0,5}\.\d{0,2})$/,
                                    max_value: 100,
                                    min_value: 0,
                                }"
                                #default="{ errors }"
                                name="discount"
                                >
                                <b-form-input
                                    id="discount"
                                    v-model="discount"
                                    placeholder="Enter Discount"
                                    @keypress="onlyNumber"
                                    max="100"
                                    maxlength="5"
                                    autocomplete="off"
                                    @input="netamount"
                                    @copy.prevent
                                    @paste.prevent
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <label for="netamount">Net Amount({{currency}})<span class="redcolorstar">*</span></label>
                                <b-form-input
                                id="netamount"
                                :placeholder="netAmountUpdate.toString()"
                                disabled
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <label for="tag">Add Tag<span class="redcolorstar"></span></label>
                                <b-form-input
                                id="tag"
                                v-model="tag"
                                maxlength="12"
                                @blur="tag = tag.trim()"
                                autocomplete="off"
                                placeholder="Enter Tag Name"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label-for="lightColor">
                                <label for="lightColor">Background Colour<span class="redcolorstar"></span></label><br>
                                <input type="color" id="lightColor" name="lightColour" v-model="lightColour">
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group label-for="darkColor">
                                <label for="darkColor">Tag Colour<span class="redcolorstar"></span></label><br>
                                <input type="color" id="darkColor" name="darkColour" v-model="darkColour">
                            </b-form-group>
                         </b-col>
                        <b-col cols="6">
                            <b-form-group>
                                <label for="description">Description<span class="redcolorstar"></span></label>
                                <b-form-textarea
                                id="description"
                                v-model="description"
                                rows="3"
                                maxlength="100"
                                @blur="description = description.trim()"
                                autocomplete="off"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="6"></b-col>
                    </b-form-row>
                </b-form>
            </validation-observer>
        </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BCol,
  BForm,
  BFormRow,
  BButton,
  BFormGroup,
  BFormTextarea,
  BSpinner,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import { dbCollections } from "@/utils/firebaseCollection.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Setting/Createplan.vue"
export default {
    components: {
        BFormInput,
        BForm,
        BButton,
        BFormRow,
        BCol,
        BFormGroup,
        BFormTextarea,
        BCard,
        ValidationProvider,
        ValidationObserver,
        BSpinner,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            isSpinner: false,
            isUpdating: false,
            planname: "",
            nocredits: 0,
            planamount: 0,
            discount: 0,
            newamount: 0,
            required,
            tag: "",
            description: "",
            editPlanId: this.$route.params.id,
            samePlanErrorEditMessage: "",
            planAmountUpdate:Number,
            netAmountUpdate:Number,
            lightColour:"#FFFFFF",
            darkColour:"#FFFFFF",
        };
    },
    computed: {
        ...mapGetters({
            getPlanManagamentList: "planManagament/getPlanManagament",
            getsettingList : 'settings/getsettingArrayFeild'
        }),
        currency(){
            if (Object.keys(this.getsettingList).length != 0) {
                return this.getsettingList.currency.slice(4)
            } else {
                return ""
            }
        },
        
    },
    created() {
        var self = this;
        let getPlanMangFound = this.getPlanManagamentList.length != 0;
        if (!getPlanMangFound) {
            this.getPlanManagament().then((res) => {
                const isInArray = self.getPlanManagamentList.some(function (item) {
                    return item.id == self.editPlanId;
                });
                if (!isInArray) {
                    self.$router.push({ name: "plan-mgmt" });
                    self.isSpinner = false;
                } else {
                    this.editPlan();
                }
            })
            .catch((error) => {
                console.error("error", error);
            });
        } else {
            const isInArray = self.getPlanManagamentList.some(function (item) {
                return item.id == self.editPlanId;
            });
            if (!isInArray) {
                self.$router.push({ name: "plan-mgmt" });
                self.isSpinner = false;
            } else {
                this.editPlan();
            }
        }
            self.$root.$off("editPlanEvent");
            self.$root.$on("editPlanEvent", (data) => {
            self.validationEditForm();
        });
    },
    methods: {
        creditsnumber() {
            this.planAmountUpdate = this.nocredits * this.getsettingList.oneCreditValue;
            this.netamount();
        },

        netamount() {
            if (this.planAmountUpdate - (this.planAmountUpdate * this.discount) / 100 >= 0) {
                this.netAmountUpdate = parseFloat(this.planAmountUpdate - (this.planAmountUpdate * this.discount) / 100).toFixed(2);
            } else {
                this.netAmountUpdate = parseFloat(this.planAmountUpdate).toFixed(2);
            }
        },
        ...mapActions({
            getPlanManagament: "planManagament/getPlanManagamentData",
        }),
        CheckPlanNameEditExist() {
            var self = this;
            self.isInArray = self.getPlanManagamentList.findIndex(function (item) {
                return item.planName.toLowerCase().trim() === self.planname.toLowerCase().trim() && item.id !== self.editPlanId;
            });
            if (self.isInArray !== -1 && self.planname != "") {
                self.samePlanErrorEditMessage = "The plan name is already exist";
            } else {
                self.samePlanErrorEditMessage = "";
            }
        },
        editPlan() {
            var self = this;
            self.isSpinner = true;
            for (var i = 0; i < self.getPlanManagamentList.length; i++) {
                self.id = self.getPlanManagamentList[i].id;
                if (self.id === self.editPlanId) {
                    (self.planname = self.getPlanManagamentList[i].planName),
                    (self.nocredits = self.getPlanManagamentList[i].numberOfCredits),
                    (self.planamount = self.getPlanManagamentList[i].planAmount),
                    // (self.netamount = self.getPlanManagamentList[i].netAmount),
                    (self.discount =
                    self.getPlanManagamentList[i].discountInPercentage),
                    (self.description = self.getPlanManagamentList[i].description),
                    (self.tag = self.getPlanManagamentList[i].tag);
                    self.planAmountUpdate = self.getPlanManagamentList[i].planAmount;
                    self.netAmountUpdate = self.getPlanManagamentList[i].netAmount;
                    self.lightColour = self.getPlanManagamentList[i].lightColour ? self.getPlanManagamentList[i].lightColour : "#FFFFFF"
                    self.darkColour = self.getPlanManagamentList[i].darkColour ? self.getPlanManagamentList[i].darkColour : "#FFFFFF"
                }
            }
            self.isSpinner = false;
        },
        validationEditForm() {
            var self = this;
            if (self.samePlanErrorEditMessage == "") {
                self.$refs.simpleRules.validate().then((success) => {
                    try {
                        if (success) {
                            let planManagement = {
                                planName: self.planname,
                                numberOfCredits: Number(self.nocredits),
                                planAmount: Number(self.planAmountUpdate),
                                discountInPercentage: Number(self.discount),
                                netAmount: Number(self.netAmountUpdate),
                                tag: self.tag,
                                description: self.description,
                                updatedAt: new Date(),
                                isDeleted: false,
                                lightColour: this.lightColour,
                                darkColour: this.darkColour
                            };
                            updateFirebase.updateRootSubCollectionDataWithDocId(
                                dbCollections.SETTINGS,
                                "setting",
                                dbCollections.PLAN,
                                self.editPlanId,
                                planManagement,
                                (res) => {
                                self.$nextTick(() => {
                                    self.$root.$emit(
                                    "showToastMessage",
                                    "Plan updated successfully.",
                                    "success"
                                    );
                                });
                                self.resetData();
                                self.$router.push({ path: "/settings/plan-management" });
                                self.$root.$emit("stopSpinner");
                                }
                            );
                        } else {
                        self.isSpinner = false;
                        setTimeout(() => {
                            self.$root.$emit("stopSpinner");
                        }, 200);
                        }
                    } catch (error) {
                        console.error("error", error);
                        self.$root.$emit("stopSpinner");
                        errorEmail({
                            projectName : process.env.VUE_APP_PROJECTNAME,
                            environment : process.env.NODE_ENV,
                            fileName : currentFileName,
                            hookName : "validationEditForm",
                            errorMessage : error.message
                        },(mailRes) => {
                            if(mailRes.error){
                            console.error(mailRes);
                            }
                        })
                    }
                });
            } else {
                setTimeout(() => {
                self.$root.$emit("stopSpinner");
                }, 200);
                self.isSpinner = false;
            }
        },

        trackChange() {
            this.nocredits = parseInt(this.nocredits) === 0 ? null : this.nocredits;
        },
        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },

        resetData() {
            var self = this;
            (self.planname = ""),
            (self.description = ""),
            (self.tag = ""),
            (self.discount = 0),
            (self.nocredits = 0),
            self.$refs.simpleRules.reset();
        }
    },
};
</script>
 