<template>
  <div class="tutor-list">
    <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners" />
    <div v-show="!isSpinner">
      <b-spinner variant="primary" v-if="isUpdating" large class="pageLoadSpinners loaderDetail" style="position: fixed"/>

      <div class="card user_table">
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="searchTerm"
                placeholder="Search..."
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="tutorListing"
          @on-cell-click="onCellClick"
          class="cursor-pointer"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
          }"
          :pagination-options="{ 
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'tutorid'" class="tutorid">  
               {{props.row.id ? props.row.id : 'N/A'}}
            </span>
            <span v-else-if="props.column.field === 'fullName'" class="text-nowrap">
              <b-avatar :src="props.row.avatar" class="user_img" />
              <router-link :to="`/user-management/users/${props.row.docId}`" class="text-nowrap">{{ props.row.fullName }}</router-link>
            </span>
            <span v-else-if="props.column.field === 'degree'" class="degree">
              {{props.row.degree ? props.row.degree : 'N/A'}}
            </span>
            <span v-else-if="props.column.field === 'mobile_number'" class="mobile_number">
              {{props.row.mobile_number ? props.row.mobile_number : 'N/A'}}
            </span>
            <span v-else-if="props.column.field === 'experience'" class="experience">
              {{props.row.experience ? props.row.experience : 'N/A'}}
            </span>
            <span v-else-if="props.column.field === 'resume'" class="resume download_btn">
              <div v-if="props.row.tutorResume!==''">
              <p> <feather-icon
                  icon="ArrowDownCircleIcon"
                  size="16"
                  class="text-body align-middle mr-25 download_text_header"
                />Download Resume</p>
              </div>
              <div v-else>
                N/A
              </div>
            </span>
            <span v-else-if="props.column.field === 'status'" class="status">
              <span v-if='props.row.status !== undefined && props.row.status !== ""'>
               <b-badge  :variant="getStatusBadgeVariant(props.row.status)">
                {{ getStatusName(props.row.status) }}
              </b-badge>
              </span>
              <span v-else>
                N/A
              </span>
            </span>
            <span v-else-if="props.column.field === 'action'" class="action">
                  <button type="button" @click="verifiedTutor(props.row,'Verify')" class="btn ml-1 btn-outline-success"> Verify </button>
                  <button type="button" v-if='getStatusName(props.row.status) != "Reject"' @click="verifiedTutor(props.row,'Reject')" class="btn ml-1 btn-outline-danger" v-b-modal.modal-reject-tutorVerfication> Reject </button> 
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
         <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap">
                        <span v-if="props.total === 0"> Showing 0 </span>
                        <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                        to
                        <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                        <span v-else>{{props.total}} of {{ props.total }} entries</span>
                        </span>

                            <div class="d-flex align-items-center mb-1 mt-0 showentry">
                            <span>Show</span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['10', '20', '30', '40', '50']"
                                class="mx-1"
                                @input="
                                (value) => props.perPageChanged({ currentPerPage: value })
                                "
                            />
                            <span>entries</span>
                            </div>
                    </div>
                    <div>
                        <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :current-page="currentPage"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="
                            (value) => {
                            props.pageChanged({ currentPage: value });
                            currentPage = value
                            }
                        "
                        >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                        </b-pagination>
                    </div>
                    </div>
          </template>
        </vue-good-table>
      </div>
    </div>
        <b-modal
        id="modal-login"
        title="Verify Your Tutor"
        ok-title="Save"
        cancel-variant="outline-secondary"
        v-model="verificationModel"
        centered
        header-class="editingtutorhead"
        body-class="editingtutor"
        @ok.prevent="tutorFinalVerification('Verify')"
      >
        <h2>Approve <b>{{this.tutorStatusData!== null ? tutorStatusData.fullName : ""}}</b> And Assign Tutor Role.</h2>
        <b-form>

          <b-form-group label="Select Tutor Type" label-for="vue-select">
          <b-spinner 
          variant="primary" 
          v-if="modalProcessor" 
          large 
          class="pageLoadSpinners" 
          style="position:fixed,left: -1% !Important,right: 0px"
          />
          <validation-observer ref="approveTutor">
            <validation-provider
              #default="{ errors }"
              name="tutor type"
              rules="required"
            >
            <b-form-select
              v-model="selected"
              :options="tutorTypesOption"
              size="lg"
            />
             <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
            </validation-provider>
          </validation-observer>
          </b-form-group>
        </b-form>
      </b-modal>
      <b-modal
            id="modal-reject-tutorVerfication"
            ref="my-modal"
            title="Rejection Reason"
            ok-title="Reject"
            data-class="addcredit_popup"
            data-value-class="addcredit_popup"
            centered
            cancel-variant="outline-secondary"
            @show="resetModal"
            @hidden="resetModal"
            @ok="rejectTutorVerification"
            :ok-disabled="isUpdating"
            :cancel-disabled="isUpdating"
          >
          <validation-observer ref="formReject">
            <b-form ref="form" @submit.stop.prevent="rejectTutorVerification">
              <validation-provider
                #default="{ errors }"
                name="reason for rejection"
                rules="required|min:3|max:300"
              >
                <b-form-group
                  label-for="name-input"
                  invalid-feedback="Reason for Rejection is required"
                >
                <label style="font-weight:600;">Reason for Rejection</label>
                  <b-form-textarea
                    id="name-input"
                    v-model="reason"
                    placeholder="Enter Reason For Rejection"
                    required
                  />
                  <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form>
          </validation-observer>
      </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
  BSpinner,
  BFormTextarea
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { mapGetters } from 'vuex';
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import {dbCollections} from "@/utils/firebaseCollection.js";
import axios from 'axios';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import firebase from "@/utils/firebaseInit"
import {sendNotificationOnTututorVerification} from './service.js'
import {updateInUserChartWhenUserRoleIsChange} from './updateUserRoleService.js'
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Users/TutorVerification/tutorVerificationDetail.vue"
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BSpinner,
    ValidationProvider, 
    ValidationObserver,
    BFormTextarea
  },
  data() {
    return {
      direction:false,
      verificationModel:false,
      modalProcessor:false,
      isSpinner:false,
      isUpdating:false,
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      selected: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      reason:"",
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "User ID",
          field: "tutorid",
        },
        {
          label: "Tutor Name",
          field: "fullName",
        },
        {
          label: "Degree",
          field: "degree",
        },
        {
          label: "Mobile Number",
          field: "mobile_number",
        },
        {
          label: "Total Exp. Years",
          field: "experience",
        },
        {
          label: "Download Resume",
          field: "resume",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      searchTerm: "",      
      action: [
        {
          1: "Verify",
          2: "Reject",
        },
        {
          1: "btn ml-1 btn-outline-success",
          2: "btn ml-1 btn-outline-danger",
        },
      ],
      tutorStatusData:null,
      fullName:""
    };
  },
  computed: {
      ...mapGetters({
          users:'userManagement/getUsers',
          getStatusList:'status/getStatus',
          gettutorTypesList:'settings/getTutorTypes'
        }),
            tutorTypesOption(){
       try {
            var arr = [{
              'id':'-1',
              'value':null,
              'text':'Select',
              'selected':true,
              disabled: true
            }]; 
            this.selected = null;                  
            this.gettutorTypesList.forEach((syl) => {
                var syllabusObj = {
                    id: syl.id,
                    value: syl.name.toLowerCase(),
                    text:syl.name                      
                }
                if(this.userTutorType && this.userTutorType !== '' && syl.name == this.userTutorType){
                  let searchIndex = arr.findIndex((values)=>{
                    return values.id == 0
                  })
                  if(searchIndex > -1){
                    arr[searchIndex].selected = false;
                    syllabusObj.selected = true;
                    this.selected = syl.name.toLowerCase();
                  }
                }
                arr.push(syllabusObj)                
            });
            return arr
        } catch (error) {
            console.error("error",error)
            errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "tutorTypesOption",
            errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
            })     
            return arr;
        }
    },
      tutorListing(){
        var len = this.users.length;
        var arr=[];
        try{      
            arr=[];
            for(var i=0;i<len;i++){
                if(this.users[i].userRole && this.users[i].userRole.includes("tutor") && parseInt(this.users[i].tutorStatus) == 0 && this.users[i].status != 4){
                    let tutorialDegree = 'N/A';
                    let userProfilePic = '';
                    if(this.users[i].tutorQualification && this.users[i].tutorQualification.length > 0){
                      tutorialDegree = '';
                      this.users[i].tutorQualification.forEach((items,index)=>{
                        if(items.degree && items.degree !== ''){
                          if(index !== 0){
                          tutorialDegree += ',';     
                          }
                          tutorialDegree += items.degree;    
                        }
                      })
                    }
                    if(this.users[i].profilePicture && Object.keys(this.users[i].profilePicture).length > 0){
                      userProfilePic = this.users[i].profilePicture ? this.users[i].profilePicture : '';
                    }
                    var obj = {
                        id: this.users[i].userRandomId,
                        tutorid : this.users[i].userId ? this.users[i].userId : 'N/A',
                        avatar: userProfilePic ? userProfilePic : '',
                        fullName: this.users[i].firstName +' '+this.users[i].lastName,
                        degree: tutorialDegree ? tutorialDegree : "N/A",
                        mobile_number:this.users[i].phoneNumber ? this.users[i].phoneNumber : 'N/A',
                        experience:this.users[i].tutorTotalExperienceYears ? this.users[i].tutorTotalExperienceYears : 'N/A',
                        resume:this.users[i].tutorResume ? this.users[i].tutorResume : '',
                        status:parseInt(this.users[i].tutorStatus),
                        docId: this.users[i].id,
                        tutorResume: this.users[i].tutorResume ? this.users[i].tutorResume : '',
                    };
                    arr.push(obj);
                }
            }
            return arr;
        }catch(error){
          console.error(error);
          errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "tutorListing",
            errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
            })     
          return arr;
        }
      },    
  },
  created() { 
  },
  methods: {
      onCellClick(data){
        if (data.column.field==="fullName") {
            this.$router.push({path: `/user-management/users/${data.row.docId}`})
        } else if (data.column.field==="resume") {
          if (data.row.tutorResume) {
            this.downloadDocument(data.row.tutorResume)
          }
        }
        else if (data.column.field ==="action") {
          return;
        }
        else {
        this.$router.push({name: 'tutor-verification-details', params: { id: data.row.docId, name: data.row.fullName }})
        }
      },
      getStatusBadgeVariant(value){        
        var variant = "";
        try{
          variant = "light-";
          if(this.getStatusList && this.getStatusList !== null){

            let statusObj =  this.getStatusList.tutorVerificationStatus;
            for (const property in statusObj) {            
              if(value == property){  
                  variant += statusObj[property].variant;
              }      
            }
          }
        }catch(error){
          console.error(error,"error");
          errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "getStatusBadgeVariant",
            errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
            })     
          return variant;
        }
        return variant;
      },
      getStatusName(statusKey){
          var status = "";
        try{
          if(this.getStatusList && this.getStatusList !== null){

            let statusObj =  this.getStatusList.tutorVerificationStatus;
            for (const property in statusObj) {            
              if(statusKey == property){  
                  status = statusObj[property].status;
              }      
            }
          }
        }catch(error){
          console.error(error,"error");
          errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "getStatusName",
            errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
            }) 
          return status;
        }
        return status;
      },
      verifiedTutor(data,type){
        try{          
          this.tutorStatusData = data;
          if(type == 'Verify'){
            this.verificationModel = true;
          }
        }catch(error){
          console.error(error)
          errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "verifiedTutor",
            errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
            }) 
        }
      },
      downloadDocument(doc){
            var self = this
            self.isUpdating = true;
            try{
            if(doc !== undefined && doc !== ''){
             var httpsReference = firebase.storage().refFromURL(doc)
            self.$axios({
                url: doc,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', httpsReference.name);
                document.body.appendChild(fileLink);

                fileLink.click();
                self.isUpdating = false;
            })
            .catch(error=>{
                console.error(error)
                self.isUpdating = false;
                self.$root.$emit('showToastMessage',error.message,'danger')
            })
            }else{
              self.isUpdating = false;
              self.$root.$emit('showToastMessage','Not uploaded resume.','danger')
            }
            }catch(error){
              self.isUpdating = false;
              self.$root.$emit('showToastMessage',error.message,'danger')
              console.error(error,"error")
              errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "downloadDocument",
              errorMessage : error.message
              },(res)=>{
                if(res.error){
                    console.error(res.error)
                }
              }) 
            }
      },
      resetModal(){
        this.reason = ""
      },
      rejectTutorVerification(bvModalEvt){
        bvModalEvt.preventDefault();
        this.rejectingTutor();
      },
      rejectingTutor(){
        try{
          let data = this.tutorStatusData;
          this.$refs.formReject.validate().then((valid) => {
            if(valid && data && Object.keys(data).length > 0){
              this.isUpdating = true;
              let updatedObj = {
                'tutorStatus':2,
                'rejectReason': this.reason,
                'userRole': ["learner"]
              }
              updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,data.docId,updatedObj,res=>{
                sendNotificationOnTututorVerification({
                    uid: data.docId,
                    tutorStatus: "Reject",
                    rejectReason: this.reason
                },() => {})
                this.isUpdating = false;
                this.$root.$emit('showToastMessage',"Reject successfully.","success")
                this.reason = ""
                this.$bvModal.hide("modal-reject-tutorVerfication")              
              })
            }
          })
        }catch(error){
          this.isUpdating = false;
          console.error(error,"error")
            errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "rejectingTutor",
            errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
            }) 
        }
      },
      tutorFinalVerification(type){
         try{
             this.$refs.approveTutor.validate().then((valid) => {
               if (valid) {  
                 let userId = this.tutorStatusData.docId;
                 this.modalProcessor = true;
                 this.isSpinner = true;
                 let updatedObj = {
                   'tutorStatus':1,
                   'tutorType':this.selected,
                   'tutorverifyTimestamp':new Date()
                 }
                 if(this.selected && this.selected !== null && this.selected !== undefined){
                   let getValue = this.gettutorTypesList.findIndex((item)=>{
                     if(this.selected && this.selected !== null){
                       return item.name.toLowerCase() == this.selected.toLowerCase()
                     }
                   })
                   if(getValue > -1){
                     updatedObj.tutorType = this.gettutorTypesList[getValue].name
                   }            
                 }
                 updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,userId,updatedObj,res=>{
                  sendNotificationOnTututorVerification({
                    uid: userId,
                    tutorStatus: "Verify",
                    rejectReason: this.reason,
                    tutorType: updatedObj.tutorType
                  },() => {})
                  updateInUserChartWhenUserRoleIsChange({
                    uid: userId,
                    role: "tutor"
                  },() => {})
                  this.$root.$emit('stopSpinner');
                  this.$root.$emit('updateTutorStatus',type);
                  this.modalProcessor = false;      
                  this.verificationModel = false;    
                  this.isSpinner = false;
                  this.$root.$emit('showToastMessage','Tutor verified successfully.','success');
                 })
               }
             })
          }catch(error){
          console.error(error,"error")
          this.$root.$emit('stopSpinner');
          this.modalProcessor = false;      
          this.verificationModel = false;    
          this.isSpinner = false;
          errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "tutorFinalVerification",
          errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          }) 
        }        
    },
  },
};
</script>
<style scoped>

.position-relative.table-responsive thead tr th {
  white-space: nowrap !important;
}
.table-hover tbody tr:hover {
  background-color: rgba(186, 22, 40, 0.12);
}

.drop-btn button {
  padding: 0.786rem 0.6rem !important;
}

.table-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

/* Badge primay variant Start*/
.badge.badge-light-primary {
  color: #7367f0 !important;
}
[dir] .badge.badge-light-primary {
  background-color: rgba(115, 103, 240, 0.12);
}
/* Badge primay variant  END*/

.s_modal_title {
  width: 100%;
  font-weight: 600;
  font-size: 20px;
}

[dir] .modal-header {
  padding: 0.5rem 1.4rem !important;
}
[dir] .modal-body {
  padding: 0rem 1.4rem !important;
}
span.resume.download_btn p {
    text-decoration: underline;
    cursor: pointer;
    color: #0096DB !important;
}

</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
