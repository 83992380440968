<template>
<div>
    <b-spinner
      variant="primary"
      v-if="isLoading"
      large
      class="pageLoadSpinners loadSpinner"
    />
    <div v-show="!isLoading">
      <b-tab title="Bank & Doc. Details" @click="onBankDocTab()">
        <b-spinner
          variant="primary"
          v-if="isSpinner"
          large
          class="pageLoadSpinners loaderDetail"
          style="z-index: 999999; margin-left: -160px; margin-top: 350px"
        />
        <div class="bank_detail_block">
          <b-row>
            <b-col lg="12">
              <b-row class="align-items-center">
                <b-col lg="6">
                  <h2 class="mb-0">Bank Account Detail</h2>
                </b-col>
                <b-col lg="6" class="text-right">
                  <b-button
                    variant="outline-primary"
                    :to="{
                      name: 'edit-bank-account',
                      params: {
                        name: $route.params.name,
                        id: $route.params.docID,
                      },
                    }"
                    class="bank_edit_round"
                    style="visibility: hidden"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="12">
              <hr />
            </b-col>
            <b-col xl="3" lg="4" md="5">
              <h5>Account Number</h5>
              <p
                v-if="
                  user_data.tutorBankAccount != undefined &&
                  user_data.tutorBankAccount
                "
              >
                {{
                  user_data.tutorBankAccount.accountNumber
                    ? user_data.tutorBankAccount.accountNumber
                    : ""
                }}
              </p>
              <p v-else>N/A</p>
            </b-col>
            <b-col xl="3" lg="4" md="5">
              <h5>Confirm Account Number</h5>
              <p
                v-if="
                  user_data.tutorBankAccount != undefined &&
                  user_data.tutorBankAccount
                "
              >
                {{
                  user_data.tutorBankAccount.accountNumber
                    ? user_data.tutorBankAccount.accountNumber
                    : ""
                }}
              </p>
              <p v-else>N/A</p>
            </b-col>
            <b-col xl="3" lg="4" md="5">
              <h5>SWIFT/BIC Code</h5>
              <p
                v-if="
                  user_data.tutorBankAccount != undefined &&
                  user_data.tutorBankAccount
                "
              >
                {{
                  user_data.tutorBankAccount.swiftbicCode
                    ? user_data.tutorBankAccount.swiftbicCode
                    : ""
                }}
              </p>
              <p v-else>N/A</p>
            </b-col>
            <b-col xl="3" lg="4" md="5">
              <h5>Account Holder Name</h5>
              <p
                v-if="
                  user_data.tutorBankAccount != undefined &&
                  user_data.tutorBankAccount
                "
              >
                {{
                  user_data.tutorBankAccount.accountHolderName
                    ? user_data.tutorBankAccount.accountHolderName
                    : ""
                }}
              </p>
              <p v-else>N/A</p>
            </b-col>
          </b-row>
        </div>
        <div class="bank_detail_block">
          <b-row>
            <b-col lg="12">
              <b-row class="align-items-center">
                <b-col lg="6">
                  <h2 class="mb-0">Qualification Document</h2>
                </b-col>
                <b-col lg="6" class="text-right">
                  <b-button
                    variant="outline-primary"
                    :to="{
                      name: 'edit-qualification-document',
                      params: {
                        name: $route.params.name,
                        id: $route.params.docID,
                      },
                    }"
                    class="bank_edit_round"
                    style="visibility: hidden"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="12">
              <hr />
            </b-col>
            <div
              style="display: contents"
              v-show="
                user_data.tutorQualification != undefined &&
                user_data.tutorQualification.length != 0
              "
              v-for="(item, index) in user_data.tutorQualification"
              :key="index"
            >
              <b-col xl="3" lg="4" md="5">
                <h5>Degree</h5>
                <p>{{ item.degree ? item.degree : "-" }}</p>
              </b-col>
              <b-col xl="3" lg="4" md="5">
                <h5>University Name</h5>
                <p>{{ item.universityName ? item.universityName : "-" }}</p>
              </b-col>
              <b-col xl="3" lg="4" md="5">
                <h5>Passing Year</h5>
                <p>{{ item.passingYear ? item.passingYear : "-" }}</p>
              </b-col>
              <b-col lg="3">
                <h5>Document</h5>
                <p>{{ item.tutorDocName ? item.tutorDocName : "" }}</p>
                <p class="downloadresume bankDoc" :hidden='item.tutorDocName==undefined||item.tutorDocName==""'>
                  <a
                    style="color: #0096db"
                    @mousedown="downloadQualification(item, 'qualification')"                
                  >
                    <feather-icon icon="ArrowDownCircleIcon" size="18" />Download</a
                  >
                </p>
                <p v-show='item.tutorDocName==undefined||item.tutorDocName==""'>-</p>
              </b-col>
            </div>
            <div
              style="display: contents"
              v-if="
                user_data.tutorQualification == undefined ||
                user_data.tutorQualification.length == 0
              "
            >
              <p class="recordnotfound">No record found</p>
            </div>
          </b-row>
          <hr />
        </div>
        <div class="bank_detail_block">
          <b-row>
            <b-col lg="12">
              <b-row class="align-items-center">
                <b-col lg="6">
                  <h2 class="mb-0">Identity Document</h2>
                </b-col>
                <b-col lg="6" class="text-right">
                  <b-button
                    variant="outline-primary"
                    :to="{
                      name: 'edit-identity-document',
                      params: {
                        name: $route.params.name,
                        id: $route.params.docID,
                      },
                    }"
                    class="bank_edit_round"
                    style="visibility: hidden"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="12">
              <hr />
            </b-col>
            <div
              style="display: contents"
              v-show="
                user_data.tutorIdentityDocument != undefined &&
                user_data.tutorIdentityDocument.length != 0
              "
              v-for="(item, index) in user_data.tutorIdentityDocument"
              :key="index"
            >
              <b-col xl="3" lg="4" md="5">
                <h5>Document ID Number</h5>
                <p>{{ item.documentIDNumber ? item.documentIDNumber : "" }}</p>
              </b-col>
              <b-col xl="3" lg="4" md="5">
                <h5>Document Name</h5>
                <p>{{ item.documentName ? item.documentName : "" }}</p>
              </b-col>
              <b-col xl="3" lg="4" md="5">
                <h5>Document Front Side</h5>
                <p :hidden='!item.docFontName || item.docFontName == ""'>{{ item.docFontName ? item.docFontName : "" }}</p>
                <p class="downloadresume bankDoc" :hidden='!item.docFontName || item.docFontName == ""'>
                  <a :href="item.documentFrontSide" target="_blank">
                    <feather-icon icon="EyeIcon" size="18" />View</a
                  >
                  <a
                    style="color: #0096db"
                    @mousedown="downloadQualification(item, 'identityFont')"
                  >
                    <feather-icon icon="ArrowDownCircleIcon" size="18" />Download</a
                  >
                </p>
                <p v-show='!item.docFontName || item.docFontName == ""'>-</p>
              </b-col>
              <b-col xl="3" lg="4" md="5">
                <h5>Document Back Side</h5>
                <p :hidden='!item.docBackName || item.docBackName == ""'>{{ item.docBackName ? item.docBackName : "" }}</p>
                <p class="downloadresume bankDoc" :hidden='!item.docBackName || item.docBackName == ""'>
                  <a :href="item.documentBackSide" :hidden='!item.docBackName || item.docBackName == ""' target="_blank">
                    <feather-icon icon="EyeIcon" size="18" />View</a
                  >
                  <a
                    style="color: #0096db"
                    :hidden='!item.docBackName || item.docBackName == ""'
                    @mousedown="downloadQualification(item, 'identityBack')"
                  >
                    <feather-icon icon="ArrowDownCircleIcon" size="18" />Download</a
                  >
                </p>
                <p v-show="!item.docBackName || item.docBackName == ''">-</p>
              </b-col>
            </div>
            <div
              style="display: contents"
              v-if="
                user_data.tutorIdentityDocument == undefined ||
                user_data.tutorIdentityDocument.length == 0
              "
            >
              <p class="recordnotfound">No record found</p>
            </div>
          </b-row>
          <hr />
        </div>
      </b-tab>
    </div>
</div>
</template>
<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import axios from "axios";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import firebase from "@/utils/firebaseInit";
import { dbCollections } from "@/utils/firebaseCollection.js";
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Users/User/UserDetailTabs/BanckDoc_Detail_learner.vue"
export default {
  name: "PastSessions",
  directives: {
    Ripple,
  },
  components: {
    VueGoodTable,
    BCard,
    BImg,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BCardText,
    BTabs,
    BTab,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    flatPickr,
    BSpinner,
  },
  data() {
    return {
      isLearner: true,
      direction: false,
      isSpinner: false,
      isLoading : false,
      isUpdating: false,
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      user_data: {},
    };
  },
  created() {},
  methods: {
    limitTo: function (value) {
      if (!value) return "";
      return value.substring(0, 90) + "...";
    },
    downloadQualification(data, type) {
      let url = "";
      let name = "";
      if(this.isSpinner == false){
      try{
      this.isSpinner = true;
      if (type == "qualification") {
        url = data.uploadDocument ? data.uploadDocument : '';
        name = data.tutorDocName ? data.tutorDocName : '';
      }
      if (type == "identityFont") {
        url = data.documentFrontSide ? data.documentFrontSide : '';
        name = data.docFontName ?  data.docFontName : '';
      }
      if (type == "identityBack") {
        url = data.documentBackSide ? data.documentBackSide : '';
        name = data.docBackName ? data.docBackName : '';
      }
      if(url !== ''){
        axios
          .get(url, { responseType: "blob" })
          .then((response) => {
            const blob = new Blob([response.data], { type: response.data.type });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = name ? name : "Test";
            link.click();
            URL.revokeObjectURL(link.href);
            this.isSpinner = false;
          })
          .catch((error) => {
            this.isSpinner = false;
            console.error(error, "error");
          });
        }else{
          this.isSpinner = false;
          this.$root.$emit('showToastMessage','Not uploaded resume.','danger');
        }
      }catch(error){
        this.isSpinner = false;
        console.error(error,"error")
        errorEmail({
        projectName : process.env.VUE_APP_PROJECTNAME,
        environment : process.env.NODE_ENV,
        fileName : currentFileName,
        hookName : "downloadQualification",
        errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        }) 
      } 
      }         
    },
    onBankDocTab() {
      var self = this;
      try {
        self.isLoading = true;
        getFirebase.simpleGetDataFromSubCollectionWithDocID(
          dbCollections.USERS,
          this.$route.params.id,
          (userData) => {
            if (userData || userData !== null) {
              try {
                self.isLoading = true;
                if (Object.keys(userData).length > 0) {
                  const storage = firebase.storage();
                  if (
                    userData.tutorQualification !== undefined &&
                    userData.tutorQualification !== null
                  ) {
                    userData.tutorQualification.forEach((values) => {
                      values.tutorDocName = "";
                      let fileRef = storage.refFromURL(values.uploadDocument);
                      values.tutorDocName = fileRef.name.split("_")[1];
                    });
                  }
                  if (
                    userData.tutorIdentityDocument !== undefined &&
                    userData.tutorIdentityDocument !== null
                  ) {
                    let indenty_doc = userData.tutorIdentityDocument;
                    indenty_doc.forEach((element) => {
                      let fname = '';
                      if(element.documentFrontSide !== undefined && element.documentFrontSide !== ''){
                      fname = firebase
                        .storage()
                        .refFromURL(element.documentFrontSide);
                      }
                      let bname = '';
                      if(element.documentBackSide !== undefined && element.documentBackSide !== ''){
                      bname = firebase
                        .storage()
                        .refFromURL(element.documentBackSide);
                      }
                      element.docFontName = fname.name ? fname.name.split("_")[1] : "";
                      element.docBackName = bname.name ? bname.name.split("_")[1] : "";
                    });
                  }
                }
                self.isLoading = false;
              } catch (error) {
                console.error(error);
                self.isLoading = false;
                errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "onBankDocTab",
                errorMessage : error.message
                },(res)=>{
                  if(res.error){
                      console.error(res.error)
                  }
                }) 
              }
              self.user_data = userData;
              self.isLoading = false;
            } else {
              self.isLoading = false;
            }
          }
        );
      } catch (error) {
        console.error(error, "ERROR");
          errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "onBankDocTab",
          errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          }) 
        self.isLoading = false;
      }
    },
  },
};
</script>