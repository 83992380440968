<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
    </div>
    <b-row>
      <b-col xl="6">
        <b-card>
          <div>
            <validation-observer ref="validateForm">
            <b-form
              ref="form"
              style=" height: auto; "
              class="repeater-form book_session_from"
              @submit.stop.prevent="bookingSession"
            >
              <b-row>
                <b-col md="6">
                  <b-form-group>
                  <label for="learnername">Learner Name<span style="color: red;">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="learner name"
                    rules="required"
                  >
                  
                    <v-select
                      v-model="learner_name_selected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="learner_name_options"
                      label="title"
                      :disabled="isSpinner"
                      placeholder="Select"
                      @input="tutor_name_options(),selectLearnerName(tutor_name_selected)"
                    >
                      <template #option="{ title, avatar, userId }">
                        <div class="d-flex align-items-center justify-content-between">
                          <span>
                            <b-avatar :src="avatar" class="user_img" />
                            <span> {{ title }}</span>
                            </span>
                          <span>                            
                            <span>{{ userId }}</span>
                          </span>
                        </div>  
                      </template>
                    </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>        
                  <small class="text-danger" v-if="learner_name_selected==null && booking">The learner name feild is required</small>
                  <small class="text-danger" v-if="isTutorOrLearnerSame(learner_name_selected,tutor_name_selected)">tutor and learner are not same</small>
                   </validation-provider>
                  </b-form-group>
                </b-col>
              
                <b-col md="6">
                  <b-form-group>
                    <label for="tutorname">Tutor Name<span style="color: red;">*</span></label>
                     <validation-provider
                        #default="{ errors }"
                        name="tutor name"
                        rules="required"
                      >
                    <v-select
                      v-model="tutor_name_selected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="tutorNameOption"
                      label="title"
                      :disabled="isSpinner || learner_name_selected == null || tutorNameOption.length == 1"
                      placeholder="Select"
                      data-icon="tutor_name_options[1].avatar"
                      @input="selectTutorName(tutor_name_selected)"
                    >
                      <template #option="{ title, avatar, userId }">
                       <div class="d-flex align-items-center justify-content-between">
                          <span>
                            <b-avatar :src="avatar" class="user_img" />
                            <span> {{ title }}</span>
                            </span>
                          <span>                            
                            <span>{{ userId }}</span>
                          </span>
                        </div>
                      </template>
                    </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger" v-if="tutor_name_selected==null && booking">The tutor name feild is required</small>

                   </validation-provider>
                  </b-form-group>
                </b-col>
                <small class="text-danger" v-if="errorMessageForSameTutorAndLearner!= ''">{{errorMessageForSameTutorAndLearner}}</small>
                <b-col md="6">
                  
                  <b-form-group>
                    <label for="category">Category<span style="color: red;">*</span></label>
                    <validation-provider
                    #default="{ errors }"
                    name="category"
                    rules="required"
                  >
                    <b-form-select
                      v-model="category_selected"
                      :options="category_options"
                       :disabled="isSpinner|| tutor_name_selected == null"
                    />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger" v-if="category_selected==null && booking && errors[0] == ''">The category name is required</small>

                  </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label for="sessiontitle">Session Title<span style="color: red;">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="Session Title"
                    rules="required"
                  >
                    <b-form-select
                      v-model="sessiontitle_selected"
                      :options="sessiontitle_options"
                      :disabled="isSpinner|| category_selected == null"
                    />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger" v-if="sessiontitle_selected==null && booking && errors[0] == ''">The session title feild is required</small>

                  </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <hr />

                  <b-form-group class="custom_checkbox_group">
                  
                    <b-form-checkbox v-model="checkselectedFirst"  :disabled="isSpinner || tutor_name_selected == null  || holidayMode || items[0].errorMessageForDate != ''"  @change="selectCheckbox('firstCheckbox')">
                      Do you want booking for 7 days?
                    </b-form-checkbox>
                  
                 
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                v-for="(item, index) in items"
                :key="index"
                 ref="row"
              >
                <b-col md="6">
                  <b-form-group>
                    <label for="item-name">Date<span style="color: red;">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="Booking date"
                    rules="required"
                  >
                    <b-form-datepicker
                      id="datepicker-placeholder"
                      placeholder="DD/MM/YYYY"
                      local="en"
                      :min="tommorrowDate"
                      :max="futureDate"
                      :disabled="isSpinner || tutor_name_selected == null || holidayMode || sessiontitle_selected==null"
                      @input="selectDate(item.date,index)"
                      v-model="item.date"
                    />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger" v-if="item.date=='' && booking && item.errorMessageForDate == ''">The date feild is required</small>
                  <small class="text-danger" v-if="errorMessageOfDate != '' && item.date == '' ">{{errorMessageOfDate}}</small>
                  <small class="text-danger" v-if="item.errorMessageForDate !=''" >{{item.errorMessageForDate}}</small>
                  </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label for="item-name">Time<span style="color: red;">*</span></label>
                  <validation-provider
                    #default="{ errors }"
                    name="start and end time"
                    rules="required"
                  >
                    <b-row class="align-items-center">
                      <b-col md="5" class="pr-0">
                         <v-select
                          v-model="item.timevalue1"
                          :options="item.fromArray"
                          label="text"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          persistent-hint
                          return-object
                          single-line
                          :disabled="isSpinner || item.date == '' || item.errorMessageForDate != ''"
                          @input="selectFromDate(item.timevalue1.text,index,item.date)"
                          class="selection sectionul"
                          :class="{'addClassForPlaceHolder':item.timevalue1==''}"
                        ></v-select>
                      </b-col>
                      <b-col md="2" class="text-center p-0">to</b-col>
                      <b-col md="5" class="pl-0">
                        <v-select
                          v-model="item.timevalue2"
                          :options="item.toArray"
                          label="text"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          persistent-hint
                          return-object
                          single-line
                          :disabled="isSpinner"
                          class="selection sectionul"
                          :class="{'addClassForPlaceHolder':item.timevalue2==''}"
                          @input="selectToDate(index)"
                        ></v-select>
                      </b-col>
                    </b-row>
                   <small class="text-danger">{{ errors[0] }}</small>
                   <small class="text-danger" v-if="item.errorMessageForTime !=''" >{{item.errorMessageForTime}}</small>
                  </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12" class="mb-50 text-right">
                  <b-button
                    variant="flat-danger"
                    class="p-0 text-decoration-underline"
                    @click="removeItem(index)"
                    :disabled="isSpinner"
                    v-if="items.length > 1"
                  >
                    <span>Remove</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button
                    variant="flat-primary"
                    class="p-0 text-decoration-underline"
                    @click="repeateAgain"
                    :disabled="isSpinner || tutor_name_selected == null  || holidayMode ||items[0].errorMessageForDate != '' ||items.length == 7||items[0].date==''"                    
                  >
                    <span>+ Add New</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
              <b-row class="align-items-center">
                <b-col cols="6">
                  <p class="mb-0">{{parseFloat(slotCredit).toFixed(2)}} Credits Use</p>
                </b-col>
                <b-col cols="6 text-right">
                  <b-button
                    type="reset"
                    variant="outline-primary"
                    class="mr-2"
                    @click="resetData()"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    type="submit"
                    variant="primary"
                    :disabled="isSpinner"
                  >
                    Book Session
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
            </validation-observer>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BCol,
  BRow,
  BForm,
  BFormRow,
  BButton,
  BFormGroup,
  BFormSelect,
  BFormTextarea,
  BFormCheckbox,
  BFormDatepicker,
  BAvatar,
  BSpinner,
  BFormTimepicker,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import moment_timezone  from "moment-timezone"
import { heightTransition } from "@core/mixins/ui/transition";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {dbCollections} from "@/utils/firebaseCollection.js";
import * as insertDatabase from "@/utils/FirebaseQueries/insertQueries/insertQueries.js";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import { mapGetters, mapActions } from "vuex";
import moment from 'moment'
import firebase from "@/utils/firebaseInit"
import axios from "axios";
const db = firebase.firestore();
import { adminBookingNotification } from '../../../SessionsManagement/PendingSession(s)/service.js';
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Users/User/BookingSession/Bookingsession_all_session.vue";
import {DateTime} from 'luxon';
import {updateBookingTimeOfTutorInTimeSlotCollection} from "./updateSlotOfBookingService.js"
import {checkBookingTimeOfTutorInTimeSlotCollection} from "./checkSlotOfBookingService.js"
import {revertBookingTimeOfTutorInTimeSlotCollection} from "./revertSlotOfBookingService.js"
import { includes } from 'postcss-rtl/lib/affected-props';
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BForm,
    BButton,
    BFormRow,
    BCol,
    BRow,
    BFormGroup,
    BFormTextarea,
    BCard,
    BSpinner,
    BFormSelect,
    BFormCheckbox,
    BFormDatepicker,
    BFormTimepicker,
    vSelect,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      tommorrowDate:new Date(new Date().getTime() + 48 * 60 * 60 * 1000),
      direction:false,
      isSpinner:false,
      isUpdating:false,
      checkselectedFirst: '',
      checkselectedSecond:'',
      date:"",
      errorMessageForSameTutorAndLearner:'',
      tutor_name_selected: null,
      learner_name_selected: null,
      category_selected: null,
      sessiontitle_selected: null,
      items: [
        {
          date: "",
          timevalue1: "",
          timevalue2: "",
          errorMessageForTime:"",
          errorMessageForDate:'',
          fromArray:[],
          toArray:[],
          errorOnToTime:'test'
        },
      ],
      nextTodoId: 2,
      selectedCheckboxValue:'',
      errorMessageOfDate:'',
      tutorHolidayArray:[],
      allDateArray:[],
      fromTimeOption:[],
      holidayMode:false,
      futureDate:  new Date(new Date().setDate(new Date().getDate() + 8)),
      tutorTimeSlotArray:[],
      toTimeOption:[],
      weekDayArray:['sunday','monday','tuesday','wednesday','thursday','friday','saturday'],
      tutorTimeSlot:[],
      sessionId:this.$route.params.id,
      bookedArray:{},
      booking:false,
      oneMinuteCreditOfUser:0,
      slotCredit:0,
      userAvailableCredits:0,
      remainingCredit:0,
      bookingId:'',
      tutorNameOption:[],
      bookingResponse:{},
      bookedArrayLearner:[],
      teachingLanguageDetails:{},
      learnerTimeZone:"",
      parentDetail : {},
      bookingPaidToTutor:0,
    };
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    
    if (this.$route.query.userDetail) {
      var autoName = JSON.parse(localStorage.getItem("bookSession"))         
      this.learner_name_selected = autoName ? autoName.learnerName : null
      if(autoName.tutor){
        this.getUsersList.forEach(tutorData => {
          if(tutorData.id === autoName.tutor){
            const tutorArray = {
              id:tutorData.id,
              userId:tutorData.userId,
              avatar:tutorData.profilePicture,
              title:`${tutorData.firstName}${tutorData.lastName}`,
              firstName:tutorData.firstName,
              lastName:tutorData.lastName,
              tutorType:tutorData.tutorType ? tutorData.tutorType : '',
              rating:(tutorData.tutorTotalRatingSum/tutorData.tutorTotalRatingUserCount),
              availableCredit:tutorData.currentCredit ? tutorData.currentCredit : 0
            }
            this.tutor_name_selected = tutorArray;
            this.selectTutorName(this.tutor_name_selected);
          }
        })
      }
    }
    var self = this;
    window.addEventListener("resize", self.initTrHeight);
     if(self.sessionId){
      self.ifSessionIdIsThere();
     }
  },
  updated(){
    this.$refs.validateForm.reset();
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  computed: {
    ...mapGetters({categories:'categoryManagement/getCategories',
                  getUsersList:'userManagement/getUsers',
                  sessions:'sesionManagement/getSessions',
                  settingArray:'settings/getsettingArrayFeild',
                  getBookinglist : 'booking/getBooking',
                  }),
    category_options(){
      
      var self = this;
      var found = false;
      var array = [];
      array.push({value : null , text: "Select"})
      try{
        if(self.tutor_name_selected){
          self.sessions.forEach(cat => {
            if(cat.status==2 && cat.tutor === self.tutor_name_selected.id){
              var obj = {
                value:cat.categoryDetails.id,
                text:cat.categoryDetails.categoryName,
              }
              if(array.length == 0){
                array.push(obj);
              }else{
                array.forEach(catData => {
                  if(catData.value == obj.value){
                    found = true;
                  }
                })
                if(!found){
                  array.push(obj);
                }
              }
            }
          })
          return array;
        }
      }
      catch(error){
        console.error('error',error)
        errorEmail({
        projectName : process.env.VUE_APP_PROJECTNAME,
        environment : process.env.NODE_ENV,
        fileName : currentFileName,
        hookName : "category_options",
        errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        }) 
      }
    },
    sessiontitle_options(){
      var self = this;
      var array = [];
      array.push({value : null , text: "Select"})
      try{
        if(self.tutor_name_selected){
          self.sessions.forEach(session => {
            if(session.status==2 && session.tutor === self.tutor_name_selected.id){
              var obj = {
                id:session.id,
                value:session.title,
                text:session.title,
                teachingLanguageDetails : session.teachingLanguageDetails
              }
              array.push(obj);
            }
          })
          return array;
        }
      }
      catch(error){
        console.error('error',error)
        errorEmail({
        projectName : process.env.VUE_APP_PROJECTNAME,
        environment : process.env.NODE_ENV,
        fileName : currentFileName,
        hookName : "sessiontitle_options",
        errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        }) 
      }
    },
    learner_name_options(){
      var self = this;
      var array = [];
      array.push({id : null , title: "Select"})
      try{
        self.getUsersList.forEach(user => {
          if(user.userRole.includes('learner') && user.status == 1){
            var obj = {
              id: user.id,
              userId:user.userId,
              firstName:user.firstName,
              lastName:user.lastName,
              title: `${user.firstName} ${user.lastName}`,
              avatar: user.profilePicture,
              tutorType:user.tutorType ? user.tutorType : '',
              rating:(user.tutorTotalRatingSum/user.tutorTotalRatingUserCount),
              availableCredit:user.currentCredit ? user.currentCredit : 0,
              isChild:user.isChild,
              parentId:user.parentId ? user.parentId : '',
              timeZone: user.timeZone
            }
            array.push(obj);
          }
        })
        return array;
      }
      catch(error){
        console.error('error',error)
        errorEmail({
        projectName : process.env.VUE_APP_PROJECTNAME,
        environment : process.env.NODE_ENV,
        fileName : currentFileName,
        hookName : "learner_name_options",
        errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        }) 
      }
    },
   
  },
  methods: {
    ifSessionIdIsThere(){
      
      var self = this;
        self.isSpinner = true;
        setTimeout(()=>{
          let isInArray = self.sessions.some(function(item) {
              return item.id == self.sessionId
          });
          if(isInArray){
            self.sessions.forEach(session => {
              if(session.id == self.sessionId){
                self.sessiontitle_selected = session.title;
                self.category_selected = session.category
                self.getUsersList.forEach(userData => {
                  if(userData.id == session.tutor){
                    const tutorArray = {
                      id:userData.id,
                      userId:userData.userId,
                      avatar:userData.profilePicture,
                      title:`${userData.firstName}${userData.lastName}`,
                      firstName:userData.firstName,
                      lastName:userData.lastName,
                      tutorType:userData.tutorType ? userData.tutorType : '',
                      rating:(userData.tutorTotalRatingSum/userData.tutorTotalRatingUserCount),
                      availableCredit:userData.currentCredit ? userData.currentCredit : 0
                    }
                    self.tutor_name_selected = tutorArray;
                    self.selectTutorName(tutorArray);

                  }
                  self.isSpinner = false;
                })
              }
            })
          }else{
            self.$router.push({name: 'error-404'})
          }
        },1000)
    },
    repeateAgain() {
      var self = this;
      self.items.push({
        date:'',
        timevalue1:'',
        timevalue2:'',
        errorMessageForTime:'',
        errorMessageForDate:'',
        fromArray:[],
        toArray:[],
        errorOnToTime:'test'
      });
    },
    tutor_name_options(){
      var self = this;
      var array = [];
      self.tutorNameOption = [];
      if(self.learner_name_selected != null){
        array.push({id : null , title: "Select"})
        try{
          getFirebase.simpleGetDataWithSpecificWhereCondition(dbCollections.CONNECTEDUSERS,'learner','==',self.learner_name_selected.id,(res) => {
            if(res){
              res.forEach(activetutor => {
                var index = self.getUsersList.findIndex(item => {
                  return item.id == activetutor.tutor && item.status == 1 && activetutor.status == 1
                })
                if(index > -1){
                  var obj = {
                    id: self.getUsersList[index].id,
                    userId:self.getUsersList[index].userId,
                    firstName:self.getUsersList[index].firstName,
                    lastName:self.getUsersList[index].lastName,
                    title: `${self.getUsersList[index].firstName} ${self.getUsersList[index].lastName}`,
                    avatar: self.getUsersList[index].profilePicture,
                    tutorType:self.getUsersList[index].tutorType ? self.getUsersList[index].tutorType : '',
                    rating:(self.getUsersList[index].tutorTotalRatingSum/self.getUsersList[index].tutorTotalRatingUserCount),
                    availableCredit:0,
                    isChild:self.getUsersList[index].isChild,
                    parentId:self.getUsersList[index].parentId ? self.getUsersList[index].parentId : ''
                  }
                  array.push(obj);
                }
              })
              self.tutorNameOption =  array;
            } else {
            }
          })
        }
        catch(error){
          console.error('error',error)
           errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "tutor_name_options",
          errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          }) 
        }
      }
    },
    incrementDate(dates){
      var date = new Date(dates);
      date.setDate(date.getDate() + 1);
      var returnDate = date.getFullYear()+'-'+this.addZero(date.getMonth()+1)+'-'+date.getDate();
      return returnDate;
    },
    incrementDates(dates){
      try {
        var date = new Date(dates);
        date.setDate(date.getDate() + 1);
         var returnDate = date.getDate()+'-'+(date.getMonth()+1)+'-'+date.getFullYear();
        return returnDate;
      } catch (error) {
        console.error(error, "incrementDates");
        return '';
      }
    },
    getDates(start, end) {
      
      var arr = [];
      var endDate = new Date(end)
      endDate.setDate(endDate.getDate()+1)
        for(var arr=[],dt=new Date(start); dt<=endDate; dt.setDate(dt.getDate()+1)){
            arr.push(new Date(dt));
        }
        return arr;
    },
    CombineDateAndTime(date, time) {
      const combined = new Date(date + ' ' + time);
      return combined;
    },
    CombineDateAndTime2(date, time) {
      const combined = new Date((date + ' ' + time));
      var local = DateTime.local(parseInt(moment(combined).format("YYYY")),parseInt(moment(combined).format("MM")),parseInt(moment(combined).format("DD")),parseInt(moment(combined).format("HH")),parseInt(moment(combined).format("mm")));
      var rezoned = local.setZone(this.learnerTimeZone);
      let startDiff = (new Date().getTimezoneOffset() * -1) - rezoned.offset
      let starting = moment(combined, "DD-MMM-YYYY HH:mm").add(startDiff, "minutes")
      return new Date(starting);
    },
    CalculateMinute(time1,time2){
      var startTime = moment(time1, 'HH:mm a');
      var endTime = moment(time2, 'HH:mm a');

      var duration = moment.duration(endTime.diff(startTime));
      var difInMin = (duration.hours() * 60) + duration.minutes()
      return difInMin;
    },
    removeItem(index) {
      var self = this;
      if(self.items[index].timevalue1!= '' && self.items[index].timevalue2 != ''){
        const calculateMinute = self.CalculateMinute(self.items[index].timevalue1.text,self.items[index].timevalue2.text)
        var creditValue = calculateMinute *  self.oneMinuteCreditOfUser
        self.slotCredit-= Number(creditValue.toFixed(2));
        self.items[index].errorMessageForTime = ''
      }
      if(index==1){
        self.items[0].errorMessageForTime = '';
      }
        self.items.splice(index, 1);
    },
    initTrHeight() {
    },
    formatAMPM(hour,min) {
      var hours = hour;
      var minutes = min;
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      var strTime = hours + ':' + minutes;
      return strTime;
    },
    addZero(value){
      if(value > 9){
        return value;
      }else{
        return '0' + value;
      }
    },
    isTutorOrLearnerSame(learner_name_selected,tutor_name_selected){
      if(JSON.stringify(tutor_name_selected) === JSON.stringify(learner_name_selected)  && learner_name_selected != null && tutor_name_selected != null){
        return true;
      }else{
        return false;
      }
    },
    getDataFromPatentId(){
      var self = this;
      return new Promise((resolve,reject)=>{
        try{
          getFirebase.simpleGetDataWithSpecificWhereCondition(dbCollections.USERS,'id','==',self.learner_name_selected.parentId,(res) => {
            if(res.length > 0){
              self.parentDetail = {
                firstName:res[0].firstName,
                lastName:res[0].lastName,
                profileImage:res[0].profilePicture ? res[0].profilePicture : '',
                id: res[0].id,
                userId:res[0].userId,
                availableCredit:res[0].currentCredit ? res[0].currentCredit : 0,
              }
              self.remainingCredit = self.parentDetail.availableCredit
              self.userAvailableCredits = self.parentDetail.availableCredit;
              resolve('error not found')
            }
          })
        }catch (error) {
          this.isSpinner = false
          console.error(error)
          errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "getDataFromPatentId",
          errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          }) 
          reject('error found getDataFromPatentId')
        }
      })
    },
    bookingSession(){
      try {
      var self = this;
      self.isSpinner = true;
      self.booking = true;
      self.remainingCredit = 0;
      let transactionObject = {};
      let parentDetails = {}
      if(self.learner_name_selected.isChild){
        self.getDataFromPatentId().then(() => {
          parentDetails = {
            firstName:self.parentDetail.firstName,
            lastName:self.parentDetail.lastName,
            profileImage:self.parentDetail.profileImage
          }
        }).catch(()=>{
          this.isSpinner = false;
        })
      }else{
        self.remainingCredit = self.learner_name_selected.availableCredit
        self.userAvailableCredits = self.learner_name_selected.availableCredit;
      }
      var CatIndex = self.category_options.findIndex(item => {
        return item.value == self.category_selected
      })
      if(CatIndex > -1){
        var CategoryDetail = {
          id:self.category_selected,
          categoryName :self.category_options[CatIndex].text
        }
      }
      var sessionIndex = self.sessiontitle_options.findIndex(item => {
        return item.value == self.sessiontitle_selected
      })
      if(sessionIndex > -1){
        var SessionDetail = {
          id:self.sessiontitle_options[sessionIndex].id,
          name :self.sessiontitle_selected
        }
        this.teachingLanguageDetails = self.sessiontitle_options[sessionIndex].teachingLanguageDetails;
      }
      var learnerDeatil = {
        firstName:self.learner_name_selected.firstName,
        lastName:self.learner_name_selected.lastName,
        profileImage:self.learner_name_selected.avatar
      }
      var tutorDeatil = {
        firstName:self.tutor_name_selected.firstName,
        lastName:self.tutor_name_selected.lastName,
        profilePicture:self.tutor_name_selected.avatar,
        tutorType:self.tutor_name_selected.tutorType,
        rating:self.tutor_name_selected.rating.toString()
      }
      var meetingDatatext = Math.random().toString(30).slice(5);
      var tutorcreditinformation = {};
      self.settingArray.tutorTypesBookingSeetings.forEach(tutorData => {
        if(tutorData.tutorType == self.tutor_name_selected.tutorType){
          tutorcreditinformation = tutorData
        }
      })
      var index = 0;
      self.checkTutorisOnHoliday().then(() => {
        self.checkSessionIsRepeat().then(() => {
          self.checkIfSessionAlreadyBook().then(() => {
            self.checkIfLearnerSessionAlreadyBook().then(() => {
                self.checkIfErrorIsArrived().then(() => {
                  self.$refs.validateForm.validate().then((success) => {
                    if (success && self.errorMessageForSameTutorAndLearner == '') {
                      if(self.learner_name_selected.id != self.tutor_name_selected.id && self.learner_name_selected.id != null && self.tutor_name_selected.id != null ){
                          let updateCount = 0
                          let updateCountFunction = (update) =>{
                            if (updateCount >= self.items.length) {
                              this.startInterval();
                              this.$bvModal
                                .msgBoxConfirm("Are you sure you want to book session?", {
                                  title: "Confirm",
                                  class: "test",
                                  okVariant: "primary",
                                  okTitle: "Yes",
                                  cancelTitle: "No",
                                  cancelVariant: "outline-primary",
                                  hideHeaderClose: false,
                                  centered: true,
                                  footerClass: "approve-footer",
                                  headerClass: "approve-header",
                                  bodyClass: "approve-body",
                                }).then((value)=>{
                                  if (value) {
                                    if(self.slotCredit <= self.userAvailableCredits && self.slotCredit > 0){
                                      try{
                                        // self.$refs.validateForm.validate().then((success) => {
                                          // if (success && self.errorMessageForSameTutorAndLearner == '') {
                                            // if(self.learner_name_selected.id != self.tutor_name_selected.id && self.learner_name_selected.id != null && self.tutor_name_selected.id != null ){
                                              self.LearnerErrorIsArrived = '';
  
                                              let count = 0;
                                              let showToast = true;
                                              let countFunction = (row) => {
                                                if (count >= self.items.length) {
                                                  const updateCredit = {
                                                      currentCredit : self.remainingCredit
                                                    }
                                                    if(self.learner_name_selected.isChild){
                                                      updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,self.learner_name_selected.parentId,updateCredit,(res) => {
                                                        if (showToast) {
                                                          self.$router.push({name : 'pending-session-mgmt'})
                                                          self.$root.$emit('showToastMessage','Session booked successfully.','success');
                                                        }
                                                        self.booking = false;
                                                        self.isSpinner = false;
                                                        self.slotCredit = 0;
                                                        localStorage.removeItem('bookSession')
                                                        self.resetData();
                                                      })
                                                    }else{
                                                      updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,self.learner_name_selected.id,updateCredit,(res) => {
                                                        if (showToast) {
                                                          self.$router.push({name : 'pending-session-mgmt'})
                                                          self.$root.$emit('showToastMessage','Session booked successfully.','success');
                                                        }
                                                        self.booking = false;
                                                        self.isSpinner = false;
                                                        self.slotCredit = 0;
                                                        localStorage.removeItem('bookSession')
                                                        self.resetData();
                                                      })
                                                  }
                                                  return;
                                                } else {
                                                      checkBookingTimeOfTutorInTimeSlotCollection({
                                                        learnerId: self.learner_name_selected.id,
                                                        tutorId: self.tutor_name_selected.id,
                                                        startTime: moment(row.date+" "+row.timevalue1.text).format("DD-MMM-YYYY HH:mm"),
                                                        endTime: moment(row.date+" "+row.timevalue2.text).format("DD-MMM-YYYY HH:mm"),
                                                        learnerTimeZone: this.learnerTimeZone,
                                                      },(resp)=>{
                                                        if (resp.status) {
                                                          let usedCredets = Math.round(Number((self.CalculateMinute(row.timevalue1.text,row.timevalue2.text)* self.oneMinuteCreditOfUser).toFixed(2)))
                                                          if(row.date != '' && row.timevalue1 != '' && row.timevalue2 != '' && row.errorMessageForTime == ''){
                                                            self.isSpinner = true;
                                                            var rendomId = Math.floor(100000 + Math.random(900000)) +  Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 3) + Math.floor(100 + Math.random(900));
                                                            var sessionObject = {
                                                              createdAt : new Date(),
                                                              updatedAt : new Date(),
                                                              id:'',
                                                              bookingId:rendomId,
                                                              bookingPaidToTutor: (((tutorcreditinformation.paidToTutor) * (usedCredets)) / (tutorcreditinformation.creditValueRequired30Min)),
                                                              bookingsTitle:self.sessiontitle_selected,
                                                              cancellationReason:'test',
                                                              category:self.category_selected,
                                                              categoryDetails:CategoryDetail,
                                                              startTime:self.CombineDateAndTime2(row.date,row.timevalue1.text),
                                                              endTime:self.CombineDateAndTime2(row.date,row.timevalue2.text),
                                                              isApprovedByLearner : false,
                                                              isApprovedByTutor:false,
                                                              learnerId:self.learner_name_selected.id,
                                                              learnerDetails:learnerDeatil,
                                                              session:SessionDetail.id,
                                                              sessionDetails:SessionDetail,
                                                              status:0,
                                                              totalBookingTimeInMinutes:self.CalculateMinute(row.timevalue1.text,row.timevalue2.text),
                                                              totalBookingValue:0,// This feild is static
                                                              transactionId:'',
                                                              tutor:self.tutor_name_selected.id,
                                                              tutorDetails:tutorDeatil,
                                                              tutorlineEarning:0,
                                                              tutorEarningInformation:tutorcreditinformation,
                                                              isTrial:false,
                                                              meetingId:meetingDatatext,
                                                              meetingPassword:meetingDatatext,
                                                              usedCredits:Math.round(Number((self.CalculateMinute(row.timevalue1.text,row.timevalue2.text)* self.oneMinuteCreditOfUser).toFixed(2))),// This feild is static
                                                              teachingLanguageDetails : this.teachingLanguageDetails ? this.teachingLanguageDetails : {}
                                                            }
                                                            this.bookingResponse = sessionObject
                                                            insertDatabase.addDataToRootCollection(dbCollections.BOOKING,sessionObject,(res) => {
                                                              if(res.id){
                                                                self.bookingId = res.id
                                                                const updateObj = {
                                                                  id : res.id
                                                                }
                                                                updateFirebase.updateRootCollectionDataByDocId(dbCollections.BOOKING,res.id,updateObj,(res) => {
                                                                })
                                                              }
                                                            })
                                                            self.remainingCredit = ((self.remainingCredit)-Math.round(Number((self.CalculateMinute(row.timevalue1.text,row.timevalue2.text)* self.oneMinuteCreditOfUser).toFixed(2))));
                                                            if(self.learner_name_selected.isChild){
                                                              transactionObject = {
                                                                  availableCredit:self.remainingCredit,
                                                                  bookingId:rendomId,
                                                                  createdAt:new Date(),
                                                                  debits:Number((self.CalculateMinute(row.timevalue1.text,row.timevalue2.text)* self.oneMinuteCreditOfUser).toFixed(2)),
                                                                  id:'',
                                                                  paymentId:'',// This feild is static
                                                                  reference:0,
                                                                  remarks:`session booked successfully for children (${learnerDeatil.firstName} ${learnerDeatil.lastName})`,
                                                                  transactionType:"dr",
                                                                  childDetail:learnerDeatil,
                                                                  childId:self.learner_name_selected.id,
                                                                  userId:self.learner_name_selected.parentId,
                                                                  userDetail:parentDetails
                                                              }
                                                            }else{
                                                              transactionObject = {
                                                                availableCredit:self.remainingCredit,
                                                                bookingId:rendomId,
                                                                createdAt:new Date(),
                                                                debits:Number((self.CalculateMinute(row.timevalue1.text,row.timevalue2.text)* self.oneMinuteCreditOfUser).toFixed(2)),
                                                                id:'',
                                                                paymentId:'',// This feild is static
                                                                reference:0,
                                                                remarks:'Booked a session.',
                                                                transactionType:"dr",
                                                                userDetail:learnerDeatil,
                                                                userId:self.learner_name_selected.id,
                                                                isSendNotification: false
                                                              }
                                                            }
                                                            insertDatabase.addDataToRootCollection(dbCollections.CREDITTRANSACTIONS,transactionObject,(res) => {
                                                              if(res.id){
                                                                const updateObj = {
                                                                  id : res.id
                                                                }
                                                                updateFirebase.updateRootCollectionDataByDocId(dbCollections.CREDITTRANSACTIONS,res.id,updateObj,(res) => {
                                                                })
                                                                const transUpdateObj = {
                                                                  transactionId : res.id
                                                                }
                                                                updateFirebase.updateRootCollectionDataByDocId(dbCollections.BOOKING,self.bookingId,transUpdateObj,(res) => {
                                                                  adminBookingNotification({
                                                                    bookingData : this.bookingResponse
                                                                  }, () => {});
                                                                })
                                                              }
                                                            })
                                                          }
                                                          else{
                                                            self.isSpinner = false;
                                                          }
                                                          count+= 1;
                                                          countFunction(self.items[count])
                                                      } else {
                                                          showToast = false
                                                        self.$root.$emit('showToastMessage',`Session not available for ${moment(row.date+" "+row.timevalue1.text).format("DD-MMM-YYYY hh:mm A")} to ${moment(row.date+" "+row.timevalue2.text).format("DD-MMM-YYYY hh:mm A")}.`,'danger');
                                                        count+= 1;
                                                        countFunction(self.items[count])
                                                      }
                                                })
                                                }
                                              }
                                              countFunction(self.items[count])
                                            // }else{
                                            //   self.isSpinner = false;
                                            //   self.booking = false;
                                            // }
                                          // }
                                        // })
                                      }
                                      catch(error){
                                        console.error(error);
                                        errorEmail({
                                          projectName : process.env.VUE_APP_PROJECTNAME,
                                          environment : process.env.NODE_ENV,
                                          fileName : currentFileName,
                                          hookName : "bookingSession",
                                          errorMessage : error.message
                                          },(res)=>{
                                            if(res.error){
                                                console.error(res.error)
                                            }
                                          }) 
                                        self.booking = false;
                                      }
                                    }else{
                                      self.isSpinner = false;
                                      self.$bvModal
                                      .msgBoxConfirm("You don't have sufficient credits in your account for this booking.", {
                                        title: 'Insufficient Balance',         
                                        class:'test',
                                        okVariant: 'primary',
                                        okTitle: 'Ok',
                                        cancelTitle: 'Cancel',
                                        cancelVariant: 'outline-primary',
                                        hideHeaderClose: false,
                                        centered: true,
                                        footerClass: 'approve-footer',
                                        headerClass: 'approve-header',
                                        bodyClass: 'approve-body',
                                      })
                                    }
                                  } else {
                                    this.stopInterval();
                                    self.isSpinner = false;
                                    for(var i=0; i<self.items.length;i++){
                                      revertBookingTimeOfTutorInTimeSlotCollection({
                                        learnerId: self.learner_name_selected.id,
                                        tutorId: self.tutor_name_selected.id,
                                        startTime: moment(self.items[i].date+" "+self.items[i].timevalue1.text).format("DD-MMM-YYYY HH:mm"),
                                        endTime: moment(self.items[i].date+" "+self.items[i].timevalue2.text).format("DD-MMM-YYYY HH:mm"),
                                        learnerTimeZone: this.learnerTimeZone,
                                      },(resp)=>{
                                      })
                                    }
                                  }
                                }).catch(()=>{
                                  this.stopInterval();
                                })
                              return;
                            } else {
                              updateBookingTimeOfTutorInTimeSlotCollection({
                                  learnerId: self.learner_name_selected.id,
                                  tutorId: self.tutor_name_selected.id,
                                  startTime: moment(update.date+" "+update.timevalue1.text).format("DD-MMM-YYYY HH:mm"),
                                  endTime: moment(update.date+" "+update.timevalue2.text).format("DD-MMM-YYYY HH:mm"),
                                  learnerTimeZone: this.learnerTimeZone,
                                },()=>{
                                  updateCount += 1;
                                  updateCountFunction(self.items[updateCount])
                                })
                            }
                          }
                          updateCountFunction(self.items[updateCount]);
                      }else{
                        self.isSpinner = false;
                        self.booking = false;
                      }
                    } else {
                      self.booking = false;
                      this.isSpinner = false;
                    }
                  })
                }).catch(()=>{
                this.isSpinner = false;
              })
              }).catch(()=>{
                this.isSpinner = false;
            })
            }).catch(()=>{
              this.isSpinner = false;
            })
        }).catch(() => {
          this.isSpinner = false;
        })
      }).catch((error)=>{
        this.isSpinner = false;
        console.error(error)
      })
      } catch (error) {
        this.isSpinner = false;
        console.error(error,"error in book session function")
      }
      
    },
    startInterval(){
    var self = this;
    clearInterval(self.intervalValue);
    let count = 0;
    self.intervalValue = setInterval(() => {
        count++;
        if(count >= 20) {
            self.$router.replace("/dashboard");
            clearInterval(self.intervalValue);
        }
    }, 60 * 1000)
    },

  stopInterval(){
    clearInterval(this.intervalValue);
  },
    // CHECK IF ANY ERROR MESSAGE WAS PRESENT OR NOT(Shraddha)
    checkIfErrorIsArrived(){
      var self = this;
      var found = false;
      return new Promise((resolve,reject)=>{
        try{
          self.items.forEach(item => {
            if(item.errorMessageForTime != '' || item.errorMessageForDate != ''){
              found = true;
            }
          })
          if(found){
            reject('error found checkIfErrorIsArrived')
          }else{
            resolve('not found checkIfErrorIsArrived else')
          }
        }catch(error){
          this.isSpinner = false;
          console.error(error);
          errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "checkIfErrorIsArrived",
          errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          }) 
        }
      })
    },
     //CHECK SESSION TIME IS REPEAT OR NOT(Shraddha)
    checkSessionIsRepeat(){
      var self = this;
      var found = false;
      return new Promise((resolve,reject)=>{
        try{
          if(self.items.length > 1){
            const mergeData = self.mergeData(self.items);
            mergeData?.map((itm_v)=>{
              var dropData= []
              var matchDropValue= [];
              itm_v?.dayData?.map((_item)=>{
                _item.errorMessageForTime = '';
                var datata = [];
                var startTime2 = moment(_item.timevalue1.text, 'HH:mm a')
                var start_Time1 = moment(startTime2).add(29, 'minutes').toDate()
                var start_time2 =  moment(startTime2).add(30, 'minutes').toDate()
                var diff1=new Date(startTime2).getDate()== new Date(start_time2).getDate()?start_time2:start_Time1; // add condition for solve validation issues for two different date
                self.getTimeStops(self.getTime(diff1),_item.timevalue2.text)?.map((x,y)=>{ 
                  datata.push(x.label);
                  if (dropData.indexOf(x.label) !== -1 ) {
                    matchDropValue.push(x.label);
                  }
                  dropData.push(x.label) 
                })
                _item.storeDate = datata;
              })
              const hasDuplicates = dropData.map(v => (v)).length > new Set(dropData.map(v => (v))).size ? true : false;
              if(hasDuplicates) {
                itm_v?.dayData?.map((_item)=>{
                  var hasDuplicateError = _item.storeDate.filter(x => matchDropValue.includes(x)).length
                  if(hasDuplicateError>0){
                    if(_item.errorMessageForTime == '' && _item.errorMessageForDate == ''){
                    _item.errorMessageForTime = 'You can not add same time slot'
                      found = true
                    }
                  }
                })
                if(found){
                  reject('error found in checkSessionIsRepeat')
                }
              }else{
                resolve('error not found')
              }
            })
          }else{
            resolve('error not found')
          }
        }catch(error){
          reject('error found in checkSessionIsRepeat')
          this.isSpinner = false
          console.error(error)
          errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "checkSessionIsRepeat",
          errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          }) 
        }
      })
    },
    // CHECK IF SESSION IS ALREADY BOOKED BY SOMEONE(Shraddha)
    checkIfSessionAlreadyBook(){
      
      var self = this;
      var slotArray = [];
      return new Promise((resolve,reject)=>{
        try{
          self.checkTutorSession().then(() => {
            self.items.forEach(itm => {
              if(itm.timevalue1 && itm.timevalue2){
                slotArray = self.getTimeSlot(itm.date,itm.timevalue1.text,itm.timevalue2.text)
                var finx = self.bookedArray?.findIndex((x) => x.day == moment(new Date(itm.date)).format("DD/MM/YYYY"));
                var hasDuplicates1 = self.bookedArray[finx]?.booking.filter(x => slotArray.includes(x)).length
                if(hasDuplicates1 > 0){
                  if(itm.errorMessageForDate == '' && itm.errorMessageForTime == ''){
                    itm.errorMessageForTime = 'Tutor unavailable for selected time';
                    this.isSpinner = false;
                  }
                  reject('error found checkIfSessionAlreadyBook')  
                }else{
                  resolve('error not found')
                }
              }else{
                if(itm.errorMessageForDate == '' && itm.errorMessageForTime == ''){
                  itm.errorMessageForTime = 'Start and end time is required';
                    this.isSpinner = false;
                }else{
                  itm.errorMessageForTime = '';
                }
                reject('error found checkIfSessionAlreadyBook else')  
              }
            })
          }).catch(()=>{
              reject('error found checkIfSessionAlreadyBook else')  
          })
        }catch(error){
          this.isSpinner = false;
          console.error(error);
          errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "checkIfSessionAlreadyBook",
          errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          })
          reject('error found checkIfSessionAlreadyBook else')  
        }
      })
    },
    //MAKE ALREADY BOOKED SESSION ARRAY(Shraddha)
    checkTutorSession(){
    try {
      var validateArray1 = []
      return new Promise((resolve,reject)=>{
         db.collection(dbCollections.BOOKING)
        .where("tutor","==",this.tutor_name_selected.id)
        .where("status","in",[0,1])
        .get().then(snapshot=>{
            const bookTutor = [];
            snapshot.forEach(docs => { bookTutor.push(docs.data()) })
              bookTutor?.length > 0 &&
              bookTutor?.map((_itm,_inx)=>{
                let from = new Date(_itm.startTime.seconds * 1000)
                let start = moment_timezone.tz(from,this.learnerTimeZone)
                let startlocal = DateTime.local(parseInt(moment(start).format("YYYY")),parseInt(moment(start).format("MM")),parseInt(moment(start).format("DD")),parseInt(moment(start).format("HH")),parseInt(moment(start).format("mm")))
                let st = (startlocal.toSeconds() * 1000)
                let to = new Date(_itm.endTime.seconds* 1000)
                let end = moment_timezone.tz(to,this.learnerTimeZone)
                let endlocal = DateTime.local(parseInt(moment(end).format("YYYY")),parseInt(moment(end).format("MM")),parseInt(moment(end).format("DD")),parseInt(moment(end).format("HH")),parseInt(moment(end).format("mm")))
                let en = (endlocal.toSeconds() * 1000)
                var startbookingTime = this.getTime(new Date(st))
                var endbookingTime = this.getTime(new Date(en))
                bookTutor[_inx].bookSlot = this.getTimeStops(startbookingTime,endbookingTime)
              })
            
            validateArray1 = this.mergeData1(bookTutor);
            validateArray1?.map((itm_b,inx_b)=>{
              var bookSlot = []
              itm_b.dayData.map((val,val_inx)=>{
                val?.bookSlot?.map((value, index1)=>{
                  if((val.bookSlot.length-1) === index1) {
                      return;
                    }
                    bookSlot.push(value.label)
                })
              })
              validateArray1[inx_b].booking = bookSlot
            })
            resolve({status:true,data:validateArray1})
            this.bookedArray=validateArray1
        }).catch((error)=>{
          console.error(error)
          reject('error found checkTutorSession get data')
        })
      })
    } catch (error) {
      this.isSpinner = false;
      console.error(error,"error in checkTutorSession")
    }
    
    },
    // CHECK IF SESSION IS ALREADY BOOKED FOR LEARNER(Shraddha)
    checkIfLearnerSessionAlreadyBook(){
      
      var self = this;
      var slotArray = [];
      return new Promise((resolve,reject)=>{
        try{
          self.checkLearnerSession().then(() => {
            self.items.forEach(itm => {
              if(itm.timevalue1 && itm.timevalue2){
                slotArray = self.getTimeSlot(itm.date,itm.timevalue1.text,itm.timevalue2.text)
                var finx = self.bookedArrayLearner?.findIndex((x) => x.day == moment(new Date(itm.date)).format("DD/MM/YYYY"));
                var hasDuplicates1 = self.bookedArrayLearner[finx]?.booking.filter(x => slotArray.includes(x)).length
                if(hasDuplicates1 > 0){
                  if(itm.errorMessageForDate == '' && itm.errorMessageForTime == ''){
                    itm.errorMessageForTime = 'Learner unavailable for selected time';
                    this.isSpinner = false;
                  }
                  reject('error found checkIfLearnerSessionAlreadyBook')  
                }else{
                  resolve('error not found')
                }
              }else{
                if(itm.errorMessageForDate == '' && itm.errorMessageForTime == ''){
                  itm.errorMessageForTime = 'Start and end time is required';
                    this.isSpinner = false;
                }else{
                  itm.errorMessageForTime = '';
                }
                reject('error found checkIfLearnerSessionAlreadyBook else')  
              }
            })
          })
        }catch(error){
          this.isSpinner = false;
          console.error(error);
          errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "checkIfLearnerSessionAlreadyBook",
          errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          }) 
        }
      })
    },
    //MAKE ALREADY BOOKED SESSION ARRAY FOR LEARNER(Shraddha)
    checkLearnerSession(){
      try {
        var validateArray1 = []
      return new Promise((resolve,reject)=>{
         db.collection(dbCollections.BOOKING)
        .where("learnerId","==",this.learner_name_selected.id)
        .where("status","in",[0,1])
        .get().then(snapshot=>{
            const bookTutor = [];
            snapshot.forEach(docs => { bookTutor.push(docs.data()) })
              bookTutor?.length > 0 &&
              bookTutor?.map((_itm,_inx)=>{
               let from = new Date(_itm.startTime.seconds * 1000)
                let start = moment_timezone.tz(from,this.learnerTimeZone)
                let startlocal = DateTime.local(parseInt(moment(start).format("YYYY")),parseInt(moment(start).format("MM")),parseInt(moment(start).format("DD")),parseInt(moment(start).format("HH")),parseInt(moment(start).format("mm")))
                let st = (startlocal.toSeconds() * 1000)
                let to = new Date(_itm.endTime.seconds * 1000)
                let end = moment_timezone.tz(to,this.learnerTimeZone)
                let endlocal = DateTime.local(parseInt(moment(end).format("YYYY")),parseInt(moment(end).format("MM")),parseInt(moment(end).format("DD")),parseInt(moment(end).format("HH")),parseInt(moment(end).format("mm")))
                let en = (endlocal.toSeconds() * 1000)
                var startbookingTime = this.getTime(new Date(st))
                var endbookingTime = this.getTime(new Date(en))
                bookTutor[_inx].bookSlot = this.getTimeStops(startbookingTime,endbookingTime)
              })
            validateArray1 = this.mergeData1(bookTutor);
            validateArray1?.map((itm_b,inx_b)=>{
              var bookSlot = []
              itm_b.dayData.map((val,val_inx)=>{
                val?.bookSlot?.map((value,index1)=>{
                  if((val.bookSlot.length-1) === index1) {
                      return;
                    }
                    bookSlot.push(value.label)
                })
              })
              validateArray1[inx_b].booking = bookSlot
            })
            resolve({status:true,data:validateArray1})
            this.bookedArrayLearner=validateArray1
        }).catch((error)=>{
          reject('error found checkLearnerSession')
          console.error("error in checkLearnerSession get Data",error)
        })
      })
      } catch (error) {
        this.isSpinner = false;
        console.error(error,"error in checkLearnerSession")
      }
    },
    // Check if selected time is in tutor HOliday or not (Karan)
    // prepare array for selected date and time of 30 minutes each and then check every element of an array that it is not in between holiday
    checkTutorisOnHoliday(){
      try{
        return new Promise((resolve,reject) => {
          var self = this;
          let count = 0
          const countFunction = (row) => {
            if (count >=  self.tutorHolidayArray.length) {
              resolve();
              return;
            } else {
              let from = moment(row.from).format("YYYY-MM-DD HH:mm");
              let to = moment(row.to).format("YYYY-MM-DD HH:mm");
              self.items.forEach((item)=>{
                let bookFrom = item.timevalue1.text
                let bookto = item.timevalue2.text
                let array = []
                var startTime = moment(bookFrom, 'hh:mm A');
                var endTime = moment(bookto, 'hh:mm A');
                while(startTime <= endTime){
                  array.push(moment(startTime).format('hh:mm A'));
                  startTime.add(30, 'minutes');
                }
                array.forEach((data)=>{
                  let date = moment(item.date + ' ' + data).format("YYYY-MM-DD HH:mm")
                  let isInHoliday = moment(date).isBetween(from, to)
                  if (isInHoliday) {
                    item.errorMessageForTime = 'Tutor unavailable for selected time';
                    this.isSpinner = false;
                    reject('Tutor is Not Available');
                  }
                })
              })
              count++;
              countFunction(self.tutorHolidayArray[count]);
            }
          }
          countFunction(self.tutorHolidayArray[count]);
        })
      } catch (error) {
        this.isSpinner = false;
        reject('Tutor is Not Available');
        console.error(error,"error in checkLearnerSession")
      }
    },
    mergeData(data){
        const groups = data?.reduce((groups, dayData) => {
          const date = moment(new Date(dayData.date)).format('YYYY-MM-DD') ;
          if (!groups[date]) {
            groups[date] = [];
          }

          groups[date].push(dayData)
          return groups;
        }, {});

      const groupArrays = Object.keys(groups).map((date) => {
        return {
          day:date, dayData: groups[date]
        };
      });

      return groupArrays;
    },
    mergeData1(data){
      
        const groups = data?.reduce((groups, dayData) => {
        const date = moment(new Date(dayData.startTime.seconds * 1000)).format("DD/MM/YYYY") ;
        if (!groups[date]) {
          groups[date] = [];
        }

        groups[date].push(dayData)
        return groups;
      }, {});

      const groupArrays = Object.keys(groups).map((date) => {
        return {
          day:date, dayData: groups[date]
        };
      });

      return groupArrays;
    },
    getTime(date1){
      var date, TimeType, hour, minutes, fullTime;
      date = date1
      hour = date?.getHours();

      if(hour <= 11) { TimeType = 'AM' }
      else{ TimeType = 'PM' }

      if( hour > 12 ) { hour = hour - 12 }
      if( hour == 0 ) { hour = 12 }

      minutes = date?.getMinutes();
      if(minutes < 10) { minutes = '0' + minutes.toString() }

      fullTime = (hour.toString()<10 ? '0'+hour.toString() : hour.toString())  + ':' + minutes.toString() + ' ' + TimeType.toString();
      return fullTime;
    },
    getTimeStops(start, end){
        var startTime = moment(start, 'hh:mm A');
        var endTime = moment(end, 'hh:mm A');

        if(endTime.isBefore(startTime)){
          endTime.add(1, 'day');
        }
        var timeStops = [];
        while(startTime <= endTime){
          timeStops.push({label:new moment(startTime).format('hh:mm A')});
          startTime.add(this.settingArray.minBookingTime, 'minutes');
        }
        return timeStops;
    },
    // MAKE TIMESLOT FOR CHECK BOOKING IS ALSO AVAILABLE FOR THIS PERTICULAR DAY OR NOT(Shraddha)
    getTimeSlot(date,start, end){
        var startTime =  moment(moment(date + ' ' +start));
        var endTime = (end == "11:59 PM") ? moment(date + ' ' +end) : moment(moment(date + ' ' +end).subtract(this.settingArray.minBookingTime, 'minutes'));
        if(startTime > endTime){
          moment(startTime.subtract(1,'minute'));
        }
        
        var timeStops = [];
        while(startTime <= endTime){
          timeStops.push(moment(startTime).format('hh:mm A'));
          startTime.add(this.settingArray.minBookingTime, 'minutes');
        }
        return timeStops;
    },
    //RESET DATA WHEN CLICK ON CANCEL BUTTON (Shraddha)
    resetData(){
      var self = this;
      self.isSpinner = false;
      self.booking = false;
      self.slotCredit = 0;
        self.tutor_name_selected = null;
        self.category_selected = null;
        self.sessiontitle_selected = null; 
      self.learner_name_selected = null;
      self.items =  [
        {
          date: "",
          timevalue1: "",
          timevalue2: "",
          errorMessageForTime:"",
          errorMessageForDate:'',
          fromArray:[],
          toArray:[],
          errorOnToTime:'test'
        }
      ];
      self.checkselectedFirst = false;
      self.checkselectedSecond = false;
      self.learnerTimeZone = ""
      self.$refs.validateForm.reset();
    },
    resetDataWhenLearnerSelected(){
      var self = this;
      self.isSpinner = false;
      self.booking = false;
      self.slotCredit = 0;
      self.remainingCredit = 0;
      self.tutor_name_selected = null;
      self.category_selected = null;
      self.sessiontitle_selected = null; 
      self.items =  [
        {
          date: "",
          timevalue1: "",
          timevalue2: "",
          errorMessageForTime:"",
          errorMessageForDate:'',
          fromArray:[],
          toArray:[],
          errorOnToTime:'test'
        }
      ];
      self.checkselectedFirst = false;
      self.checkselectedSecond = false;
      self.$refs.validateForm.reset();
    },
    selectCheckbox(event){
      var self = this;
      var found = false;
      if(event == 'firstCheckbox' && self.checkselectedFirst == true){
        if(self.items[0].date == ''){
          self.checkselectedFirst = false;
          if(self.items[0].errorMessageForDate == ''){
            self.items[0].errorMessageForDate = 'Please select atleast one date.'
          }
        }else{
          self.errorMessageOfDate = '';
          self.selectedCheckboxValue = event;
            if(self.items.length < 7){
              for(var i=1; i<self.items.length+1;i++){
                const selectedDate = self.addZero(new Date(self.items[i-1].date).getDate())+'-'+(self.addZero(new Date(self.items[i-1].date).getMonth()+1))+'-'+new Date(self.items[i-1].date).getFullYear();
                const selectedDates = self.addZero(new Date(self.items[i-1].date).getMonth()+1)+'-'+(self.addZero(new Date(self.items[i-1].date).getDate()))+'-'+new Date(self.items[i-1].date).getFullYear();
                if (new Date(moment(selectedDates).format("DD-MMM-YYYY")).getTime() <= new Date(new Date(new Date().setDate(new Date().getDate() + 7))).getTime()) {
                  self.items.push({
                      date:'',
                      timevalue1:'',
                      timevalue2:'',
                      errorMessageForTime:'',
                      errorMessageForDate:'',
                      fromArray:[],
                      toArray:[]
                  });
                  let isInArray = self.allDateArray.some(function(itemData) {
                       return itemData === self.incrementDates(selectedDates)
                   });
                   if(isInArray){
                     self.items[i].date = self.incrementDate(self.items[i-1].date);
                     self.tutorHolidayArray.forEach((holidayDate,holidayIndex) => {
                       const fromDate = new Date(holidayDate.from);
                       const toDate = new Date(holidayDate.to);
                       self.fromTimeCalculation(self.items[i].date,i);
                      //  self.CheckTutorIsInHoliday(i,fromDate,toDate,self.incrementDates(selectedDates),holidayIndex,self.incrementDates(selectedDates));
                     })
                   }
                   else{
                     self.items[i].date = self.incrementDate(self.items[i-1].date);
                     self.items[i].errorMessageForDate = '';
                     self.fromTimeCalculation(self.items[i].date,i);
                   }
                } else {
                  break;
                }
              }
            }else{
              for(var i=1; i<7;i++){
                self.items[i].date = self.incrementDate(self.items[i-1].date);
                self.fromTimeCalculation(self.items[i].date,i);
              }
            }
        }
      }
      else{
        self.selectedCheckboxValue = '';
      }
    },
    // CALL WHEN TUTOR NAME SELECTED (Shraddha)
    selectTutorName(data){

      var self = this;
        self.slotCredit = 0;
        self.holidayMode = false;
        self.fromTimeOption = [];
        self.items = [
          {
            date: "",
            timevalue1: "",
            timevalue2: "",
            errorMessageForTime:"",
            errorMessageForDate:'',
            fromArray:[],
            toArray:[]
          },
        ],
        self.checkselectedFirst = false;
        self.checkselectedSecond = false;
        self.tutorHolidayArray = [];
        self.tutorTimeSlotArray = [];
        db.collection(dbCollections.USERS).doc(data.id).get().then((userData)=>{
          if (!userData.exists) {
            console.error("no tutorData found")
          }
          self.holidayMode = userData.data().isHolidayMode
          let LearnertimeZone = self.learner_name_selected.timeZone.split("(")
          this.tutorTimeSlotArray = [];
          this.learnerTimeZone = LearnertimeZone[0].trim()
          self.calculateSlotInLearnerTimeZone(userData.data().tutorTimeManagement,LearnertimeZone[0].trim())
          self.calculateHolidayArray(userData.data().tutorHolidays, this.learnerTimeZone)
        }).catch((error)=>{
          console.error(error.message)
        })
        // self.getUsersList.forEach(userData => {
        //   if(userData.id === data.id){
        //     self.holidayMode = userData.isHolidayMode
        //     let LearnertimeZone = self.learner_name_selected.timeZone.split("(")

        //     this.tutorTimeSlotArray = [];
        //     this.learnerTimeZone = LearnertimeZone[0].trim()
        //     self.calculateSlotInLearnerTimeZone(userData.tutorTimeManagement,LearnertimeZone[0].trim())
        //     self.calculateHolidayArray(userData.tutorHolidays, this.learnerTimeZone)

        //   }
        // })
    },
     // CALL WHEN LEARNER NAME SELECTED (Shraddha)
    selectLearnerName(tutorData){
      var self = this;
      var found = false;
      if(tutorData != null && tutorData != '' && tutorData != undefined){
          getFirebase.simpleGetDataWithSpecificWhereCondition(dbCollections.CONNECTEDUSERS,'learner','==',self.learner_name_selected.id,(res) => {
            if(res){
              res.forEach(activetutor => {
                if(activetutor.tutor === tutorData.id){
                  found = true
                }
              })
              if(!found){
                this.resetDataWhenLearnerSelected();
              }
            }
          })
      }else{
        if(self.sessionId){
          self.ifSessionIdIsThere();
        }else{
          self.resetDataWhenLearnerSelected();
        }
      }
    },
    // CALL WHEN DATE IS SELECTED (Shraddha)
    selectDate(selectDate,index){
      
      var self = this;
      self.allDateArray = [];
      self.items[index].toArray=[];
      self.items[index].fromArray=[];
      self.items[index].errorMessageForDate = '';
      self.items[index].errorMessageForTime = '';
      self.items[index].timevalue2 = '';
      self.items[index].timevalue1 = '';
      self.items[index].errorOnToTime = 'test';
      if(self.tutorHolidayArray && self.tutorHolidayArray.length !== 0){
        self.tutorHolidayArray.forEach((holidayDate,holidayIndex) => {
          const fromDate = new Date(holidayDate.from);
          const toDate = new Date(holidayDate.to);
          const selectedDate = new Date(selectDate).getDate()+'-'+(new Date(selectDate).getMonth()+1)+'-'+new Date(selectDate).getFullYear();
          const DatesArray = self.getDates(fromDate,toDate);
          DatesArray.forEach((datas) => {
              self.allDateArray.push(new Date(datas).getDate()+'-'+(new Date(datas).getMonth()+1)+'-'+new Date(datas).getFullYear())
          })
          let isInArray = self.allDateArray.some(function(item) {
              return item === selectedDate
          });
          if(isInArray){
              self.fromTimeCalculation(selectDate,index);
              // self.CheckTutorIsInHoliday(index,fromDate,toDate,selectedDate,holidayIndex,selectDate);
          }else{
            self.items[index].errorMessageForDate = '';
            self.fromTimeCalculation(selectDate,index);
            self.isSpinner = false;
          }
        })
      }else{
        self.fromTimeCalculation(selectDate,index);
        self.isSpinner = false;
      }
    },
    // CHECK TUTOR IS ON HOLIDAY OR NOT FOR SELECTED DATE (Shraddha)
    // CheckTutorIsInHoliday(index,fromDate,toDate,selectedDate,holidayIndex,selectDate){
    //   var self = this;
    //   if(self.items[index].errorMessageForDate == ''){
    //     const holidayfromDate = new Date(fromDate).getDate()+'-'+(new Date(fromDate).getMonth()+1)+'-'+new Date(fromDate).getFullYear();
    //     const holidaytoDate = new Date(toDate).getDate()+'-'+(new Date(toDate).getMonth()+1)+'-'+new Date(toDate).getFullYear();
    //     if(holidayfromDate == holidaytoDate){
    //         const holidayfromHours = new Date(fromDate).getHours()+':'+new Date(fromDate).getMinutes();
    //         const holidaytoMinute = new Date(toDate).getHours()+':'+new Date(toDate).getMinutes();
    //         if(holidayfromHours == '00:00' && holidaytoMinute && '23:59' || holidayfromHours == '0:0' && holidaytoMinute && '23:59'){
    //             self.items[index].errorMessageForDate = 'This day is tutor not available.';
    //             self.isSpinner = false;
    //         }else{
    //           if(self.items[index].errorOnToTime == 'test'){
    //             self.items[index].errorOnToTime = holidayIndex;
    //           }
    //             self.items[index].errorMessageForDate = '';
    //             self.fromTimeCalculation(selectDate,index);
    //             self.isSpinner = false;
    //         }
    //     }else{
    //       var index1 = self.allDateArray.findIndex(item => {
    //           return item == selectedDate 
    //       })
    //       if(self.allDateArray[index1] == holidayfromDate){
    //         const holidayfromHour = new Date(fromDate).getHours()+':'+new Date(fromDate).getMinutes();
    //         if(holidayfromHour == '00:00' || holidayfromHour == '0:0'){
    //           self.items[index].errorMessageForDate = 'This day is tutor not available.';
    //           self.isSpinner = false;
    //         }else{
    //           if(self.items[index].errorOnToTime == 'test'){
    //             self.items[index].errorOnToTime = holidayIndex;
    //           }
    //           self.items[index].errorMessageForDate = '';
    //           self.fromTimeCalculation(selectDate,index);
    //           self.isSpinner = false;
    //         }
    //       }else if(self.allDateArray[index1] == holidaytoDate){
    //         const holidaytoMinutes = new Date(toDate).getHours()+':'+new Date(toDate).getMinutes();
    //         if(holidaytoMinutes == '23:59'){
    //           self.items[index].errorMessageForDate = 'This day is tutor not available.';
    //           self.isSpinner = false;
    //         }else{
    //           if(self.items[index].errorOnToTime == 'test'){
    //             self.items[index].errorOnToTime = holidayIndex;
    //           }
    //           self.items[index].errorMessageForDate = '';
    //           self.fromTimeCalculation(selectDate,index);
    //           self.isSpinner = false;
    //         }
    //       }else{
    //           self.items[index].errorMessageForDate = 'This day is tutor not available.';
    //           self.isSpinner = false;
    //       }
    //     }
    //   }
    // },
    // CALL FOR FROM TIME SELECTED FOR DROPDOWN CALCULATE (Shraddha)
    fromTimeCalculation(selectDate,index){
      var self = this;
      var found = false;
        var TodayDay = new Date(selectDate).getDay();
        if(self.tutorTimeSlotArray && self.tutorTimeSlotArray.length > 0){
          self.items[index].errorMessageForDate = '';
          var indexs = self.tutorTimeSlotArray.findIndex(item => {
            return item.dayId == TodayDay && item.isEnabled
          })
          if(indexs > -1){
            self.tutorTimeSlot = self.tutorTimeSlotArray[indexs].timeSlots;
            self.tutorTimeSlot.forEach((data)=>{    //sorting if diffrent timezone learner and tutor and turor is available for 24 hour at that time from dropdown sequence start from 12 am
              data.sortForm = new Date(`${moment(selectDate).format("YYYY-MM-DD")} ${moment(data.from).format('HH:mm')}`).getTime()
            })
            // sort tutorTimeSlot for show time slot in ascending order and then check merge time slot if it is in continue order.
            if(self.tutorTimeSlot.length > 1) {
              self.tutorTimeSlot.sort((a, b) => {
                  return a.sortForm - b.sortForm;
              });
            }
            // array is prepare for check to time is same of next from time if same then merge time slot and remove that time slot from array.
            let finalArray = [...self.tutorTimeSlot];
            let array = []
            finalArray.forEach((data)=>{
              // array.forEach((ele)=>{
                if ((moment(data.to).format('hh:mm A') !== '11:59 PM') && ((moment(data.to).format('mm') == '59') || (moment(data.to).format('mm') == '29'))) {
                  data.to.add(1, 'minutes')
                }
                array.push({from: moment(moment(selectDate).format('YYYY-MM-DD')+' '+moment(data.from).format('hh:mm A')), to:moment(moment(selectDate).format('YYYY-MM-DD')+' '+moment(data.to).format('hh:mm A'))})
                // array.push({to: moment(data.to).format('hh:mm A')})
              // })
            })
             for (let j = 0; j < array.length; j++) {
              for (let k = 0; k < array.length; k++) {  
                if(array[j].to - array[k].from == 0){
                  array[j].to = array[k].to
                  array.splice(k,1)
                }
              }
            }
            // let confirmArray = []
            // array.forEach((data)=>{
            //   if (!(confirmArray.includes(data))) {
            //     confirmArray.push(data)
            //   } else {
            //     let ind = confirmArray.findIndex( item =>{
            //       return item == data
            //     })
            //     if (ind > -1) {
            //       confirmArray.splice(ind,1);
            //     }
            //   }
            // })
            //  for (let j = 0; j < array.length; j++) {
            //   for (let k = 0; k < array.length; k++) {  
            //     if(array[j].to == array[k].from){
            //       array[j].to = array[k].to
            //       array.splice(k,1)
            //     }
            //   }
            // }
            // if(finalArray.length > 1){
            //     for (let j = 0; j < finalArray.length; j++) {
            //       // let addmin =  finalArray[j].to
            //       for (let k = 0; k < finalArray.length; k++) {  
            //         if(moment(finalArray[j].to).format("mm") == "59" || moment(finalArray[j].to).format("mm") == "29" && moment(finalArray[j].to).format("hh:mm A") !== '11:59 PM'){
            //            //if end time is 59 or 29 min then add 1 min to condider in 30 min slot
            //           finalArray[j].to.add(1, 'minutes').format("HH:mm A");
            //         }
            //         if(finalArray[j].to-finalArray[k].from == 0){
            //           finalArray[j].to = finalArray[k].to
            //           finalArray.splice(k,1)
            //         }
            //       }
            //     }
            // }
            array.forEach(timeData => {
              const fromTime = moment(timeData.from).format('hh:mm A');
              const minusToTime = moment(timeData.to).format('hh:mm A') == "11:59 PM" ? moment(timeData.to).subtract({'minutes' : (self.settingArray.minBookingTime-1)}).format('hh:mm A') :moment(timeData.to).subtract({'minutes' : self.settingArray.minBookingTime}).format('hh:mm A');
              let startTime = moment(fromTime, "HH:mm A");
              let endTime = moment(minusToTime, "HH:mm A");
              if(moment(endTime).format("mm") == "59" ||moment(endTime).format("mm") == "29"){    //if end time is 59 or 29 min then add 1 min to condider in 30 min slot
                 endTime.add(1, 'minutes').format("HH:mm A");
              }
              setTimeout(() => {   
                let startDate = moment(startTime).format("DD-MM-YYYY")
                while (startTime <= endTime) {
                  if(self.items[index].fromArray.length == 0){
                    self.items[index].fromArray.push({text :self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1]),value :self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])}); 
                    startTime.add(30, 'minutes').format("HH:mm A");
                    //  let newStart = moment(startTime).format("DD-MM-YYYY")
                    //  if (startDate != newStart) {
                    //   break;
                    //  }
                  }else{
                    self.items[index].fromArray.forEach(data => {
                      if(data.text == self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])){
                        found = true;
                        startTime.add(30, 'minutes').format("HH:mm A");
                      }
                    })
                    if(!found){
                      self.items[index].fromArray.push({text :self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1]),value :self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])}); 
                      startTime.add(30, 'minutes').format("HH:mm A");
                    }
                    let newStart = moment(startTime).format("DD-MM-YYYY")
                    if (startDate != newStart) {  // brek for loop if date is changed
                      break;
                    }
                  }
                }
              }, 1000);
            })
          }else{
            self.items[index].errorMessageForDate = 'This day is tutor not available.'
          }
        }else{
          self.items[index].errorMessageForDate = 'This day is tutor not available.'
        }
    },
    // CALL FOR TO TIME DROPDOWN VALUE AFTER FROM TIME SELECTED (Shraddha)
    selectFromDate(date,index,selectDate){
      var self = this;
      let rangeArray = [];
      self.items[index].toArray = [];
      self.items[index].errorMessageForTime = '';
      self.items[index].errorMessageForDate = '';
      self.items[index].timevalue2 = '';
      var TodayDay = new Date(selectDate).getDay();
      const todayDate = moment(moment(selectDate).format('YYYY-MM-DD')+' '+date);
      var indexs = self.tutorTimeSlotArray.findIndex(item => {
        return item.dayId == TodayDay && item.isEnabled
      })
      if(indexs > -1){
        self.tutorTimeSlot = self.tutorTimeSlotArray[indexs].timeSlots;
        let localArray = [...self.tutorTimeSlot]
        rangeArray = [];
        localArray.forEach(timeData => {
          let newArray = []
          const dbFromTime = moment(timeData.from).format('hh:mm A');
          const ToTime = moment(timeData.to).format('hh:mm A');
          let start = moment(dbFromTime, "HH:mm A");
          let end= moment(ToTime, "HH:mm A");
            // if(moment(end).format("mm") == "59" || moment(end).format("mm") == "29"){   //if end time is 59 or 29 min then add 1 min to consider in 30 min slot
            //     end.add(1, 'minutes').format("HH:mm A");
            // }
          let startDate = moment(start).format("DD-MM-YYYY")
          while (start <= end) {
            if(!(newArray.includes(self.formatAMPM(start.format("HH:mm A").split(":")[0],start.format("HH:mm A").split(":")[1])))){   // check if slot is not enterd multiple time in toDropdown
              newArray.push(self.formatAMPM(start.format("HH:mm A").split(":")[0],start.format("HH:mm A").split(":")[1]));
            }
            start.add(30, 'minutes').format("HH:mm A");
            let newStart = moment(start).format("DD-MM-YYYY")
            if (startDate != newStart) {
              start.add(-1, 'minutes').format("HH:mm A");
              newArray.push(self.formatAMPM(start.format("HH:mm A").split(":")[0],start.format("HH:mm A").split(":")[1]));   // if day change while adding to slot then it is stop by 11:59 PM
              break;
            }
          }
           newArray.shift();
           newArray.forEach((data)=>{
            if (!rangeArray.includes(data)){
              rangeArray.push(data);
            }
           })
        })
          // make an array for to slot based on from selection in that array slot is added without condition of date change then 
          // finalArray is prepare for add slot of selected date slot
          // then finalArray's every element is checked if it is available in rangeArray and if one ement is not include in rangeArray then break the loop 
          let fromTime = moment(todayDate).add({'minutes' : self.settingArray.minBookingTime}).format('DD-MM-YYYY hh:mm A');
          let maxTime = moment(todayDate).add({'minutes' : self.settingArray.maxBookingTime}).format('DD-MM-YYYY hh:mm A');
          let startTime = moment(fromTime, "DD-MM-YYYY hh:mm A");
          let endTime = moment(maxTime, "DD-MM-YYYY hh:mm A");
          let array = []
          while (startTime <= endTime) {
              array.push(moment(startTime).format('YYYY-MM-DD hh:mm A'))
              startTime.add(30, 'minutes').format("YYYY-MM-DD hh:mm A");
          }
          let selectDay = moment(todayDate).format('YYYY-MM-DD')
          let finalArray = []
          array.forEach((data)=>{
            if(moment(data).format("YYYY-MM-DD") == selectDay){
              finalArray.push(moment(data).format('hh:mm A'));
            } else {
              if(!(finalArray.includes('11:59 PM'))){
                finalArray.push('11:59 PM');
              }
            }
          })
          for (let i = 0; i < finalArray.length; i++) {
            // const element = array[i];
            if (rangeArray.includes(finalArray[i])) {
               self.items[index].toArray.push({text:self.formatAMPM(finalArray[i].split(":")[0],finalArray[i].split(":")[1]),value:self.formatAMPM(finalArray[i].split(":")[0],finalArray[i].split(":")[1])}); 
            } else {
              break;
            }
          }
          // if(rangeArray.length != 0 && rangeArray.includes(date)){
          //   let sDate = moment(selectDate).format('YYYY-MM-DD')
          //   const fromTime = moment(todayDate).add({'minutes' : self.settingArray.minBookingTime}).format('hh:mm A');
          //   const maxTime = moment(todayDate).add({'minutes' : self.settingArray.maxBookingTime}).format('hh:mm A');
          //   let fromDate = moment(todayDate).add({'minutes' : self.settingArray.minBookingTime}).format('YYYY-MM-DD');
          //   let toDate =moment(todayDate).add({'minutes' : self.settingArray.minBookingTime}).format('YYYY-MM-DD');
          //   let fromT = new Date(fromDate+ " " +fromTime).getTime()
          //   let toT = new Date(toDate+ " " +ToTime).getTime()
          //   if (((fromDate == toDate) &&(fromDate== sDate))) {   
          //     let startAndEndTimeDifference = self.CalculateMinute(fromTime,ToTime);
          //     let startTime = moment(fromTime, "HH:mm A");
          //     let endTime = moment(ToTime, "HH:mm A");
          //     let maximumTime = moment(maxTime, "HH:mm A");
          //     if(startAndEndTimeDifference <= self.settingArray.maxBookingTime){
          //       let startDate = moment(startTime).format("DD-MM-YYYY")
          //       if(moment(endTime).format("mm") == "59" || moment(endTime).format("mm") == "29"){  //if end time is 59 or 29 min then add 1 min to condider in 30 min slot
          //           endTime.add(1, 'minutes').format("HH:mm A");
          //       }
          //       while (startTime <= endTime) {
          //         let ind = self.items[index].toArray.findIndex((data)=>{   // check if slot is not enterd multiple time in toDropdown 
          //           return data.text == self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])
          //         })
          //         if(ind == -1){
          //           self.items[index].toArray.push({text:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1]),value:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])}); 
          //         }
          //         // if(!(self.items[index].toArray.includes({text:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1]),value:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])}))){
          //         //   self.items[index].toArray.push({text:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1]),value:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])}); 
          //         // }
          //         // self.items[index].toArray.push({text:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1]),value:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])}); 
          //         startTime.add(self.settingArray.minBookingTime, 'minutes').format("HH:mm A");
          //         let newStart = moment(startTime).format("DD-MM-YYYY")
          //         if (startDate != newStart) {
          //               if(startAndEndTimeDifference+1 !=  self.settingArray.maxBookingTime){
          //                 startTime.add(-1, 'minutes').format("HH:mm A");
          //                   let ind = self.items[index].toArray.findIndex((data)=>{
          //                     return data.text == self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])
          //                   })
          //                   if(ind == -1){
          //                     self.items[index].toArray.push({text:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1]),value:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])}); 
          //                   }
          //                 // if(!(self.items[index].toArray.includes({text:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1]),value:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])}))){
          //                 //   self.items[index].toArray.push({text:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1]),value:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])}); 
          //                 // }
          //                 // self.items[index].toArray.push({text:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1]),value:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])}); 
          //               }
          //             break;
          //         }
          //       }
          //     }else{
          //        while (startTime <= endTime && startTime <= maximumTime) {
          //           let ind = self.items[index].toArray.findIndex((data)=>{  // check if slot is not enterd multiple time in toDropdown
          //             return data.text == self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])
          //           })
          //           if(ind == -1){
          //             self.items[index].toArray.push({text:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1]),value:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])}); 
          //           }
          //         // if(!(self.items[index].toArray.includes({text:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1]),value:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])}))){
          //         //   self.items[index].toArray.push({text:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1]),value:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])}); 
          //         // }
          //         // self.items[index].toArray.push({text:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1]),value:self.formatAMPM(startTime.format("HH:mm A").split(":")[0],startTime.format("HH:mm A").split(":")[1])}); 
          //         startTime.add(self.settingArray.minBookingTime, 'minutes').format("HH:mm A");
          //       }
          //     }
          //   } else {
          //     self.items[index].toArray.push({text:"11:59 PM",value: "11:59 PM"})
          //   }
          // }
        // })
      }
    },
    // CALL FOR TO TIME DROPDOWN VALUE AFTER TO TIME SELECTED (Shraddha)
    selectToDate(index){
      
      var self = this;
      self.items[index].errorMessageForTime = '';
      if(!isNaN(self.items[index].errorOnToTime) && self.items[index].errorOnToTime != 'test'){
        if(self.tutorHolidayArray && self.tutorHolidayArray.length !== 0){
         const fromTime = self.CombineDateAndTime(self.items[index].date,self.items[index].timevalue1.text);
         const toTime = self.CombineDateAndTime(self.items[index].date,self.items[index].timevalue2.text);
         const holidayFromDate = new Date(self.tutorHolidayArray[self.items[index].errorOnToTime].from);
         const holidayToDate = new Date(self.tutorHolidayArray[self.items[index].errorOnToTime].to);
         const fromDate = new Date(holidayFromDate).getDate()+'-'+(new Date(holidayFromDate).getMonth()+1)+'-'+new Date(holidayFromDate).getFullYear();
         const toDate = new Date(holidayToDate).getDate()+'-'+(new Date(holidayToDate).getMonth()+1)+'-'+new Date(holidayToDate).getFullYear();
         const selectedDate = new Date(self.items[index].date).getDate()+'-'+(new Date(self.items[index].date).getMonth()+1)+'-'+new Date(self.items[index].date).getFullYear();
         if(selectedDate == fromDate && selectedDate == toDate){
            if(
            moment(fromTime, 'h:mma') < moment(holidayFromDate, 'h:mma') && moment(toTime, 'h:mma')<= moment(holidayFromDate, 'h:mma') ||
            moment(fromTime, 'h:mma') >= moment(holidayToDate, 'h:mma')&& moment(toTime, 'h:mma') > moment(holidayToDate, 'h:mma')
            ){
            self.calculateCredit(index);
          }else{
            self.items[index].errorMessageForTime= 'This time tutor not available.';
          }
         }else if(selectedDate == fromDate){
            if(moment(fromTime, 'h:mma') < moment(holidayFromDate, 'h:mma') && moment(toTime, 'h:mma')<= moment(holidayFromDate, 'h:mma')){
              self.calculateCredit(index);
            }else{
              self.items[index].errorMessageForTime = 'This time tutor not available.';
            }
         }else if(selectedDate == toDate){
            if(moment(fromTime, 'h:mma') >= moment(holidayToDate, 'h:mma')&& moment(toTime, 'h:mma') > moment(holidayToDate, 'h:mma')){
              self.calculateCredit(index);
            }else{
              self.items[index].errorMessageForTime = 'This time tutor not available.';
            }
         }
        }else{
          self.calculateCredit(index);
        }
      }else{
        self.calculateCredit(index);
      }
      
    },
    //CREDIT CALCULATION WHEN TO DATE IS SELECTED (Shraddha)
    calculateCredit(index){
      var self = this;
      var indexs = self.settingArray.tutorTypesBookingSeetings.findIndex(item => {
          return item.tutorType == self.tutor_name_selected.tutorType
      })
      if(indexs != -1){
        self.oneMinuteCreditOfUser = (self.settingArray.tutorTypesBookingSeetings[indexs].creditValueRequired30Min/30)
      }
      self.items[index].errorMessageForDate = '';
      self.items[index].errorMessageForTime = '';
      self.slotCredit = 0;
      self.items.forEach(itm => {
        if(itm.timevalue1.text && itm.timevalue2.text){
          self.slotCredit += (self.CalculateMinute(itm.timevalue1.text,itm.timevalue2.text)) *  self.oneMinuteCreditOfUser
        }
      })
      self.slotCredit.toString(2)
    },
    //Calculate TimeSlot Array To learnerTimeZone
    calculateSlotInLearnerTimeZone(tutortimeSlot,learnerTimeZone){
          let startTime = moment(this.futureDate)
          let endTime = moment(this.tommorrowDate)
          let afterStart = moment_timezone.tz(startTime,learnerTimeZone);
          let afterEnd = moment_timezone.tz(endTime,learnerTimeZone);
          this.futureDate = new Date(moment(afterStart).format("YYYY-MM-DD hh:mm A"));
          this.tommorrowDate = new Date(moment(afterEnd).format("YYYY-MM-DD hh:mm A"));
           let tutorTimeManagementCount = 0
           let newConvertArray = [...tutortimeSlot]
            let tutorTimeManagementFun = (timeManagment) => {
                if (tutorTimeManagementCount >= newConvertArray.length) {
                    this.splitDateFunction(newConvertArray)
                    return;
                }
                else {
                    if (timeManagment && timeManagment.isEnabled) {
                        let timeSlot = []
                        timeManagment.timeSlots.forEach((data)=>{
                            timeSlot.push(data)
                        })
                        let timeSlotCount = 0
                        let timeSlotCountFun = (timeSlotss) => {
                            if (timeSlotCount >= timeSlot.length) {
                                tutorTimeManagementCount += 1;
                                tutorTimeManagementFun(newConvertArray[tutorTimeManagementCount]);
                                return;
                            } else {
                                let st = this.lastDayOfMonth(new Date().getFullYear(),new Date().getMonth()+1,timeManagment.dayId,new Date(timeSlotss.from.seconds * 1000))
                                let en = this.lastDayOfMonth(new Date().getFullYear(),new Date().getMonth()+1,timeManagment.dayId,new Date(timeSlotss.to.seconds * 1000))
                                let from = moment(st) //convert databse getTime Into TutorTImeZone Date
                                let to = moment(en)

                                let start = moment_timezone.tz(from,learnerTimeZone)
                                let end = moment_timezone.tz(to,learnerTimeZone)
                                if(moment(start) > moment(end)){
                                  start = moment(start).subtract(1,"day");
                                    if ((moment(start).format('hh:mm A') !== '11:59 PM') && ((moment(start).format('mm') == '59') || (moment(start).format('mm') == '29'))) {
                                      start = start.add(1, 'minutes')
                                    }
                                    if ((moment(end).format('hh:mm A') !== '11:59 PM') && ((moment(end).format('mm') == '59') || (moment(end).format('mm') == '29'))) {
                                      end = end.add(1, 'minutes')
                                    }
                                  newConvertArray[tutorTimeManagementCount].timeSlots[timeSlotCount].from = moment(start)
                                  newConvertArray[tutorTimeManagementCount].timeSlots[timeSlotCount].to = moment(end)
                                  timeSlotCount+= 1;
                                  timeSlotCountFun(timeSlot[timeSlotCount]);
                                } else {
                                    if ((moment(start).format('hh:mm A') !== '11:59 PM') && ((moment(start).format('mm') == '59') || (moment(start).format('mm') == '29'))) {
                                      start = start.add(1, 'minutes')
                                    }
                                    if ((moment(end).format('hh:mm A') !== '11:59 PM') && ((moment(end).format('mm') == '59') || (moment(end).format('mm') == '29'))) {
                                      end = end.add(1, 'minutes')
                                    }
                                  newConvertArray[tutorTimeManagementCount].timeSlots[timeSlotCount].from = moment(start)
                                  newConvertArray[tutorTimeManagementCount].timeSlots[timeSlotCount].to = moment(end)
                                  timeSlotCount+= 1;
                                  timeSlotCountFun(timeSlot[timeSlotCount]);
                                }
                            }
                        }
                        timeSlotCountFun(timeSlot[timeSlotCount]);
                    } else {
                        tutorTimeManagementCount += 1;
                        tutorTimeManagementFun(newConvertArray[tutorTimeManagementCount]);
                    }
                }
            }
            tutorTimeManagementFun(newConvertArray[tutorTimeManagementCount]);
    },
    calculateHolidayArray(holidayArray,LearnerTimeZone){
      let count = 0;
      let newHolidayArray = [...holidayArray]
      let countFunction = (row) => {
          if (count >= newHolidayArray.length) {
            this.tutorHolidayArray = newHolidayArray
            return;
          } else {
            let from = new Date(row.from.seconds * 1000)
            let to = new Date(row.to.seconds * 1000)
            let start = moment_timezone.tz(from,LearnerTimeZone)
            let end = moment_timezone.tz(to,LearnerTimeZone)
             let startlocal = DateTime.local(parseInt(moment(start).format("YYYY")),parseInt(moment(start).format("MM")),parseInt(moment(start).format("DD")),parseInt(moment(start).format("HH")),parseInt(moment(start).format("mm")))
            let st = (startlocal.toSeconds() * 1000)
            let endlocal = DateTime.local(parseInt(moment(end).format("YYYY")),parseInt(moment(end).format("MM")),parseInt(moment(end).format("DD")),parseInt(moment(end).format("HH")),parseInt(moment(end).format("mm")))
            let en = (endlocal.toSeconds() * 1000)
            newHolidayArray[count].from = st
            newHolidayArray[count].to = en
            count+= 1;
            countFunction(newHolidayArray[count])
          }
      }
      countFunction(newHolidayArray[count])
    },
    splitDateFunction(slotArray){
      let count = 0;
      let newArray = slotArray
      let countFunction = (row) => {
        if (count >= newArray.length) {
          return;
        } else {
           let timeSlot = []
            row.timeSlots.forEach((data)=>{
                timeSlot.push(data)
            })
            let timeSlotCount = 0
            let timeSlotCountFun = (timeSlotss) => {
                if (timeSlotCount >= timeSlot.length) {
                    count+= 1;
                    countFunction(newArray[count])
                    return;
                } else {
                    if (moment(timeSlotss.from).format("DD-MM-YYYY") == moment(timeSlotss.to).format("DD-MM-YYYY")) {
                      this.addTime(timeSlotss.from,timeSlotss.to,moment(timeSlotss.from).day())
                      timeSlotCount+= 1;
                      timeSlotCountFun(timeSlot[timeSlotCount]);
                    } else {
                      this.addTime(timeSlotss.from,moment(timeSlotss.from).set({hour:23,minutes:59}),moment(timeSlotss.from).day())
                      this.addTime(moment(timeSlotss.to).set({hour:0,minutes:0}),timeSlotss.to,moment(timeSlotss.to).day())
                      timeSlotCount+= 1;
                      timeSlotCountFun(timeSlot[timeSlotCount]);
                    }
                }
            }
            timeSlotCountFun(timeSlot[timeSlotCount]);
        }
      }
      countFunction(newArray[count])
    },
    lastDayOfMonth(y,m,dy,date){
    var  dat = new Date(y+'/'+m+'/1')
        ,currentmonth = m
        ,firstday = false;
     while (currentmonth === m){
       firstday = dat.getDay() === dy || firstday;
       dat.setDate(dat.getDate()+(firstday ? 7 : 1));
       currentmonth = dat.getMonth()+1 ;
     }
     dat.setDate(dat.getDate()-7);
     dat.setHours(date.getHours())
     dat.setMinutes(date.getMinutes())
     return dat;
    },
    addTime(startTime,endTime,day){
      var finx1 = this.tutorTimeSlotArray?.findIndex((x) => x.dayId == day);
      if(finx1>=0){
        let newTime={from:startTime,to:endTime}
        this.tutorTimeSlotArray[finx1].timeSlots?.push(newTime)
      }else{
        let newTime={
          isEnabled:true,
          dayId:day,
          timeSlots:[{from:startTime,to:endTime}]
        } 
        this.tutorTimeSlotArray.push(newTime)
      }
    }
  },
  beforeRouteLeave(from,to,next){
    this.tutorTimeSlotArray = [];
    next()
  }

};
</script>
<style lang="scss" scoped>
  .repeater-form {

    overflow: hidden;
    transition: 0.35s height;
  }
  .addClassForPlaceHolder:after {
    content: '--:-- --';
    position: absolute;
    top: 11px;
    left: 15px;
    z-index: 9;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>