<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />

      <b-card class="user_detail_top_section current_detail_top_section">
        <h4>{{bookingTitle}}</h4>
        <b-row v-for="(item, index) in rowsArrayPastSession" :key="index" class="fontchanges">
          <b-col xl="3" lg="3" md="3">
            <h5>Booking ID</h5>
            <p>{{ item.bookingId }}</p>
            <h5>Category</h5>
            <p>
              <router-link
                :to="`/category-management/category/${item.categoryId}`"
                class="text-decoration-underline"
                >
                {{ item.category }}
                </router-link
              >
            </p>
            <h5>Tutor Name</h5>
            <span class="text-nowrap">
              <b-avatar :src="item.tutorAvtar" class="user_img" />
              <router-link
              :to="{
                  name: 'user-details',
                  params: {
                      id: item.bookingTutor
                  },
              }"
                class="text-decoration-underline"
                >{{ item.tutorNameDetail }}</router-link
              >
            </span>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Session Booking Date & Time</h5>
            <p>
             {{sessionTimeCalculate(item.sessionBooking, item.sessionEnd)}}
            </p>
             <h5>Teaching Language</h5>
            <p>{{ item.teachinglanguage }}</p>
            <h5>Tutor Type</h5>
            <span class="tutor_type">
              <b-badge :style="getTutorTypeStyle(item.tutorType)">
                <!-- :variant="getTutorTypeBadgeVariant(item.tutorType)" -->
                {{ item.tutorType }}
              </b-badge>
            </span>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Booked Date & Time</h5>
            <p>{{ TemplateCreatedDate(item.booked) }}</p>
            <h5>Learner Name</h5>
            <span class="text-nowrap">
              <b-avatar :src="item.learnerAvtar" class="user_img" />
              <router-link
              :to="{
                  name: 'user-details',
                  params: {
                      id: item.bookinglernerId
                  },
              }"
                class="text-decoration-underline"
                >{{ item.learnerChildName }}</router-link
              >
            </span>
            <h5 style="margin-top:10px;">Search Tag(s)</h5>
            <div class="tag_btn">
              <div v-for="(item, index) in rowsArrayPastSession" :key="index">
                <b-badge
                  v-for="(items, index1) in item.tagName"
                  :key="index1"
                  class="btn mr-2 mb-1 btn-outline-secondary"
                >
                  {{ items }}
                </b-badge>
              </div>
            </div>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Booking Credits</h5>
            <span v-if="item.bookingcredits !== 0" style="color: #28c76f !important;font-weight:500;">
                {{ item.bookingcredits }} Credits
              </span>
              <span v-else style="color:red">
                Trial Booking
              </span>
          </b-col>
          <b-col md="6" class="mt-2">
            <h5>Description</h5>
            <div
                class="wrap-break-word"
                v-show="true"
                >
                <span v-html="limitTo(item.description )"></span>
                <a
                    class="readmore cancelReadMore"
                    v-b-modal="'readmore' + item.bookingId"
                    v-show="item.description .length >= 200"
                    
                    >Read more</a
                >
                <UserModal title="Cancel Reason" :text="item.description " :id="item.bookingId" />
            </div>
          </b-col>
        </b-row>
      </b-card>
    
      <div class="footerSection">
        <b-row>
          <b-col xl="6" lg="6" md="6">
        <b-card v-if="rowsArrayPastSession[0].notes && rowsArrayPastSession[0].notes.length" >
            <div class="notes section">
              <h2 >Notes</h2>
                <div v-for="(item, indexes) in rowsArrayPastSession" :key="indexes">
                    <div class="notes_list-wrapper" v-if="item.notes">
                        <div class="notes_list-list" v-for="(item1, indexes1) in item.notes" :key="indexes1">
                          <feather-icon icon="CheckSquareIcon" size="18" style="color:#0096DB;"/>
                          {{item1}}
                        </div>
                    </div>
                </div>
            </div>
          </b-card>
          </b-col>
           <b-col xl="6" lg="6" md="6">
        <b-card v-if="rowsArrayPastSession[0].studyMaterial && rowsArrayPastSession[0].studyMaterial.length">
        <div class="studyMaterial section">
           <h2 >Study Material</h2>
            <div v-for="(items, indexess) in rowsArrayPastSession" :key="indexess">
                 <div v-if="items.studyMaterial">
                    <div class="studymaterial_item d-flex justify-content-between" v-for="(item2, indexes2) in items.studyMaterial" :key ="indexes2">
                      <p>{{item2.uploadDocumentName}}</p>
                      <div class="d-flex studyMaterial_icon">
                      <a :href="item2.uploadDocumentUrl" target="_blank" v-if="item2.uploadDocumentUrl">
                        <feather-icon icon="EyeIcon" size="18" />
                      </a>
                       <span class="resume download_btn" v-if="item2.uploadDocumentUrl">
                          <p  v-on:click="downloadDocument(item2.uploadDocumentUrl)" > <feather-icon
                            icon="ArrowDownCircleIcon"
                            size="18"
                            class="text-body align-middle mr-25 download_text_header"
                            style="color: #0096DB !important;cursor:pointer;"
                          /></p>
                        </span>
                      </div>
                    </div> 
                </div>
            </div>
        </div>
          </b-card>
           </b-col>
        </b-row>
      </div>

    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import { mapGetters,mapMutations } from "vuex";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "@/views/SessionsManagement/pastSession(s)/pastSessionDetail.vue"
import { dbCollections } from "@/utils/firebaseCollection.js";
import UserModal from "@/@core/components/modals/ReadMoreModal.vue";
import status from "@/store/status";
import firebase from "@/utils/firebaseInit";
import moment from "moment";
export default {
  components: {
    BCard,
    BSpinner,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BButton,
    UserModal
  },
  data() {
    return {
      direction: false,
      isSpinner: false,
      isUpdating: false,
      currentitems: [],
      tutorType: [
        {
          1: "Valuable",
          2: "Elite",
          3: "Normal",
          4: "N/A",
        },
        {
          1: "light-primary",
          2: "light-danger",
          3: "light-success",
          4: "light-dark",
        },
      ],
      bookingIdForUpdate: "",
      rowsArrayPastSession: [],
      bookingTitle: "",
    };
  },
  beforeRouteLeave(from,to,next){
   var self = this;
     var  name = '';
     self.$root.$emit('pastSessionDetailsForName', name);
     next()
  },
  computed:{
    ...mapGetters({
       gettutorTypesList: "settings/getTutorTypes",
    })
  },
  created() {
    var self = this;
    this.pastSessionDetailPageUp()
  },
  methods: {
    getTutorTypeStyle(value) {
        if(this.gettutorTypesList) {
            let index = this.gettutorTypesList.findIndex((val ) =>{
              return val.name == value
            });
            if(index > -1) {
                return {
                  'background-color' : this.gettutorTypesList[index].backgroundColor,
                  'color' : this.gettutorTypesList[index].textColor,
                  'border-color': this.gettutorTypesList[index].textColor,
                  'border-width': '1px',
                  'border-style': 'solid'
                }
            }
        } else {
            return ''
        }
    },
    pastSessionDetailPageUp() {
      try {
        let self = this
        self.isSpinner = true
        this.rowsArrayPastSession = [];
        getFirebase.simpleGetDataFromSubCollectionWithDocIDV2(dbCollections.BOOKING,this.$route.params.id,(cancelDatas)=>{
        if(cancelDatas.status === false){
          self.$root.$emit('showToastMessage','Record not found.','danger');
          self.$router.push({ name: "past-session-mgmt" });
          self.isSpinner = false;
          return;
        }
        let cancelData = cancelDatas.data
        self.$root.$emit('pastSessionDetailsForName', cancelData.bookingId);
            getFirebase.simpleGetDataFromSubCollectionWithDocIDV2(dbCollections.SESSIONS,cancelData.sessionDetails.id,(ress)=>{
              this.bookingTitle = cancelData.bookingsTitle ? cancelData.bookingsTitle : "";
              let res = ress.data
              var object = {
                bookingId: cancelData.bookingId,
                learnerChildName:
                  (cancelData.learnerDetails
                    ?cancelData.learnerDetails
                    .firstName:'') +
                  " " +
                  (cancelData.learnerDetails
                    ?cancelData.learnerDetails
                    .lastName:''),
                tutorNameDetail:
                  (cancelData.tutorDetails
                    ?cancelData.tutorDetails
                    .firstName:'') +
                  " " +
                  (cancelData.tutorDetails
                    ?cancelData.tutorDetails
                    .lastName:''),
                sessionCansallationTime: cancelData.cancellationDate,
                bookingcredits:
                  cancelData.usedCredits ? cancelData.usedCredits:0,
                cansalReason: cancelData.cancellationReason,
                sessionBooking:
                  cancelData.startTime,
                sessionEnd: cancelData.endTime,
                status: cancelData.status,
                booked: cancelData.createdAt,
                tutorType:
                  cancelData.tutorDetails
                    ?cancelData.tutorDetails
                    .tutorType:'',
                category:
                  cancelData.categoryDetails
                    .categoryName,
                categoryId:
                  cancelData.categoryDetails.id,
                sessionId:
                  cancelData.sessionDetails.id,
                teachinglanguage: res.teachingLanguageDetails ? res.teachingLanguageDetails.title : '-',
                description: res.description ? res.description:'',
                tagName: res.tags,
                bookingTutor: cancelData.tutor,
                bookinglernerId:
                  cancelData.learnerId,
                learnerAvtar: cancelData.learnerDetails.profileImage ? cancelData.learnerDetails.profileImage :"",
                tutorAvtar: cancelData.tutorDetails.profilePicture ? cancelData.tutorDetails.profilePicture :"",
                notes:cancelData.notes ? cancelData.notes:[],
                studyMaterial:cancelData.StudyMaterials ? cancelData.StudyMaterials:[]
              };
              this.rowsArrayPastSession.push(object);
              self.isSpinner = false;
            }
          );
      })
      } catch (error) {
        console.error("error", error);
        self.isSpinner = false;
        errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "pastSessionDetailPageUp",
            errorMessage : error.message
          },(mailRes) => {
            if(mailRes.error){
              console.error(mailRes);
            }
          })
      }
    },
    sessionTimeCalculate(startTime , endTime) {
      if (moment(startTime.seconds*1000).format("DD-MM-YYYY") == moment(endTime.seconds*1000).format("DD-MM-YYYY")) {
        return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("hh:mm A")}`
      } else {
        return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")}`
      }
    },
    limitTo: function (value) {
        if(value){
            if (value.length < 200){
              return value
            }else{
              return value.substring(0, 200) + "...";
            }
        }else{
          return "-";
        }
    },
     downloadDocument(doc){
            var self = this
             var httpsReference = firebase.storage().refFromURL(doc)
            self.$axios({
                url: doc,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', httpsReference.name);
                document.body.appendChild(fileLink);

                fileLink.click();
            })
            .catch(error=>{
                console.error(error)
                self.$root.$emit('showToastMessage',error.message,'danger')
            })
    },
   getTutorTypeBadgeVariant(value){
      var variant = "light-";
      if(value == "Normal")
      {
        variant += 'success';
      }
      else if(value == "Elite")
      {
        variant += 'danger';
      }
      else if(value == "Valuable")
      {
        variant += 'primary';
      }
      else{
        variant += 'warning'
      }
      return variant;
    },
    TemplateCreatedDate(TemplateCreatedDate) {
      let self = this;
      var notificationDate = new Date(
        TemplateCreatedDate.seconds * 1000
      ).getDate();
      var notificationMonth =
        new Date(TemplateCreatedDate.seconds * 1000).getMonth() + 1;
      var notificationYear = new Date(
        TemplateCreatedDate.seconds * 1000
      ).getFullYear();
      var returnnotificationDate =
        self.addZero(notificationDate) +
        "-" +
        self.addZero(notificationMonth) +
        "-" +
        notificationYear +
        ", " +
        self.notificationCreateTime(
          new Date(TemplateCreatedDate.seconds * 1000)
        );
      return returnnotificationDate;
    },

    TemplateEndDate(TemplateEndDate) {
      let self = this;
      var returnnotificationDate = self.notificationCreateTime(
        new Date(TemplateEndDate.seconds * 1000)
      );
      return returnnotificationDate;
    },

    notificationCreateTime(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      hours = hours<10 ? '0'+hours:hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },

    addZero(value) {
      if (value > 9) {
        return value;
      } else {
        return "0" + value;
      }
    },
  },  
};
</script>
<style scoped>
   .cancelReadMore{
    color: #0096DB !important;
    text-decoration: none !important;
  }
</style>