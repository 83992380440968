<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
    </div>
    
      <div class="card user_table categories_table subcategories_table grade" v-if="!isSpinner">
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Search..."
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </div>
            <vue-good-table
              :columns="columns"
              :rows="syllabusDisplay"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :select-options="{
                enabled: true,
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
              <template slot="table-row" slot-scope="props">
               <span v-if="props.column.field === 'syllabusname'" class="text-nowrap">
                  {{props.row.syllabusname}}
               </span>
                <span v-else-if="props.column.field === 'noOfGrade'" class="text-nowrap">
                  {{props.row.noOfGrade}}
               </span>
                <span
                  v-else-if="props.column.field === 'action'"
                  class="action_field"
                >
                  <b-button variant="flat-dark" @click="editSyllabus(props.row)">
                    <img
                      :src="require('@/assets/images/erflog/Edit.png')"
                      alt="image"
                    />
                  </b-button>
                  <b-button variant="flat-dark" class="ml-2" @click="deleteSyllabus(props.row.id)">
                    <img
                      :src="require('@/assets/images/erflog/Delete.png')"
                      alt="image"
                    />
                  </b-button>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> 
                  <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to
                    <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                    <span v-else>{{props.total}} of {{ props.total }} entries</span>
                  </span>

                      <div class="d-flex align-items-center mb-1 mt-0 showentry">
                        <span>Show</span>
                        <b-form-select
                          v-model="pageLength"
                          :options="['10', '20', '30', '40', '50']"
                          class="mx-1"
                          @input="
                            (value) => props.perPageChanged({ currentPerPage: value })
                          "
                      />
                        <span>entries</span>
                      </div>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :current-page="currentPage"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => {
                        props.pageChanged({ currentPage: value });
                        currentPage = value
                      }
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>`
            </template>
            </vue-good-table>
      </div>
  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
  BTabs,
  BTab,
  BCard,
  BModal,
  VBModal,
  BSpinner
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { mapGetters } from "vuex"; 
import firebase from "@/utils/firebaseInit"
import { dbCollections } from "@/utils/firebaseCollection"
const db = firebase.firestore();
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/CurriculamManagement/Syllabus.vue"
export default {  
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BCard,
    BButton,
    BTabs,
    BTab,
    BModal,
    BSpinner
  },
  data() {
    return {
      direction:false,
      isSpinner:false,
      isUpdating:false, 
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      pageLength: 10,
      syllabusDisplay:[],
      dir: false,
      columns: [
        {
          label: "Curriculum Name",
          field: "syllabusname",
        },
        {
          label: "No. of Grade",
          field: "noOfGrade",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
       searchTerm: "",
    };
  },
  created() {
    this.getSyllabus();
  },
  computed:{
      ...mapGetters({syllabus:'settings/getSyllabus',
       getSyllabusList: 'settings/getSyllabus',}),
  },
  methods: {
    getSyllabus(){
      var self = this;
      self.firebaseQuery(dbCollections.SETTINGS,'setting',dbCollections.SYLLABUS,(syllabus)=>{
        syllabus.forEach((data)=>{
          let index = self.syllabusDisplay.findIndex((x) => x.id === data.id);
          if(data.isDeleted && index !== -1) {
            self.syllabusDisplay.splice(index, 1);
             self.isSpinner = false;
          } else {
            db.collection(dbCollections.SETTINGS).doc("setting").collection(dbCollections.GRADE).where("curriculumId","==",data.id).get()
            .then((snapshot) => {
              var syllabusObj = {
                  id : data.id,
                  syllabusname : data.syllabusName,
                  noOfGrade : snapshot.size
              }
              if (index !== -1) {
                self.syllabusDisplay[index].id = syllabusObj.id;
                self.syllabusDisplay[index].syllabusname = syllabusObj.syllabusname;
                self.syllabusDisplay[index].noOfGrade = syllabusObj.noOfGrade;
                 self.isSpinner = false;
              } else {
                self.syllabusDisplay.push(syllabusObj);
                 self.isSpinner = false;
              }
            })
            .catch(error=>{
              console.error("error",error)
              self.isSpinner = false;
            })
          }
        })
      })
    },
    firebaseQuery(collectionName,id,subCollectionName,cb) {
      var self = this;
      self.snapshotListener = db.collection(collectionName).doc(id).collection(subCollectionName).onSnapshot({includeMetadataChanges: true},(snapshot) => {
        if (!snapshot.empty && snapshot.docChanges().length) {
          let response = [];
          snapshot.docChanges().forEach(change=>{
            if(change.type === "added") {
              if(change.doc.data().id) {
                response.push({id: change.doc.id, ...change.doc.data()})
              }
            } else if(change.type === "modified") {
              response.push({id: change.doc.id, ...change.doc.data()})
            } else if(change.type === "removed") {
              response.push({id: change.doc.id, ...change.doc.data(),isDeleted: true})
            }
          })
          cb(response);
        }
      },
      (error)=>{
        console.error("error",error.message);
        cb([]);
      })
    },
      deleteSyllabus(id,index){
          try {
              var self = this;
              self.$bvModal
              .msgBoxConfirm('Are you sure you want to Delete it?', {
                title: 'Delete',         
                class:'test',
                okVariant: 'primary',
                okTitle: 'Yes',
                cancelTitle: 'No',
                cancelVariant: 'outline-primary',
                hideHeaderClose: false,
                centered: true,
                footerClass: 'approve-footer',
                headerClass: 'approve-header',
                bodyClass: 'approve-body',
              })
              .then(value => {
                if(value){
                  db.collection(dbCollections.SETTINGS).doc("setting")
                    .collection(dbCollections.SYLLABUS).doc(id).delete()
                    .then(()=>{
                       var index = self.syllabusDisplay.findIndex(item => {
                          return item.id === id
                      })
                      if(index === 0 && self.syllabusDisplay.length === 1 && self.syllabus.length === 1){
                          self.syllabusDisplay.splice(index,1)
                          self.syllabus.splice(index,1)
                        }
                        self.$root.$emit('showToastMessage','Curriculum deleted successfully.','success');
                    })
                    .catch((err)=>{
                        console.error("err",err)
                    })
                }
              })
          } catch (error) {
              console.error("error",error);
              errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "deleteSyllabus",
              errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
            })
          }
      },
      editSyllabus(syllabus){
          this.$root.$emit('editSyllabus',syllabus.syllabusname,syllabus.id);
      }
  },
};
</script>
