<template>
    <div>
    <b-spinner
      variant="primary"
      large
      v-if="isSpinner"
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-card class="user_detail_top_section current_detail_top_section">
        <h4>{{bookingTitle}}</h4>
        <b-row v-for="(item, index) in dataDisplay" :key="index" class="fontchanges">
          <b-col xl="3" lg="3" md="3">
            <h5>Booking ID</h5>
            <p>{{ item.bookingId }}</p>
            <h5>Category</h5>
            <p>
              <router-link
                :to="`/category-management/category/${item.categoryId}`"
                class="text-decoration-underline"
                >
                {{ item.category }}
                </router-link
              >
            </p>
            <h5>Tutor Name</h5>
            <span class="text-nowrap">
              <b-avatar :src="item.avatarTutor" class="user_img" />
              <router-link
              :to="{
                  name: 'user-details',
                  params: {
                      id: item.bookingTutor
                  },
              }"
                class="text-decoration-underline"
                >{{ item.tutorNameDetail }}</router-link
              >
            </span>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Session Booking Date & Time</h5>
            <p>
              {{sessionTimeCalculate(item.sessionBooking, item.sessionEnd)}}
            </p>
             <h5>Teaching Language</h5>
            <p>{{ item.teachinglanguage }}</p>
            <h5>Tutor Type</h5>
            <span class="tutor_type">
              <b-badge :style="getTutorTypeStyle(item.tutorType)">
                <!-- :variant="getTutorTypeBadgeVariant(item.tutorType)" -->
                {{ item.tutorType }}
              </b-badge>
            </span>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Booked Date & Time</h5>
            <p>{{ TemplateCreatedDate(item.booked) }}</p>
            <h5>Learner Name</h5>
            <span class="text-nowrap">
              <b-avatar :src="item.avatarLearner" class="user_img" />
              <router-link
              :to="{
                  name: 'user-details',
                  params: {
                      id: item.bookinglernerId
                  },
              }"
                class="text-decoration-underline"
                >{{ item.learnerChildName }}</router-link
              >
            </span>
            <h5 style="margin-top:10px">Search Tag(s)</h5>
            <div class="tag_btn">
              <div v-for="(item, index) in dataDisplay" :key="index">
                <b-badge
                  v-for="(items, index1) in item.tagName"
                  :key="index1"
                  class="btn mr-2 mb-1 btn-outline-secondary"
                >
                  {{ items }}
                </b-badge>
              </div>
            </div>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Booking Credits</h5>
            <p class="booking_credits" v-if="item.bookingcredits !== undefined">{{ item.bookingcredits }} Credits</p>
            <p class="booking_credits" style="color:red;" v-else>Trial Booking</p>
          </b-col>
          <b-col md="6" class="mt-2">
            <h5>Description</h5>
            <p class="description">{{ item.description }}</p>
          </b-col>
          <b-col md="6" class="mt-2">
            
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import { dbCollections } from "@/utils/firebaseCollection.js";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/TransactionHistory/transectionBookingDetailPage.vue"
import moment from "moment";
import { mapGetters } from 'vuex';
export default {
    name : "TransectionBookingDetailPage",
    components: {
    BCard,
    BSpinner,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BButton,
    },
    data() {
        return{
            dataDisplay : [],
            isSpinner: false,
            bookingTitle:"",
        }
    },
    computed:{
      ...mapGetters({
         gettutorTypesList: "settings/getTutorTypes",
      })
    },
    created(){
        this.getDisplayData()
        this.$root.$emit('transectionHistoryTab',this.$route.query.tab)
    },
    methods: {
      getTutorTypeStyle(value) {
        if(this.gettutorTypesList) {
            let index = this.gettutorTypesList.findIndex((val ) =>{
              return val.name == value
            });
            if(index > -1) {
                return {
                  'background-color' : this.gettutorTypesList[index].backgroundColor,
                  'color' : this.gettutorTypesList[index].textColor,
                  'border-color': this.gettutorTypesList[index].textColor,
                  'border-width': '1px',
                  'border-style': 'solid'
                }
            }
        } else {
            return ''
        }
    },
        getDisplayData() {
            try {
                var self = this;
                self.isSpinner = true;
                getFirebase.simpleGetDataFromSubCollectionWithDocIDV2(dbCollections.BOOKING,this.$route.params.id,(cancelDatas)=>{
                if(cancelDatas.status === false){
                self.$router.push({ name: "booking-detail" });
                return;
                }
                let cancelData = cancelDatas.data
                    getFirebase.simpleGetDataFromSubCollectionWithDocIDV2(dbCollections.SESSIONS,cancelData.sessionDetails.id,(ress)=>{
                    this.bookingTitle = cancelData.bookingsTitle ? cancelData.bookingsTitle : "";
                    let res = ress.data
                    self.$root.$emit('transectionBookingDetailId', cancelData.bookingId);
                    var object = {
                        bookingId: cancelData.bookingId,
                        avatarTutor: cancelData.tutorDetails.profilePicture ? cancelData.tutorDetails.profilePicture :"",
                        avatarLearner: cancelData.learnerDetails.profileImage ? cancelData.learnerDetails.profileImage :"",
                        learnerChildName:
                        cancelData.learnerDetails
                            .firstName +
                        " " +
                        cancelData.learnerDetails
                            .lastName,
                        tutorNameDetail:
                        cancelData.tutorDetails
                            .firstName +
                        " " +
                        cancelData.tutorDetails
                            .lastName,
                        sessionCansallationTime: cancelData.cancellationDate,
                        bookingcredits:
                        cancelData.usedCredits,
                        cansalReason: cancelData.cancellationReason,
                        sessionBooking:
                        cancelData.startTime,
                        sessionEnd: cancelData.endTime,
                        status: cancelData.status,
                        booked: cancelData.createdAt,
                        tutorType:
                        cancelData.tutorDetails
                            .tutorType,
                        category:
                        cancelData.categoryDetails
                            .categoryName,
                        categoryId:
                        cancelData.categoryDetails.id,
                        sessionId:
                        cancelData.sessionDetails.id,
                        teachinglanguage: res.teachingLanguageDetails.title ,
                        description: res.description,
                        tagName: res.tags,
                        bookingTutor: cancelData.tutor,
                        bookinglernerId:
                        cancelData.learnerId,
                    };
                    self.dataDisplay.push(object);
                    self.isSpinner = false;
                    }
                );
            })
            } catch (error) {
                console.error("error", error);
                errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "getDisplayData",
                errorMessage : error.message
              },(res)=>{
                if(res.error){
                    console.error(res.error)
                }
              })
            }
        },
        sessionTimeCalculate(startTime , endTime) {
          if (moment(startTime.seconds*1000).format("DD-MM-YYYY") == moment(endTime.seconds*1000).format("DD-MM-YYYY")) {
            return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("hh:mm A")}`
          } else {
            return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")}`
          }
        },
        TemplateCreatedDate(TemplateCreatedDate) {
            var self = this;
            var notificationDate = new Date(
                TemplateCreatedDate.seconds * 1000
            ).getDate();
            var notificationMonth =
                new Date(TemplateCreatedDate.seconds * 1000).getMonth() + 1;
            var notificationYear = new Date(
                TemplateCreatedDate.seconds * 1000
            ).getFullYear();
            var returnnotificationDate =
                self.addZero(notificationDate) +
                "-" +
                self.addZero(notificationMonth) +
                "-" +
                notificationYear +
                ", " +
                self.notificationCreateTime(
                new Date(TemplateCreatedDate.seconds * 1000)
                );
            return returnnotificationDate;
        },

        TemplateEndDate(TemplateEndDate) {
            var self = this;
            var returnnotificationDate = self.notificationCreateTime(
                new Date(TemplateEndDate.seconds * 1000)
            );
            return returnnotificationDate;
        },
        getTutorTypeBadgeVariant(value){
            var variant = "light-";
            if(value == "Normal")
            {
                variant += 'success';
            }
            else if(value == "Elite")
            {
                variant += 'danger';
            }
            else if(value == "Valuable")
            {
                variant += 'primary';
            } else if (value == "Expert") {
              variant += "warning";
            } else {
              variant += 'warning'
            }
            return variant;
        }, 
        addZero(value) {
            if (value > 9) {
                return value;
            } else {
                return "0" + value;
            }
        },
        notificationCreateTime(date) {
            var hours = date.getHours();
            var minutes = date.getMinutes();
            var ampm = hours >= 12 ? "PM" : "AM";
            hours = hours % 12;
            hours = hours ? hours : 12; // the hour '0' should be '12'
            hours = hours<10 ? '0'+hours:hours
            minutes = minutes < 10 ? "0" + minutes : minutes;
            var strTime = hours + ":" + minutes + " " + ampm;
            return strTime;
        },
    }

}
</script>

<style>

</style>