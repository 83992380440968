<template>
    <div>
        <b-spinner
            variant="primary"
            v-if="isSpinner"
            large
            class="pageLoadSpinners loaderDetail"
        />
        <div v-show="!isSpinner">
            <b-tab title="Canceled Session(s)" @click="onCancelSessionLearner">
                <div class="card user_table session_table canceled_session_table" v-if="!isSpinner">
                    <div class="custom-search d-flex justify-content-end">
                        <b-form-group>
                            <div class="d-flex align-items-center">
                                <b-form-input
                                    v-model="canceledsessionsearchTerm"
                                    placeholder="Search..."
                                    type="text"
                                    class="d-inline-block"
                                />
                            </div>
                        </b-form-group>
                    </div>
                    <vue-good-table
                    :columns="canceledsessioncolumnsdetail"
                    :rows="cancelrows"
                    :rtl="direction"
                    class="cursor_pointer"
                    @on-cell-click="onRowClick"
                    :search-options="{
                        enabled: true,
                        externalQuery: canceledsessionsearchTerm,
                    }"
                    :select-options="{
                        enabled: true,
                        selectionInfoClass: 'custom-class',
                        selectionText: 'rows selected',
                        clearSelectionText: 'clear',
                        disableSelectInfo: true, // disable the select info panel on top
                    }"
                    :pagination-options="{
                        enabled: true,
                        perPage: pageLength,
                    }"
                    >
                        <template slot="table-row" slot-scope="props">
                            <span
                            v-if="props.column.field === 'bookingid'"
                            class="text-nowrap userid"
                            >
                                <span
                                    class="text-nowrap"
                                    >{{ props.row.bookingid }}</span
                                >
                            </span>
                            <span
                            v-else-if="props.column.field === 'tutorname'"
                            class="text-nowrap"
                            >
                                <b-avatar :src="props.row.avatar" class="user_img" />
                                <span class="userid" @click="getDataAgain()">
                                    <router-link :to="{
                                        name: 'user-details',
                                        params: {
                                            id: props.row.bookinglernerId
                                        },
                                    }">{{
                                    props.row.tutorname
                                    }} {{props.row.tutornamelast}}</router-link>
                                </span>
                            </span>
                            <div
                                v-else-if="props.column.field === 'cancelreason'"
                                class="wrap-break-word"
                                v-show="true"
                                >
                                <span v-html="limitTo(props.row.cancelreason)"></span>
                                <a
                                    class="readmore"
                                    v-b-modal="'readmore' + props.row.bookingid"
                                    v-show="!props.row['isEditable'] && props.row.cancelreason.length >= 90"
                                    
                                    >Read more</a
                                >
                                <UserModal title="Cancel Reason" :text="props.row.cancelreason" :id="props.row.bookingid" />
                            </div>
                            <span v-else-if="props.column.field === 'sessiontitle'" class="text-nowrap">
                            {{ props.row.sessiontitle}}
                            </span>
                        </template>
                        <template slot="pagination-bottom" slot-scope="props">
                            <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap"> 
                                <span v-if="props.total === 0"> Showing 0 </span>
                                <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                                to
                                <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                                <span v-else>{{props.total}} of {{ props.total }} entries</span>
                                </span>

                                    <div class="d-flex align-items-center mb-1 mt-0 showentry bookingRequest">
                                    <span>Show</span>
                                    <b-form-select
                                        v-model="pageLength"
                                        :options="['10', '20', '30', '40', '50']"
                                        class="mx-1"
                                        @input="
                                        (value) => props.perPageChanged({ currentPerPage: value })
                                        "
                                    />
                                    <span>entries</span>
                                    </div>
                            </div>
                            <div>
                                <b-pagination
                                :value="1"
                                :total-rows="props.total"
                                :current-page="currentPage"
                                :per-page="pageLength"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="
                                    (value) => {
                                    props.pageChanged({ currentPage: value });
                                    currentPage = value
                                    }
                                "
                                >
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                                </b-pagination>
                            </div>
                            </div>
                        </template>
                    </vue-good-table>
                </div>
            </b-tab>
        </div>
    </div>
</template>

<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";

import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import { dbCollections } from "@/utils/firebaseCollection.js";
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import UserModal from "@/@core/components/modals/ReadMoreModal.vue";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Users/User/UserDetailTabs/UserDetail_Canceled_Session_Learner.vue"
export default {
    name: "UserDetailCanceledSessionLearner",
    directives: {
        Ripple,
    },
    components: {
        VueGoodTable,
        BCard,
        UserModal,
        BImg,
        BRow,
        BCol,
        BDropdown,
        BDropdownItem,
        BCardText,
        BTabs,
        BTab,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BForm,
        BButton,
        flatPickr,
        BSpinner,
    },
    props : {
        getDataAgain : Function
    },
    data() {
        return {
            direction: false,
            pageLength: 10,
            currentPage: 1,
            isSpinner: false,
            count:0,
            canceledsessionsearchTerm: "",
            canceledsessioncolumnsdetail: [
                {
                    label: "Booking ID",
                    field: "bookingid",
                },
                {
                    label: "Session(s) Title",
                    field: "sessiontitle",
                },
                {
                    label: "Tutor Name",
                    field: "tutorname",
                },
                {
                    label: "Cancellation Reason",
                    field: "cancelreason",
                },
            ],
            cancelrows:[],
        }
    },

    methods: {
        onRowClick(data) {
            if (data.column.field === "tutorname") {
                this.$router.push({name: 'user-details', params: { id: data.row.bookinglernerId }})
            } else {
                this.$router.push({name: 'cancel-session-details', params: { id: data.row.Id}})
            }
        },
        cancleLearnerMyMethod(){
            var self = this;
            self.isSpinner = true;
            try {
                self.cancelrows = [];
                getFirebase.simpleGetDataWithSpecificTwoWhereCondition(
                    dbCollections.BOOKING,
                    "learnerId",
                    "==",
                    this.$route.params.id,
                    "status",
                    "==",
                    3,
                    (res) => {
                        if(res.length == 0){
                            self.isSpinner = false;
                            return;
                        }
                        res.forEach((syl) => {
                            var cancel = {
                            bookingid: syl.bookingId,
                            tutorname: syl.tutorDetails.firstName + " " + syl.tutorDetails.lastName,
                            cancelreason: syl.cancellationReason,
                            sessiontitle: syl.sessionDetails.name,
                            avatar: syl.tutorDetails.profilePicture,
                            Id: syl.id,
                            bookinglernerId: syl.tutor,
                            };
                            self.cancelrows.push(cancel);
                            if(res.length == this.cancelrows.length){
                                self.isSpinner = false;
                            }
                        });
                    }
                );
            } catch (error) {
                console.error("error", error);
                errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "cancleLearnerMyMethod",
                errorMessage : error.message
                },(res)=>{
                if(res.error){
                    console.error(res.error)
                }
                })
                self.isSpinner = false;
            }
        },

        limitTo: function (value) {
            if(value){
                if (value.length < 90){
                    return value
                }else{
                    return value.substring(0, 90) + "...";
                }
            }else{
                return "-";
            }
        },

        onCancelSessionLearner(){
            var self = this;
            var allTabList = document.getElementsByClassName('nav-link active')
            if (allTabList[0].innerText != 'Canceled Session(s)') { 
                self.cancleLearnerMyMethod();
                self.limitTo();
            }
        }
    }
}
</script>