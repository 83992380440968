<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners loaderDetail"
      style="position: fixed; z-index: 999999 !important;"
    />
    <div v-show="!isSpinner">
      <b-tab title="Child List" @click="onChildList">
        <div class="card user_table child_list_table">
          <div class="custom-search text-right">
            <b-button
              v-if="userDetail.hasChildren.length<=1"
              variant="outline-primary"
              v-b-modal.modal-add-child
              class="d-inline-block align-top mr-2"
              id="toggle-btn"
              @click="addChild"
              :disabled="isSpinner || userStatus == 4"
              >Add Child
            </b-button>
             <b-modal
              id="modal-add-child"
              ref="my-modal"
              title="Add Child"
              ok-title="Add"
              cancel-variant="outline-primary"
              centered
              data-class="addcredit_popup"
              @show="ChildresetModal"
              @hidden="ChildresetModal"
              @ok="ChildHandleSubmit"
              :ok-disabled="isSpinner"
              :cancel-disabled="isSpinner"
        >
          <validation-observer ref="Childform">
            <b-form-group>       
                  <label label-for='First Name'>First Name<span class="required">*</span></label>           
                  <validation-provider
                    #default="{ errors }"
                    name="first name"
                    rules="required|max:25|min:2"
                  >
                    <b-form-input
                      v-model="childfirstName"
                      :maxlength='30'
                      placeholder="Enter Text"
                      @keypress="isOnlyCharacter($event)"
                      @copy.prevent 
                      @paste.prevent 
                      ondragover="return false"
                    />
                    <small class="text-danger" >{{ errors[0] }}</small>
                  </validation-provider>
            </b-form-group>
            <b-form-group>       
                  <label label-for='First Name'>Last Name<span class="required">*</span></label>           
                  <validation-provider
                    #default="{ errors }"
                    name="last name"
                    rules="required|max:25|min:2"
                  >
                    <b-form-input
                      v-model="childlastName"
                      :maxlength='30'
                      placeholder="Enter Text"
                      @keypress="isOnlyCharacter($event)"
                      @copy.prevent 
                      @paste.prevent 
                      ondragover="return false"
                    />
                    <small class="text-danger" >{{ errors[0] }}</small>
                  </validation-provider>
            </b-form-group>

          <form ref="form">
              <validation-provider
                  #default="{ errors }"
                  name="email "
                  rules="required|email"
                >
              <b-form-group>
                 <label label-for='Email'>Email<span class="required">*</span></label>  
              <b-form-input
                id="name-input"
                v-model.trim="email"
                placeholder="Enter Email"
                autocomplete="off"
                :maxlength=50
                required
              />
              <small class="text-danger" style="display:block;" v-if="Email_Error ==''">{{ errors[0] }}</small>
              <small class="text-danger">{{ Email_Error}}</small> 
              </b-form-group>
              </validation-provider>
          </form>
          <b-form-group>
              <label label-for="Mobile No.">Mobile No.<span class="required">*</span></label>
              <div class="mobileandcountry">
                        <v-select
                            v-model="selectedCode"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="countryCodeList.allCountries"
                            @input="changeCountryCode(selectedCode)"
                            label="text"
                            placeholder="Select"
                            class="selectcountrynumber child"
                          >
                          <template #option="{ text }">
                            <div class="d-flex align-items-center justify-content-between">
                              <span>
                                <span> {{ text }}</span>
                              </span>
                            </div>  
                          </template>
                        </v-select>                                    
                        <b-input-group class="number123 addchild">
                            <validation-provider
                              #default="{ errors }"
                              name="mobile no."
                              rules="required|max:15"
                              class="enternumber"
                            >                    
                            <b-form-input
                              placeholder="Enter Number"
                              v-model="mobileno"
                              :maxlength="15"
                              id='Mobile No.'
                              @keypress="isOnlyNumber($event)"
                              @copy.prevent 
                              @paste.prevent 
                              ondragover="return false"
                            />
                            <small class="text-danger validationnumber leftvalidation"  v-if='MobileNo_Error == ""'>{{ errors[0] }}</small>   
                            <small class="text-danger validationnumber leftvalidation">{{ MobileNo_Error}}</small>
                            </validation-provider>  
                        </b-input-group>  
              </div>  
          </b-form-group>

          <b-form-group class="gendertop">       
                  <label label-for="gender">Gender<span class="required">*</span></label>
                  <div class="demo-inline-spacing schedulecampaign_radios">
                    <b-form-radio
                      v-model="GenderSelected"
                      name="some-radios"
                      value="male"
                    >
                      Male
                    </b-form-radio>
                    <b-form-radio
                      v-model="GenderSelected"
                      name="some-radios"
                      value="female"
                    >
                      Female
                    </b-form-radio>
                    <b-form-radio
                      v-model="GenderSelected"
                      name="some-radios"
                      value="Other"
                    >
                      Other
                    </b-form-radio>
                  </div>
          </b-form-group>
          </validation-observer>
        </b-modal>
            <b-form-group class="d-inline-block mb-0 align-middle">
              <div class="d-flex align-items-center">
                <b-form-input
                  v-model="childlistsearchTerm"
                  placeholder="Search..."
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
          </div>
          <vue-good-table
            :columns="childlistcolumns"
            :rows="childListArr"
            class="cursor_pointer"
            @on-row-click="onRowClick"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: childlistsearchTerm,
            }"
            :select-options="{
              enabled: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <span
                v-if="props.column.field === 'fullName'"
                class="text-nowrap childname"
                @click="getDataAgain()"
              >
                <b-avatar :src="props.row.avatar" class="user_img" />
                <span
                  class="text-nowrap"
                  >{{ props.row.fullName }}
                </span>
              </span>
              <span v-else-if="props.column.field === 'status'" class="status">
                <b-badge :variant="getAllStatusList.userStatus[props.row.status].variant">
                  {{ getAllStatusList.userStatus[props.row.status].status }}
                </b-badge>
              </span>
              <span
                v-else-if="props.column.field === 'action'"
                class="action_field"
              >
                <b-button variant="flat-dark">
                  <router-link
                  :to="{
                    name: 'edit-user',
                    params: {
                      id: props.row.id,
                      name: props.row.firstName + ' ' + props.row.lastName,
                    },
                  }"
                >
                  <img
                    :src="require('@/assets/images/erflog/Edit.png')"
                    alt="image"
                  />
                  </router-link >
                </b-button>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> 
                  <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to
                    <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                    <span v-else>{{props.total}} of {{ props.total }} entries</span>
                  </span>

                      <div class="d-flex align-items-center mb-1 mt-0 showentry">
                        <span>Show</span>
                        <b-form-select
                          v-model="pageLength"
                          :options="['10', '20', '30', '40', '50']"
                          class="mx-1"
                          @input="
                            (value) => props.perPageChanged({ currentPerPage: value })
                          "
                      />
                        <span>entries</span>
                      </div>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :current-page="currentPage"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => {
                        props.pageChanged({ currentPage: value });
                        currentPage = value
                      }
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </b-tab>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BSpinner,
  BInputGroup,
  BFormRadio
} from "bootstrap-vue";
import * as getQuery from "@/utils/FirebaseQueries/getQueries/getQueries.js";
import { dbCollections } from "@/utils/firebaseCollection.js";
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import { mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import countryTelData from 'country-telephone-data';
import {phone} from 'phone';
import { required } from '@validations';
import {ValidationFunction} from '@/utils/globalValidations'
import * as getQueries from '@/utils/FirebaseQueries/getQueries/getQueries';
import firebase from "firebase";
import * as insert from '@/utils/FirebaseQueries/insertQueries/insertQueries.js';
import * as update from '@/utils/FirebaseQueries/updateQueries/updateQueries.js';
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import axios from "axios";
import { email } from 'vee-validate/dist/rules';
import {errorEmail} from "@/utils/apiRequests.js";
import vSelect from "vue-select";
const currentFileName = "src/views/Users/User/UserDetailTabs/userDetail_childList_learner.vue"
import {createUserEmail} from '../service.js'
import {createUserAuth} from '../serviceAuth.js'
export default {
  name: "UserdetailChildListLearner",
  props: {
    userDetail: Object,
    getDataAgain: Function,
    userStatus: Number,
  },
  directives: {
    Ripple,
  },
  components: {
    VueGoodTable,
    BCard,
    BImg,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BCardText,
    BTabs,
    BTab,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    BInputGroup,
    BFormRadio,
    flatPickr,
    BSpinner,
    ValidationProvider, ValidationObserver,
    BDropdown, 
    BDropdownItem,
    vSelect
  },
  directives:{
    Ripple
  },
  data() {
    return {
      childlistsearchTerm: "",
      childListArr: [],
      direction: false,
      pageLength: 10,
      currentPage: 1,
      count: 0,
      email:'',
      mobileno:"",
      childfirstName: '',
      countryCodeList: countryTelData,
      domainLink:process.env.VUE_APP_FIREBASE_API_URL_DOMAINLINK,
      childlastName:'',
      selectedDialCode:'',
      GenderSelected: "male",
      dialCodeObj:null,
      MobileNo_Error:"",
      Email_Error:"",
      childlistcolumns: [
        {
          label: "User ID",
          field: "userid",
        },
        {
          label: "Child Name",
          field: "fullName",
        },
        {
          label: "User Email",
          field: "email",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      isSpinner: false,
      errorMessageForChild:'',
       selectedCode : '',
      selectedCountryCode:{
          dialCode: "1",
          format: "+. (...) ...-....",
          iso2: "us",
          name: "United States",
          priority: 0,
          text: "+1(United States)",
          value: "+1"
      }
    };
  },
  computed: {
    ...mapGetters({
       getAllStatusList:'settings/getAllStatus',
    }),
  },

  created() {
    this.selectedCode = "+1";
    this.childArray = this.userDetail.hasChildren
      ? this.userDetail.hasChildren
      : null;
  },

  watch: {
    userDetail(newV, preV) {
      if (newV !== preV && newV !== null) {
        this.childArray = this.userDetail.hasChildren
          ? this.userDetail.hasChildren
          : null;
      }
    },
  },
  methods: {
    onRowClick(data){
      this.$router.push({name: 'user-details', params: { id: data.row.id , name: data.row.fullName}})
      this.getDataAgain()
    },
     changeCountryCode(country){
      if(country !== null && country != ''){
        this.selectedCountryCode = country;
        this.selectedCode = country.value;
        this.checkPhoneNumber(this.mobileno);
      }else{
        this.selectedCode = '+1'
      }
    },
    childData() {
      try{
        var self = this;
        self.childListArr = [];
        self.isSpinner = true;
          if (self.userDetail.hasChildren.length === 0) {
              self.isSpinner = false;
          }
        if (self.userDetail.hasChildren.length) {
          self.userDetail.hasChildren.forEach((id)=>{
          this.count = +1;
          getQuery.simpleGetDataFromSubCollectionWithDocID(dbCollections.USERS,id.id,(childList) => {
            let obj = {
                id: childList.id,
                userid: childList.userId ? childList.userId : "",
                fullName: childList.firstName + " " + childList.lastName,
                email: childList.email,
                status: childList.status
            }
            self.childListArr.push(obj);
            if (self.count === self.childListArr.length) {
                this.isSpinner = false;
            }
          })
          })
        }
      }catch (error) {
        console.error(error);
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "childData",
          errorMessage : error.message
        },(res)=>{
            if(res.error){
                console.error(res.error)
            }
        })
      }
    },

    onChildList() {
      var allTabList = document.getElementsByClassName("nav-link active");
      if (allTabList[0].innerText != "Child List") {
        this.childData();
      }
    },
    ChildresetModal(){
      //  
      var self = this;
      self.child = '';
      self.MobileNo_Error = "";
      self.Email_Error = "";
      self.errorMessageForChild == '';
    },
    closeFirechild(){
      
      var self = this;
      self.credit = '';
      self.errorMessageForChild == '';
    },
    addChild(){
      try{
        var self = this;
        let tempDialCode = self.countryCodeList.allCountries;
        tempDialCode.forEach((vals,index)=>{
          vals.text = "+"+vals.dialCode+"("+vals.name+")";
          vals.value = "+"+vals.dialCode;
        }) 
      }catch (error) {
        console.error(error);
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "addChild",
          errorMessage : error.message
        },(res)=>{
            if(res.error){
                console.error(res.error)
            }
        })
      }
      
    },
    isOnlyCharacter(event) {
      ValidationFunction.OnlyCharacter(event);
    },
    isOnlyNumber(event){
      ValidationFunction.OnlyNumber(event);
    },  
    checkPhoneNumber(event){
      var self = this;
      try{
        if(this.selectedCountryCode !== null){
          let phone_Number_details = phone(JSON.stringify(event), {country: this.selectedCountryCode.iso2 ? this.selectedCountryCode.iso2 : ''}); 
          if(phone_Number_details.isValid !== undefined && phone_Number_details.isValid == false){
            if(this.mobileno !== ''){
              this.MobileNo_Error = 'The mobile no. is invalid';
            }
          }else{
            this.MobileNo_Error = ''
             try{
              if(event.isValid == false){
                self.MobileNo_Error = 'The mobile no. is invalid';
              }
            }catch(error){
              console.error(error)
            }
          }
        } 
      }catch(error){
        console.error(error);
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "checkPhoneNumber",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        })
      }
    },
    ChildHandleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault();
      var self = this;
      self.isSpinner = true;
      this.$refs.Childform.validate().then((valid) => {
        if (valid) {   
          try{
            getQueries.simpleGetDataWithSpecificWhereCondition(dbCollections.USERS, "email", '==', self.email, (email) => {
                if (email.length) {
                  self.Email_Error = "The email address is already exist"
                  self.isSpinner = false;
                  return;
                } else {
                    self.Email_Error = ""
                    getQueries.simpleGetDataWithSpecificWhereCondition(dbCollections.USERS, "phoneNumber", '==', self.mobileno , (phone) => {
                      if (phone.length) {
                        self.MobileNo_Error = 'The mobile no. is already exist'
                        self.isSpinner = false;
                        return;
                        }
                        else {
                          self.checkPhoneNumber(self.mobileno);
                              if (valid &&  self.MobileNo_Error === "") {
                                  let parentDetail = {
                                    firstName : this.userDetail.firstName,
                                    lastName:this.userDetail.lastName,
                                    id: this.$route.params.id,
                                    profilePicture: this.userDetail.profilePicture,
                                  }
                                  let addObject = {
                                    'lastName':self.childlastName,
                                    'firstName':self.childfirstName,                     
                                    'activeSessions': [],
                                    'areaOfInterest': [],                            
                                    'createdAt': new Date(),
                                    'dateOfBirth': null,
                                    'email':self.email,
                                    'gender':self.GenderSelected,
                                    'hasChildren': [],
                                    'isChild': true,
                                    'isEmailVerified':false,
                                    'isParent':false,
                                    'isPhoneNumberVerified':false,
                                    'location':'',                            
                                    'profilePicture':'',
                                    'referralCode':'',
                                    'school':'',
                                    'schoolDetails':null,
                                    'status':0,
                                    'syllabus': '',
                                    'syllabusDetails':null,
                                    'timeZone': null,
                                    'updatedAt': new Date(),
                                    'userId':'',
                                    'userRole':['learner'],
                                    'phoneNumber':self.mobileno,
                                    'countryCode':self.selectedCountryCode ? self.selectedCountryCode.dialCode : '',
                                    'id':'',
                                    'currentCredit':0,
                                    'learnerReferralCode': "",
                                    'isUserBasicInfoCompleted':false,
                                    'sendChatNotification': true,
                                    'parentId' :self.$route.params.id,
                                    'hasParent' : parentDetail,
                                    'sendEmailNotification': true,
                                    'sendPushNotification':true
                                  }
                                let userDocId = ""
                               createUserAuth({
                                  isPassword : false,
                                  email : self.email,
                                  phoneNumber : "+" +  addObject.countryCode + addObject.phoneNumber
                               },(userAuth) => {
                                  self.isSpinner = false;
                                  if (!userAuth.status) {
                                      self.isSpinner = false;
                                      return;                   
                                    }
                                    else{
                                      if (userAuth.id !== undefined) {
                                        userDocId = userAuth.id
                                      } else {
                                        self.isSpinner = false;
                                        return;
                                      }
                                    }
                                    let userId = this.generateUid(userDocId) 
                                      addObject.userId = userId;
                                    insert.addDataToRootCollectionWithDocKey(dbCollections.USERS,userDocId,addObject,(response)=>{
                                        const updateUser = {
                                          id : userDocId
                                        }
                                        updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,userDocId,updateUser,(res) => {
                                          let userObject = {
                                            email : addObject.email,
                                            firstName : addObject.firstName,
                                            id: userDocId,
                                            lastName: addObject.lastName,
                                            profilePicture: addObject.profilePicture,
                                            status: addObject.status,
                                          }
                                          if(self.userDetail.hasChildren) {
                                              self.userDetail.hasChildren.push(userObject);
                                          } else {
                                            self.userDetail.hasChildren = [userObject] 
                                          }
                                          console.log(self.userDetail.hasChildren,"{{}{}")
                                          const updateParent = {
                                            hasChildren : self.userDetail.hasChildren,
                                          }
                                          updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,self.$route.params.id,updateParent, (res) => {
                                          }).then((resParent)=>{
                                                createUserEmail({
                                                  email: addObject.email,
                                                  userName: addObject.firstName + " " +addObject.lastName,
                                                  domainLink: self.domainLink,
                                                  uid:  userDocId,
                                                  editEmail: false,
                                                  newUser : true,  
                                                },(resp) => {console.log(resp,"resp")})
                                                  self.$root.$emit('showToastMessage',"Child added successfully.",'success');
                                                  let object = {
                                                    id : userDocId,
                                                    userid : addObject.userId,
                                                    fullName : addObject.firstName + " " + addObject.lastName,
                                                    email : addObject.email,
                                                    status : addObject.status
                                                  }
                                                  self.childListArr.push(object);
                                                  self.isSpinner = false;
                                                  self.MobileNo_Error = ''
                                                  self.Email_Error = ''
                                                  self.childfirstName =  ''
                                                  self.childlastName = ''
                                                  self.email = ''
                                                  self.mobileno = ''
                                              }).catch((err) => {
                                                console.error(err,"ParentUpdate");
                                                self.isSpinner = false;
                                              });
                                          })
                                          // .then((response) => {
                                          // })
                                          // .catch((err) => {
                                          //     console.error(err);
                                          //     self.isSpinner = false;
                                          // });
                                    }) 
                               })
                                self.$bvModal.hide('modal-add-child');  
                              } else {
                                self.isSpinner = false;
                              }
                        }
                    })
                }
          });
          }catch (error) {
            console.error(error);
            errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "ChildHandleSubmit",
              errorMessage : error.message
            },(res)=>{
                if(res.error){
                    console.error(res.error)
                }
            })
          }
        } else {
          self.isSpinner = false;
        }
      })
    },
    generateUid(uid) {
      try{
        var result           = '';
        var characters       = uid;
        var charactersLength = characters.length;
        for ( var i = 0; i < 6; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;  
      } catch(error) {
        console.error(error,"?");
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "generateUid",
          errorMessage : error.message
        },(res)=>{
            if(res.error){
                console.error(res.error)
            }
        })
      }
    },
   
  },
};
</script>

<style></style>
