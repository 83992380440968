<template>
  <div class="maincancel">
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div class="textcenter"  v-if="isSpinner && isSuspendAction">
      <h2>Please wait for the process to complete.</h2>
    </div>
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />

      <div class="card user_filter cancel_session">
        <h2>Filters</h2>

        <b-form @submit.prevent>
          <b-row>
            <b-col xl="3" lg="3" md="3">
              <label>Learner Name</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="user_type_selected"
                variant="outline-secondary"
                class="w-100 dropdownfilter"
              >
                <b-dropdown-item
                  v-for="item in user_type_options"
                  :key="item.value"
                  @click="user_type_selected = item.text"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
            <b-col xl="3" lg="3" md="3">
              <label>Tutor Name</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="tutor_type_selected"
                variant="outline-secondary"
                class="w-100 dropdownfilter"
              >
                <b-dropdown-item
                  v-for="item in tutor_type_options"
                  @click="tutor_type_selected = item.text"
                  :key="item.value"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
            <b-col xl="3" lg="3" md="3">
              <label>Tutor Type</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="status_selected"
                variant="outline-secondary"
                class="w-100"
              >
                <b-dropdown-item
                  v-for="item in status_options"
                   @click="status_selected = item.text"
                  :key="item.value"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <div class="card user_table cancel_session_table upcoming_session_table">
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1"></label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search..."
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="filterRows"
          @on-cell-click="onCellClick"
          class="cursor-pointer"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-column" slot-scope="props">
            <span v-if="props.column.label === 'Warning'">
              {{props.column.label}}
                <b-button
                  v-b-tooltip.v-primary
                  class="warningInfo"
                  title="Total number of warning"
                  variant="outline-primary"
                    v-b-tooltip.html
                >
                  <feather-icon icon="AlertCircleIcon" size="14" />
                </b-button>
            </span>
            <span v-else>{{props.column.label}}</span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'bookingId'" class="text-nowrap">
                <span @click="$root.$emit('cancelSessionRequestTutorStatus',props.row.userStatus,props.row.warning,Number(getsettingList.userMaxWarningsToGetSuspend))">
                  {{ props.formattedRow[props.column.field] }}
                </span>
            </span>
            <span
              v-else-if="props.column.field === 'tutorType'"
              class="tutor_type"
            >
              <b-badge :variant="tutorType[1][props.row.tutorType]">
                {{ tutorType[0][props.row.tutorType] }}
              </b-badge>
            </span>
            <span
              v-else-if="props.column.field === 'learnerChildName'"
              class="text-nowrap"
            >
              <b-avatar :src="props.row.avatarForLearner" class="user_img" />
              <router-link
              :to="{ name: 'user-details', params: { id: props.row.bookinglernerId}}"
                class="text-nowrap"
                >{{ props.row.learnerChildName }}</router-link
              >
            </span>
            <span
              v-else-if="props.column.field === 'tutorNameDetail'"
              class="text-nowrap"
            >
              <b-avatar :src="props.row.avatarForTutor" class="user_img" />
              <router-link
              :to="`/user-management/users/${props.row.bookingTutor}`"
                class="text-nowrap"
                >{{ props.row.tutorNameDetail }}</router-link
              >
            </span>
            <span v-else-if="props.column.field === 'status'" class="status">
              <b-badge :variant="status[1][props.row.status]">
                {{ status[0][props.row.status] }}
              </b-badge>
            </span>
            <div
                v-else-if="props.column.field === 'cancellationReasonText'"
                class="wrap-break-word"
                v-show="true"
                >
                <span v-html="limitTo(props.row.cancellationReasonText)"></span>
                <a
                    class="readmore cancelReadMore"
                    v-b-modal="'readmore' + props.row.Id"
                    v-show="!props.row['isEditable'] && props.row.cancellationReasonText.length > 50"
                    
                    >Read more</a
                >
                <UserModal title="Cancel Reason" :text="props.row.cancellationReasonText" :id="props.row.Id" />
            </div>
            <span
              v-else-if="props.column.field === 'action'"
              class="action_field action_field1513"
            >
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  v-if="props.row.userStatus !== 2"
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item v-b-modal.modal-approve @click="selectedAction = props.row.Id,selectedTutorID = props.row.bookingTutor,tuturStatus = props.row.tutorStatus">
                    <span>
                      <feather-icon icon="CheckSquareIcon" size="20" />
                      Approve
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item v-b-modal.modal-suspend @click="selectedAction = props.row.Id,selectedTutorID = props.row.bookingTutor">
                    <span>
                      <feather-icon icon="XCircleIcon" size="20" />
                      Suspend Tutor</span
                    >
                  </b-dropdown-item >
                  <b-dropdown-item v-b-modal.modal-warning @click="selectedAction = props.row.Id,selectedTutorID = props.row.bookingTutor,warningOfTutor = props.row.warning" v-if="props.row.warning < Number(getsettingList.userMaxWarningsToGetSuspend)">
                    <span>
                      <feather-icon icon="AlertTriangleIcon" size="20" />
                      Give Warning</span
                    >
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>
            <span
              v-else-if="props.column.field === 'totalNoOfWarning'"
              class="cancel_reason"
            >
            {{props.row.warning?props.row.warning:0}}
            </span>
            <span v-else-if="props.column.field === 'sessionTitle'" class="cancel_reason">
              <span v-if='props.row.sessionTitle !== null && props.row.sessionTitle !== undefined && props.row.sessionTitle !== ""'>
                  <span v-if="props.row.sessionTitle.length >= 90 &&props.row.descToggled == null" v-html="limitTo(props.row.sessionTitle)"></span>
                  <a
                  class="readmore"
                  @click="
                      props.row.descToggled = Number(props.row.originalIndex + 1)
                  "
                  v-show="
                      !props.row['isEditable'] &&
                      props.row.sessionTitle.length >= 90 &&
                      props.row.descToggled == null
                  "
                  >Read more</a
                  >
                  <span
                  v-if="
                      props.row.sessionTitle.length < 90 ||
                      props.row.descToggled == Number(props.row.originalIndex + 1)
                  "
                  v-html="props.row.sessionTitle"
                  ></span>
                  <a
                  class="readmore"
                  @click="props.row.descToggled = null"
                  v-if="
                      props.row.descToggled == Number(props.row.originalIndex + 1)
                  "
                  >Read less</a
                  >
              </span>
              <span v-else>N/A</span>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> 
                  <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to
                  <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                  <span v-else>{{props.total}} of {{ props.total }} entries</span>
                </span>

                    <div class="d-flex align-items-center mb-1 mt-0 showentry allsession">
                      <span>Show</span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10', '20', '30', '40', '50']"
                        class="mx-1"
                        @input="
                          (value) => props.perPageChanged({ currentPerPage: value })
                        "
                     />
                      <span>entries</span>
                    </div>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :current-page="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => {
                      props.pageChanged({ currentPage: value });
                      currentPage = value
                    }
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
    <b-modal
      id="modal-approve"
      centered
      title=""
      ok-title="Yes"
      cancel-title="No"
      @ok="handlerForApprovation"
    >
      <img
        src="@/assets/images/tutorimages/approve.png"
        alt="profile"
        class="approve_image"
      />
      <h4>Approve</h4>
      <b-card-text class="approve-body12"> Are you sure you want to Approve? </b-card-text>
    </b-modal>
    <b-modal
      id="modal-warning"
      centered
      title=""
      ok-title="Yes"
      cancel-title="No"
      @ok="handlerForGiveWarning"
    >
      <img
        src="@/assets/images/tutorimages/warning.png"
        alt="profile"
        class="warning_image"
      />
      <h4>Give Warning</h4>
      <b-card-text class="approve-body12">
        Are you sure you want to Give Warning? 
        <span v-if="warningOfTutor > 0">You had already given {{warningOfTutor}} warning
        before.</span>
      </b-card-text>
    </b-modal>
     <b-modal
      id="modal-suspend"
      centered
      title=""
      ok-title="Yes"
      cancel-title="No"
      @ok="handlerForSuspendTutor"
    >
      <img
        src="@/assets/images/erflog/cancel.png"
        alt="profile"
        class="warning_image"
      />
      <h4>Suspend User</h4>
      <b-card-text class="approve-body12 suspendLine">
        Are you sure you want to Suspend User? This will cancel all future tutor session and give students their credits back.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
  BSpinner,
  VBTooltip,
  BCardText
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import { mapGetters , mapActions ,mapMutations} from "vuex";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import Ripple from "vue-ripple-directive";
import UserModal from "@/@core/components/modals/ReadMoreModal.vue";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "@/views/SessionsManagement/CanceledSession(s)/cancellationRequestDetail.vue"
import { dbCollections } from '@/utils/firebaseCollection';
import firebase from "@/utils/firebaseInit"
import functionForSuspendtionAction from '@/utils/globalFunction/suspendActionFun.js';
import functionForSuspendOrBlockChild from '@/utils/globalFunction/suspendChildFunction'
import functionForGiveWarning from '@/utils/globalFunction/giveWarningFun.js';
import {sendNotificationUserStatus} from '../../service.js'
import {onWarningGiveNotification} from '@/views/ComplaintManagement/serviceWarningNotification.js'
export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BButton,
    BSpinner,
    VBTooltip,
    BCardText,
    UserModal
  },
  data() {
    return {
      direction: false,
      isSpinner: false,
      isUpdating: false,
      isButton: false,
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      user_type_selected: "All",
      tutor_type_selected: "All",
      status_selected: "All",
      Total_no_of_Warning: null,
      Total_no_of_Warning: [
        { value: 0, text: "noraml" },
        { value: 1, text: "normal" },
        { value: 2, text: "danger" },
        { value: 3, text: "normal" },
      ],

      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Booking ID",
          field: "bookingId",
        },
        {
          label: "Session(s) Title",
          field: "sessionTitle",
        },
        {
          label: "Learner Name",
          field: "learnerChildName",
        },
        {
          label: "Tutor Name",
          field: "tutorNameDetail",
        },
        {
          label: "Warning",
          field: "totalNoOfWarning",
          thClass: 'custom-th-class',
          tooltip: 'A simple tooltip'
        },
        {
          label: "Cancellation Reason",
          field: "cancellationReasonText",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      searchTerm: "",
      status: [
        {
          1: "Current",
          2: "Active",
          3: "Block",
          4: "Suspend",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      tutorType: [
        {
          1: "Valuable",
          2: "Elite",
          3: "Normal",
          4: "N/A",
        },
        {
          1: "light-primary",
          2: "light-danger",
          3: "light-success",
          4: "light-dark",
        },
      ],
      totalNoOfWarning: [
        {
          1: "Valuable",
          2: "Elite",
          3: "Normal",
          4: "N/A",
        },
        {
          1: "light-primary",
          2: "light-danger",
          3: "light-success",
          4: "light-dark",
        },
      ],
      selectedAction:'',
      selectedTutorID: '',
      warningOfTutor: '',
      tuturStatus:Number,
      rowsArray:[],
      isSuspendAction: false,
      tutorStatus: Number
    };
  },
  computed: {
    ...mapGetters({
      getBookinglist: "booking/getBooking",
      getCancelSessionList: "cancelSessionDetails/getCancelSession",
      getsettingList : 'settings/getsettingArrayFeild',
    }),
    user_type_options() {
      var learnerName = [];
      learnerName.push({ value: 0, text: "All" });
      for (var i = 0; i < this.rowsArray.length; i++) {
        var temp = false;
        for (var j = 0; j < learnerName.length; j++) {
          if (this.rowsArray[i].learnerChildName == learnerName[j].text) {
            temp = true;
          }
        }
        if (!temp) {
          var obj = {
            val: i + 1,
            text: this.rowsArray[i].learnerChildName,
          };
          learnerName.push(obj);
        }
      }
      return learnerName;
    },
    tutor_type_options(){
      var tutorName = [];
      tutorName.push({ value: 0, text: "All" });
      for (var i = 0; i < this.rowsArray.length; i++) {
        var temp = false;
        for (var j = 0; j < tutorName.length; j++) {
          if (this.rowsArray[i].tutorNameDetail == tutorName[j].text) {
            temp = true;
          }
        }
        if (!temp) {
          var obj = {
            val: i + 1,
            text: this.rowsArray[i].tutorNameDetail,
          };
          tutorName.push(obj);
        }
      }
      return tutorName;
    },
    status_options(){
      var tutorTypeName = [];
      tutorTypeName.push({ value: 0, text: "All" });
      for (var i = 0; i < this.rowsArray.length; i++) {
        var temp = false;
        for (var j = 0; j < tutorTypeName.length; j++) {
          if (this.rowsArray[i].tutorType == tutorTypeName[j].text) {
            temp = true;
          }
        }
        if (!temp) {
          var obj = {
            val: i + 1,
            text: this.rowsArray[i].tutorType,
          };
          tutorTypeName.push(obj);
        }
      }
      return tutorTypeName;
    },
    filterRows() {
      var self = this;
      var listA = this.rowsArray;
      if (self.user_type_selected == "All" && self.tutor_type_selected == "All" && self.status_selected=="All") {
        return listA;
      }
      if (self.user_type_selected != "All") {
        listA = listA.filter((data) => {
          return data.learnerChildName.toLowerCase().includes(self.user_type_selected.toLowerCase());
        });
      }
       if (self.tutor_type_selected != "All") {
        listA = listA.filter((dataTutor) => {
          return dataTutor.tutorNameDetail.toLowerCase().includes(self.tutor_type_selected.toLowerCase());
        });
      }
       if (self.status_selected != "All") {
        listA = listA.filter((dataTutorType) => {
          return dataTutorType.tutorType.toLowerCase().includes(self.status_selected.toLowerCase());
        });
      }
      return listA;
    },
  },
  created() {
    this.data()
    
  },
  methods: {
    onCellClick(data){
        if (data.column.field==="learnerChildName") {
            this.$router.push({name: 'user-details', params: { id: data.row.bookinglernerId}})
        } else if (data.column.field==="tutorNameDetail") {
            this.$router.push({path: `/user-management/users/${data.row.bookingTutor}`})
        }
        else if (data.column.field==="cancellationReasonText") {
          if (data.row.cancellationReasonText.length < 50) {
            this.$root.$emit('cancelSessionRequestTutorStatus',data.row.userStatus,data.row.warning,Number(this.getsettingList.userMaxWarningsToGetSuspend))
            this.$router.push({ name: 'cancel-session-request-details', params: { id: data.row.Id}})
          } else {
            return;
          }
        }
        else if (data.column.field ==="action") {
          return;
        }
        else {
          this.$root.$emit('cancelSessionRequestTutorStatus',data.row.userStatus,data.row.warning,Number(this.getsettingList.userMaxWarningsToGetSuspend))
          this.$router.push({ name: 'cancel-session-request-details', params: { id: data.row.Id}})
        }
    },
    ...mapActions({
      getCancelSessionDatas: "cancelSessionDetails/getCancelSessionData"
    }),
    ...mapMutations({
      'testMutation' : "cancelSessionDetails/testMutation",
      'cancelSessionTest' : "cancelSessionDetails/cancelSessionTest",
      'cancelSessionArrayEmpty' : "cancelSessionDetails/setCancelSessionRequestArray"
    }),
    rows() {
      try {
        var self = this;
        this.isSpinner = true;
        if(this.getCancelSessionList.length === 0){
          this.isSpinner = false;
        }
        self.getCancelSessionList.forEach((items) => {
          getFirebase.simpleGetDataFromSubCollectionWithDocIDV2(dbCollections.USERS,items.tutor,(cancelDatas)=>{
            if (cancelDatas) {
              var object = {
                bookingId: items.bookingId,
                Id: items.id,
                warning : cancelDatas.data.warning ? cancelDatas.data.warning : 0,
                userStatus : cancelDatas.data.status,
                warningOfTutor : cancelDatas.data.warning,
                sessionTitle: items.sessionDetails ? items.sessionDetails.name : '',
                avatarForLearner: items.learnerDetails ? items.learnerDetails.profileImage : '',
                avatarForTutor: items.tutorDetails ? items.tutorDetails.profilePicture : '',
                learnerChildName:
                  (items.learnerDetails?items.learnerDetails.firstName : '') +
                  " " +
                  (items.learnerDetails ? items.learnerDetails.lastName : ''),
                tutorNameDetail:
                  (items.tutorDetails ? items.tutorDetails.firstName: '') + " " + (items.tutorDetails ? items.tutorDetails.lastName: ''),
                cancellationReasonText: items.cancellationReason,
                tutorType:items.tutorDetails ? items.tutorDetails.tutorType: '',
                bookingTutor: items.tutor,
                bookinglernerId: items.learnerId ? items.learnerId : ""
              };
              this.rowsArray.push(object);
              if(this.getCancelSessionList.length === this.rowsArray.length){
                this.isSpinner = false;
              }
            } else {
              this.isSpinner = false;
            }
          })
        });
      } catch (error) {
        console.error("erroe",error)
        this.isSpinner = false;
      }
    },
    data(){
      try {
        this.isSpinner = true;
        let cancelRecordFound = this.getCancelSessionList.length !== 0;
        if(!cancelRecordFound){
          this.getCancelSessionDatas().then(ress=>{
            this.$root.$on("calledAfterCancelActionPerform",(id,status)=>{
              let Indexss = this.getCancelSessionList.findIndex((values) => {
                return values.id == id;
              });
              this.getCancelSessionList[Indexss].cancelReasonStatus = Number(status);
              this.cancelSessionTest(this.getCancelSessionList[Indexss])
              this.testMutation(Indexss)
            })
            this.rows()
          }).catch(error=>{
            console.error("error",error)
            this.isSpinner = false;
          })
        }
        else{
          this.$root.$on("calledAfterCancelActionPerform",(id,status)=>{
            let Indexss = this.getCancelSessionList.findIndex((values) => {
              return values.id == id;
            });
            this.getCancelSessionList[Indexss].cancelReasonStatus = status;
            this.cancelSessionTest(this.getCancelSessionList[Indexss])
            this.testMutation(Indexss)
          })
          this.rows()
        }
      } catch (error) {
        this.isSpinner = false;
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "getCancelSessionDatas",
          errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
      }
    },
    handlerForApprovation(){
      this.isSuspendAction = false;
      try {
        this.isSpinner = true;
        let Index = this.rowsArray.findIndex((values) => {
          return values.Id == this.selectedAction;
        });
        let Indexs = this.getCancelSessionList.findIndex((values) => {
          return values.id == this.selectedAction;
        });
        const actionreasondetail = {
          cancelReasonStatus: Number(1),
          updatedAt: new Date()
        };
        updateFirebase.updateRootCollectionDataByDocId(
          dbCollections.BOOKING,
          this.selectedAction,
          actionreasondetail,
          (res) => {
            this.rowsArray.splice(Index,1);
            this.getCancelSessionList.splice(Indexs,1);
            let newArray = [];
            this.cancelSessionArrayEmpty(newArray);
            this.selectedAction = '';
            this.selectedTutorID = '';
            this.$root.$emit(
              "showToastMessage",
              "Tutor reason approved successfully.",
              "success"
            );
            this.isSpinner = false;
          }
        );
      } catch (error) {
        console.error("error",error)
        this.isSpinner = false;
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "handlerForApprovation",
          errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
      }
      
    },
    handlerForGiveWarning(){
      this.isSuspendAction = false;
      this.isSpinner = true
      try {
        let Index = this.rowsArray.findIndex((values) => {
          return values.Id == this.selectedAction;
        });
        let Indexs = this.getCancelSessionList.findIndex((values) => {
          return values.id == this.selectedAction;
        });
        const actionreasondetail = {
          cancelReasonStatus: Number(3),
          updatedAt: new Date()
        };
        updateFirebase.updateRootCollectionDataByDocId(
          dbCollections.BOOKING,
          this.selectedAction,
          actionreasondetail,
          () => {
            let newArray = [];
            this.cancelSessionArrayEmpty(newArray);
            getFirebase.simpleGetDataFromSubCollectionWithDocIDV2(dbCollections.USERS,this.selectedTutorID,(cancelDatas)=>{
              let warnning = cancelDatas.data.warning?cancelDatas.data.warning:0
              const actionreasondetai2 = {
                warning: warnning+1,
              };
              updateFirebase.updateRootCollectionDataByDocId(
                dbCollections.USERS,
              this.selectedTutorID,
              actionreasondetai2,
                () => {
                  functionForGiveWarning(this.selectedTutorID,"booking",this.getsettingList.warningResetDays).catch(error=>{
                    console.error("error",error)
                  })
                   onWarningGiveNotification({
                    tutorId :  this.selectedTutorID
                  }, () => {});
                  this.rowsArray.splice(Index,1);
                  this.getCancelSessionList.splice(Indexs,1);
                  for(let i = 0;i<this.rowsArray.length;i++){
                    if(this.rowsArray[i].bookingTutor === this.selectedTutorID){
                      this.rowsArray[i].warning++; 
                    }
                  }
                  this.selectedAction = ''
                  this.selectedTutorID = ''
                  this.$root.$emit(
                    "showToastMessage",
                    "You gave warning to the tutor.",
                    "success"
                  );
                  this.isSpinner = false
                })
            })         
          }
        );
      } catch (error) {
        console.error("error",error)
        this.isSpinner = false
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "handlerForGiveWarning",
          errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
      }
      
    },
    handlerForSuspendTutor(){
      this.isSuspendAction = true;
      this.isSpinner = true;
      var self = this;
      try {
        let Index = this.rowsArray.findIndex((values) => {
          return values.Id == this.selectedAction;
        });
        let Indexs = this.getCancelSessionList.findIndex((values) => {
          return values.id == this.selectedAction;
        });
        const actionreasondetail = {
          cancelReasonStatus: Number(2),
          updatedAt: new Date()
        };
        this.rowsArray.splice(Index,1);
        this.getCancelSessionList.splice(Indexs,1);
        this.getCancelSessionList[Indexs].cancelReasonStatus = Number(2);
        updateFirebase.updateRootCollectionDataByDocId(dbCollections.BOOKING,this.selectedAction,actionreasondetail,async (resBooking) => {
            const actionreasondetai2 = {
              status: Number(2),
              updatedAt : new Date(),
              suspendUserTime: new Date()
            };
            await updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,this.selectedTutorID,actionreasondetai2, async(resUser) => {
                functionForSuspendtionAction(this.selectedTutorID).then(()=>{
                functionForSuspendOrBlockChild(this.selectedTutorID,true,false,()=>{})
                  for(let i = 0;i<this.rowsArray.length;i++){
                    if(this.rowsArray[i].bookingTutor === this.selectedTutorID){
                      this.rowsArray[i].userStatus = Number(2)
                    }else{
                      this.rowsArray[i].userStatus = Number(1)
                    }
                  }
                  let newArray = [];
                  this.cancelSessionArrayEmpty(newArray);
                  sendNotificationUserStatus({
                      uid: this.selectedTutorID,
                      userStatus: "Suspend"
                  },() => {})
                  self.$root.$emit(
                    "showToastMessage",
                    "Tutor suspended successfully.",
                    "success"
                  );
                  this.isSpinner = false
                  this.isSuspendAction = false;
                })
                .catch((err)=>{
                  console.error("in cancel session booking",err)
                  this.isSpinner = false
                })
            });
          }
        );
      } catch (error) {
        console.error("error",error)
        this.isSpinner = false
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "handlerForSuspendTutor",
          errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
      }
      
    },
    limitTo: function (value) {
      if(value){
        if (value.length < 50){
          return value
        }else{
          return value.substring(0, 50) + "...";
        }
      }else{
        return "-";
      }
    },
    getTutorTypeBadgeVariant(value){
      var variant = "light-";
      if(value == 0)
      {
        variant += 'success';
      }
      else if(value == 1)
      {
        variant += 'danger';
      }
      else if(value == 2)
      {
        variant += 'primary';
      }
      else{
        variant += 'warning'
      }
      return variant;
    },
  },
};
</script>

<style scoped>
  .upcoming_session_table button.btn.warningInfo.btn-outline-primary {
    margin: 0;
    padding: 0px!important;
    left: -7px;
    width: auto;
    border: 0px!important;
    height: 24px!important;
    width: 24px!important;
    min-height: auto!important;
    position: relative;
    top: -5px;
    box-shadow: none!important;
}
  .cancelReadMore{
    color: #0096DB !important;
    text-decoration: none !important;
  }

 .textcenter h2 {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 200px;
}
.suspendLine{
  line-height: 26.64px;
}
</style>