<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />

      <b-card class="user_detail_top_section request_detail_top_section">
        <h4>Dynamic Arrays and Amortized Analysis</h4>
        <b-row v-for="(item, index) in requestitems" :key="index" class="fontchanges">
          <b-col xl="3" lg="3" md="3">
            <h5>Booking ID</h5>
            <p>{{ item.bookingid }}</p>
            <h5>Category</h5>
            <p>
              <router-link to="/#" class="text-decoration-underline">{{
                item.category
              }}</router-link>
            </p>
            <h5>Tutor Name</h5>
            <span class="text-nowrap">
              <b-avatar :src="item.avatar" class="user_img" />
              <router-link to="/#" class="text-decoration-underline">{{
                item.fullName
              }}</router-link>
            </span>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Session Booking Date & Time</h5>
            <p>{{ item.sessionbookingdt }}</p>
            <h5>Tutor Type</h5>
            <span class="tutor_type">
              <b-badge :variant="tutorType[1][item.tutorType]">
                {{ tutorType[0][item.tutorType] }}
              </b-badge>
            </span>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Booked Date & Time</h5>
            <p>{{ item.bookeddt }}</p>
            <h5>Teaching Language</h5>
            <p>{{ item.teachinglanguage }}</p>
            <h5>Learner Name</h5>
            <span class="text-nowrap">
              <b-avatar :src="item.cavatar" class="user_img" />
              <router-link to="/#" class="text-decoration-underline">{{
                item.childname
              }}</router-link>
            </span>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Booking Credits</h5>
            <p class="text-success">9 Credits</p>
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col md="6">
            <h5>Description</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </p>
          </b-col>
          <b-col md="6">
            <h5>Search Tag(s)</h5>
            <p>
              <b-badge class="btn mr-2 mb-1 btn-outline-secondary">
                Object Oriented
              </b-badge>
              <b-badge class="btn mr-2 mb-1 btn-outline-secondary">
                Polymorphism
              </b-badge>
              <b-badge class="btn mr-2 mb-1 btn-outline-secondary">
                Introduction Object Oriented
              </b-badge>
              <b-badge class="btn mr-2 btn-outline-secondary">
                Encapsulation
              </b-badge>
            </p>
          </b-col>
        </b-row>
      </b-card>

      <b-row cols="pass_session_bottom_block">
        <b-col md="6">
          <b-card>
            <h2>Notes</h2>
            <hr class="title_hr">
            <ul class="notes_list pl-0 mb-0">
              <li>Lorem ipsum dolor sit amet, consectetur adipiscing.</li>
                <li>Nam sagittis lorem ac neque euismod, amet dictumex luctus.</li>
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing.</li>
                <li>Nam sagittis lorem ac neque euismod, amet dictum ex luctus.</li>               
            </ul>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card>
            <h2>Study Material</h2>
            <hr class="title_hr">
            <p class="d-flex justify-content-between align-items-center">
              <span>Data Structures Sessions.pdf</span>
            <span class="downloadresume">
                    <router-link to="#" class="mr-1">
                      <feather-icon icon="EyeIcon" size="18" /></router-link
                    >
                    <router-link to="#" download>
                      <feather-icon
                        icon="ArrowDownCircleIcon"
                        size="18"
                      /></router-link
                    >
            </span>
                  </p>
            <hr>
            <p class="d-flex justify-content-between align-items-center">
              <span>Data Structures Sessions.pdf</span>
            <span class="downloadresume">
                    <router-link to="#" class="mr-1">
                      <feather-icon icon="EyeIcon" size="18" /></router-link
                    >
                    <router-link to="#" download>
                      <feather-icon
                        icon="ArrowDownCircleIcon"
                        size="18"
                      /></router-link
                    >
            </span>
                  </p>
                  <hr>
                  <p></p>

          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BAvatar, BBadge, BButton } from "bootstrap-vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BButton,
  },
  data() {
    return {
      isSpinner:false,
      isUpdating:false,
      requestitems: [
        {
          bookingid: "221231ABC336",
          sessionbookingdt: "29-12-2021 , 05:15 PM to 06:45 PM",
          bookeddt: "27-12-2021 , 05:15 PM",
          bookingcredits: "9 Credits",
          avatar: require("@/assets/images/avatars/9.png"),
          fullName: "Angela Mayer",
          tutorType: 1,
          category: "Computer Programming",
          teachinglanguage: "English",
          cavatar: require("@/assets/images/avatars/10.png"),
          childname: "Janice Coulter",
        },
      ],
      tutorType: [
        {
          1: "Valuable",
          2: "Elite",
          3: "Normal",
          4: "N/A",
        },
        {
          1: "light-primary",
          2: "light-danger",
          3: "light-success",
          4: "light-dark",
        },
      ],
    };
  },
};
</script>
