<template>
  <b-card no-body>
    <b-card-header>
      <div>
      </div>
    </b-card-header>
    <b-card-body>
      <chartjs-component-line-chart
        :height="400"
        :data="revanueChartData"
        :options="chartOptionss"
        :plugins="plugins"
        :isChange="isChange"
        :changeDone="changeDone"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,
} from 'bootstrap-vue'
import ChartjsComponentLineChart from './ChartjsComponentLineChart.vue'
import chartjsData from './chartjsData'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    ChartjsComponentLineChart,
  },
  props:{
    'chartName': String,
    chartData: Object,
    isChange: Boolean,
    chartOption: Object,
    changeDone: Function
  },
  computed: {
    revanueChartData(){
      if(this.chartData) {
        return this.chartData
      } else {
        return {}
      }
    },
    chartOptionss(){
      if(this.chartOption) {
        return this.chartOption
      } else {
        return {}
      }
    }
  },
  data() {
    return {
      chartjsData,
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
}
</script>
