<template>
<div  class="termsAndConditionBody">
       <div class = "loading" :style="`display: ${isSpinner ? 'flex' : 'none'};`" v-if="isSpinner">
        <div class="effect-1 effects"></div>
        <div class="effect-2 effects"></div>
        <div class="effect-3 effects"></div>
    </div>
  <div v-html="privacypolicy" v-if="!isSpinner"></div>
</div>

</template>

<script>
const db = firebase.firestore();
import firebase from "@/utils/firebaseInit.js";
import { dbCollections } from "@/utils/firebaseCollection.js";
import {BSpinner} from "bootstrap-vue"
export default {
name: "termsAndConditionDisplay",
data() {
    return {
        privacypolicy : "",
        isSpinner: true,
    }
},
components: {
    BSpinner
},
created() {
    db.collection(dbCollections.SETTINGS).doc("setting").collection(dbCollections.PRIVACY).doc("privacypolicy").get().then((tac)=>{
        this.isSpinner = false;
        this.privacypolicy = tac.data().termsAndCondition
    }).catch((error)=>{
        this.isSpinner = false;
        console.error(error,"error")
    })
}
}
</script>
<style>
.termsAndConditionBody { margin: 20px 20px;}
.termsAndConditionBody ul { margin: 0 10px 20px;}
.termsAndConditionBody ol { margin: 0 10px 10px 15px;}
.ql-align-justify {
    text-align: justify;
}
.ql-align-center {
    text-align: center;
}
.ql-align-right {
    text-align: right;
}
</style>