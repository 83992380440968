<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isProcessed"
        large
        class="pageLoadSpinners loaderDetail"
        style="position: fixed"
      />
    

    <div class="card user_filter all_session">
      <h2>Filters</h2>
     <b-form @submit.prevent>
        <b-row>
          <b-col xl="3" lg="3" md="3">         
            <label>Learner Name</label>
            <b-dropdown
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :text="learner_selected"
              variant="outline-secondary"
              class="w-100"
            >
              <b-dropdown-item
                v-for="item in learner_name_options"
                :key="item.value"
                @click="learner_selected = item.text"
                >{{ item.text }}</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <label>Tutor Name</label>
            <b-dropdown
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :text="byTutorFiltered"
              variant="outline-secondary"
              class="w-100"
            >
              <b-dropdown-item
                v-for="item in tutor_name_options"
                :key="item.value"
                @click="byTutorFiltered = item.text"
                >{{ item.text }}</b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-form>
    </div>

    <div class="card user_table request_session_table review_management_table">
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="reviewmanagementsearchTerm"
              placeholder="Search..."
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <vue-good-table
        :columns="reviewmanagementcolumns"
        :rows="filteredData"
        :rtl="true"
        class="cursor_pointer"
        @on-cell-click="onRowClick"
        :search-options="{
          enabled: true,
          externalQuery: reviewmanagementsearchTerm,
        }"
        :select-options="{
          enabled: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >

        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'bookingId'" class="text-nowrap">
            <span class="userid">
              <span class="text-nowrap">
                {{ props.row.bookingId }}
              </span>  
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'sessionDate'"
            class="text-nowrap"
          >
            {{sessionTimeCalculate(props.row.sessionBookingDate, props.row.sessionEndDate)}}
          </span>
          <span
            v-else-if="props.column.field === 'sessionName'"
            class="tutor_type"
          >         
            <span v-if='props.row.sessionName !== null && props.row.sessionName !== undefined && props.row.sessionName !== ""'>
                <span v-if="props.row.sessionName.length >= 90 &&props.row.descToggled == null" v-html="limitTo(props.row.sessionName)"></span>
                <a
                class="readmore"
                @click="
                    props.row.descToggled = Number(props.row.originalIndex + 1)
                "
                v-show="
                    !props.row['isEditable'] &&
                    props.row.sessionName.length >= 90 &&
                    props.row.descToggled == null
                "
                >Read more</a
                >
                <span
                v-if="
                    props.row.sessionName.length < 90 ||
                    props.row.descToggled == Number(props.row.originalIndex + 1)
                "
                v-html="props.row.sessionName"
                ></span>
                <a
                class="readmore"
                @click="props.row.descToggled = null"
                v-if="
                    props.row.descToggled == Number(props.row.originalIndex + 1)
                "
                >Read less</a
                >
            </span>
           <span v-else>N/A</span>
          </span>
          <span
            v-else-if="props.column.field === 'tutorFullName'"
            class="tutor_type"
          >     
           <b-avatar
              :src="props.row.tutorProfile"
              alt="profile image"
              class="user_img"
            />   
            <router-link v-if="props.row.tutor" :to="{ name: 'user-details', params: { id: props.row.tutor}}" class="text-nowrap">
              <a>{{ props.row.tutorFullName }}</a>
            </router-link>
          </span>
          <span
            v-else-if="props.column.field === 'learnerFullName'"
            class="tutor_type"
          > 
          <b-avatar
              :src="props.row.learnerProfile"
              alt="profile image"
              class="user_img"
          />   
          <router-link v-if="props.row.learnerId" :to="{ name: 'user-details', params: { id: props.row.learnerId}}" class="text-nowrap">
           <a>{{ props.row.learnerFullName }}</a>
          </router-link>
          </span>
          <span
            v-else-if="props.column.field === 'totalStars'"
            v-bind:class="props.row.totalStars !== '' ? 'rating pastsessionlist' : 'hide'"
          >
            <b-img
              v-if="props.row.totalStars !== '' && props.row.totalStars !== 0"
              src="@/assets/images/raty/star-on-2.png"
              class="star_img"
            />
            {{ props.row.totalStars }}
          </span>
          <div
            v-else-if="props.column.field === 'usedCredits'"
            class="wrap-break-word"           
          >
          <span v-if="props.row.usedCredits!==0">
          {{props.row.usedCredits}} Credits
          </span>
          <span v-else style="color:red">
            Trial Booking
          </span>
          </div>        
        </template>
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> 
                  <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to
                  <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                  <span v-else>{{props.total}} of {{ props.total }} entries</span>
                </span>

                    <div class="d-flex align-items-center mb-1 mt-0 showentry">
                      <span>Show</span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10', '20', '30', '40', '50']"
                        class="mx-1"
                        @input="
                          (value) => props.perPageChanged({ currentPerPage: value })
                        "
                     />
                      <span>entries</span>
                    </div>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :current-page="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => {
                      props.pageChanged({ currentPage: value });
                      currentPage = value
                    }
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
        </template>
      </vue-good-table>
    </div>    
  </div>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
  BSpinner
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "@/views/SessionsManagement/pastSession(s)/pastSessionList.vue"
import { mapActions, mapGetters } from "vuex";
import moment from 'moment';
export default {
  directives: {
        Ripple
  },
  components: {
    VueGoodTable,
    BCard,
    BImg,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BSpinner
  },
  data() {
    return {
      isSpinner: false,
      isUpdating: false,
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      currentPage: 1,    
      pageLength: 10,
      reviewmanagementsearchTerm: "",
      reviewmanagementcolumns: [
        {
          label: "Booking ID",
          field: "bookingId",
          width: '170px'
        },
        {
          label: "Session Booking Date & Time",
          field: "sessionDate",
        },
        {
          label: "Session(s) Title",
          field: "sessionName",
          width: '200px'
        },
        {
          label: "Tutor Name",
          field: "tutorFullName",
          width: '190px'
        },
        {
          label: "Learner Name",
          field: "learnerFullName",
          width: '190px'
        },
        {
          label: "Rating",
          field: "totalStars",
        },
        {
          label: "Booking Credits",
          field: "usedCredits",
        },
      ],
      reviewmanagementsrows: [],    
      readMore: false,
      tutor_name_options: [{ value: null, text: "All" }],
      learner_name_options: [{ value: null, text: "All" }], 
      selectedUser: "",
      byTutorFiltered: "All",
      learner_selected: "All",
      finalPastBookingData:[],
      isProcessed:false
    };
  },
  created(){
    this.isSpinner = true
    let getPastSessionFound = this.getPastSessionList.length != 0;
    if(!getPastSessionFound){
      this.getPastSession().then(res=>{
        this.isSpinner = false;
      }).catch(error=>{
        this.isSpinner = false;
        console.error("error",error)
      })
    }else{
      this.isSpinner = false;
    }
  },
  mounted(){
  },
  computed: {
    ...mapGetters({ users: "userManagement/getUsers",getPastSessionList: 'pastSessionManagement/getPastSession'}),
    filteredData() {     
      this.getBookingData.forEach((item) => {       
        if (this.users.length > 0) {
          let sIndex = this.users.findIndex((values) => {
            return values.id == item.tutor;
          });
          if (sIndex > -1) {  
            item.userRole  = this.users[sIndex].userRole;   
            item.tutorProfile = this.users[sIndex].profilePicture;     
            if (this.tutor_name_options.length > 0) {
              let getInd = this.tutor_name_options.findIndex((elem) => {
                return elem.value == this.users[sIndex].id;
              });
              if (getInd <= -1) {
                this.tutor_name_options.push({
                  value: this.users[sIndex].id ? this.users[sIndex].id : "",
                  text: this.users[sIndex].firstName + " " + this.users[sIndex].lastName,
                });
              }
            }           
          }
          let learnerIndex = this.users.findIndex((values) => {
            return values.id == item.learnerId ? item.learnerId : "";
          });
          if (learnerIndex > -1) {     
            item.userRole  = this.users[learnerIndex].userRole;      
            item.learnerProfile = this.users[learnerIndex].profilePicture; 
            if (this.learner_name_options.length > 0) {
              let getInd = this.learner_name_options.findIndex((elem) => {
                return elem.value == this.users[learnerIndex].id;
              });
              if (getInd <= -1) {
                this.learner_name_options.push({
                  value: this.users[learnerIndex].id? this.users[learnerIndex].id: "",
                  text:  this.users[learnerIndex].firstName + " " + this.users[learnerIndex].lastName,
                });
              }
            }
          }
        }
      });     
      let temp = this.getBookingData;      
      if (this.learner_selected != "All") {
        temp = this.filterByLearnerSelected(temp);
      }
      if (this.byTutorFiltered !== "All") {
        temp = this.filterByTutorSelected(temp);
      }
      this.reviewmanagementsrows = temp;
      return this.reviewmanagementsrows;
    },
    getBookingData(){
      let result = this.getPastSessionList;
      let response = [];
      this.finalPastBookingData = [];
        try{
          for(let count = 0; count <= result.length; count += 1){
            if(count >= result.length){
                return response            
              }else{
                let row = result[count];
                row.sessionName = row.sessionDetails?row.sessionDetails.name:'';
                row.learnerId = row.learnerId;
                row.descToggled = null;
                row.sessionDate = `${moment(new Date(row. endTime.seconds * 1000)).format('DD-MM-YYYY')}, ${moment(new Date(row. startTime.seconds * 1000)).format('HH:MMA')} to ${moment(new Date(row. endTime.seconds * 1000)).format('HH:MMA')}`;
                row.tutorFullName = row.tutorDetails.firstName + ' ' + row.tutorDetails.lastName;
                row.tutorProfile = row.tutorDetails ? row.tutorDetails.profilePicture:'';
                row.sessionBookingDate = row.startTime,
                row.sessionEndDate = row.endTime,
                row.learnerFullName = row.learnerDetails.firstName + ' ' + row.learnerDetails.lastName;
                row.learnerProfile = row.learnerDetails ? row.learnerDetails.profileImage:'';
                row.totalStars = row.tutorDetails ? row.tutorDetails.rating : '';  
                row.usedCredits = row.usedCredits ? row.usedCredits:0;
                row.tutor = row.tutor
                let sIndex = this.users.findIndex((values) => {
                  return values.id == row.tutor;
                });
                let learnerIndex = this.users.findIndex((values) => {
                  return values.id == row.learnerId ? row.learnerId : "";
                });
                if (sIndex > -1 && learnerIndex > -1) {
                  response.push(row)
                }
              }
          }   
        }catch(error){
          this.isSpinner = false
          console.error(error,"ERROR")
          errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "getBookingData",
            errorMessage : error.message
          },(mailRes) => {
            if(mailRes.error){
              console.error(mailRes);
            }
          })
        }
        return response
    }
  },
  methods: {
    ...mapActions({'getPastSession':'pastSessionManagement/getPastSessionData',}),
    onRowClick(data) {
      if (data.column.field === "action") {
          return false;
      } else if(data.column.field === "tutorFullName") {
         this.$router.push({ name: 'user-details', params: { id: data.row.tutor}})
      }else if(data.column.field === "learnerFullName"){
         this.$router.push({ name: 'user-details', params: { id: data.row.learnerId}})
      }else{    
         this.$router.push({ name: 'past-session-details', params: { id: data.row.id}})
      }
    },
    sessionTimeCalculate(startTime , endTime) {
      if (moment(startTime.seconds*1000).format("DD-MM-YYYY") == moment(endTime.seconds*1000).format("DD-MM-YYYY")) {
        return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("hh:mm A")}`
      } else {
        return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")}`
      }
    },
    filterByLearnerSelected(data) {
      return data.filter((user) => user.learnerFullName == this.learner_selected);
    },
    filterByTutorSelected(data) {
      return data.filter((user) => user.tutorFullName == this.byTutorFiltered);
    },     
    limitTo: function (value) {
      if (!value) return "";
      return value.substring(0, 90) + "...";
    },   
    TemplateCreatedDate(TemplateCreatedDate) {
      var self = this;
      var notificationDate = new Date(
        TemplateCreatedDate.seconds * 1000
      ).getDate();
      var notificationMonth =
        new Date(TemplateCreatedDate.seconds * 1000).getMonth() + 1;
      var notificationYear = new Date(
        TemplateCreatedDate.seconds * 1000
      ).getFullYear();
      var returnnotificationDate =
        self.addZero(notificationDate) +
        "-" +
        self.addZero(notificationMonth) +
        "-" +
        notificationYear +
        ", " +
        self.notificationCreateTime(
          new Date(TemplateCreatedDate.seconds * 1000)
        );
      return returnnotificationDate;
    },
    TemplateEndDate(TemplateCreatedDate) {
      var self = this;
      var returnnotificationDate = self.notificationCreateTime(
        new Date(TemplateCreatedDate.seconds * 1000)
      );
      return returnnotificationDate;
    },
    notificationCreateTime(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      hours = hours<10 ? '0'+hours:hours
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    addZero(value) {
      if (value > 9) {
        return value;
      } else {
        return "0" + value;
      }
    },     
  },
};
</script>

