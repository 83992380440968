<template>
 <b-tab title="All Transactions" @click="getTabData" >
  <div>
        <b-spinner
        variant="primary"
        v-if="isSpinner"
        large
        class="pageLoadSpinners"
        />
        <div v-show="!isSpinner">
        <b-spinner
            variant="primary"
            v-if="isUpdating"
            large
            class="pageLoadSpinners"
            style="position: fixed"
        />
        
       <div class="histroydropdown">
        <div class="card user_table cancel_session_table upcoming_session_table learner_histroy trasan_history">
            <div class="custom-search d-flex justify-content-end">
                <label class="filter_label">Filter by Transaction</label>
            <b-form @submit.prevent>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary borderclass"
                :text="transection"
              >
                <b-dropdown-item
                  v-for="item in transection_option"
                   @click="transection = item.text"
                  :key="item.value"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
              </b-form>
                <b-button
                  variant="outline-primary"
                  v-on:click="exportData"
                >
                  Export Excel
                </b-button>
            <b-form-group>
                <div class="d-flex align-items-center">
                <label class="mr-1"></label>
                <b-form-input
                    v-model="searchTerm"
                    placeholder="Search..."
                    type="text"
                    class="d-inline-block"
                />
                </div>
            </b-form-group>
            </div>
            <vue-good-table
            :columns="dynamicColumns"
            :rows="dataDisplay"
            :rtl="direction"
            :search-options="{
                enabled: true,
                externalQuery: searchTerm,
            }"
            :select-options="{
                enabled: true,
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
            }"
            :pagination-options="{
                enabled: true,
                perPage: pageLength,
            }"
            >
            <template slot="table-row" slot-scope="props">
        <span
          v-if="props.column.field === 'transactionid'"
          class="transact_id"
        >
          {{ props.formattedRow[props.column.field] }}
        </span>
        <span v-else-if="props.column.field === 'userName'" class="text-nowrap">
                <b-avatar :src="props.row.avatar" class="user_img" />                          
                <router-link :to="{ name: 'user-details', params: { id: props.row.userId ,name: props.row.userName}}" class="text-nowrap">{{ props.row.userName }}</router-link>
                </span>
        <span v-else-if="props.column.field === 'date'" class="date">                         
                {{ props.row.date }}
        </span>
                <span
                v-else-if="props.column.field === 'remarks'"
                class="remarks"
                >
                {{ props.formattedRow[props.column.field] }}
                </span>
                <span v-else-if="props.column.field === 'amount'" class="status" :style="props.row.transectionType == 'dr' ? 'color:#0D9E00':'color:#FF0000'">
                  {{props.row.amount}}
                </span>
                  <span v-else-if="props.column.field === 'availAmount'" class="status">
                  {{props.row.availAmount ? props.row.availAmount : 'N/A'}}
                </span>
                <span v-else>
                {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
              <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> 
                        <span v-if="props.total === 0"> Showing 0 </span>
                        <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                        to
                        <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                        <span v-else>{{props.total}} of {{ props.total }} entries</span>
                        </span>

                            <div class="d-flex align-items-center mb-1 mt-0 showentry subcategoryDetail">
                            <span>Show</span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['10', '20', '30', '40', '50']"
                                class="mx-1"
                                @input="
                                (value) => props.perPageChanged({ currentPerPage: value })
                                "
                            />
                            <span>entries</span>
                            </div>
                    </div>
                    <div>
                        <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :current-page="currentPage"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="
                            (value) => {
                            props.pageChanged({ currentPage: value });
                            currentPage = value
                            }
                        "
                        >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                        </b-pagination>
                    </div>
                    </div>
              </template>
            </vue-good-table>
        </div>
       </div>
      </div>
    <b-modal
      id="modal-approve"
      centered
      title=""
      ok-title="Yes"
      cancel-title="No"
    >
      <img src="@/assets/images/tutorimages/approve.png" alt="profile" class="approve_image">
      <h4>Approve</h4>
      <b-card-text>
       Are you sure you want to Approve?
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-warning"
      centered
      title=""
      ok-title="Yes"
      cancel-title="No"
    >
     <img src="@/assets/images/tutorimages/warning.png" alt="profile" class="warning_image">
      <h4>Give Warning</h4>
      <b-card-text>
        Are you sure you want to Give Warning?
        You had already given 1 warning before.
      </b-card-text>
    </b-modal>
  </div>
 </b-tab>
</template>

<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
  BTabs, 
  BTab,
  BCardText,
  BCard,
  BSpinner
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import {errorEmail} from "@/utils/apiRequests.js"
import { mapGetters,mapActions } from "vuex";

import * as getQuery from "@/utils/FirebaseQueries/getQueries/getQueries.js";
const currentFileName = "src/views/TransactionHistory/TransactionHistoryTabComponents/transactionHistoryTab.vue";
import { dbCollections } from "@/utils/firebaseCollection.js";
import moment from "moment";
import Ripple from 'vue-ripple-directive'
export default {
  directives: {
    Ripple,
  },
  name: "transactionHistoryTab",
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BButton,
    BTabs,
    BTab,
    BCardText,
    BCard,
    BSpinner
  },
  data() {
    return {
      direction:false,
      isSpinner:false,
      isUpdating:false, 
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      user_type_selected: null,
      transection_option: [
        { value: null, text: "All"},
        { value: "a", text: "Purchase" },
        { value: "b", text: "Withdraw" },
      ],
      transection: "All",
      tutor_type_selected: null,
      tutor_type_options: [
        { value: null, text: "All" },
        { value: "a", text: "Valuable" },
        { value: "b", text: "Normal" },
        { value: "c", text: "Elite" },
      ],
      status_selected: null,
      status_options: [
        { value: null, text: "All" },
        { value: "a", text: "Active" },
        { value: "b", text: "Suspend" },
        { value: "c", text: "Block" },
      ],
      Total_no_of_Warning: null,
      Total_no_of_Warning: [
        { value: 0, text: "noraml" },
        { value: 1, text: "normal" },
        { value: 2, text: "danger" },
        { value: 3, text: "normal" },
      ],

      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Date",
          field: "date",
        },
        {
          label: "Transaction ID",
          field: "transactionid",
        },
        {
          label: "User Name",
          field: "userName",
        },
       {
          label: "Remarks",
          field: "remarks",
        },
        {
          label: "Amount($)",
          field: "amount",
        },
        {
          label: "Avail. Amount($)",
          field: "availAmount",
        },
       
      ],

      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters({
          getsettingList : 'settings/getsettingArrayFeild',
          getHistoryAllTransectionData: 'RevanueCalculation/getallTransectionHistory'
      }),
    currency(){
        if (Object.keys(this.getsettingList).length != 0) {
            return this.getsettingList.currency.slice(4)
        } else {
            return ""
        }
    },
    dataDisplay(){
      let arrayShow = []
      if (this.transection==="Purchase") {
        this.getHistoryAllTransectionData.forEach((data)=>{
          if (data.transectionType === "dr") {
            arrayShow.push((data))
          }
        })
      } else if (this.transection==="Withdraw") {
        this.getHistoryAllTransectionData.forEach((data)=>{
          if (data.transectionType === "cr") {
            arrayShow.push((data))
          }
        })
      } else {
        arrayShow = this.getHistoryAllTransectionData
      }
      return arrayShow
    },
    dynamicColumns(){
        this.columns[4].label = `Amount(${this.currency})`
        this.columns[5].label = `Avail. Amount(${this.currency})`	
        return this.columns
    },
  },
  created() {
  },
  methods: {
    ...mapActions({
      'getAllTransectionss': 'RevanueCalculation/getAllTransection'
    }),
    getTabData(){
           try{ 
                var allTabList = document.getElementsByClassName('nav-link active')
                if (allTabList[0].innerText !== 'All Transactions'  || this.getTutorHistory == true) { 
                    this.getTransection();
                }
            }catch (error) {
                errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "getTabData",
                errorMessage : error.message
                },(res)=>{
                if(res.error){
                    console.error(res.error)
                }
                })
                console.error(error)
            }
    },
    getTransection(){
      let transecionData = this.getHistoryAllTransectionData.length != 0;
      if (!transecionData) {
          this.isSpinner = true;
          this.getAllTransectionss().then((resp)=>{
              this.isSpinner = false;
          }).catch((error)=>{
              this.isSpinner = false;
              console.error("error",error)
          })
      }
    },
    exportData(){
        try{
            var self = this;
            var CSV = '';
            var row = "";
            let keys = "date,transactionid,userName,remarks,amount,availAmount".split(',')
            row+='Date,Transaction ID,User Name,Remarks,Amount($),Avail. Amount($)\n'
            self.dataDisplay.forEach((data2)=>{
                row+=""
                keys.forEach(key=>{
                row+=data2[key] ? `"${data2[key]}"` : " "
                row+=",";
                })
                row+="\n"
            })
            row.slice(0, row.length - 1);
                CSV += row + "\n";
                if (CSV == '') {        
                alert("Invalid data");
                return;
            }
            var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
            var link = document.createElement("a");
            link.href = uri;
            link.style = "visibility:hidden";
            link.download = `AllTransection.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        }catch (error) {
            errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "exportData",
            errorMessage : error.message
            },(res)=>{
            if(res.error){
                console.error(res.error)
            }
            })
            console.error(error)
        }
        
        },
  },
};

</script>
<style scoped>
    
</style>>
