<template>
  <div>
    <b-spinner
      variant="primary"
      large
      v-if="isSpinner"
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />

      <b-card class="user_detail_top_section current_detail_top_section">
        <h4>{{bookingTitle}}</h4>
        <b-row v-for="(item, index) in rowsArrayData" :key="index" class="fontchanges">
          <b-col xl="3" lg="3" md="3">
            <h5>Booking ID</h5>
            <p>{{ item.bookingId }}</p>
            <h5>Category</h5>
            <p>
              <router-link
                :to="`/category-management/category/${item.categoryId}`"
                class="text-decoration-underline"
                >
                {{ item.category }}
                </router-link
              >
            </p>
            <h5>Tutor Name</h5>
            <span class="text-nowrap">
              <b-avatar :src="item.avatarTutor" class="user_img" />
              <router-link
              :to="{
                  name: 'user-details',
                  params: {
                      id: item.bookingTutor
                  },
              }"
                class="text-decoration-underline"
                >{{ item.tutorNameDetail }}</router-link
              >
            </span>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Session Booking Date & Time</h5>
            <p>
              {{sessionTimeCalculate(item.sessionBooking, item.sessionEnd)}}
            </p>
            <h5>Teaching Language</h5>
            <p>{{ item.teachinglanguage }}</p>
            <h5>Tutor Type</h5>
            <span class="tutor_type">
              <b-badge :style="getTutorTypeStyle(item.tutorType)">
                <!-- :variant="getTutorTypeBadgeVariant(item.tutorType)" -->
                {{ item.tutorType }}
              </b-badge>
            </span>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <h5>Booked Date & Time</h5>
            <p>{{ TemplateCreatedDate(item.booked) }}</p>
            <h5>Learner Name</h5>
            <span class="text-nowrap">
              <b-avatar :src="item.avatarLearner" class="user_img" />
              <router-link
              :to="{
                  name: 'user-details',
                  params: {
                      id: item.bookinglernerId
                  },
              }"
                class="text-decoration-underline"
                >{{ item.learnerChildName }}</router-link
              >
            </span>
             
            <h5 style="padding-top: 10px;">Search Tag(s)</h5>
            <div class="tag_btn">
              <div v-for="(item, index) in upcomingSessionDetailPageUp" :key="index">
                <b-badge
                  v-for="(items, index1) in item.tagName"
                  :key="index1"
                  class="btn mr-2 mb-1 btn-outline-secondary"
                >
                  {{ items }}
                </b-badge>
              </div>
            </div>
          </b-col>
          
          <b-col xl="3" lg="3" md="3">
            <h5>Booking Credits</h5>
            <span v-if="item.bookingcredits !== 0" style="color: #28c76f !important;font-weight:500;">
                {{ item.bookingcredits }} Credits
              </span>
              <span v-else style="color:red">
                Trial Booking
              </span>
          </b-col>
          <b-col md="6" >
            <h5>Description</h5>
            <div
                class="wrap-break-word"
                v-show="true"
                >
                <span v-html="limitTo(item.description)"></span>
                <a
                    class="readmore cancelReadMore"
                    v-b-modal="'readmore' + item.bookingId"
                    v-show="item.description.length >= 200"
                    
                    >Read more</a
                >
                <UserModal title="Cancel Reason" :text="item.description" :id="item.bookingId" />
            </div>
          </b-col>
          <b-col md="6" class="mt-2">
            
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import firebase from "@/utils/firebaseInit"
import * as insertFirebase from "@/utils/FirebaseQueries/insertQueries/insertQueries.js";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import { mapGetters,mapMutations,mapActions } from "vuex";
import UserModal from "@/@core/components/modals/ReadMoreModal.vue";
import { dbCollections } from "@/utils/firebaseCollection.js";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "@/views/SessionsManagement/UpcomingSession(s)/upcomingsessionDetail.vue"
import { adminBookingNotification } from '../PendingSession(s)/service.js';
import { onBookingCancelUpdateSlot } from '@/utils/globalFunction/updateSlotWhenCancelBookingService.js'
import index from 'vue-prism-component';
const db = firebase.firestore();
import axios from "axios";
import moment from "moment";
export default {
  components: {
    BCard,
    BSpinner,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BButton,
    UserModal
  },
  data() {
    return {
      direction: false,
      isSpinner: false,
      isUpdating: false,
      currentitems: [],
      tutorType: [
        {
          1: "Valuable",
          2: "Elite",
          3: "Normal",
          4: "N/A",
        },
        {
          1: "light-primary",
          2: "light-danger",
          3: "light-success",
          4: "light-dark",
        },
      ],
      learnerIdOfTheSession: "",
      rowsArrayData: [],
      usedCredit:Number,
      bookingIdForSession:'',
      bookingTitle:"",
    };
  },
  beforeRouteLeave(from,to,next){
   var self = this;
     var  name = ""
     self.$root.$emit('upcomingSessionDetailsForName', name);
     next()
  },
  created() {
    this.isSpinner = true
    let getUpComingFound = this.getupComingSessionList.length != 0;
    if(!getUpComingFound){
      this.getupComingSessionDatas().then(res=>{
        this.upcomingSessionDetailPageUp()

      }).catch(error=>{
        console.error("error",error)
        this.isSpinner = false
      })
    }else{
      this.upcomingSessionDetailPageUp()

    }
    var self = this;
    self.$root.$emit("startButtonDisabled")
    self.$root.$off("BookingCancelUpComingData");
    self.$root.$on("BookingCancelUpComingData", (val, id) => {
      self.cancellationReasonData(val, id);
      self.isSpinner = true;
    });
  },
  computed: {
    ...mapGetters({
      getupComingSessionList: "upComingSession/getupComingSession",
      gettutorTypesList: "settings/getTutorTypes",
    }),  
  },
  methods: {
     ...mapActions({
        'getupComingSessionDatas' : 'upComingSession/getupComingSessionData',
      }),
    ...mapMutations({
        'testMutation' : "upComingSession/testMutation",
        'cancelSessionTest' : "cancelSessionDetails/cancelSessionTests",
        'cancelSessionArrayEmptyForList' : "cancelSessionDetails/setCancelSessionArray"
    }),
    getTutorTypeStyle(value) {
        if(this.gettutorTypesList) {
            let index = this.gettutorTypesList.findIndex((val ) =>{
              return val.name == value
            });
            if(index > -1) {
                return {
                  'background-color' : this.gettutorTypesList[index].backgroundColor,
                  'color' : this.gettutorTypesList[index].textColor,
                  'border-color': this.gettutorTypesList[index].textColor,
                  'border-width': '1px',
                  'border-style': 'solid'
                }
            }
        } else {
            return ''
        }
    },
   upcomingSessionDetailPageUp() {
      try {
        var self = this;
        self.$root.$emit('upcomingDetailPagesCancel');
        self.$root.$emit('startButtonDisabled')
        this.rowsArrayData = [];
        getFirebase.simpleGetDataFromSubCollectionWithDocIDV2(dbCollections.BOOKING,this.$route.params.id,(cancelDatas)=>{
        if(cancelDatas.status === false){
          self.$root.$emit('showToastMessage','Record not found.','danger');
          self.$router.push({ name: "upcoming-session-mgmt" });
          self.isSpinner = false;
          return;
        }
        let cancelData = cancelDatas.data;
        self.$root.$emit('upcomingSessionDetailsForName', cancelData.bookingId);
            getFirebase.simpleGetDataFromSubCollectionWithDocIDV2(dbCollections.SESSIONS,cancelData.sessionDetails.id,(ress)=>{
              this.bookingTitle = cancelData.bookingsTitle ? cancelData.bookingsTitle : "";
              let res = ress.data
              var object = {
                bookingId: cancelData.bookingId,
                avatarTutor: cancelData.tutorDetails.profilePicture ? cancelData.tutorDetails.profilePicture :"",
                avatarLearner: cancelData.learnerDetails.profileImage ? cancelData.learnerDetails.profileImage :"",
                learnerChildName:
                  (cancelData.learnerDetails
                    ? cancelData.learnerDetails
                    .firstName:'') +
                  " " +
                  (cancelData.learnerDetails
                    ?cancelData.learnerDetails
                    .lastName:''),
                tutorNameDetail:
                  (cancelData.tutorDetails
                    ?cancelData.tutorDetails
                    .firstName:'' )+
                  " " +
                  (cancelData.tutorDetails
                    ?cancelData.tutorDetails
                    .lastName:''),
                sessionCansallationTime: cancelData.cancellationDate,
                bookingcredits:
                  cancelData.usedCredits?cancelData.usedCredits:0,
                cansalReason: cancelData.cancellationReason,
                sessionBooking:
                  cancelData.startTime,
                sessionEnd: cancelData.endTime,
                status: cancelData.status,
                booked: cancelData.createdAt,
                tutorType:
                  cancelData.tutorDetails
                    .tutorType,
                category:
                  cancelData.categoryDetails
                    .categoryName,
                categoryId:
                  cancelData.categoryDetails.id,
                sessionId:
                  cancelData.sessionDetails.id,
                teachinglanguage: res.teachingLanguageDetails ? res.teachingLanguageDetails.title : '-',
                description: res.description ? res.description : '',
                tagName: res.tags,
                bookingTutor: cancelData.tutor,
                bookinglernerId:
                  cancelData.learnerId,
                isTrial: cancelData.isTrial ? cancelData.isTrial : null,
              };
              this.rowsArrayData.push(object);
              self.isSpinner = false;
              self.$root.$emit('stopButtonDisabled')
            }
          );
      })
      } catch (error) {
        console.error("error", error);
        this.isSpinner = false
        setTimeout(() => {
          this.$root.$emit("stopButtonDisabled");
        }, 300);
        errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "upcomingSessionDetailPageUp",
            errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
      }
    },
  
    limitTo: function (value) {
      if(value){
        if (value.length < 200){
          return value
        }else{
          return value.substring(0, 200) + "...";
        }
      }else{
        return "-";
      }
    },
    cancellationReasonData(cancelradio, cancelreason) {
      let Index = this.getupComingSessionList.findIndex((values) => {
        return values.id == this.$route.params.id;
      });
      this.learnerIdOfTheSession = this.getupComingSessionList[Index].learnerId
      this.usedCredit = this.getupComingSessionList[Index].usedCredits
      this.bookingIdForSession = this.getupComingSessionList[Index].bookingId
      try {
        if (!this.getupComingSessionList[Index].isTrial) {
          getFirebase.simpleGetDataFromSubCollectionWithDocID(dbCollections.USERS,this.learnerIdOfTheSession, async (resOFLearnerDetails)=>{
            if(!resOFLearnerDetails){
              return;
            }
            await db.collection(dbCollections.CREDITTRANSACTIONS).where("userId","==",this.learnerIdOfTheSession).orderBy("createdAt", "desc").limit(1).get().then(async(docData) => {
              if(docData.empty){
              }else{
                  let sengleBookingData = docData.docs[0].data();                                         
                  const updateObj = {
                    currentCredit : Number(resOFLearnerDetails.currentCredit) + Number(this.usedCredit)
                  }
                  await updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,this.learnerIdOfTheSession,updateObj,async(res) => {
                    if(!res){
                      return;
                    }
                    const learnerDeatil = {
                      firstName : resOFLearnerDetails.firstName ? resOFLearnerDetails.firstName :'',
                      lastName : resOFLearnerDetails.lastName ? resOFLearnerDetails.lastName :'',
                      profileImage: resOFLearnerDetails.profilePicture ? resOFLearnerDetails.profilePicture :''
                    }
                    const transactionObject = {
                      availableCredit:Number(sengleBookingData.availableCredit) + Number(this.usedCredit),
                      bookingId:this.bookingIdForSession,
                      createdAt:new Date(),
                      credits:Number(this.usedCredit),
                      id:'',
                      paymentId:'',// This feild is static
                      reference:1,
                      remarks:'Booking cancelled.',
                      transactionType:"cr",
                      userDetail:learnerDeatil,
                      userId: this.learnerIdOfTheSession,
                      isSendNotification: false
                    }
                    insertFirebase.addDataToRootCollection(dbCollections.CREDITTRANSACTIONS,transactionObject,async(credit) => {
                      if(credit){
                        const updateObjCredit = {
                          id : credit.id
                        }
                        await updateFirebase.updateRootCollectionDataByDocId(dbCollections.CREDITTRANSACTIONS,credit.id,updateObjCredit,async(creditUpdate) => {
                            const cancelreasondetail = {
                            cancellationReason: cancelreason === "Other"? cancelradio :cancelreason,
                            status: Number(3),
                            cancelReasonStatus: Number(0),
                            cancellationDate: new Date(),
                            updatedAt: new Date(),
                            usedCredits: 0
                          };
                          updateFirebase.updateRootCollectionDataByDocId(
                            dbCollections.BOOKING,
                            this.$route.params.id,
                            cancelreasondetail,
                            async(res) => {
                              if(!res){
                                return;
                              }
                              adminBookingNotification({
                                bookingData : {...this.getupComingSessionList[Index], status:3, cancellationReason: cancelreason === "Other"? cancelradio :cancelreason,},
                              }, () => {});
                              onBookingCancelUpdateSlot({
                                bookingId:  this.$route.params.id
                              },() => {})
                              let newArray = [];
                              this.cancelSessionArrayEmptyForList(newArray);
                              this.learnerIdOfTheSession = '';
                              this.usedCredit = 0;
                              this.bookingIdForSession ='';
                              this.testMutation(Index)
                              this.isSpinner = false;
                              this.$root.$emit(
                                "showToastMessage",
                                "Booking cancel successfully.",
                                "success"
                              );
                              this.$router.back();
                              this.$root.$emit('stopButtonDisabled')
                            });
                        })
                      }
                    })
                  })  
                }
            })
            .catch((error) => {
                console.error(error);
            })
          });
        } else {
           const cancelreasondetail = {
            cancellationReason: cancelreason === "Other"? cancelradio :cancelreason,
            status: Number(3),
            cancelReasonStatus: Number(0),
            cancellationDate: new Date(),
            updatedAt: new Date(),
            usedCredits: 0
          };
          updateFirebase.updateRootCollectionDataByDocId(
          dbCollections.BOOKING,
          this.$route.params.id,
          cancelreasondetail,
          async(res) => {
            if(!res){
              return;
            }
            adminBookingNotification({
              bookingData : {...this.getupComingSessionList[Index], status:3, cancellationReason: cancelreason === "Other"? cancelradio :cancelreason,},
            }, () => {});
            onBookingCancelUpdateSlot({
              bookingId:  this.$route.params.id
            },() => {})
            let newArray = [];
            this.cancelSessionArrayEmptyForList(newArray);
            this.learnerIdOfTheSession = '';
            this.usedCredit = 0;
            this.bookingIdForSession ='';
            this.testMutation(Index)
            this.isSpinner = false;
            this.$root.$emit(
              "showToastMessage",
              "Booking cancel successfully.",
              "success"
            );
            this.$router.back();
            // this.$router.push({ name: "upcoming-session-mgmt" });
            this.$root.$emit('stopButtonDisabled')
          });
        }
          
      } catch (error) {
        console.error("error", error);
         errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "cancellationReasonData",
            errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
      }
    },
    sessionTimeCalculate(startTime , endTime) {
      if (moment(startTime.seconds*1000).format("DD-MM-YYYY") == moment(endTime.seconds*1000).format("DD-MM-YYYY")) {
        return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("hh:mm A")}`
      } else {
        return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")}`
      }
    },
    TemplateCreatedDate(TemplateCreatedDate) {
      var self = this;
      var notificationDate = new Date(
        TemplateCreatedDate.seconds * 1000
      ).getDate();
      var notificationMonth =
        new Date(TemplateCreatedDate.seconds * 1000).getMonth() + 1;
      var notificationYear = new Date(
        TemplateCreatedDate.seconds * 1000
      ).getFullYear();
      var returnnotificationDate =
        self.addZero(notificationDate) +
        "-" +
        self.addZero(notificationMonth) +
        "-" +
        notificationYear +
        ", " +
        self.notificationCreateTime(
          new Date(TemplateCreatedDate.seconds * 1000)
        );
      return returnnotificationDate;
    },

    TemplateEndDate(TemplateEndDate) {
      var self = this;
      var returnnotificationDate = self.notificationCreateTime(
        new Date(TemplateEndDate.seconds * 1000)
      );
      return returnnotificationDate;
    },

    notificationCreateTime(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      hours = hours<10 ? '0'+hours:hours
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },

    addZero(value) {
      if (value > 9) {
        return value;
      } else {
        return "0" + value;
      }
    },
    getTutorTypeBadgeVariant(value){
      var variant = "light-";
      if(value == "Normal")
      {
        variant += 'success';
      }
      else if(value == "Elite")
      {
        variant += 'danger';
      }
      else if(value == "Valuable")
      {
        variant += 'primary';
      } else {
        variant += 'warning'
      }
      return variant;
    },
  },  
};
</script>

<style scoped>
   .cancelReadMore{
    color: #0096DB !important;
    text-decoration: none !important;
  }
</style>
