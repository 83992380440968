<template>
    <div>
        <b-spinner
        variant="primary"
        v-if="isSpinnerIntrested"
        large
        class="pageLoadSpinners loaderDetail"
        />
        <div v-show="!isSpinnerIntrested">
        <b-tab title="Interested Area" @click="onIntrestedArea">
            <div class="card user_table interested_table"  v-if="!isSpinnerIntrested">
                <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                    <b-form-input
                        v-model="interestedsearchTerm"
                        placeholder="Search..."
                        type="text"
                        class="d-inline-block"
                    />
                    </div>
                </b-form-group>
                </div>
                <vue-good-table
                :columns="interestedcolumns"
                :rows="interestedsrows"
                :rtl="direction"
                :search-options="{
                    enabled: true,
                    externalQuery: interestedsearchTerm,
                }"
                :select-options="{
                    enabled: true,
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true, // disable the select info panel on top
                }"
                :pagination-options="{
                    enabled: true,
                    perPage: pageLength,
                }"
                >
                <template slot="table-row" slot-scope="props">
                    <span
                    class="text-nowrap userid"
                    >
                    <span class="text-nowrap underline">
                    <router-link :to="{name: 'category-details', params: {id: props.row.categoryId}}">
                        {{ props.row.categoryName }}
                        </router-link>
                    </span>
                    </span>
                </template>
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> 
                        <span v-if="props.total === 0"> Showing 0 </span>
                        <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                        to
                        <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                        <span v-else>{{props.total}} of {{ props.total }} entries</span>
                        </span>

                            <div class="d-flex align-items-center mb-1 mt-0 showentry intrestedarea_userdetail">
                            <span>Show</span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['10', '20', '30', '40', '50']"
                                class="mx-1"
                                @input="
                                (value) => props.perPageChanged({ currentPerPage: value })
                                "
                            />
                            <span>entries</span>
                            </div>
                    </div>
                    <div>
                        <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :current-page="currentPage"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="
                            (value) => {
                            props.pageChanged({ currentPage: value });
                            currentPage = value
                            }
                        "
                        >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                        </b-pagination>
                    </div>
                    </div>
                </template>
                </vue-good-table>
            </div>
        </b-tab>
        </div>
    </div>
</template>

<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import { dbCollections } from "@/utils/firebaseCollection.js";
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import { mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Users/User/UserDetailTabs/UserDetail_IntrestedArea.vue"
export default {
name : "IntrestedArea",
props : {
    userDetail : Object
},
directives: {
    Ripple,
},
components: {
    VueGoodTable,
    BCard,
    BImg,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BCardText,
    BTabs,
    BTab,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    flatPickr,
    BSpinner,
},
data() {
    return{
        interestedsearchTerm: "",
        interestedcolumns: [
        {
          label: "Category Name",
          field: "categoryname",
        },
        ],
        interestedsrows: [],
        direction: false,
        pageLength: 10,
        currentPage: 1,
        isSpinnerIntrested: false,
        

         
    }
},
computed: {
    ...mapGetters({
      getCategoryList:'categoryManagement/getCategories',
    }),
},
watch:{
    userDetail(newV , preV){
        if(newV !== preV && newV !== null){
            this.intrestedArea();
        }
    }
},
methods: {
    intrestedArea(){
        var self = this;
        var obj = {}
        try{
            self.isSpinnerIntrested = true;
            if (self.userDetail && self.userDetail.areaOfInterest && self.userDetail.areaOfInterest.length > 0) {
                for (let i = 0; i < self.getCategoryList.length; i++) {
                    for (let j = 0; j < self.userDetail.areaOfInterest.length; j++) {
                        if(self.getCategoryList[i].id === self.userDetail.areaOfInterest[j]) {
                            let obj = {
                                categoryName : self.getCategoryList[i].categoryName,
                                categoryId : self.getCategoryList[i].id,
                            }
                            self.interestedsrows.push(obj)
                        }
                    }
                }
                if (self.userDetail.areaOfInterest.length === self.interestedsrows.length) {
                    self.isSpinnerIntrested = false
                }     
            }
            else{
                    self.isSpinnerIntrested = false;
            }
        }catch (error) {
            errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "intrestedArea",
              errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
            })
             console.error(error)
        }
    },
    onIntrestedArea(){
          try{
                var allTabList = document.getElementsByClassName('nav-link active')
                if (allTabList[0].innerText != 'Interested Area') { 
                    this.interestedsrows = []    
                    this.intrestedArea()
                }
          }catch (error) {
                errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "onIntrestedArea",
                errorMessage : error.message
                },(res)=>{
                 if(res.error){
                    console.error(res.error)
                }
                })
                console.error(error)
          }
    }
}
}
</script>

<style>

</style>