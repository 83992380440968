<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
     
      <div class="card user_filter all_session">
        <h2>Filters</h2>

        <b-form class="dropdownfilter"  @submit.prevent>
          <b-row>
            <b-col xl="3" lg="3" md="3">
              <label>Tutor Name</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="tutor_name_selected"
                variant="outline-secondary"
                class="w-100"
              >
                <b-dropdown-item
                  v-for="item in tutor_name_options"
                  :key="item.value"
                  @click="tutor_name_selected = item.text"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
            <b-col xl="3" lg="3" md="3">
              <label>Tutor Type</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="tutor_type_selected"
                variant="outline-secondary"
                class="w-100"
              >
        
                <b-dropdown-item
                  v-for="item in tutor_type_options"
                  :key="item.value"
                  @click="tutor_type_selected = item.text"
                  >{{item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
            <b-col xl="3" lg="3" md="3">
              <label>Category</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="category_selected"
                variant="outline-secondary"
                class="w-100"
              >
                <b-dropdown-item
                  v-for="item in category_options"
                  :key="item.value"
                  @click="category_selected = item.text"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <div class="card user_table all_session_table all-session-table">
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1"></label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search..."
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="filterRows"
          @on-cell-click="onCellClick"
          class="cursor-pointer"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'fullName'" class="text-nowrap">
              <b-avatar :src="props.row.avatar" class="user_img" />                          
              <router-link :to="{ name: 'user-details', params: {id: props.row.userid}}" class="text-nowrap">{{ props.row.fullName }}</router-link>
            </span>
            <span
                v-else-if="props.column.field === 'tutorType'"
                class="tutor_type"
              >
            <div v-if="props.row.tutorType != '' && props.row.tutorType != null">
                <b-badge  :style="getTutorTypeStyle(props.row.tutorType)">
                  <!-- :variant="getStatusBadgeVariant(props.row.tutorType)" -->
                  {{ props.row.tutorType }}
                </b-badge>
              </div>
            </span>
            <span v-else-if="props.column.field === 'category'" class="text-nowrap">
              <span class="childname text-nowrap">
                <router-link :to="{name: 'category-details', params: {id: props.row.categoryID}}">
                {{ props.row.category }}
                </router-link>
                </span>
            </span>
            <span v-else-if="props.column.field === 'status'">
               <b-badge  :variant="getStatusBadgeVariant(getAllStatusList.sessionStatus[props.row.status])">
                    {{getAllStatusList.sessionStatus[props.row.status]}} 
                </b-badge>
              </span>
                             <span
                    v-else-if="props.column.field === 'action'"
                    class="action_field action_field1517"
                  >
                    <span>
                      <b-dropdown
                        variant="link"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>
                        <b-dropdown-item @click="View(props.row.sessionId)">
                          <span >
                           <feather-icon
                              icon="EyeIcon"
                              size="20"
                            />
                            View
                            </span
                          >
                        </b-dropdown-item>
                        <b-dropdown-item @click="booksession(props.row.sessionId)">
                          <span>
                           <feather-icon
                              icon="ClockIcon"
                              size="20"
                            />
                            Book Session</span
                          >
                        </b-dropdown-item>
                        <b-dropdown-item @click="disablesession(props.row)" v-if="props.row.status==2">
                          <span > 
                            <feather-icon
                            icon="EyeOffIcon"
                            size="20"
                            />
                            Disable Session</span
                          >
                        </b-dropdown-item>
                          <b-dropdown-item @click="ActiveSession(props.row)" v-if="props.row.status==3">
                          <span > 
                            <feather-icon
                            icon="EyeIcon"
                            size="20"
                            />
                            Active Session</span
                          >
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                 <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to
                  <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                  <span v-else>{{props.total}} of {{ props.total }} entries</span>
                </span>

                    <div class="d-flex align-items-center mb-1 mt-0 showentry allsession">
                      <span>Show</span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10', '20', '30', '40', '50']"
                        class="mx-1"
                        @input="
                          (value) => props.perPageChanged({ currentPerPage: value })
                        "
                     />
                      <span>entries</span>
                    </div>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :current-page="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => {
                      props.pageChanged({ currentPage: value });
                      currentPage = value
                    }
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index"
import Ripple from 'vue-ripple-directive'
import { mapGetters,mapActions } from 'vuex';
import { temp } from '@/@core/directives/animations';
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import {dbCollections} from "@/utils/firebaseCollection.js"
import * as insertFirebase from "@/utils/FirebaseQueries/insertQueries/insertQueries.js";
import * as insertDatabase from "@/utils/FirebaseQueries/insertQueries/insertQueries.js";
import status from '@/store/status';
import { sessionBookingNotification } from './service.js';
import {updateConnectedUserWhenSessionStatusChange} from "./updateConnectedUserWhenSessionStatusChangeService"
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/SessionsManagement/AllSession(s)/allSessionsList.vue"

export default {

  directives:{
    Ripple
  },
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BButton
  },
  data() {
    return {
      direction:false,
      isSpinner:false,
      isUpdating:false,

      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      tutor_name_selected: "All",
      tutor_type_selected: "All",
      category_selected : "All",
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Tutor Name",
          field: "fullName",
        },
        {
          label: "Tutor Type",
          field: "tutorType",
        },
        {
          label: "Session Title",
          field: "sessionTitle",
        },
        {
          label: "Category",
          field: "category",
        },
         {
          label: "Status",
          field: "status",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      searchTerm: "",
      status: [
        {
          1: "Current",
          2: "Active",
          3: "Block",
          4: "Suspend",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  computed: {
      ...mapGetters({
          sessions:'sesionManagement/getSessions',
          users:'userManagement/getUsers',
          category:'categoryManagement/getCategories',  
          getAllStatusList:'settings/getAllStatus' ,
          gettutorTypesList:'settings/getTutorTypes', 
          getsettingList : 'settings/getsettingArrayFeild',
      }),
      rows(){
          var arr=[]
          try{
            for(var i=0;i<this.sessions.length;i++){
              if(this.sessions[i].status !== 0 && this.sessions[i].status !== 1 && this.sessions[i].status !== 4){
                var category_name = ""
                var categoryId = ''
                var user= this.users.filter((val)=>{
                    if(val.id == this.sessions[i].tutor){
                        return val
                    }
                })
                var cat = this.category.filter((val)=>{
                    if(val.id == this.sessions[i].category){
                        return val
                    }
                })
                if( cat[0] && cat[0].categoryName != undefined){
                    category_name = cat[0].categoryName
                    categoryId = cat[0].id
                }
                if(user !== null && user.length > 0){
                var obj ={
                    userid: user[0].id ?user[0].id : '' ,
                    avatar: user[0].profilePicture,
                    fullName: user[0].firstName +" "+ user[0].lastName,
                    tutorType: user[0].tutorType ? user[0].tutorType : '',
                    tutorId: user[0].id ? user[0].id : '',
                    sessionTitle : this.sessions[i].title,
                    sessionId :this.sessions[i].id,
                    category : category_name,
                    userRole: user[0].userRole,
                    categoryID : categoryId,
                    action : "action",
                    status:this.sessions[i].status
                }
                arr.push(obj)
                }
              }
            }
             return arr;
          } catch(err){
              console.error(err);
              errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "rows",
              errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
            })
          }
         
      },
      category_options(){
              var categoryArr =[]
              categoryArr.push({value : 0 , text : "All"})
              for(var i=0;i<this.rows.length;i++){
                var temp =  false
                for(var j=0;j< categoryArr.length;j++){
                  if(this.rows[i].category == categoryArr[j].text ){
                    temp = true
                  }
                }
                if(!temp){
                  var obj = {
                    val : i+1,
                    text:this.rows[i].category
                  }
                  categoryArr.push(obj)
                }
              }
              return categoryArr
            
      },
      tutor_name_options(){
          var nameArr = []
          nameArr.push({value:0,text:"All"})
          for(var i=0;i<this.rows.length;i++){
              var temp = false
              for(var j=0;j<nameArr.length;j++){
                  if(this.rows[i].fullName == nameArr[j].text){
                      temp = true
                  }
              }
              if(!temp){
                  var obj={
                  value : i+1,
                  text : this.rows[i].fullName
                }
                nameArr.push(obj)
              }
          }
          return nameArr
      },
      tutor_type_options(){
        var typeArr = []
          typeArr.push({value:0,text:"All"})
          for(var i=0;i<this.rows.length;i++){
            if(this.rows[i].tutorType != ''){
              var temp = false
              for(var j=0;j<typeArr.length;j++){
                  if(this.rows[i].tutorType == typeArr[j].text){
                      temp = true
                  }
              }
              if(!temp){
                  var obj={
                  value : i+1,
                  text : this.rows[i].tutorType
                }
                typeArr.push(obj)
              }
            }
          }
          return typeArr
      },
      filterRows(){
          var listA = this.rows;
          if(this.tutor_name_selected == "All" && this.tutor_type_selected == "All" && this.category_selected == "All"){
              return listA
          }
          if(this.tutor_name_selected != "All"){
              listA = listA.filter((data)=>{
                  return data.fullName.toLowerCase().includes(this.tutor_name_selected.toLowerCase())
              })
          }
          if(this.tutor_type_selected != "All"){
               if(this.tutor_type_selected != "All"){
                listA = listA.filter((data)=>{
                    return data.tutorType.toLowerCase().includes(this.tutor_type_selected.toLowerCase())
                })
          }
          }
          if(this.category_selected != "All"){
              listA = listA.filter((data)=>{
                  return data.category.toLowerCase().includes(this.category_selected.toLowerCase())
              })
          }
          return listA
      },
   
  },
  created() {
    let statusFound = this.getAllStatusList.length !== 0
    if (!statusFound) {
      this.getStatus().then(()=>{
      })
    }
  },
  methods: {
    ...mapActions({
      getStatus: 'settings/getStatusData'
    }),
    getTutorTypeStyle(value) {
        if(this.gettutorTypesList) {
            let index = this.gettutorTypesList.findIndex((val ) =>{
              return val.name == value
            });
            if(index > -1) {
                return {
                  'background-color' : this.gettutorTypesList[index].backgroundColor,
                  'color' : this.gettutorTypesList[index].textColor,
                  'border-color': this.gettutorTypesList[index].textColor,
                  'border-width': '1px',
                  'border-style': 'solid'
                }
            }
        } else {
            return ''
        }
    },
    onCellClick(data){
        if (data.column.field==="fullName") {
            this.$router.push({path: `/user-management/users/${data.row.userid}`})
        } else if (data.column.field==="category") {
            this.$router.push({name: 'category-details', params: { id: data.row.categoryID}})
        }
        else if (data.column.field ==="action") {
          return;
        }
        else {
          this.$router.push(`/sessions-management/all-sessions/${data.row.sessionId}`)
        }
    },
    // WHEN CLICK ON SESSION BOOKING PASS ID IN ROUTE(Shraddha)
    booksession(id){
      var self = this;
      self.$router.push(`/booking-session-all-session/${id}`)
    },
    disablesession(sessionId){
       this.$bvModal
        .msgBoxConfirm("Are you sure you want to Disable Session?", {
          title: "Disable",
          class: "test",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-primary",
          hideHeaderClose: false,
          centered: true,
          footerClass: "approve-footer",
          headerClass: "approve-header suspend-header",
          bodyClass: "approve-body",
        })
        .then((value) => {
          if (value) {
            var disableobj = {
              status: 3,
            };
            updateFirebase.updateRootCollectionDataByDocId(
              dbCollections.SESSIONS,
             sessionId.sessionId,
              disableobj,
              () => {
                sessionBookingNotification({
                  title: sessionId.sessionTitle,
                  status: 3,
                  tutor: sessionId.tutorId,
                  id: sessionId.sessionId,
                }, (resp) => {});
                updateConnectedUserWhenSessionStatusChange({
                  tutorId :sessionId.tutorId,
                  categoryId  : sessionId.categoryID,
                  action: "remove"
                },(resp)=>{})
                this.$root.$emit('showToastMessage','Session disabled successfully.','success');
              }
            );
          }
        });
    },
    ActiveSession(sessionId){
       this.$bvModal
        .msgBoxConfirm("Are you sure you want to active Session?", {
          title: "Active",
          class: "test",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-primary",
          hideHeaderClose: false,
          centered: true,
          footerClass: "approve-footer",
          headerClass: "approve-header",
          bodyClass: "approve-body",
        })
        .then((value) => {
          if (value) {
            var disableobj = {
              status: 2,
            };
            updateFirebase.updateRootCollectionDataByDocId(
              dbCollections.SESSIONS,
             sessionId.sessionId,
              disableobj,
              () => {
                sessionBookingNotification({
                  title: sessionId.sessionTitle,
                  status: 2,
                  tutor: sessionId.tutorId,
                  id: sessionId.sessionId,
                }, (resp) => {});
                updateConnectedUserWhenSessionStatusChange({
                  tutorId :sessionId.tutorId,
                  categoryId  : sessionId.categoryID,
                  action: "add"
                },(resp)=>{})
                this.$root.$emit('showToastMessage','Session active successfully.','success');
              }
            );
          }
        });
    },
    getStatusBadgeVariant(value){
      var variant = "";
      if(value == "Valuable")
      {
        variant += 'light-primary';
      }
      else if(value == "Elite")
      {
        variant += 'light-danger';
      }
      else if(value == "Normal")
      {
        variant += 'light-success';
      }
      else if(value == "Expert")
      {
        variant += 'light-warning';
      }
      else if(value == "Active")
      {
        variant += 'light-success';
      }
      else if(value == "Disable")
      {
        variant += 'light-danger';
      };
      return variant;
    },
    View(id){
     this.$router.push(`/sessions-management/all-sessions/${id}`)
    }
  },
};
</script>

