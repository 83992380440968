<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-card>
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="3">
                <b-form-group>
                    <label for="planname">Plan Name<span class="redcolorstar">*</span></label>
                    <validation-provider
                        #default="{ errors }"
                        name="plan name"
                        rules="required|min:3"
                        >
                        <b-form-input
                            v-model="planname"
                            id="planname"
                            placeholder="Enter Plan Name"
                            maxlength="12"
                            @blur="planname = planname.trim()"
                            @keyup="CheckPlanNameExist()"
                            autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                            class="text-danger"
                            v-if="samePlanErrorMessage != ''"
                            >{{ samePlanErrorMessage }}
                        </small>
                    </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                    <label for="nocredits">No. of Credits<span class="redcolorstar">*</span></label>
                    <validation-provider
                        #default="{ errors }"
                        name="no. of credits"
                        rules="required"
                        >
                        <b-form-input
                            v-model="nocredits"
                            placeholder="Enter Credits"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            @keyup="trackChange"
                            maxlength="5"
                            autocomplete="off"
                            id="nocredits"
                            @onChange="creditsnumber"
                            @copy.prevent
                            @paste.prevent
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                    <label for="planamount">Plan Amount({{currency}})<span class="redcolorstar">*</span></label>
                    <b-form-input
                        :placeholder="creditsnumber.toString()"
                        type="number"
                        id="planamount"
                        disabled
                    ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                    <label for="discount">Discount(%)<span class="redcolorstar"></span></label>
                    <validation-provider
                        :rules="{
                        regex: /^(\d{1,5}|\d{0,5}\.\d{0,2})$/,
                        max_value: 100,
                        min_value: 0,
                        }"
                        #default="{ errors }"
                        name="discount"
                        >
                        <b-form-input
                            v-model="discount"
                            placeholder="Enter Discount"
                            @keypress="onlyNumber"
                            max="100"
                            maxlength="5"
                            autocomplete="off"  
                            id="discount"
                            @copy.prevent
                            @paste.prevent
                        />
                        <small class="text-danger">{{
                            errors[0] == "The discount field format is invalid"
                            ? "Enter two digit after decimal"
                            : errors[0] }}
                        </small>
                    </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label-for="newamount">
                    <label for="newamount">Net Amount({{currency}})<span class="redcolorstar">*</span></label>
                    <b-form-input
                        :placeholder="netamount"
                        type="number"
                        id="newamount"
                        disabled
                    />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                    <label for="tag">Add Tag<span class="redcolorstar"></span></label>
                    <validation-provider name="Add Tag">
                        <b-form-input
                        v-model="tag"
                        placeholder="Enter Tag Name"
                        maxlength="12"
                        @blur="tag = tag.trim()"
                        autocomplete="off"
                        id="tag"
                        />
                    </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label-for="lightColor">
                    <label for="lightColor">Background Colour<span class="redcolorstar"></span></label><br>
                    <input type="color" id="lightColor" name="lightColour" v-model="lightColour">
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label-for="darkColor">
                    <label for="darkColor">Tag Colour<span class="redcolorstar"></span></label><br>
                    <input type="color" id="darkColor" name="darkColour" v-model="darkColour">
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group>
                    <label for="description">Description<span class="redcolorstar"></span></label>
                    <b-form-textarea
                        id="description"
                        placeholder="Enter Description"
                        rows="3"
                        v-model="description"
                        maxlength="100"
                        @blur="description = description.trim()"
                        autocomplete="off"
                    />
                </b-form-group>
              </b-col>
              <b-col cols="6"></b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import * as insertDatabase from "@/utils/FirebaseQueries/insertQueries/insertQueries.js";
import { dbCollections } from "@/utils/firebaseCollection.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import { mapGetters } from "vuex";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Setting/Createplan.vue"
import {
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
  BSpinner,
} from "bootstrap-vue";
import { required } from "@validations";
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCard,
        BFormTextarea,
        BSpinner,
    },
    data() {
        return {
            isSpinner: false,
            isUpdating: false,
            tag: "",
            description: "",
            required,
            planname: "",
            nocredits: 0,
            planamount: 0,
            discount: 0,
            newamount: 0,
            samePlanErrorMessage: "",
            lightColour:"#FFFFFF",
            darkColour:"#FFFFFF",
        };
    },
    created() {
        setTimeout(() => {
            this.$root.$emit("stopSpinner");
        }, 300);
        let self = this;
        self.trackChange();
        self.$root.$off("createPlanEvent");
        self.$root.$on("createPlanEvent", (data) => {
            if (self.$refs.simpleRules) {
                self.validationForm();
            }
        });
    },
    computed: {
        ...mapGetters({
            getPlanManagamentList: "planManagament/getPlanManagament",
            getsettingList : 'settings/getsettingArrayFeild'
        }),
        currency(){
            if (Object.keys(this.getsettingList).length != 0) {
                return this.getsettingList.currency.slice(4)
            } else {
                return ""
            }
        },
        creditsnumber() {
            return this.nocredits * this.getsettingList.oneCreditValue;
        },

        netamount() {
            if (this.creditsnumber - (this.creditsnumber * this.discount) / 100 >= 0) {
                return parseFloat(this.creditsnumber - (this.creditsnumber * this.discount) / 100).toFixed(2);
            } else {
                return parseFloat(this.creditsnumber).toFixed(2);
            }
        }

    },
    methods: {
        CheckPlanNameExist() {
            var self = this;
            self.isInArray = self.getPlanManagamentList.some(function (item) {
                return item.planName.toLowerCase().trim() == self.planname.toLowerCase().trim();
            });
            if (self.isInArray && self.planname != "") {
                self.samePlanErrorMessage = "The plan name is already exist";
            } else {
                self.samePlanErrorMessage = "";
            }
        },
        validationForm() {
            var self = this;
            if (self.samePlanErrorMessage == "") {
                self.$refs.simpleRules.validate().then((success) => {
                    try {
                        if (success) {
                        let planManagement = {
                            planName: self.planname,
                            numberOfCredits: Number(self.nocredits),
                            planAmount: Number(self.creditsnumber),
                            discountInPercentage: Number(self.discount),
                            netAmount: Number(self.netamount),
                            tag: self.tag,
                            description: self.description,
                            createdAt: new Date(),
                            updatedAt: new Date(),
                            isDeleted: false,
                            lightColour: this.lightColour,
                            darkColour: this.darkColour
                        };
                        insertDatabase.addDataToRootSubCollectionWithDocKey(
                            dbCollections.SETTINGS,
                            "setting",
                            dbCollections.PLAN,
                            planManagement,
                            (res) => {
                            self.$root.$emit(
                                "showToastMessage",
                                "Plan saved successfully.",
                                "success"
                            );
                            const planid = {
                                id: res.id,
                            };
                            updateFirebase.updateRootSubCollectionDataWithDocId(
                                dbCollections.SETTINGS,
                                "setting",
                                dbCollections.PLAN,
                                res.id,
                                planid,
                                (res) => {
                                self.resetData();
                                self.$router.push({ path: "/settings/plan-management" });
                                self.isSpinner = false;
                                self.$root.$emit("stopSpinner");
                                }
                            );
                            }
                        );
                        } else {
                            self.isSpinner = false;
                            setTimeout(() => {
                                self.$root.$emit("stopSpinner");
                            }, 200);
                        }
                    } catch (error) {
                        console.error("error", error);
                        self.$root.$emit("stopSpinner");
                        errorEmail({
                            projectName : process.env.VUE_APP_PROJECTNAME,
                            environment : process.env.NODE_ENV,
                            fileName : currentFileName,
                            hookName : "validationForm",
                            errorMessage : error.message
                        },(mailRes) => {
                            if(mailRes.error){
                            console.error(mailRes);
                            }
                        })
                    }
                });
            } else {
                setTimeout(() => {
                self.$root.$emit("stopSpinner");
                }, 200);
                self.isSpinner = false;
            }
        },

        trackChange() {
            this.nocredits = parseInt(this.nocredits) === 0 ? null : this.nocredits;
        },

        onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }
        },
        resetData() {
            var self = this;
            (self.planname = ""),
            (self.description = ""),
            (self.tag = ""),
            (self.discount = 0),
            (self.nocredits = 0),
            this.$refs.simpleRules.reset();
        },
    },
};
</script>
