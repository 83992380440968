import firebase from "@/utils/firebaseInit"
import { dbCollections } from '@/utils/firebaseCollection';
const db = firebase.firestore();
import suspendActionFun from '@/utils/globalFunction/suspendActionFun';

import {unblockUser} from '@/views/ComplaintManagement/serviceUnblock.js'


const functionForActiveChild = (uid,isfromSuspend,cb) => {
    db.collection(dbCollections.USERS).doc(uid).get().then((userRes)=>{
        if (userRes.empty) {
            cb({
                status: false,
                statusText: "No Record Found for User"
            })
        }
        if (userRes.data().hasChildren.length== 0) {
            cb({
                status: true,
                statusText: "No Child"
            })
        } 
        if (userRes.data().hasChildren.length != 0) {
            let array = []
            userRes.data().hasChildren.forEach((data)=>{
                array.push((data.id))
            })
            let count = 0
            let countFun = (row) => {
                if (count >= array.length) {
                    cb({
                        status: true,
                        statusText: "Child Update Successfully"
                    })
                   return; 
                }
                if (isfromSuspend == true) {
                    let updateObj = {
                        status: 1,
                        updatedAt: new Date()
                    }
                    db.collection(dbCollections.USERS).doc(row).update(updateObj)
                    .then(()=>{
                        count++;
                        countFun(array[count])
                    }).catch((error)=>{
                        count++;
                        countFun(array[count])
                    })
                }
                if (isfromSuspend == false) {
                    let updateObj = {
                        status: 1,
                        updatedAt: new Date()
                    }
                    db.collection(dbCollections.USERS).doc(row).update(updateObj)
                    .then(()=>{
                        unblockUser({
                            uid: row
                         },(res) => {
                             count++;
                             countFun(array[count])
                         })
                    }).catch((error)=>{
                        count++;
                        countFun(array[count])
                    })
                }
            }
            countFun(array[count]);
        }
    })
}

export default functionForActiveChild;