<script>
import { Line } from 'vue-chartjs'

export default {
  extends: Line,
  props: {
    data: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
    plugins: {
      type: Array,
      default: null,
    },
    styles: {
      type: Object,
      default: null,
    },
    isChange: { 
      type: Boolean
    },
    changeDone:{
      type: Function
    }
  },
  mounted() {
    this.renderChart(this.data, this.options, this.plugins, this.styles)
  },
  watch: {
    isChange(newV, preV){
      if(newV !== preV && newV) {
        this.renderChart(this.data, this.options, this.plugins, this.styles)
        this.changeDone();
      }
    }
  },

}
</script>
