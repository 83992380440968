<!-- 
<template>
  <div>
    <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
    <div v-show="!isSpinner">

      <b-spinner variant="primary" v-if="isUpdating" large class="pageLoadSpinners" style="position:fixed"/>
      
        Table Container Card 
      <b-card
        no-body
        class="mb-0"
      >
        
      </b-card>
    </div>

  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BButton
  },
  data() {
    return {
    
    };
  },  
  methods: {},
};
</script>
-->
<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
      <b-card no-body class="mb-0" style="height: 1000px">
        <quill-editor v-model="content" :options='editorOption' style="height: 750px" />
      </b-card>
    </div>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { BCard, BSpinner } from "bootstrap-vue";
import { quillEditor } from "vue-quill-editor";
import firebase from "@/utils/firebaseInit";
import { dbCollections } from "@/utils/firebaseCollection";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Setting/Privacypolicy.vue"
const db = firebase.firestore();
export default {
  data: () => ({
    editorOption: {
        formats : [
          'background',
          'bold',
          'color',
          'font',
          'code',
          'italic',
          'link',
          'size',
          'strike',
          'script',
          'underline',
          'blockquote',
          'header',
          'indent',
          'list',
          'align',
          'direction',
          'code-block',
          'formula'
        ],
        placeholder: "Insert text here...",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            ["link"],
            ["template-partediario"]
          ],
        }
      },
    isSpinner: false,
    isUpdating: false,
    content: ``,
    tacId: "",
    recheckContent: "",
    entry: false,
  }),
  components: {
    quillEditor,
    BCard,
    BSpinner,
  },
  beforeDestroy() {
    this.$root.$off("UpdatePrivacyPolicy");
  },
  created() {
    this.dataGet();
  },
  mounted() {
    try {
      this.$root.$on("UpdatePrivacyPolicy", async () => {
        var textSimple = this.content.replace(/<[^>]+>/g, '')
        if (textSimple === "" || textSimple.trim() === "") {
          this.content = "";
          this.isSpinner = false;
          setTimeout(()=>{
            this.$root.$emit('stopButtonDisabled')
          },500)
          this.$root.$emit("showToastMessage","Privacy policy must not be blank.","danger");
          return;
        }
        if(this.content === this.recheckContent){
          this.$root.$emit("showToastMessage", "It appears there have been no changes to privacy policy.","danger");
          this.isSpinner = false;
          setTimeout(()=>{
            this.$root.$emit('stopButtonDisabled')
          },500)
          return;
        }
        if (this.entry === true) {
          try {
            this.recheckContent = this.content
            this.isSpinner = true;
            const data = await db
              .collection(dbCollections.SETTINGS)
              .doc("setting")
              .collection(dbCollections.PRIVACY)
              .doc("privacypolicy")
              .update({
                updatedAt: new Date(),
                termsAndCondition: this.content,
              });
            this.$root.$emit( "showToastMessage","Privacy policy updated successfully.","success");
            this.isSpinner = false;
            this.$root.$emit('stopButtonDisabled')
          } catch (error) {
            this.$root.$emit('stopButtonDisabled')
            this.isSpinner = false
            this.$root.$emit( "showToastMessage","Some thing went wrong.","danger");
             errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "mounted",
              errorMessage : error.message
            },(res)=>{ 
              if(res.data){
              }else if(res.error){
                console.error(res.error)
              }
            })
          }
        } else if (this.entry === false) {
          try {
            this.recheckContent = this.content
            this.entry = true;
            this.isSpinner = true;
            const data = await db
              .collection(dbCollections.SETTINGS)
              .doc("setting")
              .collection(dbCollections.PRIVACY)
              .doc("privacypolicy")
              .set({
                createdAt: new Date(),
                termsAndCondition: this.content,
              });
            this.$root.$emit("showToastMessage","Privacy policy created successfully.", "success");
            this.isSpinner = false;
            this.$root.$emit('stopButtonDisabled')
          } catch (error) {
            this.isSpinner = false
            this.$root.$emit('stopButtonDisabled')
            this.$root.$emit( "showToastMessage","Some thing went wrong.","danger");
             errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "dataGet",
              errorMessage : error.message
            },(res)=>{ 
              if(res.data){
              }else if(res.error){
                console.error(res.error)
              }
            })
          }
        }
      });
    } catch (error) {
      errorEmail({
        projectName : process.env.VUE_APP_PROJECTNAME,
        environment : process.env.NODE_ENV,
        fileName : currentFileName,
        hookName : "UpdatePrivacyPolicy",
        errorMessage : error.message
      })
      console.error("err", error);
      this.isSpinner = false
      this.$root.$emit('stopSpinner')
    }
  },
  methods: {
    dataInBox() {
    },
    dataGet() {
      try {
        this.isSpinner = true
        this.$root.$emit('startButtonDisabled');
        db.collection(dbCollections.SETTINGS)
          .doc("setting")
          .collection(dbCollections.PRIVACY)
          .get()
          .then((snapshot) => {
            let response = [];
            if (!snapshot.empty) {
              snapshot.forEach((doc) => {
                response.push({ id: doc.id, ...doc.data() });
              });
              this.entry = true;
              this.content = response[0].termsAndCondition;
              this.recheckContent = response[0].termsAndCondition;
            } else {
              this.entry = false;
            }
            this.isSpinner = false
            this.$root.$emit('stopButtonDisabled') 
          }).catch((error)=>{
            console.error("error",error)
            setTimeout(()=>{
              this.$root.$emit('stopButtonDisabled')            
            },200)
          })
      } catch (error) {
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "dataGet",
          errorMessage : error.message
        },(res)=>{ 
          if(res.data){
          }else if(res.error){
            console.error(res.error)
          }
        })
        console.error("error", error);
      }
    },
  },
};
</script>
