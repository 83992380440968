<template>
<div>
<b-spinner
  variant="primary"
  v-if="isSpinner"
  large
  class="pageLoadSpinners loaderDetail"
/>
  <b-tab title="Categories" @click="onCategory">
    <div class="user_table holiday_table tab_subcategories_table">
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="subcategoriessearchTerm"
              placeholder="Search..."
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <vue-good-table
        :columns="dynamicColumns"
        class="cursor_pointer"
        @on-cell-click="onCellClick"
        :rows="response"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: subcategoriessearchTerm,
        }"
        :select-options="{
          enabled: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'categoryName'" class="userid">
              <span class="text-nowrap underline">
               <router-link :to="{name: 'category-details', params: {id: props.row.categoryId}}">
                {{ props.row.categoryName }}
                </router-link>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'totalBookings'"
            class="userid"
            >{{ props.row.totalBookings }}
          </span>
          <span v-else-if="props.column.field === 'status'" class="userid">
            <b-form-checkbox
              v-bind:checked="props.row.status_flag"
              name="check-button"
              switch
              inline
              :disabled="isProcess || userStatus == 4"
              class="custom-control-success"
              @change="changeStatus($event, props.row)"
            >
            </b-form-checkbox>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
          <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> 
                    <span v-if="props.total === 0"> Showing 0 </span>
                    <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                    to
                    <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                    <span v-else>{{props.total}} of {{ props.total }} entries</span>
                  </span>

                      <div class="d-flex align-items-center mb-1 mt-0 showentry categoris_iserDetailTab">
                        <span>Show</span>
                        <b-form-select
                          v-model="pageLength"
                          :options="['10', '20', '30', '40', '50']"
                          class="mx-1"
                          @input="
                            (value) => props.perPageChanged({ currentPerPage: value })
                          "
                      />
                        <span>entries</span>
                      </div>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :current-page="currentPage"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="
                      (value) => {
                        props.pageChanged({ currentPage: value });
                        currentPage = value
                      }
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
          </template>
      </vue-good-table>
    </div>
  </b-tab>
</div>
</template>
<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";
import firebase from "@/utils/firebaseInit.js";
const db = firebase.firestore();
import { dbCollections } from "@/utils/firebaseCollection.js";
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import * as updateQueries from "@/utils/FirebaseQueries/updateQueries/updateQueries";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries.js";
import {updateConnectedUserWhenSessionStatusChange} from "./updateConnectedUserSessionStatusService"
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Users/User/UserDetailTabs/Categories_Learner.vue"
export default {
  name: "CategoriesLearner",
  directives: {
    Ripple,
  },
  components: {
    BFormCheckbox,
    VueGoodTable,
    BCard,
    BImg,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BCardText,
    BTabs,
    BTab,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    flatPickr,
    BSpinner,
  },
  props:{
    userStatus: Number,
  },
  data() {
    return {
      pageLength: 10,
      perPage: 10,
      currentPage: 1,
      pageOptions: [10, 20, 30, 40, 50],
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      dir: false,
      subcategoriesrows: [],
      subcategoriescolumns: [
        {
          label: "Category Name",
          field: "categoryName",
        },
        {
          label: "Total Revenue($)",
          field: "revenue",
        },
        {
          label: "Total No. of Booking",
          field: "totalBookings",
        },
        {
          label: "Status",
          field: "status",
        },
      ],
      totalBookings: 0,
      totalSubCategories: 0,
      direction: false,
      subcategoriessearchTerm: "",
      userDetail: {},
      tutor_type_selected_learner: "All",
      isProcess: false,
      response: [],
      isSpinner: false,
      totalRevanue: 0
    };
  },
  computed: {
    ...mapGetters({
      bookings: "booking/getBooking",
      getsettingList : 'settings/getsettingArrayFeild'}),
    currency(){
            if (Object.keys(this.getsettingList).length != 0) {

                return this.getsettingList.currency.slice(4)
            } else {
                return ""
            }
    },
    dynamicColumns(){
        this.subcategoriescolumns[1].label = `Total Revenue(${this.currency})`	
        return this.subcategoriescolumns
    },
  },
  methods: {
    onCellClick(data){
         if(data.column.field==="status") {
          this.changeStatus($event, data.row)
        }
        else {
        this.$router.push({ name: 'category-details',params: { id: data.row.categoryId, name: data.row.categoryName },})
        }
   },
    limitTo: function (value) {
      if (!value) return "";
      return value.substring(0, 90) + "...";
    },
    getFinalData() {
      
      try {
        this.isSpinner = true;
        var found = false;
        var index = 0;
        db.collection(dbCollections.SESSIONS)
          .where("tutor", "==", this.$route.params.id)
          .where("status", "in", [2, 3])
          .get()
          .then((snapshot) => {
            if(snapshot.size == 0){
              this.isSpinner = false;
              return;
            }
            this.response = [];
            if (!snapshot.empty) {
              snapshot.forEach((doc) => {
                index++;
                let obj = {
                  id: doc.id,
                  category: doc.data().category,
                  categoryName: doc.data().categoryDetails ? doc.data().categoryDetails.categoryName : "",
                  categoryId: doc.data().category,
                  sessionId: doc.data().id,
                  status: doc.data().status,
                  status_flag: false,
                  duplicatearray: [],
                  totalBookings:0,
                  revenue: 0,
                };        
                if (this.response.length == 0) {
                  if(obj.status == 2){
                    obj.status_flag = true;
                  }
                  if (snapshot.size == this.response.length + 1) {
                  this.isSpinner = false;
                  }
                  obj.duplicatearray.push({
                    categoryName: obj.categoryName,
                    categoryid: obj.categoryId,
                    sessionid: obj.id,
                  });
                  this.response.push(obj);
                } else {
                  this.response.forEach((catname) => {
                    if (catname.categoryId == obj.categoryId) {
                      found = true;
                      if (obj.status == 2) {
                        catname.status_flag = true;
                        obj.status_flag = true;

                      }
                      catname.duplicatearray.push({categoryName: obj.categoryName,categoryid: obj.categoryId,sessionid: obj.id,});
                    }
                  });
                  if (!found) {
                    obj.duplicatearray.push({categoryName: obj.categoryName,categoryid: obj.categoryId,sessionid: obj.id,});
                    if (obj.status == 2) {
                      obj.status_flag = true;
                    }
                    if (snapshot.size == this.response.length + 1) {
                    this.isSpinner = false;
                    }
                    this.response.push(obj);
                  }
                }
              });
              for (let i = 0; i < this.response.length; i++) {  
                for (let k = 0; k < this.bookings.length; k++) {
                if(this.bookings[k].tutor == this.$route.params.id && this.bookings[k].category == this.response[i].categoryId){
                  this.response[i].totalBookings += 1;
                  this.response[i].revenue += this.bookings[k].tutorlineEarning ? this.bookings[k].tutorlineEarning : 0
                }            
                }              
              }
            }else{
              this.isSpinner = false;
            }
            if(snapshot.size == index){
              this.isSpinner = false;
            } 
          });
      } catch (error) {
        console.error(error);
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "getFinalData",
          errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
        this.isSpinner = false;
      }
    },
    changeStatus(event, data) {
      try {
        let obj = null;
        let self = this;
        this.isProcess = true;  
        if (event == true) {
          obj = {
            status: 2,
          };
        }
        if (event == false) {
          obj = {
            status: 3,
          };
        }
        data.duplicatearray.forEach((stat) => {
          if(obj.status == 2){
            updateConnectedUserWhenSessionStatusChange({
            tutorId : this.$route.params.id,
            action : "add",
            categoryId : stat.categoryid
            },()=> {})
          }
          if (obj.status == 3) {
            updateConnectedUserWhenSessionStatusChange({
            tutorId : this.$route.params.id,
            action : "remove",
            categoryId : stat.categoryid
            },()=> {})
          }
          updateQueries.updateRootCollectionDataByDocId(
            dbCollections.SESSIONS,
            stat.sessionid,
            obj,
            (res) => {
              this.isProcess = false;
            }
          );
        });
        self.$root.$emit(
          "showToastMessage",
          "Status updated successfully.",
          "success"
        );
      } catch (err) {
        console.error(err, "error");
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "changeStatus",
          errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
        this.isProcess = false;
      }
    },
      onCategory() {
          var allTabList = document.getElementsByClassName("nav-link active");
          if (allTabList[0].innerText != "Categories") {
            this.getFinalData();
          }
        },
  },
};
</script>
