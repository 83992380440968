<template>
  <b-card class="doughnut-chart">
    <b-card-title class="mb-1">
      {{chartName}}
    </b-card-title>

    <vue-apex-charts
      type="donut"
      height="350"
      :options="options"
      :series="series"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import '@/@core/scss/base/plugins/charts/chart-apex.scss'
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  props:{
    options: Object,
    series: Array,
    chartName: String
  }
}
</script>
<style  scoped>
.card .card-body h4.card-title {
    border-bottom: 0px solid #ffff;
    padding: 0px 15px 15px;
    margin: 0px -20px 15px -20px;
}
</style>