<template>
  <div>
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed; z-index: 999999 !important;"
      />
    </div>

    <b-card class="createuser_form">
      <validation-observer ref="learnerEdit">
        <b-form>
          <b-row>        
            <b-col cols="1"> 
              <div class="upload-photo"> 
                  <div class="img-wrap" v-if="profileImg !== null && profileImg !== '' && file==null">
                      <img :src="profileImg" v-if='profileImg !== null && profileImg !== "" && file==null' alt="profile image" class="profileImage" width="88%" height="122px" />      
                  </div>  
                  <div class="upload-photo1123">        
                  <vs-upload
                    text="Upload" 
                    action="https://jsonplaceholder.typicode.com/posts/"
                    id="fileInput"
                    name="fileinput"
                    ref="img"
                    accept="image/*"
                    limit="1"
                    @change="CheckFile"
                    @on-delete="resetUploader"
                    :show-upload-button='false'           
                  >                 
                  </vs-upload>
                  </div>
                  <span class="required" v-show="profile_img_error !== ''">{{profile_img_error}}</span> 
              </div>              
            </b-col>
            <b-col cols="11">
              <b-row>
                <b-col md="3">
                  <b-form-group>       
                    <label label-for='First Name'>First Name<span class="required">*</span></label> 
                    <validation-provider
                      #default="{ errors }"
                      name="first name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="firstName"
                        :disabled='isSpinner || isUpdating'
                        placeholder="Enter First Name"
                        :maxlength='30'
                        @keypress="isOnlyCharacter($event)"
                        @copy.prevent 
                        @paste.prevent 
                        ondragover="return false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>       
                    <label label-for='Last Name'>Last Name<span class="required">*</span></label> 
                    <validation-provider
                      #default="{ errors }"
                      name="last name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="lastName"
                        :disabled='isSpinner || isUpdating'
                        placeholder="Enter Last Name"
                        :maxlength='30'
                        @keypress="isOnlyCharacter($event)"
                        @copy.prevent 
                        @paste.prevent 
                        ondragover="return false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group class="change_email">       
                    <label label-for="email">Email Address<span class="required">*</span></label>
                       <validation-observer ref="emailChange"> 
                          <validation-provider #default="{ errors }" name="email address" rules="" >
                          <b-form-input
                            v-model="userEmail" readonly 
                            type="email"
                            class="emailsize"
                            :disabled='isSpinner || isUpdating'
                            placeholder="Enter Email Address" 
                            :title='userEmail'                     
                          >
                          </b-form-input>
                           
                          <small class="text-danger" v-if='submitted'>{{ errors[0] }}</small>
                          </validation-provider>
                      </validation-observer>
                       <b-button v-if="!isEmailVerified"
                        v-on:click="emailChange"
                        :disabled="isEmailVerified"
                        v-b-modal.modal-add-credit
                        class="chnage_link"
                        >
                          change
                        </b-button>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                   <b-form-group>       
                    <label label-for="mobileno">Mobile No.<span class="required">*</span></label>  
                    <div class="mobileandcountry">   
                      <v-select
                              v-model="selectedCode"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="countryCodeList.allCountries"
                              @input="changeCountryCode(selectedCode)"
                              label="text"
                              placeholder="Select"
                              class="selectcountrynumber countrycodeuser"
                              disabled
                            >
                            <template #option="{ text }">
                              <div class="d-flex align-items-center justify-content-between">
                                <span>
                                  <span class="countrynumber"> {{ text }}</span>
                                </span>
                              </div>  
                            </template>
                      </v-select>            
                      <b-input-group class="number123">
                          <validation-provider
                            name="mobile no."
                            class="enternumber"
                          >                    
                          <b-form-input
                            placeholder="Enter Mobile No."
                            v-model="mobileNumber"
                            maxlength="15"
                            id='Mobile No.'
                            disabled
                          />                          
                          </validation-provider>  
                      </b-input-group>    
                    </div>
                   </b-form-group>               
                </b-col>
                <b-col md="3">
                  <b-form-group>       
                    <label label-for='dob'>Date of Birth</label> 
                    <flat-pickr
                     v-model="birthDate"
                      class="form-control datetime_picker"
                      placeholder="DD/MM/YYYY"
                      :disabled='isSpinner || isUpdating'
                      @on-change="doSomethingOnChange"
                      :config="{ 
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                        minDate: new Date('01/01/'+`${parseInt(new Date().getFullYear()) - 100}`),
                        maxDate: new Date().fp_incr(-1095)
                      }"
                    />   
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>       
                    <label label-for='gender'>Gender<span class="required">*</span></label> 
                    <div class="demo-inline-spacing schedulecampaign_radios">
                      <b-form-radio
                        v-model="gender"
                        name="some-radios"
                        value="male"
                      >
                        Male
                      </b-form-radio>
                      <b-form-radio
                        v-model="gender"
                        name="some-radios"
                        value="female"
                      >
                        Female
                      </b-form-radio>
                      <b-form-radio
                        v-model="gender"
                        name="some-radios"
                        value="other"
                      >
                        Other
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </b-col>
                 <b-col md="3">
                  <b-form-group>       
                    <label label-for='usertype'>User Type</label> 
                    <b-form-tags
                      v-model="userRole"
                      :disabled='true'
                      input-id="tags-basic"
                      placeholder=""
                      class="inputHide textchange height"
                      :remove-on-delete='false'
                      :no-add-on-enter='true'
                      readonly
                      :disableAddButton='true'
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>       
                    <label label-for='location'>Location</label> 
                    <vue-google-autocomplete
                    id="map2"
                    ref="toAddress"
                    classname="form-control"
                    placeholder="Enter Location"
                    v-on:placechanged="getAddressData"
                    types="(cities)"
                  >
                  </vue-google-autocomplete>
                  </b-form-group>
                </b-col>
                <b-col md="3" class="tutor_type_values">
                  <b-form-group>       
                    <label label-for='timezone'>Time Zone</label>
                    <v-select
                      v-model="timeZone"
                      :disabled='isSpinner || isUpdating'
                      :clearable='false'
                      label="text"
                      placeholder='Select'
                      :options="timezoneoptions"
                      class="language12"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3" class="tutor_type_values">
                  <b-form-group>       
                    <label label-for='school'>School</label> 
                    <v-select
                      v-model="schololSelectedValue"
                      :disabled='isSpinner || isUpdating'
                      :clearable='false'
                      label="text"
                      placeholder='Select'
                      :options="SchoolOptions"
                      class="language12"
                      @input="schololVal = schololSelectedValue.value"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3" class="tutor_type_values">
                  <b-form-group>       
                    <label label-for='syllabus'>Curriculum</label> 
                    <v-select
                      v-model="syllabusSelectedVal"
                      :disabled='isSpinner || isUpdating'
                      :clearable='false'
                      label="text"
                      placeholder='Select'
                      :options="SyllabusOptions"
                      class="language12"
                      @input="syllabusVal = syllabusSelectedVal.value , gradeDisplay(syllabusVal)"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="3" class="tutor_type_values">
                <b-form-group label="Grade" label-for="grade">
                    <v-select
                      v-model="gradeSelectedVal"
                      :disabled='disableGrade'
                      :clearable='false'
                      label="text"
                      placeholder='Select'
                      :options="gradeOptions"
                      class="language12"
                      @input="gradeSelected = gradeSelectedVal.value"
                    />
                  </b-form-group>
                </b-col>
                 <b-col md="3">
                  <b-form-group>
                    <label>Status</label>
                    <b-dropdown
                      id="dropdown-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :text="defaultStatus.text"
                      variant="outline-secondary"
                      class="dropdownchange12313 statusdropdown"
                    >
                      <b-dropdown-item 
                        v-for="data in UserStatusOprtion"
                        :key="data.value"
                        class="dropdownchange"
                        @click="showModelStatus(data)"
                      >{{data.text}}</b-dropdown-item>
                    </b-dropdown>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>       
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
     <b-modal
      id="modal-change-email"
      ref="my-modal"
      title="Email Change"
      ok-title="Change"
      cancel-title="Cancel"
      cancel-variant="outline-primary"
      centered
      data-class="addcredit_popup"
      @ok="editEmail"
      :ok-disabled="isUpdating"
      :cancel-disabled="isUpdating"
    >
      <b-form-group>       
        <label label-for="Email Address">Email Address<span class="required">*</span></label>
        <validation-observer ref="editEmailref">
          <validation-provider
          ref="changeEmail"
          #default="{ errors }"
          name="email address"
          rules="required|email|max:100"
        >
            <b-form-input
              v-model="emailValue"
              type="email"
              :maxlength='100'
              placeholder="Enter Email Address"
              @keypress="isEmailExist=false"
            />
            <small class="text-danger">{{ errors[0] }}</small>
            <small class="text-danger" v-show="!errors[0] && isEmailExist">The email address is already exist</small>
          </validation-provider>
        </validation-observer>  
      </b-form-group>
    </b-modal>
    <b-modal
      id="modal-block"
      centered
      title=""
      ok-title="Yes"
      cancel-title="No"
      @ok="blockUser"
    >
      <img
        src="@/assets/images/erflog/cancel.png"
        alt="profile"
        class="warning_image"
      />
      <h4>Block User</h4>
      <b-card-text>
        Are you sure you want to Block User? They won't be able to access Tutorline anymore.
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-suspend"
      centered
      title=""
      ok-title="Yes"
      cancel-title="No"
      @ok="suspendTutorUser"
    >
      <img
        src="@/assets/images/erflog/cancel.png"
        alt="profile"
        class="warning_image"
      />
      <h4>Suspend User</h4>
      <b-card-text>
        Are you sure you want to Suspend User? They won't be able to access Tutorline anymore.
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-active"
      centered
      title=""
      ok-title="Yes"
      cancel-title="No"
      @ok="activateUser"
    >
      <img
        src="@/assets/images/tutorimages/approve.png"
        alt="profile"
        class="approve_image"
      />
      <h4>Active</h4>
      <b-card-text> Are you sure you want to Active user? </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from 'vue-ripple-directive'
import {
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormCheckbox,
  BFormRadio,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroupAppend,
  BFormDatepicker,
  BFormFile,
  BFormSelect,
  BFormTags,
  BSpinner,
  BDropdown, 
  BDropdownItem 
} from "bootstrap-vue";
import { required } from "@validations";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapGetters } from 'vuex';
import moment from 'moment';
import "moment-timezone";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { uploadImageFile, removeImageThroughURL } from '@/utils/FirebaseQueries/storageQuery/storageQueries'
import * as update from '@/utils/FirebaseQueries/updateQueries/updateQueries'
import * as getQueries from '@/utils/FirebaseQueries/getQueries/getQueries'
import * as insertFirebase from "@/utils/FirebaseQueries/insertQueries/insertQueries.js";
import firebaseData from "firebase";
import { dbCollections } from '@/utils/firebaseCollection';
import firebase from "@/utils/firebaseInit.js"
const db = firebase.firestore()
import countryTelData from 'country-telephone-data';
import VueGoogleAutocomplete from "vue-google-autocomplete";
import {ValidationFunction} from '@/utils/globalValidations';
import * as updateFirebase from '@/utils/FirebaseQueries/updateQueries/updateQueries'
import suspendActionFun from '@/utils/globalFunction/suspendActionFun';
import functionForSuspendOrBlockChild from '@/utils/globalFunction/suspendChildFunction'
import functionForActiveChild from '@/utils/globalFunction/activeChild'
import timeZoneData from '@/utils/TimeZoneData.js'
import axios from "axios";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Users/User/Edituser.vue"
import {createUserEmail} from './service.js'
import {sendNotificationUserStatus} from '../../service.js'
import {blockUser} from '../../ComplaintManagement/service.js'
import {unblockUser} from '../../ComplaintManagement/serviceUnblock.js'
import {verifyUser} from './serviceVerifyUser.js'
export default {
  components: {
    BDropdown, BDropdownItem,
    ValidationProvider,
    VueGoogleAutocomplete,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BCard,
    flatPickr,
    BFormRadio,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea,
    BInputGroupAppend,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BFormTags,
    VuePhoneNumberInput, 
    BSpinner,
    vSelect
  },
  directives:{
    Ripple
  },
  data() {
    return {
      selectedDob:'',
      uploadedProfile:'',
      uploadedData:null,
      countryCodeList: countryTelData,
      selectedDialCode:'',
      dialCodeObj:null,
      direction:false,
      isSpinner:false,
      isUpdating:false,
      file:null,
      gender:'male',
      required,      
      submitted:false,
      MobileNo_Error:'',
      countryCode:null,
      mobileNumber:"",
      profile_img_error:'',
      profileImg:null,  
      profileImgName:'',
      birthDate:'',
      defaultCode:'', 
      userRole:[],
      location:'',
      timeZone:'',
      schololVal:null,
      syllabusVal:null,
      firstName:'',
      lastName:'',
      userEmail:'',
      profilePicture:'',
      previewImg:null,
      status:null,
      isEmailExist:false,
      emailValue:"",
      isEmailVerified:false,
      domainLink:process.env.VUE_APP_FIREBASE_API_URL_DOMAINLINK,
      defaultStatus: {
        value:null,
        text: "Select"
      },
      selectedCode:'',
      selectedCountryCode:{
          dialCode: "1",
          format: "+. (...) ...-....",
          iso2: "us",
          name: "United States",
          priority: 0,
          text: "+1(United States)",
          value: "+1"
      },
      gradeSelected: null,
      gradeOptions: [
        {value:null,text:"Select",selected:true,disabled: true}
      ],
      disableGrade:false,
      schololSelectedValue: null,
      syllabusSelectedVal:null,
      gradeSelectedVal: null,
      timeZoneSelected: null,
      editObject:null,
    };
  },
  created(){
    var self = this;
    self.$root.$emit('stopSpinner');
    try{
    let tempDialCode = self.countryCodeList.allCountries;
    tempDialCode.forEach((vals,index)=>{
      vals.text ="+"+vals.dialCode+"("+vals.name+")";
      vals.value = '+'+vals.dialCode;
    }) 
    }catch(error){
      console.error(error,'error');
      errorEmail({
        projectName : process.env.VUE_APP_PROJECTNAME,
        environment : process.env.NODE_ENV,
        fileName : currentFileName,
        hookName : "created",
        errorMessage : error.message
      },(res)=>{
          if(res.error){
              console.error(res.error)
          }
      })
    }
    self.$root.$on('editSimpleUser',()=>{
      self.validationForm();
    })
    self.userDetailes();
  }, 
  computed: {
    timezoneoptions(){
    let timezone = timeZoneData;
    let timezoneoptions = [];
    for (let i = 0; i < timezone.length; i += 1) {
        timezoneoptions.push(`${timezone[i]} (${moment().tz(timezone[i]).format('Z z')})`)
    }
   timezoneoptions.push({value:null,text:"Select",selected:true,disabled: true})
    return timezoneoptions
    },
    ...mapGetters({
      schools:'settings/getSchools',
      syllabus:'settings/getSyllabus',
      getStatusList:'status/getStatus'}),   
    SchoolOptions(){
        try {
            var arr = [{value:null,text:"Select",selected:true,disabled: true}];
            this.schools.forEach(school => {
                var schoolObj = {
                    value: school.id,
                    text:school.schoolName                      
                }
                arr.push(schoolObj)
            });
            return arr
        } catch (error) {
            console.error("error",error)
            errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "SchoolOptions",
              errorMessage : error.message
            },(res)=>{
                if(res.error){
                    console.error(res.error)
                }
            })
            return arr;
        }
    },
    SyllabusOptions(){
        try {
            var arr = [{value:null,text:"select",selected:true,disabled: true}];
            this.syllabus.forEach(syl => {
                var syllabusObj = {
                    value: syl.id,
                    text:syl.syllabusName                      
                }
                arr.push(syllabusObj)
            });
            return arr
        } catch (error) {
            console.error("error",error)
            errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "SyllabusOptions",
              errorMessage : error.message
            },(res)=>{
                if(res.error){
                    console.error(res.error)
                }
            })
            return arr;
        }
    }, 
    UserStatusOprtion(){
       try {
            var arr =[] 
            let statusObj = this.getStatusList.userStatus;
            for (const property in statusObj) {
              var syllabusObj = {
                  value: statusObj[property].status.toLowerCase(),
                  text: statusObj[property].status,
                  variant: statusObj[property].variant,
                  key: property
              }
              arr.push(syllabusObj)
            }      
            return arr
        } catch (error) {
            console.error("error",error);
            return arr;
        }
    },   
  },
  methods: {
     changeCountryCode(country){
      
      if(country !== null && country != ''){
        this.selectedCountryCode = country;
        this.selectedCode = country.value;
        this.checkPhoneNumber(this.mobileno);
      }else{
        this.selectedCode = '+1'
      }
    },
    gradeDisplay(id){
      var self = this;
      self.gradeOptions = [];
      self.gradeSelected = null;
      self.gradeSelectedVal = null;
      self.disableGrade = false;
      self.gradeOptions.push({value: null,text:"Select",disabled:true});
      db.collection(dbCollections.SETTINGS).doc('setting').collection(dbCollections.GRADE).where("curriculumId","==",id).get().then((gradeRes)=>{
        if (!gradeRes.empty) {
          gradeRes.forEach((data)=>{
            self.gradeOptions.push({value:data.data().id , text:data.data().gradeName});
          })
        }else {
            self.disableGrade = true;
            self.gradeSelected = null;
            self.gradeSelectedVal = null;
        }
      }).catch((error)=>{
        console.error(error);
      })
    },
    showModelStatus(status){
        if(this.defaultStatus.value !==  status.value) { 
            this.$bvModal.show(`modal-${status.value}`);
        }
    },
    activateUser() { 
        this.isUpdating = true;
        try {
            if(Number(this.defaultStatus.key) === 0) {
                verifyUser({
                      uid: this.$route.params.id
                },(res) => {
                   if(res && res.status) {
                      updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS, this.$route.params.id, {
                          status: 1,
                          updatedAt: new Date()
                      }, () => {
                        let getIndex = this.UserStatusOprtion.findIndex((values)=>{
                            return Number(values.key) === 1  
                        })
                        if(getIndex > -1){
                            this.defaultStatus = this.UserStatusOprtion[getIndex];
                        }
                        let removeIndex = this.UserStatusOprtion.findIndex((value) => {
                            return value.key === "0" //pending
                        });
                        this.UserStatusOprtion.splice(removeIndex , 1);
                        this.isUpdating = false;
                          sendNotificationUserStatus({
                            uid: this.$route.params.id,
                            userStatus: "Active"
                          },() => {})
                          this.$root.$emit(
                            "showToastMessage",
                            "User activated successfully.",
                            "success"
                          );
                        });
                    }else {
                        this.status = this.defaultStatus.text;
                        this.isUpdating = false;
                        this.$root.$emit(
                          "showToastMessage",
                          "User not activated.",
                          "danger"
                        );
                    }
                })
            } else if (Number(this.defaultStatus.key) === 3) {
                functionForActiveChild(this.$route.params.id,false,()=>{})
                unblockUser({
                  uid: this.$route.params.id
                },(res) => {
                  if(res && res.status){
                    updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS, this.$route.params.id, {
                        status: 1,
                        updatedAt: new Date()
                    }, () => {
                      let getIndex = this.UserStatusOprtion.findIndex((values)=>{
                          return Number(values.key) === 1  
                      })
                      if(getIndex > -1){
                          this.defaultStatus = this.UserStatusOprtion[getIndex];
                      }
                      let addStatusObj = {
                        value: this.getStatusList.userStatus[2].status.toLowerCase(),
                        text: this.getStatusList.userStatus[2].status,
                        key: "2",
                        variant: this.getStatusList.userStatus[2].variant
                      }
                      this.UserStatusOprtion.push(addStatusObj);
                      this.isUpdating = false;
                      sendNotificationUserStatus({
                          uid: this.$route.params.id,
                          userStatus: "Active"
                      },() => {})
                      this.$root.$emit(
                        "showToastMessage",
                        "User activated successfully.",
                        "success"
                      );
                    });
                } else {
                    this.status = this.defaultStatus.text;
                    this.isUpdating = false;
                    this.$root.$emit(
                      "showToastMessage",
                      "User not activated.",
                      "danger"
                    );
                }
              })
            } else if(Number(this.defaultStatus.key) === 2) {
                updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS, this.$route.params.id, {
                      status: 1,
                      updatedAt: new Date()
                  }, () => {
                functionForActiveChild(this.$route.params.id,true,()=>{})
                      let getIndex = this.UserStatusOprtion.findIndex((values)=>{
                                          return Number(values.key) === 1  
                                      })
                      if(getIndex > -1){
                          this.defaultStatus = this.UserStatusOprtion[getIndex];
                      } 
                      this.isUpdating = false;
                      sendNotificationUserStatus({
                          uid: this.$route.params.id,
                          userStatus: "Active"
                      },() => {})
                      this.$root.$emit(
                        "showToastMessage",
                        "User activated successfully.",
                        "success"
                      );
                  });
            }
        } catch (error) {
            console.error(error);
            this.isUpdating = false;
            errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "activateUser",
              errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
            })
        }
    },
    suspendTutorUser(){
      this.isUpdating = true;
      try {
          updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS, this.$route.params.id, {
              status: 2,
              suspendUserTime: new Date(),
              updatedAt: new Date()
          }, () => {
              suspendActionFun(this.$route.params.id).then(() => {
                functionForSuspendOrBlockChild(this.$route.params.id,true,false,()=>{})
                  let getIndex = this.UserStatusOprtion.findIndex((values)=>{
                                      return Number(values.key) === 2  
                                  })
                  if(getIndex > -1){
                      this.defaultStatus = this.UserStatusOprtion[getIndex];
                  }
                  this.isUpdating = false;
                  sendNotificationUserStatus({
                    uid: this.$route.params.id,
                    userStatus: "Suspend"
                  },() => {})
                  this.$root.$emit(
                    "showToastMessage",
                    "User suspended successfully.",
                    "success"
                  );
              }).catch((error) => {
                  this.status = this.defaultStatus.text;
                  this.isUpdating = false;
                  console.error(error);
                  this.$root.$emit(
                    "showToastMessage",
                    "User not suspended.",
                    "danger"
                  );
              })
          });
      } catch (error) {
          console.error(error);
          this.isUpdating = false;
          errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "suspendTutorUser",
            errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          })
      }
    },
    blockUser(){
      this.isUpdating = true;
      console.log("Block user call");
      try {
          if(Number(this.defaultStatus.key) === 2) {
              updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,this.$route.params.id, {
                  status:3,
                  updatedAt: new Date()
              }, () => {
                    functionForSuspendOrBlockChild(this.$route.params.id,false,true,()=>{})
                  blockUser({
                    uid: this.$route.params.id
                  },(res) => {
                    if(res && res.status){
                      let getIndex = this.UserStatusOprtion.findIndex((values)=>{
                          return Number(values.key) === 3  
                      })
                      if(getIndex > -1){
                          this.defaultStatus = this.UserStatusOprtion[getIndex];
                      }
                      let removeIndex = this.UserStatusOprtion.findIndex((value) => {
                          return value.key === "2" //suspend
                      });
                      this.UserStatusOprtion.splice(removeIndex , 1);
                      this.isUpdating = false;
                        sendNotificationUserStatus({
                            uid: this.$route.params.id,
                            userStatus: "Block"
                        },() => {})
                        this.$root.$emit(
                          "showToastMessage",
                          res.statusText,
                          "success"
                        );
                    }else{
                      this.status = this.defaultStatus.text;
                      this.isUpdating = false;
                      this.$root.$emit(
                        "showToastMessage",
                        res.statusText,
                        "danger"
                      );
                    }
                  })
              })
          } else {
              updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS, this.$route.params.id, {
                  status:3,
                  updatedAt: new Date()
              }, () => {
                  suspendActionFun(this.$route.params.id)
                  .then(() => {
                    functionForSuspendOrBlockChild(this.$route.params.id,false,false,()=>{})
                      blockUser({
                        uid: this.$route.params.id
                      },(res) => {
                        if(res && res.status){
                          let getIndex = this.UserStatusOprtion.findIndex((values)=>{
                              return Number(values.key) === 3  
                          })
                          if(getIndex > -1){
                              this.defaultStatus = this.UserStatusOprtion[getIndex];
                          }
                          let removeIndex = this.UserStatusOprtion.findIndex((value) => {
                              return value.key === "2" //suspend
                          });
                          this.UserStatusOprtion.splice(removeIndex , 1);
                          this.isUpdating = false;
                        sendNotificationUserStatus({
                            uid: this.$route.params.id,
                            userStatus: "Block"
                        },() => {})
                        this.$root.$emit(
                          "showToastMessage",
                          res.statusText,
                          "success"
                        );
                        } else {
                            this.status = this.defaultStatus.text;
                            this.isUpdating = false;
                            this.$root.$emit(
                              "showToastMessage",
                              res.statusText,
                              "danger"
                            );
                        }
                      })
                  }).catch((error) => {
                      console.error(error);
                      this.status = this.defaultStatus.text;
                      this.isUpdating = false;
                      this.$root.$emit(
                        "showToastMessage",
                        "User not suspended.",
                        "danger"
                      );
                  })
              })
          }
      } catch (error) {
          console.error(error);
          this.isUpdating = false;
          errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "blockUser",
            errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          })
      }
    },
    userDetailes(){
      let self=this;
      try{  
        db.collection(dbCollections.USERS).doc(this.$route.params.id).get().then((doc) => {
            if (doc.exists) {                 
              doc.data().id = doc.id; 
              doc.data().userRandomId = doc.data().id;
              let resultFinal = doc.data();
              if(resultFinal.countryCodeData){         
              self.defaultCode = resultFinal.countryCodeData ? resultFinal.countryCodeData : 'IN';
              }
              if(resultFinal.profilePicture !== undefined && resultFinal.profilePicture !== '' && resultFinal.profilePicture !== null){   
                self.profileImg = resultFinal.profilePicture;  
                self.profileImgName = resultFinal.profilePicture;
                self.profilePicture = resultFinal.profilePicture;
              }else{
                self.profilePicture = null;
              }
              self.mobileNumber = resultFinal.phoneNumber.toString();
              self.selectedCode =  '+' + resultFinal.countryCode
              self.selectedDialCode = '+' + resultFinal.countryCode;
              self.birthDate = resultFinal.dateOfBirth;
              resultFinal = resultFinal;
              resultFinal.schololVal = null;
              resultFinal.dispGender = resultFinal.gender.toLowerCase();
              self.gender = resultFinal.dispGender;
              resultFinal.dispDob = null;
              resultFinal.syllabusVal = null;            
              self.userRole = resultFinal.userRole;
              self.isEmailVerified = resultFinal.isEmailVerified;
              this.$refs.toAddress.autocompleteText = resultFinal.location ? resultFinal.location : "";
              self.location = resultFinal.location; 
              self.timeZone = resultFinal.timeZone;
              if(self.birthDate !== undefined && self.birthDate !== '' && self.birthDate !== null){
                self.birthDate = resultFinal.dateOfBirth.seconds ? moment(new Date(resultFinal.dateOfBirth.seconds * 1000)).format('DD/MM/YYYY') : moment(new Date(resultFinal.dateOfBirth)).format('DD/MM/YYYY');
              }
              if(resultFinal.schoolDetails && resultFinal.schoolDetails !== '' && Object.keys(resultFinal.schoolDetails).length > 0){
                resultFinal.schololVal = resultFinal.schoolDetails.id ? resultFinal.schoolDetails.id : null; 
                let schoolIndex = self.SchoolOptions.findIndex((data) => {
                    return data.value == resultFinal.schoolDetails.id
                })
                self.schololSelectedValue = self.SchoolOptions[schoolIndex];
              }
              if(resultFinal.syllabusDetails && resultFinal.syllabusDetails !== '' && Object.keys(resultFinal.syllabusDetails).length > 0){
                
                resultFinal.syllabusVal = resultFinal.syllabusDetails.id ? resultFinal.syllabusDetails.id  : null
                let syllabusIndex = self.SyllabusOptions.findIndex((data) => {
                    return data.value == resultFinal.syllabusDetails.id
                })
                self.syllabusSelectedVal = self.SyllabusOptions[syllabusIndex];
                this.gradeDisplay(resultFinal.syllabusDetails.id);
                setTimeout(()=>{
                    let gradeIndex = self.gradeOptions.findIndex((valGrade) => {
                        return valGrade.value == resultFinal.grade
                    })
                    self.gradeSelectedVal = self.gradeOptions[gradeIndex];
                },1000)
              } else {
              self.disableGrade = true;
              self.gradeSelected = null;
              self.gradeSelectedVal = null;
              }
              self.userEmail = resultFinal.email;
              self.schololVal = resultFinal.schololVal;
              self.syllabusVal = resultFinal.syllabusVal;
              self.firstName = resultFinal.firstName;
              self.lastName = resultFinal.lastName
              self.editObject = {
                firstNameBefore: resultFinal.firstName,
                lastNameBefore: resultFinal.lastName,
                profileImageBefore: resultFinal.profilePicture
              }
              var edit = {
                name :  self.firstName + " " + self.lastName,
                role : self.userRole,
                status: doc.data().status
              }
              self.$root.$emit('editUserDetailForName', edit);
              let tempStatus = '';
              let tempVariant = '';
              if( resultFinal.status !== undefined){
                let getIndex = self.UserStatusOprtion.findIndex((values)=>{
                  return values.key ==  resultFinal.status
                })
                if(getIndex > -1){
                  tempStatus = self.UserStatusOprtion[getIndex].text;
                  tempVariant = self.UserStatusOprtion[getIndex].variant;
                  self.status = self.UserStatusOprtion[getIndex].text;
                  self.defaultStatus = self.UserStatusOprtion[getIndex];
                    if(self.defaultStatus.key !== "0") {
                        let pendingIndex = self.UserStatusOprtion.findIndex((value) => {
                            return value.key === "0"
                        });
                        self.UserStatusOprtion.splice(pendingIndex , 1);
                    }
                    if(self.defaultStatus.key === "3") { //user is blocked
                        let removeIndex = self.UserStatusOprtion.findIndex((value) => {
                            return value.key === "2" //suspend
                        });
                        self.UserStatusOprtion.splice(removeIndex , 1);
                    }
                }
              }
            }
          }).catch((error)=>{
            console.error(error,"error")
          })
      }catch(error){
        console.error(error,"error");
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "userDetailes",
          errorMessage : error.message
        },(res)=>{
            if(res.error){
                console.error(res.error)
            }
        }) 
      } 
    },
    resetUploader(){
      try{
        this.profile_img_error='';
        this.file = null;
        this.uploadedData=null; 
      }catch(error){
        console.error(error,"error******************");
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "resetUploader",
          errorMessage : error.message
        },(res)=>{
            if(res.error){
                console.error(res.error)
            }
        }) 
      }
    },
    validationForm() {
      var self = this;
      try{        
        self.submitted = true;
        if(self.$refs.learnerEdit){
        self.$refs.learnerEdit.validate().then((success) => {
          if(success && self.MobileNo_Error == '' && self.profile_img_error == '' && self.isSpinner == false){
            var updatedObj = null;
            const updateFinalProfile = new Promise((resolve,reject)=>{
              try{
                self.isSpinner = true;                
                let dateOfBirth = '';
                if(self.selectedDob !== undefined && self.selectedDob !== ''){
                  dateOfBirth = new Date(self.selectedDob);
                }   
                let genderName = '';
                if(self.gender.toLowerCase() == 'male')  {
                  genderName = 'Male'
                }if(self.gender.toLowerCase() == 'female')  {
                  genderName = 'Female'
                }if(self.gender.toLowerCase() == 'other')  {
                  genderName = 'Other'
                }
                let fname = self.firstName ? self.firstName : '';
                let lname = self.lastName ? self.lastName : '';
                let fullname = fname.toLowerCase() +' '+ lname.toLowerCase();
                let schoolVal = null;
                let schoolId = '';
                let sylId = '';
                let sylabusVal = null;
                let gradeVal = null;
                let schoolInd = self.SchoolOptions.findIndex((ele)=>{   
                  if(ele.value !== null){                 
                    return ele.value == self.schololVal
                  }
                })
                if(schoolInd > -1){        
                  schoolVal = {
                  'id': self.SchoolOptions[schoolInd].value,
                  'schoolName':self.SchoolOptions[schoolInd].text
                  };
                  schoolId = self.SchoolOptions[schoolInd].value
                }
                let syllabusInd = self.SyllabusOptions.findIndex((ele)=>{
                  if(ele.value !== null){
                    return ele.value == self.syllabusVal
                  }
                })
                if(syllabusInd > -1){        
                  sylabusVal = self.SyllabusOptions[syllabusInd] 
                  sylabusVal =  {
                  'id': self.SyllabusOptions[syllabusInd].value,
                  'syllabusName':self.SyllabusOptions[syllabusInd].text
                  };
                  sylId = self.SyllabusOptions[syllabusInd].value;   
                }
                let tempStatus = '';
                let getStatus = self.UserStatusOprtion.findIndex((item)=>{
                  if(self.status && self.status !== ''){
                    return item.value == self.status.toLowerCase()
                  }                 
                })
                if(getStatus > -1){
                  tempStatus = parseInt(self.UserStatusOprtion[getStatus].key)
                }
                    if (self.gradeSelected !== null) {
                      let gradeInd = this.gradeOptions.findIndex((ele)=>{
                        if(ele.value !== null && self.gradeSelected !== null){
                          return ele.value == self.gradeSelected
                        }
                      })
                      if (gradeInd > -1) {
                           gradeVal = {
                          'id': self.gradeOptions[gradeInd].value,
                          'gradeName': self.gradeOptions[gradeInd].text                            
                        }  
                      }
                    }

                updatedObj = {
                  "profilePicture":"",
                  "firstName":self.firstName ? self.firstName :'',
                  "lastName":self.lastName ? self.lastName : '',                  
                  'dateOfBirth': dateOfBirth ? dateOfBirth : '',
                  'gender':genderName ? genderName : 'Male',
                  'location':self.location ? self.location : '',
                  'timeZone':self.timeZone ? self.timeZone : '',
                  'name': fullname ? fullname : '',
                  'school':schoolId,
                  'schoolDetails':schoolVal,
                  'syllabus':sylId,
                  'syllabusDetails':sylabusVal,
                  'updatedAt':firebaseData.firestore.FieldValue.serverTimestamp(), 
                  'userRole':self.userRole,
                  'gradeDetail': gradeVal,
                  'grade': self.gradeSelected
                }     
                if(self.file && self.file !== null){
                  if(self.profilePicture !== undefined && self.profilePicture !== '' && self.profilePicture !== null){
                    let imageName = self.profilePicture;
                    if(imageName){
                      removeImageThroughURL(imageName, callback=>{
                      })  
                    }
                  }                 
                  uploadImageFile("userManagement",self.file.name,self.file,res=>{
                    resolve({status:true,msg:'success','data':res})
                  })
                }else{
                  resolve({status:true,msg:'done','data':self.profilePicture})
                }
              }catch(error){
                self.isSpinner = false;
                reject({'status':false,'data':error})
              }
            })
            updateFinalProfile.then(values=>{            
              try{
                self.isSpinner = true;                
                let imageData = self.profilePicture ? self.profilePicture : '';
                updatedObj.profilePicture=imageData ? imageData : '';
                if(values.status == true && values.msg == 'success'){
                  imageData = values.data;
                  updatedObj.profilePicture=imageData.imageUrl ? imageData.imageUrl : '';
                }
                if(self.userRole.includes('tutor') == true){
                  updatedObj.tutorStatus = 0
                }
                let uid = self.$route.params.id.toString().trim()
                update.updateRootCollectionDataByDocId(dbCollections.USERS,uid,updatedObj,res=>{
                  if (updatedObj.firstName !== self.editObject.firstNameBefore || updatedObj.lastName !== self.editObject.lastNameBefore|| updatedObj.profilePicture !== self.editObject.profileImageBefore ) {
                    let updateObjInCollection = {}
                    if(updatedObj.firstName !== self.editObject.firstNameBefore){
                      updateObjInCollection.firstName = updatedObj.firstName
                    }
                    if (updatedObj.lastName !== self.editObject.lastNameBefore) {
                       updateObjInCollection.lastName = updatedObj.lastName 
                    }
                    if (updatedObj.profilePicture !== self.editObject.profileImageBefore) {
                      updateObjInCollection.profilePicture = updatedObj.profilePicture
                    }
                    updateObjInCollection.userId = self.$route.params.id
                    updateObjInCollection.createdAt = new Date()     
                    getQueries.simpleGetDataWithSpecificWhereCondition(dbCollections.UPDATERECORDS, "userId", '==',self.$route.params.id, (res) => {
                      if (res.length) {
                        updateFirebase.updateRootCollectionDataByDocId(dbCollections.UPDATERECORDS,res[0].id,updateObjInCollection, () => {
                        })
                      } else {
                        insertFirebase.addDataToRootCollection(dbCollections.UPDATERECORDS,updateObjInCollection, (update) => {
                            if (update) {
                              let updateObj = {
                                    id : update.id
                              }
                              updateFirebase.updateRootCollectionDataByDocId(dbCollections.UPDATERECORDS,update.id,updateObj, () => {
                              })
                            }
                        })
                      }
                    })
                  }
                  self.isSpinner = false;
                  self.$root.$emit('showToastMessage','User updated. It will be reflected in whole system after some hours.','success');
                  self.$root.$emit('stopSpinner');
                  self.$router.replace({path:'/user-management/users'})
                })
              }catch(error){
                console.error(error)
                self.isSpinner = false;
                self.$root.$emit('stopSpinner')
              }        
            }).catch(error=>{
              self.isSpinner = false;
              self.$root.$emit('stopSpinner');
              self.$root.$emit('showToastMessage','Please try again later.','danger');
              console.error(error,"ReferenceError")  
            })          
          }else if(success == false || self.MobileNo_Error != '' || self.profile_img_error !== ''){
            self.isSpinner = false;
            self.$root.$emit('stopSpinner');
          }
        });
        }else{
          self.isSpinner = false;
          self.$root.$emit('stopSpinner');
        }
      }catch(error){
        self.isSpinner = false;
        self.$root.$emit('stopSpinner');
        console.error(error);
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "validationForm",
          errorMessage : error.message
        },(res)=>{
            if(res.error){
                console.error(res.error)
            }
        }) 
      }
    },
    CheckFile(){
      var self = this; 
      let ind = self.$refs.img.filesx.length - 1;
      let e = self.$refs.img.filesx[ind]; 
      self.file = e;
      try{ 
        if(e && e !== null && self.isSpinner == false){   
          if(e.type == 'image/png' || e.type == 'image/jpg' || e.type == 'image/jpeg'||e.type == 'jpg' || e.type =='png' ||e.type == 'jpeg'){
            self.profile_img_error = '';
            var reader = new FileReader();
            reader.onload = (event) => {
              self.uploadedData = event.target.result;
            }
            reader.readAsDataURL(e);
          }else{
            self.file = null
            self.uploadedData = null;
            self.profile_img_error = 'Only JPG| JPEG| PNG File Upload Allowed'
          }  
        }
      }catch(error){
        self.file = null;
        self.uploadedData = null;
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "CheckFile",
          errorMessage : error.message
        },(res)=>{
            if(res.error){
                console.error(res.error)
            }
        })
      } 
    },  
    removePreviouseImage(){
      var self = this;
      self.isUpdating = true; 
      try{
        let imageName = self.profilePicture;
        if(imageName){
          removeImageThroughURL(imageName, callback=>{
            let updatedObj={
              profilePicture:''
            }
            let uid = self.$route.params.id.toString().trim()
            update.updateRootCollectionDataByDocId(dbCollections.USERS,uid,updatedObj,res=>{
              self.isUpdating = false;
              self.profileImg = ''
            })            
          })            
        }
      }catch(error){
        self.isUpdating = false; 
        console.error(error,"error")
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "removePreviouseImage",
          errorMessage : error.message
        },(res)=>{
            if(res.error){
                console.error(res.error)
            }
        })
      }
    },
    /** fname and lname validation **/
    isOnlyCharacter(event) {
      ValidationFunction.OnlyCharacter(event);
    },
    /** mobile number validation **/
    isOnlyNumber(event){
      ValidationFunction.OnlyNumber(event);
    },
    doSomethingOnChange(event){
      if(event !== null){
      let lastInd = parseInt(event.length) - 1;
      this.selectedDob = event[lastInd];
      }
    },
    getAddressData(addressData, placeResultData, id){
         this.location = placeResultData.formatted_address
    },
    emailChange(){
     this.emailValue = ""
     this.isEmailExist = false;
     this.$bvModal.show('modal-change-email');
    },
    editEmail(bvModalEvt) {
      bvModalEvt.preventDefault();
      var self = this;
      self.isUpdating = true;
        self.$refs.editEmailref.validate().then((valid) => { 
           if (valid) {
            try{
              getQueries.simpleGetDataWithSpecificWhereCondition(dbCollections.USERS, "email", '==', self.emailValue, (email) => {
                if (email.length) {
                  self.isEmailExist = true;
                  self.isUpdating = false;
                   return;
                }
                else {
                  self.isEmailExist = false;
                    createUserEmail({
                      email: self.emailValue,
                      userName: self.firstName + " " + self.lastName,
                      domainLink: self.domainLink,
                      uid: self.$route.params.id,
                      editEmail: true,
                    },(res) => {
                        if (res.status) {
                           self.isUpdating = false;
                            self.userEmail = self.emailValue
                            self.$root.$emit('showToastMessage','Email change successfully.','success');
                        }else{
                            self.isUpdating = false;
                            self.$root.$emit('showToastMessage',res.data.statusText,'success');
                        }
                        self.$bvModal.hide('modal-change-email')
                    })
                }
              })
            }catch (error) {
             self.isUpdating = false;
              console.error(error);
              errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "editEmail",
                errorMessage : error.message
              },(res)=>{
                  if(res.error){
                      console.error(res.error)
                  }
              })
            }
            
           } else {
              self.isUpdating = false;
           }
         })
    },
    EditEmailhandleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault();
    }    
  },
  beforeRouteLeave(from,to,next){
   var self = this;
   var edit = {
            name :  "",
            role : []
          }
     self.$root.$emit('editUserDetailForName', edit);
     next()
  } 
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.inputHide .b-form-tags-list .b-from-tags-field .d-flex{
    display: none;
}
input.form-control.datetime_picker {
    background-position: 94% 50%;
    padding-left: 13px;
}
.chnage_link {
    background-color: white !important;
    color: #1ca1de !important;
    border: none;
    text-decoration: underline !important;
    position: absolute;
    bottom: 15px;
    padding:0px;
    right: 0px;
}
.change_email {
    position: relative !important;
} 
.chnage_link.btn-secondary:hover:not(.disabled):not(:disabled) {
    box-shadow: none !important;
}
.emailsize {
    white-space: nowrap;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.con-input-upload{
  border: 2px dashed rgba(0,0,0,.1) !important;
  /* border-radius: 0px !important; */
}
/* .upload-photo1123 {
    position: absolute;
    top: -4px;
    z-index: 0;
    opacity: 0;
}
.upload-photo {
    position: relative;
}
.upload-photo1123:hover {
    opacity: 0.7;
} */
 /* .b-form-tag , button.b-form-tag-remove {
    float: none;
    display: none;
} */
.upload-photo {
    position: relative;
}

.upload-photo .img-wrap {
    width: 100%;
    position: absolute;
    z-index: 9;
}

.upload-photo .img-wrap img {
    width: 100%;
}
.upload-photo:hover .img-wrap {
    z-index: -1;
    opacity: 0;
}
/* .createuser_form .con-img-upload .img-upload .btn-x-file:hover~img{
  filter: grayscale(0%);
}
.createuser_form .con-img-upload .img-upload .btn-x-file:hover img {
    -webkit-filter: grayscale(0%)!important;
    filter: grayscale(0%)!important;
} */
</style>