<template>
<div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
      style="z-index:9999;"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />

      <b-card>
          <b-row>
            <b-col xl="3" lg="3" md="3">
              <h5>Category Name</h5>
                {{categoryName}}
            </b-col>
            <b-col xl="3" lg="3" md="3">
              <h5>No. of Session(s) Title</h5>
              {{noOfsession}}
            </b-col>
          </b-row>
      </b-card>
      <div class="card category_detail_table">
        <h2>Session(s) Title Listing</h2> 
        <div class="category_detail_table_inner">
          <div class="card user_filter ">
            <h2>Filters</h2>

            <b-form @submit.prevent>
              <b-row>
                <b-col xl="3" lg="3" md="3">
                  <label>User Type</label>
                  <b-dropdown
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    :text="user_type_selected"
                    v-model="user_type_selected"
                    variant="outline-secondary"
                    class="w-100"
                    
                  >
                    <b-dropdown-item
                      v-for="item in user_type_options"
                      :key="item.value"
                      @click="user_type_selected = item.text"
                      style="text-transform: capitalize !important;"
                      >{{ item.text }}</b-dropdown-item
                    >
                  </b-dropdown>
                </b-col>
                <b-col xl="3" lg="3" md="3">
                  <label>Tutor Type</label>
                  <b-dropdown
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                   :text="tutor_type_selected"
                    variant="outline-secondary"
                    class="w-100"
                  >
                    <b-dropdown-item
                      v-for="item in tutor_type_options"
                      :key="item.value"
                      @click="tutor_type_selected = item.text"
                      >{{ item.text }}</b-dropdown-item
                    >
                  </b-dropdown>
                </b-col>
                <b-col xl="3" lg="3" md="3">
                  <label>Teaching Language</label>
                  <b-dropdown
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    :text="language_selected"
                    variant="outline-secondary"
                    class="w-100"
                  >
                    <b-dropdown-item
                      v-for="item in language_options"
                      :key="item.value"
                      @click="language_selected = item.text"
                      >{{ item.text }}</b-dropdown-item
                    >
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-form>
          </div>
          <div class="card subcategory_detail_table">
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Search..."
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </div>
            <vue-good-table
              :columns="columns"
              :rows="filterRows"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :select-options="{
                enabled: true,
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
              <template slot="table-row" slot-scope="props">

                <span
                  v-if="props.column.field === 'fullName'"
                  class="text-nowrap userid"
                >
                  <b-avatar :src="props.row.avatar" class="user_img" />
                  <router-link
                   :to="{
                    name: 'user-details',
                    params: { id: props.row.id },
                  }"
                    class="text-nowrap">{{props.row.firstName}} {{props.row.lastName}}</router-link
                  >
                </span>

                <span
                  v-else-if="props.column.field === 'tutorType'"
                  class="tutor_type"
                >
                 <div v-if="props.row.tutorType != '' && props.row.tutorType != null">
                  <b-badge  :style="getTutorTypeStyle(props.row.tutorType)">
                    <!-- :variant="getStatusBadgeVariant(props.row.tutorType)" -->
                    {{ props.row.tutorType }}
                  </b-badge>
                </div>
                </span>                

                <span
                  v-else-if="props.column.field === 'action'"
                  class="action_field"
                >
                  <span>
                    <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template v-slot:button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                        />
                      </template>
                      <b-dropdown-item ref="Btn" >
                        <span @click="logClicked(props.row.sessionID)"><feather-icon icon="ClockIcon" size="24" />Book Session</span>
                        </b-dropdown-item>
                         <b-dropdown-item>
                         <span @click="disablesession(props.row.sessionID,props.row)"> 
                            <feather-icon
                            icon="EyeOffIcon"
                            size="20"
                            />
                          Disable Session</span
                          >
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>

                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      <span v-if="props.total === 0"> Showing 0 </span>
                      <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                      to
                      <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                      <span v-else>{{props.total}} of {{ props.total }} entries</span>
                    </span>

                        <div class="d-flex align-items-center mb-1 mt-0 showentry subcategoryDetail">
                          <span>Show</span>
                          <b-form-select
                            v-model="pageLength"
                            :options="['10', '20', '30', '40', '50']"
                            class="mx-1"
                            @input="
                              (value) => props.perPageChanged({ currentPerPage: value })
                            "
                        />
                          <span>entries</span>
                        </div>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :current-page="currentPage"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => {
                          props.pageChanged({ currentPage: value });
                          currentPage = value
                        }
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
      
      <b-modal
        id="modal-edit-category"
        centered
        ok-only
        body-class="edit_category_warning"
      >
        <img
          src="@/assets/images/erflog/icon_info.png"
          alt="profile"
          class="warning_image_category_edit"
        />
        <h4></h4>
        <b-card-text>
            There are session for this category.
        </b-card-text>
      </b-modal>
    </div>
  </div>
 
</template>

<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
  BCard,
  VBModal,
  BCardText,
  BSpinner 
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import firebase from "firebase"
const db = firebase.firestore()
import {dbCollections} from "@/utils/firebaseCollection.js"
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import {updateConnectedUserWhenSessionStatusChange} from "./updateconnectedUserSessionService"
import status from '@/store/status';
export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BCardText,
    BSpinner ,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    ValidationProvider, ValidationObserver,
  },
  data() {
    return {
      direction:false,
      isSpinner:false,
      isUpdating:false,
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      user_type_selected: 'All',
      tutor_type_selected: 'All',
      language_selected: 'All',
      pageLength: 10,
      dir: false,
      categoryitems: [
      ],
      columns: [
        {
          label: "User Name",
          field: "fullName",
        },
        {
          label: "Tutor Type",
          field: "tutorType",
        },
        {
          label: "Session Title",
          field: "sessiontitle",
        },
        {
          label: "Teaching Language (s)",
          field: "language",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      searchTerm: "",
      tutorType: [
        {
          1: "Valuable",
          2: "Elite",
          3: "Normal",
          4: "N/A",
        },
        {
          1: "light-primary",
          2: "light-danger",
          3: "light-success",
          4: "light-dark",
        },
      ],
      isSpinner:false,
      isUpdating:false,
      direction:'rtl',
      Categoryselected:'',
      name:'',
      editSubCategoryId:'',
      selectedCategoryId:'',
      sameSubCategoryErrorMessage:'',
      subCatMatchinIndex:'',
      dataDisplay:[],
      dataDisplayTable:[],
      categoryName:"",
      noOfsession: 0,
    };
  },
  computed: {
      ...mapGetters({
          gettutorTypesList: "settings/getTutorTypes",
      }),
       user_type_options() {
      var learnerName = [];
      learnerName.push({ value: 0, text: "All" });
      for (var i = 0; i < this.dataDisplay.length; i++) {
        var temp = false;
        for (var j = 0; j < learnerName.length; j++) {
          if (this.dataDisplay[i].fullName == learnerName[j].text) {
            temp = true;
          }
        }
        if (!temp) {
          var obj = {
            val: i + 1,
            text: this.dataDisplay[i].fullName,
          };
          learnerName.push(obj);
        }
      }
      return learnerName;
    },
      tutor_type_options(){
      var tutorName = [];
      tutorName.push({ value: 0, text: "All" });
      for (var i = 0; i < this.dataDisplay.length; i++) {
        var temp = false;
        for (var j = 0; j < tutorName.length; j++) {
          if (this.dataDisplay[i].tutorType == tutorName[j].text) {
            temp = true;
          }
        }
        if (!temp) {
          var obj = {
            val: i + 1,
            text: this.dataDisplay[i].tutorType,
          };
          tutorName.push(obj);
        }
      }
      return tutorName;
    },
    language_options(){
       var languageOption = [];
      languageOption.push({ value: 0, text: "All" });
      for (var i = 0; i < this.dataDisplay.length; i++) {
        var temp = false;
        for (var j = 0; j < languageOption.length; j++) {
          if (this.dataDisplay[i].language == languageOption[j].text) {
            temp = true;
          }
        }
        if (!temp) {
          var obj = {
            val: i + 1,
            text: this.dataDisplay[i].language,
          };
          languageOption.push(obj);
        }
      }
      return languageOption;
    },
     filterRows() {
      var self = this;
      var dataDisplayTable = self.dataDisplay;
      if (self.user_type_selected == "All" && self.tutor_type_selected == "All" && self.language_selected == "All") {
        return dataDisplayTable;
      }
      if (self.user_type_selected != "All") {
        dataDisplayTable = dataDisplayTable.filter((data) => {
          return data.fullName.toLowerCase().includes(self.user_type_selected.toLowerCase());
        });
      }
       if (self.tutor_type_selected != "All") {
        dataDisplayTable = dataDisplayTable.filter((data) => {
          return data.tutorType.toLowerCase().includes(self.tutor_type_selected.toLowerCase());
        });
      }
      if (self.language_selected != "All") {
        dataDisplayTable = dataDisplayTable.filter((data) => {
          return data.language.toLowerCase().includes(self.language_selected.toLowerCase());
        });
      }
      return dataDisplayTable;
    },
  },
  created() {
    var self = this
    this.getSessionData()
    db.collection(dbCollections.SETTINGS).doc('setting').collection(dbCollections.CATEGORY).doc(this.$route.params.id).get().then((cat)=>{
      this.categoryName = cat.data().categoryName
      this.$root.$emit('categoryName', this.categoryName);
    }).catch((error)=>{
      console.error(error);
    })
    this.categoryName = this.$route.params.name
    self.$root.$off('editCategoryPopupOpen');
    self.$root.$on('editCategoryPopupOpen',() => {
        this.categoryPopUpEdit()
    })
    self.$root.$off('afteEditName')
    self.$root.$on('afteEditName',(name,cateIcon) => {
      this.categoryName = name
      this.$root.$emit('categoryName', name, cateIcon);
    })
  },
  methods: {
    getTutorTypeStyle(value) {
        if(this.gettutorTypesList) {
            let index = this.gettutorTypesList.findIndex((val ) =>{
              return val.name == value
            });
            if(index > -1) {
                return {
                  'background-color' : this.gettutorTypesList[index].backgroundColor,
                  'color' : this.gettutorTypesList[index].textColor,
                  'border-color': this.gettutorTypesList[index].textColor,
                  'border-width': '1px',
                  'border-style': 'solid'
                }
            }
        } else {
            return ''
        }
    },
    categoryPopUpEdit() {
          if(this.noOfsession === 0) {
              this.isSpinner = false;
              this.editCategory();
          } else {
              this.isSpinner = false;
              this.$bvModal.show('modal-edit-category')
          }
    },
    editCategory(){
      
      var self = this;
      self.$root.$emit('editCategory',true,this.$route.params.id)
      
    },
    getSessionData(){
      db.collection(dbCollections.SESSIONS).where("category","==",this.$route.params.id).where("status","!=",3).get().then((session)=>{
        if (session.empty) {
          return;
        }
        else{
          this.categoryName = session.docs[0].data().categoryDetails.categoryName
          this.noOfsession = session.docs.length
          session.docs.forEach((data)=>{
            var object = {
                  avatar:(data.data().tutorDetails.profilePicture&&data.data().tutorDetails) ? data.data().tutorDetails.profilePicture : "",
                  fullName:`${data.data().tutorDetails.firstName} ${data.data().tutorDetails.lastName}`,
                  firstName:data.data().tutorDetails.firstName,
                  lastName:data.data().tutorDetails.lastName,
                  id:data.data().tutor,
                  tutorType:data.data().tutorDetails.tutorType ? data.data().tutorDetails.tutorType : '',
                  sessiontitle:data.data().title,
                  language:data.data().teachingLanguageDetails.title,
                  sessionID:data.data().id,
                  action:"action",
                  categoryid:data.data().category
            }
            this.dataDisplay.push(object)
          })
        }
      }).catch((error)=>{
        console.error(error);
      })
    },
    logClicked(id){
      var self = this;
      self.$router.push(`/booking-session-all-session/${id}`)
    },
     disablesession(sessionID,row){
       this.$bvModal
        .msgBoxConfirm("Are you sure you want to Disable Session?", {
          title: "Disable",
          class: "test",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-primary",
          hideHeaderClose: false,
          centered: true,
          footerClass: "approve-footer",
          headerClass: "approve-header",
          bodyClass: "approve-body",
        })
        .then((value) => {
          if (value) {
            var disableobj = {
              status: 3,
            };
            updateFirebase.updateRootCollectionDataByDocId(
              dbCollections.SESSIONS,
              sessionID,
              disableobj,
              (res) => {
                updateConnectedUserWhenSessionStatusChange({
                  tutorId : row.id,
                  categoryId  : row.categoryid,
                  action: "remove"
                },()=>{})
                
              this.$root.$emit('showToastMessage','Session disabled successfully.','success');
              }
            );
          }
        });
      },
    getStatusBadgeVariant(value){
      
      var variant = "";
      if(value == "Valuable")
      {
        variant += 'light-primary';
      }
      else if(value == "Elite")
      {
        variant += 'light-danger';
      }
      else if(value == "Normal")
      {
        variant += 'light-success';
      }
      return variant;
    },
  },
};
</script>

