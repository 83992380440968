<template>
    <div>
        <b-card>
            <b-row>
                <b-col>
                    <div>
                        <div style="margin:10px 0px 1.5rem 0px;" v-for="(data,index) in versionControlArr" :key="index" >
                            <label class="head-title">Version : {{data.version}} ({{data.date}})</label>
                            <ul>
                                <li class="v-updates" v-for="(data2,index2) in data.updates" :key="'versionControlUpdates'+index2">
                                    {{data2}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BSpinner,
  BBadge, BDropdown, BDropdownItem, BPagination,BModal,BFormSelect,BFormGroup,
} from 'bootstrap-vue'

export default {
    name:'VersionControlPage',

    components:{
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BSpinner,

        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        BModal,
        BFormSelect,
        BFormGroup,
    },


    data(){
        return{
            versionControlArr:[
                {
                    version:'0.0.0',
                    date:'DD MMM, YYYY //Date of update',
                    updates:[
                        'updated 1',
                        'updated 2',
                        'bug solve',
                        'updated 3',
                        'updated 4',
                    ]
                },
            ]
        }
    },
    created()
    {
        this.versionControlArr = this.versionControlArr.reverse()
    }
}
</script>

<style>
.head-title{
    color: #656565;
    font-size: 23px !important;
    font-weight: 600;
}

.v-updates{
    /* display:block; */
    color: #656565;
    font-size: 15px !important;
    font-weight: 600;
}
</style>>