import { render, staticRenderFns } from "./Bannermanagement.vue?vue&type=template&id=7fbdeb81&"
import script from "./Bannermanagement.vue?vue&type=script&lang=js&"
export * from "./Bannermanagement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports