<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners loaderDetail"
        style="z-index: 999999; margin-left: -160px; margin-top: 350px"
      />
      <div class="card user_filter">
        <h2>Filters</h2>
        <b-form @submit.prevent>
          <b-row>
            <b-col xl="3" lg="3" md="3">
              <label>User Type</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="user_type_selected"
                v-model="user_type_selected"
                variant="outline-secondary"
                class="w-100 toCapitalFirst"
              >
                <b-dropdown-item
                  v-for="item in user_type_options"
                  :key="item.value"
                  @click="user_type_selected = item.text"  
                  class="toCapitalFirst"                
                  >
                  {{ item.text }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col xl="3" lg="3" md="3" :hidden='user_type_selected.toLowerCase() == "learner"'>
              <label>Tutor Type</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="tutor_type_selected"
                variant="outline-secondary"
                class="w-100 toCapitalFirst"
              >
                <b-dropdown-item
                  v-for="item in tutor_type_options"
                  :key="item.value"
                  @click="tutor_type_selected = item.text"
                  class="toCapitalFirst"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
            <b-col xl="3" lg="3" md="3">
              <label>Status</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="status_selected"
                variant="outline-secondary"
                class="w-100 toCapitalFirst"
              >
                <b-dropdown-item
                  v-for="item in UserStatusOption"
                  :key="item.value"
                  @click="status_selected = item.text"
                  class="toCapitalFirst"
                  >{{ item.text }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col xl="3" lg="3" md="3">
              <label>Permission</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="status_selected_permission"
                variant="outline-secondary"
                class="w-100"
              >
                <b-dropdown-item
                  v-for="item in UserPermission"
                  :key="item.value"
                  @click="status_selected_permission = item.text"
                  >{{ item.text }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <div class="card user_table position123">
   <div class="serach_pagination">
            <div class="d-flex justify-content-between flex-wrap">
            </div>
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Search..."
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </div>
            </div>
        <vue-good-table
          :columns="columns"
          :rows="filteredUsers"
          @on-row-click="onRowClick"
          :rtl="direction"
          class="cursor_pointer"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'userId'" class="user_type">
              <span v-if="props.row.userId && props.row.userId !== ''">
                {{ props.row.userId }}
              </span>
              <span v-else> N/A </span>
            </span>
            <span
              v-else-if="props.column.field === 'fullName'"
              class="text-nowrap"
            >
              <b-avatar :src="props.row.avatar" class="user_img" />
              <span class="userlist">
                <a>{{ props.row.fullName }}</a>
              </span>
            </span>
            <span
              v-else-if="props.column.field === 'userRole'"
              class="user_type"
            >
              <label
                v-for="(item, index) in props.row.userRole"
                :key="index + 'userRole'"
              >
                {{
                  (index == 0 && props.row.userRole.length == 1) ||
                  index == props.row.userRole.length - 2
                    ? ""
                    : ","
                }}
                {{ item[0].toUpperCase() + item.substring(1) }}
              </label>
            </span>
            <span
              v-else-if="props.column.field === 'permission'"
              class="permission_type"
            >
              <span v-if="props.row.isChild === true">
                  Limited                  
              </span>
              <span v-if="props.row.isChild === false">
                  Full
              </span>
              <span v-if="props.row.isChild === undefined"> N/A </span>
            </span>
            <span
              v-else-if="props.column.field === 'tutorType'"
              class="tutor_type"
            >
              <span v-if="props.row.tutorType && props.row.tutorType !== ''">
                <b-badge
                  :style="getTutorTypeStyle(props.row.tutorType)"
                >
                  <!-- :variant="getTutorTypeBadgeVariant(props.row.tutorType)" -->
                  {{ props.row.tutorType }}
                </b-badge>
              </span>
              <span v-else> N/A </span>
            </span>
            <span v-else-if="props.column.field === 'statusText'">
              <b-badge :variant="getStatusBadgeVariant(props.row)">
                {{props.row.statusText ? props.row.statusText : getStatusName(props.row)}}
              </b-badge>
            </span>
            <span
              v-else-if="props.column.field === 'action'"
              class="action_field"
            >
              <b-button variant="flat-dark">
                <router-link
                  v-if="!props.row.isTutor"
                  :to="{
                    name: 'edit-user',
                    params: {
                      id: props.row.id,
                      name: props.row.firstName + ' ' + props.row.lastName,
                      role: props.row.userRole.join(','),
                    },
                  }"
                >
                  <img
                    :src="require('@/assets/images/erflog/Edit.png')"
                    alt="image"
                  />
                </router-link>
                <router-link
                  v-if="props.row.isTutor"
                  :to="{
                    name: 'edit-tutor-user',
                    params: {
                      id: props.row.id,
                      name: props.row.firstName + ' ' + props.row.lastName,
                      role: props.row.userRole.join(','),
                    },
                  }"
                >
                  <img
                    :src="require('@/assets/images/erflog/Edit.png')"
                    alt="image"
                  />
                </router-link>
              </b-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> 
                  <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to
                  <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                  <span v-else>{{props.total}} of {{ props.total }} entries</span>
                </span>

                    <div class="d-flex align-items-center mb-1 mt-0 showentry">
                      <span>Show</span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10', '20', '30', '40', '50']"
                        class="mx-1"
                        @input="
                          (value) => props.perPageChanged({ currentPerPage: value })
                        "
                     />
                      <span>entries</span>
                    </div>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :current-page="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => {
                      props.pageChanged({ currentPage: value });
                      currentPage = value
                    }
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
  BSpinner,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import firebase from "@/utils/firebaseInit.js";
const db = firebase.firestore();
import userStatus from "@/utils/teachingLanguages.json";
import { errorEmail } from '@/utils/apiRequests';
const currentFileName = "src/views/Users/User/userList.vue"
export default {
  directives: {
    Ripple,
  },
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BButton,
    BSpinner,
  },
  data() {
    return {
      userData: userStatus,
      direction: false,
      isSpinner: false,
      isUpdating: false,

      rows: [],
      lastDoc: {},

      sds: [],
      pgIndex: 0,

      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      user_type_selected: "All",
      user_type_options: [
        { value: null, text: "All" },
      ],
      tutor_type_selected: "All",
      status_selected: "All",
      status_selected_permission: "All",
      pageLength: 10,
      dir: false,
      currentPerPage:10,
      columns: [
        {
          label: "User ID",
          field: "userId",
        },
        {
          label: "User Name",
          field: "fullName",
        },
        {
          label: "User Type",
          field: "userRole",
        },
        {
          label: "Permission",
          field: "permission",
        },
        {
          label: "Tutor Type",
          field: "tutorType",
        },
        {
          label: "User Email",
          field: "email",
        },
        {
          label: "Status",
          field: "statusText",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      permission123:[],
      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters({
      users: "userManagement/getUsers",
      getStatusList: "status/getStatus",
      gettutorTypesList: "settings/getTutorTypes",
      schools: "settings/getSchools",
      syllabus: "settings/getSyllabus",
    }),
    tutor_type_options() {
      let returnResult = [{ value: null, text: "All" }];
      try {
        if (this.gettutorTypesList && this.gettutorTypesList.length > 0) {
          this.gettutorTypesList.forEach((items) => {
            returnResult.push({
              value: items.id,
              text: items.name,
            });
          });
        }
        return returnResult;
      } catch (error) {
        console.error(error, "error");
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "tutor_type_options",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        });
        return returnResult;
      }
    },
    UserStatusOption() {
      try {
        var arr = [{ value: null, text: "All" }];
        let statusObj = this.getStatusList.userStatus;
        for (const property in statusObj) {
          var syllabusObj = {
            value: statusObj[property].status,
            text: statusObj[property].status,
            variant: statusObj[property].variant,
            key: property,
          };
          arr.push(syllabusObj);
        }
        return arr;
      } catch (error) {
        console.error("error", error);
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "UserStatusOption",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        });
        return arr;
      }
    },
    UserPermission() {
        var userStatus = [];
        userStatus.push({ value: null, text: "All" });
        for (var i = 0; i < this.users.length; i++) {
          var temp = false;
          for (var j = 0; j < userStatus.length; j++) {
            let userStatusValue = (this.users[i].isChild === true )? "Limited" : "Full";
            if (userStatusValue === userStatus[j].text) {
              temp = true;
            }
          }
          if (!temp) {
            
            var obj = {
              value: this.users[i].isChild,
              text: (this.users[i].isChild === true )? "Limited" : "Full",
            };
            userStatus.push(obj);
          }
        }
      return userStatus;
    },
    allUsers() {
      var self = this;
      let result = [];
      self.isUpdating = true;
      try {
        if (self.users && self.users.length > 0) {
          for (let index = 0; index <= self.users.length; index++) {
            let items = self.users[index];
            items.userStatusName = "";
            items.userVariantName = "";
            items.isTutor = false;
            items.statusText = "";
            items.isChildValue = (items.isChild === false )? "Full" : "Limited";
            let sIndex = items.userRole.findIndex((element) => {
              return element.toLowerCase() == "tutor";
            });
            if (sIndex > -1) {
              items.isTutor = true;
            }
            if (
              items.profilePicture &&
              items.profilePicture !== null &&
              items.profilePicture !== ""
            ) {
              items.avatar = items.profilePicture ? items.profilePicture : "";
            }
            if(items.userRole && items.userRole.length > 0){
              let roleArray = items.userRole;
              for(let j = 0; j <= roleArray.length; j++){
                let roleData = roleArray[j];
                let sRoleIndex = this.user_type_options.findIndex((roleVal)=>{
                  return roleVal.text.toLowerCase() == roleData.toLowerCase()
                })
                if(sRoleIndex <= -1){
                  this.user_type_options.push(
                    { value: parseInt(this.user_type_options.length), text: roleData[0].toUpperCase() + roleData.substring(1) }
                  )
                }
                if(j == parseInt(roleArray.length - 1)){
                  break
                }
              }
            }
            if (index == parseInt(self.users.length - 1)) {
              return self.users;
            }
          }
          self.isUpdating = false;
        } else {
          self.isUpdating = false;
          return result;
        }
      } catch (error) {
        console.error(error, "error");
         errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "allUsers",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        });
        self.isUpdating = false;
        return self.users;
      }
    },   
    filteredUsers() {
      var self = this;
      try {
        self.isUpdating = true;
        var listArr = self.allUsers;
        listArr.forEach((items) => {
          if (items.status !== undefined) {
            items.statusText =  self.getStatusName(items);
            let statusObj = this.getStatusList.userStatus;
            for (const property in statusObj) {
              if (property == items.status) {
                items.statusText = statusObj[property].status;
                break;
              }
              if (parseInt(Object.keys(statusObj).length - 1) == property) {
                break;
              }
            }
          }
          if (items.fullName == undefined) {
            items.fullName = `${items.firstName ? items.firstName : ""} ${items.lastName ? items.lastName : ""}`
          }
        });
        var tmp = listArr;
        if (self.user_type_selected != "All") {
          tmp = this.filterByUserType(tmp);
        }
        if (self.tutor_type_selected != "All") {
          tmp = this.filterByTutorType(tmp);
        }
        if (self.status_selected != "All") {
          tmp = this.filterByStatusSelected(tmp);
        }
        if (self.status_selected_permission != "All") {
          tmp = this.filterByPermission(tmp);
        }

        if (
          self.tutor_type_selected == "All" &&
          self.user_type_soelected == "All" &&
          self.status_selected == "All" &&
          self.status_selected_permission == "All"
        ) {
          self.isUpdating = false;
          return listArr;
        } else {
          self.isUpdating = false;
          return tmp;
        }
      } catch (error) {
        console.error(error);
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "filteredUsers",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        });
        self.isUpdating = false;
        return self.allUsers;
      }
    },
  },
  methods: {
    onRowClick(data){
      this.$router.push({name: 'user-details', params: { id: data.row.id, userDetail: data.row }})
    },
    getTutorTypeBadgeVariant(value) {
      var variant = "";
      if (value == "Normal") {
        variant += "light-success";
      } else if (value == "Elite") {
        variant += "light-danger";
      } else if (value == "Valuable") {
        variant += "light-primary";
      }
      else if (value == "Expert") {
        variant += "light-warning";
      } else {
        variant += 'warning'
      }
      return variant;
    },
    getTutorTypeStyle(value) {
        if(this.gettutorTypesList) {
            let index = this.gettutorTypesList.findIndex((val ) =>{
              return val.name == value
            });
            if(index > -1) {
                return {
                  'background-color' : this.gettutorTypesList[index].backgroundColor,
                  'color' : this.gettutorTypesList[index].textColor,
                  'border-color': this.gettutorTypesList[index].textColor,
                  'border-width': '1px',
                  'border-style': 'solid'
                }
            }
        } else {
            return ''
        }
    },
    getStatusName(data) {
      let res = "";
      try {
        let getIndex = this.UserStatusOption.findIndex((values) => {
          return values.key == data.status;
        });
        if (getIndex > -1) {
          res = this.UserStatusOption[getIndex].text;
          data.userStatusName = res;
          data.userVariantName = this.UserStatusOption[getIndex].variant;
          data.statusText = res;
        }
        return res;
      } catch (error) {
        console.error(error, "error");
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "getStatusName",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        });
        return res;
      }
    },
    getStatusBadgeVariant(value) {
      var variant = "light-";
      try {
        let statusObj = this.getStatusList.userStatus;
        for (const property in statusObj) {
          if (value.status == property) {
            variant += statusObj[property].variant;
          }
        }
        return variant;
      } catch (error) {
        console.error(error, "error");
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "getStatusBadgeVariant",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        });
        return variant;
      }
    },
    filterByUserType(data) {
      return data.filter((user) =>
        user.userRole.includes(this.user_type_selected.toLowerCase())
      );
    },
    filterByTutorType(data) {
      return data.filter((user) => user.tutorType == this.tutor_type_selected);
    },
    filterByPermission(data) {
      return data.filter((user12) => user12.isChildValue === this.status_selected_permission);
    },
    filterByStatusSelected(data) {
      return data.filter((user) => user.statusText == this.status_selected);
    },
  },
};
</script>

<style>
.table-empty-message {
  text-align: center;
  font-weight: 600;
  padding: 15px 0;
}
.serach_pagination {
    display: flex;
    justify-content: space-between;
    justify-items: center;
    align-items: center;
}
</style>