<template>
  <div>
   <b-spinner
      variant="primary"
      v-if="showSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!showSpinner">
      <b-spinner
        variant="primary"
        v-if="showSpinner"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
        <p>Email Verification</p>
        <button v-on:click="resendLink" v-if="showResendButton">ResendEmail</button>
    </div>   
  </div>
   
</template>

<script>
import axios from "axios";
import {BSpinner}  from "bootstrap-vue";
import {emailVerification} from './service.js'
export default {
name : "EmailVerification",
components: {
    BSpinner
},
data() {
    return {
        showSpinner : true,
        showResendButton: false
    }
},
created(){
    emailVerification({
        uid: this.$route.params.varifyId,
        token: this.$route.query.token,
    },(res) => {
      this.showSpinner = false;
      if (!res.status) {
        alert("Some thing went wrong");
      } else if (res.status) {
        if (res.statusText.showResendVerification === false) {
          this.showSpinner = false;
          alert(`${res.statusText.message}`)
          this.showResendButton = false;
        } else if (res.statusText.showResendVerification === true) {
          this.message = res.statusText;
          this.showSpinner = false;
          alert(`${res.statusText.message}`)
          this.showResendButton = false;
        } else if (res.statusText.resendEmailVerification === true) {
          this.showSpinner = false;
          alert(`${res.statusText.message}`)
          this.showResendButton = true;
        }
      }else if(!res.status){
      }
    })
},
methods: {
  resendLink() {
    this.showSpinner = true;
    emailVerification({
      uid: this.$route.params.varifyId,
      domainLink: this.domainLink,
    },() => {
      this.showSpinner = false;
    }) 
  }
}
}
</script>

<style>

</style>