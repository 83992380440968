<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />

      <div class="card user_filter cancel_session">
        <h2>Filters</h2>

        <b-form @submit.prevent>
          <b-row>
            <b-col xl="3" lg="3" md="3">
              <label>Learner Name</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="user_type_selected"
                variant="outline-secondary"
                class="w-100 dropdownfilter"
              >
                <b-dropdown-item
                  v-for="item in user_type_options"
                  :key="item.value"
                  @click="user_type_selected = item.text"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
            <b-col xl="3" lg="3" md="3">
              <label>Tutor Name</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="tutor_type_selected"
                variant="outline-secondary"
                class="w-100 dropdownfilter"
              >
                <b-dropdown-item
                  v-for="item in tutor_type_options"
                  @click="tutor_type_selected = item.text"
                  :key="item.value"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
            <b-col xl="3" lg="3" md="3">
              <label>Tutor Type</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="status_selected"
                variant="outline-secondary"
                class="w-100"
              >
                <b-dropdown-item
                  v-for="item in status_options"
                   @click="status_selected = item.text"
                  :key="item.value"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
            <b-col xl="3" lg="3" md="3">
              <label>Action Taken</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="action_taken_selected"
                variant="outline-secondary"
                class="w-100"
              >
                <b-dropdown-item
                  v-for="item in booking_status_options"
                   @click="action_taken_selected = item.text"
                  :key="item.value"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <div class="card user_table cancel_session_table upcoming_session_table cancelSessionListDetail textleftside extraclassforcancel">
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1"></label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search..."
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="filterRows"
          @on-cell-click="onCellClick"
          class="cursor-pointer"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-column" slot-scope="props">
            <span v-if="props.column.label === 'Warning'">
            {{props.column.label}}
                <b-button
                  class="warningInfo"
                  v-b-tooltip.v-primary
                  title="Total number of warning"
                  variant="outline-primary"
                  v-b-tooltip.html
                >
                  <feather-icon icon="AlertCircleIcon" size="14" />
                </b-button>
            </span>
            <span v-else>{{props.column.label}}</span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'bookingId'" class="text-nowrap">
             {{ props.formattedRow[props.column.field] }}
            </span>
            <span
              v-else-if="props.column.field === 'tutorType'"
              class="tutor_type"
            >
              <b-badge :variant="tutorType[1][props.row.tutorType]">
                {{ tutorType[0][props.row.tutorType] }}
              </b-badge>
            </span>
            <span
              v-else-if="props.column.field === 'learnerChildName'"
              class="text-nowrap"
            >
              <b-avatar :src="props.row.avatarForLearner" class="user_img" />
              <router-link
                :to="{
                  name: 'user-details',
                  params: {
                      id: props.row.bookinglernerId
                  },
                }"
                class="text-nowrap"
                >{{ props.row.learnerChildName }}</router-link
              >
            </span>
            <span
              v-else-if="props.column.field === 'tutorNameDetail'"
              class="text-nowrap"
            >
              <b-avatar :src="props.row.avatarForTutor" class="user_img" />
              <router-link
              :to="{
                  name: 'user-details',
                  params: {
                      id: props.row.bookingTutor
                  },
              }"
                class="text-nowrap"
                >{{ props.row.tutorNameDetail }}</router-link
              >
            </span>
            <span v-else-if="props.column.field === 'status'" class="status">
              <b-badge :variant="status[1][props.row.status]">
                {{ status[0][props.row.status] }}
              </b-badge>
            </span>
            <span
              v-else-if="props.column.field === 'cancellationReason'"
              class="cancel_reason"
            >
                <span v-html="limitTo(props.row.cancellationReason)"></span>
                <a
                    class="readmore"
                    v-b-modal="'readmore' + String(props.row.Id)"
                    v-show="!props.row['isEditable'] && props.row.cancellationReason.length >= 50"
                    
                    >Read more</a
                >
                <UserModal title="Cancel Reason" :text="props.row.cancellationReason" :id="props.row.Id" />
            </span>
            <span
              v-else-if="props.column.field === 'action'"
              class="action_field"
            >
            <b-badge :variant="getTutorTypeBadgeVariant(props.row.actionTaken)">
                {{getAllStatusList.cancelReasonStatus[props.row.actionTaken]}}
              </b-badge>
            </span>
            <span
              v-else-if="props.column.field === 'totalNoOfWarning'"
              class="cancel_reason"
            >
            {{props.row.warning}}
            </span>
            <span v-else-if="props.column.field === 'sessionTitle'" class="cancel_reason">
              {{ props.row.sessionTitle }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> 
                  <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to
                  <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                  <span v-else>{{props.total}} of {{ props.total }} entries</span>
                </span>

                    <div class="d-flex align-items-center mb-1 mt-0 showentry allsession">
                      <span>Show</span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10', '20', '30', '40', '50']"
                        class="mx-1"
                        @input="
                          (value) => props.perPageChanged({ currentPerPage: value })
                        "
                     />
                      <span>entries</span>
                    </div>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :current-page="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => {
                      props.pageChanged({ currentPage: value });
                      currentPage = value
                    }
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
  BSpinner,
  VBTooltip
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "@/views/SessionsManagement/CanceledSession(s)/canceledSessionsList.vue"
import { dbCollections } from '@/utils/firebaseCollection';
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import UserModal from "@/@core/components/modals/ReadMoreModal.vue";
import { mapGetters , mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
export default {
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BButton,
    BSpinner,
    VBTooltip,
    UserModal
  },
  data() {
    return {
      direction: false,
      isSpinner: false,
      isUpdating: false,
      rowsArray:[],
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      user_type_selected: "All",
      tutor_type_selected: "All",
      status_selected: "All", 
      Total_no_of_Warning: null,
      Total_no_of_Warning: [
        { value: 0, text: "noraml" },
        { value: 1, text: "normal" },
        { value: 2, text: "danger" },
        { value: 3, text: "normal" },
      ],
      action_taken_selected: "All",
      action_taken_options: [
        { value: 0, text: "Aproove Reason" },
        { value: 1, text: "Give Warning" },
        { value: 2, text: "Suspent Tutor" }
      ],


      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Booking ID",
          field: "bookingId",
        },
        {
          label: "Session(s) Title",
          field: "sessionTitle",
        },
        {
          label: "Learner Name",
          field: "learnerChildName",
        },
        {
          label: "Tutor Name",
          field: "tutorNameDetail",
        },
        {
          label: "Warning",
          field: "totalNoOfWarning",
        },
        {
          label: "Cancellation Reason",
          field: "cancellationReason",
        },
        {
          label: "Action Taken",
          field: "action",
        },
      ],
      searchTerm: "",
      status: [
        {
          1: "Current",
          2: "Active",
          3: "Block",
          4: "Suspend",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      tutorType: [
        {
          1: "Valuable",
          2: "Elite",
          3: "Normal",
          4: "N/A",
        },
        {
          1: "light-primary",
          2: "light-danger",
          3: "light-success",
          4: "light-dark",
        },
      ],
      totalNoOfWarning: [
        {
          1: "Valuable",
          2: "Elite",
          3: "Normal",
          4: "N/A",
        },
        {
          1: "light-primary",
          2: "light-danger",
          3: "light-success",
          4: "light-dark",
        },
      ],
    };
  },
  created() {
    this.isSpinner = true;
    let cancelRecordFound = this.getCancelSessionList.length != 0;
    if(!cancelRecordFound){
      this.getCancelSessionDatas().then(res=>{
        this.isSpinner = false;
        this.rows()
      }).catch(error=>{
        console.error("error",error)
        this.isSpinner = false;
      })
    }else{
      this.isSpinner = false;
      this.rows()
    }
  },
  computed: {
    ...mapGetters({
      getBookinglist: "booking/getBooking",
      getCancelSessionList: "cancelSessionDetails/getCancelSessionRequest",
      getsettingList : 'settings/getsettingArrayFeild',
      getAllStatusList:'settings/getAllStatus',
    }),
    user_type_options() {
      try {
        let self = this;
        var learnerName = [];
        learnerName.push({ value: 0, text: "All" });
        for (var i = 0; i < self.rowsArray.length; i++) {
          var temp = false;
          for (var j = 0; j < learnerName.length; j++) {
            if (this.rowsArray[i].learnerChildName == learnerName[j].text) {
              temp = true;
            }
          }
          if (!temp) {
            var obj = {
              val: i + 1,
              text: this.rowsArray[i].learnerChildName,
            };
            learnerName.push(obj);
          }
        }
        return learnerName;
      } catch (error) {
        console.error("error",error)
          errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "CountRow",
            errorMessage : error.message
          },(mailRes) => {
            if(mailRes.error){
              console.error(mailRes);
            }
          })
      }
    },
    tutor_type_options(){
      try {
        var tutorName = [];
        tutorName.push({ value: 0, text: "All" });
        for (var i = 0; i < this.rowsArray.length; i++) {
          var temp = false;
          for (var j = 0; j < tutorName.length; j++) {
            if (this.rowsArray[i].tutorNameDetail == tutorName[j].text) {
              temp = true;
            }
          }
          if (!temp) {
            var obj = {
              val: i + 1,
              text: this.rowsArray[i].tutorNameDetail,
            };
            tutorName.push(obj);
          }
        }
      return tutorName;
      } catch (error) {
        console.error(error)
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "tutor_type_options",
          errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
      }
    },
    status_options(){
      try {
        var tutorTypeName = [];
        tutorTypeName.push({ value: 0, text: "All" });
        for (var i = 0; i < this.rowsArray.length; i++) {
          var temp = false;
          for (var j = 0; j < tutorTypeName.length; j++) {
            if (this.rowsArray[i].tutorType == tutorTypeName[j].text) {
              temp = true;
            }
          }
          if (!temp) {
            var obj = {
              val: i + 1,
              text: this.rowsArray[i].tutorType,
            };
            tutorTypeName.push(obj);
          }
        }
      return tutorTypeName;
      } catch (error) {
        console.error(error)
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "status_options",
          errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
      }
    },
    booking_status_options(){
      try {
        var userStatus = [];
        userStatus.push({ value: 0, text: "All" });
        for (var i = 0; i < this.rowsArray.length; i++) {
          var temp = false;
          for (var j = 0; j < userStatus.length; j++) {
            if (this.rowsArray[i].actionName == userStatus[j].text) {
              temp = true;
            }
          }
          if (!temp) {
            var obj = {
              val: i + 1,
              text: this.rowsArray[i].actionName,
            };
            userStatus.push(obj);
          }
        }
      return userStatus;
      } catch (error) {
        console.error(error)
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "status_options",
          errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
      }
    },
    filterRows() {
      try {
        let self = this;
        var listA = self.rowsArray;
        if (self.user_type_selected == "All" && self.tutor_type_selected == "All" && self.status_selected=="All" && self.action_taken_selected == "All") {
          return listA;
        }
        if (self.user_type_selected != "All") {
          listA = listA.filter((data) => {
            return data.learnerChildName.toLowerCase().includes(self.user_type_selected.toLowerCase());
          });
        }
        if (self.tutor_type_selected != "All") {
          listA = listA.filter((dataTutor) => {
            return dataTutor.tutorNameDetail.toLowerCase().includes(self.tutor_type_selected.toLowerCase());
          });
        }
        if (self.status_selected != "All") {
          listA = listA.filter((dataTutorType) => {
            return dataTutorType.tutorType.toLowerCase().includes(self.status_selected.toLowerCase());
          });
        }
        if (self.action_taken_selected != "All") {
          listA = listA.filter((action) => {
            return action.actionName.toLowerCase().includes(self.action_taken_selected.toLowerCase());
          });
        }
        return listA;
      } catch (error) {
        console.error(error)
        errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "filterRows",
            errorMessage : error.message
          },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
      }
    },
  },
  methods: {
    onCellClick(data) {
      if (data.column.field==="learnerChildName") {
            this.$router.push({name: 'user-details', params: { id: data.row.bookinglernerId}})
        } else if (data.column.field==="tutorNameDetail") {
            this.$router.push({ name: 'user-details',params: { id: data.row.bookingTutor},})
        }  else if (data.column.field==="cancellationReason") {
          if (data.row.cancellationReason.length <= 50) {
            this.$router.push({ name: 'cancel-session-details', params: { id: data.row.Id}})
          } else {
            return;
          }
        }
        else {
          this.$router.push({ name: 'cancel-session-details', params: { id: data.row.Id}})
        }
    },
    ...mapActions({
      getCancelSessionDatas: "cancelSessionDetails/getCancelSessionRequestData"
    }),
    rows() {
      this.isSpinner = true;
      this.rowsArray = [];
      try {
        if(this.getCancelSessionList.length === 0){
          this.isSpinner = false;
        }
        this.getCancelSessionList.forEach((items) => {
          getFirebase.simpleGetDataFromSubCollectionWithDocIDV2(dbCollections.USERS,items.tutor,(cancelDatas)=>{
            var object = {
              actionTaken: items.cancelReasonStatus,
              bookingId: items.bookingId,
              actionName: this.getAllStatusList.cancelReasonStatus[items.cancelReasonStatus],
              Id: items.id,
              warning : cancelDatas.data.warning ? cancelDatas.data.warning : 0,
              sessionTitle: items.sessionDetails ? items.sessionDetails.name : '',
              avatarForLearner: items.learnerDetails ? items.learnerDetails.profileImage : '',
              avatarForTutor: items.tutorDetails ? items.tutorDetails.profilePicture : '',
              learnerChildName:
                (items.learnerDetails ? items.learnerDetails.firstName : '') +
                " " +
                (items.learnerDetails ? items.learnerDetails.lastName : ''),
              tutorNameDetail:
                (items.tutorDetails ? items.tutorDetails.firstName : '') + " " + (items.tutorDetails ? items.tutorDetails.lastName : ''),
              cancellationReason: items.cancellationReason,
              tutorType:items.tutorDetails ? items.tutorDetails.tutorType : '',
              bookingTutor: items.tutor,
              bookinglernerId: items.learnerId
            };
            this.rowsArray.push(object);
            if(this.getCancelSessionList.length === this.rowsArray.length){
              this.isSpinner = false;
            }
          })
        });
      } catch (error) {
        console.error("error",error)
      }
    },
    limitTo: function (value) {
      if(value){
        if (value.length < 50){
          return value
        }else{
          return value.substring(0, 50) + "...";
        }
      }else{
        return "-";
      }
    },
    getTutorTypeBadgeVariant(value){
      var variant = "light-";
      if(value == 1)
      {
        variant += 'success';
      }
      else if(value == 2)
      {
        variant += 'danger';
      }
      else if(value == 3)
      {
        variant += 'color';
      }
      else{
        variant += 'warning'
      }
      return variant;
    },
  },
};
</script>
<style scoped>
  .upcoming_session_table button.btn.warningInfo.btn-outline-primary {
    margin: 0;
    padding: 0px!important;
    width: auto;
    border: 0px!important;
    left: -7px;
    height: 24px!important;
    width: 24px!important;
    min-height: auto!important;
    /* font-size: 9px; */
    position: relative;
    top: -5px;
    box-shadow: none!important;
}
</style>

