<template>
  <div>
    <b-card class="createuser_form">    
      <validation-observer ref="createUser">
        <b-form>
          <b-row>
            <b-col cols="1">
              <div class="upload-photo">  
              <vs-upload
                text="Upload" 
                action="https://jsonplaceholder.typicode.com/posts/"
                id="fileInput"
                name="fileinput"
                ref="img"
                accept="image/*"
                limit="1"
                v-model="uploadedData"
                @change="CheckFile"
                @on-delete="resetUploader"
                :show-upload-button='true'
              />  
              </div>              
              <strong>{{ file ? file.name : "" }}</strong>
              <span class="required" v-show="profile_img_error !== ''">{{profile_img_error}}</span>
            </b-col>
            <b-col cols="11">
              <b-row>
                <b-col md="3">                  
                  <b-form-group>       
                    <label label-for='First Name'>First Name<span class="required">*</span></label>           
                    <validation-provider
                      #default="{ errors }"
                      name="first name"
                      rules="required|max:25|min:2"
                    >
                      <b-form-input
                        v-model="firstName"
                        :disabled='isLoading'
                        :maxlength='30'
                        placeholder="Enter First Name"
                        @keypress="isOnlyCharacter($event)"
                        @copy.prevent 
                        @paste.prevent 
                        ondragover="return false"
                      />
                      <small class="text-danger" v-if='submitted'>{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>       
                    <label label-for="Last Name">Last Name<span class="required">*</span></label>  
                    <validation-provider
                      #default="{ errors }"
                      name="last name"
                      rules="required|max:25|min:2"
                    >
                      <b-form-input
                        v-model="lastName"
                        :disabled='isLoading'
                        placeholder="Enter Last Name"
                        :maxlength='30'
                        @keypress="isOnlyCharacter($event)"
                        @copy.prevent 
                        @paste.prevent 
                        ondragover="return false"
                      />
                      <small class="text-danger" v-if='submitted'>{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>       
                    <label label-for="Email Address">Email Address<span class="required">*</span></label>
                    <validation-provider
                      #default="{ errors }"
                      name="email address"
                      rules="required|email|max:100"
                    >
                      <b-form-input
                        v-model="emailValue"
                        :disabled='isLoading'
                        type="email"
                        :maxlength='100'
                        placeholder="Enter Email Address"
                        @input="checkEmailAddress"
                      />
                      <small class="text-danger" v-if='submitted'>{{ errors[0] }}</small>
                      <small class="text-danger" v-show="!errors[0] && isEmailExist !== ''">{{isEmailExist}}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <label label-for="Mobile No.">Mobile No.<span class="required">*</span></label>  
                      <div class="mobileandcountry">
                        <v-select
                            v-model="selectedCode"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="countryCodeList.allCountries"
                            @input="changeCountryCode(selectedCode)"
                            label="text"
                            placeholder="Select"
                            class="selectcountrynumber"
                          >
                          <template #option="{ text }">
                            <div class="d-flex align-items-center justify-content-between">
                              <span>
                                <span> {{ text }}</span>
                              </span>
                            </div>  
                          </template>
                        </v-select>                                  
                        <b-input-group class="number123">
                            <validation-provider
                              #default="{ errors }"
                              name="mobile no."
                              rules="required|max:15"
                              class="enternumber"
                            >                    
                            <b-form-input
                              placeholder="Enter Number"
                              v-model="mobileno"
    
                              :maxlength="15"
                              id='Mobile No.'
                              @keypress="isOnlyNumber($event)"
                              @input="checkPhoneNumber($event)"
                              @copy.prevent 
                              @paste.prevent 
                              ondragover="return false"
                            />
                            <small class="text-danger leftnumber validationnumber" v-if='submitted && MobileNo_Error == ""'>{{ errors[0] }}</small>   
                            <small class="text-danger leftnumber validationnumber" v-show="MobileNo_Error !== ''">{{ MobileNo_Error}}</small>                          
                            </validation-provider>  
                        </b-input-group> 
                      </div>   
                         
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Date of Birth" label-for="dob">                 
                    <flat-pickr
                      v-model="birthDate"
                      class="form-control datetime_picker dateandtime"
                      placeholder="DD/MM/YYYY"
                      :disabled='isLoading'
                      @on-change="doSomethingOnChange"
                      :config="{ 
                        enableTime: false,
                        dateFormat: 'd-m-Y',
                        minDate: new Date('01/01/'+`${parseInt(new Date().getFullYear()) - 100}`),
                        maxDate: new Date().fp_incr(-1095)
                      }"

                    />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>       
                    <label label-for="gender">Gender<span class="required">*</span></label>
                    <div class="demo-inline-spacing schedulecampaign_radios">
                      <b-form-radio
                        v-model="GenderSelected"
                        name="some-radios"
                        value="Male"
                      >
                        Male
                      </b-form-radio>
                      <b-form-radio
                        v-model="GenderSelected"
                        name="some-radios"
                        value="Female"
                      >
                        Female
                      </b-form-radio>
                      <b-form-radio
                        v-model="GenderSelected"
                        name="some-radios"
                        value="Other"
                      >
                        Other
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                    <b-form-group label="Location" label-for="location"> 
                    <vue-google-autocomplete
                    id="map2"
                    ref="toAddress"
                    classname="form-control"
                    placeholder="Enter Location"
                    v-on:placechanged="getAddressData"
                    types="(cities)"
                  >
                  </vue-google-autocomplete>
                  </b-form-group>

                   
                </b-col>
                <b-col md="3" class="tutor_type_values">
                  <b-form-group label="Time Zone" label-for="timezone">
                    <v-select
                      v-model="timezoneselected"
                      :disabled='isLoading'
                      :clearable='false'
                      label="text"
                      placeholder='Select'
                      :options="timezoneoptions"
                      class="language12"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3" class="tutor_type_values">
                  <b-form-group label="School" label-for="school">
                    <v-select
                      v-model="schololSelectedValue"
                      :disabled='isLoading'
                      :clearable='false'
                      label="text"
                      placeholder='Select'
                      :options="SchoolOptions"
                      class="language12"
                      @input="schoolselected = schololSelectedValue.value"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="3" class="tutor_type_values">
                  <b-form-group label="Curriculum" label-for="syllabus">
                    <v-select
                      v-model="syllabusselectedValue"
                      :disabled='isLoading'
                      :clearable='false'
                      label="text"
                      placeholder='Select'
                      :options="SyllabusOptions"
                      class="language12"
                      @input="syllabusselected = syllabusselectedValue.value, gradeDisplay(syllabusselected)"
                    />
                  </b-form-group>
                </b-col>
                 <b-col md="3" class="tutor_type_values">
                <b-form-group label="Grade" label-for="grade" class="tutor_type_values">
                    <v-select
                      v-model="gradeSelectedValue"
                      :disabled='disableGrade'
                      :clearable='false'
                      label="text"
                      placeholder='Select'
                      :options="gradeOptions"
                      class="language12"
                      @input="gradeSelected = gradeSelectedValue.value, gradeDisplayconsole() "
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>           
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import * as insert from '@/utils/FirebaseQueries/insertQueries/insertQueries.js'
import * as update from '@/utils/FirebaseQueries/updateQueries/updateQueries.js'
import { uploadImageFile, removeImageThroughURL } from '@/utils/FirebaseQueries/storageQuery/storageQueries'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {dbCollections} from "@/utils/firebaseCollection.js";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import vSelect from "vue-select";
import {
  BCard,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormCheckbox,
  BFormRadio,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroupAppend,
  BFormDatepicker,
  BFormFile,
  BFormSelect,
  BAvatar,
  BSpinner,
  BDropdown, 
  BDropdownItem
} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'
import fire from "@/utils/firebaseInit.js"
const db = fire.firestore()
import { required, email,max } from "@validations";
import flatPickr from "vue-flatpickr-component";
import { mapGetters } from 'vuex';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import firebase from "firebase";
import moment from "moment";
import "moment-timezone";
import countryTelData from 'country-telephone-data';
import {phone} from 'phone';
import {ValidationFunction} from '@/utils/globalValidations'
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import axios from "axios";
import {errorEmail} from "@/utils/apiRequests.js"
import {createUserEmail} from './service.js'
import timeZoneData from '@/utils/TimeZoneData.js'
const currentFileName = "src/views/Users/User/Createuser.vue"
import {createUserAuth} from './serviceAuth.js'
export default {
  components: {
    ValidationProvider,
    VueGoogleAutocomplete,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BCard,
    flatPickr,
    BFormRadio,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea,
    BInputGroupAppend,
    BFormDatepicker,
    BFormSelect,
    BFormFile,
    BAvatar,
    BSpinner,
    BDropdown, 
    BDropdownItem,
    vSelect,
  },
  directives:{
    Ripple
  },
  data() {
    return {
      schololSelectedValue: "",
      syllabusselectedValue: "",
      gradeSelectedValue: "",
      uploadedData:'',
      countryCodeList: countryTelData,
      selectedDialCode:'',
      dialCodeObj:null,
      direction:false,
      isLoading:false,
      isUpdating:false,
      emailValue: "",
      firstName: "",
      lastName:"",
      required,
      email,
      max,
      mobileno:"",
      location:"",
      usertype_selected: ["A", "C"],
      GenderSelected: "male",
      date: null,
      file: null,
      timezoneselected: null,
      timezoneoptions: [],
      schoolselected: null,
      syllabusselected: null,
      profileImg:null,
      submitted:false,
      MobileNo_Error:'',
      isEmailExist:'',
      countryCode:null,
      birthDate: null,
      profile_img_error:'',
      selectedDob:'',
      domainLink:process.env.VUE_APP_FIREBASE_API_URL_WEBAPPDOMAINLINK,
      selectedCountryCode:{
          dialCode: "1",
          format: "+. (...) ...-....",
          iso2: "us",
          name: "United States",
          priority: 0,
          text: "+1(United States)",
          value: "+1"
          
      },
      selectedCode : '',
      gradeSelected: null,
      gradeOptions: [
        {value:null,text:"Select",selected:true,disabled: true}
      ],
      disableGrade:false,
    };
  },
  computed:{
      ...mapGetters({schools:'settings/getSchools',syllabus:'settings/getSyllabus',users:'userManagement/getUsers'}),  
      SchoolOptions(){
        try {
            var arr = [{value:null,text:"Select",selected:true,disabled: true}];
            this.schools.forEach(school => {
                var schoolObj = {
                    value: school.id,
                    text:school.schoolName                      
                }
                arr.push(schoolObj)
            });
            return arr
        } catch (error) {
            console.error("error",error)
            errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "SchoolOptions",
            errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          })
        }
      },
      SyllabusOptions(){
        try {
            var arr = [{value:null,text:"Select",selected:true,disabled: true}];
            this.syllabus.forEach(syl => {
                var syllabusObj = {
                    value: syl.id,
                    text:syl.syllabusName                      
                }
                arr.push(syllabusObj)
            });
            return arr
        } catch (error) {
            console.error("error",error)
            errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "SyllabusOptions",
            errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          })
        }
      }      
  },
  created(){
    var self = this; 
    self.selectedCode = "+1";
    self.$root.$emit('stopSpinner');
    try{
    let tempDialCode = self.countryCodeList.allCountries;
    tempDialCode.forEach((vals,index)=>{
      vals.text = "+"+vals.dialCode+"("+vals.name+")";
      vals.value = "+"+vals.dialCode;
      if (this.syllabusselected===null) {
        this.disableGrade = true;
      }
    }) 
    let timezone = timeZoneData;
    self.timezoneoptions = [];
    for (let i = 0; i < timezone.length; i += 1) {
         self.timezoneoptions.push(`${timezone[i]} (${moment().tz(timezone[i]).format('Z z')})`)
    }
    self.timezoneoptions.push({value:null,text:"Select",selected:true,disabled: true})
    }catch(error){
      console.error(error)
      errorEmail({
        projectName : process.env.VUE_APP_PROJECTNAME,
        environment : process.env.NODE_ENV,
        fileName : currentFileName,
        hookName : "created",
        errorMessage : error.message
      },(res)=>{
        if(res.error){
            console.error(res.error)
        }
      })
    }  
    self.$root.$on('saveUsers',()=>{   
      
      try{   
        self.submitted = true;
        if(self.$refs.createUser){     
          self.$refs.createUser.validate().then((success) => {
            if (success && self.isEmailExist == '' && self.MobileNo_Error == '' && self.profile_img_error == '' && self.isLoading == false) {
              try{
                self.isLoading = true;
                var addObject = null;
                const imageUploaded = new Promise((resolve,reject)=>{
                  try{
                    let fname = self.firstName ? self.firstName : '';
                    let lname = self.lastName ? self.lastName : '';
                    let fullname = fname.toLowerCase() +' '+ lname.toLowerCase();
                    let schoolVal = null;
                    let schoolId = '';
                    let sylId = '';
                    let sylabusVal = null;
                    let gradeVal = null;
                    let schoolInd = self.SchoolOptions.findIndex((ele)=>{   
                      if(ele.value !== null && self.schoolselected !== null){                 
                        return ele.value == self.schoolselected
                      }
                    })
                    if(schoolInd > -1){        
                      schoolVal = {
                      'id': self.SchoolOptions[schoolInd].value,
                      'schoolName':self.SchoolOptions[schoolInd].text
                      };
                      schoolId = self.SchoolOptions[schoolInd].value
                    }

                    let syllabusInd = self.SyllabusOptions.findIndex((ele)=>{
                      if(ele.value !== null && self.syllabusselected !== null){
                        return ele.value == self.syllabusselected
                      }
                    })
                    if(syllabusInd > -1){        
                      sylabusVal =  {
                      'id': self.SyllabusOptions[syllabusInd].value,
                      'syllabusName':self.SyllabusOptions[syllabusInd].text
                      };
                      sylId = self.SyllabusOptions[syllabusInd].value;  
                    } 
                          
                    let dateOfBirth = '';
                    if(self.selectedDob !== undefined && self.selectedDob !== ''){
                      dateOfBirth = new Date(self.selectedDob);
                    }  
                    if (self.gradeSelected !== null) {
                      let gradeInd = this.gradeOptions.findIndex((ele)=>{
                        if(ele.value !== null && self.gradeSelected !== null){
                          return ele.value == self.gradeSelected
                        }
                      })
                      if (gradeInd > -1) {
                           gradeVal = {
                          'id': self.gradeOptions[gradeInd].value,
                          'gradeName': self.gradeOptions[gradeInd].text                            
                        }  
                      }
                    }
                    addObject = {
                      'lastName':lname,
                      'firstName':fname,              
                      'activeSessions': [],
                      'areaOfInterest': [],                            
                      'createdAt': firebase.firestore.FieldValue.serverTimestamp(),
                      'dateOfBirth': dateOfBirth ? dateOfBirth : '',
                      'email':self.emailValue,
                      'gender':self.GenderSelected,
                      'hasChildren': [],
                      'isChild':false,
                      'isEmailVerified':false,
                      'isParent':false,
                      'isPhoneNumberVerified':false,
                      'location':self.location,                            
                      'profilePicture':'',
                      'myReferralCode':fname + Math.floor(1000 + Math.random() * 9000),
                      'school':schoolId,
                      'schoolDetails':schoolVal,
                      'status':0,
                      'syllabus':sylId,
                      'syllabusDetails':sylabusVal,
                      'timeZone':self.timezoneselected ? self.timezoneselected : null,
                      'updatedAt':firebase.firestore.FieldValue.serverTimestamp(),
                      'userId':'',
                      'userRole':['learner'],
                      'phoneNumber':self.mobileno,
                      'countryCode':this.selectedCountryCode ? this.selectedCountryCode.dialCode : '',
                      'id':'',
                      'currentCredit':0,
                      'isUserBasicInfoCompleted':false,
                      'gradeDetail': gradeVal,
                      'grade': self.gradeSelected,
                      'sendChatNotification': true,
                      'sendEmailNotification': true,
                      'sendPushNotification':true
                    }                  
                    if(self.profileImg !== null){
                      uploadImageFile("userManagement",self.file.name,self.file,res=>{
                        resolve({status:true,msg:'done','data':res})
                      })
                    }else{
                      resolve({'msg':'no image','status':true,'data':null})
                    }
                  }catch(error){
                    self.isLoading = false;
                    self.$root.$emit('stopSpinner');
                    errorEmail({
                      projectName : process.env.VUE_APP_PROJECTNAME,
                      environment : process.env.NODE_ENV,
                      fileName : currentFileName,
                      hookName : "created",
                      errorMessage : error.message
                    },(res)=>{
                      if(res.error){
                          console.error(res.error)
                      }
                    })
                    reject({'msg':'no image','status':false,'data':error})
                  }
                }) 
                imageUploaded.then(value =>{
                  try{
                    if(value.status == true){   
                      let imageData = null;
                      addObject.profilePicture = '';
                      if(value.data && value.data != null && value.msg == 'done'){
                        imageData = value.data;
                        addObject.profilePicture=imageData.imageUrl ? imageData.imageUrl : '';  
                      }
                      let userDocId = ""
                      createUserAuth({
                        isPassword : false,
                        email : self.emailValue,
                        phoneNumber : "+" +  addObject.countryCode + addObject.phoneNumber
                      },(userAuth) => {
                        self.isLoading = false;
                        if (!userAuth.status) {
                            self.isLoading = false;
                            return;                   
                          }
                          else{
                            if (userAuth.id !== undefined) {
                              userDocId = userAuth.id
                              self.isLoading = false;
                            } else {
                              self.isLoading = false;
                              return;
                            }
                          }
                          let userId = this.generateUid(userDocId) 
                          addObject.myReferralCode = userId;
                          addObject.userId = ''
                          addObject.userId = userId;
                          insert.addDataToRootCollectionWithDocKey(dbCollections.USERS,userDocId,addObject,(response)=>{
                              const updateUser = {
                                id : userDocId
                              }
                              updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,userDocId,updateUser,(res) => {
                              createUserEmail({
                                    email: addObject.email,
                                    userName: addObject.firstName + " " +addObject.lastName,
                                    domainLink: self.domainLink,
                                    uid:  userDocId,
                                    editEmail: false,
                                    newUser : true, 
                              },() => {})
                              })
                            
                              self.$root.$emit('showToastMessage','User added successfully.','success');
                              self.$router.push(`/user-management/users`)
                              self.$refs.createUser.reset();
                              self.emailValue= "";
                              self.firstName= "";
                              self.lastName="";      
                              self.mobileno="";
                              self.location="";
                              this.$refs.toAddress.clear();
                              self.GenderSelected= "male";
                              self.file=null;
                              self.uploadedData = '';
                              self.profileImg=null;
                              self.$refs.img.filesx = [];
                              self.$refs.img.srcs = [];
                              self.$refs.img.itemRemove = [];
                              self.timezoneselected= null;      
                              self.schoolselected= null;
                              self.syllabusselected= null;                              
                              self.submitted=false;                       
                              self.countryCode=null;
                              self.birthDate=null;
                              self.profile_img_error = '';
                              self.resetInputs()
                              self.isLoading = false;
                              self.$root.$emit('stopSpinner');
                          })                          
                      })
                    }else{
                      self.isLoading = false;
                      self.$root.$emit('stopSpinner');
                      self.$root.$emit('showToastMessage','Please add your correct information.','danger');
                    }
                  }catch(error){
                    self.isLoading = false;
                    self.$root.$emit('stopSpinner');
                    self.$root.$emit('showToastMessage','Please try again later.','danger');
                    console.error(error,"ReferenceError")  
                    errorEmail({
                      projectName : process.env.VUE_APP_PROJECTNAME,
                      environment : process.env.NODE_ENV,
                      fileName : currentFileName,
                      hookName : "createUser",
                      errorMessage : error.message
                    },(res)=>{
                      if(res.error){
                          console.error(res.error)
                      }
                    })
                  }
              }).catch(err =>{
                    self.isLoading = false;
                    console.error(err)
                    self.$root.$emit('stopSpinner');
                    self.$root.$emit('showToastMessage','Please try again later.','danger');
                });               
              }catch(error){
                self.isLoading = false;
                self.$root.$emit('stopSpinner');
                self.$root.$emit('showToastMessage','Please try again later.','danger');
                console.error(error,"ReferenceError")
                  errorEmail({
                    projectName : process.env.VUE_APP_PROJECTNAME,
                    environment : process.env.NODE_ENV,
                    fileName : currentFileName,
                    hookName : "createUser",
                    errorMessage : error.message
                  },(res)=>{
                    if(res.error){
                        console.error(res.error)
                    }
                  })            
              }
            }
            else if(success == false || self.isEmailExist != '' || self.MobileNo_Error != ''){
              self.isLoading = false;
              self.$root.$emit('stopSpinner');
            }else{
              self.isLoading = false;
              self.$root.$emit('stopSpinner');
            }
          });
        }
      }catch(error){
        self.isLoading = false;
        self.$root.$emit('stopSpinner');
        console.error(error,"ERROR")
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "saveUsers",
          errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          })
      }
    })
  },
  methods: {
    gradeDisplayconsole(){
     var self = this;
     let gradeVal= null;
                    if (self.gradeSelected !== null) {
                      let gradeInd = this.gradeOptions.findIndex((ele)=>{
                        if(ele.value !== null && self.gradeSelected !== null){
                          return ele.value == self.gradeSelected
                        }
                      })
                      if (gradeInd > -1) {
                           gradeVal = {
                          'id': self.gradeOptions[gradeInd].value,
                          'gradeName': self.gradeOptions[gradeInd].text                            
                        }  
                      }
                    }
    },
    gradeDisplay(id){
      var self = this;
      self.gradeOptions = []
      self.disableGrade = false;
      self.gradeSelected = null;
      self.gradeSelectedValue = null;
      self.gradeOptions.push({value: null,text:"Select", disabled:true});
      db.collection(dbCollections.SETTINGS).doc('setting').collection(dbCollections.GRADE).where("curriculumId","==",id).get().then((gradeRes)=>{
        if (!gradeRes.empty) {
          gradeRes.forEach((data)=>{
            self.gradeOptions.push({value:data.data().id , text:data.data().gradeName});
          })
        }else {
            self.disableGrade = true;
            self.gradeSelected = null;
            self.gradeSelectedValue = null;
        }
      }).catch((error)=>{
        console.error(error);
      })
    },
    changeCountryCode(country){
      if(country !== null && country != ''){
        this.selectedCountryCode = country;
        this.selectedCode = country.value;
        this.checkPhoneNumber(this.mobileno);
      }else{
        this.selectedCode = '+1'
      }
    },
   
    resetUploader(){
      try{
      this.profileImg = null;
      this.profile_img_error='';
      this.file=null;
      this.uploadedData=''; 
      }catch(error){
        console.error(error,"error******************")
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "resetUploader",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        })
      }
    },
    successUpload(){
      this.$vs.notify({color:'success',title:'Upload Success',text:'Lorem ipsum dolor sit amet, consectetur'})
    },
    generateUid(uid){
      try{
        var result           = '';
        var characters       = uid;
        var charactersLength = characters.length;
        for ( var i = 0; i < 6; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
   
     
      }catch(error){
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
        environment : process.env.NODE_ENV,
        fileName : currentFileName,
        hookName : "generateUid",
        errorMessage : error.message
      },(res)=>{
        if(res.error){
            console.error(res.error)
        }
      })
        console.error(error,"?")
      }
    },    
    resetInputs(){
      try{
        var self = this;
        self.emailValue= "";
        self.firstName= "";
        self.lastName="";      
        self.mobileno="";
        self.location="";
        self.GenderSelected= "male";
        self.file=null;
        self.uploadedData='';
        self.$refs.img.filesx = [];
        self.$refs.img.srcs = [];
        self.timezoneselected= null;      
        self.schoolselected= null;
        self.syllabusselected= null;
        self.profileImg=null;
        self.submitted=false;                       
        self.countryCode=null;
        self.birthDate=null;
        self.resetUploader()
      }catch(error){
        console.error(error);
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "resetInputs",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        })
      }
    },
    checkEmailAddress(e){
    let self = this;
    let searchVal = e;      
     if(self.users.length > 0){            
        let searchEmailInd = self.users.findIndex((values)=>{
          return values.email == searchVal
        })
        if(searchEmailInd > -1){
          self.isEmailExist = 'The email address is already exist';
        }else{
          self.isEmailExist = '';
        }
     }
    },
    CheckFile(){
      var self = this; 
      let ind = self.$refs.img.filesx.length - 1;
      let e = self.$refs.img.filesx[ind]; 
      self.file = e;
      try{ 
        if(e && e !== null && self.isLoading == false){   
          if(e.type == 'image/png' || e.type == 'image/jpg' || e.type == 'image/jpeg'||e.type == 'jpg' || e.type =='png' ||e.type == 'jpeg'){
            self.profile_img_error = '';
            var reader = new FileReader();
            reader.onload = (event) => {
              self.profileImg = event.target.result;
            }
            reader.readAsDataURL(e);
          }else{
            e = null;
            self.file = null
            self.profileImg = null;
            self.profile_img_error = 'Only JPG| JPEG| PNG File Upload Allowed'
          }  
        }
      }catch(error){
        e = null;
        self.file = null;
        self.profileImg = null;
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "CheckFile",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        })
      } 
    },   
    CheckMobileNo(event){
      var self = this;
      try{
        if(event.isValid == false){
          self.MobileNo_Error = 'The mobile no. is invalid';
        }else{
          let searchEmailInd = self.users.findIndex((values)=>{
            return values.phoneNumber == event  
          })
          if(searchEmailInd > -1){
            self.MobileNo_Error = 'The mobile no. is already exist';
          }else{
            self.MobileNo_Error = '';
          }
        }
      }catch(error){
        console.error(error);
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "CheckMobileNo",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        })
      }
    },  
    changeDialCOde(event){
      try{
        let index = this.countryCodeList.allCountries.findIndex((element)=>{
          return "+" +element.dialCode+"("+element.name+")" == event
        })
        if(index > -1){
          this.dialCodeObj = this.countryCodeList.allCountries[index];
          this.checkPhoneNumber(this.mobileno)
        }
      }catch(error){
        console.error(error);
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "changeDialCOde",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        })
      }
    },
    checkPhoneNumber(event){
      var self = this;
      try{
        if(this.selectedCountryCode !== null){
          let phone_Number_details = phone(JSON.stringify(event), {country: this.selectedCountryCode.iso2 ? this.selectedCountryCode.iso2 : ''}); 
          if(phone_Number_details.isValid !== undefined && phone_Number_details.isValid == false){
            if(this.mobileno !== ''){
              this.MobileNo_Error = 'The mobile no. is invalid';
            }
          }else{
            this.MobileNo_Error = ''
             try{
              if(event.isValid == false){
                self.MobileNo_Error = 'The mobile no. is invalid';
              }else{
                let searchEmailInd = self.users.findIndex((values)=>{
                  return values.phoneNumber == event  
                })
                if(searchEmailInd > -1){
                  self.MobileNo_Error = 'The mobile no. is already exist';
                }else{
                  self.MobileNo_Error = '';
                }
              }
            }catch(error){
              console.error(error)
            }
          }
        } 
      }catch(error){
        console.error(error);
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "checkPhoneNumber",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        })
      }
    },
    /** fname and lname validation **/
    isOnlyCharacter(event) {
      ValidationFunction.OnlyCharacter(event);
    },
    /** mobile number validation **/
    isOnlyNumber(event){
      ValidationFunction.OnlyNumber(event);
    }, 
    doSomethingOnChange(event){
      if(event !== null){
      let lastInd = parseInt(event.length) - 1;
      this.selectedDob = event[lastInd];
      }
    },
    getAddressData(addressData, placeResultData, id){  //Location (Karan)
         this.location = placeResultData.formatted_address
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
