<template>
    <div>
        <b-spinner
        variant="primary"
        v-if="isSpinner"
        large
        class="pageLoadSpinners"
        />
        <div v-show="!isSpinner">
        <b-spinner
            variant="primary"
            v-if="isUpdating"
            large
            class="pageLoadSpinners"
            style="position: fixed"
        />
        
        <div class="card user_table plan_table">
            <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                    <div class="d-flex align-items-center">
                        <b-form-input
                        v-model="searchTerm"
                        placeholder="Search..."
                        type="text"
                        class="d-inline-block"
                        />
                    </div>
                </b-form-group>
            </div>
            <vue-good-table
                :columns="dynamicColumns"
                :rows="rows"
                :rtl="direction"
                :search-options="{
                enabled: true,
                externalQuery: searchTerm,
                }"
                :select-options="{
                enabled: true,
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                }"
                :pagination-options="{
                enabled: true,
                perPage: pageLength,
                }"
                >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'action'" class="action_field">
                        <b-button variant="flat-dark"  @click="editPlan(props.row.id)">
                            <img :src="require('@/assets/images/erflog/Edit.png')" alt="image"/>
                        </b-button>
                        <b-button
                            variant="flat-dark"
                            class="ml-2"
                            v-on:click="deleteData(props.row.id)"
                            >
                            <img
                                :src="require('@/assets/images/erflog/Delete.png')"
                                alt="image"
                            />
                        </b-button>
                    </span>

                    <span v-else-if="props.column.field === 'planamount'" class="action_field">
                        <p class="text-right m-0">{{ props.row.planamount.toFixed(2) }}</p>
                    </span>

                    <span v-else-if="props.column.field === 'netamount'" class="action_field">
                        <p class="text-right m-0">{{ props.row.netamount }}</p>
                    </span>

                    <span v-else-if="props.column.field === 'tag'" class="action_field">
                        <span v-if="props.row.tag.length >= 1">{{ props.row.tag }}</span>
                        <span v-else>-</span>
                    </span>
                    <div
                        v-else-if="props.column.field === 'description'"
                        class="wrap-break-word"
                        v-show="true"
                        >
                        <span v-html="limitTo(props.row.description)"></span>
                        <a
                            class="readmore"
                            v-b-modal="'readmore' + props.row.id"
                            v-show="!props.row['isEditable'] && props.row.description.length >= 30"
                            
                            >Read more</a
                        >
                        <UserModal title="Description" :text="props.row.description" :id="props.row.id" />
                    </div>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> 
                        <span v-if="props.total === 0"> Showing 0 </span>
                        <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                        to
                        <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                        <span v-else>{{props.total}} of {{ props.total }} entries</span>
                        </span>

                            <div class="d-flex align-items-center mb-1 mt-0 showentry">
                            <span>Show</span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['10', '20', '30', '40', '50']"
                                class="mx-1"
                                @input="
                                (value) => props.perPageChanged({ currentPerPage: value })
                                "
                            />
                            <span>entries</span>
                            </div>
                    </div>
                    <div>
                        <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :current-page="currentPage"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="
                            (value) => {
                            props.pageChanged({ currentPage: value });
                            currentPage = value
                            }
                        "
                        >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                        </b-pagination>
                    </div>
                    </div>
                </template>
            </vue-good-table>
        </div>
    </div>
    </div>
</template>

<script>
import {
    BButton,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BTabs,
    BTab,
    BCard,
    BModal,
    VBModal,
    BSpinner,
} from "bootstrap-vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { VueGoodTable } from "vue-good-table";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import { dbCollections } from "@/utils/firebaseCollection";
import UserModal from "@/@core/components/modals/ReadMoreModal.vue";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Setting/Planmanagement.vue"
export default {
    components: {
        VueGoodTable,
        BSpinner,
        UserModal,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BRow,
        BCol,
        BForm,
        BCard,
        BButton,
        BTabs,
        BTab,
        BModal,
    },
    data() {
        return {
            direction: false,
            isSpinner: false,
            isUpdating: false,
            perPage: 10,
            pageOptions: [10, 20, 30, 40, 50],
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            filter: null,
            filterOn: [],
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: "Plan Name",
                    field: "planname",
                },
                {
                    label: "No. of Credits",
                    field: "nocredits",
                },
                {
                    label: "Plan Amount($)",
                    field: "planamount",
                },
                {
                    label: "Discount(%)",
                    field: "discount",
                },
                {
                    label: "Net Amount($)",
                    field: "netamount",
                },
                {
                    label: "Description",
                    field: "description",
                },
                {
                    label: "Tag",
                    field: "tag",
                },
                {
                    label: "Action",
                    field: "action",
                },
            ],
            searchTerm: "",
        };
    },
    created() {
        this.isSpinner = true;
        var self = this;
        let getPlanMangFound = this.getPlanManagamentList.length != 0;
        if(!getPlanMangFound){
            this.getPlanManagament().then(res=>{
                  this.isSpinner = false
            }).catch(error=>{
                console.error("error",error)
                this.isSpinner = false
            })
            
        }
        else{
            this.getPlanManagament()
            this.isSpinner = false
        }
    },
    computed: {
        ...mapGetters({
            getPlanManagamentList: "planManagament/getPlanManagament",
            getsettingList : 'settings/getsettingArrayFeild',
        }),
        
        currency(){
            if (Object.keys(this.getsettingList).length != 0) {

                return this.getsettingList.currency.slice(4)
            } else {
                return ""
            }
        },
        
         dynamicColumns(){
            this.columns[2].label = `Plan Amount(${this.currency})`
            this.columns[4].label = `Net Amount(${this.currency})`	
            return this.columns
        },
        rows() {
            try {
                var self = this;
                let planArr = [];
                for (var i = 0; i < this.getPlanManagamentList.length; i++) {
                var obj = {
                    planname: this.getPlanManagamentList[i].planName,
                    nocredits: this.getPlanManagamentList[i].numberOfCredits,
                    planamount: this.getPlanManagamentList[i].planAmount,
                    netamount: (this.getPlanManagamentList[i].netAmount).toFixed(2),
                    discount: (this.getPlanManagamentList[i].discountInPercentage).toFixed(2),
                    description: this.getPlanManagamentList[i].description,
                    tag: this.getPlanManagamentList[i].tag,
                    id: this.getPlanManagamentList[i].id,
                };
                planArr.push(obj);
                }
                return planArr;
            } catch (error) {
                console.error("error", error);
                errorEmail({
                    projectName : process.env.VUE_APP_PROJECTNAME,
                    environment : process.env.NODE_ENV,
                    fileName : currentFileName,
                    hookName : "rows",
                    errorMessage : error.message
                    },(res)=>{
                    if(res.data){
                    }else if(res.error){
                        console.error(res.error)
                    }
                })
            }
        },
    },
    methods: {
        ...mapActions({
            'getPlanManagament' : 'planManagament/getPlanManagamentData',
        }),

        ...mapMutations({
            'testMutation' : "planManagament/testMutation"
        }),

       
        deleteData(id) {
            try {
                var self = this;
                self.$bvModal
                .msgBoxConfirm("Are you sure you want to Delete it?", {
                    title: "Delete",
                    class: "test",
                    okVariant: "primary",
                    okTitle: "Yes",
                    cancelTitle: "No",
                    cancelVariant: "outline-primary",
                    hideHeaderClose: false,
                    centered: true,
                    footerClass: "approve-footer",
                    headerClass: "approve-header",
                    bodyClass: "approve-body",
                })
                .then((value) => {
                    if (value) {
                        self.isSpinner = true;
                        const planObject = {
                            isDeleted: true,
                            updatedAt: new Date(),
                        };
                        var index = this.getPlanManagamentList.findIndex(item => {
                            return item.id == id  
                        })
                        updateFirebase.updateRootSubCollectionDataWithDocId(
                            dbCollections.SETTINGS,
                            "setting",
                            dbCollections.PLAN,
                            id,
                            planObject,
                            (res) => {
                                self.$nextTick(() => {
                                    self.$root.$emit(
                                        "showToastMessage",
                                        "Plan deleted successfully.",
                                        "success"
                                    );
                                    
                                    this.testMutation(index);
                                    this.isSpinner= false;
                                });
                            }
                        );
                    }
                });
            } catch (error) {
                console.error("error", error);
                errorEmail({
                    projectName : process.env.VUE_APP_PROJECTNAME,
                    environment : process.env.NODE_ENV,
                    fileName : currentFileName,
                    hookName : "deleteData",
                    errorMessage : error.message
                    },(res)=>{
                    if(res.data){
                    }else if(res.error){
                        console.error(res.error)
                    }
                })
            }
        },

        editPlan(id){
            this.$router.push({name:'edit-plan', params: { id: id }})
        },

        limitTo: function (value) {
            if(value){
                if (value.length < 40){
                    return value
                }else{
    
                    return value.substring(0, 30) + "...";
                }
            }else{
                return "-";
            }
        }
    },
};
</script>
