<template >
    <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />

      <div class="card user_filter cancel_session">
        <h2>Filters</h2>

        <b-form @submit.prevent>
          <b-row>
            <b-col xl="3" lg="3" md="3">
              <label>Learner Name</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="learner_selected"
                variant="outline-secondary"
                class="w-100 dropdownfilter"
              >
                <b-dropdown-item
                  v-for="item in learnerNameOptions"
                  :key="item.value"
                  @click="learner_selected = item.text"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
            <b-col xl="3" lg="3" md="3">
              <label>Tutor Name</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="tutorNameSelected"
                variant="outline-secondary"
                class="w-100 dropdownfilter"
              >
                <b-dropdown-item
                  v-for="item in tutorNameOptions"
                  @click="tutorNameSelected = item.text"
                  :key="item.value"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <div class="card user_table rejected">
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1"></label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search..."
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="filterRows"
          @on-cell-click="onCellClick"
          class="cursor-pointer"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'bookingId'" class="text-nowrap">
          <router-link :to="{ name: 'booking-detail-reject', params: { id: props.row.id}}">{{props.row.bookingId}}</router-link>
            </span>
            <span
              v-else-if="props.column.field === 'learnerChildName'"
              class="childname text-nowrap"
            >
              <b-avatar :src="(props.row.learnerDetails &&  props.row.learnerDetails.profileImage) ? props.row.learnerDetails.profileImage :  ''" class="user_img" />
              <router-link
                :to="{
                  name: 'user-details',
                  params: {
                      id: props.row.learnerId
                  },
                }"
                class="text-nowrap"
                >{{ props.row.learnerChildName }}</router-link
              >
            </span>
            <span
              v-else-if="props.column.field === 'tutorNameDetail'"
              class="childname text-nowrap"
            >
              <b-avatar :src="(props.row.tutorDetails &&  props.row.tutorDetails.profilePicture) ? props.row.tutorDetails.profilePicture :  ''" class="user_img" />
              <router-link
              :to="{
                  name: 'user-details',
                  params: {
                      id: props.row.tutor
                  },
              }"
                class="text-nowrap"
                >{{ props.row.tutorNameDetail }}</router-link
              >
            </span>
            <span v-else-if="props.column.field === 'status'" class="status">
              <b-badge :variant="status[1][props.row.status]">
                {{ status[0][props.row.status] }}
              </b-badge>
            </span>
            <span
              v-else-if="props.column.field === 'cancellationReason'"
              class="cancel_reason"
            >

                <span v-if="props.row.cancellationReason" v-html="limitTo(props.row.cancellationReason)"></span>
                <a
                    class="readmore"
                    v-b-modal="'readmore' + String(props.row.id)"
                    v-show="!props.row['isEditable'] && props.row.cancellationReason && props.row.cancellationReason.length >= 70"
                    
                    >Read more</a
                >
                <UserModal v-if="props.row.cancellationReason" title="Cancel Reason" :text="props.row.cancellationReason" :id="props.row.id" />
            </span>
            <span v-else-if="props.column.field === 'sessionTitle'" class="cancel_reason">
              {{ (props.row.sessionDetails && props.row.sessionDetails.name) ? props.row.sessionDetails.name : "" }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
         <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> 
                  <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to
                  <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                  <span v-else>{{props.total}} of {{ props.total }} entries</span>
                </span>

                    <div class="d-flex align-items-center mb-1 mt-0 showentry">
                      <span>Show</span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10', '20', '30', '40', '50']"
                        class="mx-1"
                        @input="
                          (value) => props.perPageChanged({ currentPerPage: value })
                        "
                     />
                      <span>entries</span>
                    </div>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :current-page="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => {
                      props.pageChanged({ currentPage: value });
                      currentPage = value
                    }
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
        </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>
<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
  BSpinner,
  VBTooltip
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { mapActions, mapGetters } from 'vuex'
import UserModal from "@/@core/components/modals/ReadMoreModal.vue";
import Ripple from "vue-ripple-directive";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/SessionsManagement/RejectedSession(s)/rejectedSessionList.vue"

export default {
    name:'rejectedSessionsList',
    directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BButton,
    BSpinner,
    VBTooltip,
    UserModal
  },
    data() {
        return {
            direction: false,
            isSpinner: false,
            isUpdating: false,
            rowsArray:[],
            perPage: 10,
            pageOptions: [10, 20, 30, 40, 50],
            pageLength:10,
            totalRows: 1,
            currentPage: 1,
            sortBy: "",
            sortDesc: false,
            sortDirection: "asc",
            columns: [
            {
                label: "Booking ID",
                field: "bookingId",
            },
            {
                label: "Session(s) Title",
                field: "sessionTitle",
            },
            {
                label: "Learner Name",
                field: "learnerChildName",
            },
            {
                label: "Tutor Name",
                field: "tutorNameDetail",
            },
            {
                label: "Cancellation Reason",
                field: "cancellationReason",
            },
            ],
            searchTerm: "",
            learner_selected:"All",
            tutorNameSelected: "All"
        }
    },
    created() {
        var self = this;
        self.isSpinner = true;
        if(self.getRejectedBookingList.length === 0) {
            self.getPendingBookings().then(() =>{
                self.isSpinner = false;
            }).catch((error) => {
                console.error(error);
                self.isSpinner = false;
            })
        } else {
            self.isSpinner = false;
        } 
    },
    computed: {
        ...mapGetters({
            'getRejectedBookingList' : 'booking/getRejectedBooking'
        }),
        rowData(){
            try {
                var self = this;
                let rows = [];
                self.getRejectedBookingList.forEach((booking) =>{
                    let obj = booking; 
                    obj.sessionTitle=booking.sessionDetails && booking.sessionDetails.name ? booking.sessionDetails.name : '';
                    obj.learnerChildName= booking.learnerDetails && booking.learnerDetails.firstName ? booking.learnerDetails.firstName + " " +booking.learnerDetails.lastName : "" ;
                    obj.tutorNameDetail= booking.tutorDetails && booking.tutorDetails.firstName ? booking.tutorDetails.firstName + " " +booking.tutorDetails.lastName: "" ;

                    rows.push(obj);
                });
                return rows;
            } catch (error) {
                console.error(error);
                errorEmail({
                    projectName : process.env.VUE_APP_PROJECTNAME,
                    environment : process.env.NODE_ENV,
                    fileName : currentFileName,
                    hookName : "rowData",
                    errorMessage : error.message
                },(mailRes) => {
                    if(mailRes.error){
                        console.error(mailRes);
                    }
                })
                return [];
            }
        },
        learnerNameOptions(){
            try {
                var self = this;
                let learnerOption = []
                learnerOption.push({text: "All" , value: null})
                self.getRejectedBookingList.forEach(booking => {
                    if(booking.learnerDetails && booking.learnerDetails.firstName) {
                        let index = learnerOption.findIndex((data) => {
                            return data.value == booking.learnerId
                        })
                        if(index < 0) {
                            learnerOption.push({
                                text: `${booking.learnerDetails.firstName} ${booking.learnerDetails.lastName}`,
                                value: booking.learnerId
                            });
                        }
                    }
                });
                return learnerOption
            } catch (error) {
                console.error(error);
                errorEmail({
                  projectName : process.env.VUE_APP_PROJECTNAME,
                  environment : process.env.NODE_ENV,
                  fileName : currentFileName,
                  hookName : "learnerNameOptions",
                  errorMessage : error.message
                },(mailRes) => {
                    if(mailRes.error){
                        console.error(mailRes);
                    }
                })
                return [];
            }
        },
        tutorNameOptions() {
            try {
                var self = this;
                let tutorOption = []
                tutorOption.push({text: "All" , value: null})
                self.getRejectedBookingList.forEach(booking => {
                    if(booking.tutorDetails && booking.tutorDetails.firstName) {
                        let index = tutorOption.findIndex((data) => {
                            return data.value == booking.tutor
                        })
                        if(index < 0) {
                            tutorOption.push({
                                text: `${booking.tutorDetails.firstName} ${booking.tutorDetails.lastName}`,
                                value: booking.tutor
                            })
                        }
                    }
                });
                return tutorOption
            } catch (error) {
                console.error(error);
                errorEmail({
                  projectName : process.env.VUE_APP_PROJECTNAME,
                  environment : process.env.NODE_ENV,
                  fileName : currentFileName,
                  hookName : "tutorNameOptions",
                  errorMessage : error.message
                },(mailRes) => {
                    if(mailRes.error){
                        console.error(mailRes);
                    }
                })
                return [];
            }
        },
        filterRows() {
            try {
                var self = this;
                var listA = self.rowData;
                if (self.learner_selected == "All" && self.tutorNameSelected == "All" ) {
                    return listA;
                }
                if (self.learner_selected != "All") {
                    listA = listA.filter((data) => {
                        return data.learnerChildName.toLowerCase().includes(self.learner_selected.toString().toLowerCase());
                    });
                }
                if (self.tutorNameSelected != "All") {
                    listA = listA.filter((dataTutor) => {
                        return dataTutor.tutorNameDetail.toLowerCase().includes(self.tutorNameSelected.toString().toLowerCase());
                    });
                }
                return listA;
            } catch (error) {
                console.error(error)
                errorEmail({
                    projectName : process.env.VUE_APP_PROJECTNAME,
                    environment : process.env.NODE_ENV,
                    fileName : currentFileName,
                    hookName : "filterRows",
                    errorMessage : error.message
                },(mailRes) => {
                    if(mailRes.error){
                        console.error(mailRes);
                    }
                })
            }
        },
    },
    methods: {
        onCellClick(data) {
        if (data.column.field==="learnerChildName") {
            this.$router.push({name: 'user-details', params: { id: data.row.learnerId}})
          } else if (data.column.field==="tutorNameDetail") {
              this.$router.push({  name: 'user-details', params: { id: data.row.tutor} ,})
          } else if (data.column.field==="cancellationReason") {
            if (data.row.cancellationReason.length <= 70) {
              this.$router.push({  name: 'booking-detail-reject', params: { id: data.row.id}})
            } else {
              return;
            }
        }
          else {
            this.$router.push({  name: 'booking-detail-reject', params: { id: data.row.id}})
          }
        },
        ...mapActions({
            'getPendingBookings' : 'booking/getRejectedBookingData'
        }),
        limitTo: function (value) {
            if(value){
                if (value.length < 70){
                return value
                }else{
                return value.substring(0, 70) + "...";
                }
            }else{
                return "-";
            }
        },
    },
}
</script>
<style >
    
</style>