<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />

      <div class="card user_filter all_session">
        <h2>Filters</h2>

        <b-form class="dropdownfilter" @submit.prevent>
          <b-row>
            <b-col xl="3" lg="3" md="3">
              <label>Tutor Name</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="tutor_name_selected"
                variant="outline-secondary"
                class="w-100"
              >
                <b-dropdown-item
                  v-for="item in user_type_options"
                  :key="item.value"
                  @click="tutor_name_selected = item.text"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
            <b-col xl="3" lg="3" md="3">
              <label>Tutor Type</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="tutor_type_selected"
                variant="outline-secondary"
                class="w-100"
              >
              
                <b-dropdown-item
                  v-for="item in tutor_type_options"
                  :key="item.value"
                   @click="tutor_type_selected = item.text"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
            <b-col xl="3" lg="3" md="3">
              <label>Category</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="category_selected"
                variant="outline-secondary"
                class="w-100"
              >
                <b-dropdown-item
                  v-for="item in category_options"
                  :key="item.value"
                  @click="category_selected = item.text"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <div class="card user_table request_session_table">
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1"></label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search..."
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="filterRows"
          @on-cell-click="onCellClick"
          class="cursor-pointer"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'requestid'" class="text-nowrap">
              <router-link
                :to="{
                  name: 'req-session-detail',
                  params: { id: props.row.requestid, name: props.row.fullName },
                }"
                class="text-nowrap"
                >{{ props.row.requestid }}</router-link
              >
            </span>

            <span v-else-if="props.column.field === 'fullName'" class="text-nowrap">
              <b-avatar :src="props.row.avatar" class="user_img" />
              <router-link
                :to="{ name: 'user-details', params: { id: props.row.userId, name: props.row.fullName} }"
                class="text-nowrap"
                >{{ props.row.fullName }}</router-link
              >
            </span>
            <span
                v-else-if="props.column.field === 'tutorType'"
                class="tutor_type"
              >
              <div v-if="props.row.tutorType != '' && props.row.tutorType != null">
                <b-badge :style="getTutorTypeStyle(props.row.tutorType)">
                  <!-- :variant="getStatusBadgeVariant(props.row.tutorType)" -->
                  {{ props.row.tutorType }}
                </b-badge>
              </div>
            </span>
            <span
              v-else-if="props.column.field === 'category'"
              class="text-nowrap"
            >
            <router-link :to="{name: 'category-details', params: {id: props.row.categoryid}}">
                {{ props.row.category }}
                </router-link>
            </span>
            <span v-else-if="props.column.field === 'status'" class="status">
              <b-badge :variant="status[1][props.row.status]">
                {{ status[0][props.row.status] }}
              </b-badge>
            </span>
            <span
              v-else-if="props.column.field === 'action'"
              class="action_field d-inline-block"
              v-for="item in props.row.action"
              :key="item"
            >
              <b-button
                v-bind:variant="
                  item === 'Approve' ? 'outline-success' : 'outline-danger'
                "
                v-bind:data-value=item                
                v-on:click="                
                 item === 'Approve' ? showMsgBoxOne(item,props.row) : deleteSession(props.row)                      
                      "
              >
                {{ item }}
              </b-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to 
                  <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                  <span v-else>{{props.total}} of {{ props.total }} entries</span>
                </span>

                    <div class="d-flex align-items-center mb-1 mt-0 showentry">
                      <span>Show</span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10', '20', '30', '40', '50']"
                        class="mx-1"
                        @input="
                          (value) => props.perPageChanged({ currentPerPage: value })
                        "
                     />
                      <span>entries</span>
                    </div>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :current-page="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => {
                      props.pageChanged({ currentPage: value });
                      currentPage = value
                    }
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>

      
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
  BSpinner,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from 'vue-ripple-directive'
import { mapGetters ,mapActions} from 'vuex';
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import {dbCollections} from "@/utils/firebaseCollection.js"
import axios from 'axios';
import { sessionBookingNotification } from './service.js';
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/SessionsManagement/AllSession(s)/requestSession.vue"
export default {
  directives: {
    Ripple,
  },
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BButton,
    BSpinner,
  },
  data() {
    return {
      direction:false,
      isSpinner:false,
      isUpdating:false,
      
      boxOne: '',
      boxTwo: '',
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      tutor_name_selected:"All",
       tutor_type_selected: "All",
      status_options: [
        { value: null, text: "All" },
        { value: "a", text: "Active" },
        { value: "b", text: "Suspend" },
        { value: "c", text: "Block" },
      ],
      category_selected : "All",
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Request ID",
          field: "requestid",
        },
        {
          label: "Tutor Name",
          field: "fullName",
        },
        {
          label: "Tutor Type",
          field: "tutorType",
        },
        {
          label: "Session Title",
          field: "sessionTitle",
        },
        {
          label: "Category",
          field: "category",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      searchTerm: "",
      status: [
        {
          1: "Current",
          2: "Active",
          3: "Block",
          4: "Suspend",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      tutorType: [
        {
          1: "Valuable",
          2: "Elite",
          3: "Normal",
          4: "N/A",
        },
        {
          1: "light-primary",
          2: "light-danger",
          3: "light-success",
          4: "light-dark",
        },
      ],
    };
  },
  mounted() {
      },
  computed: {
     ...mapGetters({
        sessions:'sesionManagement/getSessions',
        getUsersList:'userManagement/getUsers',
        getAllStatusList:'settings/getAllStatus' ,
        category:'categoryManagement/getCategories',
        gettutorTypesList:'settings/getTutorTypes',
        getsettingList : 'settings/getsettingArrayFeild'
      }),
      rows(){
        
        var self = this;
        self.isSpinner = true
        var array = [];
        try{
          for(var i=0;i<self.sessions.length;i++){
            for(var j=0;j<self.getUsersList.length;j++){
              for(var k=0; k<self.category.length;k++){
                if(self.sessions[i].status === 0 && self.sessions[i].tutor === self.getUsersList[j].id && self.sessions[i].category === self.category[k].id && self.getUsersList[j].status != 4){ 
                  var obj = {
                    requestid : self.sessions[i].requestId ?  self.sessions[i].requestId : "",
                    avatar: self.getUsersList[j].profilePicture ? self.getUsersList[j].profilePicture : "",
                    fullName: `${self.sessions[i].tutorDetails.firstName} ${self.sessions[i].tutorDetails.lastName}`,
                    tutorType: self.sessions[i].tutorDetails.tutorType ? self.sessions[i].tutorDetails.tutorType : "",
                    sessionTitle: self.sessions[i].title ? self.sessions[i].title : "",
                    category: self.sessions[i].categoryDetails.categoryName ? self.sessions[i].categoryDetails.categoryName : "",
                    action: ["Approve", "Decline"],
                    sessionId : self.sessions[i].id ? self.sessions[i].id : "",
                    categoryname : self.sessions[i].categoryDetails.categoryName,
                    categoryid : self.sessions[i].categoryDetails.id,
                    userId:self.getUsersList[j].id
                    
                  }
                  array.push(obj)
                }
              }
            }
          }
         
          if(!array.empty){
            self.isSpinner = false
            return array;
          }
          else{
            self.isSpinner = false
          }
         
        }catch(err){
          console.error(err);
          self.isSpinner = false
           errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "rows",
              errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
            })
        }
      },
      category_options(){
          var categoryArr = []
          categoryArr.push({value:0,text:"All"})
          for(var i=0;i<this.rows.length;i++){
            var temp = false
            for(var j=0;j<categoryArr.length;j++){
              if(this.rows[i].category==categoryArr[j].text){
                temp = true
              }
            }
            if(!temp){
              var obj={
                value: i+1,
                text: this.rows[i].category
              }
              categoryArr.push(obj)
            }
          }
          return categoryArr
      },
       user_type_options(){
          var nameArr = []
          nameArr.push({value:0,text:"All"})
          for(var i=0;i<this.rows.length;i++){
              var temp = false
              for(var j=0;j<nameArr.length;j++){
                  if(this.rows[i].fullName == nameArr[j].text){
                      temp = true
                  }
              }
              if(!temp){
                  var obj={
                  value : i+1,
                  text : this.rows[i].fullName
                }
                nameArr.push(obj)
              }
          }
          return nameArr
      },
         tutor_type_options(){
          var typeArr = []
          typeArr.push({value:0,text:"All"})
          for(var i=0;i<this.rows.length;i++){
            if(this.rows[i].tutorType != ''){
              var temp = false
              for(var j=0;j<typeArr.length;j++){
                  if(this.rows[i].tutorType == typeArr[j].text){
                      temp = true
                  }
              }
              if(!temp){
                  var obj={
                  value : i+1,
                  text : this.rows[i].tutorType
                }
                typeArr.push(obj)
              }
            }
          }
          return typeArr
      },
      filterRows(){
        //  
          var listA = this.rows;
          if(this.tutor_name_selected == "All" && this.tutor_type_selected == "All" && this.category_selected == "All"){
              return listA
          }
          if(this.tutor_name_selected != "All"){
              listA = listA.filter((data)=>{
                  return data.fullName.toLowerCase().includes(this.tutor_name_selected.toLowerCase())
              })
          }
          if(this.tutor_type_selected != "All"){
                listA = listA.filter((data)=>{
                    return data.tutorType.toLowerCase().includes(this.tutor_type_selected.toLowerCase())
                })
          }
          if(this.category_selected != "All"){
              listA = listA.filter((data)=>{
                  return data.category.toLowerCase().includes(this.category_selected.toLowerCase())
              })
          }
          return listA
      }
  },
  created() {
     let categoriesFound = this.category.length != 0;
    if(!categoriesFound){
      this.getCategories().then(res=>{
      }).catch(error=>{
        console.error("error",error)
      })
    }
  },
  methods: {
    getTutorTypeStyle(value) {
        if(this.gettutorTypesList) {
            let index = this.gettutorTypesList.findIndex((val ) =>{
              return val.name == value
            });
            if(index > -1) {
                return {
                  'background-color' : this.gettutorTypesList[index].backgroundColor,
                  'color' : this.gettutorTypesList[index].textColor,
                  'border-color': this.gettutorTypesList[index].textColor,
                  'border-width': '1px',
                  'border-style': 'solid'
                }
            }
        } else {
            return ''
        }
    },
    onCellClick(data){
      if (data.column.field==="fullName") {
            this.$router.push({name: 'user-details', params: { id: data.row.userId, name: data.row.fullName}})
        } else if (data.column.field==="category") {
            this.$router.push({name: 'category-details', params: {name: data.row.categoryname,id: data.row.categoryid },})
        }
        else if (data.column.field ==="action") {
          return;
        }
        else {
          this.$router.push({name: 'req-session-detail', params: { id: data.row.requestid, name: data.row.fullName }})
        }
    },
    ...mapActions({
        'getCategories':'categoryManagement/getCategoriesData',
      }),
    // APPROVE SESSION (Shraddha)
    showMsgBoxOne : function (index,data) {
    
    var self = this;
      self.$bvModal
        .msgBoxConfirm('Are you sure you want to Approve?', {
          title: 'Approve',         
          class:'test',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
          footerClass: 'approve-footer',
          headerClass: 'approve-header',
          bodyClass: 'approve-body',
        })
        .then(value => {
          self.isSpinner = true;
          if(value){
            const updateObject = {
              'status' : 2
            }
            updateFirebase.updateRootCollectionDataByDocId(dbCollections.SESSIONS,data.sessionId,updateObject,(res) => {
              self.$root.$emit('showToastMessage','Session approved successfully.','success');
              self.isSpinner = false;
              sessionBookingNotification({
                  title: data.sessionTitle,
                  status: 2,
                  tutor: data.userId,
                  id: data.sessionId,
              }, () => {});
            })
          }else{
            self.isSpinner = false;
          }
        }).catch(error => {
          console.error(error);
          self.isSpinner = false;
        }) 
    },
    // ADD BADGE RELATED TO STATUS(Shraddha)
    getStatusBadgeVariant(value){
      
      var variant = "";
      if(value == "Valuable")
      {
        variant += 'light-primary';
      }
      else if(value == "Elite")
      {
        variant += 'light-danger';
      }
      else if(value == "Normal")
      {
        variant += 'light-success';
      }
      else if(value == "Expert")
      {
        variant += 'light-warning';
      };
      return variant;
    },
    // DECLINE SESSION (Shraddha)
    deleteSession(data){
      
      var self = this;
       self.$bvModal
        .msgBoxConfirm('Are you sure you want to Decline?', {
          title: 'Decline',         
          class:'test',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
          footerClass: 'approve-footer',
          headerClass: 'approve-header suspend-header',
          bodyClass: 'approve-body',
        })
        .then(value => {
          self.isSpinner = true;
          if(value){
            const updateObject = {
              'status' : 1
            }
            updateFirebase.updateRootCollectionDataByDocId(dbCollections.SESSIONS,data.sessionId,updateObject,(res) => {
              self.$root.$emit('showToastMessage','Session declined successfully.','success');
              self.isSpinner = false;

              sessionBookingNotification({
                title: data.sessionTitle,
                status: 1,
                tutor: data.userId,
                id: data.sessionId
              }, () => {});
            })
          }else{
            self.isSpinner = false;
          }
        }).catch(error => {
          console.error(error)
          self.isSpinner = false;
        })
    }
  },
};
</script>

