const AutoTemplate = function(){
    return( 
        {
            "counters": {
                "u_column": 1,
                "u_row": 1,
                "u_content_text": 1
            },
            "body": {
                "id": "0idAUF9JSM",
                "rows": [
                    {
                        "id": "YTKQ0upE3B",
                        "cells": [
                            1
                        ],
                        "columns": [
                            {
                                "id": "A0UCQ1Vz4k",
                                "contents": [
                                    {
                                        "id": "wqN1IRV_Yk",
                                        "type": "text",
                                        "values": {
                                            "containerPadding": "10px",
                                            "anchor": "",
                                            "textAlign": "left",
                                            "lineHeight": "140%",
                                            "linkStyle": {
                                                "inherit": true,
                                                "linkColor": "#0000ee",
                                                "linkHoverColor": "#0000ee",
                                                "linkUnderline": true,
                                                "linkHoverUnderline": true
                                            },
                                            "displayCondition": null,
                                            "_meta": {
                                                "htmlID": "u_content_text_1",
                                                "htmlClassNames": "u_content_text"
                                            },
                                            "selectable": true,
                                            "draggable": true,
                                            "duplicatable": true,
                                            "deletable": true,
                                            "hideable": true,
                                            "text": `<p style=\"font-size: 14px; line-height: 140%; text-align: center;\"><span style=\"font-size: 22px; line-height: 30.8px;\"><strong>This Is Email Template</strong></span></p>`
                                        }
                                    }
                                ],
                                "values": {
                                    "backgroundColor": "",
                                    "padding": "0px",
                                    "border": {},
                                    "_meta": {
                                        "htmlID": "u_column_1",
                                        "htmlClassNames": "u_column"
                                    }
                                }
                            }
                        ],
                        "values": {
                            "displayCondition": null,
                            "columns": false,
                            "backgroundColor": "",
                            "columnsBackgroundColor": "",
                            "backgroundImage": {
                                "url": "",
                                "fullWidth": true,
                                "repeat": false,
                                "center": true,
                                "cover": false
                            },
                            "padding": "0px",
                            "anchor": "",
                            "hideDesktop": false,
                            "_meta": {
                                "htmlID": "u_row_1",
                                "htmlClassNames": "u_row"
                            },
                            "selectable": true,
                            "draggable": true,
                            "duplicatable": true,
                            "deletable": true,
                            "hideable": true
                        }
                    }
                ],
                "values": {
                    "popupPosition": "center",
                    "popupWidth": "600px",
                    "popupHeight": "auto",
                    "borderRadius": "10px",
                    "contentAlign": "center",
                    "contentVerticalAlign": "center",
                    "contentWidth": "500px",
                    "fontFamily": {
                        "label": "Arial",
                        "value": "arial,helvetica,sans-serif"
                    },
                    "textColor": "#000000",
                    "popupBackgroundColor": "#FFFFFF",
                    "popupBackgroundImage": {
                        "url": "",
                        "fullWidth": true,
                        "repeat": false,
                        "center": true,
                        "cover": true
                    },
                    "popupOverlay_backgroundColor": "rgba(0, 0, 0, 0.1)",
                    "popupCloseButton_position": "top-right",
                    "popupCloseButton_backgroundColor": "#DDDDDD",
                    "popupCloseButton_iconColor": "#000000",
                    "popupCloseButton_borderRadius": "0px",
                    "popupCloseButton_margin": "0px",
                    "popupCloseButton_action": {
                        "name": "close_popup",
                        "attrs": {
                            "onClick": "document.querySelector('.u-popup-container').style.display = 'none';"
                        }
                    },
                    "backgroundColor": "#e7e7e7",
                    "backgroundImage": {
                        "url": "",
                        "fullWidth": true,
                        "repeat": false,
                        "center": true,
                        "cover": false
                    },
                    "preheaderText": "",
                    "linkStyle": {
                        "body": true,
                        "linkColor": "#0000ee",
                        "linkHoverColor": "#0000ee",
                        "linkUnderline": true,
                        "linkHoverUnderline": true
                    },
                    "_meta": {
                        "htmlID": "u_body",
                        "htmlClassNames": "u_body"
                    }
                }
            },
            "schemaVersion": 8
        }
    )
}

const EditTemplate = function(emailValue){
    return( 
        {
            "counters": {
                "u_column": 1,
                "u_row": 1,
                "u_content_text": 1
            },
            "body": {
                "id": "0idAUF9JSM",
                "rows": [
                    {
                        "id": "YTKQ0upE3B",
                        "cells": [
                            1
                        ],
                        "columns": [
                            {
                                "id": "A0UCQ1Vz4k",
                                "contents": [
                                    {
                                        "id": "wqN1IRV_Yk",
                                        "type": "text",
                                        "values": {
                                            "containerPadding": "10px",
                                            "anchor": "",
                                            "textAlign": "left",
                                            "lineHeight": "140%",
                                            "linkStyle": {
                                                "inherit": true,
                                                "linkColor": "#0000ee",
                                                "linkHoverColor": "#0000ee",
                                                "linkUnderline": true,
                                                "linkHoverUnderline": true
                                            },
                                            "displayCondition": null,
                                            "_meta": {
                                                "htmlID": "u_content_text_1",
                                                "htmlClassNames": "u_content_text"
                                            },
                                            "selectable": true,
                                            "draggable": true,
                                            "duplicatable": true,
                                            "deletable": true,
                                            "hideable": true,
                                            "text": `<p style=\"font-size: 14px; line-height: 140%; text-align: center;\"><span style=\"font-size: 22px; line-height: 30.8px;\"><strong>${emailValue}</strong></span></p>`
                                        }
                                    }
                                ],
                                "values": {
                                    "backgroundColor": "",
                                    "padding": "0px",
                                    "border": {},
                                    "_meta": {
                                        "htmlID": "u_column_1",
                                        "htmlClassNames": "u_column"
                                    }
                                }
                            }
                        ],
                        "values": {
                            "displayCondition": null,
                            "columns": false,
                            "backgroundColor": "",
                            "columnsBackgroundColor": "",
                            "backgroundImage": {
                                "url": "",
                                "fullWidth": true,
                                "repeat": false,
                                "center": true,
                                "cover": false
                            },
                            "padding": "0px",
                            "anchor": "",
                            "hideDesktop": false,
                            "_meta": {
                                "htmlID": "u_row_1",
                                "htmlClassNames": "u_row"
                            },
                            "selectable": true,
                            "draggable": true,
                            "duplicatable": true,
                            "deletable": true,
                            "hideable": true
                        }
                    }
                ],
                "values": {
                    "popupPosition": "center",
                    "popupWidth": "600px",
                    "popupHeight": "auto",
                    "borderRadius": "10px",
                    "contentAlign": "center",
                    "contentVerticalAlign": "center",
                    "contentWidth": "500px",
                    "fontFamily": {
                        "label": "Arial",
                        "value": "arial,helvetica,sans-serif"
                    },
                    "textColor": "#000000",
                    "popupBackgroundColor": "#FFFFFF",
                    "popupBackgroundImage": {
                        "url": "",
                        "fullWidth": true,
                        "repeat": false,
                        "center": true,
                        "cover": true
                    },
                    "popupOverlay_backgroundColor": "rgba(0, 0, 0, 0.1)",
                    "popupCloseButton_position": "top-right",
                    "popupCloseButton_backgroundColor": "#DDDDDD",
                    "popupCloseButton_iconColor": "#000000",
                    "popupCloseButton_borderRadius": "0px",
                    "popupCloseButton_margin": "0px",
                    "popupCloseButton_action": {
                        "name": "close_popup",
                        "attrs": {
                            "onClick": "document.querySelector('.u-popup-container').style.display = 'none';"
                        }
                    },
                    "backgroundColor": "#e7e7e7",
                    "backgroundImage": {
                        "url": "",
                        "fullWidth": true,
                        "repeat": false,
                        "center": true,
                        "cover": false
                    },
                    "preheaderText": "",
                    "linkStyle": {
                        "body": true,
                        "linkColor": "#0000ee",
                        "linkHoverColor": "#0000ee",
                        "linkUnderline": true,
                        "linkHoverUnderline": true
                    },
                    "_meta": {
                        "htmlID": "u_body",
                        "htmlClassNames": "u_body"
                    }
                }
            },
            "schemaVersion": 8
        }
    )
}

module.exports = {
    EditTemplate,
    AutoTemplate
}





