<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners loaderDetail"
    />
    <div v-show="!isSpinner">
    <b-tab title="All Session(s)" @click="onAllSession">
      <div class="card user_filter">
        <h2>Filters</h2>
        <b-form @submit.prevent>
          <b-row>
            <b-col xl="3" lg="4" md="5">
              <label>Category Name</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="category_selected"
                variant="outline-secondary"
                class="w-100"
              >
                <b-dropdown-item
                  v-for="item in categoryname_options"
                  :key="item.value"
                  @click="category_selected = item.text,selectedCatId = item.catId"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
            <b-col xl="3" lg="4" md="5">
            </b-col>
          </b-row>
        </b-form>
      </div>
      <div class="user_table holiday_table allsession_table">
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="allsessionsearchTerm"
                placeholder="Search..."
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <vue-good-table
          :columns="dynamicColumns"
          :rows="filterRow"
          @on-cell-click="onCellClick"
          class="cursor-pointer"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: allsessionsearchTerm,
          }"
          :select-options="{
            enabled: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'sessiontitle'" class="userid">
              {{ props.row.sessiontitle }}
            </span>
            <span
              v-else-if="props.column.field === 'categoryname'"
              class="userid"
            >
              <span class="text-nowrap underline">
               <router-link :to="{name: 'category-details', params: {id: props.row.categoryid}}">
                {{ props.row.categoryname }}
                </router-link>
            </span>
            </span>
            <span
              v-else-if="props.column.field === 'status'"
              v-show="props.row.status !== 0 && props.row.status !== 1"
              class="userid" 
            >
              <b-form-checkbox
                v-bind:checked="props.row.status_flag"
                name="check-button"
                switch
                :disabled="isProcess || userStatus == 4"
                inline
                class="custom-control-success"
                @input="changeStatus($event, props.row)"
                @change="filterData = false"
              >
              </b-form-checkbox>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> 
                <span v-if="props.total === 0"> Showing 0 </span>
                <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                  to
                <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                <span v-else>{{props.total}} of {{ props.total }} entries</span>
                </span>

                    <div class="d-flex align-items-center mb-1 mt-0 showentry allsession_userdetailtab">
                    <span>Show</span>
                    <b-form-select
                        v-model="pageLength"
                        :options="['10', '20', '30', '40', '50']"
                        class="mx-1"
                        @input="
                        (value) => props.perPageChanged({ currentPerPage: value })
                        "
                    />
                    <span>entries</span>
                    </div>
            </div>
            <div>
                <b-pagination
                :value="1"
                :total-rows="props.total"
                :current-page="currentPage"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="
                    (value) => {
                    props.pageChanged({ currentPage: value });
                    currentPage = value
                    }
                "
                >
                <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
                </b-pagination>
            </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-tab>
    </div>
  </div>
</template>
<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BSpinner,
  BFormCheckbox,
} from "bootstrap-vue";

import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import { dbCollections } from "@/utils/firebaseCollection.js";
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import * as updateQueries from "@/utils/FirebaseQueries/updateQueries/updateQueries";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Users/User/UserDetailTabs/AllSession_Learner.vue"
export default {
  name: "AllSessionLearner",
  directives: {
    Ripple,
  },
  components: {
    VueGoodTable,
    BCard,
    BImg,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BCardText,
    BTabs,
    BTab,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    flatPickr,
    BSpinner,
    BFormCheckbox,
  },
  data() {
    return {
      direction: false,
      pageLength: 10,
      currentPage: 1,
      allsessionsearchTerm: "",
      category_selected: "All",
      allsessionrows: [],
      filterData:false,
      allsessioncolumns: [
        {
          label: "Session(s) Title",
          field: "sessiontitle",
        },
        {
          label: "Category Name",
          field: "categoryname",
        },
        {
          label: "Total Revenue($)",
          field: "totalRevenue",
        },
        {
          label: "Total No. of Booking",
          field: "totalBooking",
        },
        {
          label: "Status",
          field: "status",
        },
      ],
      isProcess: false,
      isSpinner: false,
      selectedCatId:''
    };
  },
  props:{
    userStatus: Number,
  },
  computed: {
    ...mapGetters({
      getsettingList : 'settings/getsettingArrayFeild',
    }),
     currency(){
            if (Object.keys(this.getsettingList).length != 0) {

                return this.getsettingList.currency.slice(4)
            } else {
                return ""
            }
        },
        
        dynamicColumns(){
            this.allsessioncolumns[2].label = `Total Revenue(${this.currency})`	
            return this.allsessioncolumns
        },
    categoryname_options() {
      var categoryArr = [];
      categoryArr.push({ value: 0, text: "All" });
      for (var i = 0; i < this.allsessionrows.length; i++) {
        var temp = false;
        for (var j = 0; j < categoryArr.length; j++) {
          if (this.allsessionrows[i].categoryname == categoryArr[j].text) {
            temp = true;
          }
        }
        if (!temp) {
          var obj = {
            val: i + 1,
            text: this.allsessionrows[i].categoryname,
            catId:this.allsessionrows[i].categoryid,
            subcatCategoryId:this.allsessionrows[i].insubcatCategoryId,
          };
          categoryArr.push(obj);
        }
      }
      return categoryArr;
    },
    filterRow() {
      this.filterData = true;
      
      var listA = this.allsessionrows;
      if (
        this.category_selected == "All"
      ) {
        return listA;
      }
      if (this.category_selected != "All") {
        listA = listA.filter((catname) => {
          return catname.categoryname
            .toLowerCase()
            .includes(this.category_selected.toLowerCase());
        });
      }
      return listA;
    },
  },
  methods: {
    onCellClick(data) {
        if(data.column.field==="categoryname") {
          this.$router.push({name: 'category-details', params: {  id: data.row.categoryid, name: data.row.categoryname,}})
        }
        else if(data.column.field==="status") {
          this.changeStatus($event, data.row)
        }
        else {
        this.$router.push({name: 'session-details', params: { id: data.row.sessionId,}})
        }
    },
    allsessionList() {
      let sessionList = [];
      var allsessionobj = {};
      var index = 0;
      this.isSpinner = true;
      getFirebase.simpleGetDataWithSpecificTwoWhereCondition(
        dbCollections.SESSIONS,
        "tutor",
        "==",
        this.$route.params.id,
        "status",
        "in",
        [2,3],
        (res) => {
          if (res.length == 0) {
            this.isSpinner = false;
            return;
          }
          let count = 0;
          const myCountingFunc = (resData) => {
            
            if(count >= res.length) {
              this.isSpinner = false;
              return;
            }
            getFirebase.simpleGetDataWithSpecificWhereCondition(dbCollections.BOOKING, "tutor", "==", this.$route.params.id, (bookings)=>{
                allsessionobj = {
                  sessiontitle: resData.title,
                  categoryname: resData.categoryDetails ? resData.categoryDetails.categoryName : "",
                  categoryid: resData.categoryDetails ? resData.categoryDetails.id : "",
                  insubcatCategoryId:resData.categoryDetails.id,
                  sessionId: resData.id,
                  status: resData.status,
                  status_flag: false,
                  totalBooking: bookings.length ? bookings.length : 0,
                  tutorId: resData.tutor,
                  totalRevenue: 0
                };

                if (bookings.length) {
                  bookings.forEach((data)=>{
                      allsessionobj.totalRevenue += data.tutorlineEarning ? data.tutorlineEarning : 0
                  })
                }
                if (allsessionobj.status == 2) {
                  allsessionobj.status_flag = true;
                }
                sessionList.push(allsessionobj);
                count ++
                myCountingFunc(res[count]);
            })
          };
          myCountingFunc(res[0]);
        }
      );
      this.allsessionrows = sessionList;
    },
    changeStatus(event, data) {
      
      try {
        let obj = null;
        let self = this;
        this.isProcess = true;
        if (event == true) {
          obj = {
            status: 2,
          };
        }
        if (event == false) {
          obj = {
            status: 3,
          };
        }
          updateQueries.updateRootCollectionDataByDocId(
            dbCollections.SESSIONS,
            data.sessionId,
            obj,
            (res) => {
              if(!this.filterData){
                self.$root.$emit(
                  "showToastMessage",
                  "Status updated successfully.",
                  "success"
                );
              }
              this.isProcess = false;
            }
          );
      } catch (err) {
        console.error(err, "error");
        errorEmail({
        projectName : process.env.VUE_APP_PROJECTNAME,
        environment : process.env.NODE_ENV,
        fileName : currentFileName,
        hookName : "changeStatus",
        errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
          }) 
        this.isProcess = false;
      }
    },
    onAllSession() {
      var allTabList = document.getElementsByClassName("nav-link active");
      if (allTabList[0].innerText != "All Session(s)") {
        this.allsessionList();
      }
    },
  },
};
</script>
