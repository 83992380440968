<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-card v-if="isLearner">
        <b-row class="userDetailWrapper">
          <b-col xl="1">
            <b-col xl="12" class="p-0">
            <div
              class="user_img"
              v-if="
                userDetail.avatar &&
                userDetail.avatar !== '' &&
                userDetail.avatar !== null
              "
            >
              <b-img :src="userDetail.avatar" alt="User image" rounded />
            </div>
            <div class="user_img" v-else>
              <b-img
                src="../../../assets/images/avatars/defaultUserProfile.jpg"
                alt="User image"
                rounded
              />
            </div>
          </b-col>
          </b-col>
           <b-col xl="11">
            <b-row>
               <b-col xl="3" lg="3" md="4">
            <h5 class="bold">User ID</h5>
            <p>
              {{ userDetail.userRandomId ? userDetail.userRandomId : "N/A" }}
            </p>
            <h5 class="bold">Gender</h5>
            <p style="text-transform: capitalize !important">
              {{ userDetail.gender ? userDetail.gender : "-" }}
            </p>
            <h5 class="bold">Time Zone</h5>
            <p >
              {{ userDetail.timeZone ? userDetail.timeZone : "N/A" }}
            </p>
             <h5 class="bold">Current Balance</h5>             
             <span style="color: #28c76f;">{{userDetail.currentCredit}} Credits</span>
                </b-col>
                <b-col xl="3" lg="3" md="4">
                  <h5 class="bold">User Name</h5>
                  <p >
                    {{ userDetail.firstName }} {{ userDetail.lastName }}
                  </p>
                  <h5 class="bold">User Type</h5>
                  <p>
                    <span style="text-transform: capitalize !important;" v-for="(data, ind) in userDetail.userRole" :key="ind">
                      {{ data }}
                      <span v-if="ind < parseInt(userDetail.userRole.length - 1)"
                        >,</span
                      >
                    </span>
                  </p>
                  <h5 class="bold">School</h5>
                  <p >
                    {{
                      userDetail.schoolDetails
                        ? userDetail.schoolDetails.schoolName
                        : "N/A"
                    }}
                  </p>
                  <h5 class="bold">Grade</h5>
                  <p >
                    {{
                      userDetail.gradeDetail
                        ? userDetail.gradeDetail.gradeName
                        : "N/A"
                    }}
                  </p>
                </b-col>
                <b-col xl="3" lg="3" md="4">
                  <h5 class="bold">Email</h5>
                  <p >
                    {{ userDetail.email ? userDetail.email : "N/A" }}
                  </p>
                  <h5 class="bold">Date of Birth</h5>
                  <p >
                    {{ userDetail.dob ? userDetail.dob : "N/A" }}
                  </p>
                  <h5 class="bold">Curriculum</h5>
                  <p >
                    {{
                      userDetail.syllabusDetails
                        ? userDetail.syllabusDetails.syllabusName
                        : "N/A"
                    }}
                  </p>
                </b-col>
                <b-col xl="3" lg="3" md="4">
                  <h5 class="bold">Mobile Number</h5>
                  <p >
                    {{userDetail.countryCode ? "+" + userDetail.countryCode : ' '}}
                    {{ userDetail.phoneNumber ? userDetail.phoneNumber : "N/A" }}
                  </p>
                  <h5 class="bold">Location</h5>
                  <p>
                    {{ userDetail.location ? userDetail.location : "N/A" }}
                  </p>
                  <h5 class="bold">Status</h5>
                  <b-dropdown
                    :text="userDetail.userStatusName"
                    :variant="userDetail.userVariantName"
                    class="addcolor"
                    :disabled="userDetail.status == 4"
                  >
                    <b-dropdown-item
                      v-for="data in status_options"
                      :key="data.value"
                      class="boldactive"
                      @click="showModelStatus(data,userDetail)"
                    >
                      {{ data.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
                </b-row>
          </b-col>
          
                  
        </b-row>
      </b-card>
      <b-card v-if="!isLearner">
          <b-row class="userDetailWrapper">
            <b-col xl="1">
              <b-col xl="12" class="first p-0">
                <div class="user_img" style="margin-right:0px" v-if="userDetail.avatar && userDetail.avatar !== '' && userDetail.avatar !== null">
                  <b-img :src="userDetail.avatar" alt="User image" rounded />
                </div>
                <div class="user_img" style="margin-right:0px" v-else>
                  <b-img
                    src="../../../assets/images/avatars/defaultUserProfile.jpg"
                    alt="User image"
                    rounded
                  />
                </div>
             </b-col>
            </b-col>
            <b-col xl="11">
              <b-row>
                <b-col xl="3" class="second" lg="3" md="4">
                  <h5 class="bold">User ID</h5>
                  <p>{{ userDetail.userRandomId ? userDetail.userRandomId : 'N/A' }}</p> 
                  <h5 class="bold">Mobile Number</h5>
                  <p>{{userDetail.countryCode ? "+" + userDetail.countryCode : ' '}} {{ userDetail.phoneNumber ? userDetail.phoneNumber : 'N/A' }}</p>  
                  <h5 class="bold">Date Of Birth</h5>
                  <p>{{ userDetail.dob ? userDetail.dob : 'N/A' }}</p>     
                  <h5 class="bold">Joined Date</h5>
                  <p>{{ userDetail.joinDate ? userDetail.joinDate : 'N/A' }}</p>    
                  <h5 class="bold">About Tutor</h5>
                  <p>{{ userDetail.tutorAboutMe ? userDetail.tutorAboutMe : 'N/A' }}</p>  
                </b-col>
                <b-col xl="3" class="third" lg="3" md="4">
                  <h5 class="bold">First Name</h5>
                  <p>{{ userDetail.firstName ? userDetail.firstName :'N/A' }}</p>  
                  <h5 class="bold">Gender</h5>
                  <p style="text-transform: capitalize !important">{{ userDetail.gender ? userDetail.gender : 'N/A' }}</p> 
                  <h5 class="bold">Language</h5>
                  <p v-if='userDetail.tutorTeachingLanguages && userDetail.tutorTeachingLanguages !== null && userDetail.tutorTeachingLanguages.length > 0'>
                    <b-badge
                      variant="light-secondary"
                      v-for="(i,index) in userDetail.tutorTeachingLanguages"
                      :key="index"
                      class="mr-1"
                    >
                      {{ i.tutorTeachingLanguageName }}
                    </b-badge>
                  </p>
                  <p v-else>N/A</p>
                  <h5 class="bold">Total Exp. Years</h5>
                  <p >{{ userDetail.tutorTotalExperienceYears ? userDetail.tutorTotalExperienceYears : 'N/A' }}</p>
                </b-col>
                <b-col xl="3" class="fourth" lg="3" md="4">
                  <h5 class="bold">Last Name</h5>
                  <p>{{ userDetail.lastName ? userDetail.lastName : 'N/A' }}</p>
                  <h5 class="bold" >User Type</h5>
                  <p >
                    <span style="text-transform: capitalize !important;" v-for='(data,ind) in userDetail.userRole' :key="ind" >
                    {{ data }}
                    <span v-if='ind < parseInt(userDetail.userRole.length - 1)'>,</span>
                    </span>
                  </p>
                  <h5 class="bold">Location</h5>
                  <p>{{ userDetail.location ? userDetail.location : 'N/A' }}</p> 
                  <h5 class="bold">Review & Rating</h5>
                  <p>
                    <span class="rating" style="width: auto" v-if="(userDetail.tutorTotalRatingSum && userDetail.tutorTotalRatingUserCount)">
                      <img 
                        :src="require('@/assets/images/erflog/rating.png')"
                        alt="image"
                      /> 
                      {{(userDetail.tutorTotalRatingSum && userDetail.tutorTotalRatingUserCount) ?  Number((userDetail.tutorTotalRatingSum / userDetail.tutorTotalRatingUserCount).toFixed(2)) : '0'}} 
                    </span>
                    {{userDetail.tutorTotalRatingUserCount ?  userDetail.tutorTotalRatingUserCount : '0' }} Reviews
                  </p>
                </b-col>
                <b-col xl="3" class="fifth" lg="3" md="4">
                  <h5 class="bold">Email</h5>
                  <p>{{ userDetail.email ? userDetail.email : 'N/A' }}</p> 
                  <h5 class="bold">Tutor Type</h5>
                  <p class="tutor_type">
                    <b-badge :style="getTutorTypeStyle(userDetail.tutorType)">
                      {{ userDetail.tutorType ? userDetail.tutorType : 'N/A' }}
                    </b-badge>
                  </p>
                  <h5 class="bold">Download Resume</h5>
                  <span class="resume download_btn" v-if="userDetail.tutorResume">
                  <p  v-on:click="downloadDocument(userDetail.tutorResume)" > <feather-icon
                      icon="ArrowDownCircleIcon"
                      size="18"
                      class="text-body align-middle mr-25 download_text_header"
                      style="color: #0096DB !important;"
                    />Download Resume</p>
                  </span>
                  <span v-else>N/A</span>
                  <h5 class="bold">Status</h5>
                  <b-dropdown class="addcolor" :text="userDetail.userStatusName" :variant="userDetail.userVariantName" :disabled="userDetail.status == 4">
                    <b-dropdown-item
                      v-for="data in status_options"
                      :key="data.value"
                      class="boldactive"
                      :disabled="userDetail.status == 4"
                      @click="showModelStatus(data,userDetail)"
                      >{{ data.text }}</b-dropdown-item
                    >
                  </b-dropdown>

                </b-col>
              </b-row>
             </b-col>
          </b-row>
      </b-card>

      <b-row  v-if="!isLearner" class="total_center_section">
        <b-col xl="4" lg="4">
          <b-card>
            <div>
              <h2>{{currency}} {{totalTutorEarning}}</h2>
              <p>Total Earning</p>
            </div>
            <b-badge variant="light-info">
              <feather-icon icon="DollarSignIcon" size="24" />
            </b-badge>
          </b-card>
        </b-col>
        <b-col xl="4" lg="4">
          <b-card>
            <div>
              <h2> {{currency}} {{remainingBalanceToPay}}</h2>
              <p>
                Remaining Balance to pay<span v-if="lastDayPaid"> (Last Paid on {{lastDayPaid}})</span>
              </p>
            </div>
            <b-badge variant="light-warning">
              <feather-icon icon="DollarSignIcon" size="24" />
            </b-badge>
          </b-card>
        </b-col>
        <b-col xl="4" lg="4">
          <b-card>
            <div>
              <h2>{{ totalTutorWarning === 0 ? userDetail.warning : totalTutorWarning}}</h2>
              <p>Till Date Warnings <span v-if="getsettingList && getsettingList.warningResetDays">(Warning Reset in {{getsettingList.warningResetDays}} Days)</span></p>
            </div>
            <b-badge variant="light-danger">
              <feather-icon icon="AlertTriangleIcon" size="24" />
            </b-badge>
          </b-card>
        </b-col>
      </b-row>

      <b-card class="user_detail_bottom_section">
        <b-tabs v-model="tabIndex" v-if="isLearner" vertical nav-wrapper-class="nav-vertical">
          <TuttorList :userName="userDetail.firstName + ' ' + userDetail.lastName" :getDataAgain="getDataAgain"  :userStatus="userDetail.status"/>
          <IntrestedArea  :userDetail="userDetail"/>
          <BookingRequestLearner :userDetailRole="userDetail.userRole" :getDataAgain="getDataAgain" :userStatus="userDetail.status"/>
          <UpcomingSesionTutor :userDetail="userDetail.userRole" :isLearner="!isLearner" :getDataAgain="getDataAgain" :userStatus="userDetail.status"/>
          <PastSessions :userDetailRole="userDetail.userRole" :getDataAgain="getDataAgain"/>
          <RejectedSessionUserDetail :userDetailRole="userDetail.userRole" :getDataAgain="getDataAgain"/>
          <UserDetailCanceledSessionLearner :getDataAgain="getDataAgain"/>  
          <UserdetailChildListLearner v-if="userDetail.isChild === false" :userDetail="userDetail"  :getDataAgain="getDataAgain" :userStatus="userDetail.status"/>
          <TransectionHistoryLearner/>          
        </b-tabs>
        <b-tabs v-model="tabIndex" v-if="!isLearner" vertical nav-wrapper-class="nav-vertical">
          <LearnerChildListLearner :userName="userDetail.firstName + ' ' + userDetail.lastName" :getDataAgain="getDataAgain" :userStatus="userDetail.status"/>          
          <BanckDocDetailLearner :user_data='user_data' :getDataAgain="getDataAgain" :userStatus="userDetail.status"/>
          <BookingRequestLearner :userDetailRole="userDetail.userRole" :getDataAgain="getDataAgain"  :isLearner="!isLearner" :userStatus="userDetail.status" />
          <UpcomingSesionTutor :userDetail="userDetail.userRole" :isLearner="!isLearner" :getDataAgain="getDataAgain" :userStatus="userDetail.status" />
          <PastSessions :userDetailRole="userDetail.userRole"  :isLearner="!isLearner" :getDataAgain="getDataAgain" :rating="userDetail"/>
          <RejectedSessionUserDetail :userDetailRole="userDetail.userRole" :getDataAgain="getDataAgain"  :isLearner="!isLearner"/> 
          <UserDetailCanceledSessionTutor :getDataAgain="getDataAgain" :userStatus="userDetail.status"/>  
          <AvailableTimeLearner :getDataAgain="getDataAgain"/>
          <UserdetailHolidayList :userDetail="userDetail" :getDataAgain="getDataAgain"/>   
          <CategoriesLearner  :userStatus="userDetail.status"/>  
          <AllSessionLearner :userStatus="userDetail.status"/>        
          <TransectionHistoryTutor />          
          <ReviewRating :userDetail="userDetail" :getDataAgain="getDataAgain" />          
        </b-tabs>
      </b-card>
    </div>
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      title="Add Credit"
      ok-title="Add"
      cancel-variant="outline-primary"
      centered
      v-model="creditModel"
      data-class="addcredit_popup"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form">
        <b-form-group
          :state="nameState"
          label="No. of Credits"
          label-for="name-input"
          invalid-feedback="No. of Credits is required"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            placeholder="Enter Number"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            :state="nameState"
            required
          />
        </b-form-group>
      </form>
    </b-modal>
    <b-modal
      id="modal-block"
      centered
      title=""
      ok-title="Yes"
      cancel-title="No"
      @ok="blockUser"
    >
      <img
        src="@/assets/images/erflog/cancel.png"
        alt="profile"
        class="warning_image"
      />
      <h4>Block User</h4>
      <b-card-text>
        Are you sure you want to Block User? They won't be able to access Tutorline anymore.
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-suspend"
      centered
      title=""
      ok-title="Yes"
      cancel-title="No"
      @ok="suspendTutorUser"
    >
      <img
        src="@/assets/images/erflog/cancel.png"
        alt="profile"
        class="warning_image"
      />
      <h4>Suspend User</h4>
      <b-card-text>
        Are you sure you want to Suspend User? They won't be able to access Tutorline anymore.
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-active"
      centered
      title=""
      ok-title="Yes"
      cancel-title="No"
      @ok="activateUser"
    >
      <img
        src="@/assets/images/tutorimages/approve.png"
        alt="profile"
        class="approve_image"
      />
      <h4>Active</h4>
      <b-card-text> Are you sure you want to Active user? </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import Vue from 'vue';
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BFormCheckbox,
  BButton,
  BModal,
  VBModal,
  BSpinner,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import moment from "moment";
const db = firebase.firestore();
import * as update from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import { dbCollections } from "@/utils/firebaseCollection.js";
import TuttorList from "../../Users/User/UserDetailTabs/UserDetail_TutorList.vue"
import IntrestedArea from "../../Users/User/UserDetailTabs/UserDetail_IntrestedArea.vue"
import UserdetailChildListLearner from "../../Users/User/UserDetailTabs/userDetail_childList_learner.vue"
import UserdetailHolidayList from "../../Users/User/UserDetailTabs/userDetail_holidayList.vue"
import UserDetailCanceledSessionTutor from "../../Users/User/UserDetailTabs/UserDetail_Canceled_SessionTutor.vue";
import TransectionHistoryTutor from '../../Users/User/UserDetailTabs/TransactionHistory_Tutor.vue';
import TransectionHistoryLearner from '../../Users/User/UserDetailTabs/TransactionHistory_Learner.vue'
import UserDetailCanceledSessionLearner from "../User/UserDetailTabs/UserDetail_Canceled_Session_Learner.vue";
import ReviewRating from "../../Users/User/UserDetailTabs/UserDetail_Tutor_ReviewRating.vue";
import PastSessions from "../../Users/User/UserDetailTabs/PastSessions.vue";
import UpcomingSesionTutor from "../../Users/User/UserDetailTabs/Upcoming_Session_Tutor.vue"
import BookingRequestLearner from "../../Users/User/UserDetailTabs/BookingRequest_Learner.vue"
import LearnerChildListLearner from "../../Users/User/UserDetailTabs/learnerChildList_learner.vue"
import BanckDocDetailLearner from "../../Users/User/UserDetailTabs/BanckDoc_Detail_learner.vue"
import CategoriesLearner from "../../Users/User/UserDetailTabs/Categories_Learner.vue"
import AllSessionLearner from "../../Users/User/UserDetailTabs/AllSession_Learner.vue"
import SubcategoriesLearner from  "../../Users/User/UserDetailTabs/Subcategories_Learner.vue"
import AvailableTimeLearner from  "../../Users/User/UserDetailTabs/AvailableTime_Learner.vue"
import RejectedSessionUserDetail from '@/views/Users/User/UserDetailTabs/RejectedSession_UserDetail'
import firebase from "@/utils/firebaseInit";
import * as updateFirebase from '@/utils/FirebaseQueries/updateQueries/updateQueries'
import suspendActionFun from '@/utils/globalFunction/suspendActionFun';
import functionForSuspendOrBlockChild from '@/utils/globalFunction/suspendChildFunction'
import functionForActiveChild from '@/utils/globalFunction/activeChild'
import axios from "axios";
import { errorEmail } from '@/utils/apiRequests';
import {sendNotificationUserStatus} from '../../service.js'
const currentFileName = "src/views/Users/User/userDetail.vue"
import {blockUser} from '../../ComplaintManagement/service.js'
import {unblockUser} from '../../ComplaintManagement/serviceUnblock.js'
import {verifyUser} from './serviceVerifyUser.js'
export default {
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  components: {
    RejectedSessionUserDetail,
    AvailableTimeLearner,
    SubcategoriesLearner,
    AllSessionLearner,
    CategoriesLearner,
    BanckDocDetailLearner,
    BookingRequestLearner,
    LearnerChildListLearner,
    TransectionHistoryTutor,
    TransectionHistoryLearner,
    UpcomingSesionTutor,
    PastSessions,
    ReviewRating,
    UserDetailCanceledSessionLearner,
    UserDetailCanceledSessionTutor,
    UserdetailChildListLearner,
    UserdetailHolidayList,
    IntrestedArea,
    TuttorList,
    VueGoodTable,
    BCard,
    BImg,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BCardText,
    BTabs,
    BTab,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    flatPickr,
    BFormCheckbox,
    BModal,
    BSpinner,
  },
  data() {
    return {
      creditModel: false,
      name: "",
      nameState: null,
      tutorsrows:[],
      tutorslist:[],
      childlistrows:[],
      canceledsessionsrows:[],
      pastsessionsearchTerm:'',
      pastsessionsrows:[],
      pastsessioncolumns:[],
      tutor_name_options:[],
      dateNtim:[],
      sessionsearchTerm:'',
      sessioncolumns:[],
      sessionsearchTerm:'',
      interestedsearchTerm:'',
      interestedsrows:[],
      sessionsrows:[],
      searchTerm:'',
      reviewrows:[],
      statusVariant:'',
      usersReview:[],
      tabIndex: 0,
      isLearner: false,
      direction: false,
      isSpinner: false,
      isUpdating: false,
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      user_type_selected: null,
      user_type_options: [
        { value: null, text: "All" },
        { value: "a", text: "Learner" },
        { value: "b", text: "Child" },
        { value: "c", text: "Tutor" },
      ],
      tutor_type_selected: "All",
      tutor_type_options: [],
      status_optionsForTutorList: [],
      status_selected: null,
      status_selectedForTutorList: "All",
      pageLength: 10,
      dir: false,
      tutorscolumns: [
        {
          label: "User ID",
          field: "userid",
        },
        {
          label: "Tutor Name",
          field: "fullName",
        },
        {
          label: "Tutor Type",
          field: "usertype",
        },
        {
          label: "User Email",
          field: "email",
        },
        {
          label: "No. of Session Booked",
          field: "userbooked",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      status_selected: null,
      interestedcolumns: [
        {
          label: "Category Name",
          field: "categoryname",
        },
      ],
      bookingreqsearchTerm: "",
     
      canceledsessionsearchTerm: "",
      sessionsdateNtim:"",
      canceledsessionstatus: [
        {
          1: "Pending",
          2: "Approved",
          3: "Warning",
          4: "Suspend",
        },
        {
          1: "light-warning",
          2: "light-success",
          3: "light-primary",
          4: "light-danger",
        },
      ],
      readMore: false,
      review_tutor_name_options: [],
      historysearchTerm: [],
      historysrows: [],
      historycolumns: [],
      historysearchTerm: "",
      dateNtimHistory: "",
      allsessionsearchTerm: "",
      allsessionrows: [],
      allsessioncolumns: [],
      userStatusName: "",
      categoryname_options: [],
      subcategoriessearchTerm: "",
      subcategoriesrows: [],
      subcategoriescolumns: [],
      subcategoriessearchTerm: "",
      categoriessearchTerm: "",
      categoriesrows: [],
      categoriescolumns: [],
      categoriessearchTerm: "",
      pastsessiondateNtim: "",
      bookingreqrows: [],
      bookingreqcolumns: [],
      bookingreqdateNtim: "",
      categoryname_options: [],
      subcategoriessearchTerm: "",
      subcategoriesrows: [],
      subcategoriescolumns: [],
      subcategoriessearchTerm: "",
      userDetail:{},
      tutor_type_selected_learner:"All",
      user_data:{},
      isOnlyLearner: false,
      selectedStatus: "",
      selectedItem: {},
      totalTutorWarning: 0,
      remainingBalanceToPay: 0.00,
      lastDayPaid:"",
      totalTutorEarning: 0.00,
    }
  },
  computed: {
    ...mapGetters({
        users:'userManagement/getUsers',
        review:'reviews/getReviews',
        getStatusList:'status/getStatus',
        getCategoryList:'categoryManagement/getCategories',
        getsettingList : 'settings/getsettingArrayFeild',
        gettutorTypesList: "settings/getTutorTypes",
      }),
    currency(){
        if (Object.keys(this.getsettingList).length != 0) {

            return this.getsettingList.currency.slice(4)
        } else {
            return ""
        }
    },
    status_options(){
      try {
        var arr = [];
        let statusObj = this.getStatusList.userStatus;
        for (const property in statusObj) {
          let curr_user_status = this.userStatusName ? this.userStatusName : "";
          if (curr_user_status == statusObj[property].status) {
            this.statusVariant = statusObj[property].variant;
          }
          var syllabusObj = {
            value: statusObj[property].status.toLowerCase(),
            text: statusObj[property].status,
            variant: statusObj[property].variant,                            
            key: property,
          };
          arr.push(syllabusObj);
        }
        return arr;
      } catch (error) {
        console.error("error", error);
        errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "status_options",
              errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
            });
        return arr;
      }
    },
  },
  created() {   
    this.init();
    this.$root.$off("warningIncrementOfTutor");
    this.$root.$on("warningIncrementOfTutor",(warn)=>{
        this.totalTutorWarning = warn
    })
    this.$root.$on("displayCredit",(warn)=>{
        this.userDetail.currentCredit = this.userDetail.currentCredit + warn
    })
    this.$root.$emit('DisplayAddCreditButton',this.userDetail.isChild)
    var allTabList = document.getElementsByClassName('nav-link active')
  },
  methods: {
    getTutorTypeStyle(value) {
        if(this.gettutorTypesList) {
            let index = this.gettutorTypesList.findIndex((val ) =>{
              return val.name == value
            });
            if(index > -1) {
                return {
                  'background-color' : this.gettutorTypesList[index].backgroundColor,
                  'color' : this.gettutorTypesList[index].textColor,
                  'border-color': this.gettutorTypesList[index].textColor,
                  'border-width': '1px',
                  'border-style': 'solid'
                }
            }
        } else {
            return ''
        }
    },
    init(){
        var self = this;
        this.isLearner = !Vue.prototype.$DetailPage_User_IsLearner
        self.$root.$off('changeLearner',()=>{
        })
        self.$root.$off("suspend")
        self.$root.$on("suspend", (data) => {
            let Index = this.status_options.findIndex((values) => {
                return values.key == data;
            });
            this.userDetail.status = Number(this.status_options[Index].key);
            this.userDetail.userStatusName = this.status_options[Index].text;
            this.userDetail.userVariantName = this.status_options[Index].variant;
        });
        self.tabIndex = 0;
        self.userDetail ={}
        self.isSpinner = true;
        if(self.$route.params.userDetail && self.$route.params.userDetail !== undefined && self.$route.params.userDetail !== null){
            self.userDetail = self.$route.params.userDetail;
            self.userDetail.avatar = self.userDetail.profilePicture ? self.userDetail.profilePicture : "";
            self.userDetail.joinDate = self.userDetail.createdAt ? moment(new Date(self.userDetail.createdAt.seconds * 1000)).format('DD-MM-YYYY') : "";
            self.userDetail.dob = "";
            if(self.userDetail.dateOfBirth !== '' && self.userDetail.dateOfBirth !== undefined && self.userDetail.dateOfBirth !== null){
              self.userDetail.dob = moment(new Date(self.userDetail.dateOfBirth.seconds * 1000)).format('DD-MM-YYYY');
            }
             if(self.userDetail.status !== 0) {
                let pendingIndex = this.status_options.findIndex((value) => {
                    return value.key === "0"
                });
                this.status_options.splice(pendingIndex , 1);
            }
            if(self.userDetail.status === 3) { //user is blocked
                let removeIndex = this.status_options.findIndex((value) => {
                    return value.key === "2" //suspend
                });
                this.status_options.splice(removeIndex , 1);
            }
            self.remainingBalanceToPay = self.userDetail.currentAmount ? parseFloat(self.userDetail.currentAmount).toFixed(2) : 0.00;
            self.totalTutorEarning = self.userDetail.tutorEarning ? parseFloat(self.userDetail.tutorEarning).toFixed(2) : 0.00;
            self.getLastDayPaid();
            self.callAfterDataFch();
            self.$root.$emit('userDetailForUserRole', self.userDetail.userRole);
            self.$root.$emit('userDetailStatus', self.userDetail.status);
            self.$root.$emit('userDetailForName', (self.userDetail.firstName ? self.userDetail.firstName : "" )+ " " + (self.userDetail.lastName ? self.userDetail.lastName : ""));
            self.isSpinner = false;

        }else {
          self.isSpinner = true;
          getFirebase.simpleGetDataFromSubCollectionWithDocID(dbCollections.USERS,this.$route.params.id,(userData)=>{
              if (userData || userData !== null) {
                  self.userDetail = userData;
                  if( self.userDetail.status !== undefined){
                      let getIndex = this.status_options.findIndex((values)=>{
                        return Number(values.key) === self.userDetail.status
                      })
                      if(getIndex > -1){
                          self.userDetail.userStatusName = this.status_options[getIndex].text;
                          self.userDetail.userVariantName = this.status_options[getIndex].variant;
                      }
                      if(self.userDetail.status !== 0) {
                          let pendingIndex = this.status_options.findIndex((value) => {
                              return value.key === "0"
                          });
                          this.status_options.splice(pendingIndex , 1);
                      }
                      if(self.userDetail.status === 3) { //user is blocked
                        let removeIndex = this.status_options.findIndex((value) => {
                            return value.key === "2" //suspend
                        });
                        this.status_options.splice(removeIndex , 1);
                    }
                  }
                  else{
                      self.isSpinner = false;
                  }                     
                  self.userDetail.userRandomId = self.userDetail.userId
                  self.userDetail.id = self.$route.params.id
                  self.userDetail.avatar = self.userDetail.profilePicture ? self.userDetail.profilePicture : "";
                  self.userDetail.joinDate = self.userDetail.createdAt ? moment(new Date(self.userDetail.createdAt.seconds * 1000)).format('DD-MM-YYYY') : "";
                  self.userDetail.dob = "";
                  if(self.userDetail.dateOfBirth !== '' && self.userDetail.dateOfBirth !== undefined && self.userDetail.dateOfBirth !== null){
                    self.userDetail.dob = moment(new Date(self.userDetail.dateOfBirth.seconds * 1000)).format('DD-MM-YYYY');
                  }
                  self.remainingBalanceToPay = self.userDetail.currentAmount ? parseFloat(self.userDetail.currentAmount).toFixed(2) : 0.00;
                  self.totalTutorEarning = self.userDetail.tutorEarning ? parseFloat(self.userDetail.tutorEarning).toFixed(2) : 0.00;
                  self.getLastDayPaid();
                  self.callAfterDataFch();
                  self.$root.$emit('userDetailForUserRole',self.userDetail.userRole);
                  self.$root.$emit('userDetailStatus', self.userDetail.status);
                  self.$root.$emit('userDetailForName',(self.userDetail.firstName ? self.userDetail.firstName : "" )+ " " + (self.userDetail.lastName ? self.userDetail.lastName : ""));
                  self.isSpinner = false;
              }
              else{
                  self.isSpinner = false;  
              }
              try{
                if(Object.keys(userData).length > 0){
                  const storage = firebase.storage();
                  if(userData.tutorQualification !== undefined && userData.tutorQualification !== null){
                    userData.tutorQualification.forEach((values)=>{
                      values.tutorDocName = '';  
                      let fileRef = storage.refFromURL(values.uploadDocument);
                      values.tutorDocName = fileRef.name;
                    })
                  }
                }
              }catch(error){
                console.error(error)
                 errorEmail({
                  projectName : process.env.VUE_APP_PROJECTNAME,
                  environment : process.env.NODE_ENV,
                  fileName : currentFileName,
                  hookName : "init",
                  errorMessage : error.message
                },(res)=>{
                  if(res.error){
                      console.error(res.error)
                  }
                });
              }
              self.user_data = userData;
          })
        }
    },
    getLastDayPaid() {
        try {
            var self = this;
            db.collection(dbCollections.WITHDRAW_REQUEST)
                .where("userId", "==", self.$route.params.id)
                .where("status", "==", 2)
                .orderBy("createdAt", "desc")
                .limit(1)
                .get()
                .then((resp) => {
                    if(!resp.empty) {
                        self.lastDayPaid = moment(new Date(resp.docs[0].data().createdAt.seconds*1000)).format("DD-MM-YYYY");
                    } else {
                        self.lastDayPaid = "";
                    }
                })
        } catch (error) {
            console.error(error);
            errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "getRemainingBalanceToPay",
              errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
            });
        }
    },
    getDataAgain(){
        this.$root.$off('changeLearner',()=>{
        })
        this.init();
    },
    callAfterDataFch(){
      var self = this;
      self.isOnlyLearner = false;
      if(self.userDetail.userRole && self.userDetail.userRole.includes('learner') && !self.userDetail.userRole.includes('tutor')){
          self.isLearner = false;
          self.isOnlyLearner = true;
      }
      if(self.userDetail.userRole && !self.userDetail.userRole.includes('learner') && self.userDetail.userRole.includes('tutor')){
        self.isLearner = false;
        self.isOnlyLearner = false;
      }
      else{
        self.isLearner = true;
        self.$root.$emit('learnerValue',self.isLearner)
      }
      self.$root.$on('changeLearner',(value)=>{
        self.isLearner = !value
        self.tabIndex = 0
      })
      self.$root.$off('openLearnerCreditModel')
      self.$root.$on('openLearnerCreditModel',()=>{
        self.creditModel = true;
      })
    },    
    downloadDocument(doc){
            var self = this
             var httpsReference = firebase.storage().refFromURL(doc)
            self.$axios({
                url: doc,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', httpsReference.name);
                document.body.appendChild(fileLink);

                fileLink.click();
            })
            .catch(error=>{
                console.error(error)
                self.$root.$emit('showToastMessage',error.message,'danger')
            })
    },
    toggleFlag: function (index, column) {
      this.reviewrows[index][column].toggled = true;
    },
    limitTo: function (value) {
      if (!value) return "";
      return value.substring(0, 90) + "...";
    },
    showReview: function (index) {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm(index, {
          title: "Review",
          hideHeaderClose: false,
          centered: true,
          footerClass: "review-footer",
          headerClass: "review-header",
          bodyClass: "review-body",
        })
        .then((value) => {
          this.boxOne = value;
        });
    },
    showModelStatus(status, item) {
        this.selectedStatus = status;
        this.selectedItem = item;
        if(this.userDetail.status !== Number(status.key)) { 
            this.$bvModal.show(`modal-${status.value}`)
        }
    },
    activateUser() { 
        this.isSpinner = true;
        try {
            if(this.userDetail.status === 0) {
                verifyUser({
                   uid: this.userDetail.id
                },(res) => {
                  if(res && res.status) {
                      updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS, this.userDetail.id, {
                          status: 1,
                          updatedAt: new Date()
                      }, () => {
                          this.userDetail.status = 1;
                          this.userDetail.userStatusName = this.selectedStatus.text;
                          this.userDetail.userVariantName = this.selectedStatus.variant;
                          let removeIndex = this.status_options.findIndex((value) => {
                              return value.key === "0" //pending
                          });
                          this.status_options.splice(removeIndex , 1);
                          this.isSpinner = false;
                          sendNotificationUserStatus({
                              uid: this.userDetail.id,
                              userStatus: "Active"
                          },() => {
                            this.isSpinner = false;
                          })
                          this.$root.$emit(
                            "showToastMessage",
                            "User activated successfully.",
                            "success"
                          );
                      });
                  }else {
                      this.isSpinner = false;
                  }
                })
            } else if (this.userDetail.status === 3) {
                unblockUser({
                   uid: this.userDetail.id
                },(res) => {
                  functionForActiveChild(this.userDetail.id,false,()=>{})
                  if(res && res.status){
                    updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS, this.selectedItem.id, {
                        status: 1,
                        updatedAt: new Date()
                    }, () => {
                      this.userDetail.status = 1;
                      this.userDetail.userStatusName = this.selectedStatus.text;
                      this.userDetail.userVariantName = this.selectedStatus.variant;
                      let addStatusObj = {
                        value: this.getStatusList.userStatus[2].status.toLowerCase(),
                        text: this.getStatusList.userStatus[2].status,
                        key: "2",
                        variant: this.getStatusList.userStatus[2].variant
                      }
                      this.status_options.push(addStatusObj);
                      this.isSpinner = false;
                      sendNotificationUserStatus({
                        uid: this.userDetail.id,
                        userStatus: "Active"
                      },() => {})
                      this.$root.$emit(
                        "showToastMessage",
                        "User activated successfully.",
                        "success"
                      );
                    });
                  } else {
                      this.$root.$emit(
                        "showToastMessage",
                        "User not activated.",
                        "danger"
                      );
                  }
                })
            } else if(this.userDetail.status === 2) {
                updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS, this.selectedItem.id, {
                      status: 1,
                      updatedAt: new Date()
                  }, () => {
                    functionForActiveChild(this.userDetail.id,true,()=>{})
                      this.userDetail.status = 1;
                      this.userDetail.userStatusName = this.selectedStatus.text;
                      this.userDetail.userVariantName = this.selectedStatus.variant;
                      this.isSpinner = false;
                      sendNotificationUserStatus({
                          uid: this.userDetail.id,
                          userStatus: "Active"
                      },() => {})
                      this.$root.$emit(
                        "showToastMessage",
                        "User activated successfully.",
                        "success"
                      );
                  });
            }
        } catch (error) {
            console.error(error);
            this.isSpinner = false;
            errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "activateUser",
              errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
            })
        }
    },
    suspendTutorUser(){
      this.isSpinner = true;
      try {
        updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS, this.selectedItem.id, {
            status: 2,
            suspendUserTime: new Date(),
            updatedAt: new Date()
        }, () => {
            suspendActionFun(this.selectedItem.id).then(() => {
                this.userDetail.status = 2;
                this.userDetail.userStatusName = this.selectedStatus.text;
                this.userDetail.userVariantName = this.selectedStatus.variant;
                this.isSpinner = false;
                functionForSuspendOrBlockChild(this.selectedItem.id,true,false,()=>{})
                sendNotificationUserStatus({
                    uid: this.selectedItem.id,
                    userStatus: "Suspend"
                },() => {})
                this.$root.$emit(
                  "showToastMessage",
                  "User suspended successfully.",
                  "success"
                );
            }).catch((error) => {
                this.isSpinner = false;
                console.error(error);
                this.$root.$emit(
                  "showToastMessage",
                  "User not suspended.",
                  "danger"
                );
            })
        });
      } catch (error) {
        console.error(error);
        this.isSpinner = false;
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "suspendTutorUser",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        })
      }
    },
    blockUser(){
      this.isSpinner = true;
      try {
          let item = this.selectedItem;
          if(item.status === 2) {
              updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS, item.id, {
                  status:3,
                  updatedAt: new Date()
              }, () => {
                  blockUser({
                     uid: item.id
                  },(res) => {
                    if(res && res.status){
                      functionForSuspendOrBlockChild(this.selectedItem.id,false,true,()=>{})
                      this.userDetail.status = 3;
                      this.userDetail.userStatusName = this.selectedStatus.text;
                      this.userDetail.userVariantName = this.selectedStatus.variant;
                      let removeIndex = this.status_options.findIndex((value) => {
                          return value.key === "2" //suspend
                      });
                      this.status_options.splice(removeIndex , 1);
                      this.isSpinner = false;
                        sendNotificationUserStatus({
                            uid: item.id,
                            userStatus: "Block"
                        },() => {})
                        this.$root.$emit(
                          "showToastMessage",
                          res.statusText,
                          "success"
                        );
                    } else {
                        this.isSpinner = false;
                        this.$root.$emit(
                          "showToastMessage",
                          res.statusText,
                          "danger"
                        );
                    }
                  })
              })
          } else {
              updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS, item.id, {
                  status:3,
                  updatedAt: new Date()
              }, () => {
                  suspendActionFun(item.id)
                  .then(() => {
                    blockUser({
                      uid: item.id
                    },(res) => {
                        if(res && res.status){
                          functionForSuspendOrBlockChild(this.selectedItem.id,false,true,()=>{})
                          this.userDetail.status = 3;
                          this.userDetail.userStatusName = this.selectedStatus.text;
                          this.userDetail.userVariantName = this.selectedStatus.variant;
                          let removeIndex = this.status_options.findIndex((value) => {
                              return value.key === "2" //suspend
                          });
                          this.status_options.splice(removeIndex , 1);
                          this.isSpinner = false;
                          sendNotificationUserStatus({
                              uid: item.id,
                              userStatus: "Block"
                          },() => {})  
                          this.$root.$emit(
                            "showToastMessage",
                            res.statusText,
                            "success"
                          );
                        } else {
                          this.isSpinner = false;
                          this.$root.$emit(
                            "showToastMessage",
                            res.statusText,
                            "danger"
                          );
                        }
                    })
                  }).catch((error) => {
                      console.error(error);
                      this.isSpinner = false;
                      this.$root.$emit(
                        "showToastMessage",
                        "User not suspended.",
                        "danger"
                      );
                  })
              })
          }
      } catch (error) {
          console.error(error);
          this.isSpinner = false;
          errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "blockUser",
            errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          })
      }
    },
    updateLearnerStatus(status) {
      this.isUpdating = true;
      let user_id = this.$route.params.id;
      let obj = {
        status: parseInt(status.key),
      };
      update.updateRootCollectionDataByDocId(
        dbCollections.USERS,
        user_id,
        obj,
        (res) => {
          this.userDetail.userStatusName = status.text;
          this.userDetail.userVariantName = status.variant;
          this.isUpdating = false;
        }
      );
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      this.submittedNames.push(this.name);
    },

  },
  mounted() {
    let d = [];
    d = this.reviewrows.map((item) => {
      let o = {
        avatar: {
          txt: item.avatar,
          toggled: true,
        },
        tutorname: {
          txt: item.tutorname,
          toggled: true,
        },
        star: {
          txt: item.star,
          toggled: true,
        },
        rating: {
          txt: item.rating,
          toggled: true,
        },
        reviews: {
          txt: item.reviews,
          toggled: false,
        },
      };
      return o;
    });

    this.reviewrows = d;
  },
  beforeRouteLeave(from,to,next){
   var self = this;
     var  name = ""
     self.$root.$emit('userDetailForName', name);
     next()
  } 
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
span.resume.download_btn p {
    text-decoration: underline;
    cursor: pointer;
    color: #0096DB !important;
}
.userDetailWrapper .col-xl-1 {
    flex: 0 0 10.8%;
    max-width: 10.8%;
}
.userDetailWrapper .col-xl-11 
{
    flex: 0 0 88.8%;
    max-width: 88.8%;
}
.user_img {
    margin-right:0px;
}
</style>