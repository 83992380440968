<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isSpinner"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
    </div>

    <div class="card user_filter all_session">
      <h2>Filters</h2>

      <b-form @submit.prevent>
        <b-row>
          <b-col xl="3" lg="3" md="3">         
            <label>Learner/Child</label>
            <b-dropdown
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :text="learner_selected"
              variant="outline-secondary"
              class="w-100"
            >
              <b-dropdown-item
                v-for="item in learner_name_options"
                :key="item.value"
                @click="learner_selected = item.text"
                >{{ item.text }}</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <label>Tutor</label>
            <b-dropdown
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :text="byTutorFiltered"
              variant="outline-secondary"
              class="w-100"
            >
              <b-dropdown-item
                v-for="item in tutor_name_options"
                :key="item.value"
                @click="byTutorFiltered = item.text"
                >{{ item.text }}</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>
      </b-form>
    </div>

    <div class="card user_table request_session_table review_management_table">
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="reviewmanagementsearchTerm"
              placeholder="Search..."
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <vue-good-table
        :columns="reviewmanagementcolumns"
        :rows="filteredData"
        :rtl="true"
        :search-options="{
          enabled: true,
          externalQuery: reviewmanagementsearchTerm,
        }"
        :select-options="{
          enabled: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'learnername'" class="text-nowrap">
            <b-avatar
              :src="props.row.learnerProfile"
              alt="profile image"
              class="user_img"
            />
            <span class="userid">
              <router-link :to="{ name: 'user-details', params: { id: props.row.learnerId},}">{{
                props.row.learnername
              }}</router-link>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'tutorName'"
            class="text-nowrap"
          >
            <b-avatar :src="props.row.profilePicture" class="user_img" />
            <span class="userid">
              <router-link :to="{ name: 'user-details', params: {id: props.row.tutorId},}">{{props.row.tutorName}}</router-link>
            </span>
          </span>
          <span
            v-else-if="props.column.field === 'tutorType'"
            class="tutor_type"
          >
            <span>
            <!-- <b-badge :variant="props.row.tutorialTypeVariant.values"> -->
            <b-badge :style="getTutorTypeStyle(props.row.tutorType)">
              {{ props.row.tutorType }}
            </b-badge>
            </span>
          </span>
          <span
            v-if="props.column.field === 'stars'"
            v-bind:class="props.row.stars !== '' ? 'rating' : 'hide'"
          >
            <b-img
              v-if="props.row.stars !== '' && props.row.stars !== 0"
              src="@/assets/images/raty/star-on-2.png"
              class="star_img"
            />
            <b-img
              v-if="props.row.stars == '' && props.row.stars == 0"
              src="@/assets/images/raty/star-half-2.png"
              class="star_img"
            />
            {{ props.row.stars }}
          </span>
          <div
                v-else-if="props.column.field === 'description'"
                class="wrap-break-word"
                v-show="true"
                >
                <span v-html="limitTo(props.row.description)"></span>
                <a
                    class="readmore"
                    v-b-modal="'readmore' + props.row.id"
                    v-show="!props.row['isEditable'] && props.row.description.length >= 75"
                    
                    >Read more</a
                >
                <UserModal title="Cancel Reason" :text="props.row.description" :id="props.row.id" />
          </div>
          <span
            v-else-if="props.column.field === 'action'"
            class="action_field d-inline-block"
          >
            <div v-if="props.row.status == 1">
              <p>Approved</p>
            </div>
            <div v-else-if="props.row.status == 2">
              <p>Rejected</p>
            </div>
            <div v-else>
              <b-button
                variant="outline-success"
                v-on:click="showMsgBoxOne(props.row)"
              >
                Approve
              </b-button>
              <b-button
                variant="outline-danger"
                v-b-modal.modal-prevent-closing
                @click="selectedUser = props.row.id"
              >
                Reject
              </b-button>
            </div>
          </span>       
        </template>
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to
                  <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                  <span v-else>{{props.total}} of {{ props.total }} entries</span>
                </span>

                    <div class="d-flex align-items-center mb-1 mt-0 showentry">
                      <span>Show</span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10', '20', '30', '40', '50']"
                        class="mx-1"
                        @input="
                          (value) => props.perPageChanged({ currentPerPage: value })
                        "
                     />
                      <span>entries</span>
                    </div>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :current-page="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => {
                      props.pageChanged({ currentPage: value });
                      currentPage = value
                    }
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
        </template>
      </vue-good-table>
    </div>
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      title="Reject Reason"
      ok-title="Reject"
      data-class="addcredit_popup"
      data-value-class="addcredit_popup"
      centered
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      :ok-disabled="isSpinner"
      :cancel-disabled="isSpinner"
    >
    <validation-observer ref="ReviewRejectform">
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <validation-provider
            #default="{ errors }"
            name="reason for reject"
            rules="required|min:3|max:300"
        >
        <b-form-group
          label="Reason for Reject"
          label-for="name-input"
          invalid-feedback="Reason for reject is required"
        >
          <b-form-textarea
            id="name-input"
            v-model="rejectname"
            placeholder="Enter Reason For Reject"
            :maxlength="301"
            required
          />
          <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
        </b-form-group>
        </validation-provider>
      </form>
    </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BModal,
  VBModal,
  BListGroup,
  BListGroupItem,
  BFormTextarea,
} from "bootstrap-vue";
import { dbCollections } from "@/utils/firebaseCollection.js";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import UserModal from "@/@core/components/modals/ReadMoreModal.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import firebase from "@/utils/firebaseInit"
const db = firebase.firestore();
export default {
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  components: {
    VueGoodTable,
    BCard,
    UserModal,
    BImg,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    BListGroup,
    BListGroupItem,
    BModal,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      isSpinner: false,
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      pageLength: 10,
      dir: false,
      reviewmanagementsearchTerm: "",
      reviewmanagementcolumns: [
        {
          label: "Learner Name",
          field: "learnername",
        },
        {
          label: "Tutor Name",
          field: "tutorName",
        },
        {
          label: "Tutor Type",
          field: "tutorType",
        },
        {
          label: "Rating",
          field: "stars",
        },
        {
          label: "Reviews",
          field: "description",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      reviewmanagementsrows: [],
      tutorType: [
        { text: "Valuable", values: "light-primary" },
        { text: "Elite", values: "light-danger" },
        { text: "Normal", values: "light-success" },
        { text: "Expert", values: "light-warning" },
      ],
      readMore: false,
      tutor_name_options: [{ value: null, text: "All" }],
      learner_name_options: [{ value: null, text: "All" }],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      rejectname: "",
      selectedUser: "",
      nameState: null,
      submittedNames: [],
      byTutorFiltered: "All",
      learner_selected: "All",
    };
  },
  computed: {
    ...mapGetters({ review: "reviews/getReviews",users: "userManagement/getUsers", getStatusList:'status/getStatus' ,  gettutorTypesList: "settings/getTutorTypes"}),
    filteredData() {
      this.review.forEach((item) => {
        if (item.description == "" || item.description == undefined) {
          item.description = "-";
        }
        if (item.stars == "" || item.stars == undefined) {
          item.stars = 0;
        }
        if (this.users.length > 0) {
          let sIndex = this.users.findIndex((values) => {
            return values.id == item.tutorId;
          });
          if (sIndex > -1) {
            item.descToggled = null;
            item.tutorName = this.users[sIndex].firstName + " " + this.users[sIndex].lastName;
            item.tutorType = this.users[sIndex].tutorType  ? this.users[sIndex].tutorType : "-";
            item.userRoleTutor = 'tutor'
            item.action = ["Approve", "Reject"];
            item.profilePicture = this.users[sIndex].profilePicture;
            if (this.tutor_name_options.length > 0) {
              let getInd = this.tutor_name_options.findIndex((elem) => {
                return elem.value == this.users[sIndex].id;
              });
              if (getInd <= -1) {
                this.tutor_name_options.push({
                  value: this.users[sIndex].id ? this.users[sIndex].id : "",
                  text:
                    this.users[sIndex].firstName +
                    " " +
                    this.users[sIndex].lastName,
                });
              }
            }
            let searchVariant = this.tutorType.findIndex((vall) => {
              return vall.text == item.tutorType;
            });
            if (searchVariant > -1) {
              item.tutorialTypeVariant = this.tutorType[searchVariant];
            } else {
              item.tutorialTypeVariant = this.tutorType[3];
            }
          }
          let learnerIndex = this.users.findIndex((values) => {
            return values.id == item.learnerId;
          });
          if (learnerIndex > -1) {
            item.learnerProfile = this.users[learnerIndex].profilePicture;
            item.learnername = this.users[learnerIndex].firstName +" " + this.users[learnerIndex].lastName;
            item.userRoleLearner = 'learner';
            if (this.learner_name_options.length > 0) {
              let getInd = this.learner_name_options.findIndex((elem) => {
                return elem.value == this.users[learnerIndex].id;
              });
              if (getInd <= -1) {
                this.learner_name_options.push({
                  value: this.users[learnerIndex].id? this.users[learnerIndex].id
                    : "",
                  text:
                    this.users[learnerIndex].firstName +
                    " " +
                    this.users[learnerIndex].lastName,
                });
              }
            }
          }
        }
      });
      let temp = this.review;
      if (this.learner_selected != "All") {
        temp = this.filterByLearnerSelected(temp);
      }
      if (this.byTutorFiltered !== "All") {
        temp = this.filterByTutorSelected(temp);
      }
      this.reviewmanagementsrows = temp;
      return this.reviewmanagementsrows;
    },
  },
  methods: {
    getTutorTypeStyle(value) {
        if(this.gettutorTypesList) {
            let index = this.gettutorTypesList.findIndex((val ) =>{
              return val.name == value
            });
            if(index > -1) {
                return {
                  'background-color' : this.gettutorTypesList[index].backgroundColor,
                  'color' : this.gettutorTypesList[index].textColor,
                  'border-color': this.gettutorTypesList[index].textColor,
                  'border-width': '1px',
                  'border-style': 'solid'
                }
            }
        } else {
            return ''
        }
    },
    filterByLearnerSelected(data) {
      return data.filter((user) => user.learnername == this.learner_selected);
    },
    filterByTutorSelected(data) {
      return data.filter((user) => user.tutorName == this.byTutorFiltered);
    },
    showMsgBoxOne: function (data) {
      this.boxOne = "";
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to Approve?", {
          title: "Approve",
          class: "test",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-primary",
          hideHeaderClose: false,
          centered: true,
          footerClass: "approve-footer",
          headerClass: "approve-header",
          bodyClass: "approve-body",
        })
        .then((value) => {
          if (value) {
            this.boxOne = value;
            var approvedMessage = {
              status: 1,
              updateAt: new Date(),
            };
            updateFirebase.updateRootCollectionDataByDocId(
              dbCollections.REVIEWS,
              data.id,
              approvedMessage,
              (res) => {
                let tutorIndex = this.users.findIndex((ele)=>{
                  return ele.id == data.tutorId
                })
                if (tutorIndex!==-1) {
                  if (this.users[tutorIndex].tutorTotalRatingUserCount == 0) {
                    let update = {
                       tutorTotalRatingSum :  data.stars,
                       tutorTotalRatingUserCount:  1,
                       totalRating: Number((data.stars / 1).toFixed(2)),
                       updatedAt: new Date(),
                     }
                      updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,data.tutorId,update,(response)=>{
                        let newId = db.collection(dbCollections.UPDATERECORDS).doc()
                       let updateObj = {
                        rating :  update.totalRating,
                        createdAt : new Date(),
                        userId: this.users[tutorIndex].id,
                        id: newId.id
                       }
                       newId.set(updateObj).then(()=>{
                       }).catch((error)=>{
                          console.error("error",error)
                       })
                       this.$root.$emit(
                         "showToastMessage",
                         "Review approved successfully.",
                         "success"
                       );
                      })
                  } else {
                    let update = {
                       tutorTotalRatingSum : this.users[tutorIndex].tutorTotalRatingSum + data.stars,
                       tutorTotalRatingUserCount: this.users[tutorIndex].tutorTotalRatingUserCount + 1,
                       totalRating: Number(((this.users[tutorIndex].tutorTotalRatingSum + data.stars) /  (this.users[tutorIndex].tutorTotalRatingUserCount + 1)).toFixed(2)),
                       updatedAt: new Date(),
                     }
                      updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,data.tutorId,update,(response)=>{
                       let newId = db.collection(dbCollections.UPDATERECORDS).doc()
                       let updateObj = {
                        rating :  update.totalRating,
                        createdAt : new Date(),
                        userId: this.users[tutorIndex].id,
                        id: newId.id
                       }
                       newId.set(updateObj).then(()=>{
                       }).catch((error)=>{
                          console.error("error",error)
                       })
                       this.$root.$emit(
                         "showToastMessage",
                         "Review approved successfully.",
                         "success"
                       );
                      })
                  }
                }
              }
            );
          }
        });
    },    
    limitTo: function (value) {
        if(value){
            if (value.length < 75){
                return value
            }else{

                return value.substring(0, 75) + "...";
            }
        }else{
            return "-";
        }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      return valid;
    },
    resetModal() {
      this.rejectname = "";
      this.nameState = null;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.$refs.ReviewRejectform.validate().then((valid)=>{
        if(valid){
          let id = this.selectedUser;
          this.submittedNames.push(this.rejectname);
          this.isSpinner = true;
          var rejectMessage = {
            status: 2,
            updateAt: new Date(),
            reason: this.rejectname,
          };
          updateFirebase.updateRootCollectionDataByDocId(
            dbCollections.REVIEWS,
            id,
            rejectMessage,
            (res) => {
              this.$root.$emit("showToastMessage", "Review rejected successfully.", "danger");
              this.$nextTick(() => {
                this.$bvModal.hide("modal-prevent-closing");
                this.isSpinner = false;
              });
            }
          );
        }
      })
    },    
  },
};
</script>

