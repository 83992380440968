<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
      style="z-index:9999;"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
   
    </div>
      <div class="card banner_top_section">
        <div>
          Banner parameters for uploading must be
          <strong>W 394 x H 153 px</strong>, and file size should not exceed
          <strong>200KB</strong>, file formats supported for
          <strong>jpg</strong> and <strong>png</strong>.
        </div>
      </div>
      <div class="card user_table banner_table">
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="searchTerm"
                placeholder="Search..."
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="bannersList"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'no'" class="text-nowrap">
              {{(PageNumber*10)+(props.index+1) &lt; 10 ?'0'+(props.index+1):(PageNumber*10)+(props.index+1)}}
              </span>

              <span v-else-if="props.column.field === 'userType'" class="text-nowrap" style="text-transform: capitalize !important;">
                  {{props.row.userType.join(',')}}
              </span>
              <span v-else-if="props.column.field === 'createdAt' && props.row.updatedAt" class="text-nowrap">
                {{bannerCreateDate(props.row.updatedAt)}}
              </span>

              <span v-else-if="props.column.field === 'status'" class="status">
                <b-badge :variant="getAllStatusList.bannerStatus[props.row.status].variant">
                  {{ getAllStatusList.bannerStatus[props.row.status].status }}
                </b-badge>
              </span>
            <span
              v-else-if="props.column.field === 'action'"
              class="action_field action_field1517 paddingremove"
            >
            <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="editBannerData(props.row.id)">
                      <span >
                        <feather-icon icon="CheckSquareIcon" size="20" />
                        Edit
                      </span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="deleteBannerData(props.row.id)" >
                      <span>
                        <feather-icon icon="XCircleIcon" size="20" />
                        Delete</span
                      >
                    </b-dropdown-item >
                    <b-dropdown-item v-if="props.row.status == 0" @click="ChangeBannerStatus('inactive',props.row.id)">
                      <span>
                        <feather-icon icon="AlertTriangleIcon" size="20" />
                        Inactive</span
                      >
                    </b-dropdown-item>
                    <b-dropdown-item @click="ChangeBannerStatus('active',props.row.id)" v-else>
                      <span>
                        <feather-icon icon="AlertTriangleIcon" size="20" />
                        Active</span
                      >
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> 
                  <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to
                  <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                  <span v-else>{{props.total}} of {{ props.total }} entries</span>
                </span>

                    <div class="d-flex align-items-center mb-1 mt-0 showentry">
                      <span>Show</span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10', '20', '30', '40', '50']"
                        class="mx-1"
                        @input="
                          (value) => props.perPageChanged({ currentPerPage: value })
                        "
                     />
                      <span>entries</span>
                    </div>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :current-page="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => {
                      props.pageChanged({ currentPage: value });
                      currentPage = value
                    }
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    
  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BSpinner,
  BForm,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { mapActions,mapGetters } from "vuex";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import {dbCollections} from "@/utils/firebaseCollection.js"
import moment from "moment";
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BSpinner,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BButton,
  },
  data() {
    return {
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      pageLength: 10,
      dir: false,
      isSpinner:false,
      isUpdating:false,
      direction:false,
      PageNumber : 0,
      columns: [
        {
          label: "No.",
          field: "no",
        },
        {
          label: "File Name",
          field: "fileName",
        },
        {
          label: "File Upload Date",
          field: "createdAt",
        },
        {
          label: "File Size",
          field: "fileSizeInKB",
        },
        {
          label: "User Role",
          field: "userType",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [
        {
          no: "01",
          fullName: "Learning_Banner.jpg",
          uploaddate: "29-12-2021 , 10:24 AM",
          filesize: "199 KB",
          userrole: "Learner",
          status: 1,
          action: "action",
        },
        {
          no: "02",
          fullName: "Tutor_Banner.jpg",
          uploaddate: "29-12-2021 , 10:24 AM",
          filesize: "150 KB",
          userrole: "Tutor",
          status: 1,
          action: "action",
        },
        {
          no: "03",
          fullName: "New_Year_Banner.jpg",
          uploaddate: "29-12-2021 , 10:24 AM",
          filesize: "168 KB",
          userrole: "Learner, Child, Tutor",
          status: 1,
          action: "action",
        },
        {
          no: "04",
          fullName: "New_Year_Offer_Banner.jpg",
          uploaddate: "29-12-2021 , 10:24 AM",
          filesize: "135 KB",
          userrole: "Learner, Child",
          status: 1,
          action: "action",
        },
        {
          no: "05",
          fullName: "Natal_Festival_Banner.jpg",
          uploaddate: "29-12-2021 , 10:24 AM",
          filesize: "184 KB",
          userrole: "Learner, Tutor",
          status: 2,
          action: "action",
        },
        {
          no: "06",
          fullName: "Natal_Festival_Offer_Banner.jpg",
          uploaddate: "29-12-2021 , 10:24 AM",
          filesize: "160 KB",
          userrole: "Learner",
          status: 2,
          action: "action",
        },
      ],
      status: [
        {
          1: "Active",
          2: "Inactive",
        },
        {
          1: "light-success",
          2: "light-danger",
        },
      ],
      searchTerm: "",

      imageFile:[],
      isImage:true,
    };
  },
  created() {
    this.isSpinner =true;
    this.getBannerData().then((res) => {
        this.isSpinner = false;
    });
  },
  methods:{
    ...mapActions({getBannerData:"bannerManagement/getBannersData"}),
    // CHECK THE CURRENT PAGE NUMBER (Shraddha)
    handleChangePage(page) {
      this.PageNumber = page-1;
    },
    checkFile(e)
    {
      var files = e.target.files || e.dataTransfer.files;
      this.isImage=true
      if(files.length!=0)
      {
        this.imageFile=[]
      }
      files.forEach(data=>{
          if(data.type.toLowerCase() !== 'image/png' 
          && data.type.toLowerCase() !== "image/jpeg" 
          && data.type.toLowerCase() !== "image/jpg" 
          && data.type.toLowerCase() !== 'png'
          && data.type.toLowerCase() !== 'jpeg'
          && data.type.toLowerCase() !== 'jpg')
          {
            this.isImage=false
          }
      })
      if(this.isImage){
        this.isImage=true
      }
      else
      { 
        document.getElementById("imagePicker").value=""
        this.imageFile=[]
      }
    },
    // FORMAT BANNER CREATE DATE AS PER REQUIREMENT (Shraddha)
    bannerCreateDate(bannerCreateDate){
      var returnBannerDate = moment(new Date(bannerCreateDate.seconds * 1000)).format('DD-MM-YYYY, hh:mm A')
      return returnBannerDate;
    },
    // FORMAT TIME AM AND PM FOR BANNER (Shraddha)
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    },
    // ADD ZERO IN FRONT SIDE IF VALUE IS LESS THEN 9(Shraddha)
    addZero(value){
      if(value > 9){
        return value;
      }else{
        return '0' + value;
      }
    },
    //DELETE BANNER DATA WHEN CLICK ON DELETE BUTTON(Shraddha)
    deleteBannerData(index){
      this.$bvModal
      .msgBoxConfirm('Are you sure you want to Delete it?', {
        title: 'Delete',         
        class:'test',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-primary',
        hideHeaderClose: false,
        centered: true,
        footerClass: 'approve-footer',
        headerClass: 'approve-header',
        bodyClass: 'approve-body',
      })
      .then(value => {
        if(value){
          this.isSpinner = true;
          var bannerUpdatedObject = {
            status : 2
          }
          updateFirebase.updateRootSubCollectionDataWithDocId(dbCollections.SETTINGS,'setting',dbCollections.BANNERMANAGEMENT,index,bannerUpdatedObject,(res) => {
              this.$root.$emit('showToastMessage','Banner deleted successfully.','success');
              this.isSpinner = false;
          })
        }
      })
    },
    //EDIT BANNER DATA WHEN CLICK ON EDIT BUTTON(Shraddha)
    editBannerData(id){
      var self = this;
      var index = self.bannersList.findIndex(item => {
          return item.id == id  
      })
      self.$root.$emit('editBannerPopup',true,index);
    },
    ChangeBannerStatus(status,index){
      this.isSpinner = true;
      if(status == 'inactive'){
          var bannerUpdatedObject = {
            status : 1
          } 
      }
      if(status == 'active'){
          var bannerUpdatedObject = {
            status : 0
          }
      }
      updateFirebase.updateRootSubCollectionDataWithDocId(dbCollections.SETTINGS,'setting',dbCollections.BANNERMANAGEMENT,index,bannerUpdatedObject,(res) => {
          this.$root.$emit('showToastMessage','Banner updated successfully.','success');
          this.isSpinner = false;
      })
    }
    //VIEW BANNER DATA WHEN CLICK ON VIEW BUTTON(Shraddha)
  },
  computed: {
    ...mapGetters({
      bannersList:'bannerManagement/getBanners',
      getAllStatusList:'settings/getAllStatus'
      }),
  }
};
</script>
