<template>
  <b-tab title="Transaction History" @click="getTabData">
    <div>
    <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners" />
    <div v-show="!isSpinner">
      <b-spinner variant="primary" v-if="isUpdating" large class="pageLoadSpinners" style="position: fixed" />
       <div class="card user_filter">
            <h2>Filters</h2>

            <b-form @submit.prevent>
            <b-row>
                <b-col xl="3" lg="4" md="5">
                <label>Date</label>
                <flat-pickr
                    v-model="dateNtim"
                    class="form-control datetime_picker"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', maxDate: new Date() }"
                />
                </b-col>
            </b-row>
            </b-form>
        </div>
      <div class="card user_table Transection_History_learner">
          <div class="custom-search d-flex justify-content-end">
                  <b-form-group>
                      <div class="d-flex align-items-center">
                          <b-form-input
                              v-model="searchTerm"
                              placeholder="Search..."
                              type="text"
                              class="d-inline-block"
                              
                          />
                      </div>
                  </b-form-group>
          </div>
          <vue-good-table :columns="dynamicColumns" class="cursor_pointer" :rows="filterRows" :rtl="direction" :search-options="{
              enabled: true,
              externalQuery: searchTerm,
          }" :select-options="{
              enabled: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
          }" :pagination-options="{
              enabled: true,
              perPage: pageLength,
          }">
              <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field === 'bookingId'" class="childname text-nowrap"  >
                       <router-link :to="{ name: 'booking-detail-transection-tutor', params: { id: props.row.bookingDocId} , query: {tab: 'learner'}}"   class="text-nowrap">
                        {{ props.row.bookingId }}
                        </router-link>

                    </span>
                  <span v-else-if="props.column.field === 'transactionid'" class="transact_id">
                      {{ props.formattedRow[props.column.field] }}
                  </span>
                  <span v-else-if="props.column.field === 'userName'" class="text-nowrap">
                      <b-avatar :src="props.row.avatar" class="user_img" />
                      <router-link    :to="{name: 'user-details',params: { id: props.row.userId}}" class="text-nowrap">{{ props.row.userName }}</router-link>
                  </span>
                  <span v-else-if="props.column.field === 'date'" class="date">
                      {{ props.row.date }}
                  </span>
                  <span v-else-if="props.column.field === 'remarks'" class="remarks userDetail_Remarks">
                      {{ props.formattedRow[props.column.field] }}
                  </span>
                  <span v-else-if="props.column.field === 'tutorType'" class="tutor_type">
                      <b-badge :variant="tutorType[1][props.row.tutorType]">
                          {{ tutorType[0][props.row.tutorType] }}
                      </b-badge>
                  </span>
                  <span v-else-if="props.column.field === 'amount'" class="credit" :style="props.row.transactionType == 'dr' ? 'color:#0D9E00':'color:#FF0000'">
                      {{props.row.amount}}
                  </span>
                   <span v-else-if="props.column.field === 'availAmount'" class="credit" >
                      {{props.row.availAmount}}
                  </span>
                  <span v-else-if="props.column.field === 'bookingCredits'" class="booking_Credits">
                      {{ props.formattedRow[props.column.field] }}
                  </span>
                  <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                  </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                      <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                          <span class="text-nowrap">
                          <span v-if="props.total === 0"> Showing 0 </span>
                          <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                          to
                          <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                          <span v-else>{{props.total}} of {{ props.total }} entries</span>
                          </span>

                              <div class="d-flex align-items-center mb-1 mt-0 showentry bookingRequest">
                              <span>Show</span>
                              <b-form-select
                                  v-model="pageLength"
                                  :options="['10', '20', '30', '40', '50']"
                                  class="mx-1"
                                  @input="
                                  (value) => props.perPageChanged({ currentPerPage: value })
                                  "
                              />
                              <span>entries</span>
                              </div>
                      </div>
                      <div>
                          <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :current-page="currentPage"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          @input="
                              (value) => {
                              props.pageChanged({ currentPage: value });
                              currentPage = value
                              }
                          "
                          >
                          <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18" />
                          </template>
                          <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18" />
                          </template>
                          </b-pagination>
                      </div>
                      </div>
              </template>
          </vue-good-table>
      </div>
    </div>
    </div>
  </b-tab>
</template>
<script>
import {
    BButton,
    BSpinner,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BTabs,
    BTab,
    BCardText,
    BCard,
} from "bootstrap-vue";
import {
    VueGoodTable
} from "vue-good-table";
import store from "@/store/index";
import flatPickr from "vue-flatpickr-component";
import * as getQuery from "@/utils/FirebaseQueries/getQueries/getQueries.js";
import { dbCollections } from "@/utils/firebaseCollection.js";
import moment from "moment";
import Ripple from 'vue-ripple-directive'
import firebase from "@/utils/firebaseInit.js"
const db = firebase.firestore()
import {errorEmail} from "@/utils/apiRequests.js"
import { mapGetters} from "vuex";
const currentFileName = "src/views/Users/User/UserDetailTabs/TransactionHistory_Tutor.vue"

export default {
    name: "TransectionHistoryTutor",
    directives: {
        Ripple,
    },
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BSpinner,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BRow,
        BCol,
        BForm,
        BButton,
        BTabs,
        BTab,
        BCardText,
        BCard,
        flatPickr
    },
    data() {
        return {
          direction:false,
          isSpinner:false,
          dateNtim: moment(new Date()).format("YYYY-MM-DD HH:mm"),
          isUpdating:false, 
          perPage: 10,
          pageOptions: [10, 20, 30, 40, 50],
          tutorTransection:[],
          tutorTabActive: false,
          searchTerm: "",
          currentPage: 1,
          pageLength: 10,
          columns: [
              {
                  label: "Transaction ID",
                  field: "transactionid",
              },
              {
                  label: "Date",
                  field: "date",
              },
              {
                  label: "Booking ID",
                  field: "bookingId",
              },
              {
                  label: "Amount",
                  field: "amount",
              },

              {
                  label: "Avail. Amount($)",
                  field: "availAmount",
              },
              {
                  label: "Remarks",
                  field: "remarks",
              },]
    };
    },
    computed: {
        ...mapGetters({
            getsettingList : 'settings/getsettingArrayFeild',
        }),
        currency(){
            if (Object.keys(this.getsettingList).length != 0) {

                return this.getsettingList.currency.slice(4)
            } else {
                return ""
            }
        },
        
        dynamicColumns(){
            this.columns[4].label = `Avail. Amount(${this.currency})`
            this.columns[3].label = `Amount(${this.currency})`	
            return this.columns
        },
        filterRows() {
          var listA = this.tutorTransection;
          if (this.dateNtim != moment(new Date()).format("YYYY-MM-DD HH:mm")) {
              listA = listA.filter((data) => {
              return data.filterDate < this.dateNtim
              });
          }
          return listA;
      },
    },
    created() {
      
    },
    methods: {
      getTabData(){
           try{ 
                var allTabList = document.getElementsByClassName('nav-link active')
                if (allTabList[0].innerText !== 'Transaction History') {
                    this.getTransection()
                }
            }catch (error) {
                errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "getTabData",
                errorMessage : error.message
                },(res)=>{
                 if(res.error){
                    console.error(res.error)
                }
                })
                console.error(error)

            }
      },
      getTransection(){
          try{
              var self = this;
              self.tutorTransection = []
              self.isSpinner = true;
              getQuery.simpleGetDataWithSpecificWhereCondition(dbCollections.TUTORTRANSACTIONS,"userId","==",this.$route.params.id,(transection)=>{
                  if (transection.length=== 0) {
                      self.isSpinner = false;
                      return;
                  }
                  else{
                      transection.forEach(element=>{
                          var bookingDocumentId = "";
                          if (element.bookingId !== undefined) {   
                              getQuery.simpleGetDataWithSpecificWhereCondition(dbCollections.BOOKING,'bookingId','==',element.bookingId,(booking)=>{
                                bookingDocumentId = booking[0] ? booking[0].id : ""
                                var obj = {
                                  date : element.createdAt ?  moment(new Date( element.createdAt.seconds * 1000)).format('DD-MM-YYYY, hh:mm A'): "",
                                  transactionid : element.id ? element.id : "",
                                  bookingId : element.bookingId ? element.bookingId : null,
                                  amount : element.amount ? Number((element.amount)).toFixed(2) : null,
                                  availAmount : element.availableAmount ?  Number((element.availableAmount )).toFixed(2) : null,
                                  remarks : element.remarks ? element.remarks : "",    
                                  avatar : element.userDetail ? element.userDetail.profileImage : "",
                                  bookingDocId : (bookingDocumentId !== "") ? bookingDocumentId : "",
                                  transactionType : element.transactionType,
                                  filterDate : element.createdAt ?  moment(new Date( element.createdAt.seconds * 1000)).format("YYYY-MM-DD HH:") :"",
                              }
                              self.tutorTransection.push(obj)
                              if(transection.length === self.tutorTransection.length){
                                  this.isSpinner = false;
                              }
                              })
                          }
                          else{
                              var obj = {
                                  date : element.createdAt ? moment(new Date( element.createdAt.seconds * 1000)).format('DD-MM-YYYY, hh:mm A') : "",
                                  transactionid : element.id ? element.id : "",
                                  bookingId : element.bookingId ? element.bookingId : null,
                                  credit : element.credits ? element.credits : null,
                                  availCredit : element.availableCredit ? element.availableCredit : null,
                                  remarks : element.remarks ? element.remarks : "",    
                                  amount : element.amount ? Number((element.amount)).toFixed(2) : null,
                                  bookingDocId : (bookingDocumentId !== "") ? bookingDocumentId : "",
                                  availAmount : element.availableAmount ?  Number((element.availableAmount )).toFixed(2) : null,
                                  transactionType : element.transactionType ? element.transactionType : "",
                                  filterDate : element.createdAt ?  moment(new Date( element.createdAt.seconds * 1000)).format("YYYY-MM-DD HH:") :"",
                              }
                              self.tutorTransection.push(obj)
                              if(transection.length === self.tutorTransection.length){
                                  this.isSpinner = false;
                              }
                          }
                      })
                  }
              })
          }catch (error) {
              errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "getTransection",
              errorMessage : error.message
              },(res)=>{
               if(res.error){
                  console.error(res.error)
              }
              })
              console.error(error)
          }
      },
    }
}
</script>

