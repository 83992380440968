<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />


       <div class="card user_table emailtemplate_table">
            <div class="custom-search d-flex justify-content-end subcategory_options">
              <b-form-group>
                <div class="d-flex align-items-center widthclass">
                  <label  class="category_label">Filter by Status</label>
                <b-dropdown
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  :text="selected_status"
                  variant="outline-secondary borderclass"
                  class="mr-2"
                >
                  <b-dropdown-item
                    v-for="item in emailOptions"
                    :key="item.value"
                    @click="selected_status = item.text"                     
                    >{{ item.text }}</b-dropdown-item
                  >
                </b-dropdown> 
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Search..."
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </div>
            <vue-good-table
              :columns="columns"
              :rows="CountRow"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm,
              }"
              :select-options="{
                enabled: true,
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
              <template slot="table-row" slot-scope="props" v-if="CountRow.length !== 0">
               <span v-if="props.column.field === 'created'" class="text-nowrap">
                  {{TemplateCreatedDate(props.row.created)}}
               </span>
                <span v-else-if="props.column.field === 'usertype'" class="text-nowrap" style="text-transform: capitalize !important;">
                  {{props.row.usertype}}
               </span>
              <span v-else-if="props.column.field === 'status'" class="status">
                <b-badge  :variant="getAllStatusList.emailTemplateStatus[props.row.status].variant">
                    {{ getAllStatusList.emailTemplateStatus[props.row.status].status[0].toUpperCase() + getAllStatusList.emailTemplateStatus[props.row.status].status.substring(1) }}
                  </b-badge>
              </span>
                <span
                  v-else-if="props.column.field === 'action'"
                  class="action_field"
                >
                <span v-if="props.row.status === 1" class="d-flex">
                  <b-button variant="flat-dark" @click="EditMailTemplate(props.row.id)">
                    <img
                      :src="require('@/assets/images/erflog/Edit.png')"
                      alt="image"
                    />
                  </b-button>

                  <b-button variant="flat-dark" class="ml-2" @click="DeleteEmailTemplate(props.row.id)">
                    <img
                      :src="require('@/assets/images/erflog/Delete.png')"
                      alt="image"
                    />
                  </b-button>
                </span>
                 <span v-else> 
                  <b-button variant="flat-dark" class="ml-2" @click="DeleteEmailTemplate(props.row.id)">
                    <img
                      :src="require('@/assets/images/erflog/Delete.png')"
                      alt="image"
                    />
                  </b-button>
                </span>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> 
                      <span v-if="props.total === 0"> Showing 0 </span>
                      <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                      to
                      <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                      <span v-else>{{props.total}} of {{ props.total }} entries</span>
                    </span>

                        <div class="d-flex align-items-center mb-1 mt-0 showentry">
                          <span>Show</span>
                          <b-form-select
                            v-model="pageLength"
                            :options="['10', '20', '30', '40', '50']"
                            class="mx-1"
                            @input="
                              (value) => props.perPageChanged({ currentPerPage: value })
                            "
                        />
                          <span>entries</span>
                        </div>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :current-page="currentPage"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => {
                          props.pageChanged({ currentPage: value });
                          currentPage = value
                        }
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div> 
              </div>
  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
  BTabs,
  BTab,
  BCard,
  BSpinner,
  BModal,
  VBModal,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from 'vue-ripple-directive';
import { mapGetters, mapActions } from "vuex";
import {dbCollections} from "@/utils/firebaseCollection.js";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Setting/Emailtemplate.vue"
export default {
  directives: {
    Ripple,
  },  
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BCard,
    BButton,
    BTabs,
    BTab,
    BSpinner,
    BModal,
  },
  data() {
    return {
      direction:false,
      isSpinner:false,
      isUpdating:false, 
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      valueOfEmailStatus:[],
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "Created On",
          field: "created",
        },
        {
          label: "User Type",
          field: "usertype",
        },
        {
          label: "Schedule Date & Time",
          field: "scheduledt",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      searchTerm: "",
      selected_status:'All',
      EmailTemplateData:[]
    };
  },
  created() {
    this.getEmailsData()
    this.isSpinner = true
    let getStatusFound = this.getAllStatusList.length != 0;
    if(!getStatusFound){
      this.getAllEmailStatus().then(()=>{
        this.emailStatusFound()
        this.isSpinner = false
      }).catch(error=>{
        console.error("error",error)
        this.isSpinner = false
      })
    }else{
      this.emailStatusFound()
      this.isSpinner = false
    }
  },
  computed: {
    ...mapGetters({
      MailTemplateData:"emailTemplate/getEmailData",
      getAllStatusList:'settings/getAllStatus'
      }),
      emailOptions(){
        try{
          var arr=[]
          arr.push({value:null , text:"All"})
          for(var i=0;i<this.valueOfEmailStatus.length;i++){
            var obj={
                value:i,
                text : this.valueOfEmailStatus[i].status[0].toUpperCase() + this.valueOfEmailStatus[i].status.substring(1)
            }
            arr.push(obj)
          }
          return arr
        }catch(err){
          errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "emailOptions",
            errorMessage : error.message
          },(mailRes) => {
            if(mailRes.error){
              console.error(mailRes);
            }
          })
          console.error(err)
        }
      },
    CountRow(){
      var self = this;
      var array = [];
      try{
        self.MailTemplateData.forEach(mailData => {
          var obj = {
            id:mailData.id,
            name:mailData.fromName,
            created:mailData.createdAt,
            usertype: (typeof(mailData.userType) == "string") ? (mailData.userType == "all" ? 'All User' : 'Tutor'):(mailData.userType.length < 2 ? mailData.userType.join(',') : 'All User'),
            scheduledt:(mailData.sendEmailDateAndTime && mailData.scheduleCampaign === "later") ? self.checkDate(mailData.sendEmailDateAndTime) : '-',
            status:mailData.status,
            action: "action"
          }
          array.push(obj);
        })
        return array.filter((val)=>{
          if(self.selected_status == "All"){
            return val
          }else if(this.valueOfEmailStatus[val.status].status.toLowerCase() === this.selected_status.toLowerCase() && self.selected_status !== "All"){
            return val
          }
        })
        
      }
      catch(error){
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "CountRow",
          errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
        console.error('error',error)
      }
    }
  },
  methods:{
    ...mapActions({
        getEmailsData:'emailTemplate/getMailData',
        getAllEmailStatus:'settings/getAllStatusData'
      }),
    emailStatusFound(){
      try {
        this.valueOfEmailStatus = []
        const value = Object.values(this.getAllStatusList.emailTemplateStatus)
        for (let i = 0; i < value.length; i++) {
          this.valueOfEmailStatus.push(value[i])
        }
      } catch (error) {
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "emailStatusFound",
          errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
        console.error("err",error)
      }
    },
    TemplateCreatedDate(TemplateCreatedDate){
      var self = this;
      var bannerDate = new Date(TemplateCreatedDate.seconds*1000).getDate();
      var bannerMonth = new Date(TemplateCreatedDate.seconds*1000).getMonth()+1;
      var bannerYear = new Date(TemplateCreatedDate.seconds*1000).getFullYear();
      var returnBannerDate = self.addZero(bannerDate)+'-'+self.addZero(bannerMonth)+'-'+bannerYear;
      return returnBannerDate;
    },
    addZero(value){
      if(value > 9){
        return value;
      }else{
        return '0' + value;
      }
    },
    // WHEN EDIT BUTTON CLICK FROM LISTING(Shraddha)
    EditMailTemplate(id){
      var self = this;
      // console.log("type",id)
      self.$router.push({ name: 'edit-email-template',params: { id: id }})
      self.$root.$emit('editEmailTemplate');
    },
    //WHEN DELETE BUTTON CLICK FROM LISTING(Shraddha)
    DeleteEmailTemplate(id){
      var self = this;
      self.$bvModal
      .msgBoxConfirm('Are you sure you want to Delete it?', {
        title: 'Delete',         
        class:'test',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-primary',
        hideHeaderClose: false,
        centered: true,
        footerClass: 'approve-footer',
        headerClass: 'approve-header',
        bodyClass: 'approve-body',
      })
      .then(value => {
        if(value){
          self.isSpinner = true;
          let designObject = {
            status:2,
            updatedAt : new Date(),
          }
          updateFirebase.updateRootCollectionDataByDocId(dbCollections.EMAILMAILTEMPLATE,id,designObject,(res) => {
            self.$nextTick(() => {
              self.$root.$emit('showToastMessage','Email template deleted successfully.','success');
              self.isSpinner = false;
            });
          })
        }
      })
    },
    // FORMAT BANNER CREATE DATE AS PER REQUIREMENT (Shraddha)
    checkDate(bannerCreateDate){
      var self = this;
      var dates = new Date(bannerCreateDate.seconds*1000)
      var bannerDate = new Date(dates).getDate();
      var bannerMonth = new Date(dates).getMonth()+1;
      var bannerYear = new Date(dates).getFullYear();
      var returnBannerDate = self.addZero(bannerDate)+'-'+self.addZero(bannerMonth)+'-'+bannerYear+' , '+ self.formatAMPM(new Date(dates));
      return returnBannerDate;
    },
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    },
    // ADD ZERO IN FRONT SIDE IF VALUE IS LESS THEN 9(Shraddha)
    addZero(value){
      if(value > 9){
        return value;
      }else{
        return '0' + value;
      }
    },
  }
};
</script>
<style scoped>
.vgt-center-align .vgt-text-disabled{
  text-align: center;
}

</style>