<template>
    <b-tab active title="Learner list" @click="onLearnerListClick()">
        <b-spinner
                variant="primary"
                v-if="isSpinnerTutor"
                large
                class="pageLoadSpinners loaderDetail"
        />
            <div class="card user_filter" v-if="!isSpinnerTutor">
              <h2>Filters</h2>

              <b-form @submit.prevent>
                <b-row>
                  <b-col xl="3" lg="4" md="5">
                    <label>User Type</label>
                    <b-dropdown
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      :text="tutor_type_selected"
                      variant="outline-secondary"
                      class="w-100"
                    >
                      <b-dropdown-item
                        v-for="item in tutor_type_options"
                        :key="item.value"
                        @click="tutor_type_selected = item.text"
                        >{{ item.text }}</b-dropdown-item
                      >
                    </b-dropdown>
                  </b-col>
                  <b-col xl="3" lg="4" md="5">
                    <label>Status</label>
                    <b-dropdown
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      :text="status_selectedForTutorList"
                      variant="outline-secondary"
                      class="w-100"
                    >
                      <b-dropdown-item
                        v-for="item in status_optionsForTutorList"
                        :key="item.value"
                        @click="status_selectedForTutorList = item.text"
                        >{{ item.text }}</b-dropdown-item
                      >
                    </b-dropdown>
                  </b-col>
                </b-row>
              </b-form>
            </div>
            <div class="card user_table" v-if="!isSpinnerTutor">
              <div class="custom-search d-flex justify-content-end">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <b-form-input
                      v-model="searchTerm"
                      placeholder="Search..."
                      type="text"
                      class="d-inline-block"
                    />
                  </div>
                </b-form-group>
              </div>
              <vue-good-table
                :columns="tutorscolumns"
                :rows="filterRowsTutorList"
                @on-row-click="onRowClick"
                class="cursor_pointer"
                :rtl="direction"
                :search-options="{
                  enabled: true,
                  externalQuery: searchTerm,
                }"
                :select-options="{
                  enabled: true,
                  selectionInfoClass: 'custom-class',
                  selectionText: 'rows selected',
                  clearSelectionText: 'clear',
                  disableSelectInfo: true, // disable the select info panel on top
                }"
                :pagination-options="{
                  enabled: true,
                  perPage: pageLength,
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <span
                    v-if="props.column.field === 'userid'"
                    class="text-nowrap userid"
                    @click="getDataAgain()"
                  >
                  {{ props.row.userid }}
                  </span>
                  <span
                    v-else-if="props.column.field === 'fullName'"
                    class="text-nowrap"
                  >
                    <b-avatar :src="props.row.avatar" class="user_img" />
                    <p class="text-nowrap tutornamelist">
                      {{ props.row.fullName }}
                    </p>
                  </span>
                  <span
                    v-else-if="props.column.field === 'usertype'"
                    class="tutor_type"
                  >
                      {{ props.row.tutorType }}
                  </span>
                  <span
                    v-else-if="props.column.field === 'email'"
                    class="tutor_type"
                  >
                    <p>{{ props.row.email }}</p>
                  </span>
                  <span
                    v-else-if="props.column.field === 'userbooked'"
                    class="tutor_type"
                  >
                    <p>{{ props.row.noOfsession }}</p>
                  </span>
                  <span
                    v-else-if="props.column.field === 'status'"
                  >
                    <b-badge :variant="getStatusList.connectedUsersStatus[props.row.connectedStatus].variant">
                      {{ props.row.status }}
                    </b-badge>
                  </span>
                  <span
                    v-else-if="props.column.field === 'action'"
                    class="action_field action_field1514"
                  >
                    <span>
                      <b-dropdown
                        variant="link"
                        :disabled="userStatus == 4"
                        toggle-class="text-decoration-none"
                        no-caret
                      >
                        <template v-slot:button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="text-body align-middle mr-25"
                          />
                        </template>
                        <b-dropdown-item
                          v-on:click="booksession(props.row.fullName,props.row.tutorId)"
                        >
                          <span>
                            <svg
                              width="18"
                              height="19"
                              viewBox="0 0 18 19"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M9 17.0402C13.4183 17.0402 17 13.4495 17 9.0201C17 4.59072 13.4183 1 9 1C4.58172 1 1 4.59072 1 9.0201C1 13.4495 4.58172 17.0402 9 17.0402Z"
                                stroke="#606060"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9 4.20801V9.02007L12.2 10.6241"
                                stroke="#606060"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Book Session</span
                          >
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> 
                          <span v-if="props.total === 0"> Showing 0 </span>
                          <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                          to
                          <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                          <span v-else>{{props.total}} of {{ props.total }} entries</span>
                        </span>

                            <div class="d-flex align-items-center mb-1 mt-0 showentry">
                              <span>Show</span>
                              <b-form-select
                                v-model="pageLength"
                                :options="['10', '20', '30', '40', '50']"
                                class="mx-1"
                                @input="
                                  (value) => props.perPageChanged({ currentPerPage: value })
                                "
                            />
                              <span>entries</span>
                            </div>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :current-page="currentPage"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          @input="
                            (value) => {
                              props.pageChanged({ currentPage: value });
                              currentPage = value
                            }
                          "
                        >
                          <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                          </template>
                          <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                          </template>
                        </b-pagination>
                      </div>
                    </div>
                </template>
              </vue-good-table>
            </div>
    </b-tab>
</template>
<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";

import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import { dbCollections } from "@/utils/firebaseCollection.js";
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import {errorEmail} from "@/utils/apiRequests.js";
const currentFileName = "src/views/Users/User/UserDetailTabs/UserDetail_TutorList.vue";
export default {
    name: "LearnerChildListLearner",
    directives: {
        Ripple,
    },
    props : {
    userName : String,
    getDataAgain : Function,
    userStatus: Number,
    },
    components: {
        VueGoodTable,
        BCard,
        BImg,
        BRow,
        BCol,
        BDropdown,
        BDropdownItem,
        BCardText,
        BTabs,
        BTab,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BForm,
        BButton,
        flatPickr,
        BSpinner,
    },
    data() {
       return {
      tutorslist: [],
      searchTerm: "",
      tabIndex: 0,
      direction: false,
      perPage: 10,
      currentPage: 1,
      tutor_type_selected: "All",
      tutor_type_options: [],
      status_optionsForTutorList: [],
      status_selectedForTutorList: "All",
      pageLength: 10,
      isSpinnerTutor : false,
      tutorscolumns: [
        {
          label: "User ID",
          field: "userid",
        },
        {
          label: "Learner Name",
          field: "fullName",
        },
        {
          label: "User Type",
          field: "usertype",
        },
        {
          label: "User Email",
          field: "email",
        },
        {
          label: "No. of Session Booked",
          field: "userbooked",
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
    };
    },
    created() {
        this.isSpinnerTutor = true;
        this.tutorList()
    },
    computed: {
        ...mapGetters({
              getStatusList:'status/getStatus',
        }),
        filterRowsTutorList(){ // Karan
              var listA = this.tutorslist;
              if(this.tutor_type_selected == "All" && this.status_selectedForTutorList == "All"){
                  return listA
              }
              if(this.tutor_type_selected != "All"){
                    listA = listA.filter((data)=>{
                        return data.tutorType.toLowerCase().includes(this.tutor_type_selected.toLowerCase())
                    })
              }
              if(this.status_selectedForTutorList != "All"){
                    listA = listA.filter((data)=>{
                        return data.status.toLowerCase().includes(this.status_selectedForTutorList.toLowerCase())
                    })
              }
              return listA
        },
    },
    methods: {
        onRowClick(data){
            this.$router.push({name: 'user-details', params: { id: data.row.docId }})
            this.getDataAgain()
        },
        tutorList(){
          try{
          this.isSpinnerTutor = true;
          this.tutorslist = [];
          
          getFirebase.simpleGetDataWithSpecificWhereCondition(dbCollections.CONNECTEDUSERS,'tutor','==',this.$route.params.id,(res) => {
              if(res.length !== 0){
                  let count = 0;
                  const myLoop = (data) =>{
                    if(count === res.length ){
                        this.status_optionsss();
                        this.tutor_type_optionsss();
                        this.isSpinnerTutor = false;
                        return;
                    }
                    this.checkCountSessionBooking(data).then((bookingres) => {
                      let statusObj = this.getStatusList.connectedUsersStatus;
                      var statusDisplay = data.status !== undefined ? statusObj[data.status] : "-";
                      let tutorList = {
                          userid: data.learnerDetails ? data.learnerDetails.userId : "",
                          fullName: data.learnerDetails ?
                            data.learnerDetails.firstName + " " + data.learnerDetails.lastName : "",
                          tutorType: (data.learnerDetails && data.learnerDetails.userRole)  ? data.learnerDetails.userRole.join(",") : "", // user type
                          email: data.learnerDetails ? data.learnerDetails.email : "",
                          status: statusDisplay.status,
                          noOfsession: bookingres ? bookingres.sessionBooking : 0,
                          docId: data.learner,
                          connectedStatus:data.status,
                          tutorId:data.tutor,
                          avatar:  data.learnerDetails && data.learnerDetails.profilePicture
                            ? data.learnerDetails.profilePicture
                            : "",
                      };
                      this.tutorslist.push(tutorList);           
                      count++;
                      myLoop(res[count]);
                    })     
                  }
                  myLoop(res[count]);
              }else {
                  this.isSpinnerTutor = false;
              }
          })
          }catch (error) {
              this.isSpinnerTutor = false
              errorEmail({
                  projectName : process.env.VUE_APP_PROJECTNAME,
                  environment : process.env.NODE_ENV,
                  fileName : currentFileName,
                  hookName : "tutorList",
                  errorMessage : error.message
                },(res)=>{
                  if(res.error){
                      console.error(res.error)
                  }
                })
              console.error(error)
          }
        },
        tutor_type_optionsss() {
              var typeArr = [];
              try{
                typeArr.push({ value: 0, text: "All" });
                if (this.tutorslist.length) {
                  for (var i = 0; i < this.tutorslist.length; i++) {
                    if (
                      this.tutorslist[i] && 
                      (this.tutorslist[i].tutorType !== "" &&
                      this.tutorslist[i].tutorType !== undefined )
                    ) {
                      var temp = false;
                      for (var j = 0; j < typeArr.length; j++) {
                        if (this.tutorslist[i].tutorType === typeArr[j].text) {
                          temp = true;
                        }
                      }
                      if (!temp) {
                        var obj = {
                          value: i + 1,
                          text: this.tutorslist[i].tutorType,
                        };
                        typeArr.push(obj);
                      }
                    }
                  }
                } else {
                  this.isSpinnerTutor = false;
                }
                this.tutor_type_options = typeArr;
              }catch (error) {
                this.isSpinnerTutor = false;
                console.error(error)  
                errorEmail({
                      projectName : process.env.VUE_APP_PROJECTNAME,
                      environment : process.env.NODE_ENV,
                      fileName : currentFileName,
                      hookName : "tutor_type_optionsss",
                      errorMessage : error.message
                    },(res)=>{
                       if(res.error){
                          console.error(res.error)
                      }
                    })
              }
        },
        status_optionsss() {
              var typeArr = [];
              try{
                typeArr.push({ value: 0, text: "All" });
                for (var i = 0; i < this.tutorslist.length; i++) {
                  if (
                    this.tutorslist[i] && (this.tutorslist[i].status !== "" ||
                    this.tutorList[i].status !== undefined)
                  ) {
                    var temp = false;
                    typeArr.forEach((ele) => {
                      if (this.tutorslist[i].status === ele.text) {
                        temp = true;
                      }
                    });
                    if (!temp) {
                      var obj = {
                        value: i + 1,
                        text: this.tutorslist[i].status,
                      };
                      typeArr.push(obj);
                    }
                  }
                }
                this.status_optionsForTutorList = typeArr;
              }catch (error) {
                  errorEmail({
                      projectName : process.env.VUE_APP_PROJECTNAME,
                      environment : process.env.NODE_ENV,
                      fileName : currentFileName,
                      hookName : "status_optionsss",
                      errorMessage : error.message
                    },(res)=>{
                      if(res.error){
                          console.error(res.error)
                      }
                    })
                  console.error(error)
              }
        },
        getStatusBadgeVariant(value) {
              var variant = "";
              if (value == "Valuable") {
                variant += "light-primary";
              } else if (value == "Elite") {
                variant += "light-danger";
              } else if (value == "Normal") {
                variant += "light-success";
              } else if (value == "Expert") {
                variant += "light-warning";
              }
              return variant;
        },
        booksession(learnerName,docId) {
        var self = this;
        let names = {
          learnerName: learnerName,
          tutor: docId,
        };
        localStorage.removeItem("bookSession");
        localStorage.setItem("bookSession", JSON.stringify(names));
        self.$router.push({
          name: `booking-session-all-session-new`,
          query: { userDetail: names },
        });
    },
        checkCountSessionBooking(data){
          var self = this;
          var sessionCount = 0;
          return new Promise((resolve,reject) => {
            try{
              getFirebase.simpleGetDataWithSpecificThreeWhereCondition(
              dbCollections.BOOKING,
              "learnerId","==",data.learner,
              "tutor","==",self.$route.params.id,
              "status","==",4,
              (ress) => {
                if(ress.length > 0){
                  resolve({sessionBooking:ress.length});
                }else{
                  resolve({sessionBooking:sessionCount});
                }
              })
            }catch(error){
              console.error(error);
              reject('error found')
               errorEmail({
                  projectName : process.env.VUE_APP_PROJECTNAME,
                  environment : process.env.NODE_ENV,
                  fileName : currentFileName,
                  hookName : "checkCountSessionBooking",
                  errorMessage : error.message
                },(res)=>{
                  if(res.error){
                      console.error(res.error)
                  }
                })
            }
          })
        },
        limitTo: function (value) {
            if (!value) return "";
            return value.substring(0, 90) + "...";
        },
        onLearnerListClick(){
          var self = this;
          var allTabList = document.getElementsByClassName('nav-link active')
          if (allTabList[0].innerText != 'Learner list') { 
                self.tutorList();
          }
        }
    }
}
</script>