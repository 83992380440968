<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
     style="position: fixed; z-index: 999999 !important;"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
     
      
      <div class="card user_table cancel_session_table upcoming_session_table transaction_withdraw_table ">
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1"></label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search..."
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <vue-good-table
          :columns="dynamicColumns"
          :rows="rows"
          :rtl="direction"
          class="cursor_pointer"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'date'" class="text-nowrap">                         
            {{ props.row.date }}
            </span>
            <span
              v-else-if="props.column.field === 'requestid'"
              class="reuest_id"
            >
              {{ props.formattedRow[props.column.field] }}
            </span>
<span v-else-if="props.column.field === 'userName'" class="text-nowrap">
              <b-avatar :src="props.row.avatar" class="user_img" />                          
              <router-link :to="{name: 'user-details',params: { id: props.row.userID}}" class="text-nowrap">{{ props.row.userName }}</router-link>
            </span>
    <span v-else-if="props.column.field === 'withdrawAmount'" class="withdraw_amount">
              {{ props.formattedRow[props.column.field] }}
            </span>
    <span v-else-if="props.column.field === 'availableAmount'" class="available_amount">
              {{ props.formattedRow[props.column.field] }}
            </span>
                  <span
                    v-else-if="props.column.field === 'status'"
                    class="status"
                  > 
                    <b-badge :variant="getStatusList.withdrawRequest[props.row.status].variant">
                      {{getStatusList.withdrawRequest[props.row.status].status}}
                    </b-badge>
                    <span>
                    </span>
                  </span>
              <span
                v-else-if="props.column.field === 'action'"
                class="action"
              >
                  <b-button v-if="props.row.status === 0" variant="outline-primary" class="btn"  @click="payWithdraw(props.row)">Pay</b-button>
                  <b-button v-if="props.row.status === 0" variant="outline-danger" class="btn" @click="rejectRequest(props.row)">Reject</b-button>
              </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> 
                        <span v-if="props.total === 0"> Showing 0 </span>
                        <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                        to
                        <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                        <span v-else>{{props.total}} of {{ props.total }} entries</span>
                      </span>

                          <div class="d-flex align-items-center mb-1 mt-0 showentry withdrawRequest">
                            <span>Show</span>
                            <b-form-select
                              v-model="pageLength"
                              :options="['10', '20', '30', '40', '50']"
                              class="mx-1"
                              @input="
                                (value) => props.perPageChanged({ currentPerPage: value })
                              "
                          />
                            <span>entries</span>
                          </div>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :current-page="currentPage"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="
                          (value) => {
                            props.pageChanged({ currentPage: value });
                            currentPage = value
                          }
                        "
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                      </b-pagination>
                    </div>
                  </div>
          </template>
        </vue-good-table>
      </div>
    <b-modal
      id="modal-pending"
      title="Select Data"
      centered
      ok-title="Save"
      @ok="preventClose"
      @hidden="resetPaymentModel"
      cancel-title="cancel"
      cancel-variant="outline-secondary"
      :ok-disabled="isSpinner"
      :cancel-disabled="isSpinner"
    >
      <validation-observer ref="paymentModel">
      <b-form ref="paymentModel" @submit.stop.prevent="preventClose">
        <b-form-group
          label="Transaction ID"
          label-for="Enter Transaction Id"
        >
        <validation-provider
         #default="{ errors }"
          name="transection id"
         rules="required|min:3|max:25"
        >
          <b-form-input
            id="transaction_id"
            placeholder="Enter Transaction Id"
            v-model="transectionId"
          />
          <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
        </validation-provider>  
        </b-form-group>
        
        <b-form-group
          label="Payment Mode"
          label-for="vue-select"
        >
         <validation-provider
         #default="{ errors }"
          name="payment mode"
         rules="required"
        >
          <v-select
            id="vue-select"
            v-model="selected"
            label="text"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="option"
            
          />
         <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
         </validation-provider>  
        </b-form-group>
      </b-form>
      </validation-observer>
    </b-modal>
     <b-modal
          id="modal-reject-withdrawRequest"
          ref="my-modal"
          title="Rejection Reason"
          ok-title="Reject"
          data-class="addcredit_popup"
          data-value-class="addcredit_popup"
          centered
          cancel-variant="outline-secondary"
          @show="resetModal"
          @hidden="resetModal"
          @ok="rejectWithdrawRequest"
          :ok-disabled="isSpinner"
          :cancel-disabled="isSpinner"
        >
        <validation-observer ref="formReject">
          <b-form ref="form" @submit.stop.prevent="rejectWithdrawRequest">
            <validation-provider
              #default="{ errors }"
              name="reason for rejection"
              rules="required|min:3|max:300"
            >
              <b-form-group
                label-for="name-input"
                invalid-feedback="Reason for Rejection is required"
              >
              <label style="font-weight:600;">Reason for Rejection</label>
                <b-form-textarea
                  id="name-input"
                  v-model="reason"
                  placeholder="Enter Reason For Rejection"
                  required
                />
                <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
  BSpinner,
  BFormTextarea,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from 'vue-select'
import store from "@/store/index";
import Ripple from 'vue-ripple-directive'
import { mapGetters} from "vuex";
import * as getQuery from "@/utils/FirebaseQueries/getQueries/getQueries.js";
import { dbCollections } from "@/utils/firebaseCollection.js";
import moment from "moment"
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import * as insertFirebase from "@/utils/FirebaseQueries/insertQueries/insertQueries.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/TransactionHistory/withdrawRequest.vue";
import firebase from "firebase"
const db = firebase.firestore()
import axios from "axios";
import {sendNotificationOnWithdrawRequestPayment} from './serviceWithdrawRequest.js'
export default {
  directives: {
    Ripple,
  },
  components: {
    VueGoodTable,
    ValidationProvider, 
    ValidationObserver,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BButton,
    BSpinner,
    vSelect,
    BFormTextarea
  },
  data() {
    return {
      direction:false,
      isSpinner:false,
      isUpdating:false,
      paymentObj: {},
      reason:"",
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      currentPage: 1,
       selected: { value: "cash", text: "Cash" },
       option: 
       [
        { value: "cash", text: "Cash" },
        { value: "online", text: "Online" },
       ]
       ,
       transectionId: "",
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Date",
          field: "date",
        },
        {
          label: "Request ID",
          field: "requestid",
        },
        {
          label: "User Name",
          field: "userName",
        },
        {
          label: "Withdraw Amount($)",
          field: "withdrawAmount",
        },
        {
          label: "Available Amount($)",
          field: "availableAmount",
        },
        
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Action",
          field: "action",
        },
        
      ],
      rows: [],
      searchTerm: "",
      rejectId: "",
      rejectionId:"",
      userid:"",
      amount:null
    };
  },
  computed: {
    ...mapGetters({
            getsettingList : 'settings/getsettingArrayFeild',
            getStatusList: "status/getStatus",
        }),
    currency(){
        if (Object.keys(this.getsettingList).length != 0) {

            return this.getsettingList.currency.slice(4)
        } else {
            return ""
        }
    },
    
    dynamicColumns(){
        this.columns[3].label = `Withdraw Amount(${this.currency})`
        this.columns[4].label = `Available Amount(${this.currency})`	
        return this.columns
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData(){
      try {
        this.isSpinner = true;
        db.collection(dbCollections.WITHDRAW_REQUEST).orderBy("createdAt","desc").get().then((request)=>{
        if (!request.empty) {
          request.forEach((data)=>{
            data = data.data()
            getQuery.simpleGetDataFromSubCollectionWithDocIDV2(dbCollections.USERS,data.userId,(user)=>{
              if (user) {
                let obj = {
                  date: data.createdAt ?  moment(new Date( data.createdAt.seconds * 1000)).format('DD-MM-YYYY, hh:mm A'): "",
                  requestid: data.requestId,
                  userName:  user.data.firstName + " " + user.data.lastName,
                  firstName: user.data.firstName,
                  lastName: user.data.lastName,
                  withdrawAmount: data.withdrawAmount,
                  availableAmount: user.data.currentAmount,
                  status: data.status,
                  userID: data.userId,
                  avatar: user.data.profilePicture,
                  id: data.id,
                  tutorTransactionsId: data.tutorTransactionsId ? data.tutorTransactionsId : ""
                }
                this.rows.push(obj);
                if (request.docs.length === this.rows.length) {
                  this.isSpinner = false;
                }
              }
            })

          })
        } else {
          this.isSpinner = false
        }
      }).catch(error => {
        console.error(error)
        this.isSpinner = false;
      })
      } catch (error) {
        this.isSpinner = false;
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "getData",
          errorMessage : error.message
          },(res)=>{
          if(res.data){
          }else if(res.error){
              console.error(res.error)
          }
          })
          console.error(error)
      }
      
    },
    preventClose(bvModalEvt){
      bvModalEvt.preventDefault();
      this.payment();
    },
    resetModal(){
        this.reason = ""
        this.isSpinner = false;
    },
    rejectWithdrawRequest(bvModalEvt){
        this.isSpinner = true;
        bvModalEvt.preventDefault();
        this.$refs.formReject.validate().then((valid)=>{
          if (valid) {
            let tutorTransectionObj = {
              createdAt: new Date(),
              updatedAt: new Date(),
              amount: parseInt(this.paymentObj.withdrawAmount),
              availableAmount: this.paymentObj.availableAmount + this.paymentObj.withdrawAmount,
              reference: "withdrawAmount",
              remarks: `Paid to tutor.`,
              transactionType: 'cr',
              userDetail: {
                firstName: this.paymentObj.firstName,
                lastName: this.paymentObj.lastName,
                profileImage: this.paymentObj.avatar
              },
              userId: this.paymentObj.userID,
              isSendNotification: false,
            }
            let newId = db.collection(dbCollections.TUTORTRANSACTIONS).doc()
            tutorTransectionObj.id = newId.id
            newId.set(tutorTransectionObj).then((res)=>{
              let userUpdate = {
                currentAmount : this.paymentObj.availableAmount + this.paymentObj.withdrawAmount,
                updatedAt: new Date(),
              }
              db.collection(dbCollections.USERS).doc(this.paymentObj.userID).update(userUpdate).then((response)=>{
                let update = {
                     status: 1,
                     rejectReason: this.reason,
                     updatedAt: new Date(),
                }
                updateFirebase.updateRootCollectionDataByDocId(dbCollections.WITHDRAW_REQUEST,this.rejectId,update,(update)=>{
                      sendNotificationOnWithdrawRequestPayment({
                          uid: this.userId,
                          id: this.rejectId,
                          requestId: this.rejectionId,
                          paymentStatus: "Reject",
                          rejectReason: this.reason,
                          amount: this.amount,
                      },() => {})
                      this.$root.$emit('showToastMessage', 'Reject successfully.','success')
                      this.reason = ""
                      this.$bvModal.hide("modal-reject-withdrawRequest")      
                     let ind = this.rows.findIndex((data)=>{
                       return data.id == this.rejectId
                     })
                     this.rows[ind].status = 1
                     this.paymentObj = {}
                     this.isSpinner = false;
                })  
              }).catch((error)=>{
                console.error(error,"error");
              })
            }).catch((error)=>{
              console.error(error)
            })
          } else {
            this.isSpinner = false;
          }
        })
    },
    rejectRequest(id){
      this.paymentObj = id;
      try {
        this.rejectId =  this.paymentObj.id
        this.rejectionId = this.paymentObj.requestid
        this.userId = this.paymentObj.userID
        this.amount = this.paymentObj.withdrawAmount
        this.$bvModal.show('modal-reject-withdrawRequest')
      } catch (error) {
        this.isSpinner = false;
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "rejectRequest",
          errorMessage : error.message
          },(res)=>{
          if(res.data){
          }else if(res.error){
              console.error(res.error)
          }
          })
          console.error(error)
      }
         
      
    },
    payWithdraw(id){
      this.paymentObj = id
      this.$bvModal.show('modal-pending')

    },
    demoFunction(){
      
    },
    payment(){
      try {
        this.isSpinner = true
        this.$refs.paymentModel.validate().then((value) => {
          if (value) {
            let amountTransectionObj = {
              amount: parseInt(this.paymentObj.withdrawAmount),
              createdAt: new Date(),
              updatedAt: new Date(),
              paymentMethod: "Manual",
              remarks: `Amount withdrawn.`,
              status: 2,
              transactionType: "cr",
              tutorTransactions: this.paymentObj.tutorTransactionsId,
              userId:  this.paymentObj.userID,
              currentAmount: this.paymentObj.availableAmount,
              transactionId: this.transectionId,
              paymentMode: this.selected.value,
              isSendNotification: false
            }
            let newId = db.collection(dbCollections.AMOUNTTRANSECTION).doc()
            amountTransectionObj.id = newId.id
            newId.set(amountTransectionObj).then((res) => {
              let updateWithdrawReq = {
              status: 2,
              updatedAt: new Date(),
              }
                db.collection(dbCollections.WITHDRAW_REQUEST).doc(this.paymentObj.id).update(updateWithdrawReq).then(()=>{
                  let tutorTransectionObj = {
                    remarks: `Amount withdrawn. (Ref. ID: ${this.transectionId}, Payment Mode: ${this.selected.value})`,
                    updatedAt: new Date(),
                  }
                  db.collection(dbCollections.TUTORTRANSACTIONS).doc(this.paymentObj.tutorTransactionsId).update(tutorTransectionObj).then((resp)=>{
                    let ind = this.rows.findIndex((data)=>{
                      return data.id == this.paymentObj.id
                    })
                    this.rows[ind].status = 2
                    this.transectionId = ""
                     sendNotificationOnWithdrawRequestPayment({
                        uid: this.paymentObj.userID,
                        id: this.paymentObj.id,
                        requestId: this.paymentObj.requestid,
                        paymentStatus: "Paid",
                        rejectReason: this.reason,
                        amount: this.paymentObj.withdrawAmount,
                    },() => {})
                    this.paymentObj = {}
                    this.$root.$emit('showToastMessage', 'Paid successfully.','success')
                     this.$bvModal.hide('modal-pending')
                    this.isSpinner = false;
                  }).catch((error)=>{
                    console.error(error, "error")
                    this.$bvModal.hide('modal-pending')
                    this.isSpinner = false;
                  })
                  })
                })
          } else {
            this.isSpinner = false
          }
        })
      } catch (error) {
        this.isSpinner = false;
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "payment",
          errorMessage : error.message
          },(res)=>{
          if(res.data){
          }else if(res.error){
              console.error(res.error)
          }
          })
      }
    },
    resetPaymentModel(){
    }
  },
};

</script>

