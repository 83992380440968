<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
      <div class="general_setting_section">
        <b-card no-body>
            <b-form>
              <div class="type_title">
                <b-row>
                  <b-col md="6">
                    <h2>Currency Settings</h2>
                  </b-col>
                  <b-col md="6" class="text-right">
                    <b-button
                      variant="outline-primary"
                      v-on:click="cancelCurrency"
                    >
                      Cancel
                    </b-button>
                    <b-button
                      variant="primary"
                      v-on:click="saveCurrency"
                    >
                      Save
                    </b-button>
                  </b-col>
                </b-row>
              </div>
              <b-row>
                <b-col md="3">
                    <div class="form-group-padding">
                    <b-form-group>
                    <label label-for='currency'>Currency</label>
                      <b-input-group-append class="drop_down_menu">
                                <b-form-select v-model="currency_selected"   id="currency"  >
                                  <b-form-select-option v-for="(option,index) in currency_options" v-bind:key="index" :value="option.value" >{{option.text}} </b-form-select-option>
                                </b-form-select>
                      </b-input-group-append>
                    </b-form-group>
                    </div>
                </b-col>
              </b-row>
            
            </b-form>
        </b-card>


         <b-card no-body>
          <b-form>
            <div class="type_title">
              <b-row>
                <b-col md="6">
                  <h2>Tutor Type</h2>
                </b-col>
                <b-col md="6" class="text-right">
                  <b-button
                    variant="outline-primary"
                    v-on:click="cancelTutor"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    variant="primary"
                    v-on:click="saveTutor"
                    :disabled="saveClick"
                  >
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </div>
            
            <b-row class="pt-2">
              <b-col md="3">
                <div style="padding:0rem 1.5rem 1.5rem 1.5rem" class="turot_types">
              <b-form @submit.prevent="addTutor" style="margin-bottom:0.8rem">
                <b-form-group>
                <label label-for='tutortype'>Tutor Type<span class="required">*</span></label>
                <b-form-input id="tutortype" type="text" v-model.trim="tutorTypeValue" placeholder="Enter Tutor Type" :maxlength="20" class="tutuotype"/>
                </b-form-group>
              </b-form> 
              <b-badge class="tutortypeoption coustem_badge" style="cursor:default;margin-right:10px;" variant="secondary" v-for="(data,index) in tutorTypes" :key="index" v-show="data!==''">
                {{data}} <span style="margin-left:10px;cursor:pointer" @click="removePopUp(index)">x</span>
              </b-badge>
            </div> 
      
              <span v-if="maxUser" style="color:red;margin-left:20px">{{"max 15 tutor type allow"}}</span>

              </b-col>
               <b-col md="3">
                <validation-observer ref="bookingsetting">
                  <validation-provider
                    #default="{ errors }"
                    name="1 credit value"
                    rules="required"
                  >
                  <b-form-group>
                     <label label-for='credit-value'>1 Credit Value<span class="required">*</span></label>
                    <b-input-group>
                      <b-input-group :append="currency_display">
                          <b-form-input
                        id="credit-value"
                        type="text"
                        placeholder="Enter 1 Credit Value"
                        v-on:keyup="BookingValueCalculate()"
                        v-model="creditvalue"
                        @keypress="isNumber"
                        :maxlength = "5"
                        @copy.prevent 
                        @paste.prevent
                      />
                    </b-input-group>
                    </b-input-group>
                  </b-form-group>
                     <small v-if="checkInput(creditvalue)" style="color:red" class="errorDisplay">It should be greater than zero</small> 
                    <small class="text-danger errorDisplay" style="display:block;" >{{ errors[0] }}</small>
                  </validation-provider>
                </validation-observer>
            </b-col>
            </b-row>
              
            
           
          </b-form>
          <br/>
          <validation-observer ref="bookingsetting">
        
                <b-col md="9"></b-col>
                <hr/>
                <div class="form-group-padding">
                  <b-form
                    ref="form"
                    @submit.prevent="repeateAgain"
                  >
                    <b-row
                      v-for="(item, index) in items"
                      :id="item.id"
                      :key="item.id"
                      ref="row"
                    >
                    
                      <b-col md="3">
                        <validation-provider
                        #default="{ errors }"
                        name="tutor type"
                        rules="required"
                        >
                        <b-form-group>
                     <label label-for='tutortype'>Tutor Type</label>
                            <b-form-input
                            id="bookingvalue"
                            type="text"
                            placeholder="Enter Tutor Type"
                            v-model="item.tutorType"
                            disabled

                          />

                          
                        </b-form-group>
                        <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                       </validation-provider>
                      </b-col>
                      <b-col md="3">
                        <validation-provider
                        #default="{ errors }"
                        name="no. of credits required per 30min"
                        rules="required"
                        >
                        <b-form-group>
                     <label label-for='credit-value'>No. of Credits Required per 30min<span class="required">*</span></label>
                          <b-form-input
                            type="text"
                            @keypress="isNumber"
                            v-model="item.creditValueRequired30Min"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            v-on:keyup="BookingValueCalculate() , showErr[index] ? showErr[index].creditValueRequired30min = false : '', TutorLineEarningCalculate()"
                            :maxlength = "5"
                            placeholder="Enter No. Of Credits Required Per 30min"
                            @copy.prevent 
                            @paste.prevent
        
                          />
                        </b-form-group>
                        <small v-if="checkInput(item.creditValueRequired30Min) || (showErr[index] && showErr[index].creditValueRequired30Min)" style="color:red" class="errorDisplay">It should be greater than zero</small> 
                         <small v-else class="text-danger errorDisplay" style="display:block;">{{ errors[0] }}</small>
                       </validation-provider>
                      </b-col>
                      <b-col md="3">
                        <b-form-group class="bookingvalue">
                          <label label-for="bookingvalue">
                      
                            <span> Booking Value</span>
                            <b-button
                              v-b-tooltip.v-primary
                              title="Booking Value = Credit Value X Credit Required"
                              variant="outline-primary"
                               v-b-tooltip.html
                            >
                              <feather-icon icon="AlertCircleIcon" size="24" />
                            </b-button>
                          </label>
                          <b-input-group :append="currency_display" class="currencybackground">
                          <b-form-input
                            id="bookingvalue"
                            type="text"
                            placeholder="225.00 HKD($)"
                            v-model="item.bookingValue"
                            disabled

                          />
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                         <validation-provider
                        #default="{ errors }"
                        name="paid to tutor"
                        rules="required"
                        >
                        <b-form-group >
                     <label label-for='paidtutor'>Paid to Tutor<span class="required">*</span></label>
                          <b-input-group :append="currency_display">
                          <b-form-input
                              id="paidtutor"
                              type="text"
                              @keypress="isNumber"
                              placeholder="Enter Paid to Tutor"
                              v-on:keyup="TutorLineEarningCalculate() , showErr[index] ? showErr[index].paidToTutor = false: ''"
                              v-model="item.paidToTutor"
                              :maxlength="8"
                              @copy.prevent 
                              @paste.prevent
                            />
                          </b-input-group>
                        </b-form-group>
                        <small style="color:red" class="errorDisplay">{{showTutorlineEarningErr[index]}}</small> 
                        <small v-if="checkInput(item.paidToTutor) || (showErr[index] && showErr[index].paidToTutor)" style="color:red" class="errorDisplay">It should be greater than zero</small> 
                        <small v-else class="text-danger" style="display:block;" >{{ errors[0] }}</small>
                       </validation-provider>
                      </b-col>
                      <b-col md="3">
                        <b-form-group >
                     <label label-for='tutorline'>Tutorline Earning<span class="required">*</span></label>
                       <b-input-group :append="currency_display" class="currencybackground">
                          <b-form-input
                            id="tutorline"
                            type="number"
                            placeholder="100.00 HKD($)"
                            disabled
                            v-model="item.tutorlineEarning"
                          />
                       </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col md="3">
                        <validation-provider
                         #default="{ errors }"
                          name="background colour"
                          rules="required"
                          type="color"
                        >
                          <b-form-group label-for="backgroundColor">
                              <label for="backgroundColor">Background Colour<span class="redcolorstar"></span></label><br>
                              <input type="color" id="backgroundColor" name="backgroundColor" v-model="item.backgroundColor">
                          </b-form-group>
                          <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                       </validation-provider>
                      </b-col>
                      <b-col md="3">
                        <validation-provider
                          #default="{ errors }"
                          name="text colour"
                          rules="required"
                        > 
                          <b-form-group label-for="textColor">
                              <label for="textColor">Text/Border Colour<span class="redcolorstar"></span></label><br>
                              <input type="color" id="textColor" name="textColor" v-model="item.textColor">
                          </b-form-group>
                          <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-col>
                      <b-col md="3">
                         <b-badge
                          style="margin-top: 23px;"
                          :style="`background-color: ${item.backgroundColor}; color: ${item.textColor};border-color: ${item.textColor};border-width: 1px;border-style: solid;`"
                         >
                          {{item.tutorType}}
                         </b-badge>
                      </b-col>
                      <b-col md="9" class="text-right">
                        
                      </b-col>
                      <b-col cols="12">
                        <hr />
                      </b-col>
                    </b-row>
                  </b-form>
                </div>
            </validation-observer>
        </b-card>
        <b-card no-body>
          <b-form>
            <div class="type_title">
              <b-row>
                <b-col md="6">
                  <h2>User Suspend Setting</h2>
                </b-col>
                <b-col md="6" class="text-right">
                  <b-button
                    variant="outline-primary"
                    v-on:click="cancelSuspend"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    variant="primary"
                    v-on:click="saveSuspend"
                  >
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div class="form-group-padding">
              <validation-observer ref="suspendForm">
              <b-row>
                <b-col md="3">
                   <validation-provider
                    #default="{ errors }"
                    name="max. warning(s) to get Suspend"
                    rules="required"
                  >
                  <b-form-group>
                    <label label-for='maxwarning'>Max. Warning(s) to get Suspend<span class="required">*</span></label>
                    <b-form-input
                      v-model="maxwarning"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      placeholder="Enter Max. Warning(s) to get Suspend"
                      maxlength = "3"
                      @copy.prevent 
                      @paste.prevent
                    />
                     <small v-if="checkInput(maxwarning)" style="color:red">It should be greater than zero</small> 
                     <small  class="text-danger" style="display:block;">{{ errors[0] }}</small>
                  </b-form-group>
                   </validation-provider>
                </b-col>
                <b-col md="3">
                
                   <validation-provider
                    #default="{ errors }"
                    name="max. period of suspension(days)"
                    rules="required"
                  >
                  <b-form-group>
                  <label label-for='maxperiod'>Max. Period of Suspension(Days)<span class="required">*</span></label>
                    <b-form-input
                      v-model="maxperiod"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      placeholder="Enter Max. Period of Suspension(Days)"
                      maxlength="3"
                      @copy.prevent 
                      @paste.prevent
                    />
                    <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                     <small v-if="checkInput(maxperiod)" style="color:red">It should be greater than zero</small> 
                  </b-form-group>
                   </validation-provider>
                </b-col>
                <b-col md="3">
              
                  <validation-provider
                  #default="{ errors }"
                  name="warning reset(days)"
                  rules="required"
                >
                <b-form-group>
                <label label-for='warningreset'>Warning Reset(Days)<span class="required">*</span></label>
                  <b-form-input
                    v-model="warningReset"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    placeholder="Enter Warning Reset(Days)"
                    maxlength="3"
                    @copy.prevent 
                    @paste.prevent
                  />
                  <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                    <small v-if="checkInput(warningReset)" style="color:red">It should be greater than zero</small> 
                </b-form-group>
                  </validation-provider>
              </b-col>
              </b-row>
              </validation-observer>
            </div>
          </b-form>
        </b-card>

        <b-card no-body>
          <b-form>
            <div class="type_title">
              <b-row>
                <b-col md="6">
                  <h2>Booking Time Setting</h2>
                </b-col>
                <b-col md="6" class="text-right">
                  <b-button
                    variant="outline-primary"
                    v-on:click="cancelBookingTime"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    variant="primary"
                    v-on:click="saveBookingTime"
                  >
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div class="form-group-padding">
              <validation-observer ref="booktimesetting">
              <b-row>
                <b-col md="3">
                   <validation-provider
                    #default="{ errors }"
                    name="min. booking time(min)"
                    rules="required"
                  >
                  <b-form-group>
                  <label label-for='minbooking'>Min. Booking Time(Min)<span class="required">*</span></label>
                    <!-- <b-form-input
                      v-model="minbooking"
                      maxlength="3"
                      @change="Only30Input"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      placeholder="Enter Min. Booking Time(Min)"
                      @copy.prevent 
                      @paste.prevent
                    />
                    <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                     <small v-if="checkInput(minbooking)" style="color:red">It should be greater than zero</small>  -->
                    <b-input-group-append class="drop_down_menu bookingTime">
                            <b-form-select v-model="minBookingSelected" @change="changeMaxOption"  id="currency"  >
                              <b-form-select-option v-for="(option,index) in minBooking_options" v-bind:key="index" :value="option.value" >{{option.text}} </b-form-select-option>
                            </b-form-select>
                            <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                  </b-input-group-append>
                  </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="3">
                  <validation-provider
                    #default="{ errors }"
                    name="max. booking time(min)"
                    rules="required"
                  >
                  <b-form-group>
                  <label label-for='maxbooking'>Max. Booking Time(Min)<span class="required">*</span></label>
                    <!-- <b-form-input
                      maxlength="3"
                      v-model="maxbooking"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      placeholder="Enter Max. Booking Time(Min)"
                      @copy.prevent 
                      @paste.prevent
                    />
                     <small v-if="checkInput(maxbooking)" style="color:red">It should be greater than zero</small> 
                    <small class="text-danger" style="display:block;">{{ errors[0] }}</small> -->
                    <b-input-group-append class="drop_down_menu bookingTime">
                  <b-form-select v-model="maxBookingSelected"   id="currency"  >
                    <b-form-select-option v-for="(option,index) in maxBooking_options" v-bind:key="index" :value="option.value" >{{option.text}} </b-form-select-option>
                  </b-form-select>
                  <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                  </b-input-group-append>
                  </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="3">
                   <validation-provider
                    #default="{ errors }"
                    name="trial booking time"
                    rules="required"
                  >
                  <b-form-group>
                  <label label-for='trialbooking'>Trial Booking Time<span class="required">*</span></label>
                    <!-- <b-form-input
                      maxlength="3"
                      v-model="trialbooking"
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      placeholder="Enter Trial Booking Time"
                      @copy.prevent 
                      @paste.prevent
                    />
                     <small v-if="checkInput(trialbooking)" style="color:red">It should be greater than zero</small> 
                    <small class="text-danger" style="display:block;">{{ errors[0] }}</small> -->
                    <b-input-group-append class="drop_down_menu bookingTime">
                            <b-form-select v-model="trialBookingSelected"   id="currency"  >
                              <b-form-select-option v-for="(option,index) in trialBooking_options" v-bind:key="index" :value="option.value" >{{option.text}} </b-form-select-option>
                            </b-form-select>
                            <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                  </b-input-group-append>
                  </b-form-group>
                   </validation-provider>
                </b-col>
              </b-row>
              </validation-observer>
            </div>
          </b-form>
        </b-card>
         <b-card no-body>
          <b-form>
            <div class="type_title">
              <b-row>
                <b-col md="6">
                  <h2>Refer Setting</h2>
                </b-col>
                <b-col md="6" class="text-right">
                  <b-button
                    variant="outline-primary"
                    v-on:click="cancelReferSetting"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    variant="primary"
                    v-on:click="saveReferSetting"
                    :disabled="isSpinner"
                  >
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div class="form-group-padding">
              <validation-observer ref="referSetting">
              <b-row>
                <b-col md="3">
                    <validation-provider
                        #default="{ errors }"
                        name="learner refer(credit)"
                        rules="required"
                        >
                  <b-form-group>
                  <label label-for='maxbooking'>Learner Refer(Credit)<span class="required">*</span></label>
                    <b-form-input       
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      placeholder="Enter Learner Refer(Credit)"
                      v-model="learnerRefer"
                      :maxlength = "3"
                      @change="isNumber"
                      @copy.prevent 
                      @paste.prevent
                    />
                    <small v-if="checkInput(learnerRefer)" style="color:red">It should be greater than zero</small>
                     <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                      <b-form-checkbox
                        class="checkbox_spacing text_font"
                        v-model="isEarnReferralCredit"
                      >
                        Buy 1st Time Plan and Earn Referral credit
                      </b-form-checkbox>
                  </b-form-group>
                    
                    </validation-provider>
                </b-col>
                <b-col md="3">
                    <validation-provider
                        #default="{ errors }"
                        name="tutor refer"
                        rules="required"
                        >
                  <b-form-group>
                  <label label-for='trialbooking'>Tutor Refer<span class="required">*</span></label>
                   <b-input-group>
                
                    <b-input-group :append="currency_display">
                      <b-form-input
                      onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                      placeholder="Enter Tutor Refer"
                      v-model="tutorRefer"
                      :maxlength = "3"
                       @keypress="isNumber"
                      @copy.prevent 
                      @paste.prevent
                    />
                    </b-input-group>
                      </b-input-group>
                    <small v-if="checkInput(tutorRefer)" style="color:red">It should be greater than zero</small>
                     <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                      <b-form-checkbox
                        class="checkbox_spacing text_font"
                        v-model="isEarnReferralPrice"
                      >
                        Complete 1st session and Earn Referral Price
                      </b-form-checkbox>
                  </b-form-group>
                    </validation-provider>
                </b-col>
              </b-row>
              </validation-observer>
            </div>
          </b-form>
        </b-card>

        <b-card no-body>
          <b-form>
            <div class="type_title">
              <b-row>
                <b-col md="6">
                  <h2>Support Email</h2>
                </b-col>
                <b-col md="6" class="text-right">
                  <b-button
                    variant="outline-primary"
                    v-on:click="cancelSupportEmail"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    variant="primary"
                    v-on:click="saveSupportEmail"
                    :disabled="isSpinner"
                  >
                    Save
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div class="form-group-padding">
              <validation-observer ref="supportEmail">
              <b-row>
                <b-col md="3">
                    <validation-provider
                        #default="{ errors }"
                        name="support email"
                        rules="required|email"
                        >
                  <b-form-group>
                  <label label-for='maxbooking'>Support Email<span class="required">*</span></label>
                    <b-form-input
                      placeholder="Enter Support Email"
                      v-model="supportEmail"
                      :maxlength = "50"
                    />
                     <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                  </b-form-group>
                    
                    </validation-provider>
                </b-col>
              </b-row>
              </validation-observer>
            </div>
          </b-form>
        </b-card>
        <b-modal
          id="modal-delete-tutor"
          centered
          ok-only
        >
            <img
              src="@/assets/images/erflog/cancel.png"
              alt="profile"
              class="warning_image"
            />
          <h4></h4>
          <b-card-text>
              There are tutor for this tutorType.
          </b-card-text>
        </b-modal>
        
      </div>
    </div>
  </div>
</template>

<script>
import {
  BFormTags,
  BInputGroup,
  BCard,
  BButton,
  BInputGroupAppend,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormSelect,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BBadge,
 BFormCheckbox,
 BFormSelectOption,
 BSpinner,
 BFormFile,
 BCardText
 
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import * as getQueary from "@/utils/FirebaseQueries/getQueries/getQueries";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { dbCollections } from "@/utils/firebaseCollection.js";
import {mapGetters,mapActions} from 'vuex';
import currency from '@/utils/CommonCurrency.json'
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Setting/Generalsettings.vue"
import * as storageFirebase from "@/utils/FirebaseQueries/storageQuery/storageQueries.js";
import firebase from "@/utils/firebaseInit"
const db = firebase.firestore();
export default {
  components: {
    BFormTags,
    BInputGroup,
    BSpinner,
    BCard,
    BButton,
    BInputGroupAppend,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormSelect,
    VBTooltip,
    BFormSelectOption,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    ValidationProvider, ValidationObserver,
    BBadge,
    BFormCheckbox,
    BFormFile,
    BCardText
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  mixins: [heightTransition],
  data() {
    return {
      isSpinner:false,
      isUpdating:false,
      isEarnReferralPrice:false,
      isEarnReferralCredit:false,
      maxwarning: 3,
      minBookingSelected: null,
      trialBookingSelected: null,
      maxBookingSelected: null,
      minBooking_options : [
        { value: 30, text: "30"},
        { value: 60, text: "60"},
        { value: 90, text: "90"},
        { value: 120, text: "120"},
        { value: 150, text: "150"},
        ],
        trialBooking_options : [
        { value: 30, text: "30"},
        { value: 60, text: "60"},
        ],
      maxperiod:7,
      minbooking : 90,
      maxbooking:180,
      trialbooking:30,
      creditvalue:75,
      clearInput:"",
      value: ["apple", "orange"],
      items: [
        // {
        //   tutorType: 'expert',
        //   creditValueRequired30Min:0,
        //   tutorlineEarning: 0,
        //   paidToTutor:0,
        //   bookingValue:0,
        //   paidCurrency: this.currency_selected,
        //   backgroundColor: '#000',
        //   textColor: '#000',
        // }
      ],
      learnerRefer:0,
      tutorRefer:0,
      nextTodoId: 2,
      currency_selected: null, 
      currency_options: [],
      tutortype_options: [],
       state: null,
       isLoading: false,
      tutorTypes:[],
      prevTutorTypes:[],
      tutorTypeValue:"",
      booktimeErr: true,
      settingChange:false,
      showErr: [],
      showTutorlineEarningErr:[],
      saveClick:false,
      currency_display: "",
      maxUser:false,
      tutorLineLoss: false,
      warningReset: 20,
      supportEmail: "",
    };
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    if ( Object.keys(this.getsettingList).length == 0) {  //get data from database
      this.getsettingArrayFeild().then(()=>{
        var tutorTypeSSS =  (this.getsettingList && this.getsettingList.tutorType)? this.getsettingList.tutorType : [] 
        this.tutorTypes = [...tutorTypeSSS]
        this.maxwarning = this.getsettingList.userMaxWarningsToGetSuspend
        this.maxperiod = this.getsettingList.maxPeriodOfSuspensionDays
        this.minBookingSelected = this.getsettingList.minBookingTime
        this.maxBookingSelected = this.getsettingList.maxBookingTime
        this.warningReset = this.getsettingList.warningResetDays
        this.trialBookingSelected = this.getsettingList.trialBookingTime
        this.creditvalue = this.getsettingList.oneCreditValue
        this.supportEmail = this.getsettingList.supportEmail
        this.currency_display = this.getsettingList.currency.slice(4)
        this.currencySelected()
        this.currencyOption()
        this.tutorTypeBookingData()
        this.tutortypeOption()
        this.Refer()
        this.TutorLineEarningCalculate()
      }).catch(error=>{
        console.error("error",error)
      })
    }
    else{
      var tutorTypeSSS =  this.getsettingList ? this.getsettingList.tutorType : [] 
          this.tutorTypes = [...tutorTypeSSS]
          this.maxwarning = this.getsettingList.userMaxWarningsToGetSuspend
          this.maxperiod = this.getsettingList.maxPeriodOfSuspensionDays
          this.warningReset = this.getsettingList.warningResetDays
          this.minBookingSelected = this.getsettingList.minBookingTime
          this.maxBookingSelected = this.getsettingList.maxBookingTime
          this.trialBookingSelected = this.getsettingList.trialBookingTime
          this.creditvalue = this.getsettingList.oneCreditValue
          this.supportEmail = this.getsettingList.supportEmail
          this.currency_display = this.getsettingList.currency.slice(4)
          this.currencySelected()
          this.currencyOption()
          this.tutorTypeBookingData()
          this.tutortypeOption()
          this.Refer()
          this.TutorLineEarningCalculate()
    }
    window.addEventListener("resize", this.initTrHeight);
  
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  computed:{
   ...mapGetters({
     getsettingList : 'settings/getsettingArrayFeild'
   }),
   maxBooking_options() {
    if (this.minBookingSelected == 30) {
      return [{ value: 60, text: "60"},
        { value: 90, text: "90"},
        { value: 120, text: "120"},
        { value: 150, text: "150"},
        { value: 180, text: "180"},
        { value: 210, text: "210"},
        { value: 240, text: "240"},]
    } else if(this.minBookingSelected == 60) {
      return[
        { value: 90, text: "90"},
        { value: 120, text: "120"},
        { value: 150, text: "150"},
        { value: 180, text: "180"},
        { value: 210, text: "210"},
        { value: 240, text: "240"},]
    } else if (this.minBookingSelected == 90) {
      return[
        { value: 120, text: "120"},
        { value: 150, text: "150"},
        { value: 180, text: "180"},
        { value: 210, text: "210"},
        { value: 240, text: "240"},]
    } else if (this.minBookingSelected == 120) {
      return[
        { value: 150, text: "150"},
        { value: 180, text: "180"},
        { value: 210, text: "210"},
        { value: 240, text: "240"},]
    } else if (this.minBookingSelected == 150) {
      return[
        { value: 180, text: "180"},
        { value: 210, text: "210"},
        { value: 240, text: "240"},]
    }
     else {
      return []
    }
   }
  },
  methods: {
  ...mapActions({
      'getsettingArrayFeild' : 'settings/getsettingArrayFeild',
  }),
  keydown: function(e) {
    },
  changeOptions(val, index){   //remove option if selected
      let self=this;
      self.tutortype_options.forEach(data=> {
        let found = false;
        self.items.forEach(data2=>{
            if(data.value == data2.tutorType) {
                found = true;
            }
        })
        data.show = !found;
      })
  },
  Refer(){   //for refer setting
    this.learnerRefer = this.getsettingList.learnerRefer.value
    this.tutorRefer = this.getsettingList.tutorRefer.value
    this.isEarnReferralPrice = this.getsettingList.tutorRefer.isEarnReferralPrice
    this.isEarnReferralCredit = this.getsettingList.learnerRefer.isEarnReferralCredit
  },
  tutortypeOption(){  // for tutor type option
        this.tutortype_options = [];
        this.getsettingList.tutorType.forEach(data=>{
            this.tutortype_options.push({value:data,show:true});
        })
  },
  checkInput(fieldName){  // for check if input is not 0 
     if (fieldName&&fieldName == 0) {
       return true
     }
     else {
      // this.showErr = false
       return false
     }
  },
  isNumber(e){  // prevent type other than number
      
      if(e.code != 'Numpad'+e.key && e.code != 'Digit'+e.key)
      {
          e.preventDefault();
      }
      else
      {
          return true;
      }

  },
  BookingValueCalculate(){  // calculate bookingvalue
     this.items.forEach((data)=>{
       data.bookingValue = this.creditvalue * data.creditValueRequired30Min
     })
  },
  TutorLineEarningCalculate(){  // calculate tutotr earning
  this.showTutorlineEarningErr = []
    this.items.forEach((data,index)=>{
       this.showTutorlineEarningErr.push("");
       data.tutorlineEarning = data.bookingValue - data.paidToTutor
       if (data.tutorlineEarning < 0) {
        this.tutorLineLoss = true;
        this.showTutorlineEarningErr[index] = "It should not be greater than booking value"
       } else {
        this.showTutorlineEarningErr[index] = ""
       }
     })
  },
  currencyOption(){  // get option of currency
       for (let i = 0; i < currency.length; i++) {
     this.currency_options.push({value : currency[i].code , text : currency[i].code + " " + currency[i].symbol})
   }
  },
  getTutorData(){  // get tutor data to dispaly
          var tutorTypeSSS =  this.getsettingList ? this.getsettingList.tutorType : [] 
          this.tutorTypes = [...tutorTypeSSS]
  },
  currencySelected(){  // get selected currency
     var curr = this.getsettingList.currency.slice(0,3)
     this.currency_selected = curr  
  },
  tutorTypeBookingData(){  //get booking setting data
        var bookingSett =  JSON.parse(JSON.stringify(this.getsettingList.tutorTypesBookingSeetings))

        this.items = bookingSett
  },
  addTutor(){
    if (this.tutorTypeValue !== '') { 
      this.maxUser = false; 
      if (this.tutorTypes.length+1 <= 15) { // max 15 tutor allow

        let ind = this.tutorTypes.findIndex((x)=> x.toLowerCase() === this.tutorTypeValue.toLowerCase())

        if(ind > -1)
        {
           this.$root.$emit("showToastMessage","Tutor type already exist", "danger");
            this.tutorTypeValue = ''
        } else {
        this.tutorTypes.push(this.tutorTypeValue)
           this.items.push( {
            tutorType: this.tutorTypeValue,
            creditValueRequired30Min:0,
            tutorlineEarning: 0,
            paidToTutor:0,
            bookingValue:0,
            paidCurrency:this.currency_selected
          });
            this.tutorTypeValue = ''
        }
      }
      else{
            this.maxUser = true;
      }
    }
  },
  removePopUp(index) {
        this.isSpinner = true;
        db.collection(dbCollections.USERS).where("tutorType","==",this.tutorTypes[index]).limit(1).get().then((respTutor) => {
            if(respTutor.empty) {
                this.isSpinner = false;
                this.removeTutor(index);
            } else {
                this.isSpinner = false;
                this.$bvModal.show('modal-delete-tutor')
            }
        });
  },
  removeTutor(index){
     this.$bvModal
      .msgBoxConfirm('Are you sure you want to Delete it?', {
        title: 'Delete',         
        class:'test',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-primary',
        hideHeaderClose: false,
        centered: true,
        footerClass: 'approve-footer',
        headerClass: 'approve-header',
        bodyClass: 'approve-body',
      })
      .then(value => {
        if(value){
          var remove =  this.tutorTypes.splice(index,1)
          this.items.forEach((data)=>{
          if (data.tutorType == remove) {
            this.items.splice(index,1) 
            // console.log("right",data.tutortype_selected)
          }
          })
        }
      })
  },
  saveCurrency(){
     if (this.currency_selected!== null && this.currency_selected !== this.getsettingList.currency) { // validation
     for (let i = 0; i < currency.length; i++) {  // save symbol and code in db
       if (currency[i].code == this.currency_selected) {
          this.currency_selected =  currency[i].code + " " + currency[i].symbol
       } 
       }
       this.isSpinner = true;
       try{
          let saveCurrency = {
                     currency : this.currency_selected
                    }
         updateFirebase.updateRootCollectionDataByDocId(
                        dbCollections.SETTINGS,
                        "setting",
                        saveCurrency,
                        (res) => {
                          this.$root.$emit(
                            "showToastMessage",
                            "Currency setting saved successfully.",
                            "success"
                          );  
                          this.isSpinner = false;
                          var curr = this.getsettingList.currency.slice(0,3)
                          this.currency_selected = curr
                          this.currency_display = this.getsettingList.currency.slice(4)
                        }
                      );
       }catch (error) {
        this.isSpinner = false
          console.error(error);
           errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "saveCurrency",
              errorMessage : error.message
            },(res)=>{
              if(res.data){
              }else if(res.error){
                  console.error(res.error)
              }
            })

       }
             
     }
  },
  async saveTutor(){
          try{
          this.saveClick = true;
          this.TutorLineEarningCalculate();
          let errInd = this.showTutorlineEarningErr.findIndex((data)=> data === "It should not be greater than booking value")
          console.error(errInd)
          this.$refs.bookingsetting.validate().then((valid) => {  //check all validation then add tutor type
            if (valid && errInd === -1) {
              this.BookingValueCalculate();   
              let input = true;
                this.items.forEach((data)=>{
                    if (data.creditValueRequired30Min <=0 || data.paidToTutor <=0) { //check value is greater than 0
                      input = false
                      this.showErr.push({
                        creditValueRequired30Min:true,
                          paidToTutor :true
                      })
                    }
                    else{
                      input = true
                      this.showErr.push({
                        creditValueRequired30Min:false,
                          paidToTutor :false
                      })
                    }
                })
              if (this.creditvalue!=0 && input!=false) {  //check validation creditvalue and booking setting
                if ( this.tutorTypeValue == "") { //if value is change then save it
                  if (this.tutorTypes.length > 0) {   // for minimum 1 tutor 
                    let saveTutor = {
                     tutorType : this.tutorTypes
                    }
                    updateFirebase.updateRootCollectionDataByDocId(dbCollections.SETTINGS,"setting",saveTutor,(res) => {
                                        this.isSpinner = true;
                                        if ((this.creditvalue !== this.getsettingList.creditValue) || (this.items !== this.getsettingList.tutorTypesBookingSeetings)) {  //if value is change or not
                                          try{
                                          this.items.forEach((data)=>{
                                            data.creditValueRequired30Min = parseInt(data.creditValueRequired30Min)
                                            data.paidToTutor = parseInt(data.paidToTutor)
                                          })
                                            let saveBookingSetting = {
                                            oneCreditValue :  parseInt(this.creditvalue),
                                            tutorTypesBookingSeetings : this.items
                                            }
                                          updateFirebase.updateRootCollectionDataByDocId(
                                              dbCollections.SETTINGS,
                                              "setting",
                                              saveBookingSetting,
                                              (res) => {
                                                this.$root.$emit(
                                                  "showToastMessage",
                                                  "Tutor type saved successfully.",
                                                  "success"
                                                );
                                                  this.isSpinner = false;
                                                  this.saveClick = false;
                                              }
                                            );
                                        }catch (error) {
                                          console.error(error)
                                            this.isSpinner = false;
                                            errorEmail({
                                              projectName : process.env.VUE_APP_PROJECTNAME,
                                              environment : process.env.NODE_ENV,
                                              fileName : currentFileName,
                                              hookName : "saveTutor",
                                              errorMessage : error.message
                                            },(res)=>{
                                              if(res.data){
                                              }else if(res.error){
                                                  console.error(res.error)
                                              }
                                            })
                                        }
                                        }
                                        else{
                                          this.isSpinner = false;
                                          this.saveClick = false;
                                        }
                            }
                      )
                  }
                  else{
                    this.$root.$emit("showToastMessage","Minimum 1 tutor type require.", "danger");
                    this.isSpinner = false;
                    this.saveClick = false;
                  }
                 
                }
                else{
                  this.saveClick = false
                }
              }
              else{
                this.saveClick = false
              }
            }
            else{
              this.saveClick = false
            }
          })
          }catch (error) {
            this.isSpinner = false;
            errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "saveTutor",
              errorMessage : error.message
            },(res)=>{
              if(res.data){
              }else if(res.error){
                  console.error(res.error)
              }
            })
            console.error(error)
          }
        // }
  },
  saveSuspend(){
      try{
        this.$refs.suspendForm.validate().then((valid) => {
             if (valid && ((this.maxwarning!=0) &&(this.maxperiod!=0) && (this.warningReset!=0))) { //if input is not 0
               this.isSpinner = true; 
               if (!(this.maxwarning === this.getsettingList.userMaxWarningsToGetSuspend)||!(this.maxperiod === this.getsettingList.maxPeriodOfSuspensionDays)||!(this.warningReset === this.getsettingList.warningResetDays)) {// if value is change or not
                 let saveSuspend = {
                  userMaxWarningsToGetSuspend : parseInt(this.maxwarning),
                  maxPeriodOfSuspensionDays : parseInt(this.maxperiod),
                  warningResetDays : parseInt(this.warningReset)
                 }
                 updateFirebase.updateRootCollectionDataByDocId(
                        dbCollections.SETTINGS,
                        "setting",
                        saveSuspend,
                        (res) => {
                          this.$root.$emit(
                            "showToastMessage",
                            "User suspend setting saved successfully.",
                            "success"
                          );  
                          this.isSpinner = false;
                        }
                      );
               }
               else{
                 this.isSpinner = false;
               }
             }
          })   
      }catch (error) {
        console.error(error)
        this.isSpinner = false;
        errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "saveSuspend",
              errorMessage : error.message
            },(res)=>{
              if(res.data){
              }else if(res.error){
                  console.error(res.error)
              }
            })
      }
  },
  changeMaxOption() {
    this.maxBookingSelected = null
  },
  saveBookingTime(){
      try{
        this.$refs.booktimesetting.validate().then((valid) => {
          if (valid) { //for not allow 0
            this.isSpinner = true;
            console.log(this.minBookingSelected,"minBookingSelected")
            if (!(this.minBookingSelected === this.getsettingList.minBookingTime)||!(this.maxBookingSelected === this.getsettingList.maxBookingTime) || !(this.trialBookingSelected === this.getsettingList.trialBookingTime)) { //if value is change or not
               let saveBookingTime = {
                   minBookingTime : parseInt(this.minBookingSelected),
                   maxBookingTime : parseInt(this.maxBookingSelected),
                   trialBookingTime : parseInt(this.trialBookingSelected),
                 }
                 updateFirebase.updateRootCollectionDataByDocId(
                         dbCollections.SETTINGS,
                         "setting",
                         saveBookingTime,
                         (res) => {
                           this.$root.$emit(
                             "showToastMessage",
                             "Booking time setting saved successfully.",
                             "success"
                           );
                            this.isSpinner = false;
                         }
                   )
            }
            else{
              this.isSpinner = false;
            }
          }
        })
      }catch (error) {
       console.error(error)
        this.isSpinner = false;
        errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "saveBookingTime",
              errorMessage : error.message
            },(res)=>{
              if(res.data){
              }else if(res.error){
                  console.error(res.error)
              }
            })
      } 
  },
  saveReferSetting(){
         try{
           this.$refs.referSetting.validate().then((valid) => {
            if (valid && (this.learnerRefer !=0 && this.tutorRefer!=0)) {
             this.isSpinner = true;
                  let saveReferSetting = {
                    learnerRefer : {value : parseInt(this.learnerRefer) , isEarnReferralCredit : this.isEarnReferralCredit},
                    tutorRefer : {value : parseInt(this.tutorRefer) , isEarnReferralPrice : this.isEarnReferralPrice},
                    }
                  updateFirebase.updateRootCollectionDataByDocId(
                      dbCollections.SETTINGS,
                      "setting",
                      saveReferSetting,
                      (res) => {
                        this.$root.$emit(
                          "showToastMessage",
                          "Refer setting saved successfully.",
                          "success"
                        );
                        this.isSpinner = false;
                      }
                    );  
            }
          })
         }catch (error) {
           console.error(error)
           this.isSpinner = false;
           errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "saveReferSetting",
              errorMessage : error.message
            },(res)=>{
              if(res.data){
              }else if(res.error){
                  console.error(res.error)
              }
            })
         }
  },
  saveSupportEmail(){
    this.isSpinner = true;
      this.$refs.supportEmail.validate().then((valid) => {
        if (valid) {
           let saveEmail = {
              supportEmail: this.supportEmail
                 }
                 updateFirebase.updateRootCollectionDataByDocId(
                        dbCollections.SETTINGS,
                        "setting",
                        saveEmail,
                        (res) => {
                          this.$root.$emit(
                            "showToastMessage",
                            "Support email save successfully.",
                            "success"
                          );  
                          this.isSpinner = false;
                        }
                      );
        } else {
          this.isSpinner = false;
        }
      })
  },
  cancelSupportEmail(){
    this.supportEmail = this.getsettingList.supportEmail
  },
  cancelTutor(){
      try{
          this.isSpinner = true;
          var tutorTypeSSS = this.getsettingList ? this.getsettingList.tutorType : [] 
          this.tutorTypes = [...tutorTypeSSS]
          this.tutorTypeValue = ""
          this.isSpinner = false;
          this.maxUser = false;
          var bookingSett =  JSON.parse(JSON.stringify(this.getsettingList.tutorTypesBookingSeetings))
          this.items = bookingSett
          this.showTutorlineEarningErr = [];
          this.creditvalue = this.getsettingList.oneCreditValue
      }catch (error) {
        console.error(error)
        this.isSpinner = false;
        errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "cancelTutor",
              errorMessage : error.message
            },(res)=>{
              if(res.data){
              }else if(res.error){
                  console.error(res.error)
              }
            })
      }
   
  },
  cancelSuspend(){
      try{
        this.maxwarning = this.getsettingList.userMaxWarningsToGetSuspend
        this.maxperiod = this.getsettingList.maxPeriodOfSuspensionDays
        this.warningReset = this.getsettingList.warningResetDays
      }catch (error) {
        console.error(error)
        errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "cancelSuspend",
              errorMessage : error.message
            },(res)=>{
              if(res.data){
              }else if(res.error){
                  console.error(res.error)
              }
            })
      }
  },
  cancelCurrency(){
    var curr = this.getsettingList.currency.slice(0,3)
     this.currency_selected = curr
  },
  cancelBookingTime(){
      this.minBookingSelected = this.getsettingList.minBookingTime
      this.maxBookingSelected = this.getsettingList.maxBookingTime
      this.trialBookingSelected = this.getsettingList.trialBookingTime
  },
  cancelReferSetting(){
    this.learnerRefer = this.getsettingList.learnerRefer.value
    this.tutorRefer = this.getsettingList.tutorRefer.value
    this.isEarnReferralPrice = this.getsettingList.tutorRefer.isEarnReferralPrice
    this.isEarnReferralCredit = this.getsettingList.learnerRefer.isEarnReferralCredit
  },
  repeateAgain() {
      if (this.items.length < this.tutortype_options.length) {
        this.items.push( {
            tutorType: null,
            creditValueRequired30Min:1,
            tutorlineEarning: 1,
            paidToTutor:1,
            bookingValue:1,
          });
        this.changeOptions();
        this.$nextTick(() => {
            this.trAddHeight(this.$refs.row[0].offsetHeight);
        });
      }  
  },
  removeItem(index) {
      this.items.splice(index, 1);
      this.changeOptions();
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
  },
  initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
  },
  },
};
</script>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
.turot_types{
  width: 100%;
  max-width: 367px;
}
.checkbox_spacing{
  margin-top: 3px;
}
input#credit-value {
    height: 38px;
}
.drop_down_remove_arrow .custom-select{
    width : 100%;
    max-width: 65%;
    background: none;
    padding: 0.438rem 1rem 0.438rem 1rem !important;
}
input#paidtutor{
  height: 38px;
}
.drop_down_remove_arrow .custom-select:disabled{
  color: #999999;
  font-weight: 500;
  text-align: center;
}

.coustem_badge {
    background-color:#E7E7E7;
    color: #606060
;
}
.input-group.currencybackground .input-group-text {
    background-color: #efefef;
}
@media(max-width:1024px){
  .drop_down_remove_arrow .custom-select{
    max-width: 100%;
  }
}
</style>