<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo" to="/login">
            <b-img src="@/assets/images/tutorimages/Logo.png" alt="LOGO" />
      </b-link>
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-0  mobile_bg_main">
        <div class="w-100 d-lg-flex align-items-center justify-content-center">
          <b-img fluid src="@/assets/images/tutorimages/BG.png" alt="Login V2"/>
        </div>
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-2">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto tutor_sign_in"> 

            <b-card-title class="mb-2 font-weight-bold sign_in_account" style="color:#BA1628" title-tag="h2" @click="runAuthApi()">
              Admin Log In
            </b-card-title>
            <b-card-text class="mb-2 bold_text">
             Sign in to continue to Tutorline
            </b-card-text> 
            <validation-observer ref="loginForm">
              <b-form class="auth-login-form mt-2 account_in" @submit.prevent="login">   
                    <b-form-group class="custom_placeholder">
                        <label label-for="login-email" >Email<span class="redcolorstar">*</span></label>
                      <validation-provider #default="{ errors }" name="email " vid="email" rules="required|email" >
                        <b-form-input id="login-email" v-model="userEmail" name="login-email" placeholder="john@example.com" @keyup="credentialError.email = ''"/>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small class="text-danger" v-if='credentialError.email !== ""'>{{credentialError.email}}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group>
                      <div class="d-flex justify-content-between">
                        <label for="login-password">Password<span class="redcolorstar">*</span></label>
                        <b-link :to="{name:'auth-forgot-password'}">
                          <small class="forgot_password_wrapper">Forgot Password?</small>
                        </b-link>
                      </div>
                      <validation-provider #default="{ errors }" name="password " vid="password" rules="required">
                          <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null" >
                              <b-form-input id="login-password" v-model="password" class="form-control-merge" :type="passwordFieldType" name="login-password" placeholder="Enter Password" @keyup="credentialError.pass = ''" />
                              <b-input-group-append is-text>
                                <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                              </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                          <small class="text-danger" v-if='credentialError.pass !== ""'>{{credentialError.pass}}</small>
                      </validation-provider>
                    </b-form-group>            
                    <b-button type="submit" variant="primary" block>
                      <span v-if="!isLogging">
                        Log In
                      </span>
                      <span v-else>
                        <b-spinner small/>
                      </span>
                    </b-button>
              </b-form>
            </validation-observer>
        </b-col>
          <div style="text-align:center;width:100%;position: absolute;bottom: 20px;right: 0px;">
            <a @click.prevent="$router.replace({name:'versionControl'}).catch(()=>{})">
              Version {{projectVersion}}
            </a>
          </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { version } from '@/../package.json'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, 
  BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as getQuery from '@/utils/FirebaseQueries/getQueries/getQueries.js'
import { dbCollections } from '@/utils/firebaseCollection.js';
import firebase from "@/utils/firebaseInit";
var jwt = require('jsonwebtoken');
const db = firebase.firestore()
export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {      
      projectVersion:version,
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      // validation rules
      required,
      email,
      isLogging:false,
      credentialError:{
        email:'',
        pass:''
      }
    }
  },  
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created(){
  },
  methods: {
    login() {
      this.isLogging=true
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.credentialError.email = '';
          this.credentialError.pass = '';     
                firebase
                .auth()
                .signInWithEmailAndPassword(this.userEmail,this.password)
                .then((data) => {
                      getQuery.getDataFromRootCollectionWithDocID(dbCollections.USERS,data.user.uid,(res)=>{  
                        if(Object.keys(res).length <= 0){
                          this.credentialError.email = 'Invalid user credential';
                          this.credentialError.pass = 'Invalid user credential';
                          this.isLogging=false
                        }else{
                          if(res.email == this.userEmail || res.personalEmail == this.userEmail || res.companyEmail == this.userEmail){
                                var tempObj = {};
                                if(res.userType == 'admin'){
                                  tempObj = {
                                    id: 1,
                                    fullName: 'admin',
                                    username: 'admin',
                                    email: this.userEmail,
                                    role: 'admin',
                                    password: this.password,
                                    userDocId:data.user.uid,
                                    avatar:'',
                                    ability: [
                                      {
                                        action: 'manage',
                                        subject: 'all',
                                      },
                                    ],
                                    extras: {
                                      eCommerceCartItemsCount: 5,
                                    },                             
                                  }
                                // }
                                localStorage.setItem('currentUser', JSON.stringify(tempObj))                             
                                setTimeout(()=>{
                                  useJwt.login({
                                    email: this.userEmail,
                                    password: this.password,
                                  })
                                  .then(response => {
                                     const token = jwt.sign(
                                        { userId: data.user.uid, email : this.userEmail },
                                            'tutorline',
                                        {
                                            expiresIn: "10m",
                                        }
                                    );
                                    localStorage.setItem('jwtToken',token);
                                   
                                    let userAgent = navigator.userAgent;
                                    let browserName;
                                    
                                    if(userAgent.match(/chrome|chromium|crios/i)){
                                      browserName = "chrome";
                                    }else if(userAgent.match(/firefox|fxios/i)){
                                      browserName = "firefox";
                                    }  else if(userAgent.match(/safari/i)){
                                      browserName = "safari";
                                    } else if(userAgent.match(/edg/i)){
                                      browserName = "edge";
                                    }else{
                                      browserName="No browser detection";
                                    }
                                    const { userData } = response.data
                                    useJwt.setToken(response.data.accessToken)
                                    useJwt.setRefreshToken(response.data.refreshToken)
                                    localStorage.setItem('userData', JSON.stringify(userData))
                                    this.$ability.update(userData.ability)
                                    this.$router.go()
                                    setTimeout(()=>{
                                      this.$router.replace(getHomeRouteForLoggedInUser(userData.role.toLowerCase()))
                                      .then(() => {
                                        this.isLogging=false
                                        this.$root.$emit('setVerticalTab')
                                        this.$toast({
                                          component: ToastificationContent,
                                          position: 'top-right',
                                          props: {
                                            title: `Welcome ${userData.fullName}`,
                                            icon: 'CoffeeIcon',
                                            variant: 'success',
                                            text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                                          },
                                        })
                                      })
                                    },1000)
                                  })
                                  .catch(error => {
                                    console.error(error)
                                    this.isLogging=false
                                    this.$refs.loginForm.setErrors(error)                                     
                                    this.$toast({
                                      component: ToastificationContent,
                                      position: 'top-right',
                                      props: {
                                        title: 'Invalid user credential.',
                                        icon: 'CoffeeIcon',
                                        variant: 'danger',
                                        text:'Please try again.'
                                      },
                                    })
                                  })
                                },1000)
                                }else{
                                    this.isLogging=false
                                    this.$refs.loginForm.setErrors('Admin not found')                                     
                                    this.$toast({
                                      component: ToastificationContent,
                                      position: 'top-right',
                                      props: {
                                        title: 'Invalid user credential.',
                                        icon: 'CoffeeIcon',
                                        variant: 'danger',
                                        text:'Please try again.'
                                      },
                                    })                          
                                }
                          }else{
                            this.isLogging=false
                            this.$refs.loginForm.setErrors('Admin not found')                                     
                            this.$toast({
                              component: ToastificationContent,
                              position: 'top-right',
                              props: {
                                title: 'Admin not found in storage.',
                                icon: 'CoffeeIcon',
                                variant: 'danger',
                                text:'Please try again.'
                              },
                            })                          
                          }
                        }
                      })
                }).catch((err)=>{
                  this.isLogging=false
                  console.error("error",err)
                  var msg = err.code.replace('auth/','');
                  this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `${msg=='user-disabled'?'User is Inactive.':msg}`,
                    icon: 'CoffeeIcon',
                    variant: 'danger',                   
                  },
                  })
                })    
        }
        else
        {
          this.isLogging=false
        }
      })
    },            
  },  
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
