<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
    </div>
    <b-card class="categories_section" v-show="!isSpinner">
      <b-tabs>
        <b-tab active title="Categories">
          <div class="categories_table">
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="CategorysearchTerm"
                    placeholder="Search..."
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </div>
            <div v-if="renderComponent">
            <vue-good-table
              :columns="Categorycolumns"
              :rows="categoryDisplay"
              :rtl="direction"
              class="cursor_pointer"
              :search-options="{
                enabled: true,
                externalQuery: CategorysearchTerm,
              }"
              :select-options="{
                enabled: true,
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
           
            <template slot="table-row" slot-scope="props">
               <span v-if="props.column.field === 'categoryname'" class="text-nowrap  tutor-list">
                <span class="text-nowrap">
                  <router-link :to="{name: 'category-details', params: {name: props.row.categoryname,id: props.row.categoryid}}">
                  {{props.row.categoryname}}
                  </router-link>
                </span>
               </span>
                <span
                  v-else-if="props.column.field === 'action'"
                  class="action_field"
                >
                  <b-button variant="flat-dark" @click="categoryPopUpEdit(props.row.categoryid)">
                    <img
                      :src="require('@/assets/images/erflog/Edit.png')"
                      alt="image"
                    />
                  </b-button>
                  <b-button variant="flat-dark" @click="categoryPopUp(props.row.categoryid)" class="ml-2">
                    <img
                      :src="require('@/assets/images/erflog/Delete.png')"
                      alt="image"
                    />
                  </b-button>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      <span v-if="props.total === 0"> Showing 0 </span>
                      <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                      to
                      <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                      <span v-else>{{props.total}} of {{ props.total }} entries</span>
                    </span>

                        <div class="d-flex align-items-center mb-1 mt-0 showentry category">
                          <span>Show</span>
                          <b-form-select
                            v-model="pageLength"
                            :options="['10', '20', '30', '40', '50']"
                            class="mx-1"
                            @input="
                              (value) => props.perPageChanged({ currentPerPage: value })
                            "
                        />
                          <span>entries</span>
                        </div>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :current-page="currentPage"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => {
                          props.pageChanged({ currentPage: value });
                          currentPage = value
                        }
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
            </div>
          </div>
        </b-tab>
        <b-tab title="Request for Categories">
          <div class="request_categories_table">
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="requestcategoriessearchTerm"
                    placeholder="Search..."
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </div>
            <vue-good-table
              :columns="requestcategoriescolumns"
              :rows="requestcategoriesrows"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: requestcategoriessearchTerm,
              }"
              :select-options="{
                enabled: true,
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
              }"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <span
                  v-if="props.column.field === 'fullName'"
                  class="text-nowrap userid"
                >
                  <b-avatar :src="props.row.avatar" class="user_img" />
                  <router-link
                   :to="{ name: 'user-details', params: { id: props.row.userDocId, name: props.row.fullName,role:props.row.userRole.join(',')}}"
                    class="text-nowrap"
                    >{{ props.row.fullName }}
                  </router-link
                  >
                </span>
                <span
                  v-else-if="props.column.field === 'requestdt'"
                  class="text-nowrap userid"
                >
                 {{requestCategoryDate(props.row.requestdt)}}
                </span>
                <span
                  v-else-if="props.column.field === 'tutorType'"
                  class="tutor_type"
                >
                <div v-if="props.row.tutorType != '' && props.row.tutorType != null">
                  <b-badge  
                    :style="getTutorTypeStyle(props.row.tutorType)"
                  >
                  <!-- :variant="getStatusBadgeVariant(props.row.tutorType)" -->
                    {{ props.row.tutorType }}
                  </b-badge>
                </div>
                </span>
                <span
                  v-else-if="props.column.field === 'action'"
                  class="action_field d-inline-block"
                  v-for="(item,index) in props.row.action"
                  :key="index"
                >
                  <b-button
                    v-if="item === 'Create Category'"
                    variant="outline-primary"
                    v-bind:data-value="item"
                    v-b-modal.modal-tab-create-category
                    @click="clickForCreateRequestedCategory(props.row)"
                  >
                    {{ item }}
                  </b-button>
                  <b-button
                    v-else
                    variant="outline-secondary"
                    v-bind:data-value="item"
                    @click="cancelRequest(props.row)"
                  >
                    {{ item }}
                  </b-button>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> 
                  <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to
                  <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                  <span v-else>{{props.total}} of {{ props.total }} entries</span>
                </span>

                    <div class="d-flex align-items-center mb-1 mt-0 showentry category">
                      <span>Show</span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10', '20', '30', '40', '50']"
                        class="mx-1"
                        @input="
                          (value) => props.perPageChanged({ currentPerPage: value })
                        "
                     />
                      <span>entries</span>
                    </div>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :current-page="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => {
                      props.pageChanged({ currentPage: value });
                      currentPage = value
                    }
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
              </template>
            </vue-good-table>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal
      id="modal-tab-create-category"
      ref="my-modal"
      title="Create Category"
      ok-title="Yes"
      cancel-title="No"
      cancel-variant="outline-primary"
      centered
      data-class="addcredit_popup"
      @show="CategoryresetModal"
      @hidden="CategoryresetModal"
      @ok="CategoryhandleOk"
      :ok-disabled="isSpinner"
      :cancel-disabled="isSpinner"
    >
      <p>Are you sure you want to Create Category.</p>
      <validation-observer ref="Categoryform">
      <form ref="form">
        <validation-provider
          #default="{ errors }"
          name="category name"
          rules="required"
        >
        <b-form-group
          :state="nameState"
          label="Category Name"
          label-for="name-input"
          invalid-feedback="Category Name is required"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            placeholder="Enter Category Name"
            :state="nameState"
            @keyup="CheckCategoryNameExist()"
            @blur="name=name.trim()"
            required
          />
          <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
          <small v-if="sameCategoryErrorMessage != ''" class="text-danger">{{sameCategoryErrorMessage}}</small>
        </b-form-group>
        </validation-provider>
      </form>
      </validation-observer>
    </b-modal>

    <b-modal
      id="modal-delete-category"
      centered
      ok-only
    >
      <img
        src="@/assets/images/erflog/cancel.png"
        alt="profile"
        class="warning_image"
      />
      <h4></h4>
      <b-card-text>
          There are session for this category.
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-edit-category"
      centered
      ok-only
      body-class="edit_category_warning"
    >
      <img
        src="@/assets/images/erflog/icon_info.png"
        alt="profile"
        class="warning_image_category_edit"
      />
      <h4></h4>
      <b-card-text>
          There are session for this category.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
  BTabs,
  BTab,
  BCard,
  BModal,
  BSpinner,
  VBModal,
  BCardText
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import {dbCollections} from "@/utils/firebaseCollection.js";
import { mapActions,mapGetters } from "vuex";
import firebase from "@/utils/firebaseInit.js";
import * as getQueary from "@/utils/FirebaseQueries/getQueries/getQueries"
const db = firebase.firestore()
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import {categoryRequestActionNotification} from "./categoryRequestActionNotificationService.js"
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/CategoryManagement/Category.vue"
export default {
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BCard,
    BButton,
    BTabs,
    BTab,
    BModal,
    BSpinner,
    ValidationProvider, ValidationObserver,
    BCardText
  },
  data() {
    return {
      direction:false,
      isSpinner:false,
      isUpdating:false,
      nameState : null,
      name:'',

      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      pageLength: 10,
      dir: false,
      Categorycolumns: [
        {
          label: "Category Name",
          field: "categoryname",
        },
        {
          label: "No. of Session(s) Title",
          field: "noOfSession",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      CategorysearchTerm: "",
      requestcategoriescolumns: [
        {
          label: "Category Name",
          field: "categoryname",
        },
        {
          label: "Request Date and Time",
          field: "requestdt",
        },
        {
          label: "Tutor Name",
          field: "fullName",
        },
        {
          label: "Tutor Type",
          field: "tutorType",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      requestcategoriessearchTerm: "",
      tutorType: [
        {
          1: "Valuable",
          2: "Elite",
          3: "Normal",
          4: "N/A",
        },
        {
          1: "light-primary",
          2: "light-danger",
          3: "light-success",
          4: "light-dark",
        },
      ],
      Categoryname: "",
      CategorynameState: null,
      CategorysubmittedNames: [],
      isInArray:false,
      sameCategoryErrorMessage:'',
      selctedCategory:'',
      renderComponent: true,
      tutorId:""
    };
  },
  created() {
    var self = this;
    let catfound =  this.categories.length!=0
    if (!catfound) {
       self.getCategories()
    }
    let requestCategoryFound = this.RequestCategory.length!=0
    if (!requestCategoryFound) {
      self.getRequestCategory() 
    }
    let cateGoryDisplayFound = this.categoryDisplay.length!=0
    if (!cateGoryDisplayFound) {
      this.isSpinner = true;
      self.getCategoriesDisplay().then(()=>{
        this.isSpinner = false;
      }).catch(()=>{
        this.isSpinner = false;
      });
    }
  },
  computed:{
    ...mapGetters({
      categories:'categoryManagement/getCategories',
      getUsersList:'userManagement/getUsers',
      RequestCategory:'requestCategory/getRequestUserCategory',
      categoryDisplay:'categoryManagement/getCategoriesDisplay',
      gettutorTypesList: "settings/getTutorTypes",
    }),
    Categoryrows(){
    },
    // REQUESTED CATEGORY ARE DISPLAY(Shraddha)
    requestcategoriesrows(){
      try{
        var array = [];
        for(var i=0; i<this.RequestCategory.length;i++){
          for(var j=0; j<this.getUsersList.length;j++){
            if(this.RequestCategory[i].userId == this.getUsersList[j].id){
              var obj = {
                id:this.RequestCategory[i].id,
                categoryname: this.RequestCategory[i].categoryName,
                requestdt: this.RequestCategory[i].createdAt,
                avatar: this.getUsersList[j].profilePicture,
                fullName: `${this.getUsersList[j].firstName} ${this.getUsersList[j].lastName}`,
                tutorType: this.getUsersList[j].tutorType,
                userId:this.getUsersList[j].userId,
                userDocId : this.getUsersList[j].id,
                userRole:this.getUsersList[j].userRole,
                action: ["Create Category", "Cancel"],
              }
              array.push(obj)
            }
          }
        }
        return array
      }catch(err){
          console.error(err)
          errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "requestcategoriesrows",
          errorMessage : error.message
        },(res)=>{
           if(res.error){
              console.error(res.error)
          }
        })
      }
    }
  },
  methods: {
     ...mapActions({
      addCategory: "categoryManagement/createCategory",
      getRequestCategory: "requestCategory/getRequestedCategory",
      getCategories:'categoryManagement/getCategoriesData',
      getCategoriesDisplay: 'categoryManagement/getCategoriesData'
    }),
    getTutorTypeStyle(value) {
        if(this.gettutorTypesList) {
            let index = this.gettutorTypesList.findIndex((val ) =>{
              return val.name == value
            });
            if(index > -1) {
                return {
                  'background-color' : this.gettutorTypesList[index].backgroundColor,
                  'color' : this.gettutorTypesList[index].textColor,
                  'border-color': this.gettutorTypesList[index].textColor,
                  'border-width': '1px',
                  'border-style': 'solid'
                }
            }
        } else {
            return ''
        }
    },
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
      this.renderComponent = true;
    })
    },
    onRowClick(data) {
      if (data.column.field === "action") {
          return false;
      } else {
         this.$router.push({name: 'category-details', params: {name: data.row.categoryname,id: data.row.categoryid}})
      }
    },
    clickForCreateRequestedCategory(index){
      var self = this;
      var idx = self.requestcategoriesrows.findIndex(item => {
          return item.id == index.id 
      })
      if(idx != -1){
        self.selctedCategory = idx;
        self.name = self.requestcategoriesrows[idx].categoryname;
        self.tutorId = self.requestcategoriesrows[idx].userDocId;
        self.CheckCategoryNameExist();
      }
     
    },
    CategoryresetModal() {
      this.Categoryname = "";
      // this.tutorId = "";
      this.CategorynameState = null;
    },
    CategoryhandleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.CategoryhandleSubmit();
    },
    CheckCategoryNameExist(){
      
      var self = this;
      self.sameCategoryErrorMessage = "";
      self.isInArray = self.categories.some(function(item) {
          return item.categoryName.toLowerCase().trim() === self.name.toLowerCase().trim();
      });
      if(self.isInArray && self.name != ''){
        self.sameCategoryErrorMessage = 'The category name is already exist';
      }else{
        self.sameCategoryErrorMessage = '';
      }
    },
    CategoryhandleSubmit() {
      var self = this;
      self.$refs.Categoryform.validate()
      .then((valid)=>{
        if(valid && !self.isInArray && self.sameCategoryErrorMessage == ''){
          
            self.isSpinner = true;
            try {
              var categoryObj = {
                categoryName: this.name,
                createdAt: new Date(),
                updatedAt: new Date(),
                icon: "",
              };
              self.addCategory(categoryObj)
                .then((res) => {
                  if(self.requestcategoriesrows[self.selctedCategory].categoryname == self.name || self.requestcategoriesrows[self.selctedCategory].categoryname !== self.name){
                    var categoryObject = {
                      status : 2,
                        categoryName : self.name,
                        updatedAt: new Date()
                        }
                      updateFirebase.updateRootSubCollectionDataWithDocId(dbCollections.SETTINGS,'setting',dbCollections.REQUESTFORCATEGORY,self.requestcategoriesrows[self.selctedCategory].id,categoryObject,(res) => {
                        categoryRequestActionNotification({
                          tutorId : this.tutorId,
                          categoryName  : this.name,
                          action: "approve"
                        },()=>{})
                        self.$root.$emit('showToastMessage','Category saved successfully.','success');
                        self.$nextTick(() => {
                          self.$bvModal.hide("modal-tab-create-category");
                          self.isSpinner = false;
                        });
                      })
                    }else{
                      self.$nextTick(() => {
                        self.$bvModal.hide("modal-tab-create-category");
                        self.isSpinner = false;
                      });
                    }
                  })
                .catch((error) => {
                  console.error(error);
                  self.isSpinner = false;
                });
            } catch (error) {
              console.error("error", error);
               errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "requestcategoriesrows",
                errorMessage : error.message
              },(res)=>{
                if(res.error){
                    console.error(res.error)
                }
              })
              self.isSpinner = false;
              self.$nextTick(() => {
                self.$bvModal.hide("modal-tab-create-category");
                self.isSpinner = false;
              });
            }
        }
      })
     
    },
    categoryPopUp(id) {
        this.isSpinner = true;
        getQueary.simpleGetDataWithSpecificWhereConditionV2(dbCollections.SESSIONS, "category", "==", id, (respSession) => {
            if(respSession && respSession.data.length === 0) {
                this.isSpinner = false;
                this.deleteCategory(id);
            } else {
                this.isSpinner = false;
                this.$bvModal.show('modal-delete-category')
            }
        });
    },
    categoryPopUpEdit(id) {
      this.isSpinner = true;
      getQueary.simpleGetDataWithSpecificWhereConditionV2(dbCollections.SESSIONS, "category", "==", id, (respSession) => {
          if(respSession && respSession.data.length === 0) {
              this.isSpinner = false;
              this.editCategory(id);
          } else {
              this.isSpinner = false;
              this.$bvModal.show('modal-edit-category')
          }
      });
  },
    //DELETE CATEGORY WHEN DELETE BUTTON ICON CLICK (Shraddha)
    deleteCategory(id){
      
      var self = this;
       self.$bvModal
      .msgBoxConfirm('Are you sure you want to Delete it?', {
        title: 'Delete',         
        class:'test',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-primary',
        hideHeaderClose: false,
        centered: true,
        footerClass: 'approve-footer',
        headerClass: 'approve-header',
        bodyClass: 'approve-body',
      })
      .then(value => {
        if(value){
          self.isSpinner = true;
            db.collection(dbCollections.SETTINGS).doc('setting').collection(dbCollections.CATEGORY).doc(id).delete().then(() => {
              self.$root.$emit('showToastMessage','Category deleted successfully.','success');
              var index = self.categories.findIndex(item => {
                    return item.id == id
                })
                if(index === 0 && self.categoryDisplay.length === 1 && self.categories.length === 1){
                    self.categories.splice(index,1)
                    self.categoryDisplay.splice(index,1)
                }
              self.isSpinner = false;
            })
        }
      })
    },
    //EDIT CATEGORY WHEN EDIT BUTTON ICON CLICK (Shraddha)
    editCategory(rowIndex){
      
      var self = this;
      self.$root.$emit('editCategory',true,rowIndex)
      
    },
    //CANCEL REQUESTED CATEGORY(Shraddha)
    cancelRequest(id){
      
       var self = this;
       self.$bvModal
      .msgBoxConfirm('Are you sure you want to Cancel it?', {
        title: 'Cancel',         
        class:'test',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
        cancelVariant: 'outline-primary',
        hideHeaderClose: false,
        centered: true,
        footerClass: 'approve-footer',
        headerClass: 'approve-header',
        bodyClass: 'approve-body',
      })
      .then(value => {
        if(value){
          var categoryObject = {
            status : 1,
            updatedAt:new Date()
          }
            self.isSpinner = true;
            updateFirebase.updateRootSubCollectionDataWithDocId(dbCollections.SETTINGS,'setting',dbCollections.REQUESTFORCATEGORY,id.id,categoryObject,(res) => {
            categoryRequestActionNotification({
              tutorId : id.userDocId,
              categoryName  : id.categoryname,
              action: "reject"
            },()=>{})
            self.$root.$emit('showToastMessage','Category deleted successfully.','success');
            self.isSpinner = false;
          })
        }
      })
    },
    // FORMAT BANNER CREATE DATE AS PER REQUIREMENT (Shraddha)
    requestCategoryDate(bannerCreateDate){
      var self = this;
      var bannerDate = new Date(bannerCreateDate.seconds*1000).getDate();
      var bannerMonth = new Date(bannerCreateDate.seconds*1000).getMonth()+1;
      var bannerYear = new Date(bannerCreateDate.seconds*1000).getFullYear();
      var returnBannerDate = self.addZero(bannerDate)+'-'+self.addZero(bannerMonth)+'-'+bannerYear+' , '+ self.formatAMPM(new Date(bannerCreateDate.seconds*1000));
      return returnBannerDate;
    },
     // FORMAT TIME AM AND PM FOR BANNER (Shraddha)
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return strTime;
    },
      // ADD ZERO IN FRONT SIDE IF VALUE IS LESS THEN 9(Shraddha)
    addZero(value){
      if(value > 9){
        return value;
      }else{
        return '0' + value;
      }
    },
     // ADD BADGE RELATED TO STATUS(Shraddha)
    getStatusBadgeVariant(value){
      
      var variant = "";
      if(value == "Valuable")
      {
        variant += 'light-primary';
      }
      else if(value == "Elite")
      {
        variant += 'light-danger';
      }
      else if(value == "Normal")
      {
        variant += 'light-success';
      }
      return variant;
    },
  },
};
</script>
