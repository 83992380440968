<template>
  <div>
     <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
    </div>
      <b-tab title="Pending Session(s)" @click="onBookingRequset">
              <div class="card user_filter">
                <h2>Filters</h2>

                <b-form @submit.prevent>
                  <b-row>
                    <b-col xl="3" lg="4" md="5">
                      <label>Date</label>
                      <flat-pickr
                        v-model="bookingreqdateNtim"
                        class="form-control datetime_picker"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                        :on-Change="bookingreqrows"
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </div>
              <div class="card user_table session_table">
                <div class="custom-search d-flex justify-content-end">
                  <b-form-group>
                    <div class="d-flex align-items-center">
                      <b-form-input
                        v-model="bookingreqsearchTerm"
                        placeholder="Search..."
                        type="text"
                        class="d-inline-block"
                      />
                    </div>
                  </b-form-group>
                </div>
                <vue-good-table
                  :columns="bookingreqcolumns"
                  :rows="filterRow"
                  :rtl="direction"
                   @on-cell-click="onCellClick"
                   class="cursor_pointer"
                  :search-options="{
                    enabled: true,
                    externalQuery: bookingreqsearchTerm,
                  }"
                  :select-options="{
                    enabled: true,
                    selectionInfoClass: 'custom-class',
                    selectionText: 'rows selected',
                    clearSelectionText: 'clear',
                    disableSelectInfo: true, // disable the select info panel on top
                  }"
                  :pagination-options="{
                    enabled: true,
                    perPage: pageLength,
                  }"
                >
                  <template slot="table-row" slot-scope="props">
                    <span
                      v-if="props.column.field === 'bookingid'"
                      class="text-nowrap userid"
                    >
                    {{ props.row.bookingid }}
                    </span>
                    <span
                      v-else-if="props.column.field === 'learnername'"
                      class="text-nowrap"
                    >
                      <b-avatar :src="props.row.avtar" class="user_img" />
                      <span class="userid" @click="getDataAgain()">
                        <router-link :to="`/user-management/users/${props.row.userId}`" class="text-nowrap">{{
                          props.row.learnername
                        }}</router-link>
                      </span>
                    </span>

                    <span
                      v-else-if="props.column.field === 'bookingcredits'"
                      v-bind:class="
                        props.row.bookingcredits === 'Trial Booking'
                          ? 'text-danger'
                          : ''"
                    >
                      {{ props.row.bookingcredits }}
                      {{(props.row.bookingcredits === 'Trial Booking' || props.row.bookingcredits === undefined || props.row.bookingcredits == 0) ? '' : 'Credits'}}
                    </span>
                    <span
                      v-else-if="props.column.field === 'action'"
                      class="action_field action_field1516"
                    >
                      <span>
                        <b-dropdown
                          variant="link"
                          toggle-class="text-decoration-none"
                          :disabled="userStatus == 4"
                          no-caret
                        >
                          <template v-slot:button-content>
                            <feather-icon
                              icon="MoreVerticalIcon"
                              size="16"
                              class="text-body align-middle mr-25"
                            />
                          </template>
                          <b-dropdown-item>
                            <span @click="ConfirmBooking(props.row.Id)">
                              <feather-icon icon="CheckSquareIcon" size="18" />

                              Confirm</span
                            >
                          </b-dropdown-item>
                          <b-dropdown-item>
                            <span v-b-modal.modal-reject-sub-booking @click="selctedData = props.row">
                              <feather-icon icon="XCircleIcon" size="18" />
                              Reject</span
                            >
                          </b-dropdown-item>
                        </b-dropdown>
                      </span>
                    </span>
                    <span v-else>
                      {{ props.formattedRow[props.column.field] }}
                    </span>
                  </template>
                    <template slot="pagination-bottom" slot-scope="props">
                      <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                          <span class="text-nowrap"> 
                          <span v-if="props.total === 0"> Showing 0 </span>
                          <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                          to
                          <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                          <span v-else>{{props.total}} of {{ props.total }} entries</span>
                          </span>

                              <div class="d-flex align-items-center mb-1 mt-0 showentry bookingRequest">
                              <span>Show</span>
                              <b-form-select
                                  v-model="pageLength"
                                  :options="['10', '20', '30', '40', '50']"
                                  class="mx-1"
                                  @input="
                                  (value) => props.perPageChanged({ currentPerPage: value })
                                  "
                              />
                              <span>entries</span>
                              </div>
                      </div>
                      <div>
                          <b-pagination
                          :value="1"
                          :total-rows="props.total"
                          :current-page="currentPage"
                          :per-page="pageLength"
                          first-number
                          last-number
                          align="right"
                          prev-class="prev-item"
                          next-class="next-item"
                          class="mt-1 mb-0"
                          @input="
                              (value) => {
                              props.pageChanged({ currentPage: value });
                              currentPage = value
                              }
                          "
                          >
                          <template #prev-text>
                              <feather-icon icon="ChevronLeftIcon" size="18" />
                          </template>
                          <template #next-text>
                              <feather-icon icon="ChevronRightIcon" size="18" />
                          </template>
                          </b-pagination>
                      </div>
                      </div>
                    </template>
                </vue-good-table>
              </div>
      </b-tab>
      <b-modal
        id="modal-reject-sub-booking"
        ref="my-modal"
        title="Rejection Reason"
        ok-title="Reject"
        data-class="addcredit_popup"
        data-value-class="addcredit_popup"
        centered
        cancel-variant="outline-secondary"
        @show="cancelbookingresetModal"
        @hidden="cancelbookingresetModal"
        @ok="cancelbookinghandleOk"
      >
      <validation-observer ref="formCancel">
        <b-form ref="form" @submit.stop.prevent="cancelbookinghandleSubmit">
            <b-form-group class="radioselected" label-for="usertype">
              <b-form-radio
                v-model="RadioSelected"
                name="some-radios"
                value="Not interested in this Session"
              >
                Not interested in this Session
              </b-form-radio>
              <b-form-radio
                v-model="RadioSelected"
                name="some-radios"
                value="Not available"
              >
                Not available
              </b-form-radio>
              <b-form-radio
                v-model="RadioSelected"
                name="some-radios"
                value="Medical Emergency"
              >
                Medical Emergency
              </b-form-radio>
              <b-form-radio
                v-model="RadioSelected"
                name="some-radios"
                value="Family situation"
              >
                Family situation
              </b-form-radio>
              <b-form-radio
                v-model="RadioSelected"
                name="some-radios"
                value="Other"
              >
                Other
              </b-form-radio>
            </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="reason for rejection"
            rules="required|min:3|max:300"
            v-if="RadioSelected === 'Other'"
          >
            <b-form-group
              :state="nameState"
              label-for="name-input"
              invalid-feedback="Reason for Rejection is required"
              v-if="RadioSelected === 'Other'"
            >
            <label style="font-weight:600;">Reason for Rejection</label>
              <b-form-textarea
                id="name-input"
                v-model="cancelReason"
                :state="nameState"
                placeholder="Enter Reason for Rejection"
                required
              />
              <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BSpinner,
  BFormRadio,
  BFormTextarea,
} from "bootstrap-vue";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import { dbCollections } from "@/utils/firebaseCollection.js";
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions,mapMutations } from "vuex";
import moment from 'moment'
import * as insertFirebase from "@/utils/FirebaseQueries/insertQueries/insertQueries.js";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import * as getQuery from "@/utils/FirebaseQueries/getQueries/getQueries.js";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {errorEmail} from "@/utils/apiRequests.js"
import firebase from "@/utils/firebaseInit"
import axios from "axios";
const db = firebase.firestore();
const currentFileName = "src/views/Users/User/UserDetailTabs/BookingRequest_Learner.vue"
import { adminBookingNotification } from '../../../SessionsManagement/PendingSession(s)/service.js';

export default {
    name: "PastSessions",
    directives: {
        Ripple,
    },
    components: {
        VueGoodTable,
        BCard,
        BImg,
        BRow,
        BCol,
        BDropdown,
        BDropdownItem,
        BCardText,
        BTabs,
        BTab,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BForm,
        BButton,
        flatPickr,
        BSpinner,
        BFormRadio,
        BCardText,
        BFormTextarea,
        ValidationProvider, ValidationObserver,
    },
    props:{
        getDataAgain: Function,
        userDetailRole : Array,
        isLearner: Boolean,
        userStatus: Number,
    },
    data() {
      return {
      creditModel: false,
      name: "",
      nameState: null,
      tutorsrows:[],
      tutorslist:[],
      childlistrows:[],
      canceledsessionsrows:[],
      pastsessionsearchTerm:'',
      bookingRequestArray:[],
      tutor_name_options:[],
      dateNtim:[],
      sessionsearchTerm:'',
      sessioncolumns:[],
      sessionsearchTerm:'',
      interestedsearchTerm:'',
      interestedsrows:[],
      sessionsrows:[],
      searchTerm:'',
      reviewrows:[],
      statusVariant:'',
      usersReview:[],
      tabIndex: 0,
      direction: false,
      isSpinner: false,
      isUpdating: false,

      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      user_type_selected: null,
      user_type_options: [
        { value: null, text: "All" },
        { value: "a", text: "Learner" },
        { value: "b", text: "Child" },
        { value: "c", text: "Tutor" },
      ],
      tutor_type_selected: "All",
      tutor_type_options: [],
      status_optionsForTutorList: [],
      status_selected: null,
      status_selectedForTutorList: "All",
      pageLength: 10,
      dir: false,
      status_selected: null,
      interestedcolumns: [
        {
          label: "Category Name",
          field: "categoryname",
        },
      ],
      bookingreqsearchTerm: "",
     
      canceledsessionsearchTerm: "",
      sessionsdateNtim:"",
      canceledsessionstatus: [
        {
          1: "Pending",
          2: "Approved",
          3: "Warning",
          4: "Suspend",
        },
        {
          1: "light-warning",
          2: "light-success",
          3: "light-primary",
          4: "light-danger",
        },
      ],
      readMore: false,
      review_tutor_name_options: [],
      historysearchTerm: [],
      historysrows: [],
      historycolumns: [],
      historysearchTerm: "",
      dateNtimHistory: "",
      allsessionsearchTerm: "",
      allsessionrows: [],
      allsessioncolumns: [],
      userStatusName: "",
      categoryname_options: [],
      subcategoriessearchTerm: "",
      subcategoriesrows: [],
      subcategoriescolumns: [],
      subcategoriessearchTerm: "",
      categoriessearchTerm: "",
      categoriesrows: [],
      categoriescolumns: [],
      categoriessearchTerm: "",
      pastsessiondateNtim: "",
      bookingreqdateNtim:  moment(new Date()).format("YYYY-MM-DD hh:mm:ss:A"),
      categoryname_options: [],
      subcategoriessearchTerm: "",
      subcategoriesrows: [],
      subcategoriescolumns: [],
      subcategoriessearchTerm: "",
      userDetail:{},
      tutor_type_selected_learner:"All",
      bookedRequestArray : [],
      bookingRequestArray:[],
      bookingreqcolumns: [
        {
          label: "Booking ID",
          field: "bookingid",
        },
        {
          label: "Session Booking Date & Time",
          field: "sessionbookingtime",
        },
        {
          label: "Session(s) Title",
          field: "sessiontitle",
        },
        {
          label: "Learner Name",
          field: "learnername",
        },
        {
          label: "Booking Credits",
          field: "bookingcredits",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      confirmBooked:false,
      RadioSelected: "Not interested in this Session",
      cancelReason:"",
      selctedData:null,
      };
    },
    watch:{
        userDetailRole(newV , preV){
            if(newV !== preV && newV !== null){
                this.bookingreqrows();
            }
        },
        isLearner(newV , preV){
            if(newV !== preV){
                this.bookingreqrows();
            }
        }
    },
    computed: {
      ...mapGetters({
        getBookinglist : 'booking/getBooking',
        getUsersList:'userManagement/getUsers',
      }),
      // CALL FOR GET BOOKING REQUEST DATA(Shraddha)
      filterRow(){
        var self = this;
        var listA = self.bookingRequestArray;
            if(self.confirmBooked){
              self.confirmBooked = false;
              self.isSpinner = false;
              self.bookingRequestArray = self.bookedRequestArray;
              var listA = self.bookingRequestArray;
            }else{
              var date1=moment(new Date(self.bookingreqdateNtim)).set({hour:0,minute:0,second:0,millisecond:0}).format();
              var date2=moment(new Date(self.bookingreqdateNtim)).set({hour:new Date(self.bookingreqdateNtim).getHours(),minute:new Date(self.bookingreqdateNtim).getMinutes(),second:new Date(self.bookingreqdateNtim).getSeconds(),millisecond:59}).format();
              if (self.bookingreqdateNtim!='') {
                listA = listA.filter((data) => {
                   return new Date(data.endTime.seconds*1000) < new Date(date2) && new Date(data.endTime.seconds*1000) > new Date(date1)
                });
              }
              self.bookedRequestArray = listA;
              self.isSpinner = false;
            }
            return listA;
      }
    },
  
    methods: {
      onCellClick(data) {
        if (data.column.field==="learnername") {
          this.$router.push({path: `/user-management/users/${data.row.userId}`})
          this.getDataAgain();
        } else if(data.column.field==="action") {
          return;
        } else {
          this.$router.push({name: 'booking-detail-pending', params: { id: data.row.Id }})
        }
      },
       ...mapMutations({
        'removeBooking': "booking/spliceBooking",
      }),
      ...mapActions({
        'getBooking' : 'booking/getBookingData',
      }),
      limitTo: function (value) {
          if (!value) return "";
          return value.substring(0, 90) + "...";
      },
      sessionTimeCalculate(startTime , endTime) {
        if (moment(startTime.seconds*1000).format("DD-MM-YYYY") == moment(endTime.seconds*1000).format("DD-MM-YYYY")) {
          return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("hh:mm A")}`
        } else {
          return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")}`
        }
      },
      bookingreqrows() {
          try{
            this.bookingRequestArray = []; 
            if (this.userDetailRole!== undefined &&this.userDetailRole.includes('learner')&&(this.userDetailRole.includes('tutor'))) { 
                if (!this.isLearner) { 
                    this.isSpinner = true;
                    getQuery.simpleGetDataWithSpecificTwoWhereCondition(dbCollections.BOOKING,'learnerId','==',this.$route.params.id,'status','==',0,(session)=>{
                        if(session.length === 0){
                            this.isSpinner = false;
                            return;
                        } 
                        else{
                            session.forEach(element => {
                                this.count = +1;
                                let object = {
                                    ...element,
                                    Id : element.id,
                                    bookingid  : element.bookingId ? element.bookingId : "",
                                    sessionbookingtime : (element.endTime && element.startTime) ? this.sessionTimeCalculate(element.startTime, element.endTime): "",
                                    sessiontitle :element.sessionDetails ? element.sessionDetails.name : "",
                                    learnername: element.tutorDetails  ? element.tutorDetails.firstName + " " + element.tutorDetails.lastName : "",
                                    avtar: element.tutorDetails.profilePicture ? element.tutorDetails.profilePicture : "",
                                    bookingcredits: element.usedCredits ? element.usedCredits : 0,
                                    userId : element ?  element.tutor : "",
                                    cancellationreason : element.cancellationReason,
                                    learnerId : element.learnerId,
                                    learnerDetails: element.learnerDetails,
                                    endTime:element.endTime,
                                    startTime:element.startTime,
                                    action: ["Confirm", "Reject"],
                                } 
                                this.bookingRequestArray.push(object)         
                            });
                            if(session.length === this.bookingRequestArray.length){
                                this.isSpinner = false;
                            }
                        }
                    })
                    this.bookingreqcolumns[3].label = "Tutor Name"
                }
                else{
                    this.isSpinner = true;
                    getQuery.simpleGetDataWithSpecificTwoWhereCondition(dbCollections.BOOKING,'tutor','==',this.$route.params.id,'status','==',0,(session)=>{
                        if(session.length === 0){
                            this.isSpinner = false;
                            return;
                        } 
                        else{     
                            session.forEach( (element) => {
                                this.count = +1;
                                let object = {
                                    ...element,
                                    Id : element.id,
                                    bookingid  : element.bookingId ? element.bookingId : "",
                                    sessionbookingtime : (element.endTime && element.startTime) ? this.sessionTimeCalculate(element.startTime, element.endTime) : "",
                                    sessiontitle :element.sessionDetails ? element.sessionDetails.name : "",
                                    learnername: element.learnerDetails  ? element.learnerDetails.firstName + " " + element.learnerDetails.lastName : "",
                                    avtar: element.learnerDetails.profileImage ? element.learnerDetails.profileImage : "",
                                    bookingcredits: element.usedCredits ? element.usedCredits : 0,
                                    userId : element ?  element.learnerId : "",
                                    cancellationreason : element.cancellationReason,
                                    learnerId : element.learnerId,
                                    learnerDetails: element.learnerDetails,
                                    endTime:element.endTime,
                                    startTime:element.startTime,
                                    action: ["Confirm", "Reject"],
                                }
                                this.bookingRequestArray.push(object);        
                                if(session.length === this.bookingRequestArray.length){
                                    this.isSpinner = false;
                                }
                            });
                        }
                    })   
                    this.bookingreqcolumns[3].label = "Learner Name"
                }    
            }
            else if (this.userDetailRole!== undefined &&this.userDetailRole.includes('learner')) { 
                this.isSpinner = true;
                getQuery.simpleGetDataWithSpecificTwoWhereCondition(dbCollections.BOOKING,'learnerId','==',this.$route.params.id,'status','==',0,(session)=>{
                    if(session.length === 0){
                            this.isSpinner = false;
                            return;
                    } 
                    else{
                        session.forEach(element => {
                            this.count = +1;
                            let object = {
                                ...element,
                                Id : element.id,
                                bookingid  : element.bookingId ? element.bookingId : "",
                                sessionbookingtime : (element.endTime && element.startTime) ? this.sessionTimeCalculate(element.startTime, element.endTime) : "",
                                sessiontitle :element.sessionDetails ? element.sessionDetails.name : "",
                                learnername: element.tutorDetails  ? element.tutorDetails.firstName + " " + element.tutorDetails.lastName : "",
                                avtar: element.tutorDetails.profilePicture ? element.tutorDetails.profilePicture : "",
                                bookingcredits: element.usedCredits ? element.usedCredits : 0,
                                userId : element ?  element.tutor : "",
                                cancellationreason : element.cancellationReason,
                                learnerId : element.learnerId,
                                learnerDetails: element.learnerDetails,
                                endTime:element.endTime,
                                startTime:element.startTime,
                                action: ["Confirm", "Reject"],
                            } 
                            this.bookingRequestArray.push(object)         
                            if(session.length === this.bookingRequestArray.length){
                                    this.isSpinner = false;
                            }
                        });
                    }
                }) 
                this.bookingreqcolumns[3].label = "Tutor Name"
            }
            else if (this.userDetailRole!== undefined &&this.userDetailRole.includes('tutor')) { 
                this.isSpinner = true;
                getQuery.simpleGetDataWithSpecificTwoWhereCondition(dbCollections.BOOKING,'tutor','==',this.$route.params.id,'status','==',0,(session)=>{
                    if(session.length === 0){
                            this.isSpinner = false;
                            return;
                    }
                    else{
                        session.forEach(element => {
                            this.count = +1;
                            let object = {
                                ...element,
                                Id : element.id,
                                bookingid  : element.bookingId ? element.bookingId : "",
                                sessionbookingtime : (element.endTime && element.startTime) ? this.sessionTimeCalculate(element.startTime, element.endTime) : "",
                                sessiontitle :element.sessionDetails ? element.sessionDetails.name : "",
                                learnername: element.learnerDetails  ? element.learnerDetails.firstName + " " + element.learnerDetails.lastName : "",
                                avtar: element.learnerDetails.profileImage ? element.learnerDetails.profileImage : "",
                                bookingcredits: element.usedCredits ? element.usedCredits : 0,
                                userId : element ?  element.learnerId : "",
                                cancellationreason : element.cancellationReason,
                                learnerId : element.learnerId,
                                learnerDetails: element.learnerDetails,
                                endTime:element.endTime,
                                startTime:element.startTime,
                                action: ["Confirm", "Reject"],
                            } 
                            this.bookingRequestArray.push(object)         
                            if(session.length === this.bookingRequestArray.length){
                                this.isSpinner = false;
                            }
                        });
                    } 
                }) 
                this.bookingreqcolumns[3].label = "Learner Name"
            }
            const compareObjects = (object1, object2, key) => {
                const obj1 = object1[key];
                const obj2 = object2[key];
                if (obj1 > obj2) {
                  return -1
                }
                if (obj1 < obj2) {
                  return 1
                }
                return 0
              }
            this.bookingRequestArray.sort((book1, book2) => {
              return compareObjects(book1, book2, 'createdAt');
            });
        }catch (error) {
            console.error(error)
                    this.isSpinner = false;
            errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "bookingreqrows",
                errorMessage : error.message
                },(res)=>{
                if(res.error){
                    console.error(res.error);
                }
            })
        }
      },
      //CALL WHENEVER BOOKING IS CONFIRMED (Shraddha)
      ConfirmBooking(id){
        var self = this;
        self.$bvModal
        .msgBoxConfirm('Are you sure you want to confirm this booking request?', {
          title: 'Confirm Booking Request',         
          class:'test',
          okVariant: 'primary',
          okTitle: 'Confirm',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
          footerClass: 'approve-footer',
          headerClass: 'approve-header',
          bodyClass: 'approve-body approverequest',
        })
        .then(value => {
          if(value){ 
              self.isSpinner = true;
              self.isSpinner = true;
              const updateObject = {
                status :1,
                isApprovedByTutor:true,
                isApprovedByLearner:true
              }
              updateFirebase.updateRootCollectionDataByDocId(dbCollections.BOOKING,id,updateObject,(res) => {
                self.$root.$emit('showToastMessage','Booking confirmed successfully.','success');
                var index = self.bookedRequestArray.findIndex(item => {
                  return item.Id == id  
                })
                  adminBookingNotification({
                    bookingData : {...self.bookedRequestArray[index], status:1}
                  }, () => {});
                if(index != -1){
                  self.confirmBooked = true;
                  self.bookedRequestArray.splice(index, 1)
                  self.isSpinner = false;
                }
              })
          }
        })
      },
    cancelbookingresetModal() {
      this.name = "";
      this.nameState = null;
    },
    cancelbookinghandleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.cancelbookinghandleSubmit(bvModalEvt);   
    },
    cancelbookinghandleSubmit() {
      try {
        this.$refs.formCancel.validate().then(async(valid)=>{
        if(valid){
          this.$bvModal.hide("modal-reject-sub-booking");
          this.isSpinner = true;
          try {
            await db.collection(dbCollections.CREDITTRANSACTIONS)
                    .where("userId","==",this.selctedData.learnerId)
                    .orderBy("createdAt", "desc")
                    .limit(1)
                    .get()
                    .then(async(docData) => {
                      if(docData.empty){
                      }else{
                          let sengleBookingData = docData.docs[0].data();                                         
                          const updateObj = {
                            currentCredit : Number(sengleBookingData.availableCredit) + Number(this.selctedData.bookingcredits)
                          }
                          await updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,this.selctedData.learnerId,updateObj,async(res) => {
                            if(!res){
                              return;
                            }
                            const learnerDeatil = {
                              firstName : this.selctedData.learnerDetails.firstName,
                              lastName :this.selctedData.learnerDetails.lastName,
                              profileImage: this.selctedData.learnerDetails.profileImage
                            }
                            const transactionObject = {
                              availableCredit:Number(sengleBookingData.availableCredit) + Number(this.selctedData.bookingcredits),
                              bookingId:this.selctedData.bookingid,
                              createdAt:new Date(),
                              credits:Number(this.selctedData.bookingcredits),
                              id:'',
                              paymentId:'',// This feild is static
                              reference:1,
                              remarks:'Booking cancelled.',
                              transactionType:"cr",
                              userDetail:learnerDeatil,
                              userId: this.selctedData.learnerId,
                              isSendNotification: false
                            }
                            insertFirebase.addDataToRootCollection(dbCollections.CREDITTRANSACTIONS,transactionObject,async(credit) => {
                              if(credit){
                                const updateObjCredit = {
                                  id : credit.id
                                }
                                await updateFirebase.updateRootCollectionDataByDocId(dbCollections.CREDITTRANSACTIONS,credit.id,updateObjCredit,async(creditUpdate) => {
                                    const cancelreasondetail = {
                                    cancellationReason: this.RadioSelected === "Other"? this.cancelReason : this.RadioSelected,
                                    status: 2,
                                    cancellationDate: new Date(),
                                    updatedAt: new Date(),
                                    usedCredits: 0
                                  };
                                  updateFirebase.updateRootCollectionDataByDocId(
                                    dbCollections.BOOKING,
                                    this.selctedData.Id,
                                    cancelreasondetail,
                                    async(res) => {
                                      if(!res){
                                        return;
                                      }
                                      var index = this.bookedRequestArray.findIndex(item => {
                                        return item.Id ==  this.selctedData.Id  
                                      })
                                      adminBookingNotification({
                                        bookingData : {...this.bookedRequestArray[index], status:2, cancellationReason: this.RadioSelected === "Other"? this.cancelReason : this.RadioSelected}
                                      }, () => {});

                                      if(index != -1){
                                        this.confirmBooked = true;
                                        this.bookedRequestArray.splice(index, 1)
                                        this.isSpinner = false;
                                      }
                                      this.$root.$emit(
                                        "showToastMessage",
                                        "Rejected session successfully.",
                                        "success"
                                      );
                                    });
                                })
                              }
                            })
                          })  
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    })
            } catch (error) {
              console.error("error", error);
              this.isUpdating = false
              errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "cancelbookinghandleSubmit",
              errorMessage : error.message
            },(mailRes) => {
              if(mailRes.error){
                console.error(mailRes);
              }
            })
            }
          }
        })
      } catch (error) {
        console.error("error",error)
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "cancelbookinghandleSubmit",
          errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
      }
    },
      // CALL WHEN BOOKING REQUEST TAB IS CLICKED (Shraddha)
      onBookingRequset(){
          var self = this;
          var allTabList = document.getElementsByClassName('nav-link active')
          if (allTabList[0].innerText != 'Booking Request') { 
              self.getBooking().then(() => {
                self.bookingreqrows();
              });
          }
      }
    }
}

</script>