<template>
<div>
  <b-spinner
    variant="primary"
    v-if="isLoading"
    large
    class="pageLoadSpinners loadSpinner"
  />
  <div v-show="!isLoading">
  <b-tab title="Availability Time" @click="onAvaibilityTime()">
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners loaderDetail"
      style="z-index: 999999; margin-left: -160px; margin-top: 350px"
    />
    <div class="bank_detail_block">
      <b-row>
        <b-col lg="12">
          <b-row class="align-items-center">
            <b-col lg="6">
              <h4 class="mb-0">Monday</h4>
            </b-col>
            <b-col lg="6" class="text-right">
              <b-button
                variant="outline-primary"
                to="#"
                class="bank_edit_round"
                style="visibility: hidden"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="12">
          <hr />
        </b-col>
        <div
          style="display: contents"
          v-show="mondayTimes != undefined && mondayTimes.length != 0"
          v-for="(item, index) in mondayTimes"
          :key="index"
        >
          <b-col xl="2" md="4">
            <h5>Start Time</h5>
            <p>{{ item.startTime }}</p>
          </b-col>
          <b-col xl="2" md="4">
            <h5>End Time</h5>
            <p>{{ item.endTime }}</p>
          </b-col>
        </div>
        <div
          style="display: contents"
          v-show="mondayTimes == undefined || mondayTimes.length == 0"
        >
          <b-col xl="2" md="4">
            <p class="recordnotfound">No record found</p>
          </b-col>
          <b-col xl="2" md="4"></b-col>
        </div>
      </b-row>
    </div>
    <div class="bank_detail_block">
      <b-row>
        <b-col lg="12">
          <b-row class="align-items-center">
            <b-col lg="6">
              <h4 class="mb-0">Tuesday</h4>
            </b-col>
            <b-col lg="6" class="text-right">
              <b-button
                variant="outline-primary"
                to="#"
                class="bank_edit_round"
                style="visibility: hidden"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="12">
          <hr />
        </b-col>
        <div
          style="display: contents"
          v-show="tuesdayTimes != null && tuesdayTimes.length != 0"
          v-for="(item, index) in tuesdayTimes"
          :key="index"
        >
          <b-col xl="2" md="4">
            <h5>Start Time</h5>
            <p>{{ item.startTime }}</p>
          </b-col>
          <b-col xl="2" md="4">
            <h5>End Time</h5>
            <p>{{ item.endTime }}</p>
          </b-col>
        </div>
        <div
          style="display: contents"
          v-show="tuesdayTimes == null || tuesdayTimes.length == 0"
        >
          <b-col xl="2" md="4">
            <p class="recordnotfound">No record found</p>
          </b-col>
          <b-col xl="2" md="4"></b-col>
        </div>
      </b-row>
    </div>
    <div class="bank_detail_block">
      <b-row>
        <b-col lg="12">
          <b-row class="align-items-center">
            <b-col lg="6">
              <h4 class="mb-0">Wednesday</h4>
            </b-col>
            <b-col lg="6" class="text-right">
              <b-button
                variant="outline-primary"
                to="#"
                class="bank_edit_round"
                style="visibility: hidden"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="12">
          <hr />
        </b-col>
        <div
          style="display: contents"
          v-show="wednesDayTime != null && wednesDayTime.length != 0"
          v-for="(item, index) in wednesDayTime"
          :key="index"
        >
          <b-col xl="2" md="4">
            <h5>Start Time</h5>
            <p>{{ item.startTime }}</p>
          </b-col>
          <b-col xl="2" md="4">
            <h5>End Time</h5>
            <p>{{ item.endTime }}</p>
          </b-col>
        </div>
        <div
          style="display: contents"
          v-show="wednesDayTime == null || wednesDayTime.length == 0"
        >
          <b-col xl="2" md="4">
            <p class="recordnotfound">No record found</p>
          </b-col>
          <b-col xl="2" md="4"></b-col>
        </div>
      </b-row>
    </div>
    <div class="bank_detail_block">
      <b-row>
        <b-col lg="12">
          <b-row class="align-items-center">
            <b-col lg="6">
              <h4 class="mb-0">Thursday</h4>
            </b-col>
            <b-col lg="6" class="text-right">
              <b-button
                variant="outline-primary"
                to="#"
                class="bank_edit_round"
                style="visibility: hidden"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="12">
          <hr />
        </b-col>
        <div
          style="display: contents"
          v-show="thursDayTime != null && thursDayTime.length != 0"
          v-for="(item, index) in thursDayTime"
          :key="index"
        >
          <b-col xl="2" md="4">
            <h5>Start Time</h5>
            <p>{{ item.startTime }}</p>
          </b-col>
          <b-col xl="2" md="4">
            <h5>End Time</h5>
            <p>{{ item.endTime }}</p>
          </b-col>
        </div>
        <div
          style="display: contents"
          v-show="thursDayTime == null || thursDayTime.length == 0"
        >
          <b-col xl="2" md="4">
            <p class="recordnotfound">No record found</p>
          </b-col>
          <b-col xl="2" md="4"></b-col>
        </div>
      </b-row>
    </div>
    <div class="bank_detail_block">
      <b-row>
        <b-col lg="12">
          <b-row class="align-items-center">
            <b-col lg="6">
              <h4 class="mb-0">Friday</h4>
            </b-col>
            <b-col lg="6" class="text-right">
              <b-button
                variant="outline-primary"
                to="#"
                class="bank_edit_round"
                style="visibility: hidden"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="12">
          <hr />
        </b-col>
        <div
          style="display: contents"
          v-show="fridayTime != null && fridayTime.length != 0"
          v-for="(item, index) in fridayTime"
          :key="index"
        >
          <b-col xl="2" md="4">
            <h5>Start Time</h5>
            <p>{{ item.startTime }}</p>
          </b-col>
          <b-col xl="2" md="4">
            <h5>End Time</h5>
            <p>{{ item.endTime }}</p>
          </b-col>
        </div>
        <div
          style="display: contents"
          v-show="fridayTime == null || fridayTime.length == 0"
        >
          <b-col xl="2" md="4">
            <p class="recordnotfound">No record found</p>
          </b-col>
          <b-col xl="2" md="4"></b-col>
        </div>
      </b-row>
    </div>
    <div class="bank_detail_block">
      <b-row>
        <b-col lg="12">
          <b-row class="align-items-center">
            <b-col lg="6">
              <h4 class="mb-0">Saturday</h4>
            </b-col>
            <b-col lg="6" class="text-right">
              <b-button
                variant="outline-primary"
                to="#"
                class="bank_edit_round"
                style="visibility: hidden"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="12">
          <hr />
        </b-col>
        <div
          style="display: contents"
          v-show="saturdayTime != null && saturdayTime.length != 0"
          v-for="(item, index) in saturdayTime"
          :key="index"
        >
          <b-col xl="2" md="4">
            <h5>Start Time</h5>
            <p>{{ item.startTime }}</p>
          </b-col>
          <b-col xl="2" md="4">
            <h5>End Time</h5>
            <p>{{ item.endTime }}</p>
          </b-col>
        </div>
        <div
          style="display: contents"
          v-show="saturdayTime == null || saturdayTime.length == 0"
        >
          <b-col xl="2" md="4">
            <p class="recordnotfound">No record found</p>
          </b-col>
          <b-col xl="2" md="4"></b-col>
        </div>
      </b-row>
    </div>
    <div class="bank_detail_block">
      <b-row>
        <b-col lg="12">
          <b-row class="align-items-center">
            <b-col lg="6">
              <h4 class="mb-0">Sunday</h4>
            </b-col>
            <b-col lg="6" class="text-right">
              <b-button
                variant="outline-primary"
                to="#"
                class="bank_edit_round"
                style="visibility: hidden"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col lg="12">
          <hr />
        </b-col>
        <div
          style="display: contents"
          v-show="sundayTime != null && sundayTime.length != 0"
          v-for="(item, index) in sundayTime"
          :key="index"
        >
          <b-col xl="2" md="4">
            <h5>Start Time</h5>
            <p>{{ item.startTime }}</p>
          </b-col>
          <b-col xl="2" md="4">
            <h5>End Time</h5>
            <p>{{ item.endTime }}</p>
          </b-col>
        </div>
        <div
          style="display: contents"
          v-show="sundayTime == null || sundayTime.length == 0"
        >
          <b-col xl="2" md="4">
            <p class="recordnotfound">No record found</p>
          </b-col>
          <b-col xl="2" md="4"></b-col>
        </div>
      </b-row>
    </div>
  </b-tab>
  </div>
</div>
</template>
<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import { dbCollections } from "@/utils/firebaseCollection.js";
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Users/User/UserDetailTabs/AvailableTime_Learner.vue"
export default {
  name: "PastSessions",
  directives: {
    Ripple,
  },
  components: {
    VueGoodTable,
    BCard,
    BImg,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BCardText,
    BTabs,
    BTab,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    flatPickr,
    BSpinner,
  },
  data() {
    return {
      sessionsearchTerm: "",
      sessionsearchTerm: "",
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      userData: null,

      mondayTimes: [],
      tuesdayTimes: [],
      wednesDayTime: [],
      thursDayTime: [],
      fridayTime: [],
      saturdayTime: [],
      sundayTime: [],
      isSpinner: false,
      isLoading: false
    };
  },
  created() {},
  methods: {
    limitTo: function (value) {
      if (!value) return "";
      return value.substring(0, 90) + "...";
    },
    getData() {
      var self = this;
      try {
        self.isLoading = true;
        self.cancelrows = [];
        getFirebase.simpleGetDataFromSubCollectionWithDocID(
          dbCollections.USERS,
          this.$route.params.id,
          (res) => {
            if (res && Object.keys(res).length > 0) {
              self.userData = res;
              const getTImeManageData = new Promise((resolve, reject) => {
                try {
                  let avail_time = [];
                  if (
                    this.userData !== null &&
                    this.userData.tutorTimeManagement !== undefined
                  ) {
                    avail_time = this.userData.tutorTimeManagement;
                    self.isLoading = false;
                  }
                  resolve({ status: true, data: avail_time });
                  self.isLoading = false;
                } catch (error) {
                  reject({ status: false, data: error });
                  self.isLoading = false;
                  errorEmail({
                  projectName : process.env.VUE_APP_PROJECTNAME,
                  environment : process.env.NODE_ENV,
                  fileName : currentFileName,
                  hookName : "getData",
                  errorMessage : error.message
                  },(res)=>{
                    if(res.error){
                        console.error(res.error)
                    }
                  }) 
                }
              });
              getTImeManageData
                .then((value) => {
                  try {
                    if (value.status == true) {
                      try {
                        self.isLoading = true;
                        this.mondayTimes = [];
                        this.tuesdayTimes = [];
                        this.wednesDayTime = [];
                        this.thursDayTime = [];
                        this.fridayTime = [];
                        this.saturdayTime = [];
                        this.sundayTime = [];
                        let timeManageArray = value.data;
                        if (timeManageArray.length > 0) {
                          for (let i = 0; i <= timeManageArray.length; i += 1) {
                            if (i >= timeManageArray.length) {
                              break;
                            }
                            let element = timeManageArray[i];
                            if (element.isEnabled == true) {
                              for (
                                let j = 0;
                                j <= element.timeSlots.length;
                                j += 1
                              ) {
                                if (j >= element.timeSlots.length) {
                                  break;
                                }
                                let slotItem = element.timeSlots[j];
                                let obj = {
                                  startTime: moment(
                                    new Date(slotItem.from.seconds * 1000)
                                  ).format("hh:mm A"),
                                  startDate: new Date(
                                    slotItem.from.seconds * 1000
                                  ),
                                  endTime: moment(
                                    new Date(slotItem.to.seconds * 1000)
                                  ).format("hh:mm A"),
                                  endDate: new Date(slotItem.to.seconds * 1000),
                                };
                                if (element.dayId == 0) {
                                  this.sundayTime.push(obj);
                                }
                                if (element.dayId == 1) {
                                  this.mondayTimes.push(obj);
                                }
                                if (element.dayId == 2) {
                                  this.tuesdayTimes.push(obj);
                                }
                                if (element.dayId == 3) {
                                  this.wednesDayTime.push(obj);
                                }
                                if (element.dayId == 4) {
                                  this.thursDayTime.push(obj);
                                }
                                if (element.dayId == 5) {
                                  this.fridayTime.push(obj);
                                }
                                if (element.dayId == 6) {
                                  this.saturdayTime.push(obj);
                                }
                              }
                            }
                          }
                        }
                        self.isLoading = false;
                      } catch (error) {
                        self.isLoading = false;
                        console.error(error);
                        errorEmail({
                        projectName : process.env.VUE_APP_PROJECTNAME,
                        environment : process.env.NODE_ENV,
                        fileName : currentFileName,
                        hookName : "getData",
                        errorMessage : error.message
                        },(res)=>{
                          if(res.error){
                              console.error(res.error)
                          }
                        }) 
                      }
                    }
                  } catch (error) {
                    self.isLoading = false;
                    console.error(error);
                    errorEmail({
                        projectName : process.env.VUE_APP_PROJECTNAME,
                        environment : process.env.NODE_ENV,
                        fileName : currentFileName,
                        hookName : "getData",
                        errorMessage : error.message
                        },(res)=>{
                          if(res.error){
                              console.error(res.error)
                          }
                        }) 
                  }
                })
                .catch((error) => {
                  self.isLoading = false;
                  console.error(error, "ERROR");
                });
            } else {
              self.isLoading = false;
            }
          }
        );
      } catch (error) {
        console.error("error", error);
        errorEmail({
        projectName : process.env.VUE_APP_PROJECTNAME,
        environment : process.env.NODE_ENV,
        fileName : currentFileName,
        hookName : "getData",
        errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        }) 
      }
    },
    onAvaibilityTime() {
      this.getData();
    },
  },
  computed: {},
};
</script>