<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
    </div>

    <div class="card user_filter all_session">
      <h2>Filters</h2>

      <b-form class="dropdownfilter" @submit.prevent>
        <b-row>
          <b-col xl="3" lg="3" md="3">
            <label>Learner/Child</label>
            <b-dropdown
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :text="learner_selected"
              variant="outline-secondary"
              class="w-100"
            >
              <b-dropdown-item
                v-for="item in learner_name_options"
                :key="item.value"
                @click="learner_selected = item.text"
                >{{ item.text }}</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
          <b-col xl="3" lg="3" md="3">
            <label>Tutor</label>
            <b-dropdown
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :text="byTutorFiltered"
              variant="outline-secondary"
              class="w-100"
            >
              <b-dropdown-item
                v-for="item in tutor_name_options"
                :key="item.value"
                @click="byTutorFiltered = item.text"
                >{{ item.text }}</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>
      </b-form>
    </div>

    <div class="card user_table complaint_learner_table">
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="complaintlearnersearchTerm"
              placeholder="Search..."
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <vue-good-table
        :columns="complaintlearnercolumns"
        :rows="tutorComplaintList"
        :rtl="direction"
        class="cursor_pointer"
        :search-options="{
          enabled: true,
          externalQuery: complaintlearnersearchTerm,
        }"
        :select-options="{
          enabled: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.field === 'tutorFullName'"
            class="text-nowrap"
          >
            <b-avatar :src="props.row.tutorProfile" class="user_img" />
            <span class="userid">
              <router-link :to="{ name: 'user-details', params: { id: props.row.tutorId} }">{{
               props.row.tutorFullName
              }}</router-link>
            </span>
          </span>
          <span v-if="props.column.field === 'bookingid'" class="text-nowrap userid">
            <span v-if="props.row.bookingid !== undefined && props.row.bookingid !== '' ">
              <router-link  :to="{ name: 'booking-detail-complaint-learner', params: { id: props.row.bookingDocId}}">{{
               props.row.bookingid
              }}</router-link>
            </span>
            <span v-else>N/A</span>
          </span>
          <span v-if="props.column.field === 'complaintId'" class="text-nowrap">
            <span class="userid">
              {{ props.row.complaintId }}
            </span>
          </span>
          <span
            v-if="props.column.field === 'learnerFullName'"
            class="text-nowrap"
          >
            <b-avatar :src="props.row.learnerProfile" class="user_img" />
            <span class="userid">
              <router-link :to="{ name: 'user-details', params: { id: props.row.learnerId} }">{{
               props.row.learnerFullName
              }}</router-link>
            </span>
          </span>
            <div
                v-else-if="props.column.field === 'complaintReason'"
                class="wrap-break-word"
                v-show="true"
                >
                <span v-html="limitTo(props.row.complaintReason)"></span>
                <a
                    class="readmore"
                    v-b-modal="'readmore' + props.row.complaintId.toString()"
                    v-show="!props.row['isEditable'] && props.row.complaintReason.length >= 90"
                    
                    >Read more</a
                >
                <UserModal title="Cancel Reason" :text="props.row.complaintReason" :id="props.row.complaintId.toString()" />
          </div>
          <span
            v-else-if="props.column.field === 'action'"
            class="action_field action_field1519 paddingremove"
          >
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item 
                  v-if="props.row.tutorUserStatus !== 3"
                  v-b-modal.modal-block
                  @click="selectedRowElement = props.row"
                >
                  <span>
                    <feather-icon icon="XCircleIcon" size="24"/> 
                    Block Tutor
                  </span>
                </b-dropdown-item>
                <b-dropdown-item 
                  v-else-if="props.row.tutorUserStatus === 3"
                  v-b-modal.modal-unblock
                  @click="selectedRowElement = props.row"
                >
                  <span>
                    <feather-icon icon="XCircleIcon" size="24"/> 
                    Active Tutor
                  </span>
                </b-dropdown-item>
                <b-dropdown-item 
                  v-if="props.row.tutorUserStatus !== 3 && props.row.tutorUserStatus !== 2"
                  v-b-modal.modal-suspend
                  @click="selectedRowElement = props.row"
                >
                  <span>
                    <feather-icon icon="CheckSquareIcon" size="24" /> 
                    Suspend Tutor
                  </span>
                </b-dropdown-item>
                <b-dropdown-item 
                  v-if="(getsettingList.userMaxWarningsToGetSuspend > props.row.tutorWarnings) && 
                  (props.row.tutorUserStatus !== 3 && props.row.tutorUserStatus !== 2)" 
                  v-b-modal.modal-warning 
                  @click="selectedRowElement = props.row"
                >
                  <span>
                    <feather-icon icon="AlertTriangleIcon" size="24"/> 
                    Give Warning
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field].txt }}
          </span>
        </template>
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap">
                  <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to 
                  <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                  <span v-else>{{props.total}} of {{ props.total }} entries</span>
                </span>

                    <div class="d-flex align-items-center mb-1 mt-0 showentry">
                      <span>Show</span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10', '20', '30', '40', '50']"
                        class="mx-1"
                        @input="
                          (value) => props.perPageChanged({ currentPerPage: value })
                        "
                     />
                      <span>entries</span>
                    </div>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :current-page="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => {
                      props.pageChanged({ currentPage: value });
                      currentPage = value
                    }
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
        </template>
      </vue-good-table>
    </div>
    <b-modal
      id="modal-warning"
      centered
      title=""
      ok-title="Yes"
      cancel-title="No"
      @ok="warningUserButtonHandler"
    >
      <img
        src="@/assets/images/tutorimages/warning.png"
        alt="profile"
        class="warning_image"
      />
      <h4>Give Warning</h4>
      <b-card-text class="approve-body12">
        Are you sure you want to Give Warning? 
        <span v-if="selectedRowElement && selectedRowElement.tutorWarnings > 0">
          You had already given {{selectedRowElement.tutorWarnings}} warning before.
        </span>
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-suspend"
      centered
      title=""
      ok-title="Yes"
      cancel-title="No"
      @ok="suspendUserButtonHandler"
    >
      <img
        src="@/assets/images/erflog/cancel.png"
        alt="profile"
        class="warning_image"
      />
      <h4>Suspend User</h4>
      <b-card-text class="approve-body12 suspendLine">
        Are you sure you want to Suspend User? This will cancel all future tutor session and give students their credits back.
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-block"
      centered
      title=""
      ok-title="Yes"
      cancel-title="No"
      @ok="blockUserButtonHandler"
    >
      <img
        src="@/assets/images/erflog/cancel.png"
        alt="profile"
        class="warning_image"
      />
      <h4>Block User</h4>
      <b-card-text class="approve-body12">
        Are you sure you want to Block User? They won't be able to access Tutorline anymore.
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-unblock"
      centered
      title=""
      ok-title="Yes"
      cancel-title="No"
      @ok="unblockUserButtonHandler"
    >
      <img
        src="@/assets/images/erflog/cancel.png"
        alt="profile"
        class="warning_image"
      />
      <h4>Unblock User</h4>
      <b-card-text>
          Unblock User? They will be able to access Tutorline.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BSpinner,
  BCardText
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { mapGetters } from "vuex";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import { dbCollections } from '@/utils/firebaseCollection';
import { errorEmail } from '@/utils/apiRequests';
import axios from 'axios';
import UserModal from "@/@core/components/modals/ReadMoreModal.vue";
import suspendActionFun from '@/utils/globalFunction/suspendActionFun';
import functionForSuspendOrBlockChild from '@/utils/globalFunction/suspendChildFunction'
import functionForGiveWarning from '@/utils/globalFunction/giveWarningFun.js';
import functionForActiveChild from '@/utils/globalFunction/activeChild'
const currentFileName = "src/views/ComplaintManagement/Complaintlearner.vue";
import {sendNotificationUserStatus} from '../service.js'
import {blockUser} from './service.js'
import {unblockUser} from './serviceUnblock.js'
import {onWarningGiveNotification} from '@/views/ComplaintManagement/serviceWarningNotification.js'
export default {
  directives: {
    Ripple,
  },
  components: {
    VueGoodTable,
    BCard,
    BImg,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    UserModal,
    BSpinner,
    BCardText
  },
  data() {
    return {
      direction: false,
      isSpinner: false,
      isUpdating: false,
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      pageLength: 10,
      dir: false,
      complaintlearnersearchTerm: "",
      complaintlearnercolumns: [
        {
          label: "Complaint (Report) ID",
          field: "complaintId",
        },
        {
          label: "Booking ID",
          field: "bookingid",
        },
        {
          label: "Learner Name",
          field: "learnerFullName",
        },
        {
          label: "Tutor Name",
          field: "tutorFullName",
        },
        {
          label: "Complaint Reason",
          field: "complaintReason",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      readMore: false,
      learner_name_options: [{ value: null, text: "All" }],
      tutor_name_options: [{ value: null, text: "All" }],
      learner_selected: "All",
      byTutorFiltered:"All",
      selectedRowElement:null
    };
  },
  computed: {
    ...mapGetters({
      users: "userManagement/getUsers",
      gettutorCompaintList: "complainBtTutorManagement/getTutorComplaint",
      getBookinglist: "booking/getBooking",
      getsettingList : 'settings/getsettingArrayFeild'
    }),
    tutorComplaintList() {
      let res = [];
      if (this.gettutorCompaintList && this.gettutorCompaintList.length > 0) {
        this.gettutorCompaintList.forEach((items) => {
          if (items.complaintBy.toLowerCase() == "learner") {
            let obj = items;
            obj.tutorFullName = "";
            obj.learnerFullName = "";
            obj.userRoleTutor = '';
            obj.userRoleLearner = '';
            obj.bookingid = "";
            let sIndex = this.users.findIndex((values) => { //tutor
              return values.id == items.tutorId;
            });
            let tIndex = this.users.findIndex((values) => { //learner
              return values.id == items.learnerId;
            });
            let booIndex = this.getBookinglist.findIndex((values) => {
              return values.bookingId === items.bookingId;
            });
            if (booIndex > -1) {
              obj.bookingid = this.getBookinglist[booIndex].bookingId;
              obj.bookingDocId = this.getBookinglist[booIndex].id
            }
            if (tIndex > -1) {
              obj.learnerFullName =
                this.users[tIndex].firstName +
                " " +
                this.users[tIndex].lastName;
              obj.userRoleLearner = this.users[tIndex].userRole
              obj.learnerProfile = this.users[tIndex].profilePicture;
            }
           if (sIndex > -1) {
              items.tutorFullName =
                this.users[sIndex].firstName +
                " " +
                this.users[sIndex].lastName;
              obj.userRoleTutor = this.users[sIndex].userRole
              obj.tutorWarnings = this.users[sIndex].warning ? this.users[sIndex].warning : 0;
              obj.tutorUserStatus = this.users[sIndex].status ? this.users[sIndex].status : 0; //pending if not exists
              obj.tutorProfile = this.users[sIndex].profilePicture;
           
              //MAKE TUTOR FILTER ARRAY
            if (this.tutor_name_options.length > 0) {
              let getInd = this.tutor_name_options.findIndex((elem) => {
                return elem.value == this.users[sIndex].id;
              });
              if (getInd <= -1) {
                this.tutor_name_options.push({
                  value: this.users[sIndex].id ? this.users[sIndex].id : "",
                  text:
                    this.users[sIndex].firstName +
                    " " +
                    this.users[sIndex].lastName,
                });
              }
            }
            }
            //FIND LEARNER DATAS
          let learnerIndex = this.users.findIndex((values) => {
            return values.id == items.learnerId;
          });
          if (learnerIndex > -1) {
            items.learnername =
              this.users[learnerIndex].firstName +
              " " +
              this.users[learnerIndex].lastName;
            //MAKE LEARNER FILTER ARRAY
            if (this.learner_name_options.length > 0) {
              let getInd = this.learner_name_options.findIndex((elem) => {
                return elem.value == this.users[learnerIndex].id;
              });
              if (getInd <= -1) {
                this.learner_name_options.push({
                  value: this.users[learnerIndex].id
                    ? this.users[learnerIndex].id
                    : "",
                  text:
                    this.users[learnerIndex].firstName +
                    " " +
                    this.users[learnerIndex].lastName,
                });
              }
            }
          }
            res.push(obj);
          }
        });
        //FILTERED OPTIONS
      let temp = res;
      if (this.learner_selected != "All") {
        temp = this.filterByLearnerSelected(temp);
      }
      if (this.byTutorFiltered !== "All") {
        temp = this.filterByTutorSelected(temp);
      }
      this.complaintlearnersrows = temp;
      return this.complaintlearnersrows
      } else {
        return res;
      }
    },
  },
  methods: {
    onRowClick(data) {
      if (data.column.field === "action") {
          return false;
      } else if(data.column.field === "tutorFullName") {
         this.$router.push({ name: 'user-details', params: { id: data.row.tutorId}})
      } else if(data.column.field === "learnerFullName"){
         this.$router.push({ name: 'user-details', params: { id: data.row.learnerId}})
      } else if (data.column.field==="complaintReason") {
        if (data.row.complaintReason.length <= 90) {
          this.$router.push({ name: 'booking-detail-complaint-learner', params: { id: data.row.bookingId} })
        } else {
          return false;
        }
      } else{    
         this.$router.push({ name: 'booking-detail-complaint-learner', params: { id: data.row.bookingId}})
      }
    },
    warningUserButtonHandler(){
      this.isUpdating = true;
      try {
          var self = this;
          const updateObj = {
              warning: self.selectedRowElement.tutorWarnings + 1,
              updatedAt: new Date()
          }
          updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS, self.selectedRowElement.tutorId, updateObj, () => {
              onWarningGiveNotification({
                tutorId :  self.selectedRowElement.tutorId
              }, () => {});
              this.isUpdating = false;
              functionForGiveWarning( self.selectedRowElement.tutorId,"complaint",self.getsettingList.warningResetDays).catch(error=>{
                    console.error("error",error)
              })
              this.$root.$emit(
                "showToastMessage",
                "You gave warning to the tutor.",
                "success"
              );
          });
      } catch (error) {
          this.isUpdating = false;
          console.error(error);
          errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "warningUserButtonHandler",
              errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
            });
      }
    },
    suspendUserButtonHandler(){
      var self = this;
      this.isUpdating = true;
      updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS, self.selectedRowElement.tutorId, {
          status: 2,
          suspendUserTime: new Date(),
          updatedAt: new Date()
      }, () => {
          suspendActionFun(self.selectedRowElement.tutorId).then(() => {
            functionForSuspendOrBlockChild(self.selectedRowElement.tutorId,true,false,()=>{})
              this.isUpdating = false;
              sendNotificationUserStatus({
                  uid: self.selectedRowElement.tutorId,
                  userStatus: "Suspend"
              },() => {})
              this.$root.$emit(
                "showToastMessage",
                "User suspended successfully.",
                "success"
              );
          }).catch((error) => {
              this.isUpdating = false;
              console.error(error);
              this.$root.$emit(
                "showToastMessage",
                "User not suspended.",
                "danger"
              );
          })
      });
    },
    blockUserButtonHandler(){
      var self = this;
      this.isUpdating = true;
      if(self.selectedRowElement.tutorUserStatus === 2) {
          updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS, self.selectedRowElement.tutorId, {
              status:3,
              updatedAt: new Date()
          }, () => {
              blockUser({
                uid: self.selectedRowElement.tutorId
              },(res) => {
                  functionForSuspendOrBlockChild(self.selectedRowElement.tutorId,false,true,()=>{})
                  this.isUpdating = false;
                  if(res && res.status){
                      sendNotificationUserStatus({
                        uid: self.selectedRowElement.tutorId,
                        userStatus: "Block"
                      },() => {})
                      this.$root.$emit(
                        "showToastMessage",
                        'User blocked successfully.',
                        "success"
                      );
                  } else {
                    this.$root.$emit(
                      "showToastMessage",
                      'User not blocked.',
                      "danger"
                    );
                  }
              })
          })
      } else {
          updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS, self.selectedRowElement.tutorId, {
              status:3,
              updatedAt: new Date()
          }, () => {
              suspendActionFun(self.selectedRowElement.tutorId)
              .then(() => {
                  functionForSuspendOrBlockChild(self.selectedRowElement.tutorId,false,false,()=>{})
                  blockUser({
                     uid: self.selectedRowElement.tutorId
                  },(res) => {
                      this.isUpdating = false;
                      if(res && res.status){
                          sendNotificationUserStatus({
                              uid: self.selectedRowElement.tutorId,
                              userStatus: "Block"
                          },() => {})
                          this.$root.$emit(
                            "showToastMessage",
                            'User blocked successfully.',
                            "success"
                          );
                      } else {
                          this.$root.$emit(
                            "showToastMessage",
                            'User not blocked.',
                            "danger"
                          );
                      }
                  })
              }).catch((error) => {
                  console.error(error);
                  this.isUpdating = false;
                  this.$root.$emit(
                    "showToastMessage",
                    "User not blocked.",
                    "danger"
                  );
              })
          })
      }
    },
    unblockUserButtonHandler(){
        var self = this;
        this.isUpdating = true;
        updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS, self.selectedRowElement.tutorId, {
              status: 1,
              warning: 0,
              updatedAt: new Date()
          }, () => {
              functionForActiveChild(self.selectedRowElement.tutorId,false,()=>{})
              unblockUser({
                 uid: self.selectedRowElement.tutorId
              },(res) => {

                  this.isUpdating = false;
                  if(res && res.status){
                      sendNotificationUserStatus({
                        uid: self.selectedRowElement.tutorId,
                        userStatus: "Active"
                      },() => {})
                      this.$root.$emit(
                        "showToastMessage",
                        'User unblocked successfully.',
                        "success"
                      );
                  } else {
                      this.$root.$emit(
                        "showToastMessage",
                        'User not unblocked.',
                        "danger"
                      );
                  }
              })
          });
    },
    filterByLearnerSelected(data) {
      return data.filter((user) => user.learnerFullName == this.learner_selected);
    },
     filterByTutorSelected(data) {
      return data.filter((user) => user.tutorFullName == this.byTutorFiltered);
    },
    toggleFlag: function (index, column) {
      this.complaintlearnersrows[index][column].toggled = true;
    },
    limitTo: function (value) {
        if(value){
            if (value.length < 90){
                return value
            }else{

                return value.substring(0, 90) + "...";
            }
        }else{
            return "-";
        }
    },
  }
};
</script>
