<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
    </div>

    <b-row>
      <b-col xl="6">
        <b-card>
          <div>
            <b-form
              ref="form"
              :style="{ height: trHeight }"
              class="repeater-form book_session_from"
              @submit.prevent="repeateAgain"
            >
              <b-row>
                <b-col md="6">
                  <b-form-group
                    label="Learner Name"
                    label-for="learnername"
                  >
                    <v-select
                      v-model="learner_name_selected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="learner_name_options"
                      label="title"
                      placeholder="Select"
                    >
                      <template #option="{ title, avatar, id }">
                        <div class="d-flex align-items-center justify-content-between">
                          <span>
                            <b-avatar :src="avatar" class="user_img" />
                            <span> {{ title }}</span>
                            </span>
                          <span>                            
                            <span>{{ id }}</span>
                          </span>
                        </div>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Tutor Name" label-for="tutorname">
                    <v-select
                      v-model="tutor_name_selected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="tutor_name_options"
                      label="title"
                      data-icon="tutor_name_options[1].avatar"
                    >
                      <template #option="{ title, avatar, id }">
                       <div class="d-flex align-items-center justify-content-between">
                          <span>
                            <b-avatar :src="avatar" class="user_img" />
                            <span> {{ title }}</span>
                            </span>
                          <span>                            
                            <span>{{ id }}</span>
                          </span>
                        </div>
                      </template>
                    </v-select>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Category" label-for="category">
                    <b-form-select
                      v-model="category_selected"
                      :options="category_options"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                </b-col>
                <b-col md="6">
                  <b-form-group label="Session Title" label-for="sessiontitle">
                    <b-form-select
                      v-model="sessiontitle_selected"
                      :options="sessiontitle_options"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <hr />
                  <b-form-group class="custom_checkbox_group">
                    <b-form-checkbox v-model="checkselected" value="A">
                      Do you want booking for 7 days?
                    </b-form-checkbox>
                    <b-form-checkbox v-model="checkselected" value="B">
                      Do you want to keep the same time slot for the selected
                      days?
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row
                v-for="(item, index) in items"
                :id="item.id"
                :key="item.id"
                ref="row"
              >
                <b-col md="6">
                  <b-form-group label="Date" label-for="item-name">
                    <b-form-datepicker
                      id="datepicker-placeholder"
                      placeholder="DD/MM/YYYY"
                      local="en"
                      v-model="date"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Time" label-for="item-name">
                    <b-row class="align-items-center">
                      <b-col md="5" class="pr-0">
                        <b-form-timepicker
                          v-model="timevalue1"
                          locale="en"
                          placeholder="--:-- --"
                        />
                      </b-col>
                      <b-col md="2" class="text-center p-0">to</b-col>
                      <b-col md="5" class="pl-0">
                        <b-form-timepicker
                          v-model="timevalue2"
                          locale="en"
                          placeholder="--:-- --"
                        />
                      </b-col>
                    </b-row>
                  </b-form-group>
                </b-col>
                <b-col md="12" class="mb-50 text-right">
                  <b-button
                    variant="flat-danger"
                    class="p-0 text-decoration-underline"
                    @click="removeItem(index)"
                  >
                    <span>Remove</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-button
                    variant="flat-primary"
                    class="p-0 text-decoration-underline"
                    @click="repeateAgain"
                  >
                    <span>+ Add New</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
              <b-row class="align-items-center">
                <b-col cols="6">
                  <p class="mb-0">0 Credits Use</p>
                </b-col>
                <b-col cols="6 text-right">
                  <b-button
                    type="reset"
                    variant="outline-primary"
                    class="mr-2"
                  >
                    Cancel
                  </b-button>
                  <b-button
                    type="submit"
                    variant="primary"
                  >
                    Book Session
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BCol,
  BRow,
  BForm,
  BFormRow,
  BButton,
  BFormGroup,
  BFormSelect,
  BFormTextarea,
  BFormCheckbox,
  BFormDatepicker,
  BAvatar,
  BFormTimepicker,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { heightTransition } from "@core/mixins/ui/transition";
import vSelect from "vue-select";
export default {
  components: {
    BFormInput,
    BForm,
    BButton,
    BFormRow,
    BCol,
    BRow,
    BFormGroup,
    BFormTextarea,
    BCard,
    BFormSelect,
    BFormCheckbox,
    BFormDatepicker,
    BFormTimepicker,
    vSelect,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      checkselected: [],
      tutor_name_selected: {
        id: "1594232",
        title: "Betty Decarlo",
        avatar: require("@/assets/images/avatars/9.png"),
      },
      tutor_name_options: [
        {
          id: "1594232",
          title: "Betty Decarlo",
          avatar: require("@/assets/images/avatars/9.png"),
        },
        {
          id: "1594231",
          title: "David Stinson",
          avatar: require("@/assets/images/avatars/10.png"),
        },
        {
          id: "1594230",
          title: "Marie Lemoine",
          avatar: require("@/assets/images/avatars/11.png"),
        },
        {
          id: "1594229",
          title: "Gloria Izaguirre",
          avatar: require("@/assets/images/avatars/7.png"),
        },
        {
          id: "1594228",
          title: "Derick Nicholson",
          avatar: require("@/assets/images/avatars/6.png"),
        },
        {
          id: "1594227",
          title: "Jeannine Sae",
          avatar: require("@/assets/images/avatars/5.png"),
        },
        {
          id: "1594226",
          title: "Sean Hayes",
          avatar: require("@/assets/images/avatars/4.png"),
        },
        {
          id: "1594225",
          title: "Shelly Wesson",
          avatar: require("@/assets/images/avatars/11.png"),
        },
        {
          id: "1594224",
          title: "Alex Mann",
          avatar: require("@/assets/images/avatars/2.png"),
        },
        {
          id: "1594223",
          title: "Catherine Jesse",
          avatar: require("@/assets/images/avatars/1.png"),
        },
      ],
      learner_name_selected: [],
      learner_name_options: [
        {
          id: "1594232",
          title: "Betty Decarlo",
          avatar: require("@/assets/images/avatars/9.png"),
        },
        {
          id: "1594231",
          title: "David Stinson",
          avatar: require("@/assets/images/avatars/10.png"),
        },
        {
          id: "1594230",
          title: "Marie Lemoine",
          avatar: require("@/assets/images/avatars/11.png"),
        },
        {
          id: "1594229",
          title: "Gloria Izaguirre",
          avatar: require("@/assets/images/avatars/7.png"),
        },
        {
          id: "1594228",
          title: "Derick Nicholson",
          avatar: require("@/assets/images/avatars/6.png"),
        },
        {
          id: "1594227",
          title: "Jeannine Sae",
          avatar: require("@/assets/images/avatars/5.png"),
        },
        {
          id: "1594226",
          title: "Sean Hayes",
          avatar: require("@/assets/images/avatars/4.png"),
        },
        {
          id: "1594225",
          title: "Shelly Wesson",
          avatar: require("@/assets/images/avatars/11.png"),
        },
        {
          id: "1594224",
          title: "Alex Mann",
          avatar: require("@/assets/images/avatars/2.png"),
        },
        {
          id: "1594223",
          title: "Catherine Jesse",
          avatar: require("@/assets/images/avatars/1.png"),
        },
      ],
      category_selected: null,
      category_options: [
        { value: null, text: "Select" },
        { value: "a", text: "Computer Programming" },
        { value: "b", text: "English" },
        { value: "c", text: "Chemistry" },
      ],
      sessiontitle_selected: null,
      sessiontitle_options: [
        { value: null, text: "Select" },
        { value: "a", text: "Basic Data Structures" },
        { value: "b", text: "Dynamic Arrays and Amortized Analysis" },
        { value: "c", text: "File Processing Labs" },
        { value: "e", text: "Introduction to Data Structures" },
        { value: "f", text: "Mathematical Induction" },
        { value: "g", text: "Object Oriented Programming" },
        { value: "h", text: "Priority Queues and Disjoint Set Union" },
        { value: "i", text: "Stack and Queue Labs" },
        { value: "j", text: "Stacks and Queues" },
        { value: "h", text: "Trees and Binary Search Trees" },
      ],
      items: [
        {
          id: 1,
          selected: "male",
          selected1: "designer",
          prevHeight: 0,
        },
      ],
      nextTodoId: 2,
      timevalue1: "",
      timevalue2: "",
    };
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        ;
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
};
</script>
 <style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>