<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinnerPast"
      large
      class="pageLoadSpinners loaderDetail"
      />
    <b-tab title="Past Session(s)" @click="onPastSession">
        <div class="card user_filter" v-if="!isSpinnerPast">
            <h2>Filters</h2>

            <b-form @submit.prevent>
            <b-row>
                <b-col xl="3" lg="4" md="5">
                <label>Date</label>
                <flat-pickr
                    v-model="dateNtim"
                    class="form-control datetime_picker"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', maxDate: new Date() }"
                />
                </b-col>
                <b-col xl="3" lg="4" md="5">
                <label v-if="!isLearner">Tutor Name</label>
                <label v-else>Learner Name</label>
                <b-dropdown
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    :text="tutor_name_selected"
                    variant="outline-secondary"
                    class="w-100"
                >
                    <b-dropdown-item
                    v-for="item in tutor_name_options"
                    :key="item.value"
                    @click="tutor_name_selected = item.text"
                    >{{ item.text }}</b-dropdown-item
                    >
                </b-dropdown>
                </b-col>
            </b-row>
            </b-form>
        </div>
        <div class="card user_table past_session_table" v-if="!isSpinnerPast">
            <div class="custom-search d-flex justify-content-end">
            <b-form-group>
                <div class="d-flex align-items-center">
                <b-form-input
                    v-model="pastsessionsearchTerm"
                    placeholder="Search..."
                    type="text"
                    class="d-inline-block"
                />
                </div>
            </b-form-group>
            </div>
            <vue-good-table
            :columns="pastsessioncolumns"
            @on-cell-click="onCellClick"
            :rows="filterRows"
            class="cursor_pointer"
            :rtl="direction"
            :search-options="{
                enabled: true,
                externalQuery: pastsessionsearchTerm,
            }"
            :select-options="{
                enabled: true,
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
            }"
            :pagination-options="{
                enabled: true,
                perPage: pageLength,
            }"
            >
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'bookingid'" class="text-nowrap userid">
                {{ props.row.bookingid }}
                </span>
                <span v-else-if="props.column.field === 'tutorname'" class="text-nowrap">
                <b-avatar :src="props.row.avatar" class="user_img" />
                <span class="userid" @click="getDataAgain()">
                   <router-link :to="`/user-management/users/${props.row.userId}`" class="text-nowrap">{{
                    props.row.tutorname 
                    }}</router-link>
                </span>
                </span>

                <span v-else-if="props.column.field === 'rating' && props.row.rating != null"
                v-bind:class="props.row.rating !== null ? 'rating' : 'hide'"
                >
                <b-img v-if="props.row.rating != null" src="@/assets/images/raty/star-on-2.png" class="star_img" />
                {{ props.row.rating !== null  ? props.row.rating : ""}}
                </span>
                <span v-else-if="props.column.field === 'bookingcredits'"   v-bind:class="props.row.bookingcredits === 'Trial Booking' ? 'text-danger': ''">
                {{ props.row.bookingcredits }} {{(props.row.bookingcredits === 'Trial Booking' || props.row.bookingcredits === undefined || props.row.bookingcredits == 0) ? '' : 'Credits'}}
                </span>
                <span v-else-if="props.column.field === 'sessionbookingtiming'" class="tutor_type"> {{ props.row.sessionbookingtiming }}
                </span>
                <span v-else-if="props.column.field === 'sessiontitle'" class="tutor_type"> {{ props.row.sessiontitle }}
                </span>
                <span v-else>
                {{ props.formattedRow[props.column.field] }}
                </span>
            </template>
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> 
                      <span v-if="props.total === 0"> Showing 0 </span>
                      <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                      to
                      <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                      <span v-else>{{props.total}} of {{ props.total }} entries</span>
                    </span>

                        <div class="d-flex align-items-center mb-1 mt-0 showentry">
                          <span>Show</span>
                          <b-form-select
                            v-model="pageLength"
                            :options="['10', '20', '30', '40', '50']"
                            class="mx-1"
                            @input="
                              (value) => props.perPageChanged({ currentPerPage: value })
                            "
                        />
                          <span>entries</span>
                        </div>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :current-page="currentPage"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => {
                          props.pageChanged({ currentPage: value });
                          currentPage = value
                        }
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
        </div>
    </b-tab>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import * as getQuery from "@/utils/FirebaseQueries/getQueries/getQueries.js";
import { dbCollections } from "@/utils/firebaseCollection.js";
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import {errorEmail} from "@/utils/apiRequests.js"
import { async } from 'vuesax';
const currentFileName = "src/views/Users/User/UserDetailTabs/PastSessions.vue"
export default {
name: "PastSession",
directives: {
Ripple,
},
components: {
VueGoodTable,
BCard,
BImg,
BRow,
BCol,
BDropdown,
BDropdownItem,
BCardText,
BTabs,
BTab,
BAvatar,
BBadge,
BPagination,
BFormGroup,
BFormInput,
BFormSelect,
BForm,
BButton,
flatPickr,
BSpinner,
},
props:{
  userDetailRole : Array,
  isLearner: Boolean,
  getDataAgain: Function,
  rating: Object,
},
data(){
    return{
      dateNtim: moment(new Date()).format("YYYY-MM-DD hh:mmA"),
      tutor_name_options: [],
      tutor_name_selected: "All",
      pastsessionsearchTerm: "",
      pastsessioncolumns: [
        {
          label: "Booking ID",
          field: "bookingid",
        },
        {
          label: "Session Booking Date & Time",
          field: "sessionbookingtiming",
        },
        {
          label: "Session(s) Title",
          field: "sessiontitle",
        },
        {
          label: "Tutor Name",
          field: "tutorname",
        },
        {
          label: "Rating",
          field: "rating",
        },
        {
          label: "Booking Credits",
          field: "bookingcredits",
        },
      ],
      pastsessionsrows: [],
      direction: false,
      pageLength: 10,
      currentPage: 1,
      isSpinnerPast: false,
      count: 0,
    }
},
computed: {
filterRows() {
      var listA = this.pastsessionsrows;
      if (this.tutor_name_selected == "All"  && this.dateNtim == moment(new Date()).format("YYYY-MM-DD HH:mm")) {
        return listA;
      }
      if (this.tutor_name_selected != "All") {
        listA = listA.filter((data) => {
          
          return data.tutorname.toLowerCase().includes(this.tutor_name_selected.toLowerCase());
        });
      }
      if (this.dateNtim != new Date()) {
        listA = listA.filter((data) => {

          return data.endTime < this.dateNtim
        });
      }
      return listA;
},
},
created() {
},
watch:{
    userDetailRole(newV , preV){
        if(newV !== preV && newV !== null){
            this.learner();
        }
    },
    isLearner(newV , preV){
        if(newV !== preV){
            this.learner();
        }
    }
},
methods: {
  onCellClick(data) {
   if (data.column.field==="tutorname") {
     this.$router.push({name: 'user-details', params: { id: data.row.userId }})
    this.getDataAgain()
   } else {
    this.$router.push({name: 'past-session-details', params: { id: data.row.Id }})
   }
  },
  tutor_type_optionsss() {
      var typeArr = [];
      try{
        typeArr.push({ value: 0, text: "All" });
        for (var i = 0; i < this.pastsessionsrows.length; i++) {
          if ( this.pastsessionsrows[i].tutorname !== "" || this.tutorList[i].tutorname !== undefined) {
            var temp = false;
            for (var j = 0; j < typeArr.length; j++) {
              if (this.pastsessionsrows[i].tutorname === typeArr[j].text) {
                temp = true;
              }
            }
            if (!temp) {
              var obj = {
                value: i + 1,
                text: this.pastsessionsrows[i].tutorname,
              };
              typeArr.push(obj);
            }
          }
        }
        this.tutor_name_options = typeArr;
      }catch (error) {
          errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "tutor_type_optionsss",
              errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
            })
          console.error(error)
      }
},
learner(){
  try{
    this.pastsessionsrows = []; 
        if (this.userDetailRole!== undefined &&this.userDetailRole.includes('learner')&&(this.userDetailRole.includes('tutor'))) { 
        if (!this.isLearner) { 
            this.isSpinnerPast = true;
            getQuery.simpleGetDataWithSpecificTwoWhereCondition(dbCollections.BOOKING,'learnerId','==',this.$route.params.id,'status','==',4,(session)=>{
              if(session.length === 0){
                  this.isSpinnerPast = false;
                  return;
              } 
              else{
                session.forEach(element => {
                  
                      this.count = +1;
                      let object = {
                          Id : element.id,
                          bookingid  : element.bookingId ? element.bookingId : "",
                          sessionbookingtiming : (element.endTime && element.startTime) ? this.sessionTimeCalculate(element.startTime, element.endTime) : "",
                          sessiontitle :element.sessionDetails ? element.sessionDetails.name : "",
                          tutorname: element.tutorDetails  ? element.tutorDetails.firstName + " " + element.tutorDetails.lastName : "",
                          rating: element.tutorDetails ?  element.tutorDetails.rating : "",
                          avatar: element.tutorDetails.profilePicture ? element.tutorDetails.profilePicture : "",
                          bookingcredits: element.usedCredits ? element.usedCredits : 0,
                          userId : element ?  element.tutor : "",
                          endTime : moment(element.endTime.seconds * 1000).format("YYYY-MM-DD HH:mm")
                      } 
                      this.pastsessionsrows.push(object)         
                  });
                  if(session.length === this.pastsessionsrows.length){
                    this.isSpinnerPast = false;
                  }
                this.tutor_type_optionsss()
              }
          }) 
          this.pastsessioncolumns[3].label = "Tutor Name"
        }
        else{
            this.isSpinnerPast = true;
            getQuery.simpleGetDataWithSpecificTwoWhereCondition(dbCollections.BOOKING,'tutor','==',this.$route.params.id,'status','==',4,(session)=>{
              if(session.length === 0){
                  this.isSpinnerPast = false;
                  return;
              } 
              else{     
                session.forEach( (element) => {
                  this.count = +1;
                    let ratingDisplay  = null
                      getQuery.simpleGetDataFromSubCollectionWithDocID(dbCollections.USERS,element.tutor,(rating)=>{
                      ratingDisplay = (rating.tutorTotalRatingSum && rating.tutorTotalRatingUserCount) ?  Number((rating.tutorTotalRatingSum /  rating.tutorTotalRatingUserCount).toFixed(2)) : null
                    let object = {
                        Id : element.id,
                        bookingid  : element.bookingId ? element.bookingId : "",
                        sessionbookingtiming : (element.endTime && element.startTime) ? this.sessionTimeCalculate(element.startTime, element.endTime) : "",
                        sessiontitle :element.sessionDetails ? element.sessionDetails.name : "",
                        tutorname: element.learnerDetails  ? element.learnerDetails.firstName + " " + element.learnerDetails.lastName : "",
                        rating: ratingDisplay,
                        avatar: element.learnerDetails.profileImage ? element.learnerDetails.profileImage : "",
                        bookingcredits: element.usedCredits ? element.usedCredits : 0,
                        userId : element ?  element.learnerId : "",
                        endTime : moment(element.endTime.seconds * 1000).format("YYYY-MM-DD HH:mm")
                    } 
                    this.pastsessionsrows.push(object)         
                    if(session.length === this.pastsessionsrows.length){
                      this.isSpinnerPast = false;
                    }
                    })
                });
                this.tutor_type_optionsss()
              }
            })   
            this.pastsessioncolumns[3].label = "Learner Name"
        }    
    }
    else if (this.userDetailRole!== undefined &&this.userDetailRole.includes('learner')) { 
      this.isSpinnerPast = true;
      getQuery.simpleGetDataWithSpecificTwoWhereCondition(dbCollections.BOOKING,'learnerId','==',this.$route.params.id,'status','==',4,(session)=>{
          if(session.length === 0){
                  this.isSpinnerPast = false;
                  return;
          } 
          else{
            session.forEach(element => {
                  this.count = +1;
                  let object = {
                      Id : element.id,
                      bookingid  : element.bookingId ? element.bookingId : "",
                      sessionbookingtiming : (element.endTime && element.startTime) ? this.sessionTimeCalculate(element.startTime, element.endTime) : "",
                      sessiontitle :element.sessionDetails ? element.sessionDetails.name : "",
                      tutorname: element.tutorDetails  ? element.tutorDetails.firstName + " " + element.tutorDetails.lastName : "",
                      rating: element.tutorDetails ?  element.tutorDetails.rating : "",
                      avatar: element.tutorDetails.profilePicture ? element.tutorDetails.profilePicture : "",
                      bookingcredits: element.usedCredits ? element.usedCredits : 0,
                      userId : element ?  element.tutor : "",
                      endTime : moment(element.endTime.seconds * 1000).format("YYYY-MM-DD HH:mm")
                  } 
                  this.pastsessionsrows.push(object)         
                  if(session.length === this.pastsessionsrows.length){
                        this.isSpinnerPast = false;
                  }
              });
            this.tutor_type_optionsss()
          }
      }) 
      this.pastsessioncolumns[3].label = "Tutor Name"
    }
    else if (this.userDetailRole!== undefined &&this.userDetailRole.includes('tutor')) { 
    this.isSpinnerPast = true;
    getQuery.simpleGetDataWithSpecificTwoWhereCondition(dbCollections.BOOKING,'tutor','==',this.$route.params.id,'status','==',4,(session)=>{
          if(session.length === 0){
                  this.isSpinnerPast = false;
                  return;
          }
          else{
            var ratingDisplay  = null
            session.forEach(element => {
                        this.count = +1;
                    getQuery.simpleGetDataFromSubCollectionWithDocID(dbCollections.USERS,element.tutor,(rating)=>{
                    ratingDisplay = (rating.tutorTotalRatingSum && rating.tutorTotalRatingUserCount) ?  Number((rating.tutorTotalRatingSum /  rating.tutorTotalRatingUserCount).toFixed(2)) : null
                    let object = {
                       Id : element.id,
                       bookingid  : element.bookingId ? element.bookingId : "",
                       sessionbookingtiming : (element.endTime && element.startTime) ? this.sessionTimeCalculate(element.startTime, element.endTime) : "",
                       sessiontitle :element.sessionDetails ? element.sessionDetails.name : "",
                       tutorname: element.learnerDetails  ? element.learnerDetails.firstName + " " + element.learnerDetails.lastName : "",
                       rating: ratingDisplay,
                       avatar: element.learnerDetails.profileImage ? element.learnerDetails.profileImage : "",
                       bookingcredits: element.usedCredits ? element.usedCredits : 0,
                       userId : element ?  element.learnerId : "",
                       endTime : moment(element.endTime.seconds * 1000).format("YYYY-MM-DD HH:mm")
                    } 
                    this.pastsessionsrows.push(object)         
                    if(session.length === this.pastsessionsrows.length){
                          this.isSpinnerPast = false;
                    }
                  })
               });
            this.tutor_type_optionsss()
          } 
     }) 
      this.pastsessioncolumns[3].label = "Learner Name"
    }
  }catch (error) {
      console.error(error)
              this.isSpinnerPast = false;
      errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "learner",
              errorMessage : error.message
            },(res)=>{
               if(res.error){
                  console.error(res.error)
              }
        })
  }
},
onPastSession(){
    try{
      var allTabList = document.getElementsByClassName('nav-link active')
      if (allTabList[0].innerText != 'Past Session(s)') { 
          this.learner()
          this.tutor_type_optionsss()
      }
    }catch (error) {
       errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "onPastSession",
              errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
        })
      console.error(error)
    }
},
sessionTimeCalculate(startTime , endTime) {
  if (moment(startTime.seconds*1000).format("DD-MM-YYYY") == moment(endTime.seconds*1000).format("DD-MM-YYYY")) {
    return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("hh:mm A")}`
  } else {
    return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")}`
  }
},
}
}
</script>

<style>

</style>