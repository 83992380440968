<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
   <div class="card session_detail">
        <div class="card-header current_detail_top_section requset">
            <h4>{{dataDisplay.title ? dataDisplay.title : ""}}</h4>
        </div>
        <div class="card-body fontchanges">
            <b-row>
              <b-col xl="3" lg="3" md="3">
                <h5>Request ID</h5>
                <p>{{dataDisplay.requestid}}</p>
                <h5>Tutor Name</h5>
                <p>
                  <b-avatar :src="dataDisplay.tutorProfilePicture" class="user_img" />
                  <router-link :to="`/user-management/users/${dataDisplay.tutorId}`">{{ dataDisplay.firstName + " " + dataDisplay.lastName }} </router-link>
                </p>
              </b-col>
              <b-col xl="3" lg="3" md="3">
                 <h5>Category</h5>
                <p>{{dataDisplay.category}}</p>
                <h5>Tutor Type</h5>
                <p>
                  <b-badge :style="getTutorTypeStyle(dataDisplay.tutorType)">
                    <!-- :variant="getStatusBadgeVariant(dataDisplay.tutorType)" -->
                    {{dataDisplay.tutorType}}
                  </b-badge>
                </p>
                <!-- <p>{{ item.school }}</p>  -->
              </b-col>
               <b-col xl="3" lg="3" md="3">
                <h5>Teaching Language</h5>
                <p>{{dataDisplay.teachingLanguage}}</p>
               </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <h5>Description</h5>
                <p>{{dataDisplay.description}}</p>
              </b-col>
              <b-col md="6">
                <h5>Search Tag(s)</h5>
                <div v-for="(item,index) in dataDisplay.searchTag" :key ="index" class="tag_btn">
                  <b-button variant="outline-secondary">
                    {{item}}
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>      
    </div>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BAvatar, BBadge,BButton,BSpinner } from "bootstrap-vue";
import {mapGetters} from "vuex"
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import {dbCollections} from "@/utils/firebaseCollection.js"
import axios from 'axios'
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/SessionsManagement/AllSession(s)/requestSessionDetail.vue"
import { sessionBookingNotification } from './service.js';
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BButton ,
    BSpinner
  },
  data() {
    return {
      direction:false,
      isSpinner:false,
      isUpdating:false,
      tutorType: [
        {
          1: "Valuable",
          2: "Elite",
          3: "Normal",
          4: "N/A",
        },
        {
          1: "light-primary",
          2: "light-danger",
          3: "light-success",
          4: "light-dark",
        },
      ],
    };
  },
   computed: {
     ...mapGetters({
          sessions:'sesionManagement/getSessions',
          gettutorTypesList: "settings/getTutorTypes",
      }),
      dataDisplay(){
        try{
          let dataDisplay = {
             category:"",
             teachingLanguage:"",
             description:"",
             searchTag:[],
             tutorType:"",
             firstName:"",
             lastName: "",
             tutorId:"",
             requestid:"",
             id:"",
             title: ""
          }
             for (let i = 0; i < this.sessions.length; i++) {
                 if (this.sessions[i].requestId === this.$route.params.id) {
                    dataDisplay.category = this.sessions[i].categoryDetails.categoryName
                    dataDisplay.categoryId = this.sessions[i].categoryDetails.id
                    dataDisplay.teachingLanguage = this.sessions[i].teachingLanguageDetails.title
                    dataDisplay.description = this.sessions[i].description
                    dataDisplay.searchTag = this.sessions[i].tags
                    dataDisplay.tutorType = this.sessions[i].tutorDetails.tutorType
                    dataDisplay.firstName = this.sessions[i].tutorDetails.firstName
                    dataDisplay.tutorId = this.sessions[i].tutor
                    dataDisplay.lastName =  this.sessions[i].tutorDetails.lastName
                    dataDisplay.tutorProfilePicture = this.sessions[i].tutorDetails.profilePicture
                    dataDisplay.requestid = this.sessions[i].requestId
                    dataDisplay.id =  this.sessions[i].id
                    dataDisplay.title = this.sessions[i].title
                 }
             }
    
             return dataDisplay
        }catch(error){
        console.error(error);
        errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "dataDisplay",
              errorMessage : error.message
            },(res)=>{
              if(res.error){
                  console.error(res.error)
              }
            })
          }
        return []
        }
  },
  beforeDestroy() {
      this.$root.$off('approveSession')
      this.$root.$off('rejectSession')
  },
  created() {
    try{
      this.$root.$on('approveSession',()=>{
       var self = this;
      self.$bvModal
        .msgBoxConfirm('Are you sure you want to Approve?', {
          title: 'Approve',         
          class:'test',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
          footerClass: 'approve-footer',
          headerClass: 'approve-header',
          bodyClass: 'approve-body',
        })
        .then(value => {
          self.isSpinner = true;
          if(value){
            const updateObject = {
              'status' : 2
            }
            updateFirebase.updateRootCollectionDataByDocId(dbCollections.SESSIONS,this.dataDisplay.id,updateObject,(res) => {
              self.$root.$emit('showToastMessage','Session approved successfully.','success');
              this.$router.push("/sessions-management/request-sessions")
              self.isSpinner = false;
              sessionBookingNotification({
                title: this.dataDisplay.title,
                status: 2,
                tutor: this.dataDisplay.tutorId,
                id: this.dataDisplay.id
              }, () => {});
            })
          }else{
            self.isSpinner = false;
            this.$bvModal.hide()
          }
        }).catch(error => {
          console.error(error);
          self.isSpinner = false;
        }) 
      })
    }catch(error){
      console.error(error)
      errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "created",
          errorMessage : error.message
        },(res)=>{
          if(res.data){
          }else if(res.error){
              console.error(res.error)
          }
        })
    }
    try{
      this.$root.$on('rejectSession',()=>{
      var self = this;
       self.$bvModal
        .msgBoxConfirm('Are you sure you want to Decline?', {
          title: 'Decline',         
          class:'test',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
          footerClass: 'approve-footer',
          headerClass: 'approve-header suspend-header',
          bodyClass: 'approve-body',
        })
        .then(value => {
          self.isSpinner = true;
          if(value){
            const updateObject = {
              'status' : 1
            }
            updateFirebase.updateRootCollectionDataByDocId(dbCollections.SESSIONS,this.dataDisplay.id,updateObject,(res) => {
              self.$root.$emit('showToastMessage','Session declined successfully.','success');
              self.isSpinner = false;
              this.$router.push("/sessions-management/request-sessions");
              sessionBookingNotification({
                  title: this.dataDisplay.title,
                  status: 1,
                  tutor: this.dataDisplay.tutorId,
                  id: this.dataDisplay.id
              }, () => {});
            })
          }else{
            self.isSpinner = false;
          }
        }).catch(error => {
          console.error(error)
          self.isSpinner = false;
        })
      })
    }catch(error){
     console.error(error);
     errorEmail({
        projectName : process.env.VUE_APP_PROJECTNAME,
        environment : process.env.NODE_ENV,
        fileName : currentFileName,
        hookName : "created",
        errorMessage : error.message
      },(res)=>{
        if(res.error){
            console.error(res.error);  
        }
      });
    }
  },
  methods:{
    getTutorTypeStyle(value) {
        if(this.gettutorTypesList) {
            let index = this.gettutorTypesList.findIndex((val ) =>{
              return val.name == value
            });
            if(index > -1) {
                return {
                  'background-color' : this.gettutorTypesList[index].backgroundColor,
                  'color' : this.gettutorTypesList[index].textColor,
                  'border-color': this.gettutorTypesList[index].textColor,
                  'border-width': '1px',
                  'border-style': 'solid'
                }
            }
        } else {
            return ''
        }
    },
      getStatusBadgeVariant(value){
      
      var variant = "";
      if(value == "Valuable")
      {
        variant += 'light-primary';
      }
      else if(value == "Elite")
      {
        variant += 'light-danger';
      }
      else if(value == "Normal")
      {
        variant += 'light-success';
      }
      else if(value == "Expert")
      {
        variant += 'light-warning';
      };
      return variant;
    },
  }
};
</script>
<style scoped>
.tag_btn {
    display: inline-block;
    margin-right: 10px;
}
</style>