<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
      <div class="card session_detail">
        <div class="card-header current_detail_top_section requset">
            <h4>{{dataDisplay.title ? dataDisplay.title : ""}}</h4>
        </div>
        <div class="card-body fontchanges">
            <b-row>
              <b-col xl="3" lg="3" md="3">
                <h5>Category</h5>
                <p>{{dataDisplay.category}}</p>
              </b-col>
              <b-col xl="3" lg="3" md="3">
                <h5>Tutor Name</h5>
                <p>
                  <b-avatar :src="dataDisplay.avtar" class="user_img" />
                  <router-link :to="`/user-management/users/${dataDisplay.tutorId}`">{{ dataDisplay.firstName + " " + dataDisplay.lastName }} </router-link>
                </p>
              </b-col>
              <b-col xl="3" lg="3" md="3">
                <h5>Tutor Type</h5>
                <p>
                  <b-badge :style="getTutorTypeStyle(dataDisplay.tutorType)">
                    <!-- :variant="getStatusBadgeVariant(dataDisplay.tutorType)" -->
                    {{dataDisplay.tutorType}}
                  </b-badge>
                </p>
              </b-col>
              <b-col xl="3" lg="3" md="3">
                <h5>Teaching Language</h5>
                <p>{{dataDisplay.teachingLanguage}}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <h5>Description</h5>
                <p>{{dataDisplay.description}}</p>
              </b-col>
              <b-col md="6" >
                <h5>Search Tag(s)</h5>
                <div v-for="(item,index) in dataDisplay.searchTag" :key ="index" class="tag_btn">
                  <b-button variant="outline-secondary">
                    {{item}}
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BSpinner

} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import flatPickr from 'vue-flatpickr-component'
import {mapGetters} from "vuex"
import {errorEmail} from "@/utils/apiRequests.js"
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import {dbCollections} from "@/utils/firebaseCollection.js"
import axios from 'axios';
const currentFileName = "src/views/SessionsManagement/AllSession(s)/sessionDetail.vue"
import { sessionBookingNotification } from './service.js';
import {updateConnectedUserWhenSessionStatusChange} from "./updateConnectedUserWhenSessionStatusChangeService"

export default {
  components: {
    VueGoodTable,
    BCard,
    BImg,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BCardText,
    BTabs,
    BTab,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    flatPickr,
    BSpinner
  },
  data() {
    return {
      isSpinner:false,
      isUpdating:false,
      FullName: "   ",
      Data: " " ,
    };
  },
  computed: {
     ...mapGetters({
          sessions:'sesionManagement/getSessions',
          gettutorTypesList: "settings/getTutorTypes",
      }),
      dataDisplay(){
       try{
         let dataDisplay = {
            category:"",
            teachingLanguage:"",
            description:"",
            searchTag:[],
            tutorType:"",
            firstName:"",
            lastName: "",
            tutorId:"",
            title: "",
            avtar:"",
         }
            for (let i = 0; i < this.sessions.length; i++) {
                if (this.sessions[i].id === this.$route.params.id) {
                   dataDisplay.category = this.sessions[i].categoryDetails.categoryName
                   dataDisplay.categoryid = this.sessions[i].categoryDetails.id
                   dataDisplay.teachingLanguage = this.sessions[i].teachingLanguageDetails.title
                   dataDisplay.description = this.sessions[i].description
                   dataDisplay.searchTag = this.sessions[i].tags
                   dataDisplay.tutorType = this.sessions[i].tutorDetails.tutorType
                   dataDisplay.firstName = this.sessions[i].tutorDetails.firstName
                   dataDisplay.tutorId = this.sessions[i].tutor
                   dataDisplay.title =  this.sessions[i].title
                   dataDisplay.lastName =  this.sessions[i].tutorDetails.lastName
                   dataDisplay.avtar = this.sessions[i].tutorDetails.profilePicture
                   this.$root.$emit('sessionStatus',this.sessions[i].status)
                   this.$root.$emit('allSessionDetailTitleName', this.sessions[i].title);
                }
            }
   
            return dataDisplay
       }catch(err){
         console.error(err)
         errorEmail({
              projectName : process.env.VUE_APP_PROJECTNAME,
              environment : process.env.NODE_ENV,
              fileName : currentFileName,
              hookName : "dataDisplay",
              errorMessage : err.message
            },(res)=>{
            })
            console.error(err)
         return [] 
       } 
      }
  },
  created() {
    this.Data = "KArea"
    this.$root.$on('disable-session',()=> {
        this.$bvModal
        .msgBoxConfirm("Are you sure you want to Disable Session?", {
          title: "Disable",
          class: "test",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-primary",
          hideHeaderClose: false,
          centered: true,
          footerClass: "approve-footer",
          headerClass: "approve-header suspend-header",
          bodyClass: "approve-body",
        })
        .then((value) => {
          if (value) {
            this.isSpinner = true;
            var disableobj = {
              status: 3,
            };
            updateFirebase.updateRootCollectionDataByDocId(
              dbCollections.SESSIONS,
              this.$route.params.id,
              disableobj,
              () => {
                this.isSpinner = false;
                this.$root.$emit('showToastMessage','Session disabled successfully.','success');
                sessionBookingNotification({
                  title: this.dataDisplay.title,
                  status: 3,
                  tutor: this.dataDisplay.tutorId,
                  id: this.$route.params.id,
                }, () => {});
                updateConnectedUserWhenSessionStatusChange({
                  tutorId :this.dataDisplay.tutorId,
                  categoryId  : this.dataDisplay.categoryid,
                  action: "remove"
                },()=>{})
                this.$router.push({path:'/sessions-management/all-sessions'});
              }
            );
          }
        });
    })
    this.$root.$on('active-session',()=> {
        this.$bvModal
        .msgBoxConfirm("Are you sure you want to active Session?", {
          title: "Active",
          class: "test",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-primary",
          hideHeaderClose: false,
          centered: true,
          footerClass: "approve-footer",
          headerClass: "approve-header",
          bodyClass: "approve-body",
        })
        .then((value) => {
          if (value) {
            this.isSpinner = true;
            var disableobj = {
              status: 2,
            };
            updateFirebase.updateRootCollectionDataByDocId(
              dbCollections.SESSIONS,
              this.$route.params.id,
              disableobj,
              () => {
                this.isSpinner = false;
                this.$root.$emit('showToastMessage','Session disabled successfully.','success');
                sessionBookingNotification({
                  title: this.dataDisplay.title,
                  status: 2,
                  tutor: this.dataDisplay.tutorId,
                  id: this.$route.params.id,
                }, () => {});
                updateConnectedUserWhenSessionStatusChange({
                  tutorId :this.dataDisplay.tutorId,
                  categoryId  : this.dataDisplay.categoryid,
                  action: "add"
                },()=>{})
                this.$router.push({path:'/sessions-management/all-sessions'});
              }
            );
          }
        });
    })
  },
  methods: {
    getTutorTypeStyle(value) {
        if(this.gettutorTypesList) {
            let index = this.gettutorTypesList.findIndex((val ) =>{
              return val.name == value
            });
            if(index > -1) {
                return {
                  'background-color' : this.gettutorTypesList[index].backgroundColor,
                  'color' : this.gettutorTypesList[index].textColor,
                  'border-color': this.gettutorTypesList[index].textColor,
                  'border-width': '1px',
                  'border-style': 'solid'
                }
            }
        } else {
            return ''
        }
    },
    toggleFlag: function () {
      ;
      this.readMore = !this.readMore;
    },
     getStatusBadgeVariant(value){
      
      var variant = "";
      if(value == "Valuable")
      {
        variant += 'light-primary';
      }
      else if(value == "Elite")
      {
        variant += 'light-danger';
      }
      else if(value == "Normal")
      {
        variant += 'light-success';
      }
      else if(value == "Expert")
      {
        variant += 'light-warning';
      };
      return variant;
    },
  },
  filters: {
    limitTo: function (value) {
      if (!value) return "";
      return value.substring(0, 90) + "...";
    },
   
  },
  beforeDestroy() {
      this.$root.$off('disable-session');
      this.$root.$off('active-session');

  }
};
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
.tag_btn {
    display: inline-block;
    margin-right: 10px;
}
/* span.badge.badge-primary {
    background: #F8EEFF;
    border: 1px solid #8F00FF;
    border-radius: 7px;
    color: #8F00FF;
    font-size: 12px;
    line-height: 9px;
    height: 21px;
    padding: 3px 10px;
} */
</style>

