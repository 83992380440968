<template>
<div>
  <b-spinner
    variant="primary"
    v-if="isLoading"
    large
    class="pageLoadSpinners loadSpinner"
  />
  <div v-show="!isLoading">
  <b-tab title="Review & Rating" @click="onReviewAndRating(new Date())">
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners loaderDetail"
      style="z-index: 999999; margin-left: -160px; margin-top: 350px"
    />
    <div class="card user_filter">
      <h2>Filters</h2>
      <b-form @submit.prevent>
        <b-row>
          <b-col xl="3" lg="4" md="5">
            <label>Date</label>
            <flat-pickr
              v-model="reviewdateNtim"
              @input="onReviewAndRating($event)"
              class="form-control datetime_picker"
              :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
            />
          </b-col>
          <b-col xl="3" lg="4" md="5">
            <label>Learner Name</label>
            <b-dropdown
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :text="reviewRatingFilter.text"
              variant="outline-secondary"
              class="w-100"
            >
              <b-dropdown-item
                @click="reviewRatingFilter = { text: 'All', value: '' }"
                >All</b-dropdown-item
              >
              <b-dropdown-item
                v-for="item in reviewLeatnerOption"
                :key="item.value"
                @click="reviewRatingFilter = item"
                >{{ item.text }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div class="card user_table review_rating_table">
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="reviewsearchTerm"
              placeholder="Search..."
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <vue-good-table
        :columns="reviewcolumns"
        :rows="filteredUsers"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: reviewsearchTerm,
        }"
        :select-options="{
          enabled: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'fullname'" class="text-nowrap">
            <b-avatar :src="props.row.userProfileUrl" class="user_img" />
            <span class="userid" @click="getDataAgain()">
              <router-link
                :to="{
                  name: 'user-details',
                  params: {
                    id: props.row.userDocId,
                    name: props.row.fullname,
                    role: props.row.userRole.join(','),
                  },
                }"
                class="text-nowrap"
              >
                <a>{{ props.row.fullname }}</a>
              </router-link>
            </span>
          </span>
          <span
            v-else-if="props.column.field == 'dispStar'"
            v-bind:class="props.row.dispStar !== '' ? 'ratingPast' : 'hide'"
          >
            <b-img
              v-if="props.row.dispStar !== '' && props.row.dispStar !== 0"
              src="@/assets/images/raty/star-on-2.png"
              class="star_img"
            />
            <b-img
              v-if="props.row.dispStar == '' && props.row.dispStar == 0"
              src="@/assets/images/raty/star-half-2.png"
              class="star_img"
            />
            {{ props.row.dispStar }}
          </span>
            <div
            v-else-if="props.column.field === 'reviewDescr'"
            class="wrap-break-word"
            v-show="true"
            >
            <span v-html="limitTo(props.row.reviewDescr)"></span>
            <a
                class="readmore"
                v-b-modal="'readmore' + props.row.id"
                v-show="!props.row['isEditable'] && props.row.reviewDescr.length >= 85"
                
                >Read more</a
            >
            <UserModal title="Message" :text="props.row.reviewDescr" :id="props.row.id" />
            </div>
        </template>
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> 
                  <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to 
                  <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                  <span v-else>{{props.total}} of {{ props.total }} entries</span>
                </span>

                    <div class="d-flex align-items-center mb-1 mt-0 showentry">
                      <span>Show</span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10', '20', '30', '40', '50']"
                        class="mx-1"
                        @input="
                          (value) => props.perPageChanged({ currentPerPage: value })
                        "
                     />
                      <span>entries</span>
                    </div>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :current-page="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => {
                      props.pageChanged({ currentPage: value });
                      currentPage = value
                    }
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
      </vue-good-table>
    </div>
  </b-tab>
  </div>
</div>
</template>
<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BFormCheckbox,
  BButton,
  BModal,
  BSpinner,
} from "bootstrap-vue";
import { mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import moment from "moment";
import firebase from "@/utils/firebaseInit.js";
import { dbCollections } from "@/utils/firebaseCollection";
const db = firebase.firestore();
import UserModal from "@/@core/components/modals/ReadMoreModal.vue";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Users/User/UserDetailTabs/UserDetail_Tutor_ReviewRating.vue"
export default {
  name: "ReviewRating",
  props: {
    userDetail: Object,
    getDataAgain : Function
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      reviewRatingFilter: { text: "All", value: "" },
      selectedReviewRateItem: "",
      reviewsearchTerm: "",
      reviewdateNtim: new Date(),
      reviewcolumns: [
        {
          label: "Learner Name",
          field: "fullname",
        },
        {
          label: "Rating",
          field: "dispStar",
        },
        {
          label: "Reviews",
          field: "reviewDescr",
        },
      ],
      direction: false,
      pageLength: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      reviewRowsFinal: [],
      reviewArrayFinal: [],
      isSpinner: false,
      isLoading: false
    };
  },
  components: {
    UserModal,
    BCard,
    BImg,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BCardText,
    BTabs,
    BTab,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    BFormCheckbox,
    BModal,
    BSpinner,
    flatPickr,
    VueGoodTable,
  },
  created() {},
  computed: {
    ...mapGetters({
      users: "userManagement/getUsers",
    }),
    filteredUsers() {
      var self = this;
      try {
        var listArr = self.reviewRowsFinal;
        var tmp = listArr;
        if (self.reviewRatingFilter.text != "All") {
          tmp = this.filterByUserType(tmp);
        }
        if (
          self.reviewRatingFilter.text == "All" &&
          this.reviewdateNtim == ""
        ) {
          return listArr;
        } else {
          return tmp;
        }
      } catch (error) {
        console.error(error, "error");
        errorEmail({
        projectName : process.env.VUE_APP_PROJECTNAME,
        environment : process.env.NODE_ENV,
        fileName : currentFileName,
        hookName : "filteredUsers",
        errorMessage : error.message
        },(res)=>{
        if(res.error){
            console.error(res.error)
        }
        })
        return self.reviewRowsFinal;
      }
    },
    reviewLeatnerOption() {
      let result = [];
      try {
        if (this.reviewRowsFinal && this.reviewRowsFinal.length > 0) {
          this.reviewRowsFinal.forEach((element) => {
            if (result.length == 0) {
              result.push({
                value: element.userDocId,
                text: element.fullname,
              });
            } else {
              let ind = result.findIndex((val) => {
                if (val.value !== "" && element.userDocId !== "") {
                  return val.value == element.userDocId;
                }
              });
              if (ind <= -1) {
                result.push({
                  value: element.userDocId,
                  text: element.fullname,
                });
              }
            }
          });
          return result;
        }
      } catch (error) {
        console.error(error, "ERROR");
        errorEmail({
        projectName : process.env.VUE_APP_PROJECTNAME,
        environment : process.env.NODE_ENV,
        fileName : currentFileName,
        hookName : "reviewLeatnerOption",
        errorMessage : error.message
        },(res)=>{
        if(res.error){
            console.error(res.error)
        }
        })
        return result;
      }
    },
  },
  methods: {
    filterByUserType(data) {
      try {
        return data.filter(
          (user) => user.userDocId == this.reviewRatingFilter.value
        );
      } catch (error) {
        console.error(error);
        errorEmail({
        projectName : process.env.VUE_APP_PROJECTNAME,
        environment : process.env.NODE_ENV,
        fileName : currentFileName,
        hookName : "filterByUserType",
        errorMessage : error.message
        },(res)=>{
        if(res.error){
            console.error(res.error)
        }
        })
        return data;
      }
    },
    filterByDate(data) {
      try {
        return data.filter((user) => {
          if (
            user.filterFormatedDate ==
            moment(new Date(this.reviewdateNtim)).format("DD-MM-YYYY")
          ) {
            if (new Date(user.createdDate) <= new Date(this.reviewdateNtim)) {
              return user;
            }
          }
        });
      } catch (error) {
        console.error(error, "error");
        errorEmail({
        projectName : process.env.VUE_APP_PROJECTNAME,
        environment : process.env.NODE_ENV,
        fileName : currentFileName,
        hookName : "filterByDate",
        errorMessage : error.message
        },(res)=>{
        if(res.error){
            console.error(res.error)
        }
        })
        return data;
      }
    },
     limitTo: function (value) {
            if(value){
                if (value.length < 85){
                    return value
                }else{
    
                    return value.substring(0, 85) + "...";
                }
            }else{
                return "-";
            }
        },
    onReviewAndRating(date) {
      try {
        this.reviewRowsFinal = [];
        this.reviewRatingFilter = { text: "All", value: "" };
        this.isLoading = true;
        let user_id = this.$route.params.id;
        if (date !== "") {
          db.collection(dbCollections.REVIEWS)
            .where("tutorId", "==", user_id)
            .where("createdAt", "<=", new Date(date))
            .get()
            .then((snapshot) => {
              let response = [];
              if (!snapshot.empty) {
                snapshot.forEach((doc) => {
                  let recentData = moment(new Date(date)).format("DD-MM-YYYY");
                  let firebaseDate = new Date(
                    doc.data().createdAt.seconds * 1000
                  );
                  let fbDate = moment(new Date(firebaseDate)).format(
                    "DD-MM-YYYY"
                  );
                  if (recentData == fbDate) {
                    response.push({ id: doc.id, ...doc.data() });
                  }
                });
                this.reviewArrayFinal = response;
                this.reviewFinal(response, (cb) => {
                  if (cb.status == true) {
                    this.reviewRowsFinal = cb.data;
                    this.isLoading = false;
                  }
                });
              } else {
                this.isLoading = false;
              }
            })
            .catch((error) => {
              this.isLoading = false;
              console.error(error, "error");
            });
        } else {
          db.collection(dbCollections.REVIEWS)
            .where("tutorId", "==", user_id)
            .get()
            .then((snapshot) => {
              let response = [];
              if (!snapshot.empty) {
                snapshot.forEach((doc) => {
                  response.push({ id: doc.id, ...doc.data() });
                });
                this.reviewArrayFinal = response;
                this.reviewFinal(response, (cb) => {
                  if (cb.status == true) {
                    this.reviewRowsFinal = cb.data;
                    this.isLoading = false;
                  }
                });
              } else {
                this.isLoading = false;
              }
            });
        }
      } catch (error) {
        this.isLoading = false;
        console.error(error, "error");
        errorEmail({
        projectName : process.env.VUE_APP_PROJECTNAME,
        environment : process.env.NODE_ENV,
        fileName : currentFileName,
        hookName : "onReviewAndRating",
        errorMessage : error.message
        },(res)=>{
        if(res.error){
            console.error(res.error)
        }
        })
      }
    },
    reviewFinal(response, cb) {
      let res = [];
      try {
        response.forEach((items) => {
          let sIndex = this.users.findIndex((element) => {
            return items.learnerId == element.id;
          });
          if (sIndex > -1) {
            let obj = {
              dispStar: items.stars ? items.stars : 0,
              reviewDescr: items.description ? items.description : "",
              reviewDocId: items.id,
              userDocId: this.users[sIndex].id,
              fullname:
                this.users[sIndex].firstName +
                " " +
                this.users[sIndex].lastName,
              createdDate: new Date(items.createdAt.seconds * 1000),
              userProfileUrl: this.users[sIndex].profilePicture
                ? this.users[sIndex].profilePicture
                : "",
              userRole: this.users[sIndex].userRole
                ? this.users[sIndex].userRole
                : null,
              filterFormatedDate: moment(
                new Date(items.createdAt.seconds * 1000)
              ).format("DD-MM-YYYY"),
              description: null,
            };
            res.push(obj);
          }
        });
        cb({ status: true, data: res });
      } catch (error) {
        cb({ status: false, data: error });
        errorEmail({
        projectName : process.env.VUE_APP_PROJECTNAME,
        environment : process.env.NODE_ENV,
        fileName : currentFileName,
        hookName : "reviewFinal",
        errorMessage : error.message
        },(res)=>{
        if(res.error){
            console.error(res.error)
        }
        })
      }
    },
  },
};
</script>
 
 