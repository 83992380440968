<template>
  <div class="tutor_detail">
    <div v-show="!isSpinner">

      <b-spinner variant="primary" v-if="isUpdating" large class="pageLoadSpinners" style="position:fixed"/>
      
      <b-card>
      <div v-for="(item, index) in getUserData" :key="index">
      <div class="inner_wrap">
        <b-row>
          <b-col xl="1" lg="2" md="2">
            <div class="tutor_img" v-if="item.avatar && item.avatar !== '' && item.avatar !== null">
              <b-img :src="item.avatar" alt="tutor image" rounded />
            </div>
            <div class="user_img" v-else>
              <b-img src="../../../assets/images/avatars/defaultUserProfile.jpg" alt="User image" rounded />
            </div>
          </b-col>
          <b-col xl="2" lg="2" md="2">
              <h5>User ID</h5>
              <p>{{ item.tutorid ? item.tutorid : 'N/A' }}</p>
              <h5>Total Exp. Years</h5>
              <p>{{ item.experience }}</p>
          </b-col>
          <b-col xl="2" lg="2" md="2" class="download_btn">
              <h5>User Name</h5>
              <p>{{ item.fullName ? item.fullName : 'N/A' }}</p>
              <h5>Download Resume</h5>
              <p v-on:click="downloadDocument(item.resume)" class="download_text_header"> <feather-icon
                  icon="ArrowDownCircleIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                /><span>Download Resume</span></p>
          </b-col>
          <b-col xl="2" lg="2" md="2">
              <h5>Email</h5>
              <p v-if="!item.email">N/A</p>
              <p v-else-if="item.email && item.email.length  <= 20">{{item.email}}</p>
              <p :title="item.email" v-else>{{(item.email).slice(0, 20)+'...'}}</p>
              <h5>Status</h5>
              <b-badge :variant="getStatusBadgeVariant(item.tutorStatus)"> {{ getStatusName(item.tutorStatus) }}</b-badge>
          </b-col>
          <b-col xl="2" lg="2" md="2">
              <h5>Mobile Number</h5>
              <p>{{ item.mobilenumber ? item.mobilenumber : "N/A" }}</p>
          </b-col>
        </b-row>
        </div>
        </div>
      </b-card>

      <b-card>
        <b-row class="title">
          <b-col xl="12" lg="12" md="12"> 
              <h2>Document Verification</h2>
          </b-col>
        </b-row>
        <div v-for="(item, index) in documentVerification" :key="index" class="tutor_detail_middle_section">
        <div class="inner_wrap">
        <b-row>
          <b-col xl="3" lg="3" md="6"> 
              <h5>Degree</h5>
              <p>{{ item.degree ? item.degree : "N/A" }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="6"> 
              <h5>University Name</h5>
              <p>{{ item.universityName ? item.universityName : "N/A" }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="6"> 
              <h5>Passing Year</h5>
              <p>{{ item.passingYear ? item.passingYear : "N/A" }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="6" class="doc_wrap"> 
              <h5>Document</h5>
              <p v-if='item.uploadDocumentName != undefined && item.uploadDocumentName != ""'>{{item.uploadDocumentName ? item.uploadDocumentName : "N/A" }}</p>
              <div class="doc_btn download_btn" v-if='item.uploadDocumentName != undefined && item.uploadDocumentName != ""'>
                <a :href="item.uploadDocument" target="_blank"> <feather-icon icon="EyeIcon" size="16" class="text-body align-middle mr-25" />View</a>
                <p v-on:click="downloadDocument(item.uploadDocument)" class="download_text"> <feather-icon icon="ArrowDownCircleIcon" size="16" class="text-body align-middle mr-25" />Download</p>
              </div>
              <div v-else>-</div>
          </b-col>
        </b-row>
        </div>
        </div>
        <div v-if="documentVerification.length===0" class="nodata_tutorVerfication">
         No data
        </div>  
      </b-card>

      <b-card class="tutor_detail_bottom_section">
        <b-row class="title">
          <b-col xl="12" lg="12" md="12"> 
              <h2>Identity Document</h2>
          </b-col>
        </b-row>
       <div v-for="(item, index) in identityDocument" :key="index" class="tutor_detail_bottom_section">
       <div class="inner_wrap">
        <b-row>
          <b-col xl="3" lg="3" md="6"> 
              <h5>Document ID Number</h5>
              <p>{{ item.documentIDNumber ? item.documentIDNumber : "N/A" }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="6"> 
              <h5>Document Name</h5>
              <p>{{ item.documentName ? item.documentName : "N/A" }}</p>
          </b-col>
          <b-col xl="3" lg="3" md="6" class="doc_wrap"> 
              <h5>Document Front Side</h5>
              <p v-if="item.frontSideName != undefined && item.frontSideName !=''">{{ item.frontSideName ? item.frontSideName : "N/A" }}</p>
              <div class="doc_btn download_btn" v-if="item.frontSideName != undefined && item.frontSideName !=''">
                <a :href="item.documentFrontSide" target="_blank"> <feather-icon icon="EyeIcon" size="16" class="text-body align-middle mr-25" />View</a>
                <p v-on:click="downloadDocument(item.documentFrontSide)" class="download_text"> <feather-icon icon="ArrowDownCircleIcon" size="16" class="text-body align-middle mr-25" />Download</p>
              </div>
              <div v-else>-</div>
          </b-col>
          <b-col xl="3" lg="3" md="6" class="doc_wrap"> 
              <h5>Document Back Side</h5>
              <p v-if='item.backSideName != undefined && item.backSideName !=""'>{{ item.backSideName ? item.backSideName : "N/A" }}</p>
              <div class="doc_btn download_btn" v-if='item.backSideName != undefined && item.backSideName !=""'>
                <a :href="item.documentBackSide" target="_blank"> <feather-icon icon="EyeIcon" size="16" class="text-body align-middle mr-25" />View</a>
                <p v-on:click="downloadDocument(item.documentBackSide)" class="download_text"> <feather-icon icon="ArrowDownCircleIcon" size="16" class="text-body align-middle mr-25" />Download</p>
              </div>
              <div v-else>
                -
              </div>
          </b-col>
        </b-row>
        </div>
        </div>
        <div v-if="identityDocument.length===0" class="nodata_tutorVerfication">
         No data
        </div>  

      </b-card>

    </div>
      <b-modal
        id="modal-login"
        title="Verify Your Tutor"
        ok-title="Save"
        cancel-variant="outline-secondary"
        v-model="verificationModel"
        centered
        header-class="editingtutorhead"
        body-class="editingtutor"
        @ok.prevent="verifiedTutor('Verify')"
      >
        <h2>Approve <b>{{userFullName}}</b> And Assign Tutor Role.</h2>
        <b-form>
          <b-form-group label="Select Tutor Type" label-for="vue-select">
          <b-spinner 
          variant="primary" 
          v-if="modalProcessor" 
          large 
          class="pageLoadSpinners" 
          style="position:fixed,left: -1% !Important,right: 0px"
          />
           <validation-observer ref="approveTutor">
            <validation-provider
              #default="{ errors }"
              name="tutor type"
              rules="required"
            >
            <b-form-select
              v-model="selected"
              :options="tutorTypesOption"
              size="lg"
            />
             <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
            </validation-provider>
          </validation-observer>
          </b-form-group>
        </b-form>
      </b-modal>
      <b-modal
          id="modal-reject-tutorVerfication"
          ref="my-modal"
          title="Rejection Reason"
          ok-title="Reject"
          data-class="addcredit_popup"
          data-value-class="addcredit_popup"
          centered
          cancel-variant="outline-secondary"
          @show="resetModal"
          @hidden="resetModal"
          @ok="rejectTutorVerification"
          :ok-disabled="isUpdating"
          :cancel-disabled="isUpdating"
        >
        <validation-observer ref="formReject">
          <b-form ref="form" @submit.stop.prevent="rejectTutorVerification">
            <validation-provider
              #default="{ errors }"
              name="reason for rejection"
              rules="required|min:3|max:300"
            >
              <b-form-group
                label-for="name-input"
                invalid-feedback="Reason for Rejection is required"
              >
              <label style="font-weight:600;">Reason for Rejection</label>
                <b-form-textarea
                  id="name-input"
                  v-model="reason"
                  placeholder="Enter Reason For Rejection"
                  required
                />
                <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </b-form>
        </validation-observer>
      </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,  
  BForm,
  BSpinner,
  BFormTextarea
} from 'bootstrap-vue'
import { mapGetters } from 'vuex';
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import {dbCollections} from "@/utils/firebaseCollection.js";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { data } from 'vue-echarts';
import firebase from "@/utils/firebaseInit"
import axios from "axios";
import {sendNotificationOnTututorVerification} from './service.js'
import {updateInUserChartWhenUserRoleIsChange} from './updateUserRoleService.js'
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Users/TutorVerification/tutorVerificationDetail.vue"
export default {
  components: {
    BCard,
    BImg,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BCardText,
    BTabs,
    BTab,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,  
    BForm,
    BSpinner,
    ValidationProvider,
     ValidationObserver,
     BFormTextarea
  },
  data(){
    return{
      modalProcessor:false,
      verificationModel:false,
      selected: "",
      option: ["Valuable", "Elite", "Normal", "N/A"],
      direction:false,
      isSpinner:false,
      isUpdating:false,
      documentVerification:[],
      identityDocument:[],
      status: [
        {
          1: "Reject",
          2: "Pending",
        },
        {
          1: "light-danger",
          2: "light-warning",
        },
      ],
      userFullName:'',
      userTutorType:'',
      reason:"",
    }
  },
  computed: {
    ...mapGetters({
      users:'userManagement/getUsers',
      getTutorStatusList:'status/getStatus',
      gettutorTypesList:'settings/getTutorTypes'
    }),
    tutorTypesOption(){
       try {
            var arr = [{
              'id':'-1',
              'value':null,
              'text':'Select',
              'selected':true,
              disabled: true
            }]; 
            this.selected = null;                  
            this.gettutorTypesList.forEach((syl) => {
                var syllabusObj = {
                    id: syl.id,
                    value: syl.name.toLowerCase(),
                    text:syl.name                      
                }
                if(this.userTutorType && this.userTutorType !== '' && syl.name == this.userTutorType){
                  let searchIndex = arr.findIndex((values)=>{
                    return values.id == 0
                  })
                  if(searchIndex > -1){
                    arr[searchIndex].selected = false;
                    syllabusObj.selected = true;
                    this.selected = syl.name.toLowerCase();
                  }
                }
                arr.push(syllabusObj)                
            });
            return arr
        } catch (error) {
            console.error("error", error);
            errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "tutorTypesOption",
            errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          })            
            return arr;
        }
    },
    getUserData(){
      let result = [];
      try{
        let userId = this.$route.params.id;
        let sIndex = this.users.findIndex((values)=>{
          return values.id == userId
        })
        if(sIndex > -1){
          let obj = this.users[sIndex];
          obj.tutorid = obj.userId ? obj.userId : 'N/A';  
          obj.experience = obj.tutorTotalExperienceYears ? obj.tutorTotalExperienceYears : 'N/A';
          obj.fullName = obj.firstName +' '+obj.lastName;          
          obj.resume = obj.tutorResume ? obj.tutorResume : '',
          obj.tutorStatus = parseInt(obj.tutorStatus)
          obj.tutorVariant = '';
          obj.mobilenumber = obj.phoneNumber;          
          obj.avatar = '';
          this.identityDocument = obj.tutorIdentityDocument ? obj.tutorIdentityDocument : [];
          if (this.identityDocument.length) {
            for (let i = 0; i < this.identityDocument.length; i++) {  //for dispalying name of file
                var backSideName = '';
                var frontSideName = ''
                if(this.identityDocument[i].documentBackSide !== undefined && this.identityDocument[i].documentBackSide != '' && this.identityDocument[i].documentBackSide != null){
                backSideName = firebase.storage().refFromURL(this.identityDocument[i].documentBackSide);
                }
                if(this.identityDocument[i].documentFrontSide != undefined && this.identityDocument[i].documentFrontSide != null && this.identityDocument[i].documentFrontSide != ''){
                frontSideName = firebase.storage().refFromURL(this.identityDocument[i].documentFrontSide);
                }
                this.identityDocument[i].backSideName = backSideName.name ? (backSideName.name.split("_")[1] ? backSideName.name.split("_")[1] : this.identityDocument[i].documentName +" back side") : '';
                this.identityDocument[i].frontSideName = frontSideName.name ? (frontSideName.name.split("_")[1] ? frontSideName.name.split("_")[1]  : this.identityDocument[i].documentName +" front side"): '';            }
          }
          this.documentVerification = obj.tutorQualification ? obj.tutorQualification : [];
          if (this.documentVerification.length) {
            for (let i = 0; i < this.documentVerification.length; i++) {  //for dispalying name of file
               var uploadDocumentName = firebase.storage().refFromURL(this.documentVerification[i].uploadDocument);
               this.documentVerification[i].uploadDocumentName = uploadDocumentName.name.split("_")[1];
           }
          }
          this.userFullName = obj.fullName;
          this.userTutorType = obj.tutorType ? obj.tutorType : '';
          if(obj.profilePicture && obj.profilePicture !== ''){
            obj.avatar = obj.profilePicture ? obj.profilePicture : ''
          }
          this.$root.$emit('tutorVerificationName', this.userFullName);
          result.push(obj)
        }
        return result
      }catch(error){
        console.error(error)
         console.error("error", error);
          errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "getUserData",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        })
        return result
      }
    }
  },
  created() {
    this.$root.$on('openTutorVerificationModal',()=>{
      this.verificationModel = true;
    })
    this.$root.$on('rejectTutorVerificationEmit',()=>{
      this.$bvModal.show("modal-reject-tutorVerfication")
    })
  },
  methods: {
    getStatusBadgeVariant(value){        
      var variant = "";
      try{
        variant = "light-";
        if(this.getTutorStatusList && this.getTutorStatusList !== null){

          let statusObj =  this.getTutorStatusList.tutorVerificationStatus;
          for (const property in statusObj) {            
            if(value == property){  
                variant += statusObj[property].variant;
            }      
          }
        }
      }catch(error){
         console.error("error", error);
          errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "getStatusBadgeVariant",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        })
        return variant;
      }
      return variant;
    },
    verifiedTutor(type){
      try{
        if(this.isSpinner == false){
          if(type == 'Reject'){
          }
          if(type == 'Verify'){ 
            this.tutorFinalVerification(type)  
          }
        }
      }catch(error){
        this.$root.$emit('stopSpinner');
        this.$root.$emit('showToastMessage','Please try again later.','danger');
        this.verificationModel = false;      
        this.modalProcessor = false;
         console.error("error", error);
          errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "verifiedTutor",
          errorMessage : error.message
        },(res)=>{
          if(res.error){
              console.error(res.error)
          }
        })
      }
    },
    tutorFinalVerification(type){
         try{
             this.$refs.approveTutor.validate().then((valid) => {
               if (valid) {
                 let userId = this.$route.params.id;
                 this.modalProcessor = true;
                 this.isSpinner = true;
                 let updatedObj = {
                   'tutorStatus':1,
                   'tutorType': this.selected,
                   'tutorverifyTimestamp':new Date()
                 }
                 if(this.selected && this.selected !== null && this.selected !== undefined){
                   let getValue = this.gettutorTypesList.findIndex((item)=>{
                     if(this.selected && this.selected !== null){
                       return item.name.toLowerCase() == this.selected.toLowerCase()
                     }
                   })
                   if(getValue > -1){
                     updatedObj.tutorType = this.gettutorTypesList[getValue].name
                   }            
                 }
                 updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,userId,updatedObj,res=>{
                   this.$root.$emit('showToastMessage','Tutor verified successfully.','success');
                    sendNotificationOnTututorVerification({
                        uid: userId,
                        tutorStatus: "Verify",
                        tutorType: updatedObj.tutorType
                    }, ()=>{})
                    updateInUserChartWhenUserRoleIsChange({
                    uid: userId,
                    role: "tutor"
                    },() => {})
                   this.$root.$emit('stopSpinner');
                   this.$root.$emit('updateTutorStatus',type);
                   this.modalProcessor = false;      
                   this.verificationModel = false;    
                   this.isSpinner = false;
                   this.$router.replace({name:'tutor-verification-mgmt'})
                 })                 
               }
             })
          }catch(error){
          console.error(error,"error")
          errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "tutorFinalVerification",
          errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          })
          this.$root.$emit('stopSpinner');
          this.modalProcessor = false;      
          this.verificationModel = false;    
          this.isSpinner = false;
        }        
    },
    resetModal(){
        this.reason = ""
        this.isUpdating = false;
    },
    rejectTutorVerification(bvModalEvt){
        bvModalEvt.preventDefault();
        this.rejectingTutor();
        this.isUpdating = true;
    },
    rejectingTutor(type){
      try{
        this.isUpdating = true;
         this.$refs.formReject.validate().then((valid) => {
           if(valid && this.isSpinner == false){
             let userId = this.$route.params.id;
               let rejectStatus = parseInt(2);
               let updatedObj = {
                 'tutorStatus':rejectStatus,
                 rejectReason: this.reason,
                 userRole: ["learner"]
               }
               updateFirebase.updateRootCollectionDataByDocId(dbCollections.USERS,userId,updatedObj,res=>{
                   sendNotificationOnTututorVerification({
                      uid: userId,
                      tutorStatus: "Reject",
                      rejectReason: this.reason
                    }, ()=>{})
                this.isUpdating = false;
                this.reason = ""
                this.$bvModal.hide("modal-reject-tutorVerfication")     
                this.$root.$emit('showToastMessage','Tutor rejected successfully.','danger');
                this.$root.$emit('stopSpinner'); 
                this.$root.$emit('updateTutorStatus',type);
                this.$router.replace({name:'tutor-verification-mgmt'})
               })
           } else {
            this.isUpdating = false;
           }
         })
      }catch(error){
        this.$root.$emit('stopSpinner');
        this.$root.$emit('showToastMessage','Please try again later.','danger');
        this.verificationModel = false;      
        this.modalProcessor = false;
        console.error(error)
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "rejectingTutor",
          errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          })
      }        
    },
    getStatusName(statusKey){
      var status = "";
      try{
        if(this.getTutorStatusList && this.getTutorStatusList !== null){

          let statusObj =  this.getTutorStatusList.tutorVerificationStatus;
          for (const property in statusObj) {            
            if(statusKey == property){  
                status = statusObj[property].status;
            }      
          }
        }
      }catch(error){
        console.error(error,"error");
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "getStatusName",
          errorMessage : error.message
          },(res)=>{
            if(res.error){
                console.error(res.error)
            }
          })
        return status;
      }
      return status;
    },
    downloadDocument(doc){
      if(doc !== ''){
      var httpsReference = firebase.storage().refFromURL(doc);
            var self = this
            self.$axios({
                url: doc,
                method: 'GET',
                responseType: 'blob',
            }).then((response) => {
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', httpsReference.name);
                document.body.appendChild(fileLink);

                fileLink.click();
            })
            .catch(error=>{
                console.error(error)
                self.$root.$emit('showToastMessage',error.message,'danger')
            })
      }else{
        this.$root.$emit('showToastMessage','This document is not applicable.','danger');
      }
    }
  },
  beforeDestroy () {
    this.$root.$off('rejectTutorVerificationEmit')
  },
};
</script>

<style scoped>
.position-relative.table-responsive thead tr th{
  white-space: nowrap !important;
}

.doc_btn.download_btn{
  display: flex;
}
.doc_btn.download_btn p {
  position: relative;
  color: #0096DB;
  font-size: 14px;
  cursor: pointer;
}
.download_text:after{
  content: "";
  position: absolute;
  background: #0096DB;
  width: 75%;
  height: 1px;
  left: 22px;
  bottom: 4px;
}
p.download_text_header span{
  color: #0096DB;
  text-decoration: underline;
  cursor: pointer;
}
.nodata_tutorVerfication {
    display: flex;
    justify-content: center;
}
p.download_text_header:after {
  content: "" !important;
  position: absolute !important;
  background: #0096DB;
  width: 50% !important;
  left: 35px !important;
  bottom: 17px !important;
  height: 1px !important;
} 

.table-hover tbody tr:hover{
  background-color: rgba(186, 22, 40, 0.12);
}

.drop-btn button{
  padding: 0.786rem 0.6rem !important;
}

.table-image{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.badge.badge-light-primary{
  color: #7367f0!important;
}
[dir] .badge.badge-light-primary{
  background-color: rgba(115,103,240,.12);
}

.s_modal_title{
  width: 100%;
  font-weight: 600;
  font-size: 20px;
}

[dir] .modal-header {
  padding: 0.5rem 1.4rem !important;
}
[dir] .modal-body {
  padding: 0rem 1.4rem !important;
}
</style>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}


</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>