<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
    </div>

    <b-row>
       <b-col xl="6">
    <b-card>
      <b-form novalidate class="needs-validation plan_edit_from" @submit.prevent>
        <b-row>        
          <b-col md="6">
            <b-form-group label="Account Number" label-for="accountnumber">
              <b-form-input
                id="nocredits"
                v-model="accountnumber"
                :state="accountnumber.length > 0"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              />
            </b-form-group>
          </b-col>          
          <b-col md="6">
            <b-form-group label="Confirm Account Number" label-for="confirmaccountnumber">
              <b-form-input
                id="discount"
                v-model="confirmaccountnumber"
                :state="confirmaccountnumber.length > 0"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="SWIFT/BIC Code" label-for="code">
              <b-form-input
                id="newamount"
                v-model="code"
                :state="code.length > 0"                
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Account Holder Name" label-for="accountholdername">
              <b-form-input
                id="planname"
                v-model="accountholdername"
                :state="accountholdername.length > 0"               
              />
            </b-form-group>
          </b-col>                  
          <b-col cols="12">
            <b-button
              type="submit"
              variant="primary"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
       </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BFormInput,
  BCol,
   BRow,
  BForm,
  BFormRow,
  BButton,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BFormInput,
    BForm,
    BButton,
    BFormRow,
    BCol,
     BRow,
    BFormGroup,
    BFormTextarea,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      accountholdername: "Angela Mayer",
      accountnumber: "8228675309",
      confirmaccountnumber: "8228675309",
      code: "CMFGUS33",
     
    };
  },
};
</script>
 