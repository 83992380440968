<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />

      <div class="faq_section">
        <app-collapse accordion v-for="faq in this.visibleCompanies" :key="faq.id">
          <app-collapse-item
            :title="`${faq.question}`"
            @click="$emit('visible')"
            >{{ faq.answer }}
            <p style="margin-top: 22px;margin-bottom: 4px;">
              <b-button variant="outline-danger delete" style="margin-right: 26px" @click="deletFunctionCalled(faq.id)" >Delete</b-button>
              <b-button variant="primary" style="margin-right: 26px" @click="editFunctionCalled(faq.question,faq.answer,faq.id)">Edit</b-button>
            </p>
          </app-collapse-item>
        </app-collapse>
        <div> 
        <b-button
        variant="outline-primary"
        class="d-inline-block align-top add_child_btn faq_btn cancelBookingClass12"
        id="toggle-btn"
        @click="contactUsVisible += step" v-if="contactUsVisible < contactUs.length"
      >
        Load More
      </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import { mapGetters,mapActions,mapMutations } from "vuex";
import {
  VBModal,
  BModal,
  BButton,
  BSpinner
} from "bootstrap-vue";
import { dbCollections } from '@/utils/firebaseCollection';
export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    AppCollapse,
    AppCollapseItem,
    VBModal,
    BButton,
    BModal,
    ValidationProvider, ValidationObserver,
    BSpinner
  },
  data() {
    return {
      isSpinner: false,
      isUpdating: false,
      value: false,
      faqDynemicButton:'Update',
      faqPopupTitle:'Edit FAQ',
      contactUsVisible:20,
      step:20
    };
  },
   computed: {
    ...mapGetters({
        contactUs: "faqManagement/getContactUs",
    }),
    visibleCompanies() {
      return this.contactUs.slice(0, this.contactUsVisible)
    }
  },
  methods: {
    ...mapActions({
      'getContactUsData': 'faqManagement/getContactUsData',
    }),
    ...mapMutations({
      'deleteContactUsMutation' : "faqManagement/deleteContactUs",
    }),
    
    editFunctionCalled(question,answer,id){
      this.$root.$emit("editContactUsData",question,answer,id)      
    },
    deletFunctionCalled(id){
      let self = this;
      self.$bvModal
        .msgBoxConfirm("Are you sure you want to delete Contact us?", {
          title: "Delete",
          class: "test",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-primary",
          hideHeaderClose: false,
          centered: true,
          footerClass: "approve-footer",
          headerClass: "approve-header",
          bodyClass: "approve-body",
        })
        .then((value) => {
          if (value) {
            this.$root.$emit('startButtonDisabled')
            this.isSpinner = true;
            let Index = this.contactUs.findIndex((values) => {
              return values.id == id;
            });
            this.deleteContactUsMutation(Index)
            let updatedObject = {
              updatedAt: new Date(),
              isDeleted: true
            }
            updateFirebase.updateRootSubCollectionDataWithDocId(
              dbCollections.SETTINGS,"setting",
              dbCollections.CONTACT_US,
              id,
              updatedObject,
              (res) => {
                self.$root.$emit('showToastMessage','Contact us deleted successfully.','success');
                this.$root.$emit('stopButtonDisabled')
                this.isSpinner = false;
              }
            );
          }
        });
      
    }
  },
  created(){
    this.$root.$on('startContactUsSpinner', () => {
      this.isSpinner = true;
    });
    this.$root.$on('stopContactUsSpinner', () => {
      this.isSpinner = false;
    });
    this.$root.$on('contactUsUpdate', (editObject) => {
      let Indexss = this.contactUs.findIndex((values) => {
        return values.id == editObject.id;
      });
      this.contactUs[Indexss].answer = editObject.answer
      this.contactUs[Indexss].question = editObject.question
    });
    this.isSpinner = true;
    let getContactUsFound = this.contactUs.length != 0;
    if(!getContactUsFound){
      this.getContactUsData().then(res=>{
        this.isSpinner = false;
      }).catch(error=>{
        console.error("error",error)
      })
    }else{
      this.isSpinner = false;
    }
  },
  watch:{
    contactUsVisible(val){
    }
  },
 
};
</script>
<style scoped>
  .faq_btn {
    margin-top: 9px !important;
  }
  /* .btn-outline-primary:not(:disabled):not(.disabled):focus {
      background-color: transparent !important;
  } */
  .cancelBookingClass12.btn-outline-primary:not(:disabled):not(.disabled):active,
.cancelBookingClass12.btn-outline-primary:not(:disabled):not(.disabled).active,
.cancelBookingClass12.btn-outline-primary:not(:disabled):not(.disabled):focus {
    background-color: transparent !important;
}
</style>