<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
    </div>
    <b-card class="categories_section" v-if="!isSpinner">
      <div class="categories_table subcategories_table grade">
             <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <b-form-input
                    v-model="GradesearchTerm"
                    placeholder="Search..."
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
            </div>
        <vue-good-table
          :columns="Gradecolumns"
          :rows="gradeDisplay"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: GradesearchTerm,
          }"
          :select-options="{
            enabled: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span
              v-if="props.column.field === 'gradename'"
              class="text-nowrap userid"
            >
              {{ props.row.gradename }}
            </span>
            <span
              v-else-if="props.column.field === 'curriculamname'"
              class="text-nowrap userid"
            >
              {{ props.row.curriculamname }}
            </span>
            <span
              v-else-if="props.column.field === 'action'"
              class="action_field"
            >
              <b-button
                variant="flat-dark"
                @click="editGrade(props.row.id, props.row)"
              >
                <img
                  :src="require('@/assets/images/erflog/Edit.png')"
                  alt="image"
                />
              </b-button>
              <b-button
                variant="flat-dark"
                class="ml-2"
                @click="deletegrade(props.index, props.row.id)"
              >
                <img
                  :src="require('@/assets/images/erflog/Delete.png')"
                  alt="image"
                />
              </b-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
         <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> 
                  <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to 
                  <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                  <span v-else>{{props.total}} of {{ props.total }} entries</span>
                </span>

                    <div class="d-flex align-items-center mb-1 mt-0 showentry grade">
                      <span>Show</span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10', '20', '30', '40', '50']"
                        class="mx-1"
                        @input="
                          (value) => props.perPageChanged({ currentPerPage: value })
                        "
                     />
                      <span>entries</span>
                    </div>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :current-page="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => {
                      props.pageChanged({ currentPage: value });
                      currentPage = value
                    }
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
        </template>
        </vue-good-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BSpinner,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
  BTabs,
  BTab,
  BCard,
  BModal,
  VBModal,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import { dbCollections } from "@/utils/firebaseCollection.js";
import firebase from "@/utils/firebaseInit.js";
import { ValidationProvider, ValidationObserver } from "vee-validate";
const db = firebase.firestore();
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/CurriculamManagement/Grade.vue"
export default {
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BCard,
    BButton,
    BTabs,
    BTab,
    BSpinner,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      direction: false,
      isSpinner: false,
      isUpdating: false,
      nameState: null,
      name: "",

      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      pageLength: 10,
      dir: false,
      Categoryselected: "",
      selected_category: "All",
      Gradecolumns: [
        {
          label: "Grade Name",
          field: "gradename",
        },
        {
          label: "Curriculum Name",
          field: "curriculamname",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      GradesearchTerm: "",
      gradeDisplay: [],
      categoryname: "",
      CategorynameState: null,
      CategorysubmittedNames: [],
      snapshotListener: null,
    };
  },
  beforeDestroy() {
    if (this.snapshotListener !== null) {
      this.snapshotListener();
    }
  },
  created() {
    var self = this;
    this.graderows(dbCollections.SETTINGS, "setting", dbCollections.GRADE);
  },
  methods: {
    graderows() {
      try{
         var self = this;
      self.gradeDisplay = [];
      self.isSpinner = true;
      self.firebaseQuery(
        dbCollections.SETTINGS,
        "setting",
        dbCollections.GRADE,
        (grade) => {
          if (grade.length) {
            grade.forEach((data) => {
              let index = self.gradeDisplay.findIndex((x) => x.id === data.id);
              if (data.isDeleted && index !== -1) {
                self.gradeDisplay.splice(index, 1);
                self.isSpinner = false;
              } else {
                db.collection(dbCollections.SETTINGS)
                  .doc("setting")
                  .collection(dbCollections.SYLLABUS)
                  .where("id", "==", data.curriculumId)
                  .get()
                  .then((docData) => {
                    if (docData.size) {
                      const obj = {
                        gradename: data.gradeName,
                        curriculamname: docData.docs[0].data().syllabusName,
                        id: data.id,
                        curriculamId: data.curriculumId,
                      };
                      if (index !== -1) {
                        self.gradeDisplay[index].gradename = obj.gradename;
                        self.gradeDisplay[index].curriculamname =
                          obj.curriculamname;
                        self.gradeDisplay[index].id = obj.id;
                        self.gradeDisplay[index].curriculamId =
                          obj.curriculamId;
                        self.isSpinner = false;
                      } else {
                        self.gradeDisplay.push(obj);
                        self.isSpinner = false;
                      }
                    } else {
                      self.isSpinner = false;
                    }
                  });
              }
            });
          } else {
            self.isSpinner = false;
          }
        }
      );
      } catch (error) {
        console.error("error",error)
        errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "graderows",
          errorMessage : error.message
        },(res)=>{
           if(res.error){
              console.error(res.error)
          }
        })
      }
     
    },
    firebaseQuery(collectionName, id, subCollectionName, cb) {
      try{
         var self = this;
      self.snapshotListener = db
        .collection(collectionName)
        .doc(id)
        .collection(subCollectionName)
        .onSnapshot(
          { includeMetadataChanges: true },
          (snapshot) => {
            if (!snapshot.empty && snapshot.docChanges().length) {
              let response = [];
              snapshot.docChanges().forEach((change) => {
                if (change.type === "added") {
                  response.push({ id: change.doc.id, ...change.doc.data() });
                } else if (change.type === "modified") {
                  response.push({ id: change.doc.id, ...change.doc.data() });
                } else if (change.type === "removed") {
                  response.push({
                    id: change.doc.id,
                    ...change.doc.data(),
                    isDeleted: true,
                  });
                }
              });
              cb(response);
            } else {
              cb([]);
            }
          },
          (error) => {
            console.error("error", error.message);
            cb([]);
          }
        );
      } catch (error) {
        console.error("error",error)
          errorEmail({
          projectName : process.env.VUE_APP_PROJECTNAME,
          environment : process.env.NODE_ENV,
          fileName : currentFileName,
          hookName : "firebaseQuery",
          errorMessage : error.message
        },(res)=>{
           if(res.error){
              console.error(res.error)
          }
        })
      }
    },
    deletegrade(rowIndex, id) {
      
      var self = this;
      self.$bvModal
        .msgBoxConfirm("Are you sure you want to Delete it?", {
          title: "Delete",
          class: "test",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-primary",
          hideHeaderClose: false,
          centered: true,
          footerClass: "approve-footer",
          headerClass: "approve-header",
          bodyClass: "approve-body",
        })
        .then((value) => {
          if (value) {
            self.isSpinner = true;
            db.collection(dbCollections.SETTINGS)
              .doc("setting")
              .collection(dbCollections.GRADE)
              .doc(id)
              .delete()
              .then(() => {
                var index = self.gradeDisplay.findIndex(item => {
                    return item.id === id
                })
                if(index === 0 && self.gradeDisplay.length === 1){
                  self.gradeDisplay.splice(index,1)
                }
                self.$root.$emit(
                  "showToastMessage",
                  "Grade deleted successfully.",
                  "success"
                );
                 if (rowIndex === 0 && this.gradeDisplay.length === 1) {
                    this.gradeDisplay = [];
                  }
                self.isSpinner = false;
              });
          }
        });
    },
    editGrade(rowIndex, grade) {
      var self = this;
      self.$root.$emit("editGrade", true, rowIndex, grade);
    },
  },
};
</script>
