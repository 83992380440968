<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />

     <b-card>
      <validation-observer ref="simpleRules">
        <b-form class="paddingtop">
          <b-row>
            <b-col md="3">
              <b-form-group label-for="email">
                <label for="email" style="color:'red';">Email Subject<span style="color: red;">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="email subject"
                  rules="required"
                >
                  <b-form-input
                    v-model="emailValue"
                    type="text"
                    placeholder="Enter Email Subject"
                    @keyup="changeInEmailTemplate()"
                    :disabled="isSpinner"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label-for="fromname">
                <label for="fromname" style="color:'red';">From Name<span style="color: red;">*</span></label>
                <validation-provider
                  #default="{ errors }"
                  name="from name"
                  rules="required|max:25"
                >
                  <b-form-input
                    v-model="name"
                    placeholder="Enter From Name"
                    :disabled="isSpinner"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="User Type"
                label-for="usertype"             
              >
               <div class="usertype_checkbox">
                  <b-form-radio-group 
                    id="radios2"
                    v-model="usertype_selected"
                    :options="userTypeOption"
                    name="some-radios4"
                    >
                  </b-form-radio-group>
               </div>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label-for="schedulecampaign"
                class="schedulecampaign-select"
              >
               <label for="schedulecampaign" style="color:'red';">Schedule Campaign<span style="color: red;">*</span></label>
                <div class="demo-inline-spacing schedulecampaign_radios ">
                    <b-form-radio-group 
                      id="radios1"
                      v-model="schedulecampaign_selected"
                      :options="RadioOptions"
                      name="some-radios3"
                      >
                    </b-form-radio-group>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="3" v-if="schedulecampaign_selected === 'later'">
              <b-form-group class="faltpickerWrapper">
                <h5 style="font-weight:600 !important;color:#000000 !important;">Select Date & Time<span style="color: red;">*</span></h5>
                <div>
                  <flat-pickr
                  v-model="dateNtim"
                  class="form-control datetime_picker"
                  placeholder="DD/MM/YYYY HH:MM"
                  :config="{ enableTime: true, dateFormat: 'd-m-Y H:i', minDate: new Date()}"
                  :on-Change="ChangeDateInPicker"
                  :required="true"
                />
                </div>
                <small class="text-danger" v-if="this.requireDateErrorMsg !== '' || dateNtim === null || dateNtim === ''">{{ requireDateErrorMsg }}</small>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <div class="paddingbottom">
        <b-form-group>
          <label for="fromname" style="color:'red';" class="formatemail">Format Email<span style="color: red;">*</span></label>
              <EmailEditor
                ref="emailEditor"
                v-on:load="editorLoaded"
                v-on:ready="editorReady"
                :appearance="appearance"
                :min-height="minHeight"
                :project-id="projectId"
                :locale="locale"
                :tools="tools"
                :options="options"
              />
        </b-form-group>
      </div>
    </b-card>
          </div>
  </div>
  
</template>

<script>
import { EmailEditor } from 'vue-email-editor';
import { ValidationProvider, ValidationObserver } from "vee-validate";
const { AutoTemplate, EditTemplate } = require('./sample.js')

import {
  BCard,
  BFormDatepicker,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormCheckbox,
  BFormRadio,
  BSpinner,
  BFormRadioGroup
} from "bootstrap-vue";
import { required, email } from "@validations";
import flatPickr from "vue-flatpickr-component";
import axios from "axios"
import {dbCollections} from "@/utils/firebaseCollection.js";
import * as insertDatabase from "@/utils/FirebaseQueries/insertQueries/insertQueries.js";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Setting/Createemailtemplate.vue"
import { mapGetters, mapActions } from "vuex";
import moment from 'moment'
import {createEmailTemplates} from './service.js'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormInput,
    BFormGroup,
    BFormRadioGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BFormDatepicker,
    BCard,
    BSpinner,
    flatPickr,
    BFormRadio,
    EmailEditor,
   
  },
  data() {
    return {
      isSpinner:false,
      isUpdating:false, 
      emailValue: "",
      htmlContent:"",
      name: "",
      recheckEmailValue: "",
      recheckName:'',
      recheckTemplate: '',
      required,
      email,
      requireDateErrorMsg:'',
      usertype_selected: "all",
      schedulecampaign_selected: "later",
      date: null,
      dateDefault: null,
      timePicker: null,
      dateNtim: null,
      dateNtims: null,
      multiDate: null,
      rangeDate: null,
      humanDate: null,
      disableDate: null,
      timeoutClear:null,
      inlineDate: null,
      editEmailTemplate:false,
      minDate:new Date(),
      enableButtonAfterLoad: false,
      RadioOptions: [
        { text: 'Now', value: 'now' },
        { text: 'Later', value: 'later' },
      ],
      userTypeOption: [
        {text: 'All (Learner/Tutor)', value: 'all'},
        {text: 'Only Tutor', value: 'tutor'}
      ],
      emailTemplateId:'',
      // editor feilds
      minHeight: '2000px',
      locale: 'en',
      projectId: 0, // replace with your project id
      tools: {
        // disable image tool
        image: {
          enabled: true,
        },
      },
      options: {},
      appearance: {
        theme: 'light',
        panels: {
          tools: {
            dock: 'right',
          },
        },
      },
      mailVeriable:'',
      templateId:this.$route.params.id || '',
      editedMailTemplates:'',
      userEmailArray:[],
      requireCheckboxErrorMsg:''
    };
  },
    created(){
      setTimeout(()=>{
        this.$root.$emit('stopButtonDisabled');
        this.$root.$emit('stopSpinner');
      },300)
      var self = this;
      let getEmailTempFound = this.MailTemplateData.length !== 0;
      if(!getEmailTempFound){
        this.getEmailsData().then(()=>{
          const isInEmailArray = this.MailTemplateData.some(function(item){
            return item.id === self.templateId
          })
          if(!isInEmailArray && this.templateId !== ""){
            self.$router.push({ name: "email-template" });
            self.isSpinner = false;
          }else{
            this.EditEmailTemplate();
          }
        }).catch(error=>{
          console.error("error",error)
        })
      }else{
        if(this.templateId !== ''){
          const isInEmailArrays = this.MailTemplateData.some(function(item){
            return item.id === self.templateId
          })
          if(!isInEmailArrays && this.templateId !== ""){
            self.$router.push({ name: "email-template" });
            self.isSpinner = false;
          }else{
            this.EditEmailTemplate();
          }
        }
      }
      // THIS EMIT IS CALL FROM APPBREADCRUMB.VUE (Shraddha)
      self.$root.$on('createEmailTemplate',() => {
        self.saveDesign();
      })
      // THIS EMIT IS CALL FROM APPBREADCRUMB.VUE (Shraddha)
      self.$root.$on('cancelEmailData',() => {
        self.$router.push({name: 'email-template'})
      })

    },
    beforeRouteLeave(to,from,next){
      if(to.name !== from.name){
        clearTimeout(this.timeoutClear)
        next()
      }else{
        next()
      }
    },
    methods: {
      ...mapActions({
        getEmailsData:'emailTemplate/getMailData',
        getUserDatas:"userManagement/getUsersData"
      }),
      ChangeDateInPicker(){
        if(this.dateNtim === null){
          return;
        }else if(this.dateNtim !== null){
          this.requireDateErrorMsg = ''
        }
      },
      exportHtml() {
        this.$refs.emailEditor.editor.exportHtml((data) => {
          this.htmlContent = data;
        });
      },
      //CALL WHEN EDITOR IS CREATED (Shraddha)
      editorLoaded() {
        
        let self = this;
        self.timeoutClear = setTimeout(()=>{
          if(self.templateId == ''){
            self.$refs.emailEditor.editor.loadDesign(AutoTemplate());
          }else{
            self.$refs.emailEditor.editor.loadDesign(this.editedMailTemplates);
          }
        },1500)
      },
      exportHtml() {
        this.$refs.emailEditor.editor.exportHtml((data) => {
          this.htmlContent = data.html
        });
      },
    // CALL WHEN EDITOR IS FINISHED LOADING (Shraddha)
      editorReady() {
        // console.log('editorReady');
        this.enableButtonAfterLoad = true
      },
      changeInEmailTemplate(){
      },
      saveDesign() {
        var self = this;
        self.isSpinner = true;
        this.$root.$emit('startButtonDisabled')
        try{
          if(self.checkDates(self.dateNtim)< new Date()){
            this.requireDateErrorMsg = "The select date and time is invalid"
              self.isSpinner = false;
              setTimeout(()=>{
                this.$root.$emit('stopButtonDisabled');
                self.$root.$emit('stopSpinner')            
              },200)
            return;
          }
          self.$refs.simpleRules.validate().then((success) => {
            if(this.dateNtim === null && this.schedulecampaign_selected === 'later'){
              this.requireDateErrorMsg = "The select date and time field is required"
              self.isSpinner = false;
              setTimeout(()=>{
                this.$root.$emit('stopButtonDisabled');
                self.$root.$emit('stopSpinner')            
              },200)
              return;
            }
            if(success === false && this.dateNtim === '' && this.schedulecampaign_selected === 'later'){
              this.requireDateErrorMsg = "The select date and time field is required"
              self.isSpinner = false;
              setTimeout(()=>{
                this.$root.$emit('stopButtonDisabled');
                self.$root.$emit('stopSpinner')            
              },200)
              return;
            }
            if(success === true && this.dateNtim === '' && this.schedulecampaign_selected === 'later'){
              this.requireDateErrorMsg = "The select date and time field is required"
              self.isSpinner = false;
              setTimeout(()=>{
                self.$root.$emit('stopSpinner')    
                this.$root.$emit('stopButtonDisabled');        
              },200)
              return;
            }
            if (success && this.enableButtonAfterLoad === true) {
              self.$refs.emailEditor.editor.saveDesign(async(design) => {
                await this.exportHtml()
                if(!self.editEmailTemplate){  // IF NEW EMAIL TEMPLATE IS CREATED
                  let designObject = {
                    emailTemplate :JSON.stringify(design),
                    createdAt : new Date(),
                    updatedAt : new Date(),
                    emailSubject : self.emailValue,
                    scheduleCampaign : self.schedulecampaign_selected,
                    fromName : self.name,
                    sendEmailDateAndTime : (self.dateNtim && self.schedulecampaign_selected === "later") ? self.checkDates(self.dateNtim) : self.schedulecampaign_selected === "now" ? new Date() : '',
                    userType:self.usertype_selected,
                    status: self.schedulecampaign_selected === "later"? 1 : 0, 
                  }
                  let emailTemplateId = ""
                  insertDatabase.addDataToRootCollection(dbCollections.EMAILMAILTEMPLATE,designObject,(res) => {
                    emailTemplateId = res.id
                    const designObject2 = {
                      id : emailTemplateId,
                      emailTemplateString: this.htmlContent
                    }
                    updateFirebase.updateRootCollectionDataByDocId(dbCollections.EMAILMAILTEMPLATE,emailTemplateId,designObject2,(res)=>{
                      if(self.schedulecampaign_selected === "later"){
                        self.$root.$emit('showToastMessage','Email template saved successfully.','success');
                      }
                        self.isSpinner = false;
                        setTimeout(()=>{
                          this.$root.$emit('stopButtonDisabled');
                          self.$root.$emit('stopSpinner')            
                        },200)
                        if(self.schedulecampaign_selected === "now"){
                          createEmailTemplates({
                            scheduleCampaign: self.schedulecampaign_selected,
                            userType: self.usertype_selected,
                            emailHtml: self.htmlContent,
                            id: emailTemplateId
                          }, (res) => {
                             if(res.status === true){
                                self.$root.$emit('showToastMessage',`Email sent successfully.`,'success');
                              }else{
                                self.$root.$emit('showToastMessage',`Email not sent successfully.`,'danger');
                              }
                          })
                          self.resetData();
                        }else{
                          self.resetData();
                        }
                       self.$router.push({name: 'email-template'})
                    })
                  })
                }
                else{ // WHEN EDIT EMAIL TEMPLATE                        
                  let designObject = {
                    emailTemplate :JSON.stringify(design),
                    updatedAt : new Date(),
                    emailSubject : self.emailValue,
                    scheduleCampaign : self.schedulecampaign_selected,
                    fromName : self.name,
                    userType:self.usertype_selected,
                    sendEmailDateAndTime: (self.dateNtim && self.schedulecampaign_selected === "later") ? self.checkDates(self.dateNtim) : self.schedulecampaign_selected === "now" ? new Date() : '',
                    status:self.schedulecampaign_selected === "later"? 1 : 0
                  }
                  updateFirebase.updateRootCollectionDataByDocId(dbCollections.EMAILMAILTEMPLATE,self.templateId,designObject,(res) => {
                      let designObject2 = {
                          emailTemplateString: this.htmlContent
                      }
                      updateFirebase.updateRootCollectionDataByDocId(dbCollections.EMAILMAILTEMPLATE,self.templateId,designObject2,(res) => {
                        if(self.schedulecampaign_selected === "later"){
                          self.$root.$emit('showToastMessage','Email template updated successfully.','success');
                        }
                        self.isSpinner = false;
                        setTimeout(()=>{
                          this.$root.$emit('stopButtonDisabled');
                          self.$root.$emit('stopSpinner')               
                        },200)
                        if(self.schedulecampaign_selected === "now"){
                          createEmailTemplates({
                              scheduleCampaign: self.schedulecampaign_selected,
                              userType: self.usertype_selected,
                              emailHtml: self.htmlContent,
                              id: self.templateId
                          }, (res) => {
                             if(res){
                                self.$root.$emit('showToastMessage',`${res.data.statusText}`,'success');
                              }else{
                                self.$root.$emit('showToastMessage',`${res.data.statusText}`,'danger');
                              }
                          })
                        }
                        self.$router.push({name: 'email-template'})
                        self.resetData();
                      })
                  })
                }
              });
            }
            else if(success && this.enableButtonAfterLoad === false){
              self.$root.$emit('showToastMessage','Please wait untill email editor load.','danger');
              self.isSpinner = false;
              setTimeout(()=>{
                this.$root.$emit('stopButtonDisabled');
                self.$root.$emit('stopSpinner')               
              },200)
            }
            else{
              self.isSpinner = false;
              setTimeout(()=>{
                this.$root.$emit('stopButtonDisabled');
                self.$root.$emit('stopSpinner')               
              },200)
            }
          });
        }
        catch(error){
          self.isSpinner = false;
          console.error('error',error);
          errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "saveDesign",
            errorMessage : error.message
          },(mailRes) => {
            if(mailRes.error){
              console.error(mailRes);
            }
          })
          setTimeout(()=>{
            this.$root.$emit('stopButtonDisabled');s
            self.$root.$emit('stopSpinner')               
          },200)
        }
      },
      checkDates(bannerDate){
        var dates = moment(bannerDate, 'DD/MM/YYYY HH:mm').format('MM/DD/YYYY HH:mm:ss');
        return new Date(dates);
      },
      // FORMAT BANNER CREATE DATE AS PER REQUIREMENT (Shraddha)
      checkDate(bannerCreateDate){
        var bannerDate = new Date(bannerCreateDate.seconds*1000);
        var returnBannerDate = moment(bannerDate).format('DD/MM/YYYY HH:mm');
        return returnBannerDate;
      },
      formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;
        return strTime;
      },
      addZero(value){
        if(value > 9){
          return value;
        }else{
          return '0' + value;
        }
      },
      resetData(){
        var self = this;
        self.name = '';
        self.emailValue= '',
        self.schedulecampaign_selected = "later",
        self.dateNtim = null,
        self.usertype_selected = "all",
        self.$refs.simpleRules.reset();
        self.isSpinner = false;
        self.templateId = '';
      },
      EditEmailTemplate(){
        try {
          var self = this;
        
        self.MailTemplateData.forEach(mailEditData => {
          if(mailEditData.id === self.templateId){
            self.editEmailTemplate = true;
            self.name = mailEditData.fromName;
            self.emailValue= mailEditData.emailSubject;
            self.schedulecampaign_selected = mailEditData.scheduleCampaign;
            self.dateNtim = mailEditData.sendEmailDateAndTime ? self.checkDate(mailEditData.sendEmailDateAndTime) : '';
            self.usertype_selected = typeof(mailEditData.userType) == "string" ? mailEditData.userType : "all";
            self.editedMailTemplates = JSON.parse(mailEditData.emailTemplate);
            self.recheckEmailValue = mailEditData.emailSubject;
            self.recheckName = mailEditData.fromName;
            self.recheckTemplate = JSON.parse(mailEditData.emailTemplate);
            self.emailTemplateId = mailEditData.id
          }
        })
        } catch (error) {
          errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "EditEmailTemplate",
            errorMessage : error.message
          },(mailRes) => {
            if(mailRes.error){
              console.error(mailRes);
            }
          })
          console.error("error",error)
        }
      }
    },
    computed: {
       ...mapGetters({MailTemplateData:"emailTemplate/getEmailData",userDataList:"userManagement/getUsers"}),
    },
    watch:{
      dateNtim(val){
        if(val === null){
          this.requireDateErrorMsg = "The select date and time field is required."
        }else{
          this.requireDateErrorMsg = ""
        }
      }
    },
  beforeDestroy() {
    this.$root.$off("createEmailTemplate");
    this.$root.$off("cancelEmailData");
  }
};
</script>

<style lang="scss">

@import "@core/scss/vue/libs/vue-flatpicker.scss";

.unlayer-editor iframe {
    min-height: 700px!important;
}

.faltpickerWrapper
{
  position: relative;
}
.demo-inline-spacing.schedulecampaign_radios .bv-no-focus-ring {
    margin-top: 1rem;
}
input.form-control.datetime_picker {
    background-position: 94% 50% !important;
    padding-left: 13px !important;
    border: 1px solid #979797 !important;
}
input.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #999999;
}
form.paddingtop
 {
    border-bottom: 1px solid gainsboro;
    padding-bottom: 10px;
}
.paddingbottom {
    padding-top: 22px;
}
.paddingbottom label.formatemail {
    padding-bottom: 12px;
}
</style>





