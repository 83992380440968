<template>
  <div>
    <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />
      <div class="general_setting_section">
        <b-card no-body>
            <b-form style="background-color: white;">
              <div class="type_title">
                <b-row>
                  <b-col md="6">
                    <h2>Stripe Settings</h2>
                  </b-col>
                  <b-col md="6" class="text-right">
                    <b-button
                      variant="outline-primary"
                      @click="cancleChanges()"
                    >
                      Cancel
                    </b-button>
                    <b-button
                      variant="primary"
                      @click="saveChanges()"
                    >
                      Save
                    </b-button>
                  </b-col>
                </b-row>
              </div>
                <div class="form-group-padding">
                <validation-observer ref="stripeSettingForm">
                  <b-row>
                    <b-col md="6">
                    
                      <validation-provider
                        #default="{ errors }"
                        name="secret key"
                        rules="required"
                      >
                      <b-form-group>
                      <label label-for='secretKey'>Secret Key<span class="required">*</span></label>
                        <b-input-group>
                          <b-form-input
                            v-model="secretKey"
                            placeholder="Enter Secret Key"
                            :type="passwordFieldType"
                            @blur="secretKey = secretKey.trim()"
                          />
                          <b-input-group-append is-text>
                              <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="toggleSecretKeyVisibility1" />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                      </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                  
                    <validation-provider
                      #default="{ errors }"
                      name="end point signing secret"
                      rules="required"
                    >
                    <b-form-group>
                    <label label-for='endPointKey'>End Point Signing Secret<span class="required">*</span></label>
                      <b-input-group>
                        <b-form-input
                          v-model="endPointKey"
                          placeholder="Enter End  Signing Secret"
                          :type="passwordFieldType1"
                          @blur="endPointKey = endPointKey.trim()"
                        />
                        <b-input-group-append is-text>
                            <feather-icon class="cursor-pointer" :icon="passwordToggleIcon1" @click="toggleEndPointKeyVisibility" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger" style="display:block;">{{ errors[0] }}</small>
                    </b-form-group>
                      </validation-provider>
                  </b-col>
                  </b-row>
                  </validation-observer>
                </div>
            </b-form>
        </b-card>
    </div>
    </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
  BCard,
  BInputGroupAppend,
  BInputGroup,
  BSpinner
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import * as getQueries from '@/utils/FirebaseQueries/getQueries/getQueries'
import * as updateQueries from '@/utils/FirebaseQueries/updateQueries/updateQueries'
import  * as insertQueries from '@/utils/FirebaseQueries/insertQueries/insertQueries'
import { dbCollections } from '@/utils/firebaseCollection';
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Setting/Paymentgetwaymgmt.vue"
export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BRow,
        BCol,
        BForm,
        BButton,
        ValidationProvider, ValidationObserver,
        BCard,
        BInputGroupAppend,
        BInputGroup,
        BSpinner
    },
    data() {
        return {
            secretKey: "",
            endPointKey: "",
            isSpinner: false,
            isUpdating: false,
            passwordFieldType: "password",
            passwordFieldType1: "password",
            oldSecretKey: "",
            oldEndPointKey: "",
            stripeSettingId:""
        };
    },
    created() {
        this.init();
    },
    computed:{
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
        passwordToggleIcon1() {
            return this.passwordFieldType1 === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    methods: {
        init() {
            try {
                var self = this;
                self.isSpinner = true;
                getQueries.simpleGetDataFromSubCollectionWithCondition(
                    dbCollections.SETTINGS, "setting", 
                    dbCollections.PAYMENTGATWAYS, "type", "==", "stripe", (resData) => {
                        if(resData.length !== 0) {
                            self.stripeSettingId = resData[0].id;
                            self.oldSecretKey = resData[0].secretKey;
                            self.oldEndPointKey = resData[0].endPointKey;
                            self.secretKey = resData[0].secretKey;
                            self.endPointKey = resData[0].endPointKey;
                            self.isSpinner = false;
                        } else {
                            self.secretKey = "";
                            self.endPointKey = "";
                            self.isSpinner = false;
                        }
                    })
            } catch (error) {
                console.error(error);
                this.isSpinner = false;
                errorEmail({
                  projectName : process.env.VUE_APP_PROJECTNAME,
                  environment : process.env.NODE_ENV,
                  fileName : currentFileName,
                  hookName : "init",
                  errorMessage : error.message
                },(res)=>{
                  if(res.error){
                      console.error(res.error)
                  }
                });
            }
        },
        toggleSecretKeyVisibility1() {
            if(this.passwordFieldType === "password") {
                this.passwordFieldType = "text";
            } else {
              this.passwordFieldType = "password";
            }
        },
        toggleEndPointKeyVisibility() {
            if(this.passwordFieldType1 === "password") {
                this.passwordFieldType1 = "text";
            } else {
              this.passwordFieldType1 = "password";
            }
        },
        cancleChanges() {
            this.secretKey= this.oldSecretKey;
            this.endPointKey= this.oldEndPointKey;
            this.passwordFieldType = "password";
            this.passwordFieldType1 = "password";
        },
        saveChanges() {
            try {
                var self = this;
                this.$refs.stripeSettingForm.validate().then((valid) => {
                    if(valid && (self.secretKey !== self.oldSecretKey || self.endPointKey !== self.oldEndPointKey)) {
                        self.isSpinner = true;
                        if(self.stripeSettingId !== "") {
                          const updateObj = {
                              secretKey : self.secretKey,
                              endPointKey: self.endPointKey,
                              updatedAt: new Date()
                          }
                          updateQueries.updateRootSubCollectionDataWithDocId(dbCollections.SETTINGS, "setting", 
                              dbCollections.PAYMENTGATWAYS, self.stripeSettingId,updateObj ,() => {
                                  self.oldSecretKey = self.secretKey;
                                  self.oldEndPointKey = self.endPointKey;
                                  self.passwordFieldType = "password";
                                  self.passwordFieldType1 = "password";
                                  self.isSpinner = false;
                                  this.$root.$emit(
                                    "showToastMessage",
                                    "Stripe settings saved successfully.",
                                    "success"
                                  );
                              })
                        } else {
                            const stripeSettingObj = {
                                createdAt: new Date(),
                                id: "",
                                type: "stripe",
                                secretKey : self.secretKey,
                                endPointKey: self.endPointKey,
                                updatedAt: new Date()
                            }
                            insertQueries.addDataToRootSubCollectionWithDocKey(dbCollections.SETTINGS, "setting", 
                                dbCollections.PAYMENTGATWAYS , stripeSettingObj, (res) => {
                                    updateQueries.updateRootSubCollectionDataWithDocId(dbCollections.SETTINGS, "setting", 
                                        dbCollections.PAYMENTGATWAYS, res.id, {id : res.id} ,() => {
                                            self.stripeSettingId = res.id
                                            self.oldSecretKey = self.secretKey;
                                            self.oldEndPointKey = self.endPointKey;
                                            self.passwordFieldType = "password";
                                            self.passwordFieldType1 = "password";
                                            self.isSpinner = false;
                                            this.$root.$emit(
                                              "showToastMessage",
                                              "Stripe settings saved successfully.",
                                              "success"
                                            );  
                                        });
                                });
                        }
                    }
                });
            } catch (error) {
                console.error(error);
                this.isSpinner = false;
                errorEmail({
                  projectName : process.env.VUE_APP_PROJECTNAME,
                  environment : process.env.NODE_ENV,
                  fileName : currentFileName,
                  hookName : "saveChanges",
                  errorMessage : error.message
                },(res)=>{
                  if(res.error){
                      console.error(res.error)
                  }
                });
            }
        }
    },
};
</script>



