<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners loaderDetail"
    />
    <div v-show="!isSpinner">
    <b-tab title="Holiday List" @click="onHoliday">
      <div class="user_table holiday_table">
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="holidaysearchTerm"
                placeholder="Search..."
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <vue-good-table
          :columns="holidaycolumns"
          :rows="holidayrows"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: holidaysearchTerm,
          }"
          :select-options="{
            enabled: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'action'" class="action_field">
              <b-button variant="flat-dark">
                <feather-icon icon="EditIcon" size="18" />
              </b-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> 
                  <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to 
                  <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                  <span v-else>{{props.total}} of {{ props.total }} entries</span>
                </span>

                    <div class="d-flex align-items-center mb-1 mt-0 showentry holidaylist_usedetailtab">
                      <span>Show</span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10', '20', '30', '40', '50']"
                        class="mx-1"
                        @input="
                          (value) => props.perPageChanged({ currentPerPage: value })
                        "
                     />
                      <span>entries</span>
                    </div>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :current-page="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => {
                      props.pageChanged({ currentPage: value });
                      currentPage = value
                    }
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-tab>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "UserdetailHolidayList",
  props: {
    userDetail: Object,
  },
  components: {
    VueGoodTable,
    BCard,
    BImg,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BCardText,
    BTabs,
    BTab,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BForm,
    BButton,
    flatPickr,
    BSpinner,
  },
  data() {
    return {
      holidaysearchTerm: "",
      holidaycolumns: [
        {
          label: "Start Date",
          field: "startdate",
        },
        {
          label: "Start Time",
          field: "starttime",
        },
        {
          label: "End Date",
          field: "enddate",
        },
        {
          label: "End Time",
          field: "endtime",
        },
      ],
      holidayrows: [],
      direction: false,
      pageLength: 10,
      currentPage: 1,
      isSpinner: false,
      count:0,
    };
  },
  computed: {
    ...mapGetters({
      users: "userManagement/getUsers",
    }),
  },
  watch: {
    userDetail(newV, preV) {
      if (newV !== preV && newV !== null) {
        this.holidayData();
      }
    },
  },

  methods: {
    holidayData() {
      var obj = {};
      this.holidayrows = [];
      if(this.userDetail.tutorHolidays){
          this.isSpinner = true;
      }
      if (
        this.userDetail &&
        this.userDetail.tutorHolidays &&
        this.userDetail.tutorHolidays.length > 0
      ) {
        for (let i = 0; i < this.userDetail.tutorHolidays.length; i++) {
          obj = {
            startdate: moment(
              this.userDetail.tutorHolidays[i].from.seconds * 1000
            ).format("DD-MM-YYYY"),
            starttime: moment(
              this.userDetail.tutorHolidays[i].from.seconds * 1000
            ).format("hh:mm A"),
            enddate: moment(
              this.userDetail.tutorHolidays[i].to.seconds * 1000
            ).format("DD-MM-YYYY"),
            endtime: moment(
              this.userDetail.tutorHolidays[i].to.seconds * 1000
            ).format("hh:mm A"),
          };
            if (this.userDetail.tutorHolidays.length == this.holidayrows.length + 1) {
              this.isSpinner = false;
            }
          this.holidayrows.push(obj);
        }
      } else {
        this.isSpinner = false;
      }
    },
    onHoliday() {
      var allTabList = document.getElementsByClassName("nav-link active");
      if (allTabList[0].innerText != "Holiday List") {
        this.holidayData();
      }
    },
  },
};
</script>

<style></style>
