<template>
    <div>
        <b-spinner
            variant="primary"
            v-if="isSpinner"
            large
            class="pageLoadSpinners loaderDetail"
        />
        <div v-show="!isSpinner">
            <b-tab title="Canceled Session(s)" @click="onCancelSession">
                <div class="card user_filter" v-if="!isSpinner">
                    <h2>Filter</h2>
                    <b-form @submit.prevent>
                        <b-row>
                            <b-col xl="3" lg="4" md="5">
                                <label>Date</label>
                                <flat-pickr
                                    v-model="dateNtimUpcomig"
                                    class="form-control datetime_picker"
                                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', maxDate: new Date()}"
                                />
                            </b-col>
                            <b-col xl="3" lg="4" md="5">
                                <label>Learner Name</label>
                                <b-dropdown
                                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                                    :text="tutor_type_selected_learner"
                                    variant="outline-secondary"
                                    class="w-100"
                                    >
                                    <b-dropdown-item
                                    v-for="item in tutor_name_options_learner"
                                    @click="tutor_type_selected_learner = item.text"
                                    :key="item.value"
                                    >{{ item.text }}</b-dropdown-item>
                                </b-dropdown>
                            </b-col>
                        </b-row>
                    </b-form>
                </div>
                <div
                    class="
                    card
                    user_table
                    session_table
                    canceled_session_table
                    tutor_canceled_session_table" v-if="!isSpinner"
                >
                    <div class="custom-search d-flex justify-content-end">
                        <b-form-group>
                            <div class="d-flex align-items-center">
                                <b-form-input
                                    v-model="canceledsessionsearchTermtutor"
                                    placeholder="Search..."
                                    type="text"
                                    class="d-inline-block"
                                />
                            </div>
                        </b-form-group>
                    </div>
                    <vue-good-table
                    :columns="canceledsessioncolumns"
                    :rows="filterRowsLearner"
                    :rtl="direction"
                    class="cursor_pointer"
                    @on-cell-click="onCellClick"
                    :search-options="{
                        enabled: true,
                        externalQuery: canceledsessionsearchTermtutor,
                    }"
                    :select-options="{
                        enabled: true,
                        selectionInfoClass: 'custom-class',
                        selectionText: 'rows selected',
                        clearSelectionText: 'clear',
                        disableSelectInfo: true, // disable the select info panel on top
                    }"
                    :pagination-options="{
                        enabled: true,
                        perPage: pageLength,
                    }"
                    >
                        <template slot="table-row" slot-scope="props">
                            <span
                            v-if="props.column.field === 'bookingid'"
                            class="text-nowrap"
                            >
                                <span
                                    class="text-nowrap"
                                    >{{ props.row.bookingid }}</span
                                >
                            </span>
                            <span
                            v-else-if="props.column.field === 'learnername'"
                            class="text-nowrap"
                            >
                                <b-avatar :src="props.row.avatar" class="user_img" />
                                <span class="userid" @click="getDataAgain()">
                                    <router-link :to="{
                                        name: 'user-details',
                                        params: {
                                            id: props.row.bookinglernerId
                                        },
                                    }"
                                    class="text-nowrap"
                                    >{{ props.row.learnername }}</router-link>
                                </span>
                            </span>
                    
                            <span
                                v-else-if="props.column.field === 'status'"
                                class="text-nowrap"
                                style="text-transform: capitalize !important;"
                                >
                                <b-badge style="text-transform: capitalize !important;" :variant="getTutorTypeBadgeVariant(props.row.status )">
                                    {{getAllStatusList.cancelReasonStatus[props.row.status ]}}
                                </b-badge>
                            </span>

                
                            <span
                            v-else-if="props.column.field === 'cancellationdate'"
                            class="text-nowrap"
                            >
                                {{props.row.cancellationdate}}
                            </span>


                            <div
                            v-else-if="props.column.field === 'sessiontitle'"
                            class="wrap-break-word"
                            v-show="true"
                            style="word-break:break-all"
                            >
                                {{props.row.sessiontitle}}
                            </div>
                            <span
                            v-else-if="props.column.field === 'action'"
                            class="action_field action_field1518"
                            >
                                <span v-if="statusOfTutor === 0 || statusOfTutor === 1">
                                    <b-dropdown
                                    variant="link"
                                    :disabled="userStatus == 4"
                                    toggle-class="text-decoration-none"
                                    no-caret
                                    v-if="props.row.status === 0 && statusOfTutorForSuspend === false"
                                    >
                                        <template v-slot:button-content>
                                            <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="text-body align-middle mr-25"
                                            />
                                        </template>
                                        <b-dropdown-item v-b-modal.modal-approve @click="selectedAction = props.row.Id,selectedTutorID = props.row.bookingTutor">
                                            <span>
                                            <feather-icon icon="CheckSquareIcon" size="18" />
                                            Approve</span
                                            >
                                        </b-dropdown-item>
                                        <b-dropdown-item v-b-modal.modal-suspends @click="selectedAction = props.row.Id,selectedTutorID = props.row.bookingTutor">
                                            <span>
                                            <feather-icon icon="XCircleIcon" size="18" />
                                            Suspend Tutor</span
                                            >
                                        </b-dropdown-item>
                                        <b-dropdown-item v-if ="warningOfTutor < getsettingList.userMaxWarningsToGetSuspend" v-b-modal.modal-warning @click="selectedAction = props.row.Id,selectedTutorID = props.row.bookingTutor">
                                            <span>
                                            <feather-icon icon="AlertTriangleIcon" size="18" />
                                            Give Warning</span
                                            >
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </span>
                            </span>
                
                            <span v-else>
                            {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>
                        <template slot="pagination-bottom" slot-scope="props">
                            <div class="d-flex justify-content-between flex-wrap">
                            <div class="d-flex align-items-center mb-0 mt-1">
                                <span class="text-nowrap"> 
                                <span v-if="props.total === 0"> Showing 0 </span>
                                <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                                to
                                <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                                <span v-else>{{props.total}} of {{ props.total }} entries</span>
                                </span>

                                    <div class="d-flex align-items-center mb-1 mt-0 showentry">
                                    <span>Show</span>
                                    <b-form-select
                                        v-model="pageLength"
                                        :options="['10', '20', '30', '40', '50']"
                                        class="mx-1"
                                        @input="
                                        (value) => props.perPageChanged({ currentPerPage: value })
                                        "
                                    />
                                    <span>entries</span>
                                    </div>
                            </div>
                            <div>
                                <b-pagination
                                :value="1"
                                :total-rows="props.total"
                                :current-page="currentPage"
                                :per-page="pageLength"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="
                                    (value) => {
                                    props.pageChanged({ currentPage: value });
                                    currentPage = value
                                    }
                                "
                                >
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                                </b-pagination>
                            </div>
                            </div>
                        </template>
                    </vue-good-table>
                </div>
            </b-tab>
        </div>
        <b-modal
            id="modal-approve"
            centered
            title=""
            ok-title="Yes"
            cancel-title="No"
            @ok="handlerForApprovation"
            >
            <img
                src="@/assets/images/tutorimages/approve.png"
                alt="profile"
                class="approve_image"
            />
            <h4>Approve</h4>
            <b-card-text class="approve-body12"> Are you sure you want to Approve? </b-card-text>
        </b-modal>

        <b-modal
            id="modal-warning"
            centered
            title=""
            ok-title="Yes"
            cancel-title="No"
            @ok="handlerForGiveWarning"
            >
            <img
                src="@/assets/images/tutorimages/warning.png"
                alt="profile"
                class="warning_image"
            />
            <h4 >Give Warning</h4>
            <b-card-text class="approve-body12">
                Are you sure you want to Give Warning?
                <span v-if="this.warningOfTutor > 0">You had already given {{warningOfTutor}} warning
                before.</span>
            </b-card-text>
        </b-modal>

        <b-modal
            id="modal-suspends"
            centered
            title=""
            ok-title="Yes"
            cancel-title="No"
            @ok="handlerForSuspendTutor"
            >
            <img
                src="@/assets/images/erflog/cancel.png"
                alt="profile"
                class="warning_image"
            />
            <h4>Suspend User</h4>
            <b-card-text class="approve-body12 suspendLine">
                Are you sure you want to Suspend User? This will cancel all future tutor session and give students their credits back.
            </b-card-text>
        </b-modal>
      
    </div>
</template>
<script>
import {
  BCard,
  BImg,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BTabs,
  BTab,
  BCardText,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";

import { dbCollections } from "@/utils/firebaseCollection.js";
import { VueGoodTable } from "vue-good-table";
import flatPickr from "vue-flatpickr-component";
import Ripple from "vue-ripple-directive";
import moment from "moment";
import * as getFirebase from "@/utils/FirebaseQueries/getQueries/getQueries";
import {mapGetters} from "vuex";
import UserModal from "@/@core/components/modals/ReadMoreModal.vue";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import firebase from "@/utils/firebaseInit.js";
import functionForSuspendtionAction from '@/utils/globalFunction/suspendActionFun.js';
import functionForSuspendOrBlockChild from '@/utils/globalFunction/suspendChildFunction'
import functionForGiveWarning from '@/utils/globalFunction/giveWarningFun.js';
import status from '@/store/status';
import {sendNotificationUserStatus} from '../../../service.js'
const db = firebase.firestore()
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/Users/User/UserDetailTabs/UserDetail_Canceled_SessionTutor.vue"
import {onWarningGiveNotification} from '@/views/ComplaintManagement/serviceWarningNotification.js'
export default {
    name: "UserDetailCanceledSessionTutor",
    directives: {
        Ripple,
    },
    components: {
        UserModal,
        VueGoodTable,
        BCard,
        BImg,
        BRow,
        BCol,
        BDropdown,
        BDropdownItem,
        BCardText,
        BTabs,
        BTab,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BForm,
        BButton,
        flatPickr,
        BSpinner,
    },
    
    data() {
        return {
            canceledsessioncolumns:[
                {
                    label: 'Booking ID',
                    field: 'bookingid',
                },
                {
                    label: 'Session(s) Title',
                    field: 'sessiontitle'
                },
                {
                    label: 'Learner Name',
                    field: 'learnername'
                },
                {
                    label: 'Cancellation Date',
                    field: 'cancellationdate'
                },
                {
                    label: 'Status',
                    field: 'status'
                },
                {
                    label: 'Action',
                    field: 'action'
                }
            ],
            direction: false,
            pageLength: 10,
            currentPage: 1,
            canceledsessionsearchTerm: "",
            cancelrows:[],
            dateNtimUpcomig:moment(new Date()).format("YYYY-MM-DD HH:mm"),
            tutor_type_selected_learner:"All",
            canceledsessionsearchTermtutor: "",
            cancelrowstutor:[],
            isSpinner:false,
            selectedAction:'',
            selectedTutorID: '',
            warningOfTutor:Number,
            statusOfTutor:Number,
            statusOfTutorForSuspend: false,
            warningOfTuorLast:Number
        }
    },

    props:{
        getDataAgain: Function,
        userStatus: Number,
    },

    computed: {
        ...mapGetters({
            getAllStatusList:'settings/getAllStatus',
            getsettingList: "settings/getsettingArrayFeild"
        }), // lists from store

        tutor_name_options_learner() {
            var learnerName12 = [];
            learnerName12.push({ value: 0, text: "All" });
            for (var i = 0; i < this.cancelrowstutor.length; i++) {
                var temp = false;
                for (var j = 0; j < learnerName12.length; j++) {
                    if (this.cancelrowstutor[i].learnername == learnerName12[j].text) {
                        temp = true;
                    }
                }
                if (!temp) {
                    var obj = {
                        val: i + 1,
                        text: this.cancelrowstutor[i].learnername,
                    };
                    learnerName12.push(obj);
                }
            }
            return learnerName12;
        },

        filterRowsLearner() {
            var self = this;
            var listA =  this.cancelrowstutor;
            if (self.tutor_type_selected_learner === "All" && self.dateNtimUpcomig === moment(new Date()).format("YYYY-MM-DD HH:mm")) {
                return listA;
            }
            if (self.tutor_type_selected_learner !== "All") {
                listA = listA.filter((data) => {
                    return data.learnername
                    .toLowerCase()
                    .includes(self.tutor_type_selected_learner.toLowerCase());
                });
            }
            if (this.dateNtimUpcomig !== new Date()) {
                listA = listA.filter((data) => {
                    return data.cancellationDateCalander < this.dateNtimUpcomig;
                });
            }
                return listA;
        }

    },
    methods: {
        onCellClick(data) {
            if (data.column.field==="learnername") {
                this.$router.push({name: 'user-details', params: { id: data.row.bookinglernerId }})
            }
            else if(data.column.field==="action"){
                return false;
            }
            else {
                if(data.row.status === 0){
                    this.$router.push({name: 'cancel-session-request-details', params: { id: data.row.Id }})
                    this.$root.$emit('cancelSessionRequestTutorStatus',this.statusOfTutor,this.warningOfTutor,Number(this.getsettingList.userMaxWarningsToGetSuspend));
                }else{
                    this.$router.push({name: 'cancel-session-details', params: { id: data.row.Id }})
                }
            }
        },
        cancleMyMethod(){
            let self = this;
            self.isSpinner = true;
            let cancleT =[];
            db.collection(dbCollections.BOOKING).where("tutor","==" ,self.$route.params.id)
            .where("status", "==" , 3)
            .where("cancelReasonStatus" , "in", [0,1,2,3])
            .get().then((res)=>{
                if(res.empty){
                    this.isSpinner = false;
                    return;
                }
                if(!res.empty){
                    res.docs.forEach(cancelreason => {
                    cancelreason = cancelreason.data()
                    var canceltutor = {
                        bookingid: cancelreason.bookingId,
                        learnername: cancelreason.learnerDetails ? (cancelreason.learnerDetails.firstName+ " " + cancelreason.learnerDetails.lastName) : "",
                        cancelreasonfortutor: cancelreason.cancellationReason,
                        sessiontitle:cancelreason.sessionDetails ? cancelreason.sessionDetails.name : "",
                        cancellationdate:moment(cancelreason.cancellationDate.seconds*1000).format("DD-MM-YYYY"),
                        secCancelDate: cancelreason.cancellationDate,
                        bookinglernerId: cancelreason.learnerId,
                        cancellationDateCalander:moment(cancelreason.cancellationDate.seconds*1000).format("YYYY-MM-DD HH:mm"),
                        avatar: cancelreason.learnerDetails.profileImage,
                        Id: cancelreason.id,
                        bookingTutor: cancelreason.tutor,
                        status: cancelreason.cancelReasonStatus

                    }
                    cancleT.push(canceltutor);
                    if(res.docs.length == cancleT.length){
                        getFirebase.simpleGetDataFromSubCollectionWithDocIDV2(dbCollections.USERS,this.$route.params.id,(cancelDatas123)=>{
                            this.warningOfTutor = cancelDatas123.data.warning,
                            this.statusOfTutor = cancelDatas123.data.status
                            if(this.statusOfTutor !== 1){
                                this.statusOfTutorForSuspend = true;
                            } else {
                                this.statusOfTutorForSuspend = false;
                            }
                        })
                        
                        self.isSpinner = false;
                    }
                });
                    self.cancelrowstutor = cancleT;
                }
            })
        },

        handlerForApprovation(){
            this.statusOfTutorForSuspend = false;
            this.isSpinner = true;
            this.$bvModal.hide("modal-approve");
            try {
                const actionreasondetail = {
                    cancelReasonStatus: Number(1),
                    updatedAt: new Date()
                };
                updateFirebase.updateRootCollectionDataByDocId(
                    dbCollections.BOOKING,
                    this.selectedAction,
                    actionreasondetail,
                    (res) => {
                        let Index = this.cancelrowstutor.findIndex((values) => {
                            return values.Id === this.selectedAction;
                        });
                        if(Index !== -1){
                            this.cancelrowstutor[Index].status = 1
                        };
                        this.getTutorTypeBadgeVariant(1);
                        this.selectedAction = '';
                        this.selectedTutorID = '';
                        this.isSpinner = false;
                    }
                    
                );
            } catch (error) {
                console.error("error",error)
                errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "handlerForApprovation",
                errorMessage : error.message
                },(res)=>{
                if(res.error){
                    console.error(res.error)
                }
                })
                this.isSpinner = false;
            }
      
        },

        handlerForGiveWarning(){
            this.statusOfTutorForSuspend = false;
            this.isSpinner = true;
            this.$bvModal.hide("modal-warning");
            try {
                const actionreasondetail = {
                    cancelReasonStatus: Number(3),
                    updatedAt: new Date()
                };
                updateFirebase.updateRootCollectionDataByDocId(
                    dbCollections.BOOKING,
                    this.selectedAction,
                    actionreasondetail,
                    () => {
                        getFirebase.simpleGetDataFromSubCollectionWithDocIDV2(dbCollections.USERS,this.selectedTutorID,(cancelDatas)=>{
                            let warnning = cancelDatas.data.warning
                            this.warningOfTutor = cancelDatas.data.warning
                            const actionreasondetai2 = {
                                warning: warnning+1,
                            };
                            this.$root.$emit("warningIncrementOfTutor",cancelDatas.data.warning+1)
                            this.warningOfTutor = cancelDatas.data.warning+1
                            updateFirebase.updateRootCollectionDataByDocId(
                            dbCollections.USERS,
                            this.selectedTutorID,
                            actionreasondetai2,
                                () => {
                                    functionForGiveWarning(this.selectedTutorID,"booking",this.getsettingList.warningResetDays).catch(error=>{
                                        console.error("error",error)
                                    })
                                     onWarningGiveNotification({
                                        tutorId :  this.selectedTutorID
                                    }, () => {});
                                    let Index = this.cancelrowstutor.findIndex((values) => {
                                        return values.Id === this.selectedAction;
                                    });
                                    if(Index !== -1){
                                        this.cancelrowstutor[Index].status = 3
                                    };
                                    this.selectedAction = '';
                                    this.selectedTutorID = '';
                                    this.isSpinner = false;
                                })
                        })      
                    }
                );
            } catch (error) {
                console.error("error",error)
                 errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "handlerForGiveWarning",
                errorMessage : error.message
                },(res)=>{
                if(res.error){
                    console.error(res.error)
                }
                })
                this.isSpinner = false;
            }
        
        },

        handlerForSuspendTutor(){
            // this.statusOfTutorForSuspend = true;
            this.isSpinner = true;
            this.$bvModal.hide("modal-suspends");
            try {
                const actionreasondetail = {
                    cancelReasonStatus: Number(2),
                    updatedAt: new Date()
                };
                updateFirebase.updateRootCollectionDataByDocId(
                    dbCollections.BOOKING,
                    this.selectedAction,
                    actionreasondetail,
                    () => {
                        const actionreasondetai2 = {
                            status: Number(2),
                            updatedAt : new Date()
                        };
                        updateFirebase.updateRootCollectionDataByDocId(
                            dbCollections.USERS,
                            this.selectedTutorID,
                            actionreasondetai2,
                            () => {
                                functionForSuspendtionAction(this.selectedTutorID).then(()=>{
                                    functionForSuspendOrBlockChild(this.selectedTutorID,true,false,()=>{})
                                    this.isSpinner = false;
                                    this.$root.$emit('suspend',actionreasondetai2.status);
                                    let Index = this.cancelrowstutor.findIndex((values) => {
                                        return values.Id === this.selectedAction;
                                    });
                                    if(Index !== -1){
                                        this.cancelrowstutor[Index].status = 2;
                                    };
                                    this.statusOfTutorForSuspend = true;
                                    this.selectedAction = ''
                                    this.selectedTutorID = ''
                                    sendNotificationUserStatus({
                                        uid: this.selectedTutorID,
                                        userStatus: "Suspend"
                                    },() => {}) 
                                    this.$root.$emit(
                                        "showToastMessage",
                                        "Tutor suspended successfully.",
                                        "success"
                                    );
                                        this.isSpinner = false
                                }) .catch((err)=>{
                                    console.error("in cancel session booking",err)
                                    this.isSpinner = false
                                })
                            }
                        )
                    }
                );
            } catch (error) {
                console.error("error",error)
                 errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "handlerForSuspendTutor",
                errorMessage : error.message
                },(res)=>{
                if(res.error){
                    console.error(res.error)
                }
                })
            }
        
        },

        limitToTitle: function (value) {
            if (!value) return "";
            return value.substring(0, 20) + "...";
        },

        getTutorTypeBadgeVariant(value){
            var variant = "light-";
            if(value == 1)
            {
                variant += 'success';
            }
            else if(value == 2)
            {
                variant += 'danger';
            }
            else if(value == 3)
            {
                variant += 'color';
            }
            else{
                variant += 'warning'
            }
            return variant;
        },

        onCancelSession(){
            var self = this;
            var allTabList = document.getElementsByClassName('nav-link active')
            if (allTabList[0].innerText != 'Canceled Session(s)') { 
                self.cancleMyMethod();
                self.limitToTitle();
            }
        }
    }
}
</script>
