<template>
<div class="dsfdsfdsfdsf">
    <b-modal header-class="my-class" modal-class="my-second-class" class="className" :id="'readmore' + id" centered hide-footer :title="title">
        <p class="text_interested">{{text}}</p>
    </b-modal>
</div>
</template>
<script>
export default {
  props: {
    title:String,
    text:String,
    id:String
  },
  data(){
      return{
          className: 'dataaa'
      }
  }
}
</script>