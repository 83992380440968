<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />

      <div class="card user_filter current_session">
        <h2>Filters</h2>

        <b-form @submit.prevent>
          <b-row>
            <b-col xl="3" lg="3" md="3">
              <label>Learner Name</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="user_type_selected"
                variant="outline-secondary"
                class="w-100"
              >
                <b-dropdown-item
                  v-for="item in user_type_options"
                  @click="user_type_selected = item.text"
                  :key="item.value"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
            <b-col xl="3" lg="3" md="3">
              <label>Tutor Name</label>
              <b-dropdown
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :text="tutor_type_selected"
                variant="outline-secondary"
                class="w-100"
              >
                <b-dropdown-item
                  v-for="item in tutor_type_options"
                  @click="tutor_type_selected = item.text"
                  :key="item.value"
                  >{{ item.text }}</b-dropdown-item
                >
              </b-dropdown>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <div class="card user_table current_session_table">
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1"></label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search..."
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <vue-good-table
          :columns="columns"
          :rows="filterRows"
          @on-cell-click="onCellClick"
          class="cursor-pointer"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
          <template slot="table-row" slot-scope="props">
              {{props.row.descToggled}}
            <span v-if="props.column.field === 'bookingId'" class="text-nowrap">
             {{ props.row.bookingId }}
            </span>
            <span
              v-else-if="props.column.field === 'tutorType'"
              class="tutor_type"
            >
              <b-badge :variant="tutorType[1][props.row.tutorType]">
                {{ tutorType[0][props.row.tutorType] }}
              </b-badge>
            </span>
            <span
              v-else-if="props.column.field === 'learnerChildName'"
              class="text-nowrap"
            >
              <b-avatar :src="props.row.avatarForLearner" class="user_img" />
              <router-link :to="{name: 'user-details',params: {id: props.row.bookinglernerId},}"
                class="text-nowrap"
                >{{ props.row.learnerChildName }}</router-link
              >
            </span>
            <span
              v-else-if="props.column.field === 'tutorNameDetail'"
              class="text-nowrap"
            >
              <b-avatar :src="props.row.avatarForTutor" class="user_img" />
              <router-link
                :to="{ name: 'user-details', params: {id: props.row.bookingTutor},}"
                class="text-nowrap"
                >{{ props.row.tutorNameDetail }}</router-link
              >
            </span>

            <span
              v-else-if="props.column.field === 'sessionBooking'"
              class="text-nowrap"
            >
                {{sessionTimeCalculate(props.row.sessionBooking, props.row.sessionEnd)}}
            </span>
            <span
              v-else-if="props.column.field === 'bookingcredits'"
              class="text-nowrap"
            >
              <span v-if="props.row.bookingcredits !== 0">
                {{ props.row.bookingcredits }} Credits
              </span>
              <span v-else style="color:red">
                Trial Booking
              </span>
            </span>
            <span v-else-if="props.column.field === 'sessionTitle'" class="cancel_reason">{{props.row.sessionTitle}}
              <span v-if='props.row.sessionTitle !== null && props.row.sessionTitle !== undefined && props.row.sessionTitle !== ""'>
                {{props.row.sessionTitle}}
              </span>
              <span v-else>N/A</span>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> 
                  <span v-if="props.total === 0"> Showing 0 </span>
                  <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                   to
                  <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                  <span v-else>{{props.total}} of {{ props.total }} entries</span>
                </span>

                    <div class="d-flex align-items-center mb-1 mt-0 showentry">
                      <span>Show</span>
                      <b-form-select
                        v-model="pageLength"
                        :options="['10', '20', '30', '40', '50']"
                        class="mx-1"
                        @input="
                          (value) => props.perPageChanged({ currentPerPage: value })
                        "
                     />
                      <span>entries</span>
                    </div>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :current-page="currentPage"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="
                    (value) => {
                      props.pageChanged({ currentPage: value });
                      currentPage = value
                    }
                  "
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BSpinner,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import { mapGetters,mapActions } from "vuex";
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "@/views/SessionsManagement/CurrentOnlineSession(s)/currentOnlineSessionsList.vue"
import Ripple from "vue-ripple-directive";
import moment from "moment";
export default {
  directives: {
    Ripple,
  },
  components: {
    VueGoodTable,
    BSpinner,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BButton,
  },
  data() {
    return {
      direction: false,
      isSpinner: false,
      isUpdating: false,

      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      user_type_selected: "All",
      tutor_type_selected: "All",
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Booking ID",
          field: "bookingId",
        },
        {
          label: "Session Booking Date & Time",
          field: "sessionBooking",
        },
        {
          label: "Session(s) Title",
          field: "sessionTitle",
        },
        {
          label: "Tutor Name",
          field: "tutorNameDetail",
        },
        {
          label: "Learner Name",
          field: "learnerChildName",
        },
        {
          label: "Booking Credits",
          field: "bookingcredits",
        },
      ],

      searchTerm: "",
    };
  },
  computed: {
    ...mapGetters({
      getOnlineCurrentList: "currentOnline/getOnlineCurrent",
    }),
    rows() {
      try {
        var self = this;
        var rowsArray = [];
        var count = 0;
        self.getOnlineCurrentList.forEach((items) => {
          count =+ 1
          if ((self.checkDate(new Date(items.endTime.seconds * 1000)) >= self.checkDate(new Date()))) {
            var object = {
              bookingId: items.bookingId,
              descToggled: null,
              Id: items.id,
              avatarForLearner: items.learnerDetails?items.learnerDetails.profileImage:'',
              avatarForTutor: items.tutorDetails?items.tutorDetails.profilePicture:'',
              sessionTitle: items.sessionDetails ? items.sessionDetails.name:'',
              learnerChildName:
                (items.learnerDetails ? items.learnerDetails.firstName:'' )+
                " " +
                (items.learnerDetails?items.learnerDetails.lastName:''),
              tutorNameDetail:
                (items.tutorDetails ?items.tutorDetails.firstName:'') +
                " " +
                (items.tutorDetails?items.tutorDetails.lastName:''),
              bookingcredits: items.usedCredits?items.usedCredits:0,
              sessionBooking: items.startTime,
              sessionEnd: items.endTime,
              bookingTutor: items.tutor,
              bookinglernerId: items.learnerId
            };
            rowsArray.push(object);
          }
          else{
            self.isSpinner = false;
          }     
        });
        return rowsArray;
      } catch (error) {
        console.error("error", error);
        errorEmail({
            projectName : process.env.VUE_APP_PROJECTNAME,
            environment : process.env.NODE_ENV,
            fileName : currentFileName,
            hookName : "rows",
            errorMessage : error.message
        },(mailRes) => {
          if(mailRes.error){
            console.error(mailRes);
          }
        })
      }
    },
    user_type_options() {
      var learnerName = [];
      learnerName.push({ value: 0, text: "All" });
      for (var i = 0; i < this.rows.length; i++) {
        var temp = false;
        for (var j = 0; j < learnerName.length; j++) {
          if (this.rows[i].learnerChildName == learnerName[j].text) {
            temp = true;
          }
        }
        if (!temp) {
          var obj = {
            val: i + 1,
            text: this.rows[i].learnerChildName,
          };
          learnerName.push(obj);
        }
      }
      return learnerName;
    },
    tutor_type_options() {
      var tutorName12 = [];
      tutorName12.push({ value: 0, text: "All" });
      for (var i = 0; i < this.rows.length; i++) {
        var temp = false;
        for (var j = 0; j < tutorName12.length; j++) {
          if (this.rows[i].tutorNameDetail == tutorName12[j].text) {
            temp = true;
          }
        }
        if (!temp) {
          var obj = {
            val: i + 1,
            text: this.rows[i].tutorNameDetail,
          };
          tutorName12.push(obj);
        }
      }
      return tutorName12;
    },
    filterRows() {
      var self = this;
      var listA = self.rows;
      if (
        self.user_type_selected == "All" &&
        self.tutor_type_selected == "All"
      ) {
        return listA;
      }
      if (self.user_type_selected != "All") {
        listA = listA.filter((data) => {
          return data.learnerChildName
            .toLowerCase()
            .includes(self.user_type_selected.toLowerCase());
        });
      }
      if (self.tutor_type_selected != "All") {
        listA = listA.filter((dataTutor) => {
          return dataTutor.tutorNameDetail
            .toLowerCase()
            .includes(self.tutor_type_selected.toLowerCase());
        });
      }
      return listA;
    },
  },
  created() {
    let getOnlineCurrFound = this.getOnlineCurrentList.length != 0;
    if(!getOnlineCurrFound){
      this.isSpinner = true
      this.getOnlineCurrentLists().then(res=>{
        this.isSpinner = false
      }).catch(error=>{
        console.error("error",error)
      })
    }else{
      this.isSpinner = false
    }
  },
  methods: {
    onCellClick(data) {
      if (data.column.field==="learnerChildName") {
            this.$router.push({name: 'user-details',params: {id: data.row.bookinglernerId}})
          } else if (data.column.field==="tutorNameDetail") {
              this.$router.push({ name: 'user-details', params: {id: data.row.bookingTutor},})
          }
        else {
            this.$router.push({  name: 'current-session-detail', params: { id: data.row.Id, }})
        }
    },
    sessionTimeCalculate(startTime , endTime) {
      if (moment(startTime.seconds*1000).format("DD-MM-YYYY") == moment(endTime.seconds*1000).format("DD-MM-YYYY")) {
        return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("hh:mm A")}`
      } else {
        return `${moment(startTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")} to ${moment(endTime.seconds*1000).format("DD-MM-YYYY, hh:mm A")}`
      }
    },
    ...mapActions({
        'getOnlineCurrentLists' : 'currentOnline/getOnlineCurrentData',
      }),
    limitTo: function (value) {
      if (!value) return "";
      return value.substring(0, 90) + "...";
    },
    TemplateCreatedDate(TemplateCreatedDate) {
      var self = this;
      var notificationDate = new Date(
        TemplateCreatedDate.seconds * 1000
      ).getDate();
      var notificationMonth =
        new Date(TemplateCreatedDate.seconds * 1000).getMonth() + 1;
      var notificationYear = new Date(
        TemplateCreatedDate.seconds * 1000
      ).getFullYear();
      var returnnotificationDate =
        self.addZero(notificationDate) +
        "-" +
        self.addZero(notificationMonth) +
        "-" +
        notificationYear +
        ", " +
        self.notificationCreateTime(
          new Date(TemplateCreatedDate.seconds * 1000)
        );
      return returnnotificationDate;
    },
    TemplateEndDate(TemplateEndDate) {
      var self = this;
      var returnnotificationDate = self.notificationCreateTime(
        new Date(TemplateEndDate.seconds * 1000)
      );
      return returnnotificationDate;
    },
    notificationCreateTime(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      hours = hours<10 ? '0'+hours:hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    addZero(value) {
      if (value > 9) {
        return value;
      } else {
        return "0" + value;
      }
    },
    checkDate(time) {
      const finaldate = time.getTime();
      return finaldate;
    },
  },
};
</script>

