<template>
  <div>
    <b-spinner
      variant="primary"
      v-if="isSpinner"
      large
      class="pageLoadSpinners"
    />
    <div v-show="!isSpinner">
      <b-spinner
        variant="primary"
        v-if="isUpdating"
        large
        class="pageLoadSpinners"
        style="position: fixed"
      />

    <div class="card user_table notification_table">
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              placeholder="Search..."
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <vue-good-table
        :columns="columns"
        :rows="notificationList"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'createdAt'" class="text-nowrap">
            {{ TemplateCreatedDate(props.row.createdAt) }}
          </span>
          <span 
            v-else-if="props.column.field === 'userType'"
            class="text-nowrap"
            style="text-transform: capitalize !important;"
          >
            {{ typeof(props.row.userType) === "string" ? (props.row.userType === "all" ? 'Learner, Tutor' : 'Tutor'): props.row.userType.join(", ")}}
          </span>
          <div
            v-else-if="props.column.field === 'description'"
            class="wrap-break-word"
            v-show="true"
            >
            <span v-html="limitTo(props.row.description)"></span>
            <a
                class="readmore"
                v-b-modal="'readmore' + props.row.id"
                v-show="!props.row['isEditable'] && props.row.description.length >= 220"
                
                >Read more</a
            >
            <UserModal title="Message" :text="props.row.description" :id="props.row.id" />
        </div>
          <span
            v-else-if="props.column.field === 'action'"
            class="action_field"
          >
            <b-button
              class="ml-2"
              variant="flat-dark delete"
              @click="deleteNotification(props.row.id)"
            >
              <img
                :src="require('@/assets/images/icons/delete.png')"
                alt="image"
              />
            </b-button>
          </span>
        </template>
        <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> 
                    <span v-if="props.total === 0"> Showing 0 </span>
                    <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                    to
                      <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                      <span v-else>{{props.total}} of {{ props.total }} entries</span>
                    </span>

                        <div class="d-flex align-items-center mb-1 mt-0 showentry">
                          <span>Show</span>
                          <b-form-select
                            v-model="pageLength"
                            :options="['10', '20', '30', '40', '50']"
                            class="mx-1"
                            @input="
                              (value) => props.perPageChanged({ currentPerPage: value })
                            "
                        />
                          <span>entries</span>
                        </div>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :current-page="currentPage"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => {
                          props.pageChanged({ currentPage: value });
                          currentPage = value
                        }
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
        </template>
      </vue-good-table>
    </div>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
  BTabs,
  BTab,
  BCard,
  BModal,
  BSpinner,
  VBModal,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { mapActions, mapGetters } from "vuex";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import { dbCollections } from "@/utils/firebaseCollection.js";
import UserModal from "@/@core/components/modals/ReadMoreModal.vue";

export default {
  components: {
    VueGoodTable,
    UserModal,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BCard,
    BButton,
    BTabs,
    BTab,
    BModal,
    BSpinner,
  },
  data() {
    return {
      direction: false,
      isSpinner: false,
      isUpdating: false,
      isDeleted:true,
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Date",
          field: "createdAt",
        },
        {
          label: "User Type",
          field: "userType",
        },
        {
          label: "Message",
          field: "description",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      searchTerm: "",
    };
  },
  created() {
    this.isSpinner = true;
    this.getNotificationData().then(()=>{
      this.isSpinner = false;
    })
    .catch((err)=>{
      console.error("error",err)
    })
  },
  computed: {
    ...mapGetters({
      notificationList: "notificationManagement/getNotifications",
    }),
  },
  methods: {
    ...mapActions({
      getNotificationData: "notificationManagement/getNotificationsData",
    }),
    toggleFlag: function (index, column) {
      this.rows[index][column].toggled = true;
    },
     limitTo: function (value) {
            if(value){
                if (value.length < 220){
                    return value
                }else{
    
                    return value.substring(0, 220) + "...";
                }
            }else{
                return "-";
            }
        },
    TemplateCreatedDate(TemplateCreatedDate) {
      var self = this;
      var notificationDate = new Date(TemplateCreatedDate.seconds * 1000).getDate();
      var notificationMonth = new Date(TemplateCreatedDate.seconds * 1000).getMonth() + 1;
      var notificationYear = new Date(TemplateCreatedDate.seconds * 1000).getFullYear();
      var returnnotificationDate =
        self.addZero(notificationDate) +
        "-" +
        self.addZero(notificationMonth) +
        "-" +
        notificationYear +
        " , " +
        self.notificationCreateTime(
          new Date(TemplateCreatedDate.seconds * 1000)
        );
      return returnnotificationDate;
    },
    notificationCreateTime(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    addZero(value) {
      if (value > 9) {
        return value;
      } else {
        return "0" + value;
      }
    },
    deleteNotification(id) {
        var self = this;
        self.$bvModal
        .msgBoxConfirm('Are you sure you want to Delete it?', {
          title: 'Delete',         
          class:'test',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
          footerClass: 'approve-footer',
          headerClass: 'approve-header',
          bodyClass: 'approve-body',
        })
        .then((value) => {
          if (value) {
            self.isSpinner = true;
            let notificationDeleteObj = {
            isDeleted:true
            }
            updateFirebase.updateRootCollectionDataByDocId(
              dbCollections.NOTIFICATIONMANAGEMENT,
              id,notificationDeleteObj,
              (res) => {
                self.$nextTick(() => {
                  self.$root.$emit(
                    "showToastMessage",
                    "Notification deleted successfully.",
                    "success"
                  );
                  self.isSpinner = false;
                });
              }
            );
          }
        });
    },
  },
};
</script>
<style scoped>
.wrap-break-word {
    word-break: break-all;
}
[dir] .action_field button.btn.btn-flat-dark.delete {
    margin: 0px !important;
}
</style>
