<template>

  <div>
    <b-card class="transaction_history">
        <b-tabs   v-model="tabIndex">
          <LearnerHistory/>
          <TutorHistory :getData = 'getTutorHistory'/>
          <TransactionHistoryTab/>
      </b-tabs>
    </b-card>
    <b-modal
      id="modal-approve"
      centered
      title=""
      ok-title="Yes"
      cancel-title="No"
    >
      <img src="@/assets/images/tutorimages/approve.png" alt="profile" class="approve_image">
      <h4>Approve</h4>
      <b-card-text>
       Are you sure you want to Approve?
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-warning"
      centered
      title=""
      ok-title="Yes"
      cancel-title="No"
    >
     <img src="@/assets/images/tutorimages/warning.png" alt="profile" class="warning_image">
      <h4>Give Warning</h4>
      <b-card-text>
        Are you sure you want to Give Warning?
        You had already given 1 warning before.
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BForm,
  BTabs, 
  BTab,
  BCardText,
  BCard,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import * as insertFirebase from "@/utils/FirebaseQueries/insertQueries/insertQueries.js";
import * as update from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import {dbCollections} from "@/utils/firebaseCollection.js"
import LearnerHistory from "./TransactionHistoryTabComponents/learnerHistory.vue";
import TutorHistory from "./TransactionHistoryTabComponents/tutorHistory.vue";
import TransactionHistoryTab from "./TransactionHistoryTabComponents/transactionHistoryTab.vue";
import store from "@/store/index";

import Ripple from 'vue-ripple-directive'
export default {
  directives: {
    Ripple,
  },
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BButton,
    BTabs,
    BTab,
    BCardText,
    BCard,
    LearnerHistory,
    TutorHistory,
    TransactionHistoryTab
  },
  data() {
    return {
      direction:false,
      isSpinner:false,
      isUpdating:false,
      tabActive: {},
      tabIndex: 0,
      storeTabIndex: 0,
      perPage: 10,
      pageOptions: [10, 20, 30, 40, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      user_type_selected: null,
      user_type_options: [
        { value: null, text: "All" },
        { value: "a", text: "Learner" },
        { value: "b", text: "Child" },
        { value: "c", text: "Tutor" },
      ],
      tutor_type_selected: null,
      tutor_type_options: [
        { value: null, text: "All" },
        { value: "a", text: "Valuable" },
        { value: "b", text: "Normal" },
        { value: "c", text: "Elite" },
      ],
      status_selected: null,
      status_options: [
        { value: null, text: "All" },
        { value: "a", text: "Active" },
        { value: "b", text: "Suspend" },
        { value: "c", text: "Block" },
      ],
      Total_no_of_Warning: null,
      Total_no_of_Warning: [
        { value: 0, text: "noraml" },
        { value: 1, text: "normal" },
        { value: 2, text: "danger" },
        { value: 3, text: "normal" },
      ],

      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Date",
          field: "date",
        },
        {
          label: "Transaction ID",
          field: "transactionid",
        },
        {
          label: "User Name",
          field: "userName",
        },
        {
          label: "Booking ID",
          field: "bookingId",
        },
        {
          label: "Credits",
          field: "credit",
        },
        
        {
          label: "Avail. Credits",
          field: "availCredit",
        },
        {
          label: "Remarks",
          field: "remarks",
        },
      ],
      rows: [
        {
          date: "29-12-2021 , 05:24 PM" ,
          avatar: require("@/assets/images/avatars/9.png"),
          transactionid: " ot154284199747513232",
          userName: "Angela Mayer",
          bookingId: "",
          credit: 18,          
          availCredit: 33.00,
          remarks:"Referral Earning",
        },
         {
          date: "29-12-2021 , 05:15 PM" ,
          avatar: require("@/assets/images/avatars/9.png"),
          transactionid: " ot154284199747512194",
          userName: "David Stinson",
          bookingId: "221231ABC344",
          credit: -15,          
          availCredit: 20.00,
          remarks:"Booked Betty Decarlo Tutor",
        },
         {
          date: "29-12-2021 , 03:31 PM" ,
          avatar: require("@/assets/images/avatars/9.png"),
          transactionid: " ot154284199747499731",
          userName: "Marie Lemoine",
          bookingId: "",
          credit: 300,          
          availCredit: 304.00,
          remarks:"Purchase Prime Plan Ref. ID ot154284199747489287",
        },
         {
          date: "29-12-2021 , 11:04 AM" ,
          avatar: require("@/assets/images/avatars/9.png"),
          transactionid: " ot154284199747497134",
          userName: "Gloria Izaguirre",
          bookingId: "221231ABC343",
          credit: -15,          
          availCredit: 80.00,
          remarks:"Booked Marie Lemoine Tutor",
        },
         {
          date: "29-12-2021 , 09:24 AM" ,
          avatar: require("@/assets/images/avatars/9.png"),
          transactionid: " ot154284199747496943",
          userName: "Derick Nicholson",
          bookingId: "221231ABC342",
          credit: -15,          
          availCredit: 120.00,
          remarks:"Booked Marie Lemoine Tutor",
        },
         {
          date: "28-12-2021 , 06:54 PM" ,
          avatar: require("@/assets/images/avatars/9.png"),
          transactionid: " ot154284199747496143",
          userName: "Jeannine Sae",
          bookingId: "",
          credit: 18,          
          availCredit: 18.00,
          remarks:"Referral Earning",
        },
         {
          date: "28-12-2021 , 11:24 AM" ,
          avatar: require("@/assets/images/avatars/9.png"),
          transactionid: "ot154284199747495134",
          userName: "Sean Hayes",
          bookingId: "221231ABC341",
          credit: -15,          
          availCredit: 12.00,
          remarks:"Booked Betty Decarlo Tutor",
        },
         {
          date: "28-12-2021 , 07:08 AM" ,
          avatar: require("@/assets/images/avatars/9.png"),
          transactionid: "ot154284199747493845",
          userName: "Shelly Wesson",
          bookingId: "",
          credit: 100,          
          availCredit: 100.00,
          remarks:"Purchase Plus Plan Ref. ID ot154284199747489264",
        },
         {
          date: "27-12-2021 , 08:54 PM" ,
          avatar: require("@/assets/images/avatars/9.png"),
          transactionid: " ot154284199747489827",
          userName: "Alex Mann",
          bookingId: "221231ABC340",
          credit: -15,          
          availCredit: 0.00,
          remarks:"Booked Derick Nicholson Tutor",
        },
         {
          date: "27-12-2021 , 04:36 PM" ,
          avatar: require("@/assets/images/avatars/9.png"),
          transactionid: "ot154284199747489248",
          userName: "Catherine Jesse",
          bookingId: "",
          credit: 300,          
          availCredit: 125.00,
          remarks:"Purchase Prime PlanRef. ID ot154284199747489247",
        },
        
      ],
      searchTerm: "",
      status: [
        {
          1: "Current",
          2: "Active",
          3: "Block",
          4: "Suspend",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      tutorType: [
        {
          1: "Valuable",
          2: "Elite",
          3: "Normal",
          4: "N/A",
        },
        {
          1: "light-primary",
          2: "light-danger",
          3: "light-success",
          4: "light-dark",
        },
      ],
      totalNoOfWarning: [
        {
          1: "Valuable",
          2: "Elite",
          3: "Normal",
          4: "N/A",
        },
        {
          1: "light-primary",
          2: "light-danger",
          3: "light-success",
          4: "light-dark",
        },
      ],
      getTutorHistory: false,
    };
  },
  computed: {},
  created() {
  },
  methods: {
   
  },

  beforeRouteEnter(to,from,next){
    if (from.name == 'booking-detail-transection-tutor') {
      next();
    }
    else if(from.name == 'booking-detail-transection-learner'){
      next()
    }
    else {
      next()
    }
  }
};

</script>

