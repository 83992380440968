<template>
<b-tab title="Learner Histroy"  >
<div>
    <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners" />
    <div v-show="!isSpinner">
        <b-spinner variant="primary" v-if="isUpdating" large class="pageLoadSpinners" style="position: fixed" />

        
        <div class="card user_table cancel_session_table upcoming_session_table learner_histroy ">
            <div class="custom-search d-flex justify-content-end">
                <b-button variant="outline-primary" v-on:click="exportData">
                    Export Excel
                </b-button>
                    <b-form-group>
                        <div class="d-flex align-items-center">
                            <b-form-input
                                v-model="searchTerm"
                                placeholder="Search..."
                                type="text"
                                class="d-inline-block"
                                
                            />
                        </div>
                    </b-form-group>
            </div>
            <vue-good-table :columns="columns" class="cursor_pointer"  :rows="getLearnerHistoryTransection" :rtl="direction" :search-options="{
                enabled: true,
                externalQuery: searchTerm,
            }" :select-options="{
                enabled: true,
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
            }" :pagination-options="{
                enabled: true,
                perPage: pageLength,
            }">
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'bookingId'" class="text-nowrap"  @click="LearnerHistoryss()">
                        <router-link :to="{name: 'booking-detail-transection-learner', params: { id: props.row.bookingDocId} , query: {tab: 'learner'}}">
                        <span class="text-nowrap">{{ props.row.bookingId }}</span>
                        </router-link>
                    </span>
                    <span v-else-if="props.column.field === 'transactionid'" class="transact_id">
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                    <span v-else-if="props.column.field === 'userName'" class="text-nowrap">
                        <b-avatar :src="props.row.avatar" class="user_img" />
                        <router-link    :to="{name: 'user-details',params: { id: props.row.userId}}" class="text-nowrap">{{ props.row.userName }}</router-link>
                    </span>
                    <span v-else-if="props.column.field === 'date'" class="date">
                        {{ props.row.date }}
                    </span>
                    <span v-else-if="props.column.field === 'remarks'" class="remarks">
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                    <span v-else-if="props.column.field === 'tutorType'" class="tutor_type">
                        <b-badge :variant="tutorType[1][props.row.tutorType]">
                            {{ tutorType[0][props.row.tutorType] }}
                        </b-badge>
                    </span>
                    <span v-else-if="props.column.field === 'credit'" class="credit" :style="props.row.credit > 0 ? 'color:#0D9E00':'color:#FF0000'">
                        {{props.row.credit ? props.row.credit : props.row.debits}}
                    </span>
                    <span v-else-if="props.column.field === 'bookingCredits'" class="booking_Credits">
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                    <span v-else>
                        {{ props.formattedRow[props.column.field] }}
                    </span>
                </template>
                <template slot="pagination-bottom" slot-scope="props">
                    <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> 
                        <span v-if="props.total === 0"> Showing 0 </span>
                        <span v-else> Showing {{(pageLength*(currentPage-1)+1)}} </span> 
                        to 
                        <span v-if="props.total > pageLength*currentPage">{{(pageLength*currentPage)}} of {{ props.total }} entries</span>
                        <span v-else>{{props.total}} of {{ props.total }} entries</span>
                        </span>

                            <div class="d-flex align-items-center mb-1 mt-0 showentry subcategoryDetail">
                            <span>Show</span>
                            <b-form-select
                                v-model="pageLength"
                                :options="['10', '20', '30', '40', '50']"
                                class="mx-1"
                                @input="
                                (value) => props.perPageChanged({ currentPerPage: value })
                                "
                            />
                            <span>entries</span>
                            </div>
                    </div>
                    <div>
                        <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :current-page="currentPage"
                        :per-page="pageLength"
                        first-number
                        last-number
                        align="right"
                        prev-class="prev-item"
                        next-class="next-item"
                        class="mt-1 mb-0"
                        @input="
                            (value) => {
                            props.pageChanged({ currentPage: value });
                            currentPage = value
                            }
                        "
                        >
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                        </b-pagination>
                    </div>
                    </div>
                </template>
            </vue-good-table>
        </div>
    </div>
</div>
</b-tab>
</template>

<script>
import {
    BButton,
    BSpinner,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BForm,
    BTabs,
    BTab,
    BCardText,
    BCard,
} from "bootstrap-vue";
import {
    VueGoodTable
} from "vue-good-table";
import store from "@/store/index";
import * as getQuery from "@/utils/FirebaseQueries/getQueries/getQueries.js";
import { dbCollections } from "@/utils/firebaseCollection.js";
import moment from "moment";
import Ripple from 'vue-ripple-directive'
import firebase from "@/utils/firebaseInit.js"
const db = firebase.firestore()
import {errorEmail} from "@/utils/apiRequests.js"
const currentFileName = "src/views/TransactionHistory/TransactionHistoryTabComponents/learnerHistory.vue";
import { mapGetters,mapActions } from "vuex";

export default {
    directives: {
        Ripple,
    },
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BSpinner,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BRow,
        BCol,
        BForm,
        BButton,
        BTabs,
        BTab,
        BCardText,
        BCard,
    },
    data() {
        return {
            direction:false,
            isSpinner:false,
            isUpdating:false, 
            perPage: 10,
            pageOptions: [10, 20, 30, 40, 50],
            learnerTransection:[],
            searchTerm: "",
            currentPage: 1,
            pageLength: 10,
            columns: [{
                    label: "Date",
                    field: "date",
                },
                {
                    label: "Transaction ID",
                    field: "transactionid",
                },
                {
                    label: "User Name",
                    field: "userName",
                },
                {
                    label: "Booking ID",
                    field: "bookingId",
                },
                {
                    label: "Credits",
                    field: "credit",
                },

                {
                    label: "Avail. Credits",
                    field: "availCredit",
                },
                {
                    label: "Remarks",
                    field: "remarks",
                },
            ],
        };
    },
    computed: {
            ...mapGetters({
            getLearnerHistoryTransection: 'RevanueCalculation/getLearnerHistory'
        }),
    },
    created() {
        this.getTransection()
    },
    methods: {
         ...mapActions({
            'getLearnerTransection': 'RevanueCalculation/getLearnerHistoryTransection'
         }),
        LearnerHistoryss(){
            var self = this;
            self.$root.$emit("learnerHistoryBreadcumb", "All Transactions")
        },
        getTransection(){
            let transecionData = this.getLearnerHistoryTransection.length != 0;
            if (!transecionData) {
                this.isSpinner = true;
                this.getLearnerTransection().then((resp)=>{
                    this.isSpinner = false;
                }).catch((error)=>{
                    this.isSpinner = false;
                    console.error("error",error)
                })
            }
        },
        exportData(){
            try{
                var self = this;
                var CSV = '';
                var row = "";

                let keys = "date,transactionid,userName,bookingId,credit,availCredit,remarks".split(',')

                row+='Date,Transaction ID,User Name,Booking ID,Credits,Avail. Credits,Remarks\n'

                self.getLearnerHistoryTransection.forEach((data2)=>{

                    row+=""

                    keys.forEach(key=>{
                    row+=data2[key] ? `"${data2[key]}"` : " "
                    row+=",";
                    })

                    row+="\n"
                })

                row.slice(0, row.length - 1);
                    CSV += row + "\n";
                    if (CSV == '') {        
                    alert("Invalid data");
                    return;
                }

                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
                var link = document.createElement("a");
                link.href = uri;
                link.style = "visibility:hidden";
                link.download = `LearnerTransactionHistory.csv`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

            }catch (error) {
                errorEmail({
                projectName : process.env.VUE_APP_PROJECTNAME,
                environment : process.env.NODE_ENV,
                fileName : currentFileName,
                hookName : "exportData",
                errorMessage : error.message
                },(res)=>{
                if(res.error){
                    console.error(res.error)
                }
                })
                console.error(error)
            }
            
        },
    },
};
</script>
